(function () {
  'use strict';
  angular.module('gf.common.filters', [])

    .filter('trustAsResourceUrl', ['$sce', function($sce) {
      return function(val) {
        return $sce.trustAsResourceUrl(val);
      };
    }])

    .filter('gfProperty', function ($translate) {
      return function (object, propName) {
        if (_.isEmpty(object)) {
          return '<' + $translate.instant('empty') + '>';
        }
        if (_.isPlainObject(object)) {
          return _.get(object, propName);
        }
        if (_.isArray(object)) {
          return _.map(object, propName).join(', ');
        }
        return object;
      }
    })

    .filter('gfSelectProperty', function ($translate) {
      return function (items, propName) {
        if (_.isEmpty(items)) {
          return '<' + $translate.instant('empty') + '>';
        }
        if (!_.isArray(items)) {
          return items;
        }
        if (items.length > 2) {
          return $translate.instant('multiselect.selectedText', {number: items.length});
        }
        return _.map(items, propName).join(', ');

      }
    })

})();
