(function () {
  'use strict';
  angular.module('gf.context.header', [])

    .config(function ($httpProvider) {

      $httpProvider.interceptors.push(
        function (securityService, $q, $injector, notifyManager, $translate) {
          return {
            'request': function (config) {
              if (config.url && config.url.indexOf('/icash') === 0) {
                if (securityService.context && securityService.context.currentRestaurant) {
                  config.headers['GF-RestaurantId'] = securityService.context.currentRestaurant.id;
                }
                if (securityService.context && securityService.context.currentOperator) {
                  config.headers['GF-OperatorId'] = securityService.context.currentOperator.id;
                }
              }
              return config;
            },
            'responseError': function (rejection) {
              var status = rejection.status;

              if ([412].indexOf(status) !== -1) {
                var securityService = $injector.get('securityService');
                var state = $injector.get('$state');

                securityService.refreshContext().then(function () {
                  notifyManager.error($translate.instant('message.multitab.failedToPerformAction'));
                  state.go("page.welcome");
                })
              }
              return $q.reject(rejection);
            }
          }
        }
      )
    })
})();
