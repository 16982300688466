import React from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {FormControlLabel, Switch} from "@material-ui/core";
import {isAutopreviewPeriod} from "./ReportUtils";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  inlineFilter: {
    display: 'inline-block'
  },
}));

export default function AutopreviewSwitch({isAutopreview, handleChangeAutopreview, chosenDate}) {
  const {t} = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.inlineFilter}>
      <FormControlLabel style={{marginLeft: 20}}
                        control={
                          <Switch
                            checked={isAutopreview}
                            onChange={handleChangeAutopreview}
                            name="autopreview"
                            color="primary"
                            disabled={!isAutopreviewPeriod(chosenDate)}
                            id="autopreview_switch"
                          />
                        }
                        label={t('report.autopreview')}
                        aria-owns={open ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="autopreview_period_popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open && !isAutopreviewPeriod(chosenDate)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{t('report.autopreview.availableForPeriod')}</Typography>
      </Popover>
    </div>
  );
}
