(function () {
  'use strict';
  angular.module('gf.bm.dashboard', ['gf.dashboard'])

    .config(function ($stateProvider) {

      var dateTypes = ['1', '2', '3', '4', '5', '6', '8'];

      $stateProvider
        .state('bm.dashboardDateType', {
          url: '/dashboard/:dateType',
          template: require('../template/bm/group-view/bm_dashboard.html'),
          controller: 'bmDashboardCtrl',
          controllerAs: '$ctrl',
          resolve: {
            dashboard: function ($http, $stateParams, appConfig) {
              var normalDateType = _.parseInt($stateParams.dateType).toString();
              $stateParams.dateType = _.includes(dateTypes, normalDateType) ? normalDateType : '1';
              return $http
                .get(appConfig.baseUrl + '/icash/bm_dashboard/' + $stateParams.dateType + '.json')
                .then(function (response) {
                  return response.data;
                });
            }
          },
          title: 'menu.dashboard',
          reloadOnSearch: false
        })

        .state('bm.dashboardDateTypeChartType', {
          url: '/dashboard/:dateType/:chartType',
          template: require('../template/bm/group-view/dashboard/bm_details.html'),
          controller: 'bmDashboardCtrl',
          controllerAs: '$ctrl',
          resolve: {
            dashboard: function ($http, $stateParams, securityService, appConfig) {
              var normalDateType = _.parseInt($stateParams.dateType).toString();
              $stateParams.dateType = _.includes(dateTypes, normalDateType) ? normalDateType : '1';
              return $http
                .get(appConfig.baseUrl + '/icash/bm_dashboard/' +  $stateParams.dateType + '/' + $stateParams.chartType + '.json')
                .then(function (response) {
                  return response.data;
                });
            }
          },
          title: 'menu.dashboard',
          reloadOnSearch: false
        });
    })

    .controller('bmDashboardCtrl', function (dashboard, securityService, $stateParams) {
      var self = this;

      self.dashboard = dashboard;

      self.options = {
        isBranchMode: true,
        baseUrl: '/icash/bm/'+ securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/proxy/dashboard/',
        filterParams: {
          filteredGroupId: []
        },
        getChartDetailsState: function (dateType, index, chartTypeId) {
          return 'bm.dashboardDateTypeChartType({dateType: ' + dateType + ', chartType: ' + chartTypeId + '})';
        },
        getDashboardState: function (dateType) {
          return 'bm.dashboardDateType({dateType: ' + dateType + '})';
        }
      };
    })

})();
