(function () {
  'use strict';
  angular.module('gf.bm.articleGroup', ['gf.bm.groupView'])

    .factory('BMArticleGroupMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/article_group/metadata.json')
    })
    .factory('BMArticleGroupContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/article_group/context.json')
    })
    .factory('BMArticleGroupRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.masterdataArticle_group', {
          url: '/masterdata/article_group',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMMasterdataArticleGroup',
          controllerAs: '$ctrl',
          resolve: {
            bmArticleGroupMetadata: function (BMArticleGroupMetadata, $stateParams, securityService) {
              return BMArticleGroupMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmArticleGroupContext: function (BMArticleGroupContext, $stateParams, securityService) {
              return BMArticleGroupContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmArticleGroupRules: function (BMArticleGroupRules, $stateParams, securityService) {
              return BMArticleGroupRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "masterdataArticle"
        })
    })

    .controller('BMMasterdataArticleGroup',
      function (bmArticleGroupMetadata, bmArticleGroupContext, securityService, $stateParams, bmArticleGroupRules) {
        var self = this;

        self.metadata = bmArticleGroupMetadata;
        self.context = bmArticleGroupContext;
        self.masterdataRules = bmArticleGroupRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name', enableCellEdit: false},
            {name: 'superGroup', enableCellEdit: false, displayName: 'articleGroup.parent'},
            {name: 'internalVat', showEmpty: false},
            {name: 'externalVat', showEmpty: false},
            {name: 'ticketLayout'},
            {name: 'ticketVoidLayout'},
            {
              name: 'bonPrinters',
              ruleField: 'articleGroup2Printers',
              ruleRelationField: 'printer'
            },
            {name: 'fixedAt'}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/article_group';
          }
        };

        self.permissionsConfig = {
          columnsPermissions: {
            'internalVat': {
              editPermissions: ['BM_ARTICLE_GROUP_CHANGE_TAX_RATE']
            },
            'externalVat': {
              editPermissions: ['BM_ARTICLE_GROUP_CHANGE_TAX_RATE']
            }
          }
        }

      }
    )

})();
