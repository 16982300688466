(function () {
  'use strict';
  angular.module('gf.bm.groupView', ['gf.bm.menu'])

    .config(function ($stateProvider, securityServiceProvider) {

      securityServiceProvider
        .securePath('/bm', function (security) {
          return security.hasAnyFeature('BRANCH_MANAGEMENT');
        });

      $stateProvider
        .state('bm', {
          url: '/bm/{groupId}',
          abstract: true,
          views: {
            '': {
              template: require('../../../main/angular/app/template/page.html')
            },
            'header@bm': {
              template: require('../template/bm/group-view/bm_header.html')
            },
            'footer@bm': {
              template: require('../../../main/angular/app/template/footer.html')
            }
          },
          resolve: {
            currentGroup: function ($stateParams, securityService) {
              return securityService.bmContext.$refresh($stateParams.groupId);
            }
          }
        })

    })

    // Put bmContext in the security service
    .run(function (securityService, $http, appConfig) {
      securityService.bmContext = {
        currentGroup: {},
        $refresh: function (groupId) {
          if (!groupId) return;
          //todo get info for group instead of all tree
          return $http.get(appConfig.baseUrl + '/icash/branch_group/' + groupId)
            .then(function (resp) {
              securityService.bmContext.currentGroup = resp.data;
            });
        }
      };
    })

    .component('gfGroupMultiselect', {
      template: require('../template/bm/group-view/bm_group_multiselect.html'),
      bindings: {
        selectedGroups: '=ngModel'
      },
      controller: function ($stateParams, $http) {
        var self = this;

        $http.get('/icash/branch_group/' + $stateParams.groupId + '/flat').then(function (response) {
          self.branchGroups = _.reject(response.data, {'id' : +$stateParams.groupId});
        })

      }
    })

})();
