import defaultTheme from './config.default.theme'
import sumupTheme from './config.sumup.theme'
import shoreTheme from './config.shore.theme'
import lightspeedTheme from './config.lightspeed.theme'

const resolveThemeConfig = () => {
  switch (THEME) {
    case 'sumup':
      return sumupTheme
    case 'culinaro':
      return defaultTheme
    case 'shore':
      return shoreTheme
    case 'lightspeed':
      return lightspeedTheme
    default:
      return defaultTheme
  }
}

export default resolveThemeConfig();
