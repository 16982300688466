(function () {
  'use strict';
  angular.module('gf.passwordRecovery', [])
    .controller("PasswordRecoveryCtrl", function ($scope, $http, $httpParamSerializer, $location) {
        $scope.passwordRecovery = {};
        $scope.resetPassword = {};
        $scope.recoverySuccess = false;
        $scope.resetSuccess = false;

        $scope.passwordMetadata = {
          fields: {
            password: {
              validators: [
                {
                  name: 'gf-password',
                  message: 'error.password.groupRequirements'
                },
                {
                  name: 'ng-minlength',
                  value: 8,
                  message: 'error.password.lengthRequirements'
                },
                {
                  name: 'required',
                  message: 'error.password.required'
                }
              ]
            },
            confirmPassword: {
              validators: [
                {
                  name: 'required',
                  message: 'error.password.required'
                }
              ]
            }
          }};

        $scope.submitPasswordRecoveryForm = function () {
          var request = {
            method: 'POST',
            url: '/icash/login/password_recovery',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: $httpParamSerializer($scope.passwordRecovery)
          };

          $http(request).then(function (result) {
            if (result.data.ok) {
              $scope.recoverySuccess = true;
            } else if (result.data.status == 'FAIL') {
              $scope.hasError = true;
              $scope.errorMessage = result.data.message;
            }
          });
        };
      $scope.submitPasswordResetForm = function () {
        var m = $location.search()['m'];
        var s = $location.search()['s'];
        var dt = $location.search()['dt'];
        var request = {
          method: 'POST',
          url: '/icash/login/reset_password?m=' + encodeURIComponent(m) + '&s=' + s + '&dt=' + dt,
          headers: {'Content-Type': 'application/x-www-form-urlencoded'},
          data: $httpParamSerializer($scope.resetPassword)
        };
        $http(request).then(function (result) {
          if (result.data.ok) {
            $scope.resetSuccess = true;
          } else if (result.data.status == 'FAIL') {
            $scope.hasError = true;
            $scope.errorMessage = result.data.message;
          }
        });
      }
    })
})();
