(function() {
  'use strict';
  angular.module('gf.icons.library', ['ui.router'])
    .directive('gfIconLibrary',
      function (icons, gridUtils, $q, $translate, $uibModal, localStorageService, appConfig) {
        return {
          scope: {
            iconPath: '=ngModel',
            iconSet: '='
          },
          template: require('../template/icon_library.html'),
          controller: function ($scope) {

            $scope.iconsLibraryPath = appConfig.libraryUrl;

            icons.library.get(prepareParams(), function (data) {
              $scope.icons = data;
              $scope.iconsList = convertTreeToList(data);
              $scope.recentIcons = getRecentIcons();
              $scope.currentIcon = getIconForPath($scope.iconsList, $scope.iconPath);

              $scope.$watch('currentIcon', function (currentIcon) {
                if (currentIcon) {
                  $scope.iconPath = currentIcon.path;
                } else if (currentIcon === null) {
                  $scope.iconPath = null;
                }
              })
            });

            function prepareParams() {
              var params = {};
              if ($scope.iconSet) {
                params.iconSet = $scope.iconSet;
              }
              return params;
            }

            function getIconForPath(iconsList, iconPath) {
              for (var i = 0; i < iconsList.length; i++) {
                if (iconsList[i].path === iconPath) {
                  return iconsList[i];
                }
              }
            }

            function convertTreeToList(data) {
              var stack = [], array = [], hashMap = {};

              var root = {children: data};
              stack.push(root);

              while (stack.length !== 0) {
                var node = stack.pop();
                if (!node.children) {
                  visitNode(node, hashMap, array);
                } else {
                  for (var i = node.children.length - 1; i >= 0; i--) {
                    stack.push(node.children[i]);
                  }
                }
              }
              return array;
            }

            function visitNode(node, hashMap, array) {
              if (!hashMap[node.name]) {
                hashMap[node.name] = true;
                array.push(node);
              }
            }

            $scope.showAllIconsModal = function () {

              var modalInstance = $uibModal.open({
                template: require('../template/show_all_icons_modal.html'),
                scope: $scope,
                controller: 'ModalIconLibraryCtrl',
                size: 'lg',
                windowClass: 'gf-modal gf-icon-library-modal'
              });

              modalInstance.result.then(function (selectedItem) {
                $scope.selectIcon(selectedItem);
              });

            };

            function getRecentIcons() {
              return localStorageService.get('recentIcons.' + $scope.iconSet);
            }

            function saveRecentIcon(icon) {
              var icons = getRecentIcons();

              if (icons) {
                // check same icons, get and remove if exist
                var i = 0;
                while (i < icons.length) {
                  if (isSameIcons(icon, icons[i])) {
                    icon = icons.splice(i, 1)[0];
                    break;
                  }
                  i++;
                }
              } else {
                icons = [];
              }

              // recent array length can be less than limit recentCount
              // otherwise we remove last element and add new in first position
              var recentCount = 5;
              if (icons.length >= recentCount) {
                icons.pop();
              }
              icons.unshift(icon);

              localStorageService.set('recentIcons.' + $scope.iconSet, icons);
              return icons;
            }

            /* Method for comparing icons objects and finding same icons */
            function isSameIcons(iconOne, iconTwo) {
              if (iconOne && iconTwo && iconOne.name && iconTwo.name && iconOne.path && iconTwo.path) {
                return iconOne.name === iconTwo.name && iconOne.path === iconTwo.path;
              } else {
                return false;
              }
            }

            $scope.selectIcon = function (icon) {
              if (icon === 'none') {
                $scope.currentIcon = null;
                return;
              }

              $scope.currentIcon = icon;

              // save icon and update recentIcons list
              $scope.recentIcons = saveRecentIcon(icon);

              $scope.clearSearch();

            };

            $scope.isFoundIcons = function () {
              return !$scope.iconsList || !$scope.iconsList.lenght;
            };

            // clear quickSearch input
            $scope.clearSearch = function () {
              this.quickSearch = {};
            };

          }
        }
      }
    )
    .filter('iconsFilter', function (prefixFilterFilter, translateFilter, filterFilter) {
      return function (icons, quickSearch) {
        var filteredIcons = [];
        angular.forEach(icons, function (icon) {
          var prefixFilteredIconName = prefixFilterFilter (icon.name, 'icon.');
          var translatedIconName = translateFilter (prefixFilteredIconName);
          var translatedIcon = angular.copy(icon);
          translatedIcon.name = translatedIconName;
          if (filterFilter ([translatedIcon], quickSearch).length) {
            filteredIcons.push(icon);
          }
        });
        return filteredIcons;
      }
    })
    .controller('ModalIconLibraryCtrl', function ($scope, $uibModalInstance) {

      $scope.treeOptions = {
        nodeChildren: "children"
      };

      // when init modal window open root directory
      $scope.folderItems = $scope.icons;

      // function for select icon in tree
      $scope.setCurrentNode = function (node, $parentNode) {
        if (node.children) {
          $scope.currentParent = node;
          $scope.folderItems = node.children;
          if ($scope.expanded.indexOf(node) < 0) {
            $scope.expanded.push(node);
          }
        } else {
          if ($parentNode !== null) {
            $scope.currentParent = $parentNode;
            $scope.folderItems = $parentNode.children;
          }
        }
        $scope.clearSearch();
      };

      // function for select icon in preview area
      $scope.selectNode = function (node) {
        if (node.children) {
          $scope.setCurrentNode(node);
        }
        $scope.selected = node;
      };

      $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.choice = function () {
        // if selected is directory dismiss choice
        if ($scope.isReadyForChoice($scope.selected)) {
          $uibModalInstance.close($scope.selected);
        } else {
          $uibModalInstance.dismiss('cancel');
        }
      };

      $scope.isReadyForChoice = function (node) {
        return !!node && !node.children;
      };
    });

})();
