(function () {
  'use strict';
  angular.module('gf.financial')

    .factory('PriceLevel', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/price_level/:priceLevelId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('PriceLevelMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/price_level/metadata.json')
    })
    .factory('PriceLevelContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/price_level/context.json');
    })

    .config(function ($stateProvider) {

      $stateProvider

        .state('page.financialPrice_level', {
          url: '/financial/price_level',
          template: require('../template/price_levels.html'),
          controller: 'PriceLevelGridCtrl',
          controllerAs: '$ctrl',
          resolve: {
            priceLevelContext: function (PriceLevelContext) {
              return PriceLevelContext.get({accountTypeId: 0}).$promise;
            },
            priceLevelMetadata: function (PriceLevelMetadata) {
              return PriceLevelMetadata.get().$promise;
            }
          },
          title: 'priceLevel'
        })

        .state('page.financialPrice_levelAdd', {
          url: '/financial/price_level/add',
          template: require('../template/price_level.html'),
          controller: 'PriceLevelAddCtrl',
          controllerAs: '$ctrl',
          resolve: {
            priceLevel: function (PriceLevel, $stateParams) {
              return PriceLevel.load({priceLevelId: $stateParams.id || "add"}).$promise;
            },
            priceLevelContext: function (PriceLevelContext) {
              return PriceLevelContext.get({accountTypeId: 0}).$promise;
            },
            priceLevelMetadata: function (PriceLevelMetadata) {
              return PriceLevelMetadata.get().$promise;
            }
          },
          title: "priceLevel"
        })

        .state('page.financialPrice_levelDetails', {
          url: '/financial/price_level/:id',
          template: require('../template/price_level.html'),
          controller: 'PriceLevelEditCtrl',
          controllerAs: '$ctrl',
          resolve: {
            priceLevel: function (PriceLevel, $stateParams) {
              return PriceLevel.load({priceLevelId: $stateParams.id || "add"}).$promise;
            },
            priceLevelContext: function (PriceLevelContext) {
              return PriceLevelContext.get({accountTypeId: 0}).$promise;
            },
            priceLevelMetadata: function (PriceLevelMetadata) {
              return PriceLevelMetadata.get().$promise;
            }
          },
          title: "priceLevel"
        })
    })

    .factory('PriceLevelAdd',
      function ($location, $translate, $state, notifyManager, configOptionsService) {
        return function (self, $scope, priceLevel, priceLevelContext, priceLevelMetadata) {

          self.priceLevelContext = priceLevelContext;
          self.priceLevelMetadata = priceLevelMetadata;
          self.priceLevel = priceLevel;

          self.isActivable = !configOptionsService.get('priceLevelActivateButton').isDisabled();

          self.save = function () {
            if (self.priceLevelForm.$valid) {
              self.priceLevel.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $location.path('/financial/price_level')
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          self.saveAndNew = function () {
            if (self.priceLevelForm.$valid) {
              self.priceLevel.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
        }
      }
    )

    .controller('PriceLevelAddCtrl',

      function ($scope, priceLevel, priceLevelContext, priceLevelMetadata, PriceLevelAdd) {
        var self = this;
        PriceLevelAdd(self, $scope, priceLevel, priceLevelContext, priceLevelMetadata);
      }
    )

    .controller('PriceLevelEditCtrl',

      function ($scope, $location, $translate, notifyManager, priceLevel, priceLevelContext, priceLevelMetadata, PriceLevelAdd) {
        var self = this;
        PriceLevelAdd(self, $scope, priceLevel, priceLevelContext, priceLevelMetadata);

        self.save = function () {
          if (self.priceLevelForm.$valid) {
            self.priceLevel.$edit({priceLevelId: self.priceLevel.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $location.path('/financial/price_level')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        self.saveAndNew = function () {
          if (self.priceLevelForm.$valid) {
            self.priceLevel.$edit({priceLevelId: self.priceLevel.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $location.path('/financial/price_level/add')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
      }
    )

    .controller('PriceLevelGridCtrl',

      function ($scope, priceLevelContext, priceLevelMetadata, configOptionsService) {
        var self = this;
        self.metadata = priceLevelMetadata;
        self.context = priceLevelContext;

        self.isOverlapping = false;

        self.config = {
          urlRoute: '/financial/price_level',
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'id', width: 60, enableCellEdit: false, visible: false},
            {name: 'name'},
            {name: 'monday', width: 50, enableSorting: false},
            {name: 'tuesday', width: 50, enableSorting: false},
            {name: 'wednesday', width: 50, enableSorting: false},
            {name: 'thursday', width: 50, enableSorting: false},
            {name: 'friday', width: 50, enableSorting: false},
            {name: 'saturday', width: 50, enableSorting: false},
            {name: 'sunday', width: 50, enableSorting: false},
            {
              name: 'timeStart',
              width: 50,
              visible: false,
              enableCellEdit: false,
              cellFilter: "timePriceLevelFilter:row.entity"
            },
            {
              name: 'timeEnd',
              width: 50,
              visible: false,
              enableCellEdit: false,
              cellFilter: "timePriceLevelFilter:row.entity"
            },
            {name: 'description', width: 200, visible: false},
            {
              name: 'operate',
              actions: ['activate', 'edit', 'delete'],
              actionOptions: {
                'activate': {
                  isDisabled: configOptionsService.get('priceLevelActivateButton').isDisabled
                },
                'delete': {
                  isDisabled: configOptionsService.get('priceLevelDeleteButton').isDisabled
                }
              }
            }
          ]
        };
      }
    )

    .filter('timePriceLevelFilter', ['$filter', 'moment', function ($filter, moment) {
      return function (input, entity) {
        if (!entity || _.isUndefined(input)) return;
        if (!entity.id) {
          return "";
        }
        return moment.utc(input * 60 * 1000).format("HH:mm");
      }
    }])

    .component('gfPriceLevelTime', {
      template: require('../template/price_level_time.html'),
      bindings: {
        priceLevel: '='
      },
      controller: function () {
        var self = this;

        self.$onInit = function () {
          //timepickers
          self.priceLevel.timeStartDate = new Date();
          self.priceLevel.timeStartDate.setHours(Math.floor(self.priceLevel.timeStart / 60) % 24);
          self.priceLevel.timeStartDate.setMinutes(self.priceLevel.timeStart % 60);

          self.priceLevel.timeEndDate = new Date();
          self.priceLevel.timeEndDate.setHours(Math.floor(self.priceLevel.timeEnd / 60) % 24);
          self.priceLevel.timeEndDate.setMinutes(self.priceLevel.timeEnd % 60);
        };
        var previousStartValue;
        var previousEndValue;

        self.$doCheck = function () {
          var currentStartValue = self.priceLevel.timeStartDate;
          var currentEndValue = self.priceLevel.timeEndDate;
          if (previousStartValue !== currentStartValue) {
            self.priceLevel.timeStart = currentStartValue ? currentStartValue.getHours() * 60 + currentStartValue.getMinutes() : '';
            previousStartValue = currentStartValue;
          }
          if (previousEndValue !== currentEndValue) {
            self.priceLevel.timeEnd = currentEndValue ? currentEndValue.getHours() * 60 + currentEndValue.getMinutes() : '';
            previousEndValue = currentEndValue;
          }
        }
      }
    })
})();
