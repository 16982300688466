(function () {
  'use strict';
  angular.module('gf.large.bills.amount', [])


    .controller('CustomerAddInfoCtrl', function ($scope, $http, notifyManager, $translate) {
      var self = this;

      self.$onInit = function () {
      };

      self.save = function () {
        if (self.customerInfoForm.$valid) {
          if (!self.isNew) {
            $http.put('/icash/customer/customer/' + self.customer.id + '.json', self.customer).then(
              function () {
                notifyManager.success($translate.instant('message.customers.edit.successfully'));
                self.modalSuccess(self.customer.number, self.customerCompany, self.customerDepartment);
              },
              function () {
                notifyManager.error($translate.instant('message.customers.edit.failed'));
              });
          } else {
            var customerGroup = {};
            customerGroup.name = self.customerGroup;

            if (_.isEmpty(self.customerGroups)) {
              $http.post('/icash/customer/customer_group', customerGroup).then(
                function (resp) {
                  self.customer.customerGroup = resp.data.payload.entity;
                  createCustomer(self.customer).then(
                    function () {
                      notifyManager.success($translate.instant('message.customers.create.successfully'));
                      self.modalSuccess(self.customer.number, self.customerCompany, self.customerDepartment);
                    },
                    function () {
                      notifyManager.error($translate.instant('message.customers.create.failed'));
                    }
                  )
                },
                function () {
                  notifyManager.error($translate.instant('message.customers.create.failed'));
                });
            } else {
              self.customer.customerGroup = self.customerGroup;
              createCustomer(self.customer).then(
                function () {
                  notifyManager.success($translate.instant('message.customers.create.successfully'));
                  self.modalSuccess(self.customer.number, self.customerCompany, self.customerDepartment);
                },
                function () {
                  notifyManager.error($translate.instant('message.customers.create.failed'));
                }
              )
            }
          }
        } else {
          notifyManager.error($translate.instant('entity.form.not.valid'));
          $scope.$broadcast('gf-submitted');
        }
      };


      function createCustomer(customer) {
        return $http.post('/icash/customer/customer.json', customer)
      }

      self.cancel = function () {
        $uibModalInstance.dismiss();
      };
    })

    .controller('CustomerChooseCtrl', function ($scope, $http, $uibModalInstance, countriesAndCustomerGroups, isPossibleToSkip, customerMetadata, LargeAmountBillsService) {
      var self = this;

      self.countriesAndCustomerGroups = countriesAndCustomerGroups;
      self.metadata = customerMetadata;
      self.isPossibleToSkip = isPossibleToSkip;

      self.getEmptyCustomer = LargeAmountBillsService.getCustomerWithInvoiceAddress();


      self.tabIndex = 0;

      self.isNotFilled = false;

      self.onCustomerSelect = function (item) {
        $http.get('/icash/customer/customer/' + item.id + '.json').then(function (resp) {
          self.isNotFilled = true;
          self.choosenCustomer = LargeAmountBillsService.getCustomerWithInvoiceAddress(resp.data);
        });
      };

      self.autocomplete = function (val) {
        return $http.get('/icash/customer/autocomplete', {params: {term: val}}).then(function (resp) {
          return resp.data;
        });
      };

      self.modalSuccess = function (customerNumber, customerCompany, customerDepartment) {
        $uibModalInstance.close({
          customerNumber: customerNumber,
          customerCompany: customerCompany,
          customerDepartment: customerDepartment
        })
      };

      self.customerUnknown = function () {
        $uibModalInstance.close({
          customerNumber: '$$$UNKNOWN$$$'
        });
      };

      self.cancel = function () {
        $uibModalInstance.dismiss();
      };

      self.hideForm = function () {
        self.isNotFilled = false;
      };

      self.skip = function () {
        $uibModalInstance.close({
          customerNumber: self.customer ? self.customer.customer.number : null
        })
      };
    })

    .controller('CustomerInfoCtrl', function ($scope, $http, $uibModalInstance, countriesAndCustomerGroups, isPossibleToSkip, customerMetadata, customerWithAddress) {
      var self = this;

      self.customer = customerWithAddress;

      self.countriesAndCustomerGroups = countriesAndCustomerGroups;
      self.isPossibleToSkip = isPossibleToSkip;
      self.metadata = customerMetadata;

      self.cancel = function () {
        $uibModalInstance.dismiss();
      };

      self.skip = function () {
        $uibModalInstance.close({
          customerNumber: self.customer ? self.customer.customer.number : null
        })
      };

      self.modalSuccess = function (customerNumber, customerCompany, customerDepartment) {
        $uibModalInstance.close({
          customerNumber: customerNumber,
          customerCompany: customerCompany,
          customerDepartment: customerDepartment
        })
      };
    })

    .controller('NotificationCtrl', function ($uibModalInstance, isPossibleToSkip) {
      var self = this;

      self.isPossibleToSkip = isPossibleToSkip;

      self.skip = function () {
        $uibModalInstance.close({
          customerNumber: null
        })
      };

      self.ok = function () {
        $uibModalInstance.close( {
          customerNumber: '$$$UNKNOWN$$$'
        })
      };

      self.cancel = function () {
        $uibModalInstance.dismiss();
      };
    })

    .component('customerAddInfo', {
      template: require('../template/report/customerInfoForm.html'),
      controller: 'CustomerAddInfoCtrl',
      bindings: {
        isNew: '<',
        customer: '<',
        invoiceAddress: '<',
        countries: '<',
        customerGroups: '<',
        metadataNew: '<',
        modalSuccess: '<',
        tabIndex: '<'
      }
    })

    .factory('LargeAmountBillsService',
      function ($http, $uibModal, $q, SecurityContext) {
        var BILL_TOTAL_THRESHOLD = 250;

        var emptyCustomer = {
          number: null,
          firstName: null,
          lastName: null,
          addresses: []
        };

        var emptyAddress = {
          building: null,
          byDefault: true,
          city: null,
          country: null,
          invoice: true,
          street: null,
          zip: null,
        };

        var config = [
          {
            reportId: 98,
            isDebitor: false,
            hasBill: true,
            hasCustomer: false
          },
          {
            reportId: 114,
            isDebitor: true,
            hasBill: true,
            hasCustomer: false
          },
          {
            reportId: 118,
            isDebitor: true,
            hasBill: false,
            hasCustomer: true
          },
          {
            reportId: 178,
            isDebitor: true,
            hasBill: true,
            hasCustomer: false

          },
          {
            reportId: 181,
            isDebitor: true,
            hasBill: false,
            hasCustomer: true
          },

        ];

        function getCountriesAndCustomerGroups() {
          return $http.get('/icash/customer/customer/context.json').then(
            function (resp) {
              return {
                countries: resp.data.data.dependencies.country,
                customerGroups: resp.data.data.dependencies.customerGroup
              }
            })
        }

        function getCustomerBillsReportLink(params) {
          return $http.get('/icash/report/jr/CUSTOMER_BILLS/json', {params: params}).then(function (resp) {
            return resp.data.payload.location;
          })
        }

        function getCustomerBills(link) {
          return $http.get(new URL(link).pathname)
            .then(function (resp) {
              return {
                customer: resp.data.customer,
                bills: resp.data.bills
              };
            });
        }

        function isTableTotalMoreThenThreshold(bills, isDebitor) {
          let result = _.find(bills, function (v) {
            return v.tableTotal >= BILL_TOTAL_THRESHOLD && v.isDebitor === isDebitor;
          });
          return !!result;
        }

        function shouldBeProcessed(bills, isDebitor) {
          var result = _.find(bills, function (v) {
            return v.isDebitor === isDebitor;
          });

          return !!result;
        }

        function isReportDebitor(reportId) {
          var result = _.find(config, function (v) {
            return v.reportId === reportId;
          });
          return result.isDebitor;
        }

        function isCustomerAttached(customer) {
          return !!customer;
        }

        function isCustomerFilled(customer, address) {
          return !!(customer.firstName && customer.lastName && address.city && address.street && address.building && address.zip && address.country);
        }

        function getCustomerMetadata() {
          return $http.get('/icash/customer/customer/customer_info_metadata.json').then(
            function (resp) {
              return resp.data;
            })
        }

        function addCustomerInfo(isNewCustomer, customer, invoiceAddress, isPossibleToSkip) {
          return $uibModal.open({
            template: require('../template/report/customerInfoModal.html'),
            controller: 'CustomerInfoCtrl',
            controllerAs: 'customerInfoCtrl',
            windowClass: 'gf-modal',
            resolve: {
              customerWithAddress: {
                customer: customer,
                address: invoiceAddress,
              },
              countriesAndCustomerGroups: function () {
                return getCountriesAndCustomerGroups().then(
                  function (result) {
                    return result
                  }
                )
              },
              isNewCustomer: isNewCustomer,
              isPossibleToSkip: isPossibleToSkip,
              customerMetadata: function () {
                return getCustomerMetadata().then(
                  function (result) {
                    return result
                  }
                );
              }
            }
          }).result;
        }

        function getInvoiceAddress(customer) {
          if (customer) {
            var address = _.find(customer.addresses, function (v) {
              return v.invoice === true;
            });

            if (!address) {
              address = _.cloneDeep(emptyAddress);
              customer.addresses.push(address);
            }
          }
          return address;
        }

        function checkAndFillCustomerInfo(customer, isPossibleToSkip) {
          var defer = $q.defer();
          var isNewCustomer = _.isEmpty(customer);

          var customerWithInvoiceAddress = getCustomerWithInvoiceAddress(customer);
          customer = customerWithInvoiceAddress.customer;
          var invoiceAddress = customerWithInvoiceAddress.address;

          addCustomerInfo(isNewCustomer, customer, invoiceAddress, isPossibleToSkip)
            .then(
              function (result) {
                defer.resolve(result);
              },
              function () {
                defer.reject()
              });
          // }

          return defer.promise;
        }

        function getCustomer(customer) {
          return customer ? customer : _.cloneDeep(emptyCustomer);
        }

        function getCustomerWithInvoiceAddress(customer) {
          customer = getCustomer(customer);
          var address = getInvoiceAddress(customer);

          return {
            'customer': customer,
            'address': address,
          }
        }

        function chooseCustomerAction(isPossibleToSkip) {
          return $uibModal.open({
            template: require('../template/report/customerChooseModal.html'),
            controller: 'CustomerChooseCtrl',
            controllerAs: 'customerChooseCtrl',
            windowClass: 'gf-modal',
            resolve: {
              countriesAndCustomerGroups: function () {
                return getCountriesAndCustomerGroups().then(
                  function (result) {
                    return result
                  }
                )
              },
              isPossibleToSkip: isPossibleToSkip,
              customerMetadata: function () {
                return getCustomerMetadata().then(
                  function (result) {
                    return result
                  }
                );
              }
            }
          }).result;
        }

        function chooseCustomer(isPossibleToSkip) {
          var defer = $q.defer();

          chooseCustomerAction(isPossibleToSkip).then(
            function (result) {
              defer.resolve(result);
            },
            function () {
              defer.reject();
            }
          );

          return defer.promise;
        }

        function isCustomerFeatureActivated() {
          return _.indexOf(SecurityContext.features, 'CUSTOMER') !== -1;
        }

        function isUserHasPermissions() {
          var result = _.find(['ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_CUSTOMER_DATA'], function (v) {
            return _.indexOf(SecurityContext.roles, v) !== -1;
          });
          return !!result;
        }

        function notificationModal(isPossibleToSkip) {
          return $uibModal.open({
            template: require('../template/report/notificationModal.html'),
            controller: 'NotificationCtrl',
            controllerAs: 'notificationCtrl',
            windowClass: 'gf-modal',
            resolve: {
              isPossibleToSkip: isPossibleToSkip,
            }
          }).result;
        }

        function getPreHandleReportResult(result, isReloadReportLink) {
          return {
            customerNumber: result.customerNumber,
            customerCompany: result.customerCompany,
            customerDepartment: result.customerDepartment,
            isReloadReportLink: isReloadReportLink
          }
        }

        return {

          preHandleReportRequest: function (params, reportId) {
            var defer = $q.defer();
            getCustomerBillsReportLink(params)
              .then(
                function (link) {
                  getCustomerBills(link)
                    .then(
                      function (result) {
                        var customer = result.customer;
                        var bills = result.bills;
                        var isDebitor = isReportDebitor(reportId);

                        if (!shouldBeProcessed(bills, isDebitor)) {
                          defer.resolve(getPreHandleReportResult({customerNumber: customer ? customer.number : null}, false));
                          return;
                        }

                        var isPossibleToSkip = !isTableTotalMoreThenThreshold(bills, isDebitor);

                        if (!isCustomerFeatureActivated() || !isUserHasPermissions()) {
                          notificationModal(isPossibleToSkip).then(
                            function (result) {
                              defer.resolve(getPreHandleReportResult(result, true));
                            },
                            function () {
                              defer.reject();
                            }
                          );
                          return;
                        }

                        if (isCustomerAttached(customer)) {
                          checkAndFillCustomerInfo(customer, isPossibleToSkip).then(
                            function (result) {
                              defer.resolve(getPreHandleReportResult(result, true));
                            },
                            function () {
                              defer.reject();
                            })

                        } else {
                          chooseCustomer(isPossibleToSkip).then(
                            function (result) {
                              defer.resolve(getPreHandleReportResult(result, true));
                            },
                            function () {
                              defer.reject();
                            })
                        }
                      },
                      function () {
                        defer.reject();
                      })
                },
                function () {
                  defer.reject();
                });

            return defer.promise;
          },


          getCustomerWithInvoiceAddress: function (customer) {
            return getCustomerWithInvoiceAddress(customer)
          },

          isCustomerFilled: function (customerWithAddress) {
            return isCustomerFilled(customerWithAddress.customer, customerWithAddress.address);
          },

          isCustomerOrBill: function (bill, customer, reportId) {
            var result = _.find(config, function (v) {
              if (v.reportId !== reportId)
                return false;

              if (v.hasBill)
                return v.hasBill === !!bill;
              if (v.hasCustomer)
                return v.hasCustomer === !!customer;
              return false;
            });

            return !!result;
          }
        }
      }
    )
})
();
