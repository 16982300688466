(function () {
  'use strict';
  angular.module('gf.bm.vat', ['gf.bm.groupView'])

    .factory('BMVatMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/vat/metadata.json')
    })
    .factory('BMVatContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/vat/context.json')
    })
    .factory('BMVatRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.financialVat', {
          url: '/financial/vat',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMFinancialVat',
          controllerAs: '$ctrl',
          resolve: {
            bmVatMetadata: function (BMVatMetadata, $stateParams, securityService) {
              return BMVatMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmVatContext: function (BMVatContext, $stateParams, securityService) {
              return BMVatContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmVatRules: function (BMVatRules, $stateParams, securityService) {
              return BMVatRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "financialVat"
        })
    })

    .controller('BMFinancialVat',
      function (bmVatMetadata, bmVatContext, securityService, $stateParams, bmVatRules) {
        var self = this;

        self.metadata = bmVatMetadata;
        self.context = bmVatContext;
        self.masterdataRules = bmVatRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name', enableCellEdit: false},
            {name: 'value', enableCellEdit: false},
            {name: 'mark', enableCellEdit: false},
            {name: 'vatAccount', enableCellEdit: false},
            {name: 'account', enableCellEdit: false},
            {name: 'description', enableCellEdit: false}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/vat';
          },
          isAbleToActivate: function () {
            return securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'BM_TAX_RATE_ACTIVATE_DEACTIVATE');
          }
        };

      }
    )

})();
