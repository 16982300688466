(function() {
  'use strict';
  angular.module('gf.setup.articleType', ['ui.router'])

    .factory('ArticleTypeMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/article_type/metadata.json')
    })
    .factory('ArticleTypeContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/article_type/context.json')
    })
    .factory('ArticleType', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/article_type/:articleTypeId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })

    .config(function ($stateProvider) {

        $stateProvider
          .state('page.setupArticle_type', {
            url: '/setup/article_type',
            template: require('../template/setup/article-type/article_types.html'),
            controller: 'ArticleTypeGridCtrl',
            controllerAs: 'articleTypeGridCtrl',
            resolve: {
              articleTypeMetadata: function (ArticleTypeMetadata) {
                return ArticleTypeMetadata.get().$promise;
              },
              articleTypeContext: function (ArticleTypeContext) {
                return ArticleTypeContext.get().$promise;
              }
            },
            title: 'articleType'
          })

          .state('page.setupArticle_typeAdd', {
            url: '/setup/article_type/add',
            template: require('../template/setup/article-type/article_type.html'),
            controller: 'ArticleTypeAddCtrl',
            controllerAs: 'articleTypeCtrl',
            resolve: {
              articleTypeMetadata: function (ArticleTypeMetadata) {
                return ArticleTypeMetadata.get().$promise;
              },
              articleTypeContext: function (ArticleTypeContext) {
                return ArticleTypeContext.get().$promise;
              },
              articleType: function (ArticleType, $stateParams) {
                return ArticleType.load({articleTypeId: $stateParams.id || "add"}).$promise;
              }
            },
            title: 'articleType'
          })

          .state('page.setupArticle_typeDetails', {
            url: '/setup/article_type/:id',
            template: require('../template/setup/article-type/article_type.html'),
            controller: 'ArticleTypeEditCtrl',
            controllerAs: 'articleTypeCtrl',
            resolve: {
              articleTypeMetadata: function (ArticleTypeMetadata) {
                return ArticleTypeMetadata.get().$promise;
              },
              articleTypeContext: function (ArticleTypeContext) {
                return ArticleTypeContext.get().$promise;
              },
              articleType: function (ArticleType, $stateParams) {
                return ArticleType.load({articleTypeId: $stateParams.id || 'add'}).$promise;
              }
            },
            title: 'articleType'
          })
      }
    )

    .factory('ArticleTypeAdd', [
      function () {
        return function (self, $scope, $state, $http, $translate, notifyManager, articleType, articleTypeContext, articleTypeMetadata) {
          self.articleTypeContext = articleTypeContext;
          self.articleTypeMetadata = articleTypeMetadata;
          self.articleType = articleType;

          self.save = function () {
            if (self.articleTypeForm.$valid) {
              self.articleType.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go('page.setupArticle_type')
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          self.saveAndNew = function () {
            if (self.articleTypeForm.$valid) {
              self.articleType.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
        }
      }
    ])
    .controller('ArticleTypeAddCtrl',

      function ($scope, $state, $http, $translate, notifyManager, articleType, articleTypeContext, articleTypeMetadata, ArticleTypeAdd) {
        var self = this;
        ArticleTypeAdd(self, $scope, $state, $http, $translate, notifyManager, articleType, articleTypeContext, articleTypeMetadata);
      }
    )
    .controller('ArticleTypeEditCtrl',

      function ($scope, $state, $http, $translate, notifyManager, articleType, articleTypeContext, articleTypeMetadata, ArticleTypeAdd) {
        var self = this;
        ArticleTypeAdd(self, $scope, $state, $http, $translate, notifyManager, articleType, articleTypeContext, articleTypeMetadata);

        self.save = function () {
          if (self.articleTypeForm.$valid) {
            self.articleType.$edit({articleTypeId: self.articleType.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.setupArticle_type');
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        self.saveAndNew = function () {
          if (self.articleTypeForm.$valid) {
            self.articleType.$edit({articleTypeId: self.articleType.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.setupArticle_typeAdd');
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

      }
    )
    .controller('ArticleTypeGridCtrl',

      function (articleTypeContext, articleTypeMetadata, securityService, configOptionsService) {
        var self = this;

        self.metadata = articleTypeMetadata;
        self.context = articleTypeContext;

        var cellClass = function (grid, row, col, rowRenderIndex, colRenderIndex) {
          if (row && row.entity && row.entity.id && row.entity.hidden) {
            return 'hidden-entity';
          }
        };

        var columns = [
          {name: 'id', width: 60, enableCellEdit: false, visible: false, cellClass: cellClass},
          {name: 'name', width: 230, cellClass: cellClass},
          {name: 'info', width: 70, cellClass: cellClass},
          {name: 'fixedPrice', width: 139, cellClass: cellClass},
          {name: 'fixedName', width: 142, cellClass: cellClass},
          {name: 'constraintOnly', width: 153, cellClass: cellClass}];

        if (securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_ARTICLE_NON_TURNOVER')) {
          columns.push({
            name: 'nonTurnover', width: 170,
            cellClass: cellClass
          });

        }
        columns.push({
          name: 'voucher',
          width: 100,
          cellClass: cellClass
        });
        columns.push({
          name: 'measure',
          width: 117,
          cellClass: cellClass,
          validations: {
            "gf-measureflagforarticletype": {
              'angular': {'gf-measureflagforarticletype': ''},
              'errorMessage': "de.icash.validator.measureFlagForArticleType"
            }
          }
        });
        columns.push({name: 'description', visible: false, width: 250, cellClass: cellClass});
        columns.push({
          name: 'operate',
          actions: ['edit', 'delete'],
          actionOptions: {
            'delete': {
              isDisabled: configOptionsService.get('articleTypeDeleteButton').isDisabled
            }
          }
        });

        self.config = {
          urlRoute: '/setup/article_type',
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          cellEditableCondition: function ($scope) {
            return !$scope.row.entity.hidden;
          },
          hideActions: function (entity) {
            return entity.hidden;
          },
          columns: columns
        };

      }
    )
})();
