import React from "react";
import {Chip} from "@material-ui/core";
import {useTranslation} from 'react-i18next';

export const shapes = {
  success: {
    background: '#D8FCDC',
    borderColor: '#88F398',
  },
  failed: {
    background: '#FDD6D7',
    borderColor: '#F58286',
  },
  warning: {
    background: '#FFEDD4',
    borderColor: '#FCC37A',
  },
  info: {
    background: '#D6DFFD',
    borderColor: '#829FF5',
  },
  unknown: {
    background: '#D7DCE1',
    borderColor: '#A3ABB3',
  },
}

function LSChip({size, type, color, label}) {
  const {t} = useTranslation();
  return <Chip size={size || 'small'} variant="outlined" label={label || t(type)}
               style={{background: color.background, borderColor: color.borderColor}}/>
}

export default LSChip
