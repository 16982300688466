(function () {
  'use strict';
  angular.module('gf.bm.costCenter', ['gf.bm.groupView'])

    .factory('BMCostCenterMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/cost_center/metadata.json')
    })
    .factory('BMCostCenterContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/cost_center/context.json')
    })
    .factory('BMCostCenterRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.financialCostCenter', {
          url: '/financial/cost_center',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMFinancialCostCenter',
          controllerAs: '$ctrl',
          resolve: {
            bmCostCenterMetadata: function (BMCostCenterMetadata, $stateParams, securityService) {
              return BMCostCenterMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmCostCenterContext: function (BMCostCenterContext, $stateParams, securityService) {
              return BMCostCenterContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmCostCenterRules: function (BMCostCenterRules, $stateParams, securityService) {
              return BMCostCenterRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "financialCostCenter"
        })
    })

    .controller('BMFinancialCostCenter',
      function (bmCostCenterMetadata, bmCostCenterContext, securityService, $stateParams, bmCostCenterRules) {
        var self = this;

        self.metadata = bmCostCenterMetadata;
        self.context = bmCostCenterContext;
        self.masterdataRules = bmCostCenterRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name', enableCellEdit: false},
            {name: 'description', enableCellEdit: false}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/cost_center';
          },
          isAbleToActivate: function () {
            return securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'BM_COST_CENTER_ACTIVATE_DEACTIVATE');
          }
        };

      }
    )

})();
