var PaypalCtrlResolve = {
  paypalData: ["PaypalData", "$stateParams", function (PaypalData, $stateParams) {
    return PaypalData.load({belongsTo: $stateParams.belongsTo}).$promise;
  }],
  paypalDataContext: ["PaypalDataContext", "$stateParams", function (PaypalDataContext, $stateParams) {
    return PaypalDataContext.get({belongsTo: $stateParams.belongsTo}).$promise;
  }]

};

(function () {
  'use strict';
  angular.module('gf.features', [])

    .run(function ($templateCache) {
      $templateCache.put('app/template/trial/POS_LEDGER/feature_trial_modal.html', require('./../template/trial/POS_LEDGER/feature_trial_modal.html'));
      $templateCache.put('app/template/trial/POS_LEDGER/feature_trial_modal_de.html', require('./../template/trial/POS_LEDGER/feature_trial_modal_de.html'));
    })

    .config(function ($stateProvider) {
      $stateProvider
        .state('page.featureBelongsToPaypal', {
          url: '/feature/:belongsTo/paypal',
          template: require('../template/page/feature/paypal.html'),
          controller: 'PaypalCtrl',
          controllerAs: 'paypalCtrl',
          resolve: PaypalCtrlResolve,
          title: 'paypal'
        });
    })
    .controller('PaypalCtrl',

      function ($scope, $http, $resource, Upload, notifyManager, PaypalData, paypalData, paypalDataContext,
                $timeout, $translate, securityService) {

        var self = this;

        self.paypalData = paypalData;
        angular.extend(self, paypalDataContext.payload);


        self.prepareOpenHours = function () {
          self.openHours = {};
          angular.forEach(self.paypalData.openHours, function (openHours) {
            self.openHours[openHours.day] = openHours;
          });

          for (var i = 1; i < 8; i++) {
            if (!self.openHours[i]) {
              self.openHours[i] = {day: i, active: true, from: 480, to: 1380};
              self.paypalData.openHours.push(self.openHours[i]);
            }

            self.openHours[i].fromDate = new Date();
            self.openHours[i].fromDate.setHours(Math.floor(self.openHours[i].from / 60) % 24);
            self.openHours[i].fromDate.setMinutes(self.openHours[i].from % 60);

            self.openHours[i].toDate = new Date();
            self.openHours[i].toDate.setHours(Math.floor(self.openHours[i].to / 60) % 24);
            self.openHours[i].toDate.setMinutes(self.openHours[i].to % 60);
          }
        };
        self.prepareOpenHours();
        for (var i = 1; i < 8; i++) {
          $scope.$watch("[paypalCtrl.openHours[" + i + "].fromDate, paypalCtrl.openHours[" + i + "].day]", function (value) {
            self.openHours[value[1]].from = value[0].getHours() * 60 + value[0].getMinutes();
          }, true);

          $scope.$watch("[paypalCtrl.openHours[" + i + "].toDate, paypalCtrl.openHours[" + i + "].day]", function (value) {
            self.openHours[value[1]].to = value[0].getHours() * 60 + value[0].getMinutes();
          }, true);
        }

        $scope.$watch("paypalCtrl.paypalData.restaurantAddress.country.id", function (value) {
          self.regions = self.regionsByCountry[value];
        });


        var PaypalLocation = $resource('/icash/feature/:restaurantId/paypal/location', {restaurantId: self.paypalData.belongsTo}, {
          load: {method: 'GET'},
          edit: {method: 'PUT'},
          save: {method: 'POST'},
          remove: {method: 'DELETE'}
        });

        PaypalLocation.load(function (data) {
          $scope.safeApply(function () {
            self.paypalLocation = data;
          });
        });


        self.refreshLocation = function () {
          PaypalLocation.edit(function (data) {
            if (data.ok) {
              self.paypalLocation = data.payload.location;
            } else {
              $translate(data.message).then(function (message) {
                notifyManager.error(message);
              });
            }
          });

        };

        self.createLocation = function () {
          PaypalLocation.save(function (data) {
            if (data.ok) {
              self.paypalLocation = data.payload.location;
              self.paypalData = new PaypalData(data.payload.form);
            } else {
              $translate(data.message).then(function (message) {
                notifyManager.error(message);
              });
            }
          });
        };

        self.deleteLocation = function () {
          PaypalLocation.remove(function (data) {
            if (data.ok) {
              self.paypalLocation = null;
              self.paypalData = new PaypalData(data.payload.form);
            } else {
              $translate(data.message).then(function (message) {
                notifyManager.error(message);
              });
            }
          });
        };

        self.paypalLocationId = function () {
          if (self.paypalData.paypalLiveMode) {
            return self.paypalData.paypalMerchantLocation;
          } else {
            return self.paypalData.paypalTestMerchantLocation;
          }
        };

        self.uploadPaypalLogo = function () {
          $http.post("/icash/feature/" + self.paypalData.belongsTo + "/paypal/upload_logo", {}, function (data) {
            if (data.ok) {
              self.paypalLocation = data.payload.location;
            } else {
              $translate(data.message).then(function (message) {
                notifyManager.error(message);
              });
            }
          });
        };

        self.onFileSelect = function ($files, filename) {
          self[filename + "Show"] = true;
          for (var i = 0; i < $files.length; i++) {
            var file = $files[i];
            self.upload = Upload.upload({
              url: '/icash/feature/image/upload?name=' + filename,
              method: 'POST',
              file: file,
              fileFormDataName: 'file'
            })
              .progress(function (evt) {
                  $("#" + filename + "Progress").css({width: parseInt(100.0 * evt.loaded / evt.total) + "%"});
                  $("#" + filename + "Progress").text(parseInt(100.0 * evt.loaded / evt.total) + " %");
                }
              );
          }
        };

        self.reset = function () {
          self.paypalIconShow = false;
          $("#paypalIconProgress").css({width: "0%"});
          self.paypalLogoShow = false;
          $("#paypalLogoProgress").css({width: "0%"});
          self.paypalWallpaperShow = false;
          $("#paypalWallpaperProgress").css({width: "0%"});

          $http({
            url: '/icash/feature/image/reset',
            method: 'DELETE'
          });
        };

        self.imageUpdated = new Date().getTime();

        $http({
          url: '/icash/feature/image/reset',
          method: 'DELETE'
        });

        self.save = function () {
          if ($scope.paypalDataForm.$valid) {
            self.paypalData.$save({belongsTo: self.paypalData.belongsTo}, function (data) {

              if (data.ok) {
                notifyManager.success($translate.instant('entity.edit.successfully'));

                self.paypalData = new PaypalData(data.payload.form);

                self.imageUpdated = new Date().getTime();
                self.reset();

                self.prepareOpenHours();

              } else {
                //todo improve validation on this form
                notifyManager.error($translate.instant('validate.error'));
                self.paypalData = new PaypalData(data.payload.form);
              }
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

        self.back = function () {
          if (securityService.context.currentRestaurant.id === $scope.paypalData.belongsTo) {
            $scope.go('/setup/restaurant#5');
          } else {
            $scope.go('/operator_data/restaurant/' + $scope.paypalData.belongsTo + "#5");
          }
        };

        self.unlink = function (t) {
          var test = !!t;

          $http.put("/icash/feature/paypal/unlink?test=" + test)
            .then(function (resp) {
              if (resp.data.ok) {
                angular.extend(self, resp.data.payload);
              }
            });
        };

        self.onCountryChanged = function () {
          // uiGmapGoogleMapApi.then(function (maps) {
          //   angular.forEach(self.countries, function (country) {
          //     if (country.id === $scope.paypalData.restaurantAddress.country.id) {
          //       self.paypalData.latitude = country.latitude;
          //       self.paypalData.longitude = country.longitude;
          //
          //       self.marker.control.getGMarkers()[0].setPosition(new maps.LatLng(restaurant.latitude, restaurant.longitude, true));
          //       self.map.control.refresh(paypalData);
          //     }
          //   });
          // });
        };

        $scope.onCountryRegionChanged = function () {
          // uiGmapGoogleMapApi.then(function (maps) {
          //   angular.forEach($scope.regionsByCountry[self.paypalData.restaurantAddress.country.id], function (region) {
          //     if (region.id === $scope.paypalData.restaurantAddress.region.id) {
          //       self.paypalData.latitude = region.latitude;
          //       self.paypalData.longitude = region.longitude;
          //
          //       self.marker.control.getGMarkers()[0].setPosition(new maps.LatLng(restaurant.latitude, restaurant.longitude, true));
          //       self.map.control.refresh(paypalData);
          //     }
          //   });
          // });
        };

        // $scope.updateMap = function (paypalData) {
        //   uiGmapGoogleMapApi.then(function (maps) {
        //     $scope.marker.control.getGMarkers()[0].setPosition(new maps.LatLng(restaurant.latitude, restaurant.longitude, true));
        //     $scope.map.control.refresh(paypalData);
        //   });
        // }
      }
    )

    .component('featureSetting', {
      template: require('../template/feature_setting.html'),
      bindings: {
        setting: '<',
        feature: '=',
        restaurant: '<'
      },
      controller: function () {
        var self = this;
        self.$onInit = function () {
          self.setting.$gfDirty = true;
          self.setting.prepareSettings(self)
            .then(function () {
              self.prepared = true;
            });
        };
        self.$onDestroy = function () {
          delete self.setting.$gfDirty;
        }
      }
    })

    .factory('featureSettingsService', function ($q) {
      return {
        saveAllSettings: function (featureSettings, operatorId) {
          var saveSettings = _.filter(featureSettings, {$gfDirty: true});
          var allPromises = [];
          _.forEach(saveSettings, function (setting) {
            allPromises.push(setting.savePromise(operatorId));

          });
          return $q.all(allPromises);
        }
      };
    })

    .component('featureTrialModal', {
      template: '<div ng-include="$ctrl.templateUrl"></div>',
      bindings: {
        modalInstance: '<',
        resolve: '='
      },
      controller: function ($http, notifyManager, $translate, securityService, $state, $rootScope) {
        var self = this;

        self.termsOfUseLink = '/client/documents/trial_terms_of_use.pdf';
        self.instructionsLink = '/client/documents/cashbook/Handbuch%20-%20Kassenbuch.pdf';
        self.brand = _.startCase(securityService.context.theme.name);

        self.$onInit = function () {
          self.featurePrice = self.resolve.feature.price;
          self.featureName = self.resolve.feature.name;
          self.trialPeriod = self.resolve.feature.trialPeriod;
          self.trialDurationUnit = self.resolve.feature.trialDurationUnit;

          self.templateUrl = 'app/template/trial/' + self.featureName + '/feature_trial_modal.html';
          if (securityService.context.currentLanguageTag === 'de') {
            self.templateUrl = 'app/template/trial/' + self.featureName + '/feature_trial_modal_de.html';
          }

          self.modalInstance.result.then(
            function (feature) {
              if (self.doNotShowForUser) {
                $http.post('/icash/feature_trial/' + feature + '/do_not_show');
              }
            }
          )
        };

        self.getRestrictions = function (feature) {
          $http.post('/icash/feature_trial/' + feature + '/restriction')
            .then(function (res) {
              self.restrictions = res.data;
              self.doNotShowForUser = false;
              self.goToStep(2);
            })
        };

        self.confirmTrial = function (featureName) {
          $http
            .post('/icash/feature_trial/' + featureName)
            .then(function (response) {
              $rootScope.$broadcast('activatedTrial', response.data);
              securityService.refreshContext();
              notifyManager.success($translate.instant('featureTrial.activate.successfully'));
            }, function () {
              notifyManager.error($translate.instant('error.general'));
            });
          self.goToStep(3);
        };

        self.goToStep = function (stepNr) {
          self.stepNr = stepNr;
        };

        self.goToWelcome = function () {
          $state.go('page.welcome');
          self.modalInstance.close();
        }
      },
      controllerAs: '$ctrl',
      backdrop: 'static'
    })

    .component('trialDeactivateModal', {
      template: require('../template/trial/deactivate_feature_trial_modal.html'),
      bindings: {
        modalInstance: '<',
        resolve: '='
      },
      controller: function ($http, $translate, notifyManager, securityService, $rootScope) {
        var self = this;

        self.$onInit = function () {
          self.featureName = self.resolve.featureName;
          self.modalInstance.result.then(
            function (featureName) {
              $http
                .post('/icash/feature_trial/' + featureName + '/deactivate')
                .then(function (response) {
                  $rootScope.$broadcast('deactivatedTrial', response.data);
                  securityService.refreshContext();
                  notifyManager.success($translate.instant('featureTrial.deactivate.successfully'));
                }, function () {
                  notifyManager.error($translate.instant('error.general'));
                })
            }
          )
        }
      },
      controllerAs: '$ctrl',
      backdrop: 'static'
    })

    .component('gfTrialButton', {
      template: require('../template/trial/feature_trial_button.html'),
      bindings: {
        trial: '=',
        feature: '<'
      },
      controller: function ($uibModal) {
        var self = this;

        self.$onInit = function () {
          self.showTrialPopup = !self.trial;
        };

        self.manageTrial = function () {
          if (self.showTrialPopup && !self.feature.selected) {
            //if trial is disabled - show trial feature modals the same as after the login
            $uibModal.open({
              component: 'featureTrialModal',
              resolve: {
                feature: function () {
                  return self.feature;
                }
              }
            })
          } else {
            //if trial is enabled - show modal window to disable it
            $uibModal.open({
              component: 'trialDeactivateModal',
              windowClass: 'gf-modal',
              resolve: {
                featureName: function () {
                  return self.feature.name;
                }
              }
            })
          }
        }
      }
    })
})();
