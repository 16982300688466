(function () {
  'use strict';
  angular.module('gf.hardware.printer', ['ui.router'])
    .factory('PrinterMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/printer/metadata.json')
    })
    .factory('PrinterContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/printer/context.json')
    })
    .factory('Printer', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/printer/:printerId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Restaurant', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/:restaurantId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('RestaurantSettings', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/:restaurantId/settings.json')
    })
    .factory('PrinterSettings', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/printer/printer_settings.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      })
    })
    .factory('PrinterSettingsContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/printer/printer_settings/context.json')
    })
    .factory('PrinterSettingsMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/printer/printer_settings/metadata.json')
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.printers', {
          url: '',
          abstract: true,
          views: {
            '': {
              template: require('../template/page/hardware/printer_tabs.html'),
              controller: 'PrintersCtrl',
              controllerAs: 'printersCtrl'
            }
          }
        })
        .state('page.printers.hardwarePrinter', {
          url: '/hardware/printer/printers_list',
          template: require('../template/page/hardware/printers.html'),
          controller: 'PrinterGridCtrl',
          controllerAs: 'printerGridCtrl',
          resolve: {
            printerMetadata: function (PrinterMetadata) {
              return PrinterMetadata.get().$promise;
            },
            printerContext: function (PrinterContext) {
              return PrinterContext.get().$promise;
            }
          },
          title: "printer"
        })
        .state('page.printers.printerDefaultSettings', {
          url: '/hardware/printer/printer_default_settings',
          template: require('../template/page/hardware/printer_default_settings.html'),
          controller: 'PrinterDefaultSettingsCtrl',
          controllerAs: 'printerDefaultSettingsCtrl',
          resolve: {
            printerSettings: ['PrinterSettings', function (PrinterSettings) {
              return PrinterSettings.get().$promise;
            }],
            printerSettingsContext: ['PrinterSettingsContext', function (PrinterSettingsContext) {
              return PrinterSettingsContext.get().$promise;
            }],
            printerSettingsMetadata: ['PrinterSettingsMetadata', function (PrinterSettingsMetadata) {
              return PrinterSettingsMetadata.get().$promise;
            }]
          },
          title: "printer"
        })
        .state('page.printers.hardwarePrinterAdd', {
          url: '/hardware/printer/add',
          template: require('../template/page/hardware/printer.html'),
          controller: 'PrinterAddCtrl',
          controllerAs: 'printerCtrl',
          resolve: {
            printerMetadata: function (PrinterMetadata) {
              return PrinterMetadata.get().$promise;
            },
            printerContext: function (PrinterContext) {
              return PrinterContext.get().$promise;
            },
            printer: function (Printer, $stateParams) {
              return Printer.load({printerId: $stateParams.id || "add"}).$promise;
            }
          },
          title: "printer"
        })
        .state('page.printers.hardwarePrinterDetails', {
          url: '/hardware/printer/:id',
          template: require('../template/page/hardware/printer.html'),
          controller: 'PrinterEditCtrl',
          controllerAs: 'printerCtrl',
          resolve: {
            printerMetadata: function (PrinterMetadata) {
              return PrinterMetadata.get().$promise;
            },
            printerContext: function (PrinterContext) {
              return PrinterContext.get().$promise;
            },
            printer: function (Printer, $stateParams) {
              return Printer.load({printerId: $stateParams.id || "add"}).$promise;
            }
          },
          title: "printer"
        })
    })
    .factory('PrinterAdd',
      function ($translate, $state, notifyManager, configOptionsService, $uibModal, securityService) {
        return function (self, $scope, printer, printerContext, printerMetadata) {
          self.printerContext = printerContext;
          self.printerMetadata = printerMetadata;
          self.printer = printer;

          function isTse() {
            return securityService.hasAnyFeature('EPSON_TSE') && printerContext.data.dependencies.tsePrinter.length
              && self.printer.id === printerContext.data.dependencies.tsePrinter[0].printer.id;
          };

          self.isActivable = !configOptionsService.get('printerActivateButton').isDisabled() && !isTse();

          angular.extend(self, printerContext.data.dependencies);
          if (isTse()) {
            self.printerDriver = _.filter(printerContext.data.dependencies.printerDriver, function (d) {
              return _.includes(d.name, 'Epson TM-m30')
            })
          }
          self.save = function () {
            if (self.printerForm.$valid) {
              self.printer.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go('page.printers.hardwarePrinter')
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          self.saveAndNew = function () {
            if (self.printerForm.$valid) {
              self.printer.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
        }
      }
    )
    .controller('PrintersCtrl',
      function ($location, $state) {
        var self = this;
        self.activeTab = $state.current.name === 'page.printers.hardwarePrinter' ? 0 : 1;
      }
    )
    .controller('PrinterDefaultSettingsCtrl',
      function ($scope, notifyManager, $state, $translate, printerSettings, printerSettingsContext,
                printerSettingsMetadata) {
        var self = this;

        self.dependencies = printerSettingsContext.data.dependencies;
        self.metadata = printerSettingsMetadata;
        self.printerSettings = printerSettings;
        self.multiselectDefaultSettings = $scope.multiselectDefaultSettings;
        self.multiselectText = $scope.multiselectText;

        self.save = function () {
          if (self.printerDefaultSettingsForm.$valid) {
            self.printerSettings.$edit(function () {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $state.reload();
            }, function () {
              notifyManager.error($translate.instant('settings.save.failed'));
            })
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

      }
    )
    .controller('PrinterAddCtrl',
      function ($scope, printer, printerContext, printerMetadata, PrinterAdd) {
        var self = this;
        PrinterAdd(self, $scope, printer, printerContext, printerMetadata);
      }
    )
    .controller('PrinterEditCtrl',
      function ($scope, $translate, $state, notifyManager, printer, printerContext, printerMetadata, PrinterAdd) {

        var self = this;
        PrinterAdd(self, $scope, printer, printerContext, printerMetadata);

        self.save = function () {
          if (self.printerForm.$valid) {
            self.printer.$edit({printerId: self.printer.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.printers.hardwarePrinter')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        self.saveAndNew = function () {
          if (self.printerForm.$valid) {
            self.printer.$edit({printerId: self.printer.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.printers.hardwarePrinterAdd');
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }

        };

      }
    )
    .controller('PrinterGridCtrl',
      function ($scope, $translate, printerContext, printerMetadata, configOptionsService, $http, securityService) {
        var self = this;

        self.metadata = printerMetadata;
        self.context = printerContext;

        function isTsePrinter(printer) {
          return securityService.hasAnyFeature('EPSON_TSE') && printerContext.data.dependencies.tsePrinter.length
            && printerContext.data.dependencies.tsePrinter[0].printer.id === printer.id;
        }

        var cellClass = function (grid, row) {
          if (row && row.entity && row.entity.id && isTsePrinter(row.entity)) {
            return 'outlet-entity';
          }
        };

        self.config = {
          urlRoute: '/hardware/printer/printers_list',
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'id', width: 110, visible: false, cellClass: cellClass},
            {name: 'name', cellClass: cellClass},
            {
              name: 'ipAddress', cellClass: cellClass, cellEditableCondition: function ($scope) {
                return !isTsePrinter($scope.row.entity)
              }
            },
            {
              name: 'printerDriver', cellClass: cellClass, cellEditableCondition: function ($scope) {
                return !isTsePrinter($scope.row.entity)
              }
            },
            {
              name: 'tcpPort', cellClass: cellClass, cellEditableCondition: function ($scope) {
                return !isTsePrinter($scope.row.entity)
              }
            },
            {
              name: 'udpPort', cellClass: cellClass, cellEditableCondition: function ($scope) {
                return !isTsePrinter($scope.row.entity)
              }
            },
            {name: 'description', visible: false, width: 250, cellClass: cellClass},
            {
              name: 'operate',
              actions: ['activate', 'edit', 'delete'],
              actionOptions: {
                'activate': {
                  isDisabled: configOptionsService.get('printerActivateButton').isDisabled,
                  isHidden: isTsePrinter
                },
                'delete': {
                  isDisabled: configOptionsService.get('printerDeleteButton').isDisabled,
                  isHidden: isTsePrinter
                }
              }
            }
          ]
        };

        function refreshContext() {
          $http.get('/icash/hardware/printer/context.json').then(function (res) {
            self.context = res.data;
          })
        }

        $scope.$on('changeActivation', function (event, opt) {
          if (!opt.active) {
            refreshContext();
          }
        });
        $scope.$on('deleteEntity', refreshContext);
      }
    )
})();
