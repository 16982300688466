(function () {
  'use strict';
  angular.module('gf.bm.article', ['gf.bm.groupView'])

    .factory('BMArticleMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/article/metadata.json')
    })
    .factory('BMArticleContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/article/context.json')
    })
    .factory('BMArticleRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.masterdataArticle', {
          url: '/masterdata/article',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMMasterdataArticle',
          controllerAs: '$ctrl',
          resolve: {
            bmArticleMetadata: function (BMArticleMetadata, $stateParams, securityService) {
              return BMArticleMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmArticleContext: function (BMArticleContext, $stateParams, securityService) {
              return BMArticleContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmArticleRules: function (BMArticleRules, $stateParams, securityService) {
              return BMArticleRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "masterdataArticle"
        })
    })

    .controller('BMMasterdataArticle',
      function (bmArticleMetadata, bmArticleContext, securityService, $stateParams, bmArticleRules, $uibModal) {
        var self = this;

        self.metadata = bmArticleMetadata;
        self.context = bmArticleContext;
        self.masterdataRules = bmArticleRules;


        function addPriceLevelCols(priceLevels) {
          priceLevels.forEach(function (priceLevel, index) {
            columns.splice((columns.length - 1), 0, {
              name: 'article2PriceLevels[' + index + '].price',
              displayName: priceLevel.name,
              metadataField: 'priceLevel',
              headerTooltip: true,
              width: 100,
              cellEditableCondition: function () {
                if (self.gridConfig && self.gridConfig.ruleManager) {
                  return !self.gridConfig.ruleManager.state.isToday();
                }
              },
              findRuleValue: function (rule) {
                return _.chain(rule['article2PriceLevels']).find(function (rulePriceLevel) {
                  return rulePriceLevel.priceLevel.id === priceLevel.id;
                }).get('price').value();
              },
              ruleField: 'article2PriceLevels',
              preSaveRule: function (newPrice, rule) {
                if (rule && rule.article2PriceLevels) {
                  var rulePL = _.find(rule.article2PriceLevels, function (rulePL) {
                    return rulePL.priceLevel.id === priceLevel.id;
                  });
                  if (rulePL) {
                    rulePL.price = newPrice;
                  } else {
                    rule.article2PriceLevels.push({price: newPrice, priceLevel: priceLevel});
                  }
                  return rule.article2PriceLevels;
                }
                return [{price: newPrice, priceLevel: priceLevel}];
              },
              deleteRule: function (rule) {
                if (rule && rule.article2PriceLevels) {
                  _.remove(rule.article2PriceLevels, function (rulePL) {
                    return rulePL.priceLevel.id === priceLevel.id;
                  })
                }
              }
            });
          });
        }

        function syncPriceLevelsWithGrid(data, context) {
          _.forEach(data, function (article) {
            var fullPriceLevelList = [];
            _.forEach(context.data.dependencies.priceLevels, function (priceLevel, index) {
              var found = _.find(article.article2PriceLevels, function (article2PriceLevel) {
                return article2PriceLevel.priceLevel && (article2PriceLevel.priceLevel.id === priceLevel.id);
              });

              if (found) {
                fullPriceLevelList.splice(index, 0, found)
              } else {
                var article2PriceLevel = {priceLevel: priceLevel, price: undefined};
                fullPriceLevelList.splice(index, 0, article2PriceLevel);
              }

            });
            article.article2PriceLevels = fullPriceLevelList;
          });
        }

        var columns = [
          {name: 'id', enableCellEdit: false, width: 70},
          {name: 'name'},
          {name: 'shortName'},
          {name: 'articleGroup'},
          {name: 'articleType'},
          {name: 'price'},
          {
            // use 'mode' field because it is not possible to change constraints without changing or fixing mode
            name: 'mode',
            displayName: 'article.constraints',
            enableCellEdit: false,
            cellTemplate: 'app/template/bm/group-view/grid/cell/bm_cell_edit.html',
            editConstraintsBM: editConstraintsBM,
            isChanged: function (newValue, oldValue) {
              return true;
            },
            deleteRule: function (rule) {
              if (rule && !_.isNil(rule.mode)) {
                _.unset(rule, 'mode');
                _.unset(rule, 'constraints');
              }
            }
          }
        ];

        self.metadata.fields.priceLevel = {
          javaType: 'java.math.BigDecimal'
        };

        self.gridConfig = {
          columnDefs: columns,
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/article';
          },
          prepareData: function (data, context) {

            syncPriceLevelsWithGrid(data, context);

            _.remove(this.columnDefs, function (col) {
              return col.metadataField === 'priceLevel';
            });
            addPriceLevelCols(context.data.dependencies.priceLevels);
          },
          onRegisterApi: function (gridApi) {
            self.gridApi = gridApi;
          }
        };

        self.permissionsConfig = {
          columnsPermissions: {
            'name': {
              editPermissions: ['BM_CHANGE_ARTICLE_NAME']
            },
            'price': {
              editPermissions: ['BM_CHANGE_PRICE']
            }
          }
        };

        function editConstraintsBM(entity) {
          $uibModal.open({
            template: require('../../template/bm/group-view/grid/cell/bm_edit_article_constraints_modal.html'),
            windowClass: 'gf-modal',
            size: 'lg',
            controllerAs: '$ctrl',
            controller: 'bmEditConstraintsCtrl',
            resolve: {
              bmEntity: function ($http) {
                return $http.get(self.gridConfig.getBaseUrl(self.gridConfig.ruleManager.state.currentRule.id) + '/' + entity.id)
                  .then(function (resp) {
                    return {
                      article: resp.data.payload.entity,
                      rule: resp.data.payload.rule
                    }
                  });
              },
              bmContext: {
                ruleId: self.gridConfig.ruleManager.state.currentRule.id,
                getBaseUrl: self.gridConfig.getBaseUrl
              }
            }
          }).result.then(function (article) {
            var modeColDef = self.gridApi.grid.getColDef('mode');
            var constraintsColDef = {name: 'constraints', isChanged: modeColDef.isChanged};
            self.gridApi.edit.raise.afterCellEdit(entity, constraintsColDef, article.constraints, entity.constraints);
            self.gridApi.edit.raise.afterCellEdit(entity, modeColDef, article.mode, entity.mode);
          });
        }

      }
    )
    .controller('bmEditConstraintsCtrl',
      function ($uibModalInstance, bmEntity, securityService, $stateParams, bmContext, $log) {

        var self = this;
        self.article = bmEntity.article;

        if (!bmContext.ruleId) {
          var message = 'RuleId is required to configure article constraints in BM view';
          $log.error(message);
          throw new Error(message);
        }

        if (bmEntity.rule && !_.isNil(bmEntity.rule.mode)) {
          self.article.mode = bmEntity.rule.mode;
          self.article.constraints = bmEntity.rule.constraints;
        }

        self.AMSetupConfig = {
          baseUrl: bmContext.getBaseUrl(bmContext.ruleId),
          isEnabledAggregated: true
        };

        self.save = function () {
          if (self.articleModeForm.$valid) {
            $uibModalInstance.close(self.article);
          } else {
            self.articleModeForm.$setSubmitted();
          }
        }
      })

})();
