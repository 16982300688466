(function() {
  'use strict';
  angular.module('gf.tour', [])

    .run(function ($templateCache) {
      $templateCache.put('app/template/tour/header_cell_tour.html', require('../template/tour/header_cell_tour.html'));
      $templateCache.put('app/template/tour/ui_tour_template_full.html', require('../template/tour/ui_tour_template_full.html'));
    })

    .factory('tourService',
      function (uiTourService, $state, $http, securityService, $anchorScroll, $timeout, $rootScope) {
        var allStatesWithTour = ['page.dashboardDateType', 'page.dashboardEdit', 'page.masterdataArticle_group', 'page.masterdataArticle_groupAdd', 'page.masterdataArticle', 'page.masterdataArticleAdd',
          'page.masterdataOrder_screen', 'page.masterdataCourse_group', 'page.masterdataWaiter', 'page.setupWaiter_group', 'page.financialSettlement_form', 'page.setupRestaurant'];
        var statesWithTour = [];

        var tourServiceObj = {

          refreshAvaliablePaths: function () {
            statesWithTour.length = 0;
            for (var i = 0; i < allStatesWithTour.length; i++) {
              var stepUrl = $state.href(allStatesWithTour[i]);
              if (securityService.hasAccess(stepUrl.slice(1))) {
                statesWithTour.push(allStatesWithTour[i].substring(allStatesWithTour[i].indexOf('.') + 1));
              }
            }
          },

          hasTour: function (currentState) {
            var state = currentState.substring(currentState.indexOf('.') + 1);
            return statesWithTour.indexOf(state);
          },

          getNextState: function (tourPagesStates) {
            var currentStepIndex = statesWithTour.indexOf($state.current.name.substring($state.current.name.indexOf('.') + 1));

            function checkIfTourEnded (tourPagesStates, currentPage) {
              var next;

              var result = _.filter(tourPagesStates, function( obj ) {
                return obj.page == currentPage;
              });
              if (!result[0]) {
                next = currentPage;
              } else if (!result[0].endDate) {
                next = result[0].page;
              }
              return next;
            }

            for (var i = currentStepIndex; i < statesWithTour.length; i++) {
              if (i == statesWithTour.length - 1) {
                i = -1;
              }
              var next = checkIfTourEnded(tourPagesStates, statesWithTour[i + 1]);
              if (next) {
                return next;
              }
            }
          },

          doStep: function (tour, isNext) {
            tour.options.scrollIntoView = true;
            function sendTourState() {
              var data = { page: tour.options.name, step: String(tour.getCurrentStep().stepId) };
              $http.put('/icash/user_tour/' + tour.options.name, data);
            }
            if (isNext) {
              tour.next().then(sendTourState);
            } else {
              tour.prev().then(sendTourState);
            }
            $timeout(function() {
              tour.options.scrollIntoView = false;
            }, 1000);
          },

          checkIfShowNextButton: function() {
            $http.get('/icash/user_tour/').then(function (response) {
              var tourPagesStates = response.data.payload.state;
              var result = _.filter(tourPagesStates, function(obj) {
                return !obj.endDate;
              });
              if (tourPagesStates.length < statesWithTour.length || (tourPagesStates.length == statesWithTour.length && result.length > 1)) {
                securityService.showNextPageTutorialButton();
              } else {
                securityService.hideNextPageTutorialButton();
              }
            });
          },

          startTour: function (tour, currentTour) {
            if (!securityService.context.userTourEnabled) {
              return;
            }
            tour.options.scrollIntoView = true;
            if (this.hasTour($state.current.name) < 0) {
              return;
            }
            var self = this;

            if (currentTour && currentTour.payload.info) {
              if (currentTour.payload.info.endDate) {
                securityService.showTutorialButton();
                return;
              }
              var startStep = currentTour.payload.info.step;
              tour.waitFor (String(startStep));
              tour.goTo(String(startStep));
              securityService.hideTutorialButton();
              self.checkIfShowNextButton();

            } else {
              var data = { page: tour.options.name, step: '0', action: 'START' };
              $http.put('/icash/user_tour/' + tour.options.name, data).then(function (response) {
                self.checkIfShowNextButton();
              });
              tour.waitFor('0');
              tour.goTo('0');
            }
            $timeout(function() {
              tour.options.scrollIntoView = false;
            }, 2000);
          },

          startDetachedTour: function (tourName, currentTour) {
            uiTourService.createDetachedTour(tourName, {
              appendToBody: true, popupDelay: 500, useUiRouter: true, template: require('../template/tour/ui_tour_template.html'), popupClass: 'gf-tour'
            });
            var t = uiTourService.getTourByName(tourName);
            this.startTour(t, currentTour);
          },

          onNextTourStep: function (tour) {
            var self = this;
            var currentStep = tour.getCurrentStep().stepId ;
            tour.end();

            var data = { action: 'FINISH', page: tour.options.name, step: currentStep };
            $http.put('/icash/user_tour/' + tour.options.name, data).then(function () {
              $http.get('/icash/user_tour/').then (function (response) {
                var tourPagesStates = response.data.payload.state;
                var state = self.getNextState(tourPagesStates);
                if (state) {
                  $state.go('page.' + state);
                }
              });
            });
          },

          startTourAgain: function(tour) {
            if (!tour || !tour.options) {
              return;
            }
            tour.options.scrollIntoView = true;
            var self = this;
            $rootScope.$broadcast('beforeTourStart');
            var data = { action: 'START', page: tour.options.name, step: '0' };
            $http.put('/icash/user_tour/' + tour.options.name, data).then(function (response) {
              securityService.hideTutorialButton();
              self.checkIfShowNextButton();
              tour.startAt('0');
              tour.goTo('0');
            });
            $timeout(function() {
              tour.options.scrollIntoView = false;
            }, 1000);
          },

          finishTour: function(tour) {
            var currentStep = tour.getCurrentStep().stepId ;
            tour.end();
            var data = { action: 'FINISH', page: tour.options.name, step: currentStep };
            $http.put('/icash/user_tour/' + tour.options.name, data).then(function (response) {
              securityService.showTutorialButton(true);
              $anchorScroll('top');
            });
          }
        };

        tourServiceObj.refreshAvaliablePaths();
        return tourServiceObj;
      })
})();
