(function () {
  'use strict';
  angular.module('gf.bm.waiter', ['gf.bm.groupView'])

    .factory('BMWaiterMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/waiter/metadata.json')
    })
    .factory('BMWaiterContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/waiter/context.json')
    })
    .factory('BMWaiterRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.masterdataWaiter', {
          url: '/masterdata/waiter',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMMasterdataWaiter',
          controllerAs: '$ctrl',
          resolve: {
            bmWaiterMetadata: function (BMWaiterMetadata, $stateParams, securityService) {
              return BMWaiterMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmWaiterContext: function (BMWaiterContext, $stateParams, securityService) {
              return BMWaiterContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmWaiterRules: function (BMWaiterRules, $stateParams, securityService) {
              return BMWaiterRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: 'masterdataWaiter'
        })
    })

    .controller('BMMasterdataWaiter',
      function (bmWaiterMetadata, bmWaiterContext, securityService, $stateParams, bmWaiterRules) {
        var self = this;

        self.metadata = bmWaiterMetadata;
        self.context = bmWaiterContext;
        self.masterdataRules = bmWaiterRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'firstName'},
            {name: 'lastName'},
            {name: 'number', enableCellEdit: false},
            {name: 'loginPin'},
            {
              name: 'groups',
              field: 'groups',
              ruleField: 'waiter2WaiterGroups',
              ruleRelationField: 'waiterGroup'
            }
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/waiter';
          }
        };
      }
    )

})();
