(function () {
  'use strict';

  angular.module('gastrofix')

    .run(function($rootScope, $translate, uibPaginationConfig) {

      $rootScope.$on('$translateChangeSuccess', function () {
        $translate(['multiselect.noneSelectedText', 'multiselect.checkAllText', 'multiselect.uncheckAllText',
          'multiselect.filter.label'])
          .then(function (translations) {
            $rootScope.multiselectText = {
              buttonDefaultText: translations['multiselect.noneSelectedText'],
              checkAll: translations['multiselect.checkAllText'],
              uncheckAll: translations['multiselect.uncheckAllText'],
              searchPlaceholder: translations['multiselect.filter.label'],
              dynamicButtonTextSuffix: "selectedText"
            };
          });
        $translate(['button.previous', 'button.next', 'button.first', 'button.last'])
          .then(function (translations) {
            uibPaginationConfig.firstText = translations['button.first'];
            uibPaginationConfig.lastText = translations['button.last'];
            uibPaginationConfig.previousText = translations['button.previous'];
            uibPaginationConfig.nextText = translations['button.next'];
          });
      });

      $rootScope.multiselectDefaultSettings = {
        enableSearch: true,
        smartButtonMaxItems: 3,
        displayProp: 'name',
        scrollable: true
      };

    });

})();
