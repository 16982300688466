(function () {
  'use strict';
  angular.module('gf.dnd', [])
    .constant('draggableConfig', {
      revert: 'invalid',
      helper: "clone",
      snap: true,
      scroll: true,
      zIndex: 10000,
      cursor: "move",
      addClasses: false,
      distance: 50,
      opacity: .7
    })
    .constant('droppableConfig', {
      tolerance: "touch"
    })
    .constant('sortableConfig', {})
    .directive('gfDraggable', ["draggableConfig", function (draggableConfig) {
      return {
        restrict: 'AC',
        scope: {
          draggableDisable: "=draggableDisable",
          gfDraggable: "=gfDraggable"
        },
        link: function ($scope, element, attrs) {

          var config = angular.copy(draggableConfig);

          config = angular.extend(config, $scope.gfDraggable || {});

          element.draggable(config);

          function switchState() {
            if ($scope.draggableDisable) {
              element.draggable("disable");
            } else {
              element.draggable("enable");
            }
          }

          switchState();

          $scope.$watch('draggableDisable', function () {
            switchState();
          });
        }
      }
    }])
    .directive('gfDroppable', ["droppableConfig", function (droppableConfig) {
      return {
        restrict: 'AC',
        link: function ($scope, element, attrs) {

          var config = angular.copy(droppableConfig);

          angular.extend(config, $scope.$eval(attrs.gfDroppable) || {});

          element.droppable(config);
        }
      }
    }])
    .directive('gfSortable', ["sortableConfig", function (sortableConfig) {
      return {
        restrict: 'AC',
        scope: {
          gfSortable: "=gfSortable",
          ngModel: "=ngModel"
        },
        link: function ($scope, element, attrs) {

          function init() {
            var config = angular.copy(sortableConfig);

            var newConfig = $scope.gfSortable || {};
            angular.extend(config, newConfig);

            if (!newConfig.stop) {
              config = angular.extend(config, {
                stop: function (event, ui) {

                  $scope.$apply(function () {

                    var startIndex = ui.item.attr('ng-index');
                    var stopIndex = ($(ui.item).index());

                    var move = !ui.item.attr('ng-model');
                    var model = $scope.ngModel;

                    if (move) {
                      if (startIndex != stopIndex) {
                        model.splice(stopIndex, 0, model.splice(startIndex, 1)[0]);
                        ui.item.detach();
                      }
                    } else {
                      var draggableModel = $scope.$parent.$eval(ui.item.attr('ng-model'));
                      model.splice(stopIndex, 0, angular.copy(draggableModel[startIndex]));
                      ui.item.detach();
                    }

                  });
                }
              });
            }

            element.sortable(config);
          };

          init();

          $scope.$watch('gfSortable', function () {
            init();
          });
        }
      }
    }])
  ;
})();

