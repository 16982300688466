(function () {
  'use strict';
  angular.module('gf.masterdata', [])
    .controller('OrderScreenAddCtrl',
      function ($scope, $translate, $resource, $location, notifyManager, orderScreenContext, orderScreenMetadata,
                orderScreen) {

        $scope.orderScreenContext = orderScreenContext;
        $scope.orderScreenMetadata = orderScreenMetadata;
        $scope.orderScreen = orderScreen;
        $scope.dependencies = orderScreenContext.data.dependencies;

        $scope.save = function () {
          if ($scope.orderScreenForm.$valid) {
            $scope.orderScreen.$save(function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $location.path('/masterdata/order_screen');
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
      }
    )
    .controller('OrderScreenEditCtrl',
      function ($scope, $translate, $resource, $location, notifyManager, $state, orderScreenContext,
                orderScreenMetadata, orderScreen, usedEntities) {

        $scope.orderScreenContext = orderScreenContext;
        $scope.orderScreenMetadata = orderScreenMetadata;
        $scope.orderScreen = orderScreen;
        $scope.dependencies = orderScreenContext.data.dependencies;
        $scope.dependencies.articles = usedEntities.articles ? usedEntities.articles : [];
        $scope.dependencies.articleGroups = usedEntities.articleGroups ? usedEntities.articleGroups : [];

        $scope.save = function () {
          if ($scope.orderScreenForm.$valid) {
            $scope.orderScreen.$edit({orderScreenId: $scope.orderScreen.id}, function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $location.path('/masterdata/order_screen');
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
      }
    )

    .controller('OrderScreenGridCtrl',

      function ($scope, orderScreenContext, orderScreenMetadata, configOptionsService) {
        $scope.metadata = orderScreenMetadata;
        $scope.context = orderScreenContext;

        var actions = [{
          name: 'copy',
          template: 'app/template/grid/copy_article_action.html',
          controller: 'CopyOrderScreenActionCtrl'
        }];

        var columns = [
          {name: 'id', width: 60, enableCellEdit: false, visible: false},
          {name: 'name'},
          {name: 'rowSize', width: 100},
          {name: 'size', width: 100},
          {
            name: 'courseGroup', width: 150,
            validations: {
              'gf-uniquecoursegroupperscreensize-coursegroup': {
                'angular': {'gf-uniquecoursegroupperscreensize-coursegroup': ''},
                'errorMessage': "de.icash.validator.UniqueCourseGroupPerScreenSize.message"
              }
            },
            editableCellTemplate: "<div><form name=\"inputForm\"><select class=\"form-control\" ng-class=\"'colt' + col.uid\" ui-grid-edit-dropdown ng-model=\"MODEL_COL_FIELD\" ng-options=\"field[editDropdownValueLabel] CUSTOM_FILTERS for field in editDropdownOptionsArray track by field[editDropdownIdLabel]\"><option ng-show=\"col.colDef.showEmpty\" value=\"\" translate=\"without.course\"></option></select></form></div>",
            cellTemplate: "<div uib-tooltip=\"{{(row.entity.id!=null||row.entity.filled)&&!row.entity.courseGroup ?  ('without.course.tooltip' | translate) : ''}}\" tooltip-append-to-body=\"false\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD CUSTOM_FILTERS}}</div>"
          },
          {
            name: 'deviceScreenSize', width: 150,
            validations: {
              'gf-uniquecoursegroupperscreensize-screensize': {
                'angular': {'gf-uniquecoursegroupperscreensize-screensize': ''},
                'errorMessage': "de.icash.validator.UniqueCourseGroupPerScreenSize.message"
              }
            }
          },
          {
            name: 'operate',
            actions: ['edit', 'copy', 'delete'],
            actionOptions: {
              'delete': {
                isDisabled: configOptionsService.get('orderScreenDeleteButton').isDisabled
              }
            }
          }
        ];

        $scope.config = {
          urlRoute: 'masterdata/order_screen',
          registerActions: actions,
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: columns
        };
      }
    )
    .controller('CopyOrderScreenActionCtrl',
      function ($scope, $translate, $uibModal, notifyManager, OrderScreen, OrderScreenMetadata, OrderScreenContext,
                entity, gridApi) {

        $scope.gridApi = gridApi;
        $scope.entity = entity;

        var newEntity = angular.copy(entity);
        newEntity.id = null;
        newEntity.courseGroup = null;
        newEntity.deviceScreenSize = null;

        $scope.pleasWateDialog = $uibModal.open({
          template: require('./../../../../../main/angular/app/template/grid/order_screen_copy_dialog.html'),
          controller: 'CopyOrderScreenDialog',
          scope: $scope,
          windowClass: 'gf-modal',
          resolve: {
            entity: function () {
              return newEntity;
            },
            orderScreenMetadata: OrderScreenMetadata.get().$promise,
            orderScreenContext: OrderScreenContext.get().$promise
          }
        }).result.then(
          function (readyEntity) {

            var orderScreenResource = new OrderScreen(readyEntity);

            orderScreenResource.$save(function (data) {
              var index = _.findIndex($scope.gridApi.grid.options.data, function (item) {
                return item.id == $scope.entity.id;
              });
              // insert new article to the next position
              $scope.gridApi.grid.options.data.splice(index + 1, 0, data.payload.entity);

              notifyManager.success($translate.instant('entity.save.successfully'));
            });
          });
      }
    )
    .controller('CopyOrderScreenDialog',
      function ($scope, $uibModalInstance, entity, orderScreenContext, orderScreenMetadata) {
        $scope.orderScreen = entity;
        $scope.orderScreenMetadata = orderScreenMetadata;

        angular.extend($scope, orderScreenContext.data.dependencies);

        $scope.ok = function () {
          $uibModalInstance.close(entity);
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
      }
    );

  angular.module('masterdataFilters', [])
    .filter('ticketLayoutDuplicateFilter', function () {
      // size need for run filter during changes
      return function (modules, layouts, size) {

        _.map(modules, function (currentModule) {
          currentModule.selected = false;
          var layout = _.find(layouts, function (layout) {
            return layout.module.id == currentModule.module.id;
          });
          if (layout) {
            currentModule.selected = true;
          }
        });

        return modules;
      };
    });

})();
