var DatevCtrlResolve = {
  datevMetadata: ["DatevMetadata", function (DatevMetadata) {
    return DatevMetadata.get().$promise;
  }],
  datevContext: ["DatevContext", function (DatevContext) {
    return DatevContext.get().$promise;
  }],
  datev: ["Datev", function (Datev) {
    return Datev.load().$promise;
  }]
};

var CubeCtrlResolve = {
  cubeMetadata: ["CubeMetadata", function (CubeMetadata) {
    return CubeMetadata.get().$promise;
  }],
  cubeContext: ["CubeContext", function (CubeContext) {
    return CubeContext.get().$promise;
  }],
  cube: ["Cube", function (Cube) {
    return Cube.get().$promise;
  }]
};

var CorrectionJournalCtrlResolve = {
  journalCorrections: ["JournalCorrections", "$stateParams", function (JournalCorrections, $stateParams) {
    return JournalCorrections.load().$promise;
  }]
};

var InvoicesCtrlResolve = {
  invoices: ["Invoices", function (Invoices) {
    return Invoices.get().$promise;
  }]
};

(function () {
  'use strict';
  angular.module('gf.financial', [])

    .config(function($stateProvider) {

      $stateProvider

        .state('page.financialDatev', {
          url: '/financial/datev',
          template: require('./../../../template/page/financial/datev_account.html'),
          controller: 'DatevCtrl',
          resolve: DatevCtrlResolve,
          title: 'datev'
        })

        .state('page.financialCube', {
          url: '/financial/cube',
          template: require('./../../../template/page/financial/get_cube.html'),
          controller: 'CubeCtrl',
          resolve: CubeCtrlResolve,
          title: 'getCubeAccount'
        })

        .state('page.financialCorrection', {
          url: '/financial/correction',
          template: require('./../../../template/page/financial/correction.html'),
          controller: 'CorrectionJournalCtrl',
          resolve: CorrectionJournalCtrlResolve,
          title: 'corrections'
        })

        .state('page.financialInvoices', {
          url: '/financial/invoices',
          template: require('./../../../template/page/financial/invoices.html'),
          controller: 'InvoicesCtrl',
          resolve: InvoicesCtrlResolve,
          title: 'invoices'
        })
    })

    .controller('DatevCtrl',

      function ($scope, $location, $translate, notifyManager, Datev, datev, datevContext, datevMetadata, $locale) {

        $scope.dateFormat = $locale.DATETIME_FORMATS.shortDate;

        $scope.datev = datev;
        $scope.datevContext = datevContext;
        $scope.datevMetadata = datevMetadata;

        // to convert LocalDate format to Date object for timepicker
        datev.fiscalYearBegin = new Date(datev.fiscalYearBegin);

        angular.extend($scope, datevContext.data.dependencies);

        $scope.save = function () {
          if ($scope.datevForm.$valid) {
            $scope.datev.$edit(function (data) {
              $scope.datev = new Datev(data.payload.datev);
              notifyManager.success($translate.instant('entity.edit.successfully'));
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
      }
    )
    .controller('CubeCtrl',

      function ($scope, $location, $translate, notifyManager, Cube, cube, cubeContext, cubeMetadata) {
        $scope.cube = cube;
        $scope.cubeContext = cubeContext;
        $scope.cubeMetadata = cubeMetadata;

        $scope.save = function () {
          if ($scope.cubeForm.$valid) {
            $scope.cube.$edit(function (data) {
              $scope.cube = new Cube(data.payload.cube);
              notifyManager.success($translate.instant('entity.edit.successfully'));
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

      }
    )

    .controller('CorrectionJournalCtrl',

      function ($scope, $location, $http, $translate, notifyManager, journalCorrections, JournalCorrections) {
        $scope.corrections = journalCorrections;

        $scope.selectedDate = 2;
        $scope.dates = [
          {id: 1, name: $translate.instant('date.today')},
          {id: 2, name: $translate.instant('date.yesterday')},
          {id: 3, name: $translate.instant('date.thisWeek')},
          {id: 4, name: $translate.instant('date.lastWeek')},
          {id: 5, name: $translate.instant('date.thisMonth')},
          {id: 6, name: $translate.instant('date.lastMonth')}
        ];

        $scope.loadCorrections = function () {
          JournalCorrections.load({date: $scope.selectedDate}, function (corrections) {
            $scope.corrections = corrections;
          });
        };

        $scope.isEmpty = function (obj) {
          return $.isEmptyObject(obj);
        };

        $scope.deleteBill = function (billNumber) {
          if (confirm($translate.instant("corrections.delete.alert"))) {
            $http({method: 'DELETE', url: "/icash/financial/correction/" + billNumber})
              .then(function (resp) {
                if (resp.data.ok) {
                  notifyManager.success($translate.instant("corrections.delete.success"));
                  $scope.loadCorrections();
                }
              });
          }
        }
      }
    )
    .controller('InvoicesCtrl',

      function ($scope, invoices) {
        $scope.invoices = invoices;
      }
    )
})();

