import React from 'react';

import CloudTSE from './CloudTSE';
import EpsonTSE from './EpsonTSE';
import TSEInformationOverview from './TSEInformationOverview';
import statuses from "./TseStatuses";
import _ from "lodash";


export function normalizeState(tseInformation) {
  tseInformation.tseInitializationState = statuses[tseInformation.tseInitializationState?.toUpperCase()] || statuses.UNINITIALIZED;
  tseInformation.isDeletedStatus = _.includes(statuses.getDeletedStatuses(), tseInformation.tseInitializationState);

  return tseInformation;
}

function FiscalDevice({security}) {

  if (security.hasAnyFeature('EPSON_TSE')) {
    return <EpsonTSE security={security}/>
  }
  if (security.hasAnyFeature('CLOUD_TSE')) {
    return <CloudTSE security={security}/>
  }
  return (
    <TSEInformationOverview security={security}/>
  );
}

export default FiscalDevice;
