import React from 'react';
import Typography from '@material-ui/core/Typography';

function GFAlertTitle({children, align}) {
  return (
    <Typography variant={'h6'} gutterBottom align={align}>
      {children}
    </Typography>
  );
}

export default GFAlertTitle;
