/**
 * Created by ologunov on 11/16/15.
 *
 * See grid.md
 *
 *
 */
(function () {
  'use strict';
  angular.module('gf.grid', ['gf.directives', 'gf.validations', 'ui.grid', 'ui.grid.edit', 'ui.grid.pagination', 'ui.grid.saveState', 'ui.grid.resizeColumns', 'ui.grid.rowEdit',
    'ui.grid.selection', 'ui.grid.cellNav', 'ui.grid.autoResize', 'ui.grid.moveColumns', 'ui.bootstrap', 'ui.grid.edit'])

    .run(function ($templateCache) {
      $templateCache.put('app/template/grid/activate_action.html', require('../../../../../main/angular/app/template/grid/activate_action.html'));
      $templateCache.put('app/template/grid/edit_action.html', require('../../../../../main/angular/app/template/grid/edit_action.html'));
      $templateCache.put('app/template/grid/delete_action.html', require('../../../../../main/angular/app/template/grid/delete_action.html'));
      $templateCache.put('app/template/grid/copy_article_action.html', require('../../../../../main/angular/app/template/grid/copy_article_action.html'));
      $templateCache.put('app/template/grid/change_id_action.html', require('../../../../../main/angular/app/template/grid/change_id_action.html'));
      $templateCache.put('app/template/grid/copy_article_group_action.html', require('../../../../../main/angular/app/template/grid/copy_article_group_action.html'));
      $templateCache.put('app/template/grid/change_password.html', require('../../../../../main/angular/app/template/grid/change_password.html'));
      $templateCache.put('app/template/grid/download_svg_action.html', require('../../../../../main/angular/app/template/grid/download_svg_action.html'));
      $templateCache.put('app/template/grid/details_action.html', require('../../../../../main/angular/app/template/grid/details_action.html'));
      $templateCache.put('app/template/grid/master_action.html', require('../../../../../main/angular/app/template/grid/master_action.html'));
      $templateCache.put('app/template/grid/master_restaurant_action.html', require('../../../../../main/angular/app/template/grid/master_restaurant_action.html'));
      $templateCache.put('app/template/grid/clone_action.html', require('../../../../../main/angular/app/template/grid/clone_action.html'));
      $templateCache.put('app/template/grid/switch_user_action.html', require('../../../../../main/angular/app/template/grid/switch_user_action.html'));
      $templateCache.put('app/template/grid/restore_action.html', require('../../../../../main/angular/app/template/grid/restore_action.html'));
      $templateCache.put('app/template/grid/clone_customer_action.html', require('../../../../../main/angular/app/template/grid/clone_customer_action.html'));
      $templateCache.put('app/template/grid/switch_restaurant_action.html', require('../../../../../main/angular/app/template/grid/switch_restaurant_action.html'));
      $templateCache.put('app/template/grid/connection_status_action.html', require('../../../../../main/angular/app/template/grid/connection_status_action.html'));
      $templateCache.put('app/template/grid/master_info_action.html', require('../../../../../main/angular/app/template/grid/master_info_action.html'));
    })

    .directive('gfGrid',
      function ($rootScope, $resource, $translate, $q, $timeout, $log, $templateRequest, $controller, $window,
                notifyManager, gfStandardValidations, uiGridConstants, $uibModal, i18nService, localStorageService,
                securityService, rowSorter, $state, appConfig, $stateParams) {
        return {
          restrict: "E",
          replace: true,
          template: require('../../../../../main/angular/app/template/grid/grid.html'),
          scope: {
            config: "=config",
            metadata: "=metadata",
            context: "=context",
            additionalSettings: "=additionalSettings",
            index: "=index"
          },
          link: function ($scope, element, attributes, gfGridCtrl) {
            /**
             * initialize section
             */

            $scope.gridOptionsLoaded = true;

            // grid in the init state, this flag rules the saving state
            var init = true;

            $scope.dataLoaded = false;

            $scope.rowsSelected = false;

            $scope.security = securityService;

            // Language detection - start
            // get current language code
            var lang = $translate.use().split('-')[0];
            // get grid language(i18nService - ui-grid service that have all information about languages)
            var gridLang = _.find(i18nService.getAllLangs(), function (language) {
              return language.indexOf(lang) > -1;
            });
            // set grid language
            i18nService.setCurrentLang(gridLang);
            // this fix bug in grid library, that apply ui-i18n directive with en, we override it
            // after update grid check and delete using ui-i18n directive, if it will be fixed
            $scope.lang = gridLang;
            // Language detection - end

            var getAllResource, EntityResource, CreateEntityResource, ContextResource;

            //create new resources after changing refreshEndpoint
            function createResources() {

              // create resources
              getAllResource = $resource(appConfig.baseUrl + "/icash" + $scope.context.refreshEndpoint + "/page", {}, {
                getPage: {method: 'POST'}
              }, {cancellable: true});
              EntityResource = $resource(appConfig.baseUrl + "/icash" + $scope.context.refreshEndpoint + "/:id.json", {}, {
                edit: {method: 'PUT'}
              });
              CreateEntityResource = $resource(appConfig.baseUrl + "/icash" + $scope.context.refreshEndpoint + "/", {}, {
                create: {method: 'POST'}
              });
              ContextResource = $resource(appConfig.baseUrl + "/icash" + $scope.context.refreshEndpoint + "/context.json", {}, {});

              if (!$scope.config.registerActions) {
                $scope.config.registerActions = [];
              } else {
                var removedActions = [];
                for (var i = 0; i < $scope.config.registerActions.length; i++) {
                  if ($scope.config.registerActions[i].name === 'delete' ||
                    $scope.config.registerActions[i].name === 'edit' ||
                    $scope.config.registerActions[i].name === 'activate') {
                    removedActions.push($scope.config.registerActions[i]);
                  }
                }
                for (var i = 0; i < removedActions.length; i++) {
                  $scope.config.registerActions.pop(removedActions[i]);
                }
              }

              // add default actions
              $scope.config.registerActions = $scope.config.registerActions.concat([
                {
                  name: 'delete',
                  template: 'app/template/grid/delete_action.html',
                  controller: 'RemoveRowGridCtrl',
                  resolve: {
                    EntityResource: EntityResource
                  }
                },
                {
                  name: 'edit',
                  template: 'app/template/grid/edit_action.html',
                  controller: 'EditRowGridCtrl',
                  resolve: {
                    url: $scope.config.urlRoute
                  }
                },
                {
                  name: 'activate',
                  template: 'app/template/grid/activate_action.html',
                  controller: 'ActivateRowGridCtrl',
                  resolve: {
                    url: $scope.context.refreshEndpoint,
                    entityName: $scope.metadata.name
                  }
                }
              ]);

              // Create full columns description
              mergeColumnParameters($scope.config.columns, $scope.metadata);

            }

            $scope.config.deleteRow = function (entity) {
              $scope.gridOptions.data.splice(_.findIndex($scope.gridOptions.data, function (item) {
                return item.id == entity.id;
              }), 1);
              updateGridHeight();
              postDelete();
              $rootScope.$broadcast('deleteEntity', {gridData: $scope.gridOptions.data});
            };

            function postDelete() {
              var addRow = _.find($scope.gridApi.grid.rows, function (row) {
                return row.entity.id == null;
              });

              if (!addRow && $scope.config.enableAddRow && $scope.gridOptions.canAdd($scope.gridApi.grid) && (!$scope.gridOptions.maxDataNumber || $scope.gridOptions.dataItemsNumber == $scope.config.maxDataNumber)) {
                $scope.gridOptions.data.push(getAddRowObject());
                updateGridHeight();
              }

              $scope.gridOptions.dataItemsNumber--;
            }

            // save state key for current table, generated during loading page
            var tableSaveStateKey = getStateStorageKey();

            // Height row, need for calculate height of grid
            var rowHeight = 40;

            // Width selection column
            var selectionCheckboxColumnWidth = 40;

            // Setup common grid settings
            $scope.gridOptions = {
              rowHeight: rowHeight,
              showGridFooter: false,
              showColumnFooter: false,
              enableColumnsHiding: false,
              // for allowing add new rows
              paginationPageSize: 100000,
              //waite before save
              rowEditWaitInterval: 1000,
              enableSorting: false,
              enableFiltering: $scope.config.enableFiltering ? $scope.config.enableFiltering : false,
              // use external because we load data from server and it is already sorted or filtered
              useExternalSorting: false,
              useExternalFiltering: false,
              // default cell edit
              enableCellEdit: true,
              // edit like in excel
              enableCellEditOnFocus: true,
              // don't use standard widget for pagination
              enablePaginationControls: false,
              // grid view setup settings
              enableColumnMoving: false,
              enableColumnResizing: false,
              // hide scrollbar
              enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
              enableVerticalScrollbar: uiGridConstants.scrollbars.NEVER,
              maxDataNumber: undefined,
              data: [],
              canAdd: function (grid) {
                return true;
              },
              showAdd: function () {
                return true;
              },
              enableRowSelection: false,
              multiSelect: false,
              enableSelectAll: false,
              // must be predefined
              enableRowHeaderSelection: $scope.config.enableRowSelection ? $scope.config.enableRowSelection : false,
              // need for right rendering header before loading data
              columnDefs: [{name: 'empty'}],
              // exclude fast entry row
              isRowSelectable: function (row) {
                return row.entity.id != null;
              },
              selectionRowHeaderWidth: selectionCheckboxColumnWidth,
              hideHeader: false,
              disableSaveState: false,
              prepareData: undefined,
              hideActions: function (entity) {
                return false;
              }
            };
            gfGridCtrl.gridOptions = $scope.gridOptions;

            if ($scope.config.enableRowSelection) {
              $scope.gridOptions.enableSelectAll = $scope.gridOptions.enableSelectAll ? $scope.gridOptions.enableSelectAll : true;
              $scope.gridOptions.multiSelect = $scope.gridOptions.multiSelect ? $scope.gridOptions.multiSelect : true;
            }

            // setup pagination options
            if ($scope.config.enablePagination) {
              $scope.gridOptions.useExternalSorting = $scope.config.enableSorting;
              $scope.gridOptions.useExternalFiltering = $scope.config.enableFiltering;
            }

            // reload grid data manually
            $scope.config.reloadData = updateData;

            // settings for multiselect
            $scope.multiselectDefaultSettings = {
              smartButtonMaxItems: 5,
              closeOnBlur: true,
              displayProp: 'name',
              buttonClasses: 'btn btn-default btn-sm',
              enableSearch: false,
              showCheckAll: false,
              showUncheckAll: false,
              externalIdProp: '',
              scrollable: true
            };

            $scope.loadTemplateCount = 0;

            $scope.$watch('context.refreshEndpoint', createResources);
            createResources();

            // for right way to resize columns we need to set sizes for all columns
            var updateContainerWidth = function (gridWidth) {
              // (- 2) minus borders
              $scope.containerWidth = {
                width: (gridWidth ? gridWidth : element.find('.gridContainer').outerWidth(false) - 2) + 32 + 'px'
              };

              // (+2) borders
              $scope.headerWidth = {
                width: (gridWidth ? gridWidth : element.find('.gridContainer').outerWidth(false)) + 2 + 'px'
              };
            };

            if ($scope.config.enableColumnResizing) {
              updateColumnsWidth($scope.config.columns);
            }
            var unsizedColumnWidth = getWidthForUnsizedColumn($scope.config.columns);
            var gridWidth = $scope.config.columns.reduce(function (sum, column) {
              return sum + (column.visible !== false ? (column.width ? column.width : unsizedColumnWidth) : 0);
            }, 0);

            if ($scope.config.enableRowSelection) {
              gridWidth += selectionCheckboxColumnWidth;
            }
            updateContainerWidth(gridWidth);

            // initialize grid events and actions
            $scope.gridOptions.onRegisterApi = function (gridApi) {
              //set gridApi on scope
              $scope.gridApi = gridApi;

              // access to gridAPI in controller
              if ($scope.config.onRegisterApi) {
                $scope.config.onRegisterApi(gridApi);
              }

              /**
               * add after cell edit function directly to the column
               */
              gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, cellModel, origCellValue) {
                if (rowEntity.id == null || rowEntity.addRow) {
                  return;
                }
                if (colDef.afterCellEdit) {
                  colDef.afterCellEdit(colDef, rowEntity, cellModel, origCellValue);
                  gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                }
              });

              /**
               * Save previous state of cell for restore if it became invalid
               */
              var oldEntity = {}; // using for saving old object. oldValue - is a label inside cell but we need object
              gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
                var columnName = colDef.name;

                if (rowEntity.id == null || rowEntity.addRow) {
                  return;
                }

                if (!validate(rowEntity, colDef, newValue)) {
                  // update cell color
                  gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);

                  var promise = $timeout(function () {

                    rowEntity[columnName] = _.result(_.find(rowEntity.savedValidValues, function (rollback) {
                      return rollback.name == columnName;
                    }), 'value');

                    setColumnStatusValid(rowEntity, colDef);

                    // update cell color
                    gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);

                    // delete rollback
                    var index = _.findIndex(rowEntity.activeRollbacks, function (rollback) {
                      return rollback.name == columnName;
                    });
                    rowEntity.activeRollbacks.splice(index, 1);
                  }, 5000);

                  // if rollbacks not exist - initialize
                  if (!rowEntity.activeRollbacks) {
                    rowEntity.activeRollbacks = [];
                  }
                  // save active rollback
                  rowEntity.activeRollbacks.push({name: columnName, promise: promise});

                  // save or update old valid column value, it's need for rollback
                  if (!getFailedValidator(colDef, oldEntity[rowEntity.id][columnName], rowEntity)) {
                    if (!rowEntity.savedValidValues) {
                      rowEntity.savedValidValues = [];
                    }

                    var savedValue = _.find(rowEntity.savedValidValues, function (rollback) {
                      return rollback.name == columnName;
                    });
                    if (savedValue) {
                      // update value
                      savedValue.value = oldEntity[rowEntity.id][columnName];
                    } else {
                      // save value
                      rowEntity.savedValidValues.push({name: columnName, value: oldEntity[rowEntity.id][columnName]});
                    }
                  }
                  return;
                }

                findDependCells(colDef.name).forEach(function (column) {
                  if (!validate(rowEntity, column, rowEntity[column.name])) {
                    gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                  }
                  ;
                });

                // if valid remove active rollbacks if it exist
                // delete rollback
                removeActiveRollbackPromise(rowEntity, columnName);
              });

              // remove active rollback promise
              gridApi.edit.on.beginCellEdit($scope, function (rowEntity, colDef, triggerEvent) {
                removeActiveRollbackPromise(rowEntity, colDef.name);
                // save old entity
                oldEntity[rowEntity.id] = angular.copy(rowEntity);
              });

              function removeActiveRollbackPromise(rowEntity, columnName) {
                var index = 0;
                var rollback = _.find(rowEntity.activeRollbacks, function (rollback, $index) {
                  index = $index;
                  return rollback.name == columnName;
                });
                if (rollback) {
                  $timeout.cancel(rollback.promise);
                  rowEntity.activeRollbacks.splice(index, 1);
                }
              }

              function findDependCells(cellName) {
                var columns = [];
                gridApi.grid.columns.forEach(function (column) {
                  // console.log(column.colDef);
                  if (column.colDef.validations
                    && column.colDef.dependOn
                    && column.colDef.dependOn.indexOf(cellName) !== -1) {
                    columns.push(column.colDef);
                  }
                });
                return columns;
              }

              /**
               * Inline add section
               */
              if ($scope.config.enableAddRow) {
                gridApi.edit.on.afterCellEdit($scope, function (rowEntity, colDef, cellModel, origCellValue) {

                  if (!cellModel || cellModel.length === 0) {
                    return;
                  }

                  if (rowEntity.id != null && !rowEntity.addRow) {
                    return;
                  }
                  // fill row by default values if it is not
                  if (!rowEntity.filled) {
                    for (var property in $scope.context.template) {
                      if ($scope.context.template.hasOwnProperty(property) && (rowEntity[property] == null
                        || ((rowEntity[property] instanceof Array) && rowEntity[property].length === 0))) {
                        if (property !== 'id') {
                          rowEntity[property] = angular.copy($scope.context.template[property]);
                        }
                      }
                    }
                    this.grid.columns.forEach(function (item) {
                      if (item.colDef.preFillValue) {
                        rowEntity[item.name] = item.colDef.preFillValue(colDef, rowEntity);
                      }
                    });
                    rowEntity.filled = true;
                  }

                  //run after edit actions
                  this.grid.columns.forEach(function (colDef) {
                    if (colDef.afterCellEdit) {
                      colDef.afterCellEdit(colDef, rowEntity);
                    }
                  });

                  // validate all columns
                  this.grid.columns.forEach(function (colDef) {
                    if (!('actions' in colDef.colDef)) {
                      validate(rowEntity, colDef.colDef, rowEntity[colDef.name]);
                    }
                  });
                  gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
                });
              }

              /**
               * Inline saving section
               */
              gridApi.rowEdit.on.saveRow($scope, function (rowEntity) {
                if (rowEntity.notValidColumns && rowEntity.notValidColumns.length > 0) {
                  // use fake promise to made row clean(it's work around from developers)
                  var promise = $q.defer();
                  $scope.gridApi.rowEdit.setSavePromise(rowEntity, promise.promise);
                  promise.resolve();

                  if (rowEntity.addRow) {
                    notifyManager.error($translate.instant('validate.incompleteItems'));
                  }

                  return;
                }

                // add fast entry row
                $scope.gridOptions.dataItemsNumber++;
                if (rowEntity.addRow && $scope.gridOptions.canAdd($scope.gridApi.grid) && (!$scope.config.maxDataNumber
                  || ($scope.gridOptions.dataItemsNumber < $scope.config.maxDataNumber))) {
                  // add new fast entry row
                  $scope.gridOptions.data.push(getAddRowObject());
                  // update height
                  updateGridHeight();
                }


                function handleUpdateError(resp) {
                  if (resp.status === 500) {
                    notifyManager.error($translate.instant('grid.error.internalServerError'))
                  }
                  notifyManager.error($translate.instant('grid.error.failed.update'));
                }

                function handleCreateError(resp) {
                  if (resp.status === 500) {
                    notifyManager.error($translate.instant('grid.error.internalServerError'))
                  }
                  notifyManager.error($translate.instant('grid.error.failed.create'));
                }

                // set save promise, it is allow track state of saving transaction
                var afterSave = function (data) {

                  if (data.ok === false) {
                    notifyManager.error($translate.instant(data.message));
                    return;
                  }

                  var entity = data.payload ? data.payload.entity : data;

                  // update entity
                  if (entity) {
                    angular.merge(rowEntity, entity);
                  }
                  rowEntity.addRow = false;
                  // apply changes
                  gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);

                  notifyManager.info($translate.instant('entity.edit.successfully'));

                  // update context
                  var context = new ContextResource();
                  context.$get(function (data) {
                    angular.merge($scope.context, data);
                  });
                  $rootScope.$broadcast('gfSaveRow', {newEntity: entity});
                };

                var idColDef = gridApi.grid.getColDef('id');
                var rowEntityId;
                if (idColDef) {
                  rowEntityId = _.get(rowEntity, idColDef.field || 'id');
                }
                if (rowEntityId) {
                  var entity = new EntityResource(rowEntity);
                  $scope.gridApi.rowEdit.setSavePromise(rowEntity, entity.$edit({id: rowEntityId}, afterSave, handleUpdateError));
                } else {
                  var entity = new CreateEntityResource(rowEntity);
                  $scope.gridApi.rowEdit.setSavePromise(rowEntity, entity.$create({}, afterSave, handleCreateError));
                }

              });

              /**
               * Grid configuration actions and events, and saving state
               */
              /**
               * Resize column
               */
              gridApi.colResizable.on.columnSizeChanged($scope, function (colDef, deltaChange) {
                if (init) {
                  return;
                }

                var columns = this.grid.columns;
                var gridWidth = columns.reduce(function (sum, column) {
                  return sum + (column.visible ? column.drawnWidth : 0);
                }, 0);

                updateGridWidth(gridWidth);
                updateContainerWidth(gridWidth);

                $scope.stateChanged = true;
                $scope.saveState();
              });

              /**
               * Change column position
               */
              gridApi.colMovable.on.columnPositionChanged($scope, function (colDef, originalPosition, newPosition) {
                if (init) {
                  return;
                }

                $scope.stateChanged = true;
                $scope.saveState();
              });

              gridApi.core.on.columnVisibilityChanged($scope, function (changedColumn) {
                if (init) {
                  return;
                }

                var gridWidth = calculateGridWidth(this.grid.columns);

                updateGridWidth(gridWidth);
                updateContainerWidth(gridWidth);
                $scope.saveState();
              });

              // refresh height of container after changing numbers of visible rows
              // using during internal filtering
              if (!$scope.gridOptions.useExternalFiltering) {
                gridApi.core.on.rowsRendered($scope, function () {
                  updateGridHeight($scope.gridApi.core.getVisibleRows().length);
                });
              }
              if ($scope.config.enableAddRow) {
                gridApi.core.on.rowsVisibleChanged($scope, function () {
                  var addRow = _.find($scope.gridApi.grid.rows, function (row) {
                    return row.entity.id == null;
                  });
                  if (addRow) {
                    addRow.visible = true;
                  }
                });
              }

              /**
               * Save state of filters and sorts
               */
              var filterTimeout;
              gridApi.core.on.filterChanged($scope, function () {
                if ($scope.resetting || init) {
                  return;
                }

                // if there was a previous attempt, stop it.
                if (filterTimeout) {
                  $timeout.cancel(filterTimeout);
                  filterTimeout = null;
                }

                // start a new attempt with a delay to keep it from
                // getting too "chatty".
                filterTimeout = $timeout(function () {
                  $scope.stateChanged = true;
                  $scope.saveState();
                }, 200);
              });
              gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                if (init) {
                  return;
                }

                $scope.stateChanged = true;
                $scope.saveState();
              });

              /**
               * external(ajax) filtering
               */
              if ($scope.gridOptions.useExternalFiltering) {
                var timeout, updatingResource;
                gridApi.core.on.filterChanged($scope, function () {
                  var filters = [];
                  // get column's terms
                  angular.forEach(this.grid.columns, function (column) {
                    if (column.filters[0].term) {
                      filters.push({
                        '@class': 'de.icash.pagination.filter.' + (column.colDef.filterType ? column.colDef.filterType : 'Like'),
                        field: column.colDef.filterPathFieldName ? column.colDef.filterPathFieldName : column.name + ($scope.metadata.columns[column.name] && $scope.metadata.columns[column.name].dependency ? '.name' : ''),
                        value: column.filters[0].term
                      })
                    }
                  });

                  // add last modified filter
                  if (!$scope.all) {
                    filters.push({
                      '@class': 'de.icash.pagination.filter.Between',
                      field: 'modified',
                      from: securityService.context.sessionStart
                    });
                  }

                  if ($scope.showOnlyActive) {
                    filters.push({
                      '@class': 'de.icash.pagination.filter.BooleanEquals',
                      field: 'active',
                      value: true
                    });
                  }

                  // set columns sort options according priorities
                  $scope.paginationOptions.pagination.filters = filters;

                  // don't load data before it not be loaded first time
                  if (!$scope.dataLoaded) {
                    return;
                  }

                  // if there was a previous attempt, stop it.
                  if (timeout) {
                    $timeout.cancel(timeout);
                  }
                  if (updatingResource) {
                    updatingResource.$cancelRequest();
                  }

                  // start a new attempt with a delay to keep it from
                  // getting too "chatty".
                  timeout = $timeout(function () {
                    updatingResource = updateData();
                  }, 200);
                });
              }

              /**
               * external(ajax) sorting
               */
              if ($scope.gridOptions.useExternalSorting) {
                gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
                  var sorts = [];
                  // get list of sorted columns with direction and pryority
                  $scope.paginationOptions.pagination.sorts = [];
                  angular.forEach(sortColumns, function (column) {
                    sorts.push({
                      field: column.colDef.filterPathFieldName ? column.colDef.filterPathFieldName : column.name + ($scope.metadata.columns[column.name] && $scope.metadata.columns[column.name].dependency ? '.name' : ''),
                      order: column.sort.direction.toUpperCase(),
                      priority: column.sort.priority
                    });
                  });

                  // set columns sort options according priorities
                  $scope.paginationOptions.pagination.sorts = _.sortBy(sorts, 'priority');

                  // dont load data during init process
                  if (init) {
                    return;
                  }

                  updateData();
                });
              }

              // need for close custom widgets during navigation
              //todo o.logunov try to find better event to switcher off multiselect
              gridApi.cellNav.on.navigate($scope, function (newRowCol, oldRowCol) {
                if (oldRowCol && oldRowCol.col.colDef.multiselect) {
                  $rootScope.$broadcast('on-navigate');
                }
              });

              gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                $scope.rowsSelected = gridApi.selection.getSelectedCount() > 0;
              });

              gridApi.selection.on.rowSelectionChangedBatch($scope, function (rows) {
                $scope.rowsSelected = gridApi.selection.getSelectedCount() > 0;
              });
            };

            // Pagination widgets - start
            // init pagination
            if ($scope.config.enablePagination) {
              var pageSize = localStorageService.get(tableSaveStateKey + "_page_settings");

              // init page size
              $scope.pageSize = pageSize ? pageSize : 25;

              // init max showing pages size
              $scope.maxSize = 5;

              $scope.currentPage = 1;

              // init pagination options
              $scope.paginationOptions = {
                pagination: {
                  from: 0,
                  limit: $scope.pageSize,
                  filters: [],
                  sorts: []
                }
              };

              if ($scope.config.sortDefs) {
                $scope.paginationOptions = {
                  pagination: {
                    from: 0,
                    limit: $scope.pageSize,
                    filters: [],
                    sorts: $scope.config.sortDefs
                  }
                }
              }

              // Action on shange page
              $scope.pageChanged = function () {
                //($scope.currentPage - 1) because currentPage started from 1
                $scope.paginationOptions.pagination.from = ($scope.currentPage - 1) * $scope.pageSize;
                updateData();
              };

              // action on change page size
              $scope.setPageSize = function (size) {
                $scope.currentPage = 1;
                $scope.pageSize = size;
                $scope.paginationOptions.pagination.from = 0;
                $scope.paginationOptions.pagination.limit = $scope.pageSize;

                localStorageService.remove(tableSaveStateKey + "_page_settings");
                localStorageService.set(tableSaveStateKey + "_page_settings", $scope.pageSize);

                updateData();
              };
            }
            // Pagination widgets - end

            // all/last modified filter - start
            $scope.all = true;
            if ($scope.config.enableLastModifiedFilter) {

              $scope.lastModifiedFilter = function (showAll) {
                $scope.all = showAll;
                $scope.gridApi.core.raise.filterChanged();
              }
            }
            // all/last modified filter - end

            // all/active filter - start
            $scope.showOnlyActive = $scope.config.showOnlyActive;
            if ($scope.config.enableShowOnlyActiveFilter) {

              $scope.showOnlyActiveFilter = function (showOnlyActive) {
                $scope.showOnlyActive = showOnlyActive;
                $scope.gridApi.core.raise.filterChanged();

                localStorageService.remove(tableSaveStateKey + "_active_filter_settings");
                localStorageService.set(tableSaveStateKey + "_active_filter_settings", showOnlyActive);
              };

              $scope.showOnlyActive = localStorageService.get(tableSaveStateKey + "_active_filter_settings");
              if ($scope.showOnlyActive) {
                $scope.paginationOptions.pagination.filters.push({
                  '@class': 'de.icash.pagination.filter.BooleanEquals',
                  field: 'active',
                  value: true
                });
              }
            }
            // all/active filter - end

            // init table
            function loadSavedState() {

              if ($scope.gridOptions.disableSaveState) {
                init = false;
                $scope.defaultState = $scope.gridApi.saveState.save();
                return;
              }

              if (!$scope.defaultState) {
                // save default state
                $scope.defaultState = $scope.gridApi.saveState.save();

                // load pre-saved state
                var preSavedState = localStorageService.get(tableSaveStateKey);
                if (preSavedState) {
                  // delete if columns was deleted
                  preSavedState.columns = _.filter(preSavedState.columns, function (savedColumn) {
                    return !!_.find($scope.gridApi.grid.columns, function (actualColumns) {
                      return actualColumns.name == savedColumn.name;
                    })
                  });

                  // reset width of action column to calculated value
                  angular.forEach(preSavedState.columns, function (savedColumn) {
                    var actionColumn = _.find($scope.gridApi.grid.columns, function (actualColumns) {
                      return actualColumns.name == savedColumn.name;
                    });
                    var isActionColumn = !!actionColumn.colDef && !!actionColumn.colDef.actions;
                    if (isActionColumn) {
                      savedColumn.width = actionColumn.colDef.width;
                    }
                  });

                  // add if columns was added
                  var addedColumns = _.filter($scope.defaultState.columns, function (defaultColumn) {
                    return !_.find(preSavedState.columns, function (actualColumns) {
                      return actualColumns.name == defaultColumn.name;
                    })
                  });

                  if (addedColumns && addedColumns.length > 0) {
                    addedColumns.forEach(function (column) {
                      preSavedState.columns.splice(preSavedState.columns.length - 1, 0, column);
                    });
                  }

                  $scope.gridApi.saveState.restore($scope, preSavedState);
                  $scope.stateChanged = true;
                  var gridWidth = preSavedState.columns.reduce(function (sum, column) {
                    return sum + (column.visible ? column.width : 0);
                  }, 0);

                  if ($scope.config.enableRowSelection) {
                    gridWidth += selectionCheckboxColumnWidth;
                  }

                  $scope.gridApi.grid.columns.forEach(function (column) {
                    column.drawnWidth = column.width;
                    column.colDef.width = column.width;
                  });
                  updateGridWidth(gridWidth);
                  updateContainerWidth(gridWidth);
                }

                init = false;
              }
            }

            function addFocusScrollEvents() {
              //workaround for focus issue
              var x, y;
              $window.onscroll = function () {
                x = $window.scrollX;
                y = $window.scrollY;
              };

              element.find('.ui-grid-focuser').on('focus', function (evt) {
                $window.scrollTo(x, y);
                return;
              });
            }

            function initTable() {
              // waite loading action templates
              $timeout(function () {
                if ($scope.loadTemplateCount === 0) {
                  // load config
                  angular.extend($scope.gridOptions, $scope.config);

                  // add column description
                  $scope.gridOptions.columnDefs = $scope.config.columns;

                  // before loading process we mast be sure that default state are loaded by digest
                  $timeout(function () {
                    // load pre-saved state
                    loadSavedState();

                    //workaround for focus issue
                    addFocusScrollEvents();

                    // load data
                    updateData();
                  });

                } else {
                  initTable();
                }
              }, 10);
            }

            initTable();

            // set height during loading, for hiding unloaded table controls
            updateGridHeight();

            // add watcher
            // increase height after adding new row
            $scope.$watch("gridOptions.data.length", function (newValue, oldValue) {
              if (newValue - oldValue === 1) {
                updateGridHeight();
              }
            });

            /**
             * view form controller section
             */

            // Grid widget - start

            // calculate actual grid height
            function getTableHeight(numbers) {
              if ($scope.gridOptions.data) {
                var headerHeight = $scope.config.enableFiltering ? 62 : 32;
                var length = numbers != undefined ? numbers : $scope.gridOptions.data.length;
                var dataGridHeight = (length * rowHeight);
                return (dataGridHeight ? dataGridHeight : rowHeight) + headerHeight + 'px';
              }
            }

            /**
             *
             * @param numbers - number of rows
             */
            function updateGridHeight(numbers) {
              if (!$scope.gridSize) {
                $scope.gridSize = {};
              }
              $scope.gridSize.height = getTableHeight(numbers);
            }

            // Grid widget - end

            // Add new entity button - start
            if ($scope.config.enableAdd) {
              $scope.add = function () {
                $state.go($state.current.name + 'Add', $stateParams);
              };
            }
            // Add new entity button - end


            // configuring visible columns - start
            $scope.isActionColumn = function (column) {
              return 'actions' in column;
            };

            /**
             * Customizable grid section, calculation sizes
             */
            $scope.showColumn = function (colDef) {
              colDef.visible = true;
              var gridColumn = $scope.gridApi.grid.columns.find(function (column) {
                return colDef.name == column.name;
              });

              gridColumn.visible = true;

              var gridWidth = calculateGridWidth($scope.gridApi.grid.columns);

              updateGridWidth(gridWidth);
              updateContainerWidth(gridWidth);
              $scope.saveState();

              $scope.stateChanged = true;
            };

            $scope.showColumns = function () {
              $scope.gridApi.grid.columns.forEach(function (column) {
                column.visible = true;
                column.colDef.visible = true;
              });

              var gridWidth = calculateGridWidth($scope.gridApi.grid.columns);

              updateGridWidth(gridWidth);
              updateContainerWidth(gridWidth);
              $scope.saveState();

              $scope.stateChanged = true;
            };
            // configuring visible columns - end

            // calculating width of grid
            function calculateGridWidth(columns) {
              return columns.reduce(function (sum, column) {
                return sum + (column.colDef.visible != false ? column.width : 0);
              }, 0);
            }

            // update width of grid
            function updateGridWidth(gridWidth) {
              if (!$scope.gridSize) {
                $scope.gridSize = {};
              }
              $scope.gridSize.width = gridWidth + 'px';
            }

            // calculate width of columns where it was skipped
            function getWidthForUnsizedColumn(colDef) {
              // (- 2) minus borders
              var totalWidth = element.find('.gridContainer').outerWidth(false) - 2;

              // exclude selectin column
              if ($scope.config.enableRowSelection) {
                totalWidth -= selectionCheckboxColumnWidth;
              }

              // width that already set
              var setWidth = colDef.reduce(function (sum, column) {
                return sum + (column.width && column.visible != false ? column.width : 0);
              }, 0);

              // column without width
              var columnWithoutUnsetWidth = colDef.reduce(function (sum, column) {
                return sum + (column.width || column.visible == false ? 0 : 1);
              }, 0);

              // width that need to set to the columns without width
              var width = (totalWidth - setWidth) / columnWithoutUnsetWidth;

              // limit min column size
              width = width >= 60 ? width : 60;
              return width;
            }

            function updateColumnsWidth(colDef) {
              var width = getWidthForUnsizedColumn(colDef);
              colDef.forEach(function (column) {
                if (!column.width) {
                  column.width = width;
                }
              });
            }

            // states section
            function getStateStorageKey() {
              return 'grid' + ($scope.index != undefined ? $scope.index : '') + $state.current.url.replace(new RegExp('/', 'g'), "") + securityService.context.currentOperator.id + ":" + securityService.context.currentUser.id;
            }

            $scope.saveState = function () {
              if ($scope.gridOptions.disableSaveState) {
                return;
              }
              localStorageService.remove(tableSaveStateKey);
              localStorageService.set(tableSaveStateKey, $scope.gridApi.saveState.save());

            };

            $scope.resetState = function () {
              $scope.resetting = true;
              $scope.gridApi.saveState.restore($scope, $scope.defaultState);
              var width = $scope.defaultState.columns.reduce(function (sum, column) {
                return sum + (column.visible ? column.width : 0);
              }, 0);
              if ($scope.config.enableRowSelection) {
                width += selectionCheckboxColumnWidth;
              }
              updateGridWidth(width);
              updateContainerWidth(width);
              $scope.stateChanged = false;
              localStorageService.remove(tableSaveStateKey);

              // reset last modified filter
              if ($scope.lastModifiedFilter && !$scope.all) {
                $scope.lastModifiedFilter(true);
              }

              if ($scope.showOnlyActiveFilter && $scope.showOnlyActive) {
                $scope.showOnlyActiveFilter(false);
              }

              $scope.gridApi.grid.columns.forEach(function (column) {
                column.drawnWidth = column.width;
                column.colDef.width = column.width;
              });

              $timeout(function () {
                $scope.resetting = false;
              }, 100);
            };
            // end - states section

            /**
             * End - customizable grid section
             */

            /**
             * Group actions
             */
            $scope.groupAction = function (action) {
              var entities = $scope.gridApi.selection.getSelectedRows();
              if (entities && entities.length > 0) {
                action.action(entities);
              }
            };

            /**
             * Create column description section
             */
            function mergeColumnParameters(columns, metadata) {
              _.each(columns, function (column) {
                // add actions
                if ('actions' in column) {
                  addActionButtons(column);
                  return;
                }

                // todo: o.logunov migrate to map
                var columnMetadata = _.find(metadata.columns, function (metadataItem) {
                  return metadataItem.id == column.name;
                });

                // set up column hiding(default false)
                column.enableHiding = column.enableHiding ? column.enableHiding : $scope.config.enableColumnsHiding ? $scope.config.enableColumnsHiding : false;

                // add disabled-cell class to disabled cells
                var originalCellClass = column.cellClass;
                column.cellClass = function (grid, row, col, rowRenderIndex, colRenderIndex) {
                  if ((!_.isUndefined(column.enableCellEdit) && !column.enableCellEdit) ||
                    (_.isFunction(column.cellEditableCondition) && !column.cellEditableCondition({
                      row: row,
                      col: col
                    }))) {
                    var cellClass = originalCellClass ? originalCellClass(grid, row, col, rowRenderIndex, colRenderIndex) : '';
                    return cellClass ? cellClass + ' disabled-cell' : 'disabled-cell';
                  }
                  return originalCellClass ? originalCellClass(grid, row, col, rowRenderIndex, colRenderIndex) : '';
                };

                column.headerCellFilter = 'translate';

                // column not in entity
                if (!columnMetadata) {
                  return;
                }

                // disable cell according to metadata, but don't make editable if column is disabled on configuration level
                if (!_.isUndefined(columnMetadata.editable) &&
                  (_.isUndefined(column.enableCellEdit) || column.enableCellEdit !== false)) {
                  column.enableCellEdit = columnMetadata.editable;
                }

                // add column header
                column.displayName = column.displayName || columnMetadata.nameCode;

                var prefilledCellClass = column.cellClass;

                // add validation class
                column.cellClass = function (grid, row, col, rowRenderIndex, colRenderIndex) {
                  if (!isColumnObjectValid(row.entity, col.colDef.name)) {
                    return 'warning';
                  } else {
                    return prefilledCellClass ? prefilledCellClass(grid, row, col, rowRenderIndex, colRenderIndex) : "";
                  }

                };

                // create selection list if dependency exist
                if ($scope.context.data.dependencies && $scope.context.data.dependencies[column.name]) {
                  createSelectionList(column);
                } else {
                  // in other case set type and template
                  setTypeAndTemplates(column, columnMetadata);
                }

                // move fast entry row down
                if ($scope.config.enableAddRow) {
                  // sorting algorithm
                  column.sortingAlgorithm = function (propA, propB, rowA, rowB, direction) {
                    // if rowA is fast entry row
                    if (rowA.entity.id == null) {
                      return direction === 'asc' ? 1 : -1;
                    }

                    // if rowB is fast entry row
                    if (rowB.entity.id == null) {
                      return direction === 'asc' ? -1 : 1;
                    }

                    // if usual rows use standard algorithm
                    var sortFn = rowSorter.guessSortFn(column.type);
                    return sortFn(propA, propB, rowA, rowB, direction);
                  };
                }

                column.cellTooltip = _.defaultTo(column.cellTooltip, true);
                column.headerTooltip = _.defaultTo(column.headerTooltip, true);
              });
            }

            // create selection list depending on dependencies
            function createSelectionList(column) {

              column.type = "object";

              column.editDropdownOptionsArray = $scope.context.data.dependencies[column.name];

              if (column.multiselect) {
                $scope.multiselectDefaultSettings.enableSearch = column.enableMultiselectFiltering;
                $scope.multiselectDefaultSettings.showCheckAll = column.enableMultiselectFiltering;
                $scope.multiselectDefaultSettings.showUncheckAll = column.enableMultiselectFiltering;

                $scope.multiselectDefaultSettings.smartButtonMaxItems = column.maxVisibleItems ? column.maxMultiselectVisibleItems : $scope.multiselectDefaultSettings.smartButtonMaxItems;
                column.cellFilter = column.cellFilter ? column.cellFilter : 'multiselectFilter:' + $scope.multiselectDefaultSettings.smartButtonMaxItems;
                column.enableSorting = false;
                column.enableFiltering = false;

                column.editableCellTemplate = column.editableCellTemplate || '<ui-select-wrap ng-class="\'colt\' + col.uid" style="width: inherit" ui-grid-edit-dropdown><div style="width: inherit" id="selection{{row.entity.id}}" ng-hide="row.entity.id==-1" gf-multiselect="" options="col.colDef.editDropdownOptionsArray" ng-model="MODEL_COL_FIELD" extra-settings="grid.appScope.multiselectDefaultSettings"></div></ui-select-wrap>';

                return;
              }


              if (_.isUndefined(column.showEmpty)) {
                column.showEmpty = _.isUndefined($scope.metadata.columns[column.name].angular.required);
              }


              var isSimpleSelect = function (options) {
                return options.length && _.isString(options[0])
              };


              // if dependencies are array of string (for example ["a", "b", "c"]), draw simple select
              if (isSimpleSelect(column.editDropdownOptionsArray)) {
                column.editableCellTemplate = "<div><form name=\"inputForm\"><select class=\"form-control\" ng-class=\"'colt' + col.uid\" ui-grid-edit-dropdown ng-model=\"MODEL_COL_FIELD\" ng-options=\"field CUSTOM_FILTERS for field in editDropdownOptionsArray track by field\"><option ng-if=\"col.colDef.showEmpty\" value=\"\" translate=\"{{col.colDef.showEmptyText || 'option.select'}}\"></option></select></form></div>";
              } else {
                // add nedded parameters
                column.editDropdownIdLabel = 'id';
                column.editDropdownValueLabel = 'name';
                column.field = column.name + '.name';
                column.editModelField = column.name;

                // add template
                column.editableCellTemplate = column.editableCellTemplate ? column.editableCellTemplate : "<div><form name=\"inputForm\"><select class=\"form-control\" ng-class=\"'colt' + col.uid\" ui-grid-edit-dropdown ng-model=\"MODEL_COL_FIELD\"  ng-options=\"field[editDropdownValueLabel] CUSTOM_FILTERS disable when field.disabled for field in editDropdownOptionsArray track by field[editDropdownIdLabel]\"><option ng-if=\"col.colDef.showEmpty\" value=\"\" translate=\"{{col.colDef.showEmptyText || 'option.select'}}\"></option></select></form></div>";
              }

            }

            // set type of column and specific templates, default type is 'string'
            // add specific properties(filters, templates) for representation and editing fields
            function setTypeAndTemplates(column, columnMetadata) {
              if (columnMetadata.javaType === 'java.lang.Date'
                || columnMetadata.javaType === 'java.util.Date'
                || columnMetadata.javaType === 'java.time.LocalDate') {
                column.type = 'date';
                column.cellFilter = column.cellFilter ? column.cellFilter : 'date:"shortDate"';
                column.editableCellTemplate = column.editableCellTemplate ? column.editableCellTemplate : '<div><form name="inputForm"><div ui-grid-edit-datepicker ng-class="\'colt\' + col.uid"></div></form></div>';
              } else if (columnMetadata.javaType === 'int'
                || columnMetadata.javaType === 'short'
                || columnMetadata.javaType === 'byte'
                || columnMetadata.javaType === 'long') {
                column.type = 'number';

              } else if (columnMetadata.javaType === 'double'
                || columnMetadata.javaType === 'float') {
                column.type = 'number';
                column.editableCellTemplate = column.editableCellTemplate ? column.editableCellTemplate : "<div><form name=\"inputForm\"><input ignore-mouse-wheel type=\"INPUT_TYPE\" step=\"0.01\" ng-class=\"'colt' + col.uid\" ui-grid-editor ng-model=\"MODEL_COL_FIELD\"></form></div>";
              } else if (columnMetadata.javaType === 'java.math.BigDecimal') {
                column.type = 'number';
                column.cellFilter = typeof column.cellFilter !== 'undefined' ? column.cellFilter : 'number:2';
                column.editableCellTemplate = column.editableCellTemplate ? column.editableCellTemplate : "<div><form name=\"inputForm\"><input ignore-mouse-wheel number-input type=\"INPUT_TYPE\" step=\"0.01\" ng-class=\"'colt' + col.uid\" ui-grid-editor ng-model=\"MODEL_COL_FIELD\"></form></div>";
              } else if (columnMetadata.javaType === 'boolean') {
                column.type = 'boolean';
                column.enableFiltering = false;
                column.cellTemplate = column.cellTemplate ? column.cellTemplate : '<div class="ui-grid-cell-contents" ng-show="row.entity.id!=null||row.entity.filled"><i ng-show="COL_FIELD" class="far fa-check-square"></i><i ng-hide="COL_FIELD" class="far fa-square"></i></div>';
                column.editableCellTemplate = '<div tabindex="0" autofocus class="ui-grid-cell-focus ui-grid-cell-contents" ui-grid-editor ng-model="MODEL_COL_FIELD" ng-click="MODEL_COL_FIELD = !MODEL_COL_FIELD"><i ng-show="MODEL_COL_FIELD" class="far fa-check-square"></i><i ng-hide="MODEL_COL_FIELD" class="far fa-square"></i></div>';
              }
            }

            function addActionButtons(column) {
              // create buttons template - start
              column.cellTemplate = '<div class="grid-action-row" ng-hide="row.entity.addRow || grid.options.hideActions(row.entity)">';

              var columnWidth = 0;

              var index = 0;

              if (column.alternativeActions) {
                if (!column.showAlternativeActions) {
                  $log.error("You can't use 'alternativeActions' without 'showAlternativeActions'. Specify it!");
                }
                column.cellTemplate += '<div ng-hide="col.colDef.showAlternativeActions(row.entity)">'
              }

              // add actions
              angular.forEach(column.actions, function (actionName) {
                // find action description
                var action = _.find($scope.config.registerActions, function (actionDescription) {
                  return actionName == actionDescription.name;
                });

                // if nothing found show error and proceed with another one
                if (!action) {
                  $log.error('\"' + actionName + '\"' + ' action does not exist');
                  return;
                }

                // put place label for action.
                // It need for locate actions in right order during ajax loading templates.
                var actionPlace = 'ACTION_PLACE_' + index;
                column.cellTemplate += actionPlace;
                index++;

                $scope.loadTemplateCount++;

                // get template by request
                $templateRequest(action.template).then(function (template) {

                  // action, that run appropriate controller
                  $scope[actionName] = function (entity) {
                    var ctrlLocals = {};
                    ctrlLocals.$scope = $scope.$new();
                    ctrlLocals.entity = entity;
                    ctrlLocals.gridApi = $scope.gridApi;

                    angular.forEach(action.resolve, function (value, key) {
                      ctrlLocals[key] = value;
                    });


                    $controller(action.controller, ctrlLocals);
                  };

                  // create template, replace all wildcards(ACTION, ENTITY)
                  column.cellTemplate = column.cellTemplate.replace(actionPlace, template.replace(new RegExp('ACTION', 'g'), 'grid.appScope.' + actionName + '(row.entity)').replace(new RegExp('ENTITY', 'g'), 'row.entity'));

                  $scope.loadTemplateCount--;
                });

                columnWidth += 34;

              });

              if (column.alternativeActions) {
                column.cellTemplate += '</div><div ng-show="col.colDef.showAlternativeActions(row.entity)">'

                var alternativeColumnWidth = 0;

                angular.forEach(column.alternativeActions, function (actionName) {
                  // find action description
                  var action = _.find($scope.config.registerActions, function (actionDescription) {
                    return actionName == actionDescription.name;
                  });

                  // if nothing found show error and proceed with another one
                  if (!action) {
                    $log.error('\"' + actionName + '\"' + ' action does not exist');
                    return;
                  }

                  // put place label for action.
                  // It need for locate actions in right order during ajax loading templates.
                  var actionPlace = 'ACTION_PLACE_' + index;
                  column.cellTemplate += actionPlace;
                  index++;

                  $scope.loadTemplateCount++;

                  // get template by request
                  $templateRequest(action.template).then(function (template) {

                    // action, that run appropriate controller
                    $scope[actionName] = function (entity) {
                      var ctrlLocals = {};
                      ctrlLocals.$scope = $scope.$new();
                      ctrlLocals.entity = entity;
                      ctrlLocals.gridApi = $scope.gridApi;

                      angular.forEach(action.resolve, function (value, key) {
                        ctrlLocals[key] = value;
                      });


                      $controller(action.controller, ctrlLocals);
                    };

                    // create template, replace all wildcards(ACTION, ENTITY)
                    column.cellTemplate = column.cellTemplate.replace(actionPlace, template.replace(new RegExp('ACTION', 'g'), 'grid.appScope.' + actionName + '(row.entity)').replace(new RegExp('ENTITY', 'g'), 'row.entity'));

                    $scope.loadTemplateCount--;
                  });

                  alternativeColumnWidth += 34;

                });
                column.cellTemplate += '</div>';

                if (alternativeColumnWidth > columnWidth) {
                  columnWidth = alternativeColumnWidth;
                }
              }

              column.cellTemplate += '</div>';
              // create buttons template - end

              // add action button column specific settings
              column.width = column.width ? column.width : columnWidth;
              column.enableColumnResizing = false;
              column.displayName = column.displayName ? column.displayName : '';
              column.enableSorting = false;
              column.enableFiltering = false;
              column.enableCellEdit = false;
              column.cellClass = function () {
                return 'action-cell'
              };
              column.enableColumnMenu = column.enableHiding ? column.enableHiding : false;
            }

            /**
             * Load or update data section
             */
            function updateData() {
              var loadDataCallback = function (data) {
                if (data.ok) {
                  // get parameter for pagination
                  if (data.totals) {
                    $scope.totalItems = data.totals;
                  }

                  if ($scope.context.data && $scope.context.data.totalNumber) {
                    $scope.gridOptions.dataItemsNumber = $scope.context.data.totalNumber;
                  } else {
                    $scope.gridOptions.dataItemsNumber = 0;
                  }

                  $scope.gridOptions.data = data.payload.data;

                  if ($scope.additionalSettings) {
                    if ($scope.additionalSettings.excludeById) {
                      _.forEach($scope.additionalSettings.excludeById, function (excludeById) {
                        if (excludeById.exceptThemes && !_.includes(excludeById.exceptThemes, $scope.security.context.theme.name)) {
                          $scope.gridOptions.data = _.reject($scope.gridOptions.data, function (o) {
                            return o.id === excludeById.id;
                          });
                        }
                      })
                    }
                  }

                  // add empty row for fast add new entries
                  if ($scope.config.enableAddRow && $scope.gridOptions.canAdd($scope.gridApi.grid) && (!$scope.config.maxDataNumber || ($scope.gridOptions.dataItemsNumber < $scope.config.maxDataNumber))) {
                    $scope.gridOptions.data.push(getAddRowObject());
                  }

                  if ($scope.gridOptions.prepareData) {
                    $scope.gridOptions.prepareData(data.payload.data, $scope.context)
                  }

                  $scope.dataLoaded = true;
                  updateGridHeight();
                }
              };
              if ($scope.config.enablePagination) {
                return getAllResource.getPage($scope.paginationOptions, loadDataCallback);
              } else {
                return getAllResource.get(loadDataCallback);
              }
            }

            function getAddRowObject() {
              var addRow = angular.copy($scope.context.template);
              for (var property in addRow) {
                if (addRow.hasOwnProperty(property)) {
                  if (!(addRow[property] instanceof Array)) {
                    addRow[property] = null;
                  } else {
                    addRow[property] = [];
                  }
                }
              }
              addRow.addRow = true;
              return addRow;
            }

            /**
             * validation section
             */
            // Validate column and return invalid validator
            // need for validate column without saving entity
            function getFailedValidator(colDef, value, entity) {
              //get actual validations
              var validations = $scope.metadata.columns[colDef.name] ? $scope.metadata.columns[colDef.name].angular : [];

              // add custom column validations
              angular.forEach(colDef.validations, function (validation, key) {
                validations[key] = validation.angular[key];
              });
              // init custom validations

              // fake controller for saving result
              var fakeController = {
                validationResult: [],
                $setValidity: function (message, isValid) {
                  this.validationResult.push({message: message, isValid: isValid});
                }
              };

              // gets column validators
              var validators = [];
              angular.forEach(validations, function (value, key) {
                var validator = gfStandardValidations[key](fakeController, value);

                // init custom validator if it needs
                if (colDef.validations && colDef.validations[key] && colDef.validations[key].init) {
                  colDef.validations[key].init(validator);
                }

                validators.push(validator);
              });

              // validate
              angular.forEach(validators, function (validator) {
                validator(value, entity, $scope.context);
              });

              var failedValidator = _.find(fakeController.validationResult, function (result) {
                return !result.isValid;
              });
              return failedValidator;
            }

            // validate column and save invalid in entity
            // it creates validity list in entity and add column if it is invalid
            function validate(entity, colDef, value) {
              // create array with invalid fields of entity
              if (entity.notValidColumns == undefined) {
                entity.notValidColumns = [];
              }

              var failedValidator = getFailedValidator(colDef, value, entity);
              //if nothing find exit
              if (!failedValidator) {

                setColumnStatusValid(entity, colDef);
                return true;
              }

              // check if it's new invalid column, save it and show warning
              if (entity.notValidColumns.indexOf(colDef.name) == -1) {
                // save column name as invalid
                entity.notValidColumns.push(colDef.name);
              }

              // find and show message
              // find message from columns metadata
              var messages = $scope.metadata.columns[colDef.name].errorMessages;
              var message = _.find(messages, function (message) {
                return message.keys.indexOf(failedValidator.message) > -1;
              });

              // find message from custom validations
              if (!message) {
                message = {};
                message.message = colDef.validations[failedValidator.message].errorMessage;
                if (colDef.validations[failedValidator.message].errorMessageArg) {
                  var argName = colDef.validations[failedValidator.message].errorMessageArg;
                  message.args = {};
                  message.args[argName] = colDef.validations[failedValidator.message].errorMessageArgConstant || value;
                } else if (colDef.validations[failedValidator.message].errorMessageArgsConstants) {
                  message.args = colDef.validations[failedValidator.message].errorMessageArgsConstants;
                }
              }

              var messageValue = $translate.instant(message.message, message.args);

              notifyManager.error(messageValue);

              return false;
            }

            // remove valid column from invalid list
            function setColumnStatusValid(entity, colDef) {

              var index = entity.notValidColumns.indexOf(colDef.name);
              if (index > -1) {
                entity.notValidColumns.splice(index, 1);
              }
            }

            // Return validity of column
            function isColumnObjectValid(entity, columnName) {
              return !(entity.notValidColumns && entity.notValidColumns.indexOf(columnName) > -1);
            }
          },
          controller: function ($scope) {

          }
        };
      }
    )
    .directive('ignoreMouseWheel', function () {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.bind('mousewheel', function (event) {
            event.preventDefault();
          });
        }
      }
    })
    .filter('prefixFilter', function () {
      return function (input, prefix) {
        if (input) {
          return prefix + input;
        }
      }
    })
    .filter('postfixFilter', function () {
      return function (input, postfix) {
        if (input) {
          return input + postfix;
        }
      }
    })
    .filter('multiselectFilter', ['$translate', function ($translate) {
      return function (input, max) {
        if (input.length > max) {
          return $translate.instant('multiselect.selectedText', {number: input.length});
        }
        var value = _.map(_.sortBy(input, 'name'), 'name');
        return _(value).toString();
      };
    }])
    /**
     * Standard actions controllers - start
     */
    .controller('RemoveRowGridCtrl', ['$scope', '$uibModal', '$translate', 'notifyManager', 'entity', 'gridApi', 'EntityResource',

      function ($scope, $uibModal, $translate, notifyManager, entity, gridApi, EntityResource) {
        $uibModal.open({
          template: require('../../../../../main/angular/app/template/grid/delete_confirmation.html'),
          scope: $scope,
          windowClass: "gf-modal"
        }).result.then(function () {

          var entityResource = new EntityResource(entity);

          entityResource.$delete({id: entity.id}, function (data) {
            if (data.ok) {
              gridApi.grid.options.deleteRow(entity);
              notifyManager.info($translate.instant('grid.entriesDeleted', {number: 1}));
            } else {
              notifyManager.error($translate.instant(data.message));
              if (data.message.toLowerCase().indexOf("not_exists") > -1) {
                gridApi.grid.options.reloadData();
              }
            }
          });
        });
      }])
    .controller('EditRowGridCtrl',
      function (entity, $state, $stateParams) {
        $stateParams.id = entity.id;
        $state.go($state.current.name + 'Details', $stateParams);
      })
    .controller('ActivateRowGridCtrl',
      function ($scope, $translate, $uibModal, notifyManager, $resource, uiGridConstants, entity, gridApi, url, entityName, appConfig, $state, $rootScope) {
        // for entities that have PK that contains id and belongsTo.id
        var belongsToParam = entity.belongsTo ? {'belongsTo': entity.belongsTo.id} : {};
        var ActivateResource = $resource(appConfig.baseUrl + "/icash" + url + "/:id/activate", belongsToParam, {
          activate: {method: 'PUT'}
        });

        var instanceActivateResource = new ActivateResource();

        var activateCallback = function (data) {
          if (data.payload.error == 0) {
            if (!data.payload.entity.active && $state.current.name === 'masterdataArticle') {
              $uibModal.open({
                template: require('../../../../../main/angular/app/template/grid/deactivate_info.html'),
                scope: $scope,
                windowClass: "gf-modal"
              });
            }

            $rootScope.$broadcast('changeActivation', {active: data.payload.entity.active});

            angular.merge(entity, data.payload.entity);
            gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
          } else if (data.payload.error == 3) {
            notifyManager.info($translate.instant(entityName + '.restricted.warning') || $translate.instant('activable.restricted'));
          }

        };

        if (entity.active) {
          // ask before deactivate
          $scope.pleasWateDialog = $uibModal.open({
            template: require('../../../../../main/angular/app/template/grid/deactivate_confirmation.html'),
            scope: $scope,
            windowClass: 'gf-modal'
          }).result.then(
            function () {
              instanceActivateResource.$activate({
                id: entity.id,
                active: false
              }, activateCallback);
            }
          );
        } else {
          // activate
          instanceActivateResource.$activate({
            id: entity.id,
            active: true
          }, activateCallback);
        }
      }
    )
    /**
     * Standard actions controllers - end
     */
    // wrapper for multislect, provide END_CELL_EDIT event to close ond save
    // there is no way to implement it better (link to author of solution http://brianhann.com/ui-grid-and-dropdowns/)
    .directive('uiSelectWrap', function ($document, uiGridEditConstants, $timeout) {
      return {
        restrict: 'EAC',
        link: function ($scope) {

          $scope.$on('on-navigate', docClick);
          $document.on('click', docClick);

          function docClick(evt) {
            if ($(evt.target).closest('.multiselect-parent').size() === 0) {
              $document.off('click', docClick);
              $timeout(function () {
                $scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
              }, 100);
            }
          }
        }
      }
    })
    // using for attach multiselect to body
    .factory('uisOffset', ['$document', '$window', function ($document, $window) {

      return function (element) {
        var boundingClientRect = element[0].getBoundingClientRect();
        return {
          width: boundingClientRect.width || element.prop('offsetWidth'),
          height: boundingClientRect.height || element.prop('offsetHeight'),
          top: boundingClientRect.top + ($window.pageYOffset || $document[0].documentElement.scrollTop),
          left: boundingClientRect.left + ($window.pageXOffset || $document[0].documentElement.scrollLeft)
        };
      };
    }])
    // multiselect directive modified for ui-grid
    .directive('gfMultiselect', ['$filter', '$document', '$compile', '$parse', '$translate', 'uisOffset', '$timeout',
      function ($filter, $document, $compile, $parse, $translate, uisOffset, $timeout) {

        return {
          restrict: 'AE',
          require: "ngModel",
          scope: {
            ngModel: '=',
            options: '=',
            extraSettings: '=',
            events: '=',
            searchFilter: '=?',
            translationTexts: '=',
            groupBy: '@'
          },
          template: function (element, attrs) {
            var checkboxes = attrs.checkboxes ? true : false;
            var groups = attrs.groupBy ? true : false;

            var template = '<div style="width: inherit" class="multiselect-parent btn-group dropdown-multiselect">';
            template += '<button type="button" class="dropdown-toggle grid-multiselect-button" ng-class="settings.buttonClasses" ng-click="toggleDropdown()">{{getButtonText()}}&nbsp;<i class="fa fa-caret-down pull-right triangle-multiselect"></i></button>';
            template += '<div class="dropdown-list">';
            template += '<ul class="dropdown-menu dropdown-menu-form" ng-style="{display: open ? \'block\' : \'none\', height : settings.scrollable ? settings.scrollableHeight : \'auto\' }" style="overflow: scroll" >';
            template += '<li ng-hide="!settings.showCheckAll || settings.selectionLimit > 0"><a data-ng-click="selectAll()"><span class="glyphicon glyphicon-ok"></span>  {{texts.checkAll}}</a>';
            template += '<li ng-show="settings.showUncheckAll"><a data-ng-click="deselectAll();"><span class="glyphicon glyphicon-remove"></span>   {{texts.uncheckAll}}</a></li>';
            template += '<li ng-hide="(!settings.showCheckAll || settings.selectionLimit > 0) && !settings.showUncheckAll" class="divider"></li>';
            template += '<li ng-show="settings.enableSearch"><div class="dropdown-header"><input type="text" class="form-control" style="width: 100%;" ng-model="searchFilter" placeholder="{{texts.searchPlaceholder}}" /></li>';
            template += '<li ng-show="settings.enableSearch" class="divider"></li>';

            if (groups) {
              template += '<li ng-repeat-start="option in orderedItems | filter: searchFilter" ng-show="getPropertyForObject(option, settings.groupBy) !== getPropertyForObject(orderedItems[$index - 1], settings.groupBy)" role="presentation" class="dropdown-header">{{ getGroupTitle(getPropertyForObject(option, settings.groupBy)) }}</li>';
              template += '<li ng-repeat-end role="presentation">';
            } else {
              template += '<li role="presentation" ng-repeat="option in options | filter: searchFilter">';
            }

            template += '<a role="menuitem" tabindex="-1" ng-click="setSelectedItem(getPropertyForObject(option,settings.idProp))">';

            if (checkboxes) {
              template += '<div class="checkbox"><label><input class="checkboxInput" type="checkbox" ng-click="checkboxClick($event, getPropertyForObject(option,settings.idProp))" ng-checked="isChecked(getPropertyForObject(option,settings.idProp))" /> {{getPropertyForObject(option, settings.displayProp)}}</label></div></a>';
            } else {
              template += '<span data-ng-class="{\'glyphicon glyphicon-ok\': isChecked(getPropertyForObject(option,settings.idProp))}"></span> {{getPropertyForObject(option, settings.displayProp)}}</a>';
            }

            template += '</li>';

            template += '<li class="divider" ng-show="settings.selectionLimit > 1"></li>';
            template += '<li role="presentation" ng-show="settings.selectionLimit > 1"><a role="menuitem">{{selectedLength}} {{texts.selectionOf}} {{settings.selectionLimit}} {{texts.selectionCount}}</a></li>';

            template += '</ul>';
            template += '</div>';
            template += '</div>';

            element.html(template);
          },
          link: function ($scope, $element, $attrs, ngModelCtrl) {
            var $dropdownTrigger = $element.children()[0];

            $scope.checkboxClick = function ($event, id) {
              $scope.setSelectedItem(id);
              $event.stopImmediatePropagation();
            };

            $scope.externalEvents = {
              onItemSelect: angular.noop,
              onItemDeselect: angular.noop,
              onSelectAll: angular.noop,
              onDeselectAll: angular.noop,
              onInitDone: angular.noop,
              onMaxSelectionReached: angular.noop
            };

            $scope.settings = {
              dynamicTitle: true,
              scrollable: false,
              scrollableHeight: '300px',
              closeOnBlur: true,
              displayProp: 'label',
              idProp: 'id',
              externalIdProp: 'id',
              enableSearch: false,
              selectionLimit: 0,
              showCheckAll: true,
              showUncheckAll: true,
              closeOnSelect: false,
              buttonClasses: 'btn btn-default',
              closeOnDeselect: false,
              groupBy: $attrs.groupBy || undefined,
              groupByTextProvider: null,
              smartButtonMaxItems: 0,
              smartButtonTextConverter: angular.noop
            };

            $scope.texts = {
              checkAll: 'Check All',
              uncheckAll: 'Uncheck All',
              selectionCount: 'checked',
              selectionOf: '/',
              searchPlaceholder: 'Search...',
              buttonDefaultText: 'Select',
              dynamicButtonTextSuffix: 'checked'
            };

            $scope.searchFilter = $scope.searchFilter || '';

            if (angular.isDefined($scope.settings.groupBy)) {
              $scope.$watch('options', function (newValue) {
                if (angular.isDefined(newValue)) {
                  $scope.orderedItems = $filter('orderBy')(newValue, $scope.settings.groupBy);
                }
              });
            }

            angular.extend($scope.settings, $scope.extraSettings || []);
            angular.extend($scope.externalEvents, $scope.events || []);
            angular.extend($scope.texts, $scope.translationTexts);

            $scope.singleSelection = $scope.settings.selectionLimit === 1;

            function getFindObj(id) {
              var findObj = {};

              if ($scope.settings.externalIdProp === '') {
                findObj[$scope.settings.idProp] = id;
              } else {
                findObj[$scope.settings.externalIdProp] = id;
              }

              return findObj;
            }

            function clearObjectViewValue() {
              var copy = angular.copy(ngModelCtrl.$viewValue);
              for (var prop in copy) {
                delete copy[prop];
              }
              ngModelCtrl.$setViewValue(copy);
            }

            if ($scope.singleSelection) {
              if (angular.isArray(ngModelCtrl.$viewValue) && ngModelCtrl.$viewValue.length === 0) {
                clearObjectViewValue();
              }
            }

            if ($scope.settings.closeOnBlur) {
              $document.on('click', function (e) {
                var target = e.target.parentElement;
                var parentFound = false;

                while (angular.isDefined(target) && target !== null && !parentFound) {
                  if (_.includes(target.className.split(' '), 'multiselect-parent') && !parentFound) {
                    if (target === $dropdownTrigger) {
                      parentFound = true;
                    }
                  }
                  target = target.parentElement;
                }

                if (!parentFound) {
                  $scope.$apply(function () {
                    $scope.open = false;
                    resetDropdown();
                  });
                }
              });
            }

            $scope.getGroupTitle = function (groupValue) {
              if ($scope.settings.groupByTextProvider !== null) {
                return $scope.settings.groupByTextProvider(groupValue);
              }

              return groupValue;
            };

            $scope.getButtonText = function () {
              if ($scope.settings.dynamicTitle && ngModelCtrl.$viewValue.length > 0 && (ngModelCtrl.$viewValue.length > 0 || (angular.isObject(ngModelCtrl.$viewValue) && _.keys(ngModelCtrl.$viewValue).length > 0))) {
                if (ngModelCtrl.$viewValue.length > $scope.settings.smartButtonMaxItems) {
                  return $translate.instant('multiselect.selectedText', {number: ngModelCtrl.$viewValue.length});
                }

                if ($scope.settings.smartButtonMaxItems > 0) {
                  var itemsText = [];

                  angular.forEach($scope.options, function (optionItem) {
                    if ($scope.isChecked($scope.getPropertyForObject(optionItem, $scope.settings.idProp))) {
                      var displayText = $scope.getPropertyForObject(optionItem, $scope.settings.displayProp);
                      var converterResponse = $scope.settings.smartButtonTextConverter(displayText, optionItem);

                      itemsText.push(converterResponse ? converterResponse : displayText);
                    }
                  });

                  return itemsText.join(', ');
                } else {
                  var totalSelected;

                  if ($scope.singleSelection) {
                    totalSelected = (ngModelCtrl.$viewValue !== null && angular.isDefined(ngModelCtrl.$viewValue[$scope.settings.idProp])) ? 1 : 0;
                  } else {
                    totalSelected = angular.isDefined(ngModelCtrl.$viewValue) ? ngModelCtrl.$viewValue.length : 0;
                  }

                  if (totalSelected === 0) {
                    return $scope.texts.buttonDefaultText;
                  } else {
                    return totalSelected + ' ' + $scope.texts.dynamicButtonTextSuffix;
                  }
                }
              } else {
                return $scope.texts.buttonDefaultText;
              }
            };

            $scope.getPropertyForObject = function (object, property) {
              if (angular.isDefined(object) && object.hasOwnProperty(property)) {
                return object[property];
              }

              return '';
            };

            $scope.selectAll = function () {
              $scope.deselectAll(false);
              $scope.externalEvents.onSelectAll();

              angular.forEach($scope.options, function (value) {
                $scope.setSelectedItem(value[$scope.settings.idProp], true);
              });
            };

            $scope.deselectAll = function (sendEvent) {
              sendEvent = sendEvent || true;

              if (sendEvent) {
                $scope.externalEvents.onDeselectAll();
              }

              if ($scope.singleSelection) {
                clearObjectViewValue();
              } else {
                ngModelCtrl.$setViewValue([]);
              }
            };

            $scope.setSelectedItem = function (id, dontRemove) {
              var findObj = getFindObj(id);
              var finalObj = null;

              if ($scope.settings.externalIdProp === '') {
                finalObj = _.find($scope.options, findObj);
              } else {
                finalObj = findObj;
              }

              if ($scope.singleSelection) {
                ngModelCtrl.$setViewValue(finalObj);
                $scope.externalEvents.onItemSelect(finalObj);
                if ($scope.settings.closeOnSelect) $scope.open = false;

                return;
              }

              dontRemove = dontRemove || false;

              var exists = _.findIndex(ngModelCtrl.$viewValue, findObj) !== -1;
              var copy = angular.copy(ngModelCtrl.$viewValue);

              if (!dontRemove && exists) {
                copy.splice(_.findIndex(ngModelCtrl.$viewValue, findObj), 1);
                ngModelCtrl.$setViewValue(copy);
                $scope.externalEvents.onItemDeselect(findObj);
              } else if (!exists && ($scope.settings.selectionLimit === 0 || ngModelCtrl.$viewValue.length < $scope.settings.selectionLimit)) {
                copy.push(finalObj);
                ngModelCtrl.$setViewValue(copy);
                $scope.externalEvents.onItemSelect(finalObj);
              }
              if ($scope.settings.closeOnSelect) $scope.open = false;
            };

            $scope.isChecked = function (id) {
              if ($scope.singleSelection) {
                return ngModelCtrl.$viewValue !== null && angular.isDefined(ngModelCtrl.$viewValue[$scope.settings.idProp]) && ngModelCtrl.$viewValue[$scope.settings.idProp] === getFindObj(id)[$scope.settings.idProp];
              }

              return _.findIndex(ngModelCtrl.$viewValue, getFindObj(id)) !== -1;
            };

            $scope.externalEvents.onInitDone();

            // Support for appending the select field to the body when its open
            $scope.toggleDropdown = function () {
              $scope.open = !$scope.open;
              if ($scope.open) {
                positionDropdown();
              } else {
                resetDropdown();
              }

            };

            // Move the dropdown back to its original location when the scope is destroyed. Otherwise
            // it might stick around when the user routes away or the select field is otherwise removed
            $scope.$on('$destroy', function () {
              resetDropdown();
            });

            // Hold on to a reference to the .ui-select-container element for appendToBody support
            var placeholder = null,
              originalWidth = '';

            function positionDropdown() {
              // Remember the absolute position of the element
              var offset = uisOffset($element);

              // Clone the element into a placeholder element to take its original place in the DOM
              placeholder = angular.element('<div class="dropdown-list"></div>');
              placeholder[0].style.width = offset.width + 'px';
              placeholder[0].style.height = offset.height + 'px';
              $element.after(placeholder);

              // Remember the original value of the element width inline style, so it can be restored
              // when the dropdown is closed
              originalWidth = $element[0].style.width;

              // Now move the actual dropdown element to the end of the body
              $document.find('body').append($element);

              $element[0].style.position = 'absolute';
              $element[0].style.left = offset.left + 'px';
              $element[0].style.top = offset.top + 'px';
              $element[0].style.width = offset.width + 'px';
            }

            function resetDropdown() {
              if (placeholder === null) {
                // The dropdown has not actually been display yet, so there's nothing to reset
                return;
              }

              // Move the dropdown element back to its original location in the DOM
              placeholder.replaceWith($element);
              placeholder = null;

              $element[0].style.position = '';
              $element[0].style.left = '';
              $element[0].style.top = '';
              $element[0].style.width = originalWidth;
            }

            //Hold on to a reference to the .ui-select-dropdown element for direction support.
            var dropdown = null,
              directionUpClassName = 'direction-up';

            var setDropdownPosUp = function (offset, offsetDropdown) {

              offset = offset || uisOffset($element);
              offsetDropdown = offsetDropdown || uisOffset(dropdown);

              dropdown[0].style.position = 'absolute';
              dropdown[0].style.top = (offsetDropdown.height * -1) + 'px';
              $element.addClass(directionUpClassName);

            };

            var setDropdownPosDown = function (offset, offsetDropdown) {

              $element.removeClass(directionUpClassName);

              offset = offset || uisOffset($element);
              offsetDropdown = offsetDropdown || uisOffset(dropdown);

              dropdown[0].style.position = '';
              dropdown[0].style.top = '';

            };

            $scope.calculateDropdownPos = function () {

              if ($scope.open) {
                dropdown = angular.element($element).find('.dropdown-list');
                if (dropdown.length === 0) {
                  return;
                }

                // Hide the dropdown so there is no flicker until $timeout is done executing.
                dropdown[0].style.opacity = 0;

                // Delay positioning the dropdown until all choices have been added so its height is correct.
                $timeout(function () {
                  $element.removeClass(directionUpClassName);

                  var offset = uisOffset($element);
                  var offsetDropdown = uisOffset(dropdown);

                  //https://code.google.com/p/chromium/issues/detail?id=342307#c4
                  var scrollTop = $document[0].documentElement.scrollTop || $document[0].body.scrollTop; //To make it cross browser (blink, webkit, IE, Firefox).

                  // Determine if the direction of the dropdown needs to be changed.
                  if (offset.top + offset.height + offsetDropdown.height > scrollTop + $document[0].documentElement.clientHeight) {
                    //Go UP
                    setDropdownPosUp(offset, offsetDropdown);
                  } else {
                    //Go DOWN
                    setDropdownPosDown(offset, offsetDropdown);
                  }

                  // Display the dropdown once it has been positioned.
                  dropdown[0].style.opacity = 1;
                });
              } else {
                if (dropdown === null || dropdown.length === 0) {
                  return;
                }

                // Reset the position of the dropdown.
                dropdown[0].style.position = '';
                dropdown[0].style.top = '';
                $element.removeClass(directionUpClassName);
              }
            };
          }
        }
      }]
    )
    .factory('gfGridUtils', function ($q, $interval) {
      return {
        imitateSafeClosing: function (gridApi) {
          var def = $q.defer();

          var dirtyRows, errorRows;
          var int = $interval(function () {
            dirtyRows = gridApi.rowEdit.getDirtyRows(gridApi.grid);
            errorRows = gridApi.rowEdit.getErrorRows(gridApi.grid);
            if (!dirtyRows.length || errorRows.length) {
              $interval.cancel(int);
            }
          }, 200, 10);

          int.finally(function () {
            if (errorRows.length) {
              def.reject();
            } else if (dirtyRows.length) {
              def.reject();
            } else {
              def.resolve();
            }
          });

          return def.promise;
        }
      }
    })
    // fixes of scroll for all grids (CLOUD-1088)
    // code from ui-grid bug's comment https://github.com/angular-ui/ui-grid/issues/1926#issuecomment-226547114
    .config(function ($provide) {
      $provide.decorator('Grid', function ($delegate, $timeout) {
        $delegate.prototype.renderingComplete = function () {
          if (angular.isFunction(this.options.onRegisterApi)) {
            this.options.onRegisterApi(this.api);
          }
          this.api.core.raise.renderingComplete(this.api);
          $timeout(function () {
            var $viewport = $('.ui-grid-render-container');
            ['touchstart', 'touchmove', 'touchend', 'keydown', 'wheel', 'mousewheel', 'DomMouseScroll', 'MozMousePixelScroll']
              .forEach(function (eventName) {
                $viewport.unbind(eventName);
              });
          }.bind(this));
        };
        return $delegate;
      });
    })

    .component('gfGridMultiselect', {
      template: require('../../../../../main/angular/app/template/grid/gf_grid_multiselect.html'),
      bindings: {
        selected: '=ngModel',
        options: '<',
        config: '<'
      },
      require: 'ngModel',
      controller: function () {
        var self = this;

        self.$onInit = function () {
          self.config = self.config ? self.config : {};
        };

        self.toggle = function (option) {
          if (!self.isDisabled(option)) {
            self.selected = _.xorBy(self.selected, [option], 'id');
          }
        };

        self.isChecked = function (option) {
          return !!_.find(self.selected, {id: option.id});
        };

        self.isDisabled = function (option) {
          if (_.isFunction(self.config.isDisabled)) {
            return self.config.isDisabled(option, self);
          }
          return false;
        };
      }
    })
})();
