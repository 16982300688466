import React, {useEffect, useState} from 'react';
import LSDataGrid from '../common-components/LSDataGrid';
import axios from 'axios';
import TransactionType, {getTransactionType} from './TransactionType';
import {Link} from '@material-ui/core';
import moment from 'moment';
import Card from "./Card";
import Currency from './Currency';
import TransactionStatus, {getTransactionStatus} from "./TransactionStatus";
import {useSnackbar} from "notistack";
import {getAlertMessage} from "./Alert";
import {useTranslation} from "react-i18next";

const columns = [
  {
    field: 'transactionDate', headerName: 'lsp.date', width: 170, flex: 0.5
  },
  {
    field: 'card', headerName: 'lsp.card', width: 100, sortable: false,
    renderCell: params => <Card value={params.value} type={params.row.cardType}/>
  },
  {
    field: 'type', headerName: 'lsp.type', width: 110,
    renderCell: params => (
      <TransactionType type={getTransactionType(params.value)}/>
    )
  },
  {
    field: 'status', headerName: 'lsp.status', width: 110,
    renderCell: params => (
      <TransactionStatus status={getTransactionStatus(params.value)}/>
    )
  },
  {field: 'payoutId', headerName: 'lsp.payoutId', flex: 0.5, sortable: false},
  {field: 'invoiceNumber', headerName: 'lsp.receiptId', width: 110, sortable: false},
  {
    field: 'amountGross',
    headerName: 'lsp.amountGross',
    width: 120,
    renderCell: params => (
      <Currency value={params.value} currency={params.row.currency}/>
    )
  },
  {
    field: 'paymentLink',
    headerName: 'lsp.paymentLink',
    width: 120,
    hide: true
  }
];

const PAGE_SIZE = 20;

function Transactions({
                        from,
                        to,
                        goToDetails,
                        goToBill,
                        onDataLoad,
                        onChangeReportSortModel,
                        onChangeReportPage,
                        sortModel,
                        page
                      }) {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState();

  // set renderCell for transactionDate column
  columns.find(col => col.field === 'transactionDate').renderCell = params =>
    <Link component="button" style={{overflow: 'hidden'}}
          onClick={() => {
            goToDetails({id: params.id})
          }}>
      {moment(params.value).format('lll')}
    </Link>

  columns.find(col => col.field === 'invoiceNumber').renderCell = params =>
    <Link component="button" style={{overflow: 'hidden'}}
          onClick={() => {
            goToBill(params.value)
          }}>
      {params.value}
    </Link>

  useEffect(() => {
    setLoading(true);
    let respData = [];
    axios.post('/icash/ls_payments/transactions', {
        startDate: moment(from).unix(),
        endDate: moment(to).unix(),
        order: sortModel.sort,
        orderBy: sortModel.field,
        page: page + 1,
        perPage: PAGE_SIZE,
        include: ['transactionMetadata']
      }
    ).then(resp => {
      setTotalRows(resp.data.meta.pagination.total);
      respData = normalizeData(resp.data.data);
      var metadata = getTransactionMetadata(resp.data.included);
      var respDataWithMetadata = _.values(_.merge(_.keyBy(respData, 'id'), _.keyBy(metadata, 'id')));
      setData(respDataWithMetadata);
    }).catch(error => {
      setTotalRows(0);
      setData([]);
      enqueueSnackbar(t(getAlertMessage(error)), {variant: 'error'});
    }).finally(() => {
      setLoading(false);
      if (onDataLoad) {
        onDataLoad(respData);
      }
    });
  }, [from, to, sortModel.sort, sortModel.field, page]);

  const onPageChange = params => {
    onChangeReportPage(params?.page || params);
  }

  const onSortModelChange = sortModel => {
    onChangeReportSortModel(sortModel);
  }

  return <LSDataGrid columns={columns} rows={data} loading={loading}
                     onSortModelChange={onSortModelChange}
                     onPageChange={onPageChange} pageSize={PAGE_SIZE}
                     rowCount={totalRows} sortModel={sortModel} page={page}/>
}

export default Transactions;

function getTransactionMetadata(included) {
  return normalizeTransactionMetadata(_.filter(included, function (i) {
    return i.type === "transaction_metadata";
  }));
}

function normalizeTransactionMetadata(metadata) {
  return metadata.map(el => {
    return {
      id: el.attributes.transactionId,
      invoiceNumber: el.attributes.hrOrderId
    }
  })
}

function normalizeData(data) {
  return data.map(el => {
      return {
        id: el.id,
        transactionDate: el.attributes.transactionDate,
        card: el.attributes.cardLastFourDigits,
        cardType: el.attributes.cardType,
        type: el.attributes.type,
        status: el.attributes.status,
        payoutId: el.attributes.payoutId,
        amountGross: el.attributes.amountGross,
        paymentLink: el.links.self,
        currency: el.attributes.currency
      }
    }
  );
}
