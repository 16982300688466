import React from 'react';
import LSDataGrid from '../common-components/LSDataGrid';
import moment from 'moment';
import TransactionType, {getTransactionType} from './TransactionType';
import Card from "./Card";
import {Link} from "@material-ui/core";
import Currency from "./Currency";

const columns = [
  {field: 'id', headerName: 'lsp.ID', flex: 0.5, hide: true},
  {
    field: 'transactionDate', headerName: 'lsp.date', width: 170, flex: 0.35,
    valueFormatter: params => moment(params.value).format('lll')
  },
  {
    field: 'card', headerName: 'lsp.card', width: 120,
    renderCell: params => (
      <Card value={params.value} type={params.row.cardType}/>
    )
  },
  {
    field: 'type', headerName: 'lsp.type', width: 100,
    renderCell: params => (
      <TransactionType type={getTransactionType(params.value)}/>
    )
  },
  {
    field: 'amountGross',
    headerName: 'lsp.amountGross',
    width: 120,
    renderCell: params => (
      <Currency value={params.value} currency={params.row.currency}/>)
  }
];

function SettledTransactionsTable({id, data, goToDetails}) {
  // todo LSDataGrid sorting not working

  columns.find(col => col.field === 'transactionDate').renderCell = params =>
    <Link component="button" style={{overflow: 'hidden'}}
          onClick={() => {
            goToDetails({id: id, trId: params.id})
          }}>
      {moment(params.value).format('lll')}
    </Link>

  return <LSDataGrid columns={columns} rows={data} hideFooter={true}
                     sortingMode='client' paginationMode='client'/>
}

export default SettledTransactionsTable;
