(function () {
  'use strict';
  angular.module('gf.setup.table', ['ui.router'])

    .factory('TableMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/table/metadata.json')
    })
    .factory('TableContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/table/context.json')
    })
    .factory('Table', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/table/:tableId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('TableRange', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/table/range')
    })

    .config(function ($stateProvider) {

      var TableAddCtrlResolve = {
        tableMetadata: ["TableMetadata", function (TableMetadata) {
          return TableMetadata.get().$promise;
        }],
        tableContext: ["TableContext", function (TableContext) {
          return TableContext.get().$promise;
        }],
        table: ["Table", "$stateParams", function (Table, $stateParams) {
          return Table.load({tableId: $stateParams.id || "add"}).$promise;
        }]
      };

      $stateProvider
        .state('page.setupTable', {
          url: '/setup/table',
          template: require('../template/setup/table/tables.html'),
          controller: 'TableGridCtrl',
          controllerAs: 'tableGridCtrl',
          resolve: TableAddCtrlResolve,
          title: 'restaurantTable'
        })

        .state('page.setupTableAdd', {
          url: '/setup/table/add',
          template: require('../template/setup/table/table.html'),
          controller: 'TableAddCtrl',
          controllerAs: 'tableEditCtrl',
          resolve: TableAddCtrlResolve,
          title: 'restaurantTable'
        })

        .state('page.setupTableDetails', {
          url: '/setup/table/:id',
          template: require('../template/setup/table/table.html'),
          controller: 'TableEditCtrl',
          controllerAs: 'tableEditCtrl',
          resolve: TableAddCtrlResolve,
          title: 'restaurantTable'
        })
    })

    .factory('TableAdd', [
      function () {
        return function (self, $scope, $location, $http, $translate, $state, notifyManager, table, tableContext, tableMetadata) {
          self.tableContext = tableContext;
          self.tableMetadata = tableMetadata;
          self.table = table;
          self.newItem = true;

          angular.extend(self, tableContext.data.dependencies);


          self.save = function () {
            if (self.tableForm.$valid) {
              self.table.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $location.path('/setup/table')
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          self.saveAndNew = function () {
            if (self.tableForm.$valid) {
              self.table.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };

          self.goBack = function () {
            $location.path('/setup/table');
          }
        }
      }
    ])
    .controller('TableAddCtrl',

      function ($scope, $location, $http, $translate, $state, notifyManager, table, tableContext, tableMetadata, TableAdd) {
        var self = this;

        TableAdd(self, $scope, $location, $http, $translate, $state, notifyManager, table, tableContext, tableMetadata)
      }
    )
    .controller('TableEditCtrl',

      function ($scope, $location, $http, $translate, $state, notifyManager, table, tableContext, tableMetadata, TableAdd) {
        var self = this;

        TableAdd(self, $scope, $location, $http, $translate, $state, notifyManager, table, tableContext, tableMetadata);
        self.newItem = false;

        self.save = function () {
          if (self.tableForm.$valid) {
            self.table.$edit({tableId: self.table.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $location.path('/setup/table')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        self.saveAndNew = function () {
          if (self.tableForm.$valid) {
            self.table.$edit({tableId: self.table.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $location.path('/setup/table/add')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

      }
    )
    .controller('TableGridCtrl',

      function (tableContext, tableMetadata, PluService, $uibModal) {
        var self = this;

        self.metadata = tableMetadata;
        self.context = tableContext;

        self.config = {
          urlRoute: '/setup/table',
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {
              name: 'id', width: 120,
              preFillValue: function (colDef, entity) {
                var url = '/icash' + self.context.refreshEndpoint + '/plu';
                if (colDef.name != 'id') {
                  return PluService.acquire(url);
                } else {
                  PluService.lock(url, entity.id);
                  return entity.id;
                }
              },
              cellEditableCondition: function ($scope) {
                return $scope.row.entity.id == null || $scope.row.entity.notValidColumns && _.find($scope.row.entity.notValidColumns, function (item) {
                    return 'id' == item;
                  });
              },
              validations: {
                'gf-uniquetebleplu': {
                  'angular': {'gf-uniquetebleplu': ''},
                  'errorMessage': "de.icash.validator.UniquePLU.message",
                  'errorMessageArg': "plu"
                }
              }
            },
            {name: 'name'},
            {name: 'room'},
            {name: 'billPrinter', visible: false},
            {name: 'description', visible: false, width: 250},
            {name: 'operate', actions: ['edit', 'delete']}
          ]
        };
        self.generateTables = function () {
          var modalDialog = $uibModal.open({
            template: require('../template/setup/table/generate_table.html'),
            windowClass: 'gf-modal',
            controller: 'GenerateTableCtrl',
            controllerAs: 'generateTableCtrl',
            resolve: {
              context: tableContext
            }
          });

          modalDialog.result.then(function () {
            self.config.reloadData();
          });
        };
      }
    )

    .controller('GenerateTableCtrl',

      function ($uibModalInstance, notifyManager, TableRange, context) {
        var self = this;
        self.rangeTable = new TableRange({});
        self.context = context.data.dependencies;

        self.ok = function () {
          $uibModalInstance.close();
        };

        self.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        self.change = function () {
          self.rangeTable.$save(
            function (data) {
              if (data.ok) {
                notifyManager.success(data.message);
              } else {
                notifyManager.error(data.message);
              }
              $uibModalInstance.close();
            });
        }
      }
    )
})();
