(function() {
  'use strict';
  angular.module('gf.financial.account', ['ui.router'])
    .factory('AccountMetadata', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/account/metadata.json')
    })
    .factory('AccountContext', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/account/context.json')
    })
    .factory('Account', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/account/:accountId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .config(function($stateProvider) {

      $stateProvider
        .state('page.financialAccount', {
          url: '/financial/account',
          template: require('../template/financial/account/accounts.html'),
          controller: 'AccountGridCtrl',
          controllerAs: 'accountGridCtrl',
          resolve: {
            accountMetadata: function(AccountMetadata) {
              return AccountMetadata.get().$promise;
            },
            accountContext: function(AccountContext) {
              return AccountContext.get().$promise;
            }
          },
          title: "account"
        })

        .state('page.financialAccountAdd', {
          url: '/financial/account/add',
          template: require('../template/financial/account/account.html'),
          controller: 'AccountAddCtrl',
          controllerAs: 'accountCtrl',
          resolve: {
            accountMetadata: function(AccountMetadata) {
              return AccountMetadata.get().$promise;
            },
            accountContext: function(AccountContext) {
              return AccountContext.get().$promise;
            },
            account: function(Account, $stateParams) {
              return Account.load({accountId: $stateParams.id || 'add'}).$promise;
            }
          },
          title: 'account'
        })

        .state('page.financialAccountDetails', {
          url: '/financial/account/:id',
          template: require('../template/financial/account/account.html'),
          controller: 'AccountEditCtrl',
          controllerAs: 'accountCtrl',
          resolve: {
            accountMetadata: function(AccountMetadata) {
              return AccountMetadata.get().$promise;
            },
            accountContext: function(AccountContext) {
              return AccountContext.get().$promise;
            },
            account: function(Account, $stateParams) {
              return Account.load({accountId: $stateParams.id || "add"}).$promise;
            }
          },
          title: 'account'
        })
    })

    .factory('AccountAdd', [
      function () {
        return function (self, $scope, $http, $translate, $state, notifyManager, account, accountContext, accountMetadata) {
          self.accountContext = accountContext;
          self.accountMetadata = accountMetadata;
          self.account = account;

          angular.extend(self, accountContext.data.dependencies);

          self.save = function () {
            if (self.accountForm.$valid) {
              self.account.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go('page.financialAccount')
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };
          self.saveAndNew = function () {
            if (self.accountForm.$valid) {
              self.account.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };
        }
      }
    ])
    .controller('AccountAddCtrl',

      function ($scope, $location, $http, $translate, $state, notifyManager, account, accountContext, accountMetadata, AccountAdd) {
        var self = this;
        AccountAdd(self, $scope, $http, $translate, $state, notifyManager, account, accountContext, accountMetadata);
      }
    )
    .controller('AccountEditCtrl',

      function ($scope, $location, $http, $translate, $state, notifyManager, account, accountContext, accountMetadata, AccountAdd) {
        var self = this;
        AccountAdd(self, $scope, $http, $translate, $state, notifyManager, account, accountContext, accountMetadata);

        self.save = function () {
          if (self.accountForm.$valid) {
            self.account.$edit({accountId: self.account.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.financialAccount')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
        self.saveAndNew = function () {
          if (self.accountForm.$valid) {
            self.account.$edit({accountId: self.account.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.financialAccountAdd')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
      }
    )
    .controller('AccountGridCtrl',

      function (accountContext, accountMetadata, securityService, configOptionsService) {
        var self = this;
        self.metadata = accountMetadata;
        self.context = accountContext;

        var columns = [
          {name: 'name'},
          {name: 'account'},
          {name: 'chart'},
          {name: 'code'},
          {
            name: 'type',
            cellFilter: "prefixFilter:'AccountType.' | translate",
            editDropdownFilter: "prefixFilter:'AccountType.' | translate",
            validations: {
              "gf-financialaccounttype": {
                'angular': {'gf-financialaccounttype': ''},
                'errorMessage': "de.icash.validator.financialAccountType"
              }
            }
          },
          {name: 'value'},
          {name: 'sort'}
        ];

        if (securityService.hasAnyFeature('DATEV')) {
          columns.push({name: 'costCenter', visible: false});
          columns.push({name: 'bookingText', visible: false});
        }

        columns.push({
          name: 'operate',
          actions: ['edit', 'delete'],
          actionOptions: {
            'delete': {
              isDisabled: configOptionsService.get('vatDeleteButton').isDisabled
            }
          }
        });

        self.config = {
          urlRoute: '/financial/account',
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          columns: columns
        };
      }
    )
})();
