(function () {
  'use strict';
  angular.module('gf.bm.rules.grid')

    .factory('RuleManager', function ($http, $stateParams, securityService, $q) {
      return function (rules) {
        var self = this;

        self.branchRules = rules;

        self.entityRules = [];

        var RULE_TODAY = {
          id: 0,
          name: 'bm.scheduled.today'
        };
        self.branchRules.unshift(RULE_TODAY);

        self.state = {
          currentRule: RULE_TODAY,
          setCurrentRule: function (rule) {
            this.currentRule = rule;
          },
          setDefaultRule: function () {
            this.currentRule = RULE_TODAY;
          },
          isToday: function () {
            return this.currentRule.id === 0;
          },
          isCurrent: function (ruleId) {
            return this.currentRule.id === ruleId;
          }
        };

        function addNewBranchRule(rule) {
          var deferred = $q.defer();
          $http
            .post('/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/rule', rule)
            .then(function (resp) {
              self.branchRules.push(resp.data);
              deferred.resolve(resp.data);
            }, function () {
              deferred.reject();
            });
          return deferred.promise;
        }

        function deleteBranchRule(ruleId) {
          var deferred = $q.defer();
          $http
            .delete('/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/rule/' + ruleId)
            .then(function (resp) {
              _.remove(self.branchRules, {
                id: ruleId
              });
              deferred.resolve(resp.data);
            }, function () {
              deferred.reject();
            });
          return deferred.promise;
        }

        function editBranchRule(rule) {
          var deferred = $q.defer();
          $http
            .put('/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/rule/' + rule.id, rule)
            .then(function (resp) {
              var oldRule = _.find(self.branchRules, {id: resp.data.id});
              _.merge(oldRule, resp.data);
              deferred.resolve(resp.data);
            }, function () {
              deferred.reject();
            });
          return deferred.promise;
        }

        function editEntityRule(entity, fieldName, newValue, oldValue) {
          var entityRule = getEntityRule(entity.id);
          if (!entityRule) {
            entityRule = createEntityRule(entity);
            self.entityRules.push(entityRule);
          }
          return _.set(entityRule, fieldName, newValue);
        }

        function deleteEntityRule(entityId, fieldName) {
          var entityRule = getEntityRule(entityId);
          if (!entityRule) return;
          return _.unset(entityRule, fieldName);
        }

        function createEntityRule(entity) {
          return {
            branchRule: self.state.currentRule,
            entity: {
              id: entity.id,
              restaurantId: entity.belongsTo.id
            }
          }
        }

        function getEntityRule(entityId) {
          return _.find(self.entityRules, function (rule) {
            return rule.entity.id === entityId;
          });
        }

        function getEntityRules() {
          return self.entityRules;
        }

        function setEntityRules(rules) {
          self.entityRules = rules;
        }

        function saveEntityRules(entityUrl, rules) {
          var deferred = $q.defer();
          if (!entityUrl || _.isEmpty(rules)) {
            deferred.resolve();
            return deferred.promise;
          }
          $http
            .put(entityUrl, rules)
            .then(function () {
              deferred.resolve();
            }, function () {
              deferred.reject();
            });
          return deferred.promise;
        }

        return {
          getBranchRules: function () {
            return self.branchRules;
          },
          state: self.state,
          addNewBranchRule: addNewBranchRule,
          editBranchRule: editBranchRule,
          deleteBranchRule: deleteBranchRule,

          editEntityRule: editEntityRule,
          getEntityRule: getEntityRule,
          deleteEntityRule: deleteEntityRule,

          setEntityRules: setEntityRules,
          getEntityRules: getEntityRules,
          saveEntityRules: saveEntityRules
        }
      };
    })

})();
