import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import {useTranslation} from "react-i18next";

const ReactMarkdown = require('react-markdown/with-html');

function MarkdownPreviewModal({content, open, handleClose}) {

  const {t} = useTranslation();

  const handleCloseDialog = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog
        id='markdown-modal'
        open={open}
        onClose={handleClose}
      >
        <DialogContent style={{minHeight: '200px', width: '570px'}}>
          <DialogContentText id='delete-dialog-description' style={{color: '#333', fontSize: '14px'}}>
            <div><ReactMarkdown source={content}/></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id='delete-dialog-cancel-btn' onClick={handleCloseDialog} variant="contained" color="secondary">
            {t("button.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default MarkdownPreviewModal;
