import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import {useSnackbar} from 'notistack';
import axios from "axios";
import {useForm, Controller} from 'react-hook-form';
import {useTranslation} from "react-i18next";
import _ from 'lodash';
import * as yup from "yup";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";


function AddPrinterDialog({open, classes, handleClose, addNewPrinter, drivers}) {

  const {t} = useTranslation();

  const initialPrinter = {
    id: 0,
    name: '',
    ipAddress: '',
    tcpPort: '9100',
    udpPort: '3289',
    description: '',
    printerDriver: {id: '', name: ''}
  };

  const schema = yup.object().shape({
    name: yup.string().required(t('validation.requiredField')),
    ipAddress: yup.string().matches(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i, t('tse.addPrinter.wrongIpFormat')).required(t('validation.requiredField')),
    tcpPort: yup.string().max(5, t('tse.tseFormValidation.maxLength', {number: '5'})).required(t('validation.requiredField')),
    udpPort: yup.string().max(5, t('tse.tseFormValidation.maxLength', {number: '5'})).required(t('validation.requiredField')),
  });

  const [printer, setPrinter] = useState(initialPrinter);
  const {enqueueSnackbar} = useSnackbar();
  const {handleSubmit, errors, control} = useForm({validationSchema: schema});

  useEffect(() => {
      let defaultDriver = _.find(drivers, {name: 'Epson TM-m30F8'});
      setPrinter({...printer, 'printerDriver': defaultDriver ? defaultDriver : drivers[0]});
  }, []);

  const onSubmitPrinterForm = (data, event) => {
    event.preventDefault();
    handleSave(data);
  };

  const handleSave = (data) => {
    savePrinter(data).then((res) => {
        enqueueSnackbar(t('tse.addPrinter.printerAdded'), {variant: 'success'});
        addNewPrinter(res.data.payload.entity);
      },
      () => {
        enqueueSnackbar(t('tse.addPrinter.printerFailedToAdd'), {variant: 'error'});
      });
  };

  const DialogActions = withStyles(theme => ({
    root: {
      padding: theme.spacing(3),
    },
  }))(MuiDialogActions);

  const DialogTitle = withStyles(theme => ({
    root: {
      padding: theme.spacing(3),
    },
  }))(MuiDialogTitle);

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      scroll={'body'}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {t('tse.addTsePrinter')}
      </DialogTitle>
      <DialogContent dividers>
        <form id='printerForm' className={classes.form}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                control={control}
                label={t('printer.name') + '*'}
                id='name'
                name='name'
                defaultValue={printer.name}
                type='text'
                fullWidth
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl className={classes.formControl}>
                <InputLabel>{t('printer.printerDriver')}*</InputLabel>
                <Controller
                  control={control}
                  name="printerDriver"
                  id="printerDriver"
                  defaultValue={_.find(drivers, {name: printer.printerDriver.name}) || {}}
                  as={ <Select>
                    {drivers.map((driver, index) => (
                      <MenuItem key={index} value={driver}>{driver.name}</MenuItem>
                    ))}
                  </Select>}
                />

              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Controller
                as={TextField}
                control={control}
                id='ipAddress'
                name='ipAddress'
                label={t('printer.host') + '*'}
                defaultValue={printer.ipAddress}
                fullWidth
                error={!!errors.ipAddress}
                helperText={errors.ipAddress && errors.ipAddress.message}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                as={TextField}
                control={control}
                id='tcpPort'
                name='tcpPort'
                label={t('printer.tcpPort') + '*'}
                defaultValue={printer.tcpPort}
                fullWidth
                InputProps={{
                  type: 'number'
                }}
                error={!!errors.tcpPort}
                helperText={errors.tcpPort && errors.tcpPort.message}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <Controller
                as={TextField}
                control={control}
                id='udpPort'
                name='udpPort'
                label={t('printer.udpPort') + '*'}
                defaultValue={printer.udpPort}
                fullWidth
                InputProps={{
                  type: 'number'
                }}
                error={!!errors.udpPort}
                helperText={errors.udpPort && errors.udpPort.message}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Controller
                as={TextField}
                control={control}
                id='description'
                name='description'
                label={t("printer.description")}
                defaultValue={printer.description}
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button id='printerCancelEditBtn' onClick={handleClose}>
          {t("button.cancel")}
        </Button>
        <Button id='printerSaveEditBtn' onClick={handleSubmit(onSubmitPrinterForm)} variant='contained'>
          {t("button.addShort")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const styles = theme => ({
  deleteBtn: {
    marginRight: 'auto'
  },
  form: {
    paddingBottom: theme.spacing(4),
  }
});

export default withStyles(styles)(AddPrinterDialog);

function savePrinter(printer) {
  return axios.post('/icash/hardware/printer', printer)
}


