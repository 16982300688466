import defaultCloudImg from './../images/cloud_tse.png'
import sumupCloudImg from './../images/sumup_cloud_tse.png'

const resolveTSECloudImage = () => {
  switch (THEME) {
    case 'sumup':
      return sumupCloudImg
    default:
      return defaultCloudImg
  }
}

export default resolveTSECloudImage();
