(function () {
  'use strict';
  angular.module('gf.bm.rules.grid')

    .factory('metadataService', function ($log, COLUMN_TYPES, COLUMN_DEFAULTS) {

      return {
        applyMetadata: applyMetadata
      };

      function applyMetadata(columnDefs, metadata, dependencies) {

        columnDefs.forEach(function (column) {
          var mColumn = metadata.fields[column.metadataField || column.name];
          if (!mColumn) {
            return;
          }
          if (!mColumn && column.name !== 'actions') {
            $log.info('Metadata not found for field: ', column.name);
            return;
          }
          applyTemplate(column, mColumn, dependencies);
          applyValidationOptions(column, mColumn);
        });
      }

      function applyTemplate(column, mColumn, dependencies) {
        var colType = _.chain(COLUMN_TYPES)
          .findKey(function (colJavaTypes) {
            return _.includes(colJavaTypes, mColumn.javaType);
          })
          .defaultTo('object')
          .value();

        _.defaults(column, COLUMN_DEFAULTS[colType]);

        if (!column.cellTemplate) {
          column.cellTemplate = 'app/template/bm/group-view/grid/cell/bm_cell_default.html';
        }

        if (dependencies) {
          if (column.type !== 'object') {
            return;
          }
          var columnDep = dependencies[column.dependenciesField || column.name];
          if (columnDep) {
            var defaults = column.multiselect
              ? COLUMN_DEFAULTS.buildForMultiselect(column)
              : COLUMN_DEFAULTS.buildForSelect(column);

            if (!mColumn.required) {
              defaults.showEmpty = true
            }

            _.defaults(column, defaults);

            column.editDropdownOptionsArray = columnDep;
          }

          // set field for objects
          if (!column.multiselect) {
            column.field = _.defaultTo(column.field, column.name + '.name');
          }
        }

      }

      function applyValidationOptions(column, columnMetadata) {
        column.metadata = columnMetadata;
      }
    })

    .constant('COLUMN_TYPES', {
      string: ['java.lang.String'],
      integer: ['int', 'short', 'byte', 'long'],
      double: ['double', 'float'],
      currency: ['java.math.BigDecimal'],
      boolean: ['boolean'],
      date: ['java.lang.Date', 'java.util.Date', 'java.time.LocalDate'],
      list: ['java.util.List']
    })

    //todo use this constant in grid.js
    .constant('COLUMN_DEFAULTS', {
      string: {
        editableCellTemplate: 'app/template/bm/group-view/grid/cell/bm_cell_string.html'
      },
      integer: {
        type: 'number',
        editableCellTemplate: 'app/template/bm/group-view/grid/cell/bm_cell_number.html'
      },
      double: {
        type: 'number',
        editableCellTemplate: 'app/template/bm/group-view/grid/cell/bm_cell_double.html'
      },
      currency: {
        type: 'number',
        editableCellTemplate: 'app/template/bm/group-view/grid/cell/bm_cell_currency.html',
        cellFilter: 'number:2'
      },
      boolean: {
        type: 'boolean',
        enableFiltering: false,
        cellTemplate: 'app/template/bm/group-view/grid/cell/bm_cell_boolean.html',
        editableCellTemplate: 'app/template/bm/group-view/grid/cell/bm_cell_boolean_edit.html'
      },
      date: {
        type: 'date',
        cellFilter: "date:'shortDate'",
        editableCellTemplate: 'app/template/bm/group-view/grid/cell/bm_cell_datepicker.html'
      },
      list: {
        type: 'object',
        cellFilter: "gfSelectProperty:'name'",
        multiselect: true,
        isChanged: function (newValue, oldValue) {
          return !_.isUndefined(newValue) && !(_.isArray(newValue) && _.isEmpty(newValue));
        }
      },
      object: {
        type: 'object',
        cellFilter: "gfProperty:'name'"
      },
      buildForSelect: function (col) {
        return {
          type: 'object',
          enableCellEdit: true,
          editDropdownIdLabel: 'id',
          editDropdownValueLabel: 'name',
          editModelField: col.name,
          editableCellTemplate: 'app/template/bm/group-view/grid/cell/bm_cell_select.html'
        }
      },
      buildForMultiselect: function (col) {
        return {
          type: 'object',
          enableFiltering: false,
          enableSorting: false,
          enableCellEdit: true,
          editModelField: col.name,
          editableCellTemplate: 'app/template/bm/group-view/grid/cell/bm_cell_multiselect.html'
        }
      }
    })

})();
