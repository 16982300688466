(function () {
  'use strict';
  angular.module('gf.login', ['ui.bootstrap', 'ui.bootstrap.carousel'])
    .run(function ($templateCache) {
      $templateCache.put('app/template/carousel.html', require('./../../template/carousel.html'));
      $templateCache.put('app/template/carousel_culinaro.html', require('./../../template/carousel_culinaro.html'));
    })
    .controller('LoginCtrl',
      function ($scope, $http, securityService, $location, $httpParamSerializer, appConfig, $state, $translate) {
        $scope.login = {
          'remember-me': true,
          'j_operator': ''
        };

        $scope.showOperator = false;
        $scope.toggle = false;
        $scope.loginPlaceholder = 'operatorUser.email';
        $scope.active = 0;
        $scope.theme = securityService.context.theme.name;
        $scope.helpUrl = window.hasOwnProperty("LOGIN_HELP_LINK") ? LOGIN_HELP_LINK : null;
        $scope.confirmCode = false;

        $scope.handleLoginClick = () => {
          $scope.showOperator = !$scope.showOperator;
          $scope.toggle = !$scope.toggle;
          if ($scope.showOperator) {
            $scope.loginPlaceholder = 'jsp.login.user';
          } else {
            $scope.loginPlaceholder = 'operatorUser.email';
            $scope.login.j_operator = '';
          }
        };

        $scope.submitLoginForm = function () {
          var request = {
            method: 'POST',
            url: appConfig.baseUrl + '/icash/login',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: $httpParamSerializer($scope.login)
          };

          $http(request).then(function (result) {
            if (result.data.ok) {
              securityService.refreshContext().then(function () {
                $state.go('page.welcome');
              });
            } else if (result.data.status === 'FAIL') {
              $scope.hasError = true;
              $scope.reason = result.data.payload.reason;
              if (result.data.message && result.data.message !== '') {
                if (result.data.message === 'MFA_REQUIRED') {
                  $scope.confirmCode = true;
                  const mfaUseApp = result.data.payload.mfaUseApp;
                  $scope.reason = mfaUseApp ? 'MFA_REQUIRED_APP' : 'MFA_REQUIRED_EMAIL';
                } else {
                  $scope.reason = result.data.message;
                }
              }
            }
          });
        };
        function formTermsLink() {
          var termValues = {
            link1: '<a id="terms_link" href="' + $translate.instant('termsAndConditionsUrl') + '" target="_blank">',
            link1end: '</a>'
          };
          return $translate.instant('jsp.copyright', {link1: termValues.link1, link1end: termValues.link1end});
        }

        $translate.onReady().then(function () {
          $scope.copyright = formTermsLink();
        });
      }
    )
    .factory('logoutService',
      function ($http, securityService, appConfig) {
        return {
          logout: function () {
            return $http.get(appConfig.baseUrl + '/icash/logout')
              .then(function () {
                securityService.refreshContext();
              });
          }
        }
      })
})();
