(function () {
  'use strict';
  angular.module('gf.news', [])

    .run(function ($templateCache) {
      $templateCache.put('app/template/tse.html', require('../template/tse.html'));
      $templateCache.put('app/template/gf_to_ls.html', require('../template/gf_to_ls.html'));
      $templateCache.put('app/template/ls_first_release.html', require('../template/ls_first_release.html'));
    })

    .component('newsModal', {
      template: require('../template/whats_new_modal.html'),
      bindings: {
        modalInstance: '<',
        resolve: '='
      },
      controller: function ($http) {
        var self = this;
        self.doNotShowForUser = false;
        self.isLastNews = false;
        self.$onInit = function () {
          self.news = self.resolve.news;
          self.news[0].first = true;
          self.news[0].current = true;
          self.contractTypeCountry = self.resolve.security.context.contractType.country
            ? self.resolve.security.context.contractType.country.code
            : null;
          self.ATorCH = self.contractTypeCountry && self.contractTypeCountry === 'AT' || self.contractTypeCountry === 'CH';

          var showdown  = require('showdown');
          var converter = new showdown.Converter({parseImgDimensions: true});

          self.toHtml = function(mark) {
            return converter.makeHtml(mark);
          };
          self.modalInstance.result.then(
            function () {
              if (self.doNotShowForUser) {
                if (self.resolve.user.additionalSettings.hiddenNews && self.resolve.user.additionalSettings.hiddenNews.length) {
                  self.resolve.user.additionalSettings.hiddenNews = _.concat(self.resolve.user.additionalSettings.hiddenNews, _.map(self.news, 'id'));
                } else {
                  self.resolve.user.additionalSettings.hiddenNews = _.map(self.news, 'id');
                }
                $http.put('/icash/dashboard/hide_news', self.resolve.user.additionalSettings).then(function (res) {
                })
              }
            }
          )
        };
        self.goNext = function () {
          var currentNewsIndex = _.findIndex(self.news, {'current': true});
          self.news[currentNewsIndex].current = false;
          self.news[currentNewsIndex + 1].current = true;
          if (self.news.length === currentNewsIndex + 2) {self.isLastNews = true}
        };
      },
      controllerAs: '$ctrl',
      backdrop: 'static'
    })
})();
