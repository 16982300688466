import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import tseImg from './TSEBrandImage'
import epsonTseImg from './../images/epson_tse.png'
import cloudTseImg from './TSECloudImage'
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center'
  },
  tseDeviceRoot: {
    display: 'flex',
    flexDirection: 'column'
  },
  tseDeviceContent: {
    display: 'flex',
    flexGrow: 1
  },
  mainImageRoot: {
    textAlign: 'center'
  },
  image: {
    marginLeft: theme.spacing(2)
  },
  buttonsRoot: {
    textAlign: 'center',
    marginTop: theme.spacing(4)
  },
  dividerRoot: {
    textAlign: 'center'
  },
  divider: {
    height: '100%',
    borderLeft: '1px solid #ccc',
    display: 'inline-block'
  }
}))

function TSEInformationOverview({security}) {
  const {t} = useTranslation();
  const classes = useStyles();

  const goToCloudTSEPage = () => {
    window.open(security.context.epsonTsePageUrl, '_blank')
  }

  return (
    <Grid container spacing={6} direction='column' alignItems='stretch'>
      <Grid item>
        <Typography variant={'h5'} className={classes.title}>
          {t('tseOverview.mainTitle')}
        </Typography>
      </Grid>
      <Grid item container>
        <Grid item md={4} xs={2}/>
        <Grid item md={4} xs={8}>
          {
            tseImg &&
            <div className={classes.mainImageRoot}>
              <img height='210' src={tseImg}/>
            </div>
          }
          <Typography>
            {t('tseOverview.mainText')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container spacing={4} alignItems='stretch'>
        <Grid item xs={5} className={classes.tseDeviceRoot}>
          <div className={classes.tseDeviceContent}>
            <div>
              <Typography>
                <strong>{t('tseOverview.cloudTse.title')}</strong>
              </Typography>
              <Typography>
                {t('tseOverview.cloudTse.text')}
              </Typography>
              <Typography>
                <i>{t('tseOverview.cloudTse.asteriskText')}</i>
              </Typography>
            </div>
            <div className={classes.image}>
              <img height={100} src={cloudTseImg}/>
            </div>
          </div>
        </Grid>
        <Grid item xs={2} className={classes.dividerRoot}>
          <div className={classes.divider}/>
        </Grid>
        <Grid item xs={5} className={classes.tseDeviceRoot}>
          <div className={classes.tseDeviceContent}>
            <div>
              <Typography>
                <strong>{t('tseOverview.epsonTse.title')}</strong>
              </Typography>
              <Typography>
                {t('tseOverview.epsonTse.text')}
              </Typography>
            </div>
            <div className={classes.image}>
              <img height={100} src={epsonTseImg}/>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid item>
        <Typography align='center'>
          {t('tseOverview.support')}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default TSEInformationOverview;
