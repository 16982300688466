(function () {
  'use strict';

  function ConfigOption() {
    var disableOptions = [];
    var hideOptions = [];

    function addOption(optionsArr, optionToAdd) {
      var option;
      if (_.isFunction(optionToAdd)) {
        option = optionToAdd;
      } else {
        option = function () {
          return optionToAdd;
        }
      }
      optionsArr.push(option);
    }

    function checkAllOptions(optionsArr) {
      if (_.isEmpty(optionsArr)) {
        return false;
      }
      return _.every(optionsArr, function (optionFn) {
        return optionFn();
      });
    }

    return {
      disableWhen: function (option) {
        return addOption(disableOptions, option);
      },

      isDisabled: function () {
        return checkAllOptions(disableOptions);
      },

      hideWhen: function (option) {
        return addOption(hideOptions, option);
      },

      isHidden: function () {
        return checkAllOptions(hideOptions);
      }

    }
  }

  angular.module('gf.config.options', [])

    .provider('configOptionsService', function () {

      var configurationOptions = {};

      this.$get = [function () {

        return {
          get: function (alias) {
            var configOption = configurationOptions[alias];
            if (!configOption) {
              configOption = new ConfigOption();
              configurationOptions[alias] = configOption;
            }
            return configOption;
          }
        };

      }]
    })

})();

