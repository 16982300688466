(function () {
  'use strict';
  angular.module('gf.financial.settlement.form', ['ui.router'])

    .run(function ($templateCache) {
      $templateCache.put('app/template/sf_bill_layouts_multiselect.html', require('../template/sf_bill_layouts_multiselect.html'));
    })

    .factory('SettlementFormMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/settlement_form/metadata.json')
    })
    .factory('SettlementFormMetadataNew', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/settlement_form/metadata_new.json')
    })
    .factory('SettlementFormContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/settlement_form/context.json')
    })
    .factory('SettlementForm', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/settlement_form/:settlementFormId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('SettlementFormPositions', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/settlement_form/positions', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })

    .config(function ($stateProvider) {
      var SettlementFormAddCtrlResolve = {
        settlementFormMetadata: ['SettlementFormMetadata', function (SettlementFormMetadata) {
          return SettlementFormMetadata.get().$promise;
        }],
        // todo o.logunov: rename to settlementFormMetadata after migration
        settlementFormMetadataNew: ['SettlementFormMetadataNew', function (SettlementFormMetadataNew) {
          return SettlementFormMetadataNew.get().$promise;
        }],
        settlementFormContext: ['SettlementFormContext', function (SettlementFormContext) {
          return SettlementFormContext.get().$promise;
        }],
        settlementForm: ['SettlementForm', '$stateParams', function (SettlementForm, $stateParams) {
          return SettlementForm.load({settlementFormId: $stateParams.id || 'add'}).$promise;
        }],
        settlementFormPositions: ['SettlementFormPositions', function (SettlementFormPositions) {
          return SettlementFormPositions.load().$promise;
        }],
        payment_icons: ['icons', function (icons) {
          return icons['payment'].get().$promise;
        }],
        currentTour: ['Tour', function (Tour) {
          return Tour.get({page: 'financialSettlement_form'}).$promise;
        }]
      };


      $stateProvider
        .state('page.financialSettlement_form', {
          url: '/financial/settlement_form',
          template: require('../template/page/financial/settlement_forms.html'),
          controller: 'SettlementFormGridCtrl',
          controllerAs: 'settlementFormGridCtrl',
          resolve: SettlementFormAddCtrlResolve,
          title: "settlementForm"
        })

        .state('page.financialSettlement_formAdd', {
          url: '/financial/settlement_form/add',
          template: require('../template/page/financial/settlement_form.html'),
          controller: 'SettlementFormAddCtrl',
          controllerAs: 'settlementFormEditCtrl',
          resolve: SettlementFormAddCtrlResolve,
          title: "settlementForm"
        })

        .state('page.financialSettlement_formDetails', {
          url: '/financial/settlement_form/:id',
          template: require('../template/page/financial/settlement_form.html'),
          controller: 'SettlementFormEditCtrl',
          controllerAs: 'settlementFormEditCtrl',
          resolve: SettlementFormAddCtrlResolve,
          title: "settlementForm"
        })
    })
    .factory('SettlementFormAdd', [
      function () {
        return function (self, $scope, settlementForm, settlementFormContext, metadata, notifyManager, $translate, $state) {
          self.settlementFormContext = settlementFormContext;
          self.metadata = metadata;
          self.settlementForm = settlementForm;
          angular.extend(self, settlementFormContext.data.dependencies);

          self.save = function () {
            if (self.settlementFormForm.$valid) {
              self.settlementForm.$save(function () {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go('page.financialSettlement_form');
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };

          self.saveAndNew = function () {
            if (self.settlementFormForm.$valid) {
              self.settlementForm.$save(function () {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };

          $scope.$watch('settlementFormEditCtrl.settlementForm.image', function (value1, value2) {
            if (value1 !== value2) {
              self.settlementFormForm.image.$setDirty(true);
            }
          });

          function updateCurrency() {
            if (self.settlementForm.type.name !== 'FOREIGN_CURRENCY') {
              self.settlementForm.currency = null;
            } else {
              self.settlementForm.currency = self.settlementFormContext.data.defaultCurrency;
            }
          }

          self.handleTypeChange = function () {
            updateCurrency();
          };

          self.handleSinglePurposeVoucherFlag = function () {
            if (self.settlementForm.singlePurposeVoucher) {
              self.settlementForm.addSystem = false;
              self.settlementForm.addUser = false;
              self.multiPurposeVoucher = false;
            }
          };

          self.handleMultiPurposeVoucherFlag = function () {
            if (self.settlementForm.multiPurposeVoucher) {
              self.settlementForm.addSystem = true;
              self.settlementForm.addUser = false;
              self.singlePurposeVoucher = false;
            }
          };

          self.disableTicketLayoutByType = function (type) {
            var result = _.find(self.settlementForm.billLayouts, function (o) {
              return o.type.id === type.id;
            });
            return !!result;
          }
        }
      }
    ])
    .controller('SettlementFormAddCtrl',
      function ($scope, settlementForm, settlementFormContext, settlementFormMetadataNew, SettlementFormAdd, notifyManager, $translate, $state) {
        var self = this;

        SettlementFormAdd(self, $scope, settlementForm, settlementFormContext, settlementFormMetadataNew, notifyManager, $translate, $state);
      }
    )
    .controller('SettlementFormEditCtrl',
      function ($scope, $location, $translate, notifyManager, settlementForm, settlementFormContext,
                settlementFormMetadataNew, SettlementFormAdd, $state) {

        var self = this;

        SettlementFormAdd(self, $scope, settlementForm, settlementFormContext, settlementFormMetadataNew, notifyManager, $translate, $state);

        self.save = function () {
          if (self.settlementFormForm.$valid) {
            self.settlementForm.$edit({settlementFormId: self.settlementForm.id}, function () {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.financialSettlement_form');
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
        self.saveAndNew = function () {
          if (self.settlementFormForm.$valid) {
            self.settlementForm.$edit({settlementFormId: self.settlementForm.id}, function () {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $location.path('/financial/settlement_form/add');
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

      }
    )
    .controller('SettlementFormGridCtrl',

      function ($scope, $translate, settlementFormContext, settlementFormMetadata, settlementFormMetadataNew, settlementFormPositions,
                SettlementFormPositions, payment_icons, securityService, currentTour, tourService, configOptionsService) {
        var self = this;

        self.currentTour = currentTour;
        tourService.startDetachedTour('financialSettlement_form', currentTour);

        self.metadataNew = settlementFormMetadataNew;
        // todo o.logunov: currency must be requared in new metadata only for grid.
        // todo o.logunov: Please, provide it when grid will be migrated on new metadata
        // todo o.logunov: if (self.metadataNew.fields['currency'].visible) {
        // todo o.logunov:    self.metadataNew.fields['currency'].validators.validator.name = 'requared';
        // todo o.logunov: }

        var addSystemEditable = self.metadataNew.fields['addSystem'].editable;

        self.metadata = settlementFormMetadata;
        if (self.metadataNew.fields['currency'].visible) {
          self.metadata.columns['currency'].angular = {required: ""};
        }

        self.context = settlementFormContext;

        var typeValidations = {
          'gf-paymentmethodtypevalidate': {
            'angular': {'gf-paymentmethodtypevalidate': ''},
            'errorMessage': "methodWithCashTypeExists"
          }
        };

        // columns
        var columns = [
          {name: 'id', width: 60, enableCellEdit: false, visible: false},
          {name: 'name', headerCellTemplate: 'app/template/tour/header_cell_tour.html'},
          {name: 'shortName', headerCellTemplate: 'app/template/tour/header_cell_tour.html'},
          {
            name: 'billLayouts',
            width: 150,
            cellEditableCondition: function () {
              return self.metadataNew.fields['billLayouts'].editable;
            },
            editableCellTemplate: 'app/template/sf_bill_layouts_multiselect.html',
            headerCellTemplate: 'app/template/tour/header_cell_tour.html',
            multiselect: true,
            gridMultiselectConfig: {
              isDisabled: function (option, ctrl) {
                return !ctrl.isChecked(option) && !!_.find(ctrl.selected, function (item) {
                  return item.type.id === option.type.id;
                });
              }
            }
          },
          {
            name: 'addSystem',
            width: 60,
            enableCellEdit: addSystemEditable,
            headerCellTemplate: 'app/template/tour/header_cell_tour.html',
            cellEditableCondition: function ($scope) {
              return !$scope.row.entity.singlePurposeVoucher && !$scope.row.entity.multiPurposeVoucher;
            },
            cellTemplate: "<div uib-tooltip=\"{{(row.entity.id!=null||row.entity.filled)&&row.entity.singlePurposeVoucher " +
              "?  ('settlementForm.totalRevenue.enable.tooltip' | translate) : (row.entity.id!=null||row.entity.filled)&&row.entity.multiPurposeVoucher " +
              "? ('settlementForm.totalRevenue.disable.tooltip' | translate) : ''}}\" tooltip-append-to-body=\"false\"  " +
              "class=\"ui-grid-cell-contents\" ng-show=\"row.entity.id!=null||row.entity.filled\">" +
              "<i ng-show=\"COL_FIELD\" class=\"far fa-check-square\"></i><i ng-hide=\"COL_FIELD\" class=\"far fa-square\"></i></div>"
          },
          {
            name: 'addUser', width: 60, headerCellTemplate: 'app/template/tour/header_cell_tour.html',
            cellEditableCondition: function ($scope) {
              return !$scope.row.entity.singlePurposeVoucher && !$scope.row.entity.multiPurposeVoucher;
            },
            cellTemplate: "<div uib-tooltip=\"{{(row.entity.id!=null||row.entity.filled)&&(row.entity.singlePurposeVoucher || row.entity.multiPurposeVoucher) " +
              "?  ('settlementForm.cashRevenue.enable.tooltip' | translate) : ''}}\" tooltip-append-to-body=\"false\"  " +
              "class=\"ui-grid-cell-contents\" ng-show=\"row.entity.id!=null||row.entity.filled\"><i ng-show=\"COL_FIELD\" class=\"far fa-check-square\">" +
              "</i><i ng-hide=\"COL_FIELD\" class=\"far fa-square\"></i></div>",

          },
          {
            name: 'singlePurposeVoucher', width: 60,
            validations:{
              'gf-singlepurposevouchermodeflag': {
                'angular': {'gf-singlepurposevouchermodeflag': ''},
                'errorMessage': "settlementFormType.SinglePurposeVoucherMode.warning"
              }
            },
            cellEditableCondition: function($scope) {
              return !$scope.row.entity.multiPurposeVoucher;
            },
            cellTemplate: "<div uib-tooltip=\"{{(row.entity.id!=null||row.entity.filled)&&row.entity.multiPurposeVoucher ?  ('settlementForm.singlePurpose.enable.tooltip' | translate) : ''}}\" tooltip-append-to-body=\"false\"  class=\"ui-grid-cell-contents\" ng-show=\"row.entity.id!=null||row.entity.filled\"><i ng-show=\"COL_FIELD\" class=\"far fa-check-square\"></i><i ng-hide=\"COL_FIELD\" class=\"far fa-square\"></i></div>",
          },
          {
            name: 'multiPurposeVoucher', width: 60,
            cellEditableCondition: function($scope) {
              return !$scope.row.entity.singlePurposeVoucher;
            },
            cellTemplate: "<div uib-tooltip=\"{{(row.entity.id!=null||row.entity.filled)&&row.entity.singlePurposeVoucher ?  ('settlementForm.multiPurpose.enable.tooltip' | translate) : ''}}\" tooltip-append-to-body=\"false\"  class=\"ui-grid-cell-contents\" ng-show=\"row.entity.id!=null||row.entity.filled\"><i ng-show=\"COL_FIELD\" class=\"far fa-check-square\"></i><i ng-hide=\"COL_FIELD\" class=\"far fa-square\"></i></div>",
          }
        ];

        columns.push({
          name: 'type',
          width: 200,
          cellFilter: "prefixFilter:'settlementFormType.'|translate",
          editDropdownFilter: "prefixFilter:'settlementFormType.'|translate",
          headerCellTemplate: 'app/template/tour/header_cell_tour.html',
          validations: typeValidations,
          afterCellEdit: function (colDef, entity) {
            if (entity.type.name !== 'FOREIGN_CURRENCY') {
              entity.currency = null;
            } else {
              entity.currency = self.context.data.defaultCurrency;
            }
          }
        });

        // if (self.metadataNew.fields['currency'].visible) {
        //   columns.push({
        //     name: 'currency', width: 200, visible: false, displayName: 'operator.currency',
        //     cellEditableCondition: function ($scope) {
        //       return $scope.row.entity.type.name == 'FOREIGN_CURRENCY'
        //     }
        //   });
        // }

        columns.push({name: 'account', width: 200, visible: false});

        payment_icons.forEach(function (icon) {
          icon.name = $translate.instant("icon." + icon.name);
        });
        payment_icons = _.sortBy(payment_icons, function (icon) {
          return icon.name;
        });
        columns.push({
          name: 'image',
          visible: false,
          editDropdownIdLabel: 'path',
          editDropdownValueLabel: 'name',
          cellFilter: 'mapImageArticleFilter:grid.appScope.context.data.dependencies.icons',
          editableCellTemplate: "<div><form name=\"inputForm\"><select class=\"form-control\" ng-class=\"'colt' + col.uid\" ui-grid-edit-dropdown ng-model=\"MODEL_COL_FIELD\" ng-options=\"field[editDropdownIdLabel] as field[editDropdownValueLabel]  CUSTOM_FILTERS for field in editDropdownOptionsArray\"><option value=\"\" translate=\"option.select\"></option></select></form></div>",
          editDropdownOptionsArray: payment_icons
        });

        // create hash map for mapImageArticleFilter
        self.context.data.dependencies.icons = {};
        payment_icons.forEach(function (icon) {
          self.context.data.dependencies.icons[icon.path] = icon.name;
        });

        columns.push({name: 'showOnPaymentScreen', visible: false, width: 30});

        if (self.metadataNew.fields['minPaymentAmount'].visible) {
          var minPaymentAmountValidator = {
            'gf-minpaymentamount': {
              'angular': {'gf-minpaymentamount': ''},
              'errorMessage': "validation.SettlementForm.minPaymentAmount.max"
            }
          };

          columns.push({name: 'minPaymentAmount', visible: false, width: 200, validations: minPaymentAmountValidator});
        }
        if (self.metadataNew.fields['maxPaymentAmount'].visible) {
          var maxPaymentAmountValidator = {
            'gf-maxpaymentamount': {
              'angular': {'gf-maxpaymentamount': ''},
              'errorMessage': "validation.SettlementForm.maxPaymentAmount.min"
            }
          };
          columns.push({name: 'maxPaymentAmount', visible: false, width: 200, validations: maxPaymentAmountValidator});
        }
        if (self.metadataNew.fields['voidable'].visible) {
          columns.push({name: 'voidable', visible: false, width: 100});
        }
        columns.push({name: 'description', visible: false, width: 200});
        columns.push({
          name: 'operate',
          actions: ['edit', 'delete'],
          headerCellTemplate: 'app/template/tour/header_cell_tour.html',
          actionOptions: {
            'delete': {
              isDisabled: configOptionsService.get('settlementFormDeleteButton').isDisabled
            }
          }
        });

        // grid
        self.config = {
          urlRoute: '/financial/settlement_form',
          enableSorting: true,
          enableAdd: true,
          enableFiltering: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: columns
        };

        self.settlementFormPositions = settlementFormPositions;
        self.settlementForms = self.settlementFormPositions.positions;

        self.savePositions = function () {
          self.settlementFormPositions.$save(function (data) {
            if (data.ok) {

              self.settlementFormPositions = new SettlementFormPositions(data.payload.positions);
              self.settlementForms = self.settlementFormPositions.positions;
            }
          });
        };

        self.refreshPositions = function () {
          SettlementFormPositions.load(function (data) {
            self.settlementFormPositions = data;
            self.settlementForms = self.settlementFormPositions.positions;

          });
        };

      }
    )
})();
