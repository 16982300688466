(function() {
  'use strict';
  angular.module('gf.hardware.ticketLayout', ['ui.router'])

    .factory('TicketLayout', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/ticket_layout/:ticketLayoutId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('TicketLayoutMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/ticket_layout/metadata.json');
    })
    .factory('TicketLayoutContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/ticket_layout/context.json');
    })

    .config(function($stateProvider) {

      var ticketLayoutMetadataResolve = function (TicketLayoutMetadata) {
          return TicketLayoutMetadata.get().$promise;
      };

      var ticketLayoutContextResolve = function (TicketLayoutContext) {
        return TicketLayoutContext.get().$promise;
      };


      $stateProvider
        .state('page.hardwareTicket_layout', {
          url: '/hardware/ticket_layout',
          template: require('../template/hardware/ticket-layout/ticket_layouts.html'),
          controller: 'TicketLayoutGridCtrl',
          controllerAs: 'ticketLayoutGridCtrl',
          resolve: {
            ticketLayoutMetadata: ticketLayoutMetadataResolve,
            ticketLayoutContext: ticketLayoutContextResolve
          },
          title: 'ticketLayout'
        })

        .state('page.hardwareTicket_layoutAdd', {
          url: '/hardware/ticket_layout/add',
          template: require('../template/hardware/ticket-layout/ticket_layout.html'),
          controller: 'TicketLayoutAddCtrl',
          controllerAs: 'ticketLayoutCtrl',
          resolve: {
            ticketLayoutMetadata: ticketLayoutMetadataResolve,
            ticketLayoutContext: ticketLayoutContextResolve,
            ticketLayout: function (TicketLayout) {
              return TicketLayout.load({ticketLayoutId: 'add'}).$promise;
            }
          },
          title: 'ticketLayout'
        })

        .state('page.hardwareTicket_layoutDetails', {
          url: '/hardware/ticket_layout/:id',
          template: require('../template/hardware/ticket-layout/ticket_layout.html'),
          controller: 'TicketLayoutEditCtrl',
          controllerAs: 'ticketLayoutCtrl',
          resolve: {
            ticketLayoutMetadata: ticketLayoutMetadataResolve,
            ticketLayoutContext: ticketLayoutContextResolve,
            ticketLayout: function (TicketLayout, $stateParams) {
              return TicketLayout.load({ ticketLayoutId: $stateParams.id }).$promise;
            }
          },
          title: 'ticketLayout'
        })
    })

    .factory('TicketLayoutAdd', [
      function () {
        return function (self, $scope, $location, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata) {
          self.ticketLayoutContext = ticketLayoutContext;
          self.ticketLayoutMetadata = ticketLayoutMetadata;
          self.ticketLayout = ticketLayout;
          angular.extend(self, ticketLayoutContext.data.dependencies);

          self.save = function () {
            if (self.ticketLayoutForm.$valid) {
              self.ticketLayout.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $location.path('/hardware/ticket_layout')
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          self.saveAndNew = function () {
            if (self.ticketLayoutForm.$valid) {
              self.ticketLayout.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          self.goBack = function () {
            $location.path('/hardware/ticket_layout')
          }
        }
      }
    ])

    .controller('TicketLayoutAddCtrl',

      function ($scope, $location, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata, TicketLayoutAdd) {
        var self = this;
        TicketLayoutAdd(self, $scope, $location, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata);
      }
    )

    .controller('TicketLayoutEditCtrl',

      function ($scope, $location, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata, TicketLayoutAdd) {
        var self = this;
        // Fill scope by service
        TicketLayoutAdd(self, $scope, $location, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata);

        self.save = function () {
          if (self.ticketLayoutForm.$valid) {
            self.ticketLayout.$edit({ticketLayoutId: self.ticketLayout.id}, function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $location.path('/hardware/ticket_layout')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        self.saveAndNew = function () {
          if (self.ticketLayoutForm.$valid) {
            self.ticketLayout.$edit({ticketLayoutId: self.ticketLayout.id}, function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $location.path('/hardware/ticket_layout/add')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

      }
    )

    .controller('TicketLayoutGridCtrl',

      function ($scope, ticketLayoutContext, ticketLayoutMetadata, configOptionsService) {
        var self = this;

        self.metadata = ticketLayoutMetadata;
        self.context = ticketLayoutContext;

        var actions = [{
          name: 'copy',
          template: 'app/template/grid/copy_article_action.html',
          controller: 'CopyTicketLayoutActionCtrl'
        }];

        self.config = {
          urlRoute: '/hardware/ticket_layout',
          registerActions: actions,
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'id', width: 70, visible: false},
            {name: 'name'},
            {
              name: 'type',
              width: 300,
              cellFilter: "prefixFilter:'ticketType.' | translate",
              editDropdownFilter: "prefixFilter:'ticketType.' | translate"
            },
            {
              name: 'operate',
              actions: ['edit', 'copy', 'delete'],
              actionOptions: {
                'delete': {
                  isDisabled: configOptionsService.get('ticketLayoutDeleteButton').isDisabled
                }
              }
            }
          ]
        };

      }
    )

    .controller('CopyTicketLayoutActionCtrl',
      function ($scope, $translate, notifyManager, TicketLayout, entity, gridApi) {
        var self = this;

        self.gridApi = gridApi;
        self.entity = entity;

        var newEntity = angular.copy(entity);
        newEntity.id = null;

        var ticketLayoutResource = new TicketLayout(newEntity);

        ticketLayoutResource.$save(function (data) {
          var index = _.findIndex(self.gridApi.grid.options.data, function (item) {
            return item.id == self.entity.id;
          });
          // insert new article to the next position
          self.gridApi.grid.options.data.splice(index + 1, 0, data.payload.entity);

          notifyManager.success($translate.instant('entity.save.successfully'));
        });
      }
    )
})();
