import _ from 'lodash';
import angular from 'angular';

angular.module('gf.setup.history', [])

  .factory('HistoryChanges', function ($resource, appConfig) {
    return $resource(appConfig.baseUrl + '/icash/setup/history/:domain/:id.json')
  })
  .factory('HistoryChangesPeriod', function ($resource, appConfig) {
    return $resource(appConfig.baseUrl + '/icash/setup/history/period/:domain/:id.json')
  })
  .factory('HistoryContext', function ($resource, appConfig) {
    return $resource(appConfig.baseUrl + '/icash/setup/history/:domain/context.json')
  })
  .factory('HistoryMetadata', function ($resource, appConfig) {
    return $resource(appConfig.baseUrl + '/icash/setup/history/:domain/metadata.json')
  })
  .factory('AppVersionHistoryContext', function ($resource, appConfig) {
    return $resource(appConfig.baseUrl + '/icash/setup/app_version_history/context.json')
  })
  .factory('AppVersionHistoryMetadata', function ($resource, appConfig) {
    return $resource(appConfig.baseUrl + '/icash/setup/app_version_history/metadata.json')
  })

  .config(function ($stateProvider) {

    var HistoryCtrlResolve = {
      historyContext: ['HistoryContext', '$stateParams', function (HistoryContext, $stateParams) {
        return HistoryContext.get({domain: $stateParams.domain}).$promise;
      }],
      historyMetadata: ['HistoryMetadata', '$stateParams', function (HistoryMetadata, $stateParams) {
        return HistoryMetadata.get({domain: $stateParams.domain}).$promise;
      }]
    };

    var HistoryChangesCtrlResolve = {
      historyChanges: ["HistoryChanges", "$stateParams", function (HistoryChanges, $stateParams) {
        return HistoryChanges.get({domain: $stateParams.domain, id: $stateParams.id}).$promise;
      }]
    };

    $stateProvider
      .state('page.setupAppVersionHistory', {
        url: '/setup/history/hardware/app_version_history',
        template: require('../template/setup/history/history.html'),
        controller: "AppVersionHistoryCtrl",
        controllerAs: "historyCtrl",
        resolve: {
          appVersionHistoryContext: ['AppVersionHistoryContext', function (AppVersionHistoryContext) {
            return AppVersionHistoryContext.get().$promise;
          }],
          appVersionHistoryMetadata: ['AppVersionHistoryMetadata', function (AppVersionHistoryMetadata) {
            return AppVersionHistoryMetadata.get().$promise;
          }]
        },
        title: 'history'
      })

      .state('page.setupHistory', {
        url: '/setup/history/:tab/:domain',
        template: require('../template/setup/history/history.html'),
        controller: 'HistoryCtrl',
        controllerAs: 'historyCtrl',
        resolve: HistoryCtrlResolve,
        title: 'history'
      })

      .state('page.setupHistoryTabDomainId', {
        url: '/setup/history/:tab/:domain/:id',
        template: require('../template/setup/history/history_changes.html'),
        controller: 'HistoryChangesCtrl',
        controllerAs: '$ctrl',
        resolve: HistoryChangesCtrlResolve,
        title: 'history'
      });
  })
  .controller('HistoryCtrl',
    function ($translate, $stateParams, appConfig, securityService, dialogs, $uibModal, $http, notifyManager, historyContext, historyMetadata) {
      var self = this;

      self.tab = $stateParams.tab;
      self.domain = $stateParams.domain;
      self.entityName = historyContext.data.entityName;

      self.open = {
        masterdata: (self.tab === 'masterdata'),
        setup: (self.tab === 'setup'),
        hardware: (self.tab === 'hardware'),
        financial: (self.tab === 'financial'),
        customers: (self.tab === 'customers'),
        operator: (self.tab === 'operator'),
        filter: (self.tab === 'filter')
      };

      // "Article", "Article-Group", "Employee", "Employee-Group rights", "Payment Methods".
      self.showExportButton = self.domain === 'article'
        || self.domain === 'article_group'
        || self.domain === 'waiter'
        || self.domain === 'waiter_group'
        || self.domain === 'settlement_form'
      ;

      self.context = historyContext;
      self.metadata = historyMetadata;

      var actions = [
        {
          name: 'details',
          template: 'app/template/grid/details_action.html',
          controller: 'DetailsActionCtrl'
        }
      ];

      var columns = [];
      if (self.domain === 'customer_group') {
        columns.push({
          name: "id",
          width: 300,
          enableCellEdit: false,
          displayName: 'operator.id'
        });
      } else if (self.domain === 'reason') {
        columns.push({
          name: "id",
          width: 80,
          filterPathFieldName: 'reason_id',
          enableCellEdit: false,
          displayName: 'operator.id'
        });
      } else {
        columns.push({
          name: "id",
          width: 80,
          enableCellEdit: false,
          displayName: 'operator.id'
        });
      }
      if (self.domain === 'course_group') {
        columns.push({
          name: "name",
          filterPathFieldName: 'course_group_name',
          enableCellEdit: false,
          displayName: 'operator.name'
        });
      } else if (self.domain === 'reason') {
        columns.push({
          name: "name",
          filterPathFieldName: 'description',
          enableCellEdit: false,
          displayName: 'reason.description'
        });
      } else {
        columns.push({name: "name", enableCellEdit: false, displayName: 'operator.name'});
      }

      columns.push({
        name: 'modified',
        enableFiltering: false,
        displayName: 'ui.titles.modified',
        cellFilter: "date:'HH.mm dd.MM.yyyy'",
        width: 130,
        enableColumnResizing: true,
        enableCellEdit: false
      });
      columns.push({
        name: 'modifiedBy',
        filterPathFieldName: 'modified_by',
        width: 130,
        displayName: 'history.modifiedBy',
        enableCellEdit: false
      });
      columns.push({name: 'actions', actions: ['details']});

      self.config = {
        urlRoute: '/setup/history/' + self.domain,

        registerActions: actions,
        enableFiltering: true,
        enablePagination: true,
        enableSorting: true,
        enableColumnResizing: false,
        disableSaveState: true,
        columns: columns
      };

      self.export = function () {
        if (securityService.context.currentUser.email && securityService.context.currentUser.mailConfirmed) {
          $http({
            method: 'GET',
            url: appConfig.baseUrl + "/icash/setup/history/" + self.domain + "/export"
          }).then(function (response) {
              if (response.data.status === 'OK') {
                $uibModal.open({
                  template: require('../template/setup/history/send_mail_history_export_report.html'),
                  windowClass: "gf-modal",
                  controller: 'ExportHistoryReportMailCtrl',
                  controllerAs: 'migrateReportMailCtrl'
                });
              } else {
                notifyManager.error($translate.instant('messages.exportHistoryFailed'));
              }
            }, function () {
              notifyManager.error($translate.instant('messages.exportHistoryFailed'));
            }
          );
        } else {
          $uibModal.open({
            template: require('../../gf-dashboard/template/page/welcome/provideEmailPopup.html'),
            controller: 'ProvideEmailCtrl',
            controllerAs: '$ctrl',
            windowClass: 'gf-modal'
          });
        }
      };
    }
  )

  .controller('AppVersionHistoryCtrl', function ($scope, $translate, appVersionHistoryContext, appVersionHistoryMetadata) {

    var self = this;
    self.context = appVersionHistoryContext;
    self.metadata = appVersionHistoryMetadata;
    self.entityName = appVersionHistoryContext.data.entityName;
    self.domain = 'app_version_history';

    self.open = {
      hardware: true
    };

    var columns = [];
    columns.push({
      name: "version",
      filterPathFieldName: "version",
      displayName: 'appVersion',
      enableCellEdit: false
    });
    columns.push({
      name: "build",
      filterPathFieldName: "build",
      displayName: 'device.appBuild',
      enableCellEdit: false
    });
    columns.push({
      name: "updated",
      filterPathFieldName: "updated",
      displayName: 'jsp.last.updated1',
      cellFilter: "date:'HH.mm dd.MM.yyyy'",
      enableColumnResizing: true,
      enableCellEdit: false
    });

    self.config = {
      urlRoute: '/setup/app_version_history',

      enablePagination: true,
      enableSorting: true,
      sortDefs: [
        {field: "updated", order: "DESC", priority: 0}
      ],
      enableColumnResizing: false,
      disableSaveState: true,
      columns: columns
    };
  })

  .controller('ExportHistoryReportMailCtrl',
    function ($scope, $uibModalInstance) {
      var self = this;
      self.close = function () {
        $uibModalInstance.close();
      };
    }
  )

  .controller('DetailsActionCtrl',
    function (entity, $state, $stateParams) {
      $stateParams.id = entity.id;
      $state.go('page.setupHistoryTabDomainId', $stateParams);
    })

  .controller('HistoryChangesCtrl',
    function ($uibModal, $translate, $location, $stateParams, $state, $filter, notifyManager, HistoryChangesPeriod,
              historyChanges) {
      const self = this;

      if (historyChanges.ok) {
        self.tab = $stateParams.tab;
        self.domain = $stateParams.domain;

        self.back = function () {
          $state.go('page.setupHistory', $stateParams);
        };

        self.entities = historyChanges.payload.entities;
        self.entityName = historyChanges.payload.entityName;

        self.updateDate = function (from, to) {
          if (from && to && (from < to)) {
            updateData($filter("date")(from, 'yyyy-MM-dd'), $filter("date")(to, 'yyyy-MM-dd'));
          }
        };

        if (self.domain === 'fiscal_device') {
          const displayConfigValueConfig = {
            type: 'json',
            translationPrefix: 'tse.'
          };

          self.displayOldValueConfig = {
            'fiscalDevice.configuration': {
              ...displayConfigValueConfig,
              hideFields: ['hash']
            }
          };

          self.displayNewValueConfig = {
            'fiscalDevice.configuration': {
              ...displayConfigValueConfig
            }
          };
        }
      }

      function updateData(from, to) {
        HistoryChangesPeriod.get({
          domain: $stateParams.domain,
          id: $stateParams.id,
          from: from,
          to: to
        }, function (data) {
          if (data.ok) {
            self.entities = data.payload.entities;
          } else {
            notifyManager.error($translate.instant('fail.load.data'));
          }
        });
      }
    }
  )

  .component('valueTdContent', {
    template: require('../template/setup/history/value_td_content.html'),
    bindings: {
      field: '<',
      value: '<',
      configuration: '<'
    },
    controller: function () {
      const self = this;

      self.$onInit = () => {
        if (self.configuration && self.configuration[self.field] && self.configuration[self.field].type === 'json') {
          self.complexValue = true;
          self.config = self.configuration[self.field];
          const value = parseJSONOrNothing(self.value);
          if (value) {
            self.preparedKeyValues = objToArr(value);
          }
        }
      };

      function objToArr(obj) {
        return Object.entries(obj).map(([key, value]) => ({key, value}));
      }

      function parseJSONOrNothing(json) {
        if (!json || typeof json !== 'string') return;
        try {
          const parsed = JSON.parse(json);
          return _.isObject(parsed) ? parsed : undefined;
        } catch (e) {
          // Nothing if can't be parsed
        }
      }
    }
  });
