import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";

function ChangePrinterWarningDialog({open, onSave, printer, handleClose}) {

  const {t} = useTranslation();
  const handleCloseDialog = () => {
    handleClose();
  };

  const handleSave = () => {
    onSave();
  };

  return (
    <div>
      <Dialog
        id='confirm-delete-dialog'
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id='delete-dialog-title'>{t('Change TSE printer confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-dialog-description'>
            {t('tse.changePrinterDialog.warning', {printer: printer.name})}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id='delete-dialog-cancel-btn' onClick={handleCloseDialog}>
            {t("button.cancel")}
          </Button>
          <Button id='delete-dialog-confirm-btn' onClick={handleSave} variant='contained'
                  autoFocus>
            {t("button.save")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

}

export default ChangePrinterWarningDialog;
