(function () {
  'use strict';
  angular.module('gf.financial')
    .controller('TaxGridCtrl',
      function (taxMetadata, taxContext, $translate, $uibModal, GridApi, notifyManager, $http, $scope, taxRuleUtils) {
        var self = this;

        self.metadata = taxMetadata;
        self.context = taxContext;

        var columns = [
          {
            name: 'id',
            displayName: 'taxClass.id',
            width: 60,
            enableCellEdit: false
          },
          {
            name: 'name',
            displayName: 'taxClass.name'
          },
          {
            name: 'mark',
            displayName: 'taxClass.mark'
          },
          {
            name: 'operate',
            headerCellTemplate: '<div class="text-center gf-grid-add-column-wrapper"><a href ng-click="grid.appScope.config.addTaxAuthorityModal()" uib-tooltip="{{\'taxAuthority.add\' | translate}}"><span class="add-grid-icon"></span></a></div>',
            actions: ['delete']
          }
        ];

        var groupActions = [
          {
            title: 'button.delete',
            action: function (entities) {
              $uibModal.open({
                template: require('./../../../../../../components/gf-masterdata-article/template/delete_group_confirmation.html'),
                windowClass: "gf-modal"
              }).result.then(function () {

                var ids = entities.map(function (entity) {
                  return entity.id;
                });

                $http
                  .post('/icash/financial/tax/deleteGroup/', ids)
                  .then(function (resp) {
                    if (resp.data.ok) {
                      self.config.reloadData();
                      notifyManager.info($translate.instant('messages.taxClass.group.deleted.success'));
                    } else {
                      notifyManager.error($translate.instant(resp.data.message));
                    }
                  });
              });
            }
          }
        ];

        self.config = {
          urlRoute: '/financial/tax',
          registerGroupActions: groupActions,
          enableRowSelection: true,
          enableSorting: true,
          //enableAddRow: true,
          columns: columns,
          addTaxAuthorityModal: addTaxAuthorityModal,
          manageTaxRulesModal: manageTaxRulesModal,
          isAllExpired: taxRuleUtils.isAllExpired,
          isNonExempt: taxRuleUtils.isNonExempt,
          prepareData: function (data, context) {
            data.forEach(function (taxClass) {
              taxClass.taxRules = _.groupBy(taxClass.taxRules, 'taxAuthority.id');
            });
          }
        };

        $scope.$watch(function () {
          return self.context.data.dependencies.taxAuthority;
        }, function (taxAuthorities) {
          removeAllTaxAuthorityColumns();
          taxAuthorities.forEach(function (taxAuthority) {
            addTaxAuthorityColumn(taxAuthority)
          });
        });

        function addTaxAuthorityModal() {
          $uibModal.open({
            template: require('./../../../template/page/financial/tax/add_tax_authority_modal.html'),
            windowClass: 'gf-modal',
            controller: 'AddTaxAuthorityModalCtrl',
            controllerAs: 'taModalCtrl',
            resolve: {
              taxAuthorityContext: function (TaxAuthorityContext) {
                return TaxAuthorityContext.get().$promise;
              }
            }
          }).result.then(function () {
            self.context.$get();
          });
        }

        function addTaxAuthorityColumn(ta) {
          columns.splice(columns.length-1, 0, {
            name: 'taDynamicColumn' + ta.id,
            displayName: ta.name,
            field: 'taxRules[' + ta.id + ']',
            width: 200,
            enableCellEdit: false,
            cellTemplate: '<div class="ui-grid-cell-contents cell-clickable" ' +
            'ng-class="{\'cell-danger\': grid.appScope.config.isAllExpired(grid.getCellValue(row, col)), ' +
            '\'cell-info\': grid.appScope.config.isNonExempt(grid.getCellValue(row, col))}" ' +
            'ng-click="grid.appScope.config.manageTaxRulesModal('+ta.id+', row, grid)"' +
            '>' +
            '{{grid.getCellValue(row, col) | taxRulesToString}}' +
            '</div>'
          });
        }

        function removeAllTaxAuthorityColumns() {
          _.remove(self.config.columns, function (column) {
             return column.name.indexOf('taDynamicColumn') === 0;
          });
        }

        function manageTaxRulesModal(taId, row, grid) {
          if (row.entity.addRow) {
            return;
          }
          $uibModal.open({
            template: require('./../../../template/page/financial/tax/manage_tax_rules_modal.html'),
            windowClass: 'gf-modal',
            size: 'lg',
            controller: 'ManageTaxRulesModalCtrl',
            controllerAs: 'trModalCtrl',
            backdrop: 'static',
            resolve: {
              taxRulesConfig: {
                taxClassId: row.entity.id,
                taxAuthorityId: taId
              },
              taxRulesContext: function (TaxRulesContext) {
                return TaxRulesContext.get({taxClassId: row.entity.id, taxAuthorityId: taId}).$promise;
              },
              taxRulesMetadata: function (TaxRulesMetadata) {
                return TaxRulesMetadata.get().$promise;
              }
            }
          }).closed.then(function () {
            self.config.reloadData();
          });
        }

        self.addTaxClassModal = function () {
          $uibModal.open({
            template: require('./../../../template/page/financial/tax/add_tax_class_modal.html'),
            windowClass: 'gf-modal',
            controller: 'AddTaxClassModalCtrl',
            controllerAs: 'tcModalCtrl'
          }).result.then(function (entity) {
            self.config.reloadData();
            notifyManager.success($translate.instant('entity.save.successfully'));
          });
        };

        self.manageTaxAuthorityModal = function () {
          $uibModal.open({
            template: require('./../../../template/page/financial/tax/manage_tax_authority_modal.html'),
            windowClass: 'gf-modal',
            size: 'lg',
            controller: 'ManageTaxAuthorityModalCtrl',
            controllerAs: 'mtaModalCtrl',
            backdrop: 'static',
            resolve: {
              taxAuthorityContext: function (TaxAuthorityContext) {
                return TaxAuthorityContext.get().$promise;
              },
              taxAuthorityMetadata: function (TaxAuthorityMetadata) {
                return TaxAuthorityMetadata.get().$promise;
              }
            }
          }).closed.then(function () {
            self.context.$get();
          });
        };

        self.manageTaxLocationModal = function () {
          $uibModal.open({
            template: require('./../../../template/page/financial/tax/manage_tax_location_modal.html'),
            windowClass: 'gf-modal',
            controller: 'ManageTaxLocationModalCtrl',
            controllerAs: 'mtlModalCtrl',
            backdrop: 'static',
            resolve: {
              taxLocationContext: function (TaxLocationContext) {
                return TaxLocationContext.get().$promise;
              },
              taxLocationMetadata: function (TaxLocationMetadata) {
                return TaxLocationMetadata.get().$promise;
              }
            }
          }).closed.then(function () {
            self.context.$get();
          });
        };
      }
    )
    .controller('AddTaxAuthorityModalCtrl',
      function ($uibModalInstance, $http, notifyManager, $translate, taxAuthorityContext) {
        var self = this;

        //self.taxLocations = taxAuthorityContext.data.dependencies.taxLocations;

        self.taxAuthority = {
          name: '',
          taxDorId: ''
          //taxLocations: []
        };

        self.add = function () {
          $http
            .post('/icash/financial/tax/taxAuthority/add', self.taxAuthority)
            .then(function (resp) {
              if (resp.data.ok) {
                $uibModalInstance.close();
                notifyManager.info($translate.instant('messages.taxAuthority.added.success'));
              } else {
                notifyManager.error($translate.instant(resp.data.message));
              }
            });
        }
      }
    )
    .controller('ManageTaxAuthorityModalCtrl',
      function ($uibModalInstance, taxAuthorityContext, taxAuthorityMetadata, $translate, notifyManager, $uibModal,
                $http, gfGridUtils) {
        var self = this;

        self.context = taxAuthorityContext;
        self.metadata = taxAuthorityMetadata;

        self.gridConfig = {
          urlRoute: '/financial/tax/taxAuthority',
          registerGroupActions: [
            {
              title: 'button.delete',
              action: groupDelete
            }
          ],
          enableRowSelection: true,
          enableSorting: true,
          enableAddRow: true,
          columns: [
            {
              name: 'name',
              displayName: 'taxAuthority.name'
            },
            {
              name: 'taxDorId',
              displayName: 'taxAuthority.dorId'
            },
            //{
            //  name: 'taxLocations',
            //  filterPathFieldName: 'taxLocations.name',
            //  displayName: 'taxAuthority.taxLocations',
            //  multiselect: true
            //},
            {
              name: 'operate',
              actions: ['delete']
            }
          ]
        };

        function groupDelete(entities) {
          $uibModal.open({
            template: require('./../../../../../../components/gf-masterdata-article/template/delete_group_confirmation.html'),
            windowClass: "gf-modal"
          }).result.then(function () {
            var ids = entities.map(function (entity) {
              return entity.id;
            });

            $http
              .post('/icash/financial/tax/taxAuthority/deleteGroup/', ids)
              .then(function (resp) {
                if (resp.data.ok) {
                  self.gridConfig.reloadData();
                  notifyManager.info($translate.instant('messages.taxAuthority.group.deleted.success'));
                } else {
                  notifyManager.error($translate.instant(resp.data.message));
                }
              });
          });
        }

        $uibModalInstance.rendered.then(function() {
          self.modalIsReady = true;
        });

        // safe closing section
        var gridApi;
        self.gridConfig.onRegisterApi = function (gApi) {
          gridApi = gApi;
        };

        self.close = function () {
          self.saving = true;
          gfGridUtils.imitateSafeClosing(gridApi)
            .then(function () {
              $uibModalInstance.close();
              self.saving = false;
            });
        };
        // end - safe closing section
      }
    )
    .controller('ManageTaxLocationModalCtrl',
      function ($uibModalInstance, taxLocationContext, taxLocationMetadata, $translate, notifyManager, $uibModal, $http) {
        var self = this;

        self.context = taxLocationContext;
        self.metadata = taxLocationMetadata;

        self.gridConfig = {
          urlRoute: '/financial/tax/taxLocation',
          registerGroupActions: [
            {
              title: 'button.delete',
              action: groupDelete
            }
          ],
          enableRowSelection: true,
          enableSorting: true,
          enableAddRow: true,
          columns: [
            {
              name: 'name',
              displayName: 'taxLocation.name'
            },
            {
              name: 'operate',
              actions: ['delete']
            }
          ]
        };

        function groupDelete(entities) {
          $uibModal.open({
            template: require('./../../../../../../components/gf-masterdata-article/template/delete_group_confirmation.html'),
            windowClass: "gf-modal"
          }).result.then(function () {
            var ids = entities.map(function (entity) {
              return entity.id;
            });

            $http
              .post('/icash/financial/tax/taxLocation/deleteGroup/', ids)
              .then(function (resp) {
                if (resp.data.ok) {
                  self.gridConfig.reloadData();
                  notifyManager.info($translate.instant('messages.taxLocation.group.deleted.success'));
                } else {
                  notifyManager.error($translate.instant(resp.data.message));
                }
              });
          });
        }

        $uibModalInstance.rendered.then(function() {
          self.modalIsReady = true;
        });
      }
    )
    .controller('ManageTaxRulesModalCtrl',
      function ($uibModalInstance, $http, taxRulesConfig, taxRulesContext, taxRulesMetadata, $translate, $uibModal,
                notifyManager, gfGridUtils) {
        var self = this;

        self.context = taxRulesContext;
        self.metadata = taxRulesMetadata;

        function groupDelete(entities) {
          $uibModal.open({
            template: require('./../../../../../../components/gf-masterdata-article/template/delete_group_confirmation.html'),
            windowClass: "gf-modal"
          }).result.then(function () {
            var ids = entities.map(function (entity) {
              return entity.id;
            });

            $http
              .post('/icash/financial/tax/taxRule/deleteGroup/' + taxRulesConfig.taxClassId + '/' + taxRulesConfig.taxAuthorityId, ids)
              .then(function (resp) {
                if (resp.data.ok) {
                  self.gridConfig.reloadData();
                  notifyManager.info($translate.instant('messages.taxRules.group.deleted.success'));
                } else {
                  notifyManager.error($translate.instant(resp.data.message));
                }
              });
          });
        }

        function groupChangeRate(entities) {
          $uibModal.open({
            template: require('./../../../template/page/financial/tax/change_rate_group_confirmation.html'),
            windowClass: 'gf-modal'
          }).result.then(function (newRate) {
            entities.forEach(function (entity) {
              entity.rate = newRate;
            });

            groupSave(entities).then(function (resp) {
              self.gridConfig.reloadData();
              if (resp.data.ok) {
                notifyManager.info($translate.instant('messages.taxRules.group.changeRate.success'));
              } else {
                notifyManager.error($translate.instant(resp.data.message));
              }
            }, function () {
              self.gridConfig.reloadData();
              notifyManager.error($translate.instant('messages.taxRules.group.changeRate.fail'));
            });
          });
        }

        function groupChangeNonExempt(entities) {
          $uibModal.open({
            template: require('./../../../template/page/financial/tax/change_nonexempt_group_confirmation.html'),
            windowClass: "gf-modal",
            controller: function () {
              this.nonExemptValue = false;
            },
            controllerAs: 'changeNonExemptCtrl'
          }).result.then(function (nonexempt) {
            entities.forEach(function (entity) {
              entity.nonexempt = nonexempt;
            });

            groupSave(entities).then(function (resp) {
              self.gridConfig.reloadData();
              if (resp.data.ok) {
                notifyManager.info($translate.instant('messages.taxRules.group.changeNonExempt.success'));
              } else {
                notifyManager.error($translate.instant(data.message));
              }
            }, function () {
              self.gridConfig.reloadData();
              notifyManager.error($translate.instant('messages.taxRules.group.changeNonExempt.fail'));
            });
          });
        }

        function groupSave(entities) {
          return $http.post('/icash/financial/tax/taxRule/updateGroup/', entities);
        }

        self.gridConfig = {
          urlRoute: '/financial/tax/taxRate',
          registerGroupActions: [
            {
              title: 'button.delete',
              action: groupDelete
            },
            {
              title: 'TaxRule.group.changeRate',
              action: groupChangeRate
            },
            {
              title: 'TaxRule.group.changeNonExempt',
              action: groupChangeNonExempt
            }
          ],
          enableRowSelection: true,
          enableSorting: true,
          enableAddRow: true,
          columns: [
            {
              name: 'start',
              displayName: 'taxRule.start'
            },
            {
              name: 'expires',
              displayName: 'taxRule.expires'
            },
            {
              name: 'rate',
              displayName: 'taxRule.rate',
              cellFilter: null,
              editableCellTemplate: "<div><form name=\"inputForm\"><input ignore-mouse-wheel type=\"INPUT_TYPE\" step=\"0.0001\" ng-class=\"'colt' + col.uid\" ui-grid-editor ng-model=\"MODEL_COL_FIELD\"></form></div>"
            },
            {
              name: 'nonexempt',
              displayName: 'taxRule.nonExempt'
            },
            {
              name: 'operate',
              actions: ['delete']
            }
          ]
        };

        $uibModalInstance.rendered.then(function() {
          self.modalIsReady = true;
        });

        // safe closing section
        var gridApi;
        self.gridConfig.onRegisterApi = function (gApi) {
          gridApi = gApi;
        };

        self.close = function () {
          self.saving = true;
          gfGridUtils.imitateSafeClosing(gridApi)
            .then(function () {
              $uibModalInstance.close();
              self.saving = false;
            });
        };
        // end - safe closing section
      }
    )
    .controller('AddTaxClassModalCtrl',
      function ($uibModalInstance, $http) {
        var self = this;

        self.taxClass = {};

        self.add = function () {
          $http
            .post('/icash/financial/tax/', self.taxClass)
            .then(function (resp) {
              var entity = resp.data.payload.entity;
              $uibModalInstance.close(entity);
            });
        }
      }
    )
    .filter('taxRulesToString', function (taxRuleUtils, $translate) {
      return function (arr) {
        if (!arr || !_.isArray(arr) || !arr.length) {
          return '';
        }
        var actualRules = taxRuleUtils.filterActual(arr);
        if (!actualRules.length) {
          return $translate.instant('expired');
        }
        var taxRule = taxRuleUtils.getLastStarted(arr);
        return taxRule.rate + '% ' +  taxRuleUtils.expiresToString(taxRule.expires);
      }
    })
    .factory('taxRuleUtils', function ($translate, $filter) {
      var utils = {
        isActual: function (taxRule) {
          var nowTimestamp = (new Date()).getTime();
          return taxRule.start < nowTimestamp && (!taxRule.expires || nowTimestamp < taxRule.expires);
        },
        filterActual: function (taxRules) {
          return _.filter(taxRules, function (tr) {
            return utils.isActual(tr);
          })
        },
        expiresToString: function (exp) {
          return exp
            ? $translate.instant('expires') + ' ' + $filter('date')(new Date(exp), 'shortDate')
            :  $translate.instant('unrestricted');
        },
        getLastStarted: function (taxRules) {
          return _.maxBy(taxRules, 'start');
        },
        isAllExpired: function (taxRules) {
          if (!taxRules) {
            return false;
          }
          return utils.filterActual(taxRules).length === 0;
        },
        isNonExempt: function (taxRules) {
          var actualRules = utils.filterActual(taxRules);
          if (actualRules.length === 0) {
            return false
          }
          return utils.getLastStarted(actualRules).nonexempt;
        }
      };
      return utils;
    })
})();
