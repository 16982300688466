(function () {
  'use strict';
  angular.module('gf.hardware.device', ['ui.router'])

    .run(function ($templateCache) {
      $templateCache.put('app/template/grid/zuora_contract_select.html', require('./../../../main/angular/app/template/grid/zuora_contract_select.html'));
      $templateCache.put('app/template/grid/zuora_contract_info.html', require('./../../../main/angular/app/template/grid/zuora_contract_info.html'));
    })

    .factory('DeviceMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/device/metadata.json')
    })
    .factory('DeviceMetadataNew', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/device/metadata_new.json')
    })
    .factory('DeviceContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/device/context.json')
    })
    .factory('ZuoraContractList', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/device/:deviceId/zuora.json')
    })
    .factory('WizardCode', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/device/code')
    })
    .factory('Device', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/device/:deviceId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('OutletsStatus', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/device/outlets/status', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(["$provide", function ($provide) {
      var urls = {
        'DeviceMasterSwitch': '/hardware/device/master',
        'ZuoraContractAssign': '/hardware/device/:deviceId/zuora/:contractId',
        'HardwareDeviceSwitchRestaurantAction': '/hardware/device/open/:restaurantId',
        'DeviceRestaurants': '/hardware/device/restaurants.json',
        'DeleteZuoraContractAssign': '/hardware/device/:deviceId/zuora'
      };
      _.each(urls, function (jsonLocation, name) {
        $provide.constant(name, '/icash' + jsonLocation)
      });
    }])

    .config(function ($stateProvider) {

      var DeviceAddCtrlResolve = {
        deviceMetadata: ["DeviceMetadata", function (DeviceMetadata) {
          return DeviceMetadata.get().$promise;
        }],
        deviceMetadataNew: ["DeviceMetadataNew", function (DeviceMetadataNew) {
          return DeviceMetadataNew.get().$promise;
        }],
        deviceContext: ["DeviceContext", function (DeviceContext) {
          return DeviceContext.get().$promise;
        }],
        device: ["Device", "$stateParams", function (Device, $stateParams) {
          return Device.load({deviceId: $stateParams.id || "add"}).$promise;
        }]
      };

      var DeviceWizardCtrlResolve = {
        wizardCode: ["WizardCode", function (WizardCode) {
          return WizardCode.save().$promise;
        }]
      };

      $stateProvider
        .state('page.hardwareDevice', {
          url: '/hardware/device',
          template: require('../template/page/hardware/devices.html'),
          controller: 'DeviceGridCtrl',
          controllerAs: 'deviceGridCtrl',
          resolve: DeviceAddCtrlResolve,
          title: 'device'
        })

        .state('page.hardwareDeviceWizard', {
          url: '/hardware/device/wizard',
          template: require('../template/page/hardware/device_code.html'),
          controller: 'DeviceWizardCtrl',
          controllerAs: 'deviceWizardCtrl',
          resolve: DeviceWizardCtrlResolve,
          title: 'device'
        })

        .state('page.hardwareDeviceAdd', {
          url: '/hardware/device/add',
          template: require('../template/page/hardware/device.html'),
          controller: 'DeviceAddCtrl',
          controllerAs: 'deviceEditCtrl',
          resolve: DeviceAddCtrlResolve,
          title: 'device'
        })

        .state('page.hardwareDeviceDetails', {
          url: '/hardware/device/:id',
          template: require('../template/page/hardware/device.html'),
          controller: 'DeviceEditCtrl',
          controllerAs: 'deviceEditCtrl',
          resolve: DeviceAddCtrlResolve,
          title: 'device'
        })
    })
    .factory('DeviceAdd', [
      function () {
        return function (self, $scope, $location, $http, $translate, $state, notifyManager, device, deviceContext, deviceMetadata, deviceMetadataNew) {
          self.deviceContext = deviceContext;
          self.deviceMetadata = deviceMetadata;
          self.metadataNew = deviceMetadataNew;
          self.device = device;

          self.multiselectDefaultSettings = $scope.multiselectDefaultSettings;
          self.multiselectText = $scope.multiselectText;

          angular.extend(self, deviceContext.data.dependencies);

          self.showDefaultBon = self.deviceContext.data.defaultBonPrinters.length > 0 && self.device.restrictedBonPrinters.length <= 0;
          self.showDefaultBill = self.deviceContext.data.defaultBillPrinter && !self.device.billPrinter;
          self.showDefaultJournal = self.deviceContext.data.defaultJournalPrinter && !self.device.journalPrinter;
          self.defaultBonPrinters = _.map(self.deviceContext.data.defaultBonPrinters, 'name').toString();
          // self.multiselectTextBonPrinter = angular.copy(self.multiselectText);
          // if (self.showDefaultBon) {
          //   self.multiselectTextBonPrinter.buttonDefaultText = $translate.instant('jsp.general.default ') + ': ' + self.defaultBonPrinters;
          // }

          self.save = function () {
            if (self.deviceForm.$valid) {
              self.device.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $location.path('/hardware/device')
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          self.saveAndNew = function () {
            if (self.deviceForm.$valid) {
              self.device.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };


          // disable editing of udid when it is outlet fake device
          if (isEnabledOutletFakeDevice(self.device)) {
            self.metadataNew.fields["udid"].editable = false
          }

          function isEnabledOutletFakeDevice(device) {
            return _.find(deviceContext.data.outletsStatus, function (o) {
              return +o.id === +device.id && o.enabled;
            });
          }
        }
      }
    ])
    .controller('DeviceAddCtrl',

      function ($scope, $location, $http, $translate, $state, notifyManager, device, deviceContext, deviceMetadata, deviceMetadataNew, DeviceAdd) {
        var self = this;
        DeviceAdd(self, $scope, $location, $http, $translate, $state, notifyManager, device, deviceContext, deviceMetadata, deviceMetadataNew);
      }
    )
    .controller('DeviceEditCtrl',

      function ($scope, $location, $uibModal, $resource, $http, $translate, notifyManager, device, deviceContext, deviceMetadata, deviceMetadataNew, $state, DeviceAdd, ZuoraContractList, ZuoraContractAssign, DeleteZuoraContractAssign, $stateParams) {
        var self = this;
        DeviceAdd(self, $scope, $location, $http, $translate, $state, notifyManager, device, deviceContext, deviceMetadata, deviceMetadataNew);

        self.today = Date.now();

        self.openSelectionLicenceDialog = function () {
          ZuoraContractList.get({deviceId: device.id}, function (data) {
            if (data.ok) {

              self.pleasWateDialog = $uibModal.open({
                template: require('../template/page/hardware/zuora_contract_selection_dialog.html'),
                controller: 'ZuoraContractSelectDialog',
                controllerAs: 'zuoraContractSelectDialog',
                scope: $scope,
                windowClass: 'gf-modal',
                resolve: {
                  licenses: function () {
                    return data;
                  }
                }
              }).result.then(
                function (licenseId) {
                  if (licenseId) {
                    var zuoraContractAssignResource = $resource(ZuoraContractAssign, {
                      deviceId: device.id,
                      contractId: licenseId
                    });
                    zuoraContractAssignResource.save(function (data) {
                      if (data.ok) {
                        device.zuoraLicenseInfo = data.payload.data;
                        notifyManager.success($translate.instant('license.assigned'));
                      } else {
                        notifyManager.error($translate.instant(data.message));
                      }
                    });
                  } else {
                    var deleteZuoraContractAssignResource =
                      $resource(DeleteZuoraContractAssign, {deviceId: device.id});
                    deleteZuoraContractAssignResource.remove(function (data) {
                      if (data.ok) {
                        device.zuoraLicenseInfo = null;
                        notifyManager.success($translate.instant('license.unassigned'));
                      } else {
                        notifyManager.error($translate.instant(data.message));
                      }
                    });
                  }
                }
              );
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          });
        };

        self.save = function () {
          if (self.deviceForm.$valid) {
            self.device.$edit({deviceId: self.device.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $location.path('/hardware/device')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

        self.saveAndNew = function () {
          if (self.deviceForm.$valid) {
            self.device.$edit({deviceId: self.device.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $location.path('/hardware/device/add')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

        self.setAsMaster = function () {
          $http.post('/icash/hardware/device/master?id=' + $stateParams.id)
            .then(function (resp) {
              $scope.safeApply(function () {
                if (resp.data.error == 0) {
                  self.device.master = true;
                }
              });
            });
        };

        // automatically enable customer display when switched on license
        $scope.$watch('device.zuoraLicenseInfo.productName', function (newValue, oldValue) {
          if (newValue == 'CUSTOMER_DISPLAY') {
            self.device.customerDisplay = true;
            self.isCustomerDisplayLicense = true;
          } else {
            if (oldValue == 'CUSTOMER_DISPLAY') {
              self.device.customerDisplay = false;
            }
            self.isCustomerDisplayLicense = false;
          }
        });
      }
    )
    .controller('DeviceGridCtrl',

      function ($scope, $rootScope, $location, $translate, $timeout, $resource, notifyManager, deviceContext,
                deviceMetadata, deviceMetadataNew, DeviceRestaurants, HardwareDeviceSwitchRestaurantAction,
                securityService, $http) {
        // need for zuora license status
        $rootScope.AssignedDate = Date;

        var self = this;

        self.metadata = deviceMetadata;
        self.context = deviceContext;

        self.metadataNew = deviceMetadataNew;

        var cellClass = function (grid, row) {
          if (row && row.entity && row.entity.id && isEnabledOutletFakeDevice(row.entity)) {
            return 'outlet-entity';
          }
        };

        var masterAction = {
          name: 'master',
          template: 'app/template/grid/master_action.html',
          controller: 'DeviceMasterActionCtrl'
        };
        var zuoraAction = {
          name: 'zuora',
          template: 'app/template/grid/zuora_contract_select.html',
          controller: 'ZuoraContractSelectActionCtrl'
        };

        var registerActions = securityService.context.zuoraBilling ? [masterAction, zuoraAction] : [masterAction];
        var actions = securityService.context.zuoraBilling ? ['zuora', 'master', 'activate', 'edit', 'delete'] : ['master', 'activate', 'edit', 'delete'];

        var columns = [
          {name: 'id', width: 60, enableCellEdit: false, visible: false, cellClass: cellClass},
          {
            name: 'udid', visible: false, cellClass: cellClass,
            validations: {
              'gf-uniquedeviceudid': {
                'angular': {'gf-uniquedeviceudid': ''},
                'errorMessage': "de.icash.validator.UniqueUDID.message"
              }
            },
            cellEditableCondition: function ($scope) {
              return !isEnabledOutletFakeDevice($scope.row.entity);
            }
          },
          {
            name: 'deviceType',
            filterPathFieldName: 'deviceType.device',
            editDropdownValueLabel: 'device',
            width: 100,
            cellClass: cellClass
          },
          {name: 'name', width: 170, cellClass: cellClass},
          {name: 'waiter', width: 150, cellClass: cellClass},
          {name: 'costCenter', width: 125, cellClass: cellClass},
          {name: 'checkOutTable', width: 189, cellClass: cellClass},
          {
            name: 'ipAddress', width: 137, cellClass: cellClass,
            validations: {
              'gf-uniquedeviceipaddress': {
                'angular': {'gf-uniquedeviceipaddress': ''},
                'errorMessage': "de.icash.validator.UniqueIPAndPort.message"
              }
            }
          },
          {
            name: 'ipPort', visible: true, width: 60, cellClass: cellClass,
            validations: {
              'gf-uniquedeviceipaddress': {
                'angular': {'gf-uniquedeviceipaddress': ''},
                'errorMessage': "de.icash.validator.UniqueIPAndPort.message"
              }
            }
          },
          {
            name: 'appVersion', width: 101, cellClass: cellClass,
            cellEditableCondition: function () {
              return securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER')
            }
          },
          {
            name: 'appBuild', width: 101, cellClass: cellClass,
            visible: false,
            cellEditableCondition: function () {
              return securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER')
            }
          },
          {name: 'description', visible: false, width: 250, cellClass: cellClass}];

        if (self.metadataNew.fields['advertisementId'].visible) {
          columns.push({name: 'advertisementId', visible: false, width: 200, cellClass: cellClass});
        }
        if (self.metadataNew.fields['hardwareString'].visible) {
          columns.push({
            name: 'hardwareString',
            visible: false,
            width: 200,
            enableCellEdit: false,
            cellClass: cellClass
          });
        }

        //columns.push({name: 'ipPort', visible: false, width: 200, cellClass: cellClass});

        if (self.metadataNew.fields['disableCashPayment'].visible) {
          columns.push({name: 'disableCashPayment', visible: false, width: 220, cellClass: cellClass});
        }


        // if (self.metadataNew.fieldGroups['orientedPrinter'].visible) {
        columns.push(
          {
            name: 'bonPrinters',
            enableCellEdit: self.metadataNew.fields['bonPrinters'].editable,
            visible: false,
            width: 200,
            enableFiltering: false,
            multiselect: true,
            cellClass: cellClass
          },
          {
            name: 'restrictedBonPrinters',
            enableCellEdit: self.metadataNew.fields['restrictedBonPrinters'].editable,
            visible: false,
            width: 200,
            enableFiltering: false,
            multiselect: true,
            cellClass: cellClass
          },
          {
            name: 'billPrinter',
            visible: false,
            width: 264, enableCellEdit: self.metadataNew.fields['billPrinter'].editable, cellClass: cellClass
          },
          {
            name: 'journalPrinter',
            visible: false,
            width: 264, enableCellEdit: self.metadataNew.fields['journalPrinter'].editable, cellClass: cellClass,
            cellTemplate: "<div uib-tooltip=\"{{(row.entity.id!=null||row.entity.filled) ?  ('printer.master' | translate) : ''}}\" tooltip-append-to-body=\"false\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD CUSTOM_FILTERS}}</div>"
          },
          {
            name: 'ticketLayout',
            enableCellEdit: self.metadataNew.fields['ticketLayout'].editable,
            visible: false,
            width: 200,
            cellClass: cellClass
          },
          {
            name: 'resetCourseGroup',
            enableCellEdit: self.metadataNew.fields['resetCourseGroup'].editable,
            visible: false,
            width: 200,
            cellClass: cellClass
          }
        );
        // }

        columns.push({name: 'screensaverUrl', visible: false, width: 200, cellClass: cellClass},
          {name: 'screensaverTime', visible: false, width: 200, cellClass: cellClass},
          {name: 'customerDisplay', visible: false, width: 200, cellClass: cellClass},
          {name: 'customerDisplaySource', visible: false, width: 200, cellClass: cellClass},
          {name: 'compactMode', visible: false, width: 200, cellClass: cellClass},
          {
            name: 'updatedMasterData',
            cellFilter: "date:'medium'|updatedMasterDataDeviceFilter",
            visible: false,
            width: 200,
            enableCellEdit: false, cellClass: cellClass
          },

          {
            name: 'operate', actions: actions,
            actionOptions: {
              'delete': {
                // todo use configOptionsService for this function
                isHidden: isEnabledOutletFakeDevice
              },
              'activate': {
                isHidden: function (device) {
                  return _.find(deviceContext.data.outletsStatus, function (o) {
                    return +o.id === +device.id && o.enabled && !o.master;
                  })
                }
              },
              'master': {
                isHidden: function (device) {
                  return _.find(deviceContext.data.outletsStatus, function (o) {
                    return +o.id === +device.id && o.enabled && !o.master;
                  })
                }
              }
            }
          }
        );

        function isEnabledOutletFakeDevice(device) {
          return _.find(deviceContext.data.outletsStatus, function (o) {
            return +o.id === +device.id && o.enabled;
          });
        }

        self.config = {
          registerActions: registerActions,
          urlRoute: '/hardware/device',
          enableSorting: true,
          enableAdd: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          showAdd: function () {
            return securityService.hasAnyRole('ROLE_SUPERVISOR');
          },
          columns: columns
        };

        $timeout(function () {
          self.showDeviceGrid = true;
        }, 0);

        // licenses section
        self.restaurants = [];
        self.zoraRestaurantInit = false;
        self.getRestaurants = function () {
          if (self.zoraRestaurantInit) {
            self.restaurants.forEach(function (restaurant) {
              restaurant.config.reloadData();
            });
            return;
          }

          var deviceRestaurantsResource = $resource(DeviceRestaurants);
          deviceRestaurantsResource.get(function (data) {
            if (data.ok) {

              var zuoraInfo = {
                name: 'zuoraInfo',
                template: 'app/template/grid/zuora_contract_info.html'
              };

              self.configDeviceInfo = {
                registerActions: [zuoraInfo],
                enableSorting: true,
                enableCellEdit: false,
                hideHeader: true,
                disableSaveState: true,
                columns: [
                  {name: 'deviceName', width: '25%', displayName: 'device.name'},
                  {name: 'deviceType', width: '25%', displayName: 'device.deviceType'},
                  {
                    name: 'zuoraLicenseInfo',
                    width: '25%',
                    cellFilter: 'zuoraContractTypeFilter',
                    displayName: 'assigned.license'
                  },
                  {
                    name: 'operate',
                    width: '25%',
                    actions: ['zuoraInfo'],
                    displayName: 'license.information'
                  }
                ]
              };

              data.payload.restaurants.forEach(function (restaurant) {
                restaurant.metadata = {};
                restaurant.context = {refreshEndpoint: '/hardware/device/' + restaurant.id, data:{isLive:restaurant.live}};
                restaurant.config = angular.copy(self.configDeviceInfo);
              });


              self.restaurants = data.payload.restaurants;
              self.zoraRestaurantInit = true;
              $timeout(function () {
                self.showDeviceRestaurantInfoGrid = true;
              }, 10);

            } else {
              notifyManager.error($translate.instant(data.message));
            }

          })
        };

        self.switchToRestaurant = function (restaurant) {
          var switchRestaurantResource = $resource(HardwareDeviceSwitchRestaurantAction, {restaurantId: restaurant.id});
          switchRestaurantResource.get(function (data) {
            if (data.ok) {
              // todo o.logunov update after fix ICR-5528
              var path = $location.absUrl().split('#')[0];
              window.open(path, '_self', "", true);
            }
          })
        };

        function refreshContext() {
          $http.get('/icash/hardware/device/context.json').then(function (res) {
            self.context = res.data;
          })
        }

        $scope.$on('changeActivation', function (event, opt) {
          if (!opt.active) {
            refreshContext();
          }
        });
        $scope.$on('deleteEntity', refreshContext);
      }
    )
    .filter('updatedMasterDataDeviceFilter', ['$filter', '$translate', function ($filter, $translate) {
      return function (input) {
        return input ? input : $translate.instant('forms.temporal.never');
      }
    }])
    .filter('zuoraContractTypeFilter', ['$translate', function ($translate) {
      return function (input) {
        return !input ? $translate.instant('no.license') : $translate.instant('zuoraProduct.' + input.productName) + ' (' + $translate.instant('zuoraPersistedRatePlan.' + input.ratePlanName) + ')';
      }
    }])
    .controller('DeviceMasterActionCtrl',
      function ($scope, $resource, DeviceMasterSwitch, uiGridConstants, entity, gridApi) {
        if (!entity.active || entity.master) {
          return;
        }
        var switchMaster = $resource(DeviceMasterSwitch, {id: entity.id});
        switchMaster.save(function (data) {
          if (data.ok) {
            // reset old
            var previousMasterRow = _.find(gridApi.grid.rows, function (row) {
              return row.entity.master;
            });
            if (previousMasterRow) {
              previousMasterRow.entity.master = false;
            }

            // set up new
            entity.master = true;

            gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
          }
        });
      }
    )
    .controller('ZuoraContractSelectActionCtrl',
      function ($scope, $resource, $uibModal, $translate, notifyManager, ZuoraContractList, ZuoraContractAssign, DeleteZuoraContractAssign, entity) {
        // ask before deactivate
        ZuoraContractList.get({deviceId: entity.id}, function (data) {
          if (data.ok) {

            $scope.pleasWateDialog = $uibModal.open({
              template: require('../template/page/hardware/zuora_contract_selection_dialog.html'),
              controller: 'ZuoraContractSelectDialog',
              controllerAs: 'zuoraContractSelectDialog',
              scope: $scope,
              windowClass: 'gf-modal',
              resolve: {
                licenses: function () {
                  return data;
                }
              }
            }).result.then(
              function (licenseId) {
                if (licenseId) {
                  var zuoraContractAssignResource = $resource(ZuoraContractAssign, {
                    deviceId: entity.id,
                    contractId: licenseId
                  });
                  zuoraContractAssignResource.save(function (data) {
                    if (data.ok) {
                      entity.zuoraLicenseInfo = data.payload.data;
                      notifyManager.success($translate.instant('license.assigned'));
                    } else {
                      notifyManager.error($translate.instant(data.message));
                    }
                  });
                } else {
                  var deleteZuoraContractAssignResource =
                    $resource(DeleteZuoraContractAssign, {deviceId: entity.id});
                  deleteZuoraContractAssignResource.remove(function (data) {
                    if (data.ok) {
                      entity.zuoraLicenseInfo = null;
                      notifyManager.success($translate.instant('license.unassigned'));
                    } else {
                      notifyManager.error($translate.instant(data.message));
                    }
                  });
                }
              }
            );
          } else {
            notifyManager.error($translate.instant(data.message));
          }
        });
      }
    )
    .controller('ZuoraContractSelectDialog',

      function ($scope, $translate, $filter, $uibModalInstance, $timeout, licenses) {
        var self = this;

        self.licenses = licenses.payload.licenses;

        self.preloadLicense = licenses.payload.license;

        self.isSaveDisable = false;
        $timeout(function () {

          if (self.preloadLicense) {
            self.emptySelected = false;
            self.licenses.forEach(function (item) {
              item.selected = item.id == self.preloadLicense.id;
              // disable save if there is selected and no license was not selected
              self.isSaveDisable = item.selected || self.isSaveDisable;
            });
          } else {
            // disable save if no license is selected
            self.isSaveDisable = true;
            self.emptySelected = true;
          }
        }, 10);

        self.yes = function () {
          $uibModalInstance.close(self.license);
        };

        self.no = function () {
          $uibModalInstance.dismiss();
        };

        self.getContractInfo = function (item) {
          var productInfo = $translate.instant('zuoraProduct.' + item.productName) + ' (' + $translate.instant('zuoraPersistedRatePlan.' + item.ratePlanName) + ')';
          if (item.id > 0 && (item.standard || item.seasonType) && (item.productName != 'CUSTOMER_DISPLAY')) {
            return productInfo + ' [' + item.leftLicenses + ']';
          } else if (item.id < 0) {
            return productInfo + ' ' + $translate.instant('valid') + ' ' + $translate.instant('from') + ' ' + $filter('date')(item.startDate, 'short') + ' ' + $translate.instant('until') + ' ' + $filter('date')(item.endDate, 'short');
          }
          return productInfo;
        };

        self.isOldAppVersion = function () {
          // we use id of selected licenses
          if (self.license) {
            var selectedLicense = _.find(self.licenses, function (item) {
              return self.license == item.id;
            });
            return selectedLicense.oldAppVersion;
          }
          if (self.preloadLicense) {
            return self.preloadLicense.oldAppVersion;
          }
          return false;
        };
      })
    .controller('DeviceWizardCtrl',

      function (wizardCode) {
        var self = this;

        self.code = wizardCode.payload.code;
        self.wizardCode = wizardCode;

        self.refresh = function () {
          self.wizardCode.$save(function (data) {
            self.code = data.payload.code;
          })
        }
      }
    )

    .directive('gfOutlets', function ($http, $stateParams, $translate, notifyManager, OutletsStatus) {

      return {
        template: require('../template/page/hardware/outlets.html'),
        link: function ($scope) {

          $scope.outlets = {};
          $scope.outlets.enabled = false;

          OutletsStatus.load(function (response) {
            $scope.outlets.currentDevice = _.filter(response, function (item) {
              return +item.id === +$stateParams.id;
            });
            $scope.outlets.enabled = $scope.outlets.currentDevice[0].enabled;

            if ($scope.outlets.enabled) {
              $scope.getDeviceOutletsStatus();
            }
          });

          $scope.getDeviceOutletsStatus = function () {
            $http.get('/icash/hardware/device/' + $stateParams.id + '/outlets').then(function (response) {
              $scope.outlets.restaurants = response.data;
            })
          };

          $scope.enableOutlets = function () {
            if ($scope.outlets.enabled) {
              $http.put('/icash/hardware/device/' + $stateParams.id + '/outlets/enable').then(function (response) {
                $scope.outlets.restaurants = response.data;
                notifyManager.success($translate.instant('outlets.outletsEnabled'));
              })
            } else {
              $http.put('/icash/hardware/device/' + $stateParams.id + '/outlets/disable').then(function (response) {
                notifyManager.success($translate.instant('outlets.outletsDisabled'));
              })
            }
          };

          $scope.addOutlet = function (restaurantId, isEnabled) {
            if (isEnabled) {
              $http.put('/icash/hardware/device/' + $stateParams.id + '/outlets/' + restaurantId + '/add').then(function (response) {
                notifyManager.success($translate.instant('outlets.outletAdded'));
              })
            } else {
              $http.put('/icash/hardware/device/' + $stateParams.id + '/outlets/' + restaurantId + '/remove').then(function (response) {
                notifyManager.success($translate.instant('outlets.outletRemoved'));
              })
            }
          };
        }
      }
    })
})();
