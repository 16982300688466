(function () {
  'use strict';
  angular.module('gf.masterdata.course', ['ui.router'])

    .factory('CourseGroupMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/course_group/metadata.json');
    })
    .factory('CourseGroupContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/course_group/context.json');
    })
    .factory('CourseOrderScreenMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/course_order/metadata.json');
    })
    .factory('CourseOrderScreenContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/course_order/context.json');
    })
    .factory('CourseOrderMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/course_order/metadata.json');
    })
    .factory('CourseOrderContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/course_order/context.json');
    })
    .factory('CourseGroup', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/course_group/:courseGroupId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('CourseGroupPositions', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/course_group/positions', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('CourseOrderGroupPositions', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/course_order/positions', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('CourseOrderScreen', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/course_order/course_order_screen/:courseOrderScreenId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.masterdataCourse_group', {
          url: '/masterdata/course_group',
          template: require('../template/page/masterdata/course_groups.html'),
          controller: 'CourseGroupGridCtrl',
          controllerAs: 'courseGroupGridCtrl',
          resolve: {
            courseGroupMetadata: function (CourseGroupMetadata) {
              return CourseGroupMetadata.get().$promise;
            },
            courseGroupContext: function (CourseGroupContext) {
              return CourseGroupContext.get().$promise;
            },
            courseGroup: function (CourseGroup, $stateParams) {
              return CourseGroup.load({courseGroupId: $stateParams.courseGroupId || "add"}).$promise;
            },
            courseGroupPositions: function (CourseGroupPositions) {
              return CourseGroupPositions.load().$promise;
            },
            default_icons: function (icons) {
              return icons["default"].get().$promise;
            },
            currentTour: function (Tour) {
              return Tour.get({ page: 'masterdataCourse_group'}).$promise;
            }
          },
          title: "courseGroup"
        })

        .state('page.masterdataCourse_groupAdd', {
          url: '/masterdata/course_group/add',
          template: require('../template/page/masterdata/course_group.html'),
          controller: 'CourseGroupAddCtrl',
          controllerAs: 'courseGroupCtrl',
          resolve: {
            courseGroupMetadata: function (CourseGroupMetadata) {
              return CourseGroupMetadata.get().$promise;
            },
            courseGroupContext: function (CourseGroupContext) {
              return CourseGroupContext.get().$promise;
            },
            courseGroup: function (CourseGroup, $stateParams) {
              return CourseGroup.load({courseGroupId: $stateParams.courseGroupId || "add"}).$promise;
            },
            courseGroupPositions: function (CourseGroupPositions) {
              return CourseGroupPositions.load().$promise;
            }
          },
          title: "courseGroup"
        })

        .state('page.masterdataCourse_groupDetails', {
          url: '/masterdata/course_group/:id',
          template: require('../template/page/masterdata/course_group.html'),
          controller: 'CourseGroupEditCtrl',
          controllerAs: 'courseGroupCtrl',
          resolve: {
            courseGroupMetadata: function (CourseGroupMetadata) {
              return CourseGroupMetadata.get().$promise;
            },
            courseGroupContext: function (CourseGroupContext) {
              return CourseGroupContext.get().$promise;
            },
            courseGroup: function (CourseGroup, $stateParams) {
              return CourseGroup.load({courseGroupId: $stateParams.id || "add"}).$promise;
            },
            courseGroupPositions: function (CourseGroupPositions) {
              return CourseGroupPositions.load().$promise;
            }
          },
          title: "courseGroup"
        })

        .state('page.masterdataCourse_order', {
          url: '/masterdata/course_order',
          template: require('../template/page/masterdata/course_groups.html'),
          controller: 'CourseOrderScreenGridCtrl',
          controllerAs: 'courseGroupGridCtrl',
          resolve: {
            courseGroupContext: function (CourseOrderContext) {
              return CourseOrderContext.get().$promise;
            },
            courseGroupMetadata: function (CourseOrderMetadata) {
              return CourseOrderMetadata.get().$promise;
            },
            courseGroupPositions: function (CourseOrderGroupPositions) {
              return CourseOrderGroupPositions.load().$promise;
            },
            default_icons: function (icons) {
              return icons["default"].get().$promise;
            }
          },
          title: "courseOrder"
        })

        .state('page.masterdataCourse_orderAdd', {
          url: '/masterdata/course_order/add',
          template: require('../template/page/masterdata/course_order.html'),
          controller: 'CourseOrderScreenAddCtrl',
          controllerAs: 'courseOrderCtrl',
          resolve: {
            courseOrderScreenContext: function (CourseOrderScreenContext) {
              return CourseOrderScreenContext.get().$promise;
            },
            courseOrderScreenMetadata: function (CourseOrderScreenMetadata) {
              return CourseOrderScreenMetadata.get().$promise;
            },
            courseOrderScreen: function (CourseOrderScreen) {
              return CourseOrderScreen.load({courseOrderScreenId: "add"}).$promise;
            }
          },
          title: "courseOrder"
        })

        .state('page.masterdataCourse_orderDetails', {
          url: '/masterdata/course_order/:id',
          template: require('../template/page/masterdata/course_order.html'),
          controller: 'CourseOrderScreenEditCtrl',
          controllerAs: 'courseOrderCtrl',
          resolve: {
            courseOrderScreenContext: function (CourseOrderScreenContext) {
              return CourseOrderScreenContext.get().$promise;
            },
            courseOrderScreenMetadata: function (CourseOrderScreenMetadata) {
              return CourseOrderScreenMetadata.get().$promise;
            },
            courseOrderScreen: function (CourseOrderScreen, $stateParams) {
              return CourseOrderScreen.load({courseOrderScreenId: $stateParams.id}).$promise;
            }
          },
          title: "courseOrder"
        })
    })

    .factory('CourseGroupAdd',
      function () {
        return function (self, $scope, $location, $http, $translate, $state, notifyManager, courseGroup, courseGroupContext, courseGroupMetadata) {
          self.courseGroupContext = courseGroupContext;
          self.courseGroupMetadata = courseGroupMetadata;
          self.courseGroup = courseGroup;

          self.save = function () {
            if (self.courseGroupForm.$valid) {
              self.courseGroup.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go('page.masterdataCourse_group')
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };
          self.saveAndNew = function () {
            if (self.courseGroupForm.$valid) {
              self.courseGroup.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };
        }
      }
    )

    .controller('CourseGroupAddCtrl',

      function ($scope, $location, $http, $translate, $state, notifyManager, courseGroup, courseGroupContext,
                courseGroupMetadata, CourseGroupAdd) {

        var self = this;
        CourseGroupAdd(self, $scope, $location, $http, $translate, $state, notifyManager, courseGroup, courseGroupContext,
          courseGroupMetadata);
      }
    )
    .controller('CourseGroupEditCtrl',

      function ($scope, $location, $http, $translate, $state, notifyManager, courseGroup, courseGroupContext,
                courseGroupMetadata, CourseGroupAdd) {

        var self = this;
        CourseGroupAdd(self, $scope, $location, $http, $translate, $state, notifyManager, courseGroup, courseGroupContext,
          courseGroupMetadata);

        self.save = function () {
          if (self.courseGroupForm.$valid) {
            self.courseGroup.$edit({courseGroupId: self.courseGroup.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.masterdataCourse_group')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
        self.saveAndNew = function () {
          if (self.courseGroupForm.$valid) {
            self.courseGroup.$edit({courseGroupId: self.courseGroup.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.masterdataCourse_groupAdd')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

      }
    )
    .controller('CourseGroupGridCtrl',

      function ($scope, courseGroupContext, courseGroupMetadata, $translate, courseGroupPositions, CourseGroupPositions,
                default_icons, currentTour, tourService, configOptionsService) {

        var self = this;
        self.currentTour = currentTour;
        tourService.startDetachedTour('masterdataCourse_group', currentTour);
        self.courseGroupContext = courseGroupContext;
        self.metadata = courseGroupMetadata;
        self.context = courseGroupContext;

        var actions = [
          {
            name: 'copy',
            template: 'app/template/grid/copy_article_action.html',
            controller: 'CopyCourseGroupCtrl'
          }
        ];

        var columns = [
          {name: 'id', width: 60, enableCellEdit: false, visible: false},
          {name: 'name', resizable: true, filterPlaceholder: 'grid.filter.placeholder.realtime', headerCellTemplate: 'app/template/tour/header_cell_tour.html'}
        ];

        default_icons.forEach(function (icon) {
          icon.name = $translate.instant("icon." + icon.name);
        });
        default_icons = _.sortBy(default_icons, function (icon) {
          return icon.name;
        });
        columns.push({
          name: 'image',
          visible: false,
          width: 120,
          editDropdownIdLabel: 'path',
          editDropdownValueLabel: 'name',
          cellFilter: "mapImageArticleFilter:grid.appScope.context.data.dependencies.icons",
          editableCellTemplate: "<div><form name=\"inputForm\"><select class=\"form-control\" ng-class=\"'colt' + col.uid\" ui-grid-edit-dropdown ng-model=\"MODEL_COL_FIELD\" ng-options=\"field[editDropdownIdLabel] as field[editDropdownValueLabel]  CUSTOM_FILTERS for field in editDropdownOptionsArray\"><option value=\"\" translate=\"option.select\"></option></select></form></div>",
          editDropdownOptionsArray: default_icons,
          enableFiltering: false,
          enableSorting: false

        });

        // create hash map for mapImageArticleFilter
        self.context.data.dependencies.icons = {};
        default_icons.forEach(function (icon) {
          self.context.data.dependencies.icons[icon.path] = icon.name;
        });

        columns.push({
          name: 'operate',
          actions: ['edit', 'copy', 'delete'],
          headerCellTemplate: 'app/template/tour/header_cell_tour.html',
          actionOptions: {
            'delete': {
              isDisabled: configOptionsService.get('courseGroupDeleteButton').isDisabled
            }
          }
        });

        self.config = {
          urlRoute: '/masterdata/course_group',
          registerActions: actions,
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: columns
        };

        self.courseGroupPositions = courseGroupPositions;
        self.courseGroups = self.courseGroupPositions.positions;

        self.savePositions = function () {
          self.courseGroupPositions.$save(function (data) {
            if (data.ok) {

              self.courseGroupPositions = new CourseGroupPositions(data.payload.positions);
              self.courseGroups = self.courseGroupPositions.positions;
            }
          });
        };

        self.refreshPositions = function () {
          CourseGroupPositions.load(function (data) {
            self.courseGroupPositions = data;
            self.courseGroups = self.courseGroupPositions.positions;

          });
        };

      }
    )
    .controller('CopyCourseGroupCtrl',
      function ($scope, $translate, ArticleChangeId, PluService, notifyManager, CourseGroup, entity) {
        var newEntity = angular.copy(entity);
        newEntity.id = 0;
        var courseGroupResource = new CourseGroup(newEntity);

        $scope.entity = entity;
        courseGroupResource.$save(function (data) {
          var index = _.findIndex($scope.gridApi.grid.options.data, function (item) {
            return item.id == $scope.entity.id;
          });
          // insert new article to the next position
          $scope.gridApi.grid.options.data.splice(index + 1, 0, data.payload.entity);

          notifyManager.success($translate.instant('entity.save.successfully'));
        });
      }
    )
    .controller('CourseOrderScreenAddCtrl',
      function ($scope, $uibModal, $translate, $location, notifyManager, courseOrderScreenContext, courseOrderScreenMetadata, courseOrderScreen, $state) {

        var self = this;
        self.courseOrderScreenContext = courseOrderScreenContext;
        self.courseOrderScreenMetadata = courseOrderScreenMetadata;
        self.courseOrderScreen = courseOrderScreen;

        angular.extend(self, courseOrderScreenContext.data.dependencies);

        self.orderScreens = courseOrderScreen.orderScreens;
        self.courseGroup = courseOrderScreen.courseGroup;

        self.tabs = _.range(self.orderScreens.length).map(function () {
          return false;
        });

        self.toggleTabs = function () {
          for (var i = 0; i < self.tabs.length; i++) {
            self.tabs[i] = false;
          }
        };

        self.save = function () {
          if (self.courseGroupForm.$valid) {
            self.courseOrderScreen.$save(function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $state.go('page.masterdataCourse_order')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

        self.back = function () {
          $state.go('page.masterdataCourse_order');
        }
      }
    )

    .controller('CourseOrderScreenEditCtrl',
      function ($scope, $uibModal, $translate, $location, notifyManager, courseOrderScreenContext, courseOrderScreenMetadata, courseOrderScreen, $state) {

        var self = this;
        self.courseOrderScreenContext = courseOrderScreenContext;
        self.courseOrderScreenMetadata = courseOrderScreenMetadata;
        self.courseOrderScreen = courseOrderScreen;

        angular.extend(self, courseOrderScreenContext.data.dependencies);

        self.orderScreens = courseOrderScreen.orderScreens;
        self.courseGroup = courseOrderScreen.courseGroup;

        self.tabs = _.range(self.orderScreens.length).map(function () {
          return false;
        });

        self.toggleTabs = function () {

          for (var i = 0; i < self.tabs.length; i++) {
            self.tabs[i] = false;
          }
        };


        self.save = function () {
          if (self.courseGroupForm.$valid) {
            self.courseOrderScreen.$edit({courseOrderScreenId: self.courseGroup.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.masterdataCourse_order')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

        self.back = function () {
          $state.go('page.masterdataCourse_order');
        }
      }
    )

    .controller('CourseOrderScreenGridCtrl',
      function ($scope, courseGroupContext, courseGroupMetadata, $translate, courseGroupPositions, CourseGroupPositions,
                default_icons) {

        var self = this;
        self.metadata = courseGroupMetadata;
        self.context = courseGroupContext;
        self.courseGroupContext = courseGroupContext;

        var columns = [
          {name: 'id', width: 60, enableCellEdit: false, visible: false},
          {name: 'name', resizable: true, filterPlaceholder: 'grid.filter.placeholder.realtime'}
        ];

        default_icons.forEach(function (icon) {
          icon.name = $translate.instant("icon." + icon.name);
        });
        default_icons = _.sortBy(default_icons, function (icon) {
          return icon.name;
        });
        columns.push({
          name: 'image',
          visible: false,
          width: 120,
          editDropdownIdLabel: 'path',
          editDropdownValueLabel: 'name',
          cellFilter: "mapImageArticleFilter:grid.appScope.context.data.dependencies.icons",
          editableCellTemplate: "<div><form name=\"inputForm\"><select class=\"form-control\" ng-class=\"'colt' + col.uid\" ui-grid-edit-dropdown ng-model=\"MODEL_COL_FIELD\" ng-options=\"field[editDropdownIdLabel] as field[editDropdownValueLabel]  CUSTOM_FILTERS for field in editDropdownOptionsArray\"><option value=\"\" translate=\"option.select\"></option></select></form></div>",
          editDropdownOptionsArray: default_icons,
          enableFiltering: false,
          enableSorting: false
        });

        // create hash map for mapImageArticleFilter
        self.context.data.dependencies.icons = {};
        default_icons.forEach(function (icon) {
          self.context.data.dependencies.icons[icon.path] = icon.name;
        });

        columns.push({name: 'operate', actions: ['edit', 'delete']});

        self.config = {
          urlRoute: '/masterdata/course_order',
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: columns
        };


        self.courseGroupPositions = courseGroupPositions;
        self.courseGroups = self.courseGroupPositions.positions;

        self.savePositions = function () {
          self.courseGroupPositions.$save(function (data) {
            if (data.ok) {

              self.courseGroupPositions = new CourseGroupPositions(data.payload.positions);
              self.courseGroups = self.courseGroupPositions.positions;
            }
          });
        };

        self.refreshPositions = function () {
          CourseGroupPositions.load(function (data) {
            self.courseGroupPositions = data;
            self.courseGroups = self.courseGroupPositions.positions;

          });
        };

      }
    )
    .filter('mapImageArticleFilter', function () {
      return function (input, icons) {
        return icons[input];
      }
    })

})();
