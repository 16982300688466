(function() {
  'use strict';
  angular.module('gf.financial.costCenter', ['ui.router'])

    .factory('CostCenter', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/cost_center/:costCenterId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('CostCenterMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/cost_center/metadata.json');
    })
    .factory('CostCenterContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/cost_center/context.json');
    })

    .config(function($stateProvider) {

      $stateProvider
        .state('page.financialCost_center', {
          url: '/financial/cost_center',
          template: require('../template/page/financial/cost_centers.html'),
          controller: 'CostCenterGridCtrl',
          controllerAs: 'costCenterGridCtrl',
          resolve: {
            costCenterMetadata: function(CostCenterMetadata) {
              return CostCenterMetadata.get().$promise;
            },
            costCenterContext: function(CostCenterContext) {
              return CostCenterContext.get().$promise;
            }
          },
          title: "costCenter"
        })

        .state('page.financialCost_centerAdd', {
          url: '/financial/cost_center/add',
          template: require('../template/page/financial/cost_center.html'),
          controller: 'CostCenterAddCtrl',
          controllerAs: 'costCenterCtrl',
          resolve: {
            costCenterMetadata: function(CostCenterMetadata) {
              return CostCenterMetadata.get().$promise;
            },
            costCenterContext: function(CostCenterContext) {
              return CostCenterContext.get().$promise;
            },
            costCenter : function(CostCenter, $stateParams) {
              return CostCenter.load({costCenterId: $stateParams.id || "add"}).$promise;
            }
          },
          title: 'costCenter'
        })

        .state('page.financialCost_centerDetails', {
          url: '/financial/cost_center/:id',
          template: require('../template/page/financial/cost_center.html'),
          controller: 'CostCenterEditCtrl',
          controllerAs: 'costCenterCtrl',
          resolve: {
            costCenterMetadata: function(CostCenterMetadata) {
              return CostCenterMetadata.get().$promise;
            },
            costCenterContext: function(CostCenterContext) {
              return CostCenterContext.get().$promise;
            },
            costCenter : function(CostCenter, $stateParams) {
              return CostCenter.load({costCenterId: $stateParams.id || "add"}).$promise;
            }
          },
          title: 'costCenter'
        })
    })

    .factory('CostCenterAdd', [
      function () {
        return function (self, $scope, $location, $http, $translate, $state, notifyManager, costCenter, costCenterContext, costCenterMetadata) {
          self.costCenterContext = costCenterContext;
          self.costCenterMetadata = costCenterMetadata;
          self.costCenter = costCenter;

          self.save = function () {
            if (self.costCenterForm.$valid) {
              self.costCenter.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go('page.financialCost_center')
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };
          self.saveAndNew = function () {
            if (self.costCenterForm.$valid) {
              self.costCenter.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };
        }
      }
    ])
    .controller('CostCenterAddCtrl',

      function ($scope, $location, $http, $translate, $state, notifyManager, costCenter, costCenterContext, costCenterMetadata, CostCenterAdd) {
        var self = this;
        CostCenterAdd(self, $scope, $location, $http, $translate, $state, notifyManager, costCenter, costCenterContext, costCenterMetadata);
      }
    )
    .controller('CostCenterEditCtrl',

      function ($scope, $location, $http, $translate, $state, notifyManager, costCenter, costCenterContext, costCenterMetadata, CostCenterAdd) {
        var self = this;
        CostCenterAdd(self, $scope, $location, $http, $translate, $state, notifyManager, costCenter, costCenterContext, costCenterMetadata);

        self.save = function () {
          if (self.costCenterForm.$valid) {
            self.costCenter.$edit({costCenterId: self.costCenter.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.financialCost_center')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
        self.saveAndNew = function () {
          if (self.costCenterForm.$valid) {
            self.costCenter.$edit({costCenterId: self.costCenter.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.financialCost_centerAdd')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
      }
    )
    .controller('CostCenterGridCtrl',

      function ($scope, costCenterContext, costCenterMetadata, configOptionsService) {
        var self = this;
        self.metadata = costCenterMetadata;
        self.context = costCenterContext;

        self.config = {
          urlRoute: '/financial/cost_center',
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'id', width: 60, enableCellEdit: false, visible: false},
            {name: 'name'},
            {name: 'description', width: 200, visible: false},
            {
              name: 'operate',
              actions: ['edit', 'delete'],
              actionOptions: {
                'delete': {
                  isDisabled: configOptionsService.get('costCenterDeleteButton').isDisabled
                }
              }
            }
          ]
        };
      }
    )
})();
