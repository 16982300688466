(function () {
  'use strict';
  angular.module('gf.bm.rules.grid', [
    'ui.grid', 'ui.grid.edit', 'ui.grid.pagination', 'ui.grid.rowEdit', 'ui.grid.cellNav', 'ui.grid.rowEdit',
    'ui.grid.selection', 'gf.grid.validation'
  ])

    .run(function ($templateCache) {
      $templateCache.put('app/template/bm/group-view/grid/cell/bm_cell_default.html', require('../../template/bm/group-view/grid/cell/bm_cell_default.html'));
      $templateCache.put('app/template/bm/group-view/grid/cell/bm_cell_string.html', require('../../template/bm/group-view/grid/cell/bm_cell_string.html'));
      $templateCache.put('app/template/bm/group-view/grid/cell/bm_cell_select.html', require('../../template/bm/group-view/grid/cell/bm_cell_select.html'));
      $templateCache.put('app/template/bm/group-view/grid/cell/bm_cell_multiselect.html', require('../../template/bm/group-view/grid/cell/bm_cell_multiselect.html'));
      $templateCache.put('app/template/bm/group-view/grid/cell/bm_cell_currency.html', require('../../template/bm/group-view/grid/cell/bm_cell_currency.html'));
      $templateCache.put('app/template/bm/group-view/grid/cell/bm_cell_number.html', require('../../template/bm/group-view/grid/cell/bm_cell_number.html'));
      $templateCache.put('app/template/bm/group-view/grid/cell/bm_cell_boolean.html', require('../../template/bm/group-view/grid/cell/bm_cell_boolean.html'));
      $templateCache.put('app/template/bm/group-view/grid/cell/bm_cell_boolean_edit.html', require('../../template/bm/group-view/grid/cell/bm_cell_boolean_edit.html'));
      $templateCache.put('app/template/bm/group-view/grid/cell/bm_cell_edit.html', require('../../template/bm/group-view/grid/cell/bm_cell_edit.html'));
      $templateCache.put('app/template/bm/group-view/grid/cell/bm_cell_actions.html', require('../../template/bm/group-view/grid/cell/bm_cell_actions.html'));
      $templateCache.put('app/template/bm/group-view/grid/bm_rule_timepicker.html', require('../../template/bm/group-view/grid/bm_rule_timepicker.html'));
      $templateCache.put('app/template/bm/group-view/grid/rule_popover.html', require('../../template/bm/group-view/grid/rule_popover.html'));
    })

    .component('bmRulesGrid', {
      template: require('../../template/bm/group-view/grid/bm_grid.html'),
      bindings: {
        gridConfig: '<',
        permissionsConfig: '<',
        metadata: '<',
        context: '<',
        rules: '<'
      },
      controller: function ($scope, $http, $stateParams, securityService, gridDefaultConfig, RuleManager, moment,
                            metadataService, $timeout, notifyManager, $translate, $uibModal, permissionsService,
                            multiselectService, $q) {
        var self = this;

        this.$onInit = function () {

          applyDefaultOptions(self.gridConfig);

          self.ruleManager = new RuleManager(self.rules);

          self.gridConfig.ruleManager = self.ruleManager;

          self.security = securityService;

          permissionsService.applyPermissions(self.gridConfig, self.permissionsConfig);

          metadataService.applyMetadata(self.gridConfig.columnDefs, self.metadata, self.context.data.dependencies);

          var _onRegisterApi = self.gridConfig.onRegisterApi;
          self.gridConfig.onRegisterApi = function (gridApi) {
            if (_.isFunction(_onRegisterApi)) {
              _onRegisterApi(gridApi)
            }
            self.gridApi = gridApi;
            gridApi.core.on.sortChanged($scope, onSortChanged);
            gridApi.core.on.filterChanged($scope, onFilterChanged);
            gridApi.edit.on.afterCellEdit($scope, onAfterCellEdit);
            gridApi.edit.on.cancelCellEdit($scope, onCancelCellEdit);
            gridApi.rowEdit.on.saveRow($scope, onSaveRow);
          };

          function updateGridContent(data, total, rules) {
            self.gridConfig.data = data;
            self.gridConfig.paginationOptions.pagination.totalItems = total;
            self.ruleManager.setEntityRules(beforeUpdate(rules));
          }

          function clearGridContent() {
            updateGridContent([], 0, []);
          }

          self.gridConfig.getPage = function () {
            var updatingPagePromise, updatingContextPromise;
            var canceler = $q.defer();
            updatingPagePromise = $http
              .post(
                // todo use refreshEndpoint from context. Refresh context first, then get page
                self.gridConfig.getBaseUrl(self.ruleManager.state.currentRule.id) + '/page', self.gridConfig.paginationOptions,
                {timeout: canceler.promise})
              .then(function (response) {
                if (!response.data.ok) {
                  notifyManager.error($translate.instant('bm.rule.failedToLoadData'));
                  clearGridContent();
                  return;
                }

                updateGridContent(response.data.payload.data, response.data.totals, response.data.payload.rules);

                updatingContextPromise = self.context.$get({
                  masterUnitId: securityService.context.masterUnit.id,
                  groupId: $stateParams.groupId,
                  ruleId: self.ruleManager.state.currentRule.id
                }).then(function () {
                  if (_.isFunction(self.gridConfig.prepareData)) {
                    self.gridConfig.prepareData(self.gridConfig.data, self.context);
                    permissionsService.applyPermissions(self.gridConfig, self.permissionsConfig);
                  }
                  metadataService.applyMetadata(self.gridConfig.columnDefs, self.metadata, self.context.data.dependencies);
                });

              }, function (data) {
                if (data.status === -1) return; //check if updating was canceled
                notifyManager.error($translate.instant('bm.rule.failedToLoadData'));
                clearGridContent();
              });

            updatingPagePromise.cancel = function () {
              updatingPagePromise && canceler.resolve();
              updatingContextPromise && updatingContextPromise.$cancelRequest && updatingContextPromise.$cancelRequest();
            };

            return updatingPagePromise;
          };

          self.gridConfig.getPage();


          self.gridConfig.isValidCellValue = function (entity, colDef) {
            var valid = true;
            var selected = self.gridConfig.getCellOrRuleValue(entity, colDef);
            if (colDef.metadata && colDef.metadata.required) {
              valid &= !_.isNil(selected) && (_.isNumber(selected) || _.isBoolean(selected) || !_.isEmpty(selected));
            }
            if (valid && !_.isUndefined(colDef.editDropdownOptionsArray)) {
              if (selected) {
                if (_.size(colDef.editDropdownOptionsArray) !== 0) { // if dependency list is not empty
                  if (colDef.multiselect) {
                    selected.forEach(function (val) {
                      valid &= !!_.find(colDef.editDropdownOptionsArray, {id: val.id});
                    })
                  } else {
                    valid &= !!_.find(colDef.editDropdownOptionsArray, {id: selected.id});
                  }
                } else { // if dependency list is empty then selected value should be undefined
                  valid &= _.isUndefined(selected);
                }
              }
            }
            return valid;
          };

          self.gridConfig.getCellOrRuleValue = function (entity, colDef) {
            var rule = self.ruleManager.getEntityRule(entity.id);
            var fieldValue = _.get(entity, colDef.name);
            if (rule) {
              var ruleValue = _.isFunction(colDef.findRuleValue)
                ? colDef.findRuleValue(rule)
                : _.get(rule, colDef.ruleField || colDef.name);

              if (!_.isUndefined(ruleValue) && !(_.isArray(ruleValue) && _.isEmpty(ruleValue))) {
                if (colDef.multiselect) {
                  return multiselectService.merge(fieldValue, ruleValue, colDef);
                }
                return ruleValue;
              }
            }
            return fieldValue;
          };

          self.gridConfig.getEntityValue = function (entity, colField) {
            return _.get(entity, colField);
          };

          self.gridConfig.deleteEntityRule = function (entity, colDef) {
            $uibModal.open({
              template: require('../../template/bm/group-view/grid/delete_entity_rule_confirmation.html'),
              windowClass: 'gf-modal'
            }).result
              .then(function () {
                _.isFunction(colDef.deleteRule)
                  ? colDef.deleteRule(self.ruleManager.getEntityRule(entity.id))
                  : self.ruleManager.deleteEntityRule(entity.id, colDef.ruleField || colDef.name);
                self.gridApi.rowEdit.setRowsDirty([entity]);
              })
          };

          self.gridConfig.activate = function (entity, value) {
            if (!value && !getInvalidCells(entity)) {
              notifyManager.error($translate.instant('bm.rule.failedToActivateInvalid'));
              return false;
            }
            self.ruleManager.editEntityRule(entity, 'active', !value, value);
            self.gridApi.rowEdit.setRowsDirty([entity]);
          };

          self.gridConfig.isActivationControlEnabled = function () {
            return !self.ruleManager.state.isToday() && self.gridConfig.isAbleToActivate();
          };

          function getInvalidCells(entity) {
            var valid = true;
            self.gridApi.grid.columns.forEach(function (column) {
              //todo check here all entity fields by metadata and context
              valid &= self.gridConfig.isValidCellValue(entity, column.colDef);
            });
            return valid;

          }
        };

        this.$doCheck = function () {

          // apply default config on dynamic columns
          var newCols = _.filter(self.gridConfig.columnDefs, function (col) {
            return !col._complete;
          });
          newCols.forEach(applyColumnDefaultOption);
        };

        self.ruleAdding = {};
        self.isOpen = false;

        self.toggle = function () {
          self.ruleAdding = {
            scheduled: undefined
          };
        };

        self.addNewRule = function () {
          if (!self.ruleAdding.scheduled) {
            return;
          }
          if (isRuleExists(self.rules, self.ruleAdding)) {
            notifyManager.error($translate.instant('bm.rule.ruleExists'));
            return;
          }
          self.ruleManager.addNewBranchRule(self.ruleAdding)
            .then(function (rule) {
              notifyManager.success($translate.instant('bm.rule.newRuleAdded'));
              self.ruleManager.state.setCurrentRule(rule);
              self.gridConfig.getPage();
              self.isOpen = false;
            }, function () {
              notifyManager.error($translate.instant('bm.rule.failedToAdd'));
              self.isOpen = false;
            });
        };

        self.deleteRule = function () {
          $uibModal.open({
            template: require('../../template/bm/group-view/grid/delete_rule_confirmation.html'),
            windowClass: 'gf-modal'
          }).result
            .then(function () {
              self.ruleManager.deleteBranchRule(self.ruleManager.state.currentRule.id)
                .then(function () {
                  notifyManager.success($translate.instant('bm.rule.ruleDeleted'));
                  self.ruleManager.state.setDefaultRule();
                  self.gridConfig.getPage();
                }, function () {
                  notifyManager.error($translate.instant('bm.rule.failedToDelete'));
                })
            })
        };

        self.editCurrentRule = function () {
          $uibModal.open({
            template: require('../../template/bm/group-view/grid/bm_edit_rule_modal.html'),
            resolve: {
              rule: function () {
                return _.cloneDeep(self.ruleManager.state.currentRule)
              },
              allRules: function () {
                return self.ruleManager.getBranchRules()
              }
            },
            controller: function (rule, allRules, $uibModalInstance) {
              var self = this;
              self.rule = rule;
              self.existingRules = _.filter(allRules, function (existingRule) {
                return existingRule.id !== rule.id;
              });
              self.save = function () {
                if (_.isEqual(rule, _.find(allRules, {id: rule.id}))) {
                  $uibModalInstance.dismiss('cancel');
                }
                if (isRuleExists(self.existingRules, self.rule)) {
                  notifyManager.error($translate.instant('bm.rule.ruleExists'));
                } else {
                  $uibModalInstance.close(self.rule);
                }
              }
            },
            controllerAs: '$ctrl',
            windowClass: 'gf-modal'
          }).result.then(function (editedRule) {
            self.ruleManager.editBranchRule(editedRule)
              .then(function () {
                notifyManager.success($translate.instant('bm.rule.ruleEdited'));
              }, function () {
                notifyManager.error($translate.instant('bm.rule.failedToEditRule'));
              });
          })
        };

        function isRuleExists(existingRules, newRule) {
          return _.find(existingRules, function (rule) {
            return rule.scheduled === newRule.scheduled;
          })
        }

        self.showOverViewModal = function () {
          $uibModal.open({
            template: require('../../template/bm/group-view/grid/bm_overview_modal.html'),
            resolve: {
              frameUrl: function () {
                return $http.get('/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/rule/' + self.ruleManager.state.currentRule.id + '/overview/html')
                  .then(function (response) {
                    return response.data;
                  });
              },
              currentRuleId: function () {
                return self.ruleManager.state.currentRule.id;
              }
            },
            controller: function (frameUrl, currentRuleId, $uibModalInstance) {
              var self = this;
              self.overviewState = {
                frameUrl: frameUrl,
                downloadUrl: '',
                state: 'default',
                setState: function (state) {
                  switch (state) {
                    case ('loaded'):
                      this.state = 'loaded';
                      break;
                    default:
                      // console.log('Wrong state type: ' + state);
                  }
                },
                isState: function (state) {
                  return this.state === state;
                }
              };
              self.uploadDone = function () {
                self.overviewState.setState('loaded');
                $scope.$apply();
              };
              self.downloadPdf = function () {
                $http.get('/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/rule/'
                  + currentRuleId + '/overview/pdf')
                  .then(function (response) {
                    self.overviewState.downloadUrl = response.data;
                    $uibModalInstance.close();
                  });
              }
            },
            controllerAs: '$ctrl',
            windowClass: 'gf-modal'
          });
        };

        self.showInstantApplyModal = function () {
          $uibModal.open({
            template: require('../../template/bm/group-view/grid/bm_instant_apply_modal.html'),
            windowClass: 'gf-modal'
          }).result
            .then(function () {
              $http.post('/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId
                + '/rule/' + self.ruleManager.state.currentRule.id + '/run')
                .then(function () {
                  _.remove(self.ruleManager.getBranchRules(), function (rule) {
                    return rule.id === self.ruleManager.state.currentRule.id;
                  });
                  self.ruleManager.state.setDefaultRule();
                  self.gridConfig.getPage();
                  notifyManager.success($translate.instant('bm.rule.successfullyApplied'));
                }, function () {
                  notifyManager.error($translate.instant('bm.rule.failedToApply'));
                })
            })
        };

        self.setCurrentRule = function (rule) {
          self.ruleManager.state.setCurrentRule(rule);
          self.gridConfig.getPage();
          self.gridApi.selection.clearSelectedRows();
          self.globalRulesFilter = {
            name: 'option.all'
          };
          removeRuleFilter();
        };

        self.changeSelectionActivation = function (isActive) {
          var selectedRows = self.gridApi.selection.getSelectedRows();
          var rulesForSaving = [];
          _.forEach(selectedRows, function (row) {
            var rule = self.ruleManager.editEntityRule(row, 'active', isActive, !isActive);
            rulesForSaving.push(rule);
          });
          saveRules(rulesForSaving)
            .then(function () {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              self.gridApi.selection.clearSelectedRows();
            }, function () {
              notifyManager.error($translate.instant('entity.save.failed'));
            });
        };

        function onSortChanged(grid, sortColumns) {
          self.gridConfig.paginationOptions.pagination.sorts = [];
          if (sortColumns && sortColumns.length > 0) {
            sortColumns.forEach(function (c) {
              self.gridConfig.paginationOptions.pagination.sorts.push({
                'field': c.field,
                'order': c.sort.direction.toUpperCase(),
                'priority': c.sort.priority
              });
            });
          } else {
            self.gridConfig.paginationOptions.pagination.sorts = [];
          }
          self.gridConfig.getPage();
        }

        var updatingPageFilter, refreshTimeout;

        function onFilterChanged() {
          var grid = this.grid;
          self.gridConfig.paginationOptions.pagination.filters = [];
          grid.columns.forEach(function (c) {
            if (c.filters[0].term) {
              self.gridConfig.paginationOptions.pagination.filters.push({
                '@class': 'de.icash.pagination.filter.' + (c.colDef.filterType ? c.colDef.filterType : 'Like'),
                field: c.filterPathFieldName ? c.filterPathFieldName : c.field,
                value: c.filters[0].term
              })
            }
          });

          if (self.globalRulesFilter && self.globalRulesFilter.type) {
            self.gridConfig.paginationOptions.pagination.filters.push(self.globalRulesFilter);
          }

          if (refreshTimeout) {
            $timeout.cancel(refreshTimeout);
          }
          if (updatingPageFilter) {
            updatingPageFilter.cancel();
          }

          refreshTimeout = $timeout(function () {
            updatingPageFilter = self.gridConfig.getPage();
          }, 200);
        }

        function removeRuleFilter() {
          _.remove(self.gridConfig.paginationOptions.pagination.filters, function (filter) {
            return filter.type === 'ruleFilter';
          });
        }

        function onAfterCellEdit(rowEntity, colDef, newValue, oldValue) {
          _.set(rowEntity, colDef.name, oldValue);
          if (!isChanged(rowEntity, colDef, newValue, oldValue)) {
            return;
          }
          if (_.isFunction(colDef.preSaveRule)) {
            newValue = colDef.preSaveRule(newValue, self.ruleManager.getEntityRule(rowEntity.id));
          }
          var ruleField = colDef.ruleField || colDef.name;
          self.ruleManager.editEntityRule(rowEntity, ruleField, newValue, oldValue);
          self.gridApi.rowEdit.setRowsDirty([rowEntity]);
        }

        function onCancelCellEdit(rowEntity, colDef, newValue, oldValue) {
          // console.log(rowEntity, colDef, newValue, oldValue);
        }

        function onSaveRow(rowEntity) {
          var rule = self.ruleManager.getEntityRule(rowEntity.id);
          var preparedRule = beforeSaveRule(rule);
          this.rowEdit.setSavePromise(rowEntity,
            saveRules([preparedRule])
              .then(function () {
                notifyManager.success($translate.instant('entity.edit.successfully'));
              }, function () {
                notifyManager.error($translate.instant('entity.save.failed'));
              })
          );
        }

        function beforeSaveRule(rule) {
          return addEmptyFields(rule);
        }

        function addEmptyFields(rule) {
          var emptyFields = '';
          for (var key in rule) {
            if (rule.hasOwnProperty(key) && rule[key] === null) {
              emptyFields += emptyFields ? ',' + key : key;
            }
          }
          return _.set(_.clone(rule), 'emptyFields', emptyFields);
        }

        function beforeUpdate(rules) {
          return applyEmptyFields(rules)
        }

        function applyEmptyFields(initialRules) {
          var rules = _.cloneDeep(initialRules);
          rules.forEach(function (rule) {
            if (rule.emptyFields) {
              rule.emptyFields.split(',').forEach(function (fieldName) {
                rule[fieldName] = null;
              });
              delete rule.emptyFields;
            }
          });
          return rules;
        }

        function saveRules(rules) {
          return self.ruleManager.saveEntityRules(self.gridConfig.getBaseUrl(self.ruleManager.state.currentRule.id), rules);
        }

        function isChanged(rowEntity, colDef, newValue, oldValue) {
          var rule = self.ruleManager.getEntityRule(rowEntity.id);
          var ruleValue = _.get(rule, colDef.ruleField || colDef.name);
          if (!_.isUndefined(ruleValue)) {
            return !_.isEqual(newValue, ruleValue);
          } else {
            if (_.isFunction(colDef.isChanged)) {
              return colDef.isChanged(newValue, oldValue);
            }
            return !_.isEqual(newValue, oldValue);
          }
        }

        function applyDefaultOptions(gridConfig) {
          _.defaults(gridConfig, gridDefaultConfig);

          gridConfig.columnDefs.push({
            name: 'actions',
            width: 100,
            enableCellEdit: false,
            allowCellFocus: false,
            enableSorting: false,
            enableFiltering: false,
            displayName: '',
            // cellFilter: 'activationCellFilter:row.entity | translate',
            cellTemplate: 'app/template/bm/group-view/grid/cell/bm_cell_actions.html'
          });

          gridConfig.columnDefs.forEach(applyColumnDefaultOption);

          gridConfig.paginationOptions = {
            pagination: {
              from: 0,
              limit: gridConfig.paginationPageSize,
              sorts: [],
              filters: []
            }
          };
        }

        function applyColumnDefaultOption(c) {
          c.displayName = _.isUndefined(c.displayName) ? self.metadata.name + '.' + c.name : c.displayName;
          c.headerCellFilter = 'translate';
          c.enableColumnResizing = true;

          c.headerTooltip = true;

          c.cellEditableCondition = function () {
            return self.gridConfig.ruleManager && !self.gridConfig.ruleManager.state.isToday();
          };
          if (c.cellClass) {
            console.error('trying to specify second cellClass');
          }
          c.cellClass = function () {
            if (!c.enableCellEdit || !c.cellEditableCondition()) {
              return 'disabled-cell';
            }
          };

          // mark columns are already processed by this fn to check new column in doCheck
          c._complete = true;
        }
      }
    })

    .config(function ($provide) {
      $provide.decorator('uiGridRowEditService', function ($delegate) {

        function bmEndEditCell(rowEntity) {
          var grid = this.grid;
          var gridRow = grid.getRow(rowEntity);
          if (!gridRow) {
            gridUtil.logError('Unable to find rowEntity in grid data, dirty flag cannot be set');
            return;
          }

          if (gridRow.isDirty) {
            delete gridRow.isError;
            $delegate.considerSetTimer(grid, gridRow);
          }
        }

        var endEditCellOriginal = $delegate.endEditCell;

        function endEditCellPatched() {
          var grid = this.grid;
          if (grid.options.bmMode) {
            bmEndEditCell.apply(this, arguments);
          } else {
            endEditCellOriginal.apply(this, arguments);
          }
        }

        $delegate.endEditCell = endEditCellPatched;

        return $delegate;
      })
    })

    .component('gfGridPagination', {
      template: require('../../template/bm/group-view/grid/bm_grid_pagination.html'),
      bindings: {
        gridConfig: '='
      },
      controller: function () {
        var self = this;

        self.maxPaginationSize = 5;

        self.setPageSize = function (size) {
          self.currentPage = 1;
          self.gridConfig.paginationPageSize = size;
          self.gridConfig.paginationOptions.pagination.limit = size;
          self.gridConfig.paginationOptions.pagination.from = 0;
          self.gridConfig.getPage();
        };

        self.pageChanged = function (newPage, pageSize) {
          self.gridConfig.paginationOptions.pagination.from = (newPage - 1) * pageSize;
          self.gridConfig.paginationOptions.pagination.limit = pageSize;
          self.gridConfig.getPage();
        };

      }
    })

    .constant('gridDefaultConfig', {

      bmMode: true,

      enableSorting: true,
      enableFiltering: true,
      useExternalFiltering: true,
      useExternalPagination: true,
      enablePaginationControls: false,
      selectionRowHeaderWidth: 37,
      rowHeight: 40,

      paginationPageSizes: [10, 25, 50, 100, 200],
      paginationPageSize: 10,
      excessRows: 200
    })

    .component('bmRuleDatepicker', {
      template: require('../../template/bm/group-view/grid/bm_rule_datepicker.html'),
      bindings: {
        date: '=ngModel',
        allRules: '<'
      },
      controller: function (moment) {
        var self = this;

        self.dateFormat = 'yyyy-MM-dd';

        self.dateOptions = {
          dateDisabled: function (data) {
            return _.find(self.allRules, function (r) {
              //todo use moment library to compare this condition, instead of comparing stings or Date objects
              return r.scheduled === moment(data.date).format('YYYY-MM-DD') || data.date <= new Date();
            })
          }
        };
      }
    })

    .component('bmRuleCell', {
      template: ['$attrs', function ($attrs) {
        switch ($attrs.cellType) {
          case 'BM_EDIT_CELL' :
            return require('../../template/bm/group-view/grid/cell/bm_cell_edit_template.html');
          case 'BM_ACTIONS_CELL' :
            return require('../../template/bm/group-view/grid/cell/bm_cell_actions_template.html');
          case 'BM_BOOLEAN_CELL' :
            return require('../../template/bm/group-view/grid/cell/bm_cell_boolean_template.html');
          default :
            return require('../../template/bm/group-view/grid/cell/bm_cell_default_template.html');
        }
      }],
      bindings: {
        entityCellValue: '<',
        entityCellOriginalValue: '<',
        grid: '<',
        row: '<',
        col: '<',
        cellType: '<'
      },
      controller: function ($translate) {
        var self = this;

        self.isValid = function (entity, colDef) {
          return self.grid.options.isValidCellValue(entity, colDef);
        };

        self.isRule = function (entity, colDef) {
          var rule = self.grid.options.ruleManager.getEntityRule(entity.id);
          if (!rule) {
            return false;
          }
          var fieldRule = _.isFunction(colDef.findRuleValue)
            ? colDef.findRuleValue(rule)
            : _.get(rule, colDef.ruleField || colDef.name);
          if (_.isUndefined(fieldRule)) {
            return false;
          }
          return !(_.isArray(fieldRule) && _.isEmpty(fieldRule))
        };

        self.getOriginalValue = function () {
          if (!_.isBoolean(self.entityCellOriginalValue) && _.isEmpty(self.entityCellOriginalValue)) {
            return '<' + $translate.instant('empty') + '>';
          }
          return self.entityCellOriginalValue;
        }
      }
    })

    .component('bmActivateButton', {
      template: require('../../template/bm/group-view/grid/cell/bm_activate_button.html'),
      bindings: {
        grid: '<',
        row: '<',
        col: '<'
      },
      controller: function () {
        var self = this;

        self.isActive = function () {
          return self.grid.options.getCellOrRuleValue(self.row.entity, {name: 'active'});
        };

        self.actionBtnClass = function () {
          return {
            'action-btn-success': self.isActive(),
            'action-btn-danger fa-ban-btn': !self.isActive()
          }
        };

        self.activeBtnIconClass = function () {
          return {
            'fa-check-square-o': self.isActive(),
            'fa-ban': !self.isActive()
          }
        };

        self.toggleActive = function () {
          self.grid.options.activate(self.row.entity, self.isActive());
        };

        self.isDisabled = function () {
          return !self.grid.options.isActivationControlEnabled();
        };
      }
    })

    .component('bmRuleFilter', {
      template: require('../../template/bm/group-view/grid/bm_rule_filter.html'),
      bindings: {
        filter: '=ngModel',
        gridApi: '<',
        entityName: '<'
      },
      require: 'ngModel',
      controller: function () {
        var self = this;
        self.$onInit = function () {

          var defaultOption = {
            name: 'option.all'
          };

          self.filter = defaultOption;

          self.ruleFilterTypes = [
            defaultOption,
            {
              name: 'bm.rule.filter.changed',
              "@class": "de.icash.pagination.filter.BranchRuleChanged",
              type: 'ruleFilter',
              branchRuleName: self.entityName
            },
            {
              name: 'bm.rule.filter.activated',
              "@class": "de.icash.pagination.filter.BranchRuleActive",
              value: true,
              type: 'ruleFilter',
              branchRuleName: self.entityName
            },
            {
              name: 'bm.rule.filter.deactivated',
              "@class": "de.icash.pagination.filter.BranchRuleActive",
              value: false,
              type: 'ruleFilter',
              branchRuleName: self.entityName
            }
          ];
        }
      }
    })

    .component('ruleMultiselect', {
      template: require('../../template/bm/group-view/grid/bm_rule_multiselect.html'),
      bindings: {
        rulesModel: '=ngModel',
        entity: '<',
        options: '<',
        rule: '<',
        col: '<',
        grid: '<'
      },
      require: 'ngModel',
      controller: function (multiselectService, notifyManager) {
        var self = this;

        self.$onInit = function () {
          self.ruleFieldName = self.col.colDef.ruleField || self.col.colDef.name;
          self.optionFieldName = self.col.colDef.ruleRelationField || self.col.colDef.name.slice(0, -1); // last expression to get singular from the plural

          self.initialModel = self.rulesModel;

          self.rulesModel = self.rule ? _.cloneDeep(self.rule[self.ruleFieldName]) || [] : [];

          self.invalidOptions = getInvalidOptions(self.options, self.initialModel, self.rulesModel, self.col.colDef);
        };


        self.toggle = function (option) {
          var toggleRule = _.find(self.rulesModel, function (rule) {
            var field = rule[self.optionFieldName];
            return field && field.id === option.id;
          });
          if (!toggleRule) {
            toggleRule = createMultiselectRule(option);
            self.rulesModel.push(toggleRule);
          }
          if (option.$gfInvalid && !toggleRule.active) {
            //todo TRANSLATE THIS
            notifyManager.error('It is impossible to select inactive option');
            return;
          }
          toggleRule.active = !toggleRule.active;
        };

        self.deleteOptionRule = function (option) {
          _.remove(self.rulesModel, function (rule) {
            var field = rule[self.optionFieldName];
            return field && field.id === option.id;
          });
        };

        self.getSelectedTotal = function () {
          return multiselectService.merge(self.initialModel, self.rulesModel, self.col.colDef);
        };


        self.isChecked = function (option) {
          var mergedArr = multiselectService.merge(self.initialModel, self.rulesModel, self.col.colDef);
          var isChecked = _.find(mergedArr, {id: option.id});
          return !!isChecked;
        };

        self.isRule = function (option) {
          var isFound = _.find(self.rulesModel, function (rule) {
            return rule[self.optionFieldName].id === option.id;
          });
          return !!isFound;
        };

        function createMultiselectRule(option) {
          var rule = {
            active: self.isChecked(option)
          };
          rule[self.optionFieldName] = option;
          return rule;
        }

        function getInvalidOptions(options, initialModel, rulesModel, colDef) {

          var usedOptions = _.chain(rulesModel)
            .map(colDef.ruleRelationField)
            .unionBy(initialModel, 'id')
            .value();

          return _.chain(usedOptions).filter(function (item) {
            return !_.find(options, {id: item.id})
          }).map(function (item) {
            item.$gfInvalid = true;
            return item;
          }).uniqBy('id').value()
        }

        self.getAllOptions = function (options, invalidOptions) {
          return _.unionBy(options, invalidOptions, 'id');
        }
      }
    })

    .filter('formatRuleDateFilter', function (moment) {
      return function (input) {
        if (input) {
          return moment(input).format('YYYY-MM-DD/HH:mm');
        }
      }
    })

    .filter('activationCellFilter', function () {
      return function (entity) {
        if (_.isUndefined(entity)) return;
        return entity ? 'bm.rule.filter.activated' : 'bm.rule.filter.deactivated';
      }
    })

    .filter('bmRuleCell', function () {
      return function (cell, row, col) {
        return row.grid.options.getCellOrRuleValue(row.entity, col.colDef);
      }
    })

    .factory('multiselectService', function () {
      return {
        merge: function (values, rules, colDef) {

          var result = _.uniqBy(values, 'id') || [];

          var activations = _.chain(rules).filter({active: true}).map(colDef.ruleRelationField).value();
          var deactivations = _.chain(rules).filter({active: false}).map(colDef.ruleRelationField).value();

          result = _.unionBy(result, activations, 'id');
          result = _.pullAllBy(result, deactivations, 'id');

          return result;
        }
      }
    })

})();
