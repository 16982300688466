(function () {
  'use strict';
  angular.module('gf.masterdata.inventory', [])

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.masterdataInventory', {
          url: '/masterdata/inventory',
          template: require('../../../main/angular/app/template/page/gf-grid.html'),
          controller: 'InventoryCtrl',
          resolve: {
            articleStockContext: function (ArticleStockContext) {
              return ArticleStockContext.get().$promise;
            },
            articleStockMetadata: function (ArticleStockMetadata) {
              return ArticleStockMetadata.get().$promise;
            }
          },
          title: "inventory"
        });
    })

    .controller('InventoryCtrl',
      function ($scope, $translate, articleStockContext, articleStockMetadata) {
        $scope.metadata = articleStockMetadata;
        $scope.context = articleStockContext;

        $scope.config = {
          enableSorting: true,
          enableFiltering: true,
          enablePagination: true,
          showOnlyActive: true,
          columns: [
            {
              name: 'id',
              field: 'article.id',
              filterPathFieldName: 'article.id',
              width: 70,
              enableCellEdit: false,
              displayName: 'article.id'
            },
            {
              name: 'article.name',
              enableCellEdit: false,
              displayName: 'article.name'
            },
            {
              name: 'article.articleGroup.name',
              width: 150,
              displayName: 'article.articleGroup',
              enableCellEdit: false
            },
            {
              name: 'amount',
              width: 120,
              displayName: 'article.amount',
              enableFiltering: false
            }
          ]
        };
      }
    )

})();
