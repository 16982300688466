var TicketLayoutAddCtrlResolve = {
  ticketLayoutMetadata: ["TicketLayoutMetadata", function (TicketLayoutMetadata) {
    return TicketLayoutMetadata.get().$promise;
  }],
  ticketLayoutContext: ["TicketLayoutContext", function (TicketLayoutContext) {
    return TicketLayoutContext.get().$promise;
  }],
  ticketLayout: ["TicketLayout", "$stateParams", function (TicketLayout, $stateParams) {
    return TicketLayout.load({ticketLayoutId: $stateParams.id || "add"}).$promise;
  }]
};


(function () {
  'use strict';
  angular.module('gf.hardware', [])
    .factory('TicketLayoutAdd', [
      function () {
        return function ($scope, $location, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata) {
          $scope.ticketLayoutContext = ticketLayoutContext;
          $scope.ticketLayoutMetadata = ticketLayoutMetadata;
          $scope.ticketLayout = ticketLayout;
          angular.extend($scope, ticketLayoutContext.data.dependencies);

          $scope.save = function () {
            if ($scope.ticketLayoutForm.$valid) {
              $scope.ticketLayout.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $location.path('/hardware/ticket_layout')
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          $scope.saveAndNew = function () {
            if ($scope.ticketLayoutForm.$valid) {
              $scope.ticketLayout.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          $scope.goBack = function () {
            $location.path('/hardware/ticket_layout')
          }
        }
      }
    ])
    .controller('TicketLayoutAddCtrl',

      function ($scope, $location, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata, TicketLayoutAdd) {
        TicketLayoutAdd($scope, $location, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata);
      }
    )
    .controller('TicketLayoutEditCtrl',

      function ($scope, $location, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata, TicketLayoutAdd) {
        // Fill scope by service
        TicketLayoutAdd($scope, $location, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata);

        $scope.save = function () {
          if ($scope.ticketLayoutForm.$valid) {
            $scope.ticketLayout.$edit({ticketLayoutId: $scope.ticketLayout.id}, function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $location.path('/hardware/ticket_layout')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        $scope.saveAndNew = function () {
          if ($scope.ticketLayoutForm.$valid) {
            $scope.ticketLayout.$edit({ticketLayoutId: $scope.ticketLayout.id}, function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $location.path('/hardware/ticket_layout/add')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

      }
    )
    .controller('TicketLayoutGridCtrl',

      function ($scope, ticketLayoutContext, ticketLayoutMetadata) {

        $scope.metadata = ticketLayoutMetadata;
        $scope.context = ticketLayoutContext;

        var actions = [{
          name: 'copy',
          template: 'app/template/grid/copy_article_action.html',
          controller: 'CopyTicketLayoutActionCtrl'
        }];

        $scope.config = {
          urlRoute: '/hardware/ticket_layout',
          registerActions: actions,
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'id', width: 70, visible: false},
            {name: 'name'},
            {
              name: 'type',
              width: 300,
              cellFilter: "prefixFilter:'ticketType.' | translate",
              editDropdownFilter: "prefixFilter:'ticketType.' | translate"
            },
            {name: 'operate', actions: ['edit', 'copy', 'delete']}
          ]
        };
      }
    )
    .controller('CopyTicketLayoutActionCtrl',
      function ($scope, $translate, notifyManager, TicketLayout, entity, gridApi) {
        $scope.gridApi = gridApi;
        $scope.entity = entity;

        var newEntity = angular.copy(entity);
        newEntity.id = null;

        var ticketLayoutResource = new TicketLayout(newEntity);

        ticketLayoutResource.$save(function (data) {
          var index = _.findIndex($scope.gridApi.grid.options.data, function (item) {
            return item.id == $scope.entity.id;
          });
          // insert new article to the next position
          $scope.gridApi.grid.options.data.splice(index + 1, 0, data.payload.entity);

          notifyManager.success($translate.instant('entity.save.successfully'));
        });
      }
    )
})();

