(function () {
  'use strict';
  angular.module('gf.bm.articleType', ['gf.bm.groupView'])

    .factory('BMArticleTypeMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/article_type/metadata.json')
    })
    .factory('BMArticleTypeContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/article_type/context.json')
    })
    .factory('BMArticleTypeRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.setupArticle_type', {
          url: '/setup/article_type',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMSetupArticleType',
          controllerAs: '$ctrl',
          resolve: {
            bmArticleTypeMetadata: function (BMArticleTypeMetadata, $stateParams, securityService) {
              return BMArticleTypeMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmArticleTypeContext: function (BMArticleTypeContext, $stateParams, securityService) {
              return BMArticleTypeContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmArticleTypeRules: function (BMArticleTypeRules, $stateParams, securityService) {
              return BMArticleTypeRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "setupArticleType"
        })
    })

    .controller('BMSetupArticleType',
      function (bmArticleTypeMetadata, bmArticleTypeContext, securityService, $stateParams, bmArticleTypeRules) {
        var self = this;

        self.metadata = bmArticleTypeMetadata;
        self.context = bmArticleTypeContext;
        self.masterdataRules = bmArticleTypeRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name', enableCellEdit: false},
            {name: 'info', enableCellEdit: false},
            {name: 'fixedPrice', enableCellEdit: false},
            {name: 'fixedName', enableCellEdit: false},
            {name: 'constraintOnly', enableCellEdit: false},
            {name: 'measure', enableCellEdit: false},
            {name: 'description', enableCellEdit: false}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/article_type';
          }
        };

      }
    )

})();
