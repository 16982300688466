(function () {
  'use strict';
  angular.module('gf.report.running', [])

    .factory('AsyncRunningReports', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/report/async.json');
    })
    .factory('AsyncLongRunningReports', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/report/asynclong.json');
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.reportRunning', {
          url: '/report/running',
          template: require('../template/report/running.html'),
          controller: 'RunningReportCtrl',
          controllerAs: 'runningReportCtrl',
          resolve: {
            asyncRunningReports: function (AsyncRunningReports) {
              return AsyncRunningReports.query().$promise;
            },
            asyncLongRunningReports: function (AsyncLongRunningReports) {
              return AsyncLongRunningReports.query().$promise;
            },
            profile: function (Profile) {
              return Profile.get().$promise;
            }
          }
        });
    })

    .controller('RunningReportCtrl',
      function (asyncRunningReports, asyncLongRunningReports, AsyncRunningReports, AsyncLongRunningReports) {
        var self = this;

        self.asyncQeue = asyncRunningReports;
        self.refreshAsyncQueue = function () {
          self.asyncQeue = null;
          AsyncRunningReports.query(function (resp) {
            self.asyncQeue = resp;
          });
        };

        self.asyncLongQeue = asyncLongRunningReports;

        self.refreshLongQueue = function () {
          self.asyncLongQeue = null;
          AsyncLongRunningReports.query(function (resp) {
            self.asyncLongQeue = resp;
          });
        };

      }
    )

})();


