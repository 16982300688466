import {CircularProgress} from "@material-ui/core";
import React from "react";

function Loader() {
  return (
    <div
      style={{display: 'flex', justifyContent: 'center', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0}}>
      <CircularProgress style={{margin: 'auto'}} size={55}/>
    </div>
  )
}

export default Loader
