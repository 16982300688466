(function () {
    'use strict';
    angular.module('gf.reportUtils', [])
        .factory('prepareReportOrCorrections', function ($cookies, $location) {
            function cookieName (name, filterTypeId, global) {
                return 'report.' + (global ? "" : (filterTypeId + ".")) + name;
            }
            return {
                fetchAndSetValue: function (name, defaultValue, filterTypId, global) {
                    var value = $location.search()[name] || defaultValue || $cookies.get(cookieName(name, filterTypId, global));
                    this.setValue(name, value, filterTypId, global);
                    return value;
                },
                getValue: function (name, filterTypeId, global) {
                    return $location.search()[name] || $cookies.get(cookieName(name, filterTypeId, global));
                },
                createDate: function (stringDate) {
                    return new Date(stringDate) != 'Invalid Date' ? new Date(stringDate) : null;
                },
                setValue: function (name, value, filterTypeId, global) {
                    $location.search(name, value);
                    if (value) {
                        $cookies.put(cookieName(name, filterTypeId, global), value);
                    } else {
                        $cookies.remove(cookieName(name, filterTypeId, global));
                    }
                    return value;
                }
            }
        })
})();