import React, { useEffect, useState } from "react";
import {
  PaymentsPortal,
  PortalType,
} from "@lightspeed-payments/payments-portal-sdk";
import { Box } from "@material-ui/core";
import axios from "axios";
import { Global, css as emotionCSS } from "@emotion/react";

const OnboardingPortalType = {
  Capital: "CAPITAL",
  Payments: "PAYMENTS",
};

const getPortalType = () => {
  const queryParams = window.location.href.split("?")[1];

  if (queryParams && queryParams.indexOf("view=capital") !== -1) {
    return PortalType.Onboarding;
  } else {
    return PortalType.Merchant;
  }
};

const getLocale = (tag) => {
  const localeMap = {
    en: "en-US",
    fr: "fr-FR",
    de: "de-DE",
    it: "it-IT",
    no: "no-NO",
    sv: "sv-SE",
    es: "es-ES",
  };
  return localeMap[tag] || "en-US";
};

const getPortalUrl = (portalType, onboardingType, restaurantId) => {
  let sessionType;

  portalType === PortalType.Onboarding && onboardingType
    ? (sessionType = `${onboardingType}_ONBOARDING`)
    : (sessionType = "MERCHANT_PORTAL");

  return axios.get(
    `/icash/lightspeed-financial/portal-session/${restaurantId}/?sessionType=${sessionType}`
  );
};

export default function FinancialServices({ security }) {
  const [renderGlobalStyles, setRenderGlobalStyles] = useState(true);

  const merpStyling = emotionCSS`
        [data-testid='gf-main-body'] {
          display: flex;
          flex-direction: column;
          width: 100%;
          position: fixed;
        }

        [data-testid='gf-view-wrapper'] {
          height: 100%;
          padding: 0px;
        }

        [data-testid='gf-main-view-wrapper'] {
          height: 100%;
          padding: 0px;
          display: flex;
          flex-direction: column;
        }

        [data-testid='gf-main-container'] {
          margin: 0px;
          display: contents;
        }

        [name='FinancialServices'] {
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        [data-testid='react-app'] {
          height: 100%;
        }`;

  const resetStyling = emotionCSS`
        [data-testid='gf-main-body'] {
        }

        [data-testid='gf-view-wrapper'] {
        }

        [data-testid='gf-main-view-wrapper'] {
        }

        [data-testid='gf-main-container'] {
        }

        [name='FinancialServices'] {
        }

        [data-testid='react-app'] {
        }`;

  const portalType = getPortalType();
  const onboardingType = OnboardingPortalType.Capital;
  const restaurantId = security.context.currentRestaurant.id;

  const locale = getLocale(security.context.currentLanguageTag.toLowerCase());

  useEffect(() => {
    const handlePopState = () => {
      setRenderGlobalStyles(false);
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    // The portal can either be Capital onboarding or Merchant portal, when its first loaded.
    getPortalUrl(portalType, onboardingType, restaurantId).then(
      ({ data: { portalUrl } }) => {
        let portal;

        portal = PaymentsPortal.setup({
          targetIframeId: "portal",
        });

        portal.load({
          url: portalUrl,
          portalType: portalType,
          path: "",
          lang: locale,
        });

        portal.subscribeToEvents({
          onSessionExpiringSoon: () => {
            getPortalUrl(portalType, onboardingType, restaurantId).then(
              ({ data: { portalUrl } }) => {
                portal.load({
                  url: portalUrl,
                  portalType: portalType,
                  path: "",
                  lang: locale,
                });
              }
            );
          },
          onMerchantPortalRequested: ({ path }) => {
            getPortalUrl(PortalType.Merchant, "", restaurantId).then(
              ({ data: { portalUrl } }) => {
                portal.load({
                  url: portalUrl,
                  portalType: PortalType.Merchant,
                  path: path || "",
                  lang: locale,
                });
              }
            );
          },
          onOnboardingPortalRequested: async ({ onboarding, pli }) => {
            getPortalUrl(
              PortalType.Onboarding,
              onboarding?.type || OnboardingPortalType.Payments,
              restaurantId
            ).then(({ data: { portalUrl } }) => {
              portal.load({
                url: portalUrl,
                portalType: PortalType.Onboarding,
                path: "",
                lang: locale,
              });
            });
          },
        });
      }
    );
  }, []);

  return (
    <>
      <Global styles={renderGlobalStyles ? merpStyling : resetStyling} />
      <Box display="flex" height="100%" padding="0px">
        <iframe id="portal" width="100%" title="Lightspeed Payments"></iframe>
      </Box>
    </>
  );
}
