import i18n from "../../../../../components/react_components/i18n";
import moment from 'moment-timezone';

(function () {
  'use strict';

  angular.module('gf.security', ['ngResource'])

    .provider('securityService', function () {

      var paths = {};
      var securityContext;

      /**
       * Return available default path
       * @param securitySelf - 'this' of security service
       * @param menuService - menuService injection
       * @param defaultPath - check default path first if not found looking for available path.
       */
      function availablePath(securitySelf, menuService, defaultPath) {

        var menuPaths = menuService.getPaths();
        var availablePath;

        /**
         * Try to check default path first
         */
        if (defaultPath && securitySelf.hasAccess(defaultPath)) {
          return defaultPath;
        }

        /**
         * Try to find available path then
         */
        _.forEach(menuPaths, function (path) {
          if (securitySelf.hasAccess(path)) {
            availablePath = path;
            return false;
          }
        });

        return availablePath;
      }

      this.securePath = function (path) {

        if (arguments.length > 1) {
          var roles = [];

          for (var i = 1; i < arguments.length; i++) {
            roles.push(arguments[i]);
          }
          if (roles.length === 1 && angular.isFunction(roles[0])) {
            paths[path] = roles[0];
          } else {
            paths[path] = roles;
          }
        }

        return this;
      };

      function getSecurePaths() {
        var securePaths = [];
        for (var key in paths) {
          securePaths.push(key);
        }
        return securePaths;
      }

      // Need to set SecurityContext resource in config section of application
      this.setSecurityContext = function (SecurityContextResource) {
        securityContext = SecurityContextResource;
      };

      function hasAnyRole() {
        var roles = securityContext.roles;

        var userRoles = arguments;

        if (angular.isArray(arguments[0])) {
          userRoles = arguments[0];
        }

        for (var i = 0; i < userRoles.length; i++) {
          if (roles.indexOf(userRoles[i]) !== -1) {
            return true;
          }
        }

        return false;
      }

      function hasAnyFeature() {
        var features = securityContext.features;

        var userFeatures = arguments;

        if (angular.isArray(arguments[0])) {
          userFeatures = arguments[0];
        }

        for (var i = 0; i < userFeatures.length; i++) {
          if (features.indexOf(userFeatures[i]) > -1) {
            return true;
          }
        }

        return false;
      }

      function hasAccess(path) {
        var securitySelf = this;
        var securePaths = getSecurePaths();
        var startWithPaths = _.filter(securePaths, function (obj) {
          return path.indexOf(obj) === 0;
        });
        if (startWithPaths.length) {
          var accessDenied = _.some(startWithPaths, function (path) {
            var securePathAccessor = paths[path];
            if (_.isFunction(securePathAccessor)) {
              return !securePathAccessor(securitySelf);
            }
            return !hasAnyRole(securePathAccessor);
          });
          if (accessDenied) {
            return false;
          }
        }
        return true;
      }

      this.$get = ['$q', 'languageService', 'menuService', function ($q, languageService, menuService) {

        var refreshInProgress = false;

        return {
          context: securityContext,
          initialize: function (context) {
            angular.extend(this, context);
          },
          refreshContext: function () {
            return $q(function (resolve, reject) {
              if (refreshInProgress) {
                reject();
                console.log('Security context refreshing is in progress');
                return;
              }
              refreshInProgress = true;
              securityContext.$get()
                .then(function (context) {
                  moment.tz.setDefault(context.currentRestaurant?.timezone);
                  languageService.setLanguage(context.currentLanguageTag)
                    .then(function () {
                      resolve(context);
                    }, function () {
                      reject(context);
                    })
                    .finally(function () {
                      refreshInProgress = false;
                    })
                }, function () {
                  reject();
                  refreshInProgress = false;
                })
            });
          },
          showTutorialButton: function(withPopover) {
            this.showTutorialAgain = true;
            this.showTutorialButtonPopover = withPopover;
          },
          hideTutorialButton: function() {
            this.showTutorialAgain = false;
            this.showTutorialButtonPopover = false;
          },
          showNextPageTutorialButton: function() {
            this.showNextPageButton = true;
          },
          hideNextPageTutorialButton: function() {
            this.showNextPageButton = false;
          },
          hideStartTourButtonPopover: function() {
            this.showTutorialButtonPopover = false;
          },
          hasAnyRole: hasAnyRole,
          hasAnyFeature: hasAnyFeature,
          hasAccess: hasAccess,
          availablePath: function(defaultPath) {
            return availablePath(this, menuService, defaultPath)
          }
        };
      }]
    });

  angular.module('gf.services', ['ngResource'])
    .factory('Customer', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/customer/customer/:customerId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('CustomerCard', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/customer/card', {}, {
        save: {method: 'POST'},
        remove: {method: 'DELETE'}
      });
    })
    .factory('CustomerGroup', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/customer/customer_group/:customerGroupId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('CustomerDiscount', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/customer/discount/:customerDiscountId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('SwitchOperators', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/user/new/switchOperators.json', {}, {
        load: {method: 'GET'}
      });
    })
    .factory('SwitchRestaurant', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/user/new/switchRestaurant.json', {}, {
        load: {method: 'GET'},
        change: {method: 'POST'}
      });
    })
    .factory('SwitchRestaurantGroup', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/user/switch_group.json', {}, {
        load: {method: 'GET'},
        change: {method: 'POST'}
      });
    })
    .factory('Article', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/article/:articleId.json', {}, {
        load: {method: 'GET'},
        change: {method: 'PUT'}
      });
    })
    .factory('Dashboard', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/dashboard/:dateType.json', {}, {
        load: {method: 'GET'}
      });
    })
    .factory('DashboardEntry', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/dashboard/edit/dashboard.json', {}, {
        load: {method: 'GET'}
      });
    })
    .factory('DashboardDetails', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/dashboard/:dateType/:chartType.json', {}, {
        load: {method: 'GET'}
      });
    })
    .factory('WaiterGroup', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/waiter_group/:waiterGroupId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('ArticleType', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/article_type/:articleTypeId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Table', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/table/:tableId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Account', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/account/:accountId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('PriceLevel', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/price_level/:priceLevelId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Yovite', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/customer/yovite/yovite.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Datev', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/datev/datev.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Cube', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/cube/cube.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('JournalCorrections', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/correction/correction.json', {}, {
        load: {method: 'GET', isArray: true}
      });
    })
    .factory('AppVersions', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/dashboard/app_version/data.json', {}, {
        load: {method: 'GET', isArray: true}
      });
    })
    .factory('OrderScreen', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/order_screen/:orderScreenId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('RestaurantGroup', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant_group/:restaurantGroupId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Administrator', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/admin/admin/:administratorId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('ResellerUser', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/admin/reseller_user/:resellerUserId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('ReportFilter', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/report/report_filter/:typeId/:reportFilterId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('RoomPositions', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/room/positions', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('CurrentPaypalLocation', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/restaurant/location', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'},
        save: {method: 'POST'},
        remove: {method: 'DELETE'}
      });
    })
    .factory('PaypalData', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/feature/:belongsTo/paypal', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Feature', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/permission/feature/:featureId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('OperatorContractType', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/permission/operator_contract_type/:operatorContractTypeId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Theme', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/admin/theme/:themeId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Currency', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/admin/currency/:currencyId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('DeviceType', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/admin/device_type/:deviceTypeId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('PrinterDriver', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/admin/printer_driver/:printerDriverId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('TicketType', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/admin/ticket_type/:ticketTypeId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('InAppMessage', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/admin/in_app_message/:inAppMessageId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Country', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/address_setup/country/:countryId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('CountryRegion', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/address_setup/country_region/:countryRegionId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('CloneOperator', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator/operator/:operatorId/clone', {}, {
        load: {method: 'GET'},
        clone: {method: 'POST'}
      });
    })
    .factory('CloneCustomer', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator/operator/:operatorId/customer/clone', {}, {
        load: {method: 'GET'},
        clone: {method: 'POST'}
      });
    })
    .factory('CloneRestaurant', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator/restaurant/:restaurantId/clone', {}, {
        load: {method: 'GET'},
        clone: {method: 'POST'}
      });
    })
    .factory('CloneRestaurantOperator', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/:restaurantId/clone', {}, {
        load: {method: 'GET'},
        clone: {method: 'POST'}
      });
    })
    .factory('ZuoraProducts', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/zuora/product/new', {}, {
        load: {method: 'GET'},
        save: {method: 'POST'}
      });
    })
    .factory('ZuoraProduct', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/zuora/:productId.json', {}, {
        load: {method: 'GET'},
        save: {method: 'PUT'},
        refresh: {method: 'POST'}
      });
    })
    .factory('Invoices', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/invoice', {}, {
        get: {'method': 'GET', 'isArray': true}
      })
    })
    .factory('Tour', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/user_tour/:page', {}, {
        get: {'method': 'GET'}
      })
    })
    .factory('errorService', function () {
      var errorObject = null;
      return {
        setLastError: function (error) {
          errorObject = error;
        },
        getLastError: function () {
          return errorObject;
        }
      }
    })
    .factory('Reasons', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/reasons/:accountTypeId/:method', {}, {
        load: {method: 'GET'}
      });
    })
    .factory('AccountRegister', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/register_account/context.json', {}, {
        get: {'method': 'GET'}
      })
    })
    .factory('AccountRegisterInfo', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/register_account/load', {}, {
        load: {'method': 'GET'}
      })
    })
    .factory('BillingInfo', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/billing_information/load', {}, {
        load: {'method': 'GET'}
      })
    })
    .factory('BillingInfoContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/billing_information/context.json', {}, {
        get: {'method': 'GET'}
      })
    })
    .factory('languageService',
      function ($translate, tmhDynamicLocale, $q, amMoment) {
        return {
          setLanguage: function (languageTag) {
            var tag = languageTag.toLowerCase();
            return $q.all([
              $translate.use(tag),
              tmhDynamicLocale.set(tag),
              amMoment.changeLocale(tag)
            ]).then(function () {
              i18n.addResources(tag, 'translation', $translate.getTranslationTable(tag));
              i18n.changeLanguage(tag);
            })
          }
        }
      }
    )
    .factory('Testing', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/control_room/testing/onboarding', {}, {
        load: {method: 'GET'},
        save: {method: 'POST'}
      });
    })
    .service('printerStrategy', function () {
      var self = this;
      self.preSave = function (printer) {
        printer.tcpPort = 9100;
        printer.udpPort = 3289;
      }
    })
    .service("BrowserInfoService", function () {
      var self = this;
      self.getBrowserInfo = function () {
        return (function(){
          var ua = navigator.userAgent, tem,
            M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
          if(/trident/i.test(M[1])){
            tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE '+(tem[1] || '');
          }
          if(M[1]=== 'Chrome'){
            tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
          }
          M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
          if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
          return M.join(' ');
        })();
      };
    })
  .service('dateFormatService', function (moment) {
    var self = this;
    self.formatDate = function (date) {
      return moment(date).format('MMMM Do YYYY, h:mm')
    }
  })
})();

