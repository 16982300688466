import PNotify from 'pnotify/dist/es/PNotifyCompat';

(function () {
  angular.module('gf.utils', [])
    .factory('notifyManager', function () {
      var settings = {
        styling: 'bootstrap3',
        type: 'success',
        animation: 'none',
        maxonscreen: 2,
        history: false,
        delay: 3000,
        buttons: {
          sticker: false
        }
      };

      return {
        success: function (message) {
          $(function () {
            new PNotify(angular.extend({}, settings, {
              text: message
            }));
          });
        },
        info: function (message) {
          $(function () {
            new PNotify(angular.extend({}, settings, {
              text: message,
              type: 'info'
            }));
          });
        },
        error: function (message) {
          $(function () {
            new PNotify(angular.extend({}, settings, {
              text: message,
              type: 'error'
            }));
          });
        }

      };
    })
    .value('gridUtils', {
      jsonPromise: function ($q, jsonResource, params) {

        if (!params) {
          params = {};
        }

        var deferred = $q.defer();

        if (jsonResource) {
          jsonResource.get(params, function (data) {
            deferred.resolve(data);
          });
        } else {
          deferred.reject();
        }

        return deferred.promise;
      }
    })
    .value('keyCode', {
      BACKSPACE: 8,
      COMMA: 188,
      DELETE: 46,
      DOWN: 40,
      END: 35,
      ENTER: 13,
      ESCAPE: 27,
      HOME: 36,
      LEFT: 37,
      NUMPAD_ADD: 107,
      NUMPAD_DECIMAL: 110,
      NUMPAD_DIVIDE: 111,
      NUMPAD_ENTER: 108,
      NUMPAD_MULTIPLY: 106,
      NUMPAD_SUBTRACT: 109,
      PAGE_DOWN: 34,
      PAGE_UP: 33,
      PERIOD: 190,
      RIGHT: 39,
      SPACE: 32,
      TAB: 9,
      UP: 38
    })
    .constant('cleanPagesUrls', [
      '/login', '/error', '/password_recovery', '/password_recovery_success'
    ])
    /*Clean page is a page without header and footer*/
    .factory('isCleanPage', function ($location, cleanPagesUrls) {
      return {
        clean: function () {
          var currentUrl = $location.path();
          return cleanPagesUrls.indexOf(currentUrl) > -1;
        }
      }
    })
    /* filter intersection of two different arrays of objects */
    /* for example
    *
    *  arrayForFiltering = [{
    *   waiter : {
    *     id: 'somePropertyValue'
    *   }
    *  }, ... ]
    *
    *  arrayForCompare = [{
    *   id: 'somePropertyValue'
    *  }, ... ]
    *
    *  options = {
    *   objName: 'waiter',
    *   propName: 'id'
    *  }
    *
    * */
    .filter('filterIntersection', function ($filter) {
      return function (arrayForFiltering, arrayForCompare, options) {
        if (arrayForCompare) {
          return $filter("filter")(arrayForFiltering, function (item) {
            for (var i = 0; i < arrayForCompare.length; i++) {
              if (item[options.propName] === arrayForCompare[i][options.objName][options.propName]) return false;
            }
            return true;
          });
        }
      }
    })
    .directive(
      "mAppLoading",
      function( $animate, $timeout ) {

        // Return the directive configuration.
        return({
          link: function ( scope, element, attributes) {

            $timeout(function(){
              $animate.leave( element.children().eq( 1 ) ).then(
                function cleanupAfterAnimation() {

                  // Remove the root directive element.
                  element.remove();

                  // Clear the closed-over variable references.
                  scope = element = attributes = null;

                });
            }, 100)
          },
          restrict: "C"
        })
      })
})();
