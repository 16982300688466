import React from "react";
import LSChip, {shapes} from "../common-components/LSChip";
import {useTranslation} from "react-i18next";

const transactionStatuses = {
  declined: {
    name: 'lsp.declined',
    color: shapes.failed
  },
  failed: {
    name: 'lsp.failed',
    color: shapes.failed
  },
  pending: {
    name: 'lsp.pending',
    color: shapes.unknown
  },
  success: {
    name: 'lsp.success',
    color: shapes.success
  },
  unknown: {
    name: 'lsp.unknown',
    color: shapes.unknown
  },
}

export function getTransactionStatus(status){
  return transactionStatuses[status] || transactionStatuses.unknown;
}

function TransactionStatus({size, status}) {
  const {t} = useTranslation();

  return <LSChip size={size} type={t(status.name)} color={status.color}/>
}

export default TransactionStatus
