(function () {
  'use strict';
  angular.module('gf.bm.servicePeriod', ['gf.bm.groupView'])

    .factory('BMServicePeriodMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/service_period/metadata.json')
    })
    .factory('BMServicePeriodContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/service_period/context.json')
    })
    .factory('BMServicePeriodRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.financialService_period', {
          url: '/financial/service_period',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMFinancialServicePeriod',
          controllerAs: '$ctrl',
          resolve: {
            bmServicePeriodMetadata: function (BMServicePeriodMetadata, $stateParams, securityService) {
              return BMServicePeriodMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmServicePeriodContext: function (BMServicePeriodContext, $stateParams, securityService) {
              return BMServicePeriodContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmServicePeriodRules: function (BMServicePeriodRules, $stateParams, securityService) {
              return BMServicePeriodRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "financialServicePeriod"
        })
    })

    .controller('BMFinancialServicePeriod',
      function (bmServicePeriodMetadata, bmServicePeriodContext, securityService, $stateParams, bmServicePeriodRules) {
        var self = this;

        self.metadata = bmServicePeriodMetadata;
        self.context = bmServicePeriodContext;
        self.masterdataRules = bmServicePeriodRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name', enableCellEdit: false},
            {name: 'timeStart'},
            {name: 'timeEnd'},
            {name: 'monday'},
            {name: 'tuesday'},
            {name: 'wednesday'},
            {name: 'thursday'},
            {name: 'friday'},
            {name: 'saturday'},
            {name: 'sunday'},
            {name: 'description', enableCellEdit: false}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/service_period';
          },
          isAbleToActivate: function () {
            return securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'BM_SERVICE_PERIOD_ACTIVATE_DEACTIVATE');
          }
        };

      }
    )

})();
