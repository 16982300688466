import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  FormControl, Button, Grid, InputLabel, Select, MenuItem, TextField, Typography,
  CircularProgress
} from '@material-ui/core';
import axios from 'axios';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import DeleteConfirmationDialog from "../../react_components/components/DeleteConfirmationDialog";
import ChangePrinterWarningDialog from './components/ChangePrinterWarningDialog';
import AddPrinterDialog from "./components/AddPrinterDialog";
import {Controller, useForm} from 'react-hook-form';
import moment from 'moment';
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import GFAlert from '../../gf-react-components/alert/GFAlert';
import GFAlertTitle from '../../gf-react-components/alert/GFAlertTitle';
import {addDevice, getDevice, getStatus, updateDevice} from './components/FiscalDeviceClient';
import AdvancedSettings from './AdvancedSettings';
import GfStaticFormControl from '../../gf-react-components/form/GfStaticFormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import statuses from './TseStatuses'
import {normalizeState} from './FiscalDevice'

const useStyles = makeStyles(theme => ({
  greenText: {
    color: theme.palette.success.main,
    '& input': {
      color: theme.palette.success.main,
    }
  },
  redText: {
    color: theme.palette.error.main,
    '& input': {
      color: theme.palette.error.main,
    }
  },
  bottomSpacing: {
    marginBottom: theme.spacing(2)
  },
  buttons: {
    textAlign: 'right'
  },
  noPadding: {
    padding: '0'
  },
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center'
  },
  addPrinterSection: {
    textAlign: 'center',
    lineHeight: 3
  },
}));


function EpsonTSE({security}) {
  const {t} = useTranslation();
  const initialTse = {
    printer: {id: 0, name: ''},
    configuration: {}
  };
  const initialStatus = {
    tseInitializationState: statuses.UNINITIALIZED
  };
  const schema = yup.object().shape({
    printer: yup.object().nullable(true).required(t('validation.requiredField')),
    pin1: yup.lazy(value => {
      if (!_.isUndefined(value)) {
        return yup.string()
          .test('len', t('tse.tseFormValidation.pinLength', {number: '5'}), val => val.length === 5)
          .required(t('validation.requiredField'));
      }
      return yup.mixed().notRequired();
    }),
    pin2: yup.lazy(value => {
      if (!_.isUndefined(value)) {
        return yup.string()
          .test('len', t('tse.tseFormValidation.pinLength', {number: '5'}), val => val.length === 5)
          .required(t('validation.requiredField'));
      }
      return yup.mixed().notRequired();
    }),
    puk: yup.lazy(value => {
      if (!_.isUndefined(value)) {
        return yup.string()
          .test('len', t('tse.tseFormValidation.pukLength', {number: '6'}), val => val.length === 6)
          .required(t('validation.requiredField'));
      }
      return yup.mixed().notRequired();
    })
  });
  const classes = useStyles();
  const [tse, setTse] = useState(initialTse);
  const [tseConfig, setTseConfig] = useState(tse.configuration);
  const [tseStatus, setTseStatus] = useState(initialStatus);
  const [tsePrinters, setTsePrinters] = useState([]);
  const [editTse, setEditTse] = useState(false);
  const [loading, setLoading] = useState(true);
  const [chosenPrinter, setChosenPrinter] = useState({});
  const {enqueueSnackbar} = useSnackbar();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddPrinterDialog, setOpenAddPrinterDialog] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [noConnection, setNoConnection] = useState(false);
  const [openChangePrinterWarningDialog, setOpenChangePrinterWarningDialog] = useState(false);
  const [tsePrinterDrivers, setTsePrinterDrivers] = useState([]);
  const deleteText = t('tse.deleteText');
  const {handleSubmit, errors, setValue, control} = useForm({
    validationSchema: schema
  });
  const onSubmitTseForm = (data, event) => {
    saveTse();
  };

  //getting data for the page
  useEffect(() => {
    setIsAdmin(security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER'));
    Promise
      .all([
        getDevice(),
        getPrinters()
      ])
      .then(([tseResp, tsePrinterRes]) => {
        setTse(tseResp.data);
        setTseConfig(tseResp.data.configuration);
        setTsePrinters(tsePrinterRes.data);

        if (!!tseResp.data.id) {
          setEditTse(true);
          setChosenPrinter(tseResp.data.printer);
          getStatus(security)
            .then(resp => {
              setTseStatus(!_.isEmpty(resp.data) ? normalizeState(resp.data.tseInformation) : initialStatus);
              setLoading(false);
            }, () => {
              setTseStatus({
                tseInitializationState: statuses.NOT_DEFINED
              });
              setLoading(false);
            })
        } else {
          setLoading(false);
        }
      }, function () {
        setNoConnection(true);
        setLoading(false);
      })
  }, []);

  const handleChange = event => {
    setValue(event.target.name, event.target.value, true);
    setTse({...tse, [event.target.name]: event.target.value});
  };
  const handleConfigChange = event => {
    setValue(event.target.name, event.target.value, true);
    setTseConfig({...tseConfig, [event.target.name]: event.target.value});
  };
  useEffect(() => {
    setTse({...tse, configuration: tseConfig});
  }, [tseConfig]);

  const createTseEntity = () => {

    askForPin().then((res) => {

      const updatedTse = {...tse, configuration: {...tse.configuration, ...res.data}};

      addDevice(updatedTse)
        .then(res => {
          setTse(res.data);
          setTseConfig(res.data.configuration);
          setChosenPrinter(res.data.printer);
          setEditTse(true);
          enqueueSnackbar(t('tse.tseSaved'), {variant: 'success'});
        });
    });
  };

  const editTseEntity = () => {
    if (isAdmin) {
      askForHash(tse.configuration).then((res) => {
        const updatedTse = {...tse, configuration: {...tse.configuration, ...res.data}};
        callUpdateDevice(updatedTse);
      });
    } else {
      callUpdateDevice(tse);
    }
  };

  const callUpdateDevice = (device) => {
    updateDevice(device).then((res) => {
      setTse(res.data);
      enqueueSnackbar(t('tse.tseUpdated'), {variant: 'success'});
      setOpenChangePrinterWarningDialog(false);
    });
  };

  const saveTse = () => {
    if (editTse && (chosenPrinter.id !== tse.printer.id)) {
      setOpenChangePrinterWarningDialog(true);
    } else {
      editTse ? editTseEntity()
        : (tse.printer && !_.isEmpty(tse.printer) ? createTseEntity()
        : enqueueSnackbar(t('tse.addPrinterWarning'), {variant: 'error'}));
    }
  };

  const addPrinter = () => {
    getPrinterDrivers().then(function (res) {
      let drivers = _.filter(res.data, function (d) {
        return _.includes(d.name, 'Epson TM-m30')
      });
      setTsePrinterDrivers(drivers);
      if (drivers && drivers.length) {
        setOpenAddPrinterDialog(true);
      } else {
        enqueueSnackbar(t('tse.cannotAddTsePrinter'), {variant: 'error'});
      }
    })
  };
  const addNewPrinter = (printer) => {
    setTsePrinters([printer]);
    setTse({...tse, printer: printer});
    setOpenAddPrinterDialog(false);
    setValue('printer', printer, true);
  };
  const handleCloseAddPrinterDialog = () => {
    setOpenAddPrinterDialog(false);
  };

  const deleteTse = () => {
    setOpenDeleteDialog(true);
  };
  const handleDeleteEntity = () => {
    deleteTseEntity(tse).then((res) => {
      setEditTse(false);
      setTse(initialTse);
      setTseStatus(initialStatus);
      setOpenDeleteDialog(false);
      enqueueSnackbar(t('tse.tseDeleted'), {variant: 'success'});
    });
  };
  const handleCloseDeleteCofirmationDialog = () => {
    setOpenDeleteDialog(false)
  };

  const handleCloseChangePrinterWarningDialog = () => {
    setOpenChangePrinterWarningDialog(false);
  };

  return loading || noConnection ?
    (<div>
      {(loading && !noConnection) && <div className={classes.loaderWrapper}><CircularProgress size={48}/></div>}
      {(noConnection && !loading) && <div style={{textAlign: 'center'}}>{t('failedToConnectToServer')}</div>
      }
    </div>) :
    (<div>
      {
        !editTse &&
        <GFAlert type='warning' className={classes.bottomSpacing}>
          <GFAlertTitle align={'center'}>{t('tse.alert.attention')}</GFAlertTitle>
          {t('tse.alert.warning')}
        </GFAlert>
      }
      {
        (editTse && tseStatus.tseInitializationState === statuses.UNINITIALIZED) &&
        <GFAlert type='info' className={classes.bottomSpacing}>
          {t('tse.alert.initializeInstructions')}
        </GFAlert>
      }
      <form onSubmit={handleSubmit(onSubmitTseForm)} id='tse-form'>

        <div className={`${classes.bottomSpacing} ${classes.buttons}`}>
          {tseStatus.tseInitializationState !== statuses.SHUTDOWN &&
          <Button type="submit" color='primary' variant='contained'>{t("button.save")}</Button>
          }
          {
            openChangePrinterWarningDialog &&
            <ChangePrinterWarningDialog
              open={openChangePrinterWarningDialog}
              onSave={editTseEntity}
              handleClose={handleCloseChangePrinterWarningDialog}
              printer={tse.printer}/>
          }
          {editTse && tseStatus.tseInitializationState === statuses.SHUTDOWN &&
          <Button variant='contained' color='primary' onClick={deleteTse}>{t("button.delete")}</Button>
          }
          {
            openDeleteDialog &&
            <DeleteConfirmationDialog
              open={openDeleteDialog}
              onDelete={handleDeleteEntity}
              handleClose={handleCloseDeleteCofirmationDialog}/>
          }
        </div>
        <Grid container className={classes.root}>
          <Grid item sm={3} xs={1} implementation="css"/>
          <Grid item sm={6} xs={10}>
            <Typography
              component="h3"
              variant="h5"
              color="textSecondary"
              className={classes.bottomSpacing}
            >
              {t('tse.configuration.for', {1: t('feature.EPSON_TSE')})}
            </Typography>

            {tsePrinters.length
              ?
              <FormControl fullWidth error={!!errors.printer}>
                <InputLabel>{t('tse.tsePrinter')}*</InputLabel>
                <Controller
                  control={control}
                  name='printer'
                  id='printer'
                  defaultValue={tse.printer && tse.printer.id ? _.find(tsePrinters, {id: tse.printer.id}) : ''}
                  onChange={([e]) => {
                    handleChange(e);
                    return e;
                  }}
                  as={<Select>
                    {tsePrinters.map((printer, index) => (
                      <MenuItem key={index} value={printer}>{printer.name}</MenuItem>
                    ))}
                  </Select>}
                />
                <FormHelperText>
                  {errors.printer && errors.printer.message}
                </FormHelperText>
              </FormControl>
              :
              <div className={classes.addPrinterSection}>
                <div>{t('tse.addPrinterText')}</div>
                <Button onClick={addPrinter} color='primary' variant='contained'>{t('tse.addTsePrinter')}</Button>
              </div>
            }

            {editTse &&
            <>
              <GfStaticFormControl
                id='status'
                label={t('tse.status')}
                value={t('tse.status.' + tseStatus.tseInitializationState)}
                className={tseStatus.tseInitializationState === statuses.INITIALIZED ? classes.successText
                  : tseStatus.tseInitializationState === statuses.SHUTDOWN ? classes.errorText : ''}
              />

              {tseStatus.tseInitializationState === statuses.INITIALIZED &&
              <div>
                <FormControl fullWidth>
                  <TextField
                    id="tse_health"
                    label={t('tse.initializedOn')}
                    value={moment(tseStatus.initializedOn).format('YYYY-MM-DD, hh:mm:ss a')}
                    margin="normal"
                    readOnly
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="tse_health"
                    label={t('tse.tseHealth')}
                    value={t('tse.tseHealth.' + tseStatus.tseHealth)}
                    margin="normal"
                    readOnly
                    className={tseStatus.tseHealth === 'PASS' ? classes.greenText
                      : tseStatus.tseHealth === 'ERROR' ? classes.redText : ''}
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="certificate_expiration"
                    label={t('tse.certificateExpirationDate')}
                    value={moment(tseStatus.certificateExpirationDate).format('YYYY-MM-DD, hh:mm:ss a')}
                    margin="normal"
                    readOnly
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="tse_capacity"
                    label={t('tse.tseCapacity')}
                    value={tseStatus.tseCapacity}
                    margin="normal"
                    readOnly
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="software_version"
                    label={t('tse.softwareVersion')}
                    value={tseStatus.softwareVersion}
                    margin="normal"
                    readOnly
                    InputProps={{
                      disableUnderline: true,
                    }}
                  />
                </FormControl>
              </div>
              }
              {
                isAdmin && tseStatus.tseInitializationState !== statuses.SHUTDOWN &&
                <AdvancedSettings>

                  {tseStatus.tseInitializationState === statuses.INITIALIZED &&
                  <FormControl fullWidth>
                    <TextField
                      id="serial_number"
                      label={t('tse.serialNumber')}
                      value={tseStatus.serialNumber}
                      margin="normal"
                      readOnly
                      InputProps={{
                        disableUnderline: true,
                      }}
                    />
                  </FormControl>
                  }

                  <FormControl fullWidth>
                    <Controller
                      as={TextField}
                      control={control}
                      id="pin1"
                      name="pin1"
                      label={t('tse.pin1')}
                      defaultValue={tseConfig.pin1}
                      margin="normal"
                      InputProps={{
                        type: 'number'
                      }}
                      onChange={([e]) => {
                        handleConfigChange(e);
                        return e;
                      }}
                      error={!!errors.pin1}
                      helperText={errors.pin1 && errors.pin1.message}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <Controller
                      as={TextField}
                      control={control}
                      id="pin2"
                      name="pin2"
                      label={t('tse.pin2')}
                      defaultValue={tseConfig.pin2}
                      margin="normal"
                      onChange={([e]) => {
                        handleConfigChange(e);
                        return e;
                      }}
                      InputProps={{
                        type: 'number'
                      }}
                      error={!!errors.pin2}
                      helperText={errors.pin2 && errors.pin2.message}
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <Controller
                      as={TextField}
                      control={control}
                      id="puk"
                      name="puk"
                      label={t('tse.puk')}
                      defaultValue={tseConfig.puk}
                      margin="normal"
                      onChange={([e]) => {
                        handleConfigChange(e);
                        return e;
                      }}
                      InputProps={{
                        type: 'number'
                      }}
                      error={!!errors.puk}
                      helperText={errors.puk && errors.puk.message}
                    />
                  </FormControl>
                </AdvancedSettings>
              }
            </>
            }
          </Grid>
        </Grid>
        {editTse &&
        <div className={classes.buttons}>
          {tseStatus.tseInitializationState !== statuses.SHUTDOWN &&
          <Button type="submit" color='primary' variant='contained'>{t("button.save")}</Button>
          }
          {
            openChangePrinterWarningDialog &&
            <ChangePrinterWarningDialog
              open={openChangePrinterWarningDialog}
              onSave={editTseEntity}
              handleClose={handleCloseChangePrinterWarningDialog}
              printer={tse.printer}/>
          }
          {tseStatus.tseInitializationState === statuses.SHUTDOWN &&
          <Button variant='contained' color='primary' onClick={deleteTse}>{t("button.delete")}</Button>
          }
          {
            openDeleteDialog &&
            <DeleteConfirmationDialog
              open={openDeleteDialog}
              onDelete={handleDeleteEntity}
              handleClose={handleCloseDeleteCofirmationDialog}
              text={deleteText}
            />
          }
        </div>
        }
      </form>
      {
        openAddPrinterDialog &&
        <AddPrinterDialog
          open={openAddPrinterDialog}
          handleClose={handleCloseAddPrinterDialog}
          addNewPrinter={addNewPrinter}
          drivers={tsePrinterDrivers}
        />
      }
    </div>);
}

function askForPin() {
  return axios.get('/icash/setup/fiscal_device/epson/generate_pin');
}

function askForHash(config) {
  return axios.put('/icash/setup/fiscal_device/epson/generate_hash', config);
}

function getPrinters() {
  return axios.get('/icash/hardware/printer/tse')
}


function getPrinterDrivers() {
  return axios.get('/icash/hardware/printer/printer_driver')
}

export default EpsonTSE;
