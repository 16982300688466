(function () {
  'use strict';
  angular.module('gf.bm.discount', ['gf.bm.groupView'])

    .factory('BMDiscountMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/discount/metadata.json')
    })
    .factory('BMDiscountContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/discount/context.json')
    })
    .factory('BMDiscountRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.financialDiscount', {
          url: '/financial/discount',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMFinancialDiscount',
          controllerAs: '$ctrl',
          resolve: {
            bmDiscountMetadata: function (BMDiscountMetadata, $stateParams, securityService) {
              return BMDiscountMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmDiscountContext: function (BMDiscountContext, $stateParams, securityService) {
              return BMDiscountContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmDiscountRules: function (BMDiscountRules, $stateParams, securityService) {
              return BMDiscountRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "financialDiscount"
        })
    })

    .controller('BMFinancialDiscount',
      function (bmDiscountMetadata, bmDiscountContext, securityService, $stateParams, bmDiscountRules) {
        var self = this;

        self.metadata = bmDiscountMetadata;
        self.context = bmDiscountContext;
        self.masterdataRules = bmDiscountRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name', enableCellEdit: false},
            {name: 'value', enableCellEdit: false},
            {name: 'absolute', enableCellEdit: false},
            {name: 'checkDiscountable', enableCellEdit: false}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/discount';
          }
        };

      }
    )

})();
