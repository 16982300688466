import axios from 'axios';

function getDevice() {
  return axios.get('/icash/setup/fiscal_device.json')
}

function getCloudTSEDevice() {
  return axios.get('/icash/setup/fiscal_device/cloud/config.json')
}

function addDevice(device) {
  return axios.post('/icash/setup/fiscal_device', device)
}

function updateDevice(device) {
  return axios.put('/icash/setup/fiscal_device/' + device.id, device)
}

function deleteDevice(device) {
  return axios.delete('/icash/setup/fiscal_device/' + device.id + '.json')
}

function getStatus(security) {
  return axios.get('/icash/internal/status/pos', {
      headers: {
        'X-Business-Units': security.context.currentRestaurant.id
      }
    }
  )
}

function disableCloudTSS() {
  return axios.post('/icash/tse/cloud/disable');
}

function initCloudTSE() {
  return axios.post('/icash/tse/cloud/init');
}

function getCloudTSEConfig() {
  return axios.get('/icash/tse/cloud/config')
}

function getCloudTSEJob(url) {
  return axios.get(url || '/icash/tse/cloud/queue/jobs');
}

export {getDevice, addDevice, updateDevice, getStatus, disableCloudTSS, initCloudTSE, getCloudTSEConfig, getCloudTSEJob};
