import gastrofixTseImg from './../../../main/angular/assets/images/tse/tse.jpg'
import shoreTseImg from './../../../main/angular/assets/images/tse/shore_tse.png'
import sumupTseImg from './../../../main/angular/assets/images/tse/sumup_tse.png'

const resolveTSEImage = () => {
  switch (THEME) {
    case 'default':
      return gastrofixTseImg
    case 'shore':
      return shoreTseImg
    case 'culinaro':
      return gastrofixTseImg
    case 'sumup':
      return sumupTseImg
    default:
      return
  }
}

export default resolveTSEImage();
