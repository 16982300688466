(function () {
  'use strict';
  angular.module('gastrofix')

    .config(function ($httpProvider) {

      $httpProvider.interceptors.push(
        function ($q, $location, $injector) {
          return {
            'request': function (config) {
              if (config.url && config.url.indexOf('/icash') === 0) {
                config.headers['X-Requested-With'] = 'XMLHttpRequest';
              }

              return config;
            },

            'responseError': function (rejection) {
              var status = rejection.status;

              if ([401, 403].indexOf(status) !== -1) {
                var securityService = $injector.get('securityService');
                securityService.refreshContext()
                  .then(function () {
                    $location.path('/login');
                  });
              }

              // otherwise
              return $q.reject(rejection);
            }
          }
        });
    })

    .run(function ($rootScope, securityService, $location, $translate, $state, errorService, appConfig) {

      $rootScope.$on("$stateChangeStart", function (event, toState, toParams) {

        //todo check pages available only for authorized users and redirect to login if the user isn't authorized
        if (!securityService.context.currentUser && toState.name.indexOf('page.') !== -1) {
          $location.path('/login');
          return;
        }

        // if a user doesn't have access to url, he is redirected to the first allowed url
        if (toState.url !== '/welcome') {
          var toUrl = $state.href(toState.name, toParams).replace('#', '');
          if (!securityService.hasAccess(toUrl)) {
            event.preventDefault();
            $rootScope.$applyAsync(function () {
              $location.path(securityService.availablePath(appConfig.defaultPage));
            });
          }
        }
      });

      $rootScope.$on("$stateChangeSuccess", function () {
        //Change page title, based on Route information
        $translate($state.current.title).then(function (translation) {
          $rootScope.title = translation;
        });

        if (securityService.context.currentUser && $location.path() === '/login') {
          $location.path(securityService.availablePath(appConfig.defaultPage));
        }
      });

      $rootScope.$on("$stateChangeError", function (event, toState, toParams, fromState, fromParams, error) {
        if (error.status === 401) {
          securityService.refreshContext().then(function () {
            if (securityService.context.currentUser) {
              $state.reload();
            } else {
              $state.go("clean.login");
            }
          });
          return;
        }
        errorService.setLastError(error);
        $state.go("clean.error");
      });

    })

})();


