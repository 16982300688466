(function() {
  'use strict';
  angular.module('gf.financial.services', ['ui.router'])

  .config(function($stateProvider) {
    $stateProvider
      .state('page.financialServices', {
        url: '/financialServices',
        template: require('../financial_services.html'),
        controller: 'FinancialServices',
        title: 'financial.services'
      })
  })
  .controller('FinancialServices', function($scope) {
    console.log('In Scope of Financial Services')
  })

})();