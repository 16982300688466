import {Box, Divider, Grid, Link, Paper} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import {getTransactionType} from "./TransactionType";
import {useTranslation} from "react-i18next";
import BreakdownElement from "./BreakdownElement";
import Loader from "../common-components/Loader";
import Currency from "./Currency";
import TransactionStatus, {getTransactionStatus} from "./TransactionStatus";
import {getEntryMethod} from "./EntryMethod";
import {getAlertMessage} from "./Alert";
import {getCardType} from "./Card";
import {Alert, AlertTitle} from "@material-ui/lab";

function TransactionDetails({id, onGoBack, backText, goToBill}) {
  const {t} = useTranslation();

  const [data, setData] = useState({});
  const [metadata, setMetadata] = useState({});
  const [loading, setLoading] = useState({});
  const [isError, setIsError] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setLoading(true);
    axios.post('/icash/ls_payments/transactions/' + id, {
      include: ['transactionMetadata'],
    }).then(resp => {
      setData(normalizeData(resp.data.data));
      var transactionMetadata = getTransactionMetadata(resp.data.included);
      setMetadata(transactionMetadata);
      setIsError(false);
      setLoading(false);
    }, error => {
      setIsError(true);
      var message = getAlertMessage(error);
      setErrorMessage(t(message));
      setLoading(false);
    });
  }, [id]);

  return <>
    {loading
      ? <Loader/>
      :
      <Grid container spacing={4} direction={'column'}>
        <Grid item>
          <Button
            onClick={() => onGoBack()}
            startIcon={<ArrowBackIosIcon/>}>Back to {backText || "payment transactions"}</Button>
        </Grid>
        {isError
          ?
          <Grid item>
            <Alert severity="error">
              <AlertTitle>{t('validation.title.error')}</AlertTitle>
              {errorMessage}
            </Alert>
          </Grid>
          : <>
            <Grid item>
              <Typography variant="h6">
                <Box display="inline">
                  {t('lsp.transaction')} {data.id}
                </Box>
                <Box display="inline" p={1}>
                  <TransactionStatus status={getTransactionStatus(data.status)}/>
                </Box>
              </Typography>
              <Typography variant="subtitle2">
                {t('lsp.fullBreakdown')}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="h6" gutterBottom>
                    {t('lsp.transactionDetails.summary')}
                  </Typography>
                  <Typography variant="subtitle2">
                    {t('lsp.transactionDetails.summary.details')}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container direction={'column'} spacing={3}>
                    <Grid item>
                      <Paper variant={'outlined'} elevation={0}>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Grid container direction={'column'} spacing={3}>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.paymentType'}
                                    value={t('lsp.paymentType')}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement field={'lsp.amountGross'}
                                                    value={<Currency value={data.amountGross}
                                                                     currency={data.currency}/>}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement field={'lsp.date'}
                                                    value={moment(data.transactionDate).format('lll')}/>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Divider orientation={'vertical'} flexItem
                                   style={{marginRight: "-1px"}}/>
                          <Grid item xs={8}>
                            <Box p={2}>
                              <Grid container direction={'column'} spacing={3}>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.payoutId'}
                                    value={data.payoutId}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.receiptId'}
                                    value={
                                      <Link component="button" style={{overflow: 'hidden'}}
                                            onClick={() => {
                                              goToBill(metadata.invoiceNumber)
                                            }}>
                                        {metadata.invoiceNumber}
                                      </Link>
                                    }/>
                                </Grid>

                                {/* since these fields are not provided at the moment, we decided to hide them */}
                                {/*<Grid item>*/}
                                {/*  <BreakdownElement*/}
                                {/*    field={'lsp.transactionDetails.account'}*/}
                                {/*    value={'N/A'}/>*/}
                                {/*</Grid>*/}
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.device'}
                                    value={metadata.deviceName}/>
                                </Grid>
                                {/*<Grid item>*/}
                                {/*  <BreakdownElement*/}
                                {/*    field={'lsp.transactionDetails.customer'}*/}
                                {/*    value={'N/A'}/>*/}
                                {/*</Grid>*/}
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.employee'}
                                    value={metadata.user}/>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider/>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="h6" gutterBottom>
                    {t('lsp.transactionDetails.details')}
                  </Typography>
                  <Typography variant="subtitle2">
                    {t('lsp.transactionDetails.details.details')}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container direction={'column'} spacing={3}>
                    <Grid item>
                      <Paper variant={'outlined'} elevation={0}>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Grid container direction={'column'} spacing={3}>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.transactionType'}
                                    value={t(getTransactionType(data.type).name)}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement field={'lsp.status'}
                                                    value={t(getTransactionStatus(data.status).name)}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.amountAuthorized'}
                                    value={<Currency value={data.amountRequested} currency={data.currency}/>}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.transactionFee'}
                                    value={<Currency value={data.amountFees} currency={data.currency}/>}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.amountNet'}
                                    value={<Currency value={data.amountNet} currency={data.currency}/>}/>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Divider orientation={'vertical'} flexItem
                                   style={{marginRight: "-1px"}}/>
                          <Grid item xs={8}>
                            <Box p={2}>
                              <Grid container direction={'column'} spacing={3}>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.cardBrand'}
                                    value={getCardType(data.cardType).name}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.maskedPAN'}
                                    value={'**** **** **** '
                                    + data.cardLastFourDigits}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.entryMethod'}
                                    value={t(getEntryMethod(data.entryMethod))}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.currency'}
                                    value={data.currency}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.processorReference'}
                                    value={data.processorReference}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.transactionDetails.transactionLSID'}
                                    value={data.id}/>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>}
      </Grid>
    }
  </>
}

export default TransactionDetails;

function normalizeData(data) {
  return {
    id: getField(data.id),
    amountFees: data.attributes.amountFees,
    amountGratuity: data.attributes.amountGratuity,
    amountGross: data.attributes.amountGross,
    amountNet: data.attributes.amountNet,
    amountRequested: data.attributes.amountRequested,
    cardLastFourDigits: getField(data.attributes.cardLastFourDigits),
    cardType: getField(data.attributes.cardType),
    currency: getField(data.attributes.currency),
    entryMethod: getField(data.attributes.entryMethod),
    invoiceNumber: getField(data.attributes.invoiceNumber),
    payoutId: getField(data.attributes.payoutId),
    processorReference: getField(data.attributes.processorReference),
    status: getField(data.attributes.status),
    transactionDate: getField(data.attributes.transactionDate),
    type: getField(data.attributes.type),
  }
}

function getTransactionMetadata(included) {
  return normalizeTransactionMetadata(_.find(included, i => i.type === "transaction_metadata"));
}

function normalizeTransactionMetadata(metadata) {
  return {
    deviceName: getField(metadata?.attributes?.deviceName),
    user: getField(metadata?.attributes?.user),
    invoiceNumber: getField(metadata?.attributes?.hrOrderId)
  }
}

function getField(field) {
  return field || 'N/A';
}
