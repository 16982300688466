export function getAlertMessage(error){
  var resp = error.response;
  var data = resp.data;
  var errors = data?.errors;

  var messages = [];
  errors && Object.values(errors).map(error => {
    messages.push(error.detail);
  })

  return messages.join('. ') || data.term || resp.statusText;
}
