(function () {
  'use strict';
  angular.module('gf.branchManagement',
    ['gf.bm.groupManagement', 'gf.bm.articleTemplate', 'gf.bm.groupView', 'gf.bm.article', 'gf.bm.rules.grid', 'gf.bm.articleGroup',
      'gf.bm.priceLevel', 'gf.bm.course', 'gf.bm.orderScreen', 'gf.bm.articleType', 'gf.bm.printer', 'gf.bm.ticketLayout',
    'gf.bm.vat', 'gf.bm.account', 'gf.bm.discount', 'gf.bm.costCenter', 'gf.bm.servicePeriod', 'gf.bm.waiter', 'gf.bm.waiterGroup',
    'gf.bm.settlementForm', 'gf.bm.report', 'gf.bm.userPermissions', 'gf.bm.dashboard', 'gf.config.options'])

    .run(function (configOptionsService, securityService) {

      var isBranchManagement = securityService.hasAnyFeature('BRANCH_MANAGEMENT');
      var isMaster = !!securityService.context.masterUnit &&
        securityService.context.masterUnit.id === securityService.context.currentRestaurant.id;

      configOptionsService.get('articleActivateButton')       .disableWhen(isBranchManagement);
      configOptionsService.get('printerActivateButton')       .disableWhen(isBranchManagement);
      configOptionsService.get('servicePeriodActivateButton') .disableWhen(isBranchManagement);
      configOptionsService.get('priceLevelActivateButton')    .disableWhen(isBranchManagement);

      configOptionsService.get('articleGroupDeleteButton')    .disableWhen(isBranchManagement);
      configOptionsService.get('courseGroupDeleteButton')     .disableWhen(isBranchManagement);
      configOptionsService.get('orderScreenDeleteButton')     .disableWhen(isBranchManagement);
      configOptionsService.get('waiterDeleteButton')          .disableWhen(isBranchManagement);
      configOptionsService.get('articleTypeDeleteButton')     .disableWhen(isBranchManagement);
      configOptionsService.get('waiterGroupDeleteButton')     .disableWhen(isBranchManagement);
      configOptionsService.get('printerDeleteButton')         .disableWhen(isBranchManagement);
      configOptionsService.get('ticketLayoutDeleteButton')    .disableWhen(isBranchManagement);
      configOptionsService.get('settlementFormDeleteButton')  .disableWhen(isBranchManagement);
      configOptionsService.get('vatDeleteButton')             .disableWhen(isBranchManagement);
      configOptionsService.get('accountDeleteButton')         .disableWhen(isBranchManagement);
      configOptionsService.get('costCenterDeleteButton')      .disableWhen(isBranchManagement);
      configOptionsService.get('discountDeleteButton')        .disableWhen(isBranchManagement);
      configOptionsService.get('priceLevelDeleteButton')      .disableWhen(isBranchManagement);
      configOptionsService.get('servicePeriodDeleteButton')   .disableWhen(isBranchManagement);

    })

    .run(function ($rootScope, securityService) {

      var body = angular.element(document.querySelector('body'));

      $rootScope.$watch('security.features', function () {
        securityService.hasAnyFeature('BRANCH_MANAGEMENT') ? body.addClass('gf-bm-unit') : body.removeClass('gf-bm-unit')
      });

      $rootScope.$on('$stateChangeStart', function (event, toState) {
        toState.name.indexOf('bm.') === 0 ? body.addClass('gf-bm-group') : body.removeClass('gf-bm-group');
      });
    })
})();
