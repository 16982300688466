(function() {
  'use strict';
  angular.module('gf.financial.vat', ['ui.router'])

    .factory('VatMetadata', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/vat/metadata.json')
    })
    .factory('VatContext', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/vat/context.json')
    })
    .factory('Vat', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/vat/:vatId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })

    .config(function($stateProvider) {

      $stateProvider
        .state('page.financialVat', {
          url: '/financial/vat',
          template: require('../template/page/financial/vats.html'),
          controller: 'VatGridCtrl',
          controllerAs: 'vatGridCtrl',
          resolve: {
            vatMetadata: function(VatMetadata) {
              return VatMetadata.get().$promise;
            },
            vatContext: function(VatContext) {
              return VatContext.get().$promise;
            }
          },
          title: "vat"
        })

        .state('page.financialVatAdd', {
          url: '/financial/vat/add',
          template: require('../template/page/financial/vat.html'),
          controller: 'VatAddCtrl',
          controllerAs: 'vatCtrl',
          resolve: {
            vatMetadata: function(VatMetadata) {
              return VatMetadata.get().$promise;
            },
            vatContext: function(VatContext) {
              return VatContext.get().$promise;
            },
            vat: function(Vat, $stateParams) {
              return Vat.load({vatId: $stateParams.id || 'add'}).$promise;
            }
          },
          title: 'vat'
        })

        .state('page.financialVatDetails', {
          url: '/financial/vat/:id',
          template: require('../template/page/financial/vat.html'),
          controller: 'VatEditCtrl',
          controllerAs: 'vatCtrl',
          resolve: {
            vatMetadata: function(VatMetadata) {
              return VatMetadata.get().$promise;
            },
            vatContext: function(VatContext) {
              return VatContext.get().$promise;
            },
            vat: function(Vat, $stateParams) {
              return Vat.load({vatId: $stateParams.id || 'add'}).$promise;
            }
          },
          title: 'vat'
        })
    })

    .factory('VatAdd',
      function () {
        return function (self, $scope, $location, $http, $translate, $state, notifyManager, vat, vatContext, vatMetadata) {
          angular.extend(self, vatContext.data.dependencies);
          self.vatMetadata = vatMetadata;
          self.vat = vat;

          self.singlePurposeVoucherMode = vatContext.data.singlePurposeVoucherMode;
          self.singlePurposeVoucherModeEditId = vatContext.data.singlePurposeVoucherModeEditId;

          self.save = function () {
            if (self.vatForm.$valid) {
              self.vat.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go('page.financialVat')
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          self.saveAndNew = function () {
            if (self.vatForm.$valid) {
              self.vat.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
        }
      })
    .controller('VatAddCtrl',

      function ($scope, $location, $http, $translate, $state, notifyManager, vat, vatContext, vatMetadata, VatAdd) {
        var self = this;
        VatAdd(self, $scope, $location, $http, $translate, $state, notifyManager, vat, vatContext, vatMetadata);
      }
    )
    .controller('VatEditCtrl',

      function ($scope, $location, $http, $translate, $state, notifyManager, vat, vatContext, vatMetadata, VatAdd) {
        var self = this;
        VatAdd(self, $scope, $location, $http, $translate, $state, notifyManager, vat, vatContext, vatMetadata);

        self.save = function () {
          if (self.vatForm.$valid) {
            self.vat.$edit({vatId: self.vat.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.financialVat')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        self.saveAndNew = function () {
          if (self.vatForm.$valid) {
            self.vat.$edit({vatId: self.vat.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.financialVatAdd')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

      }
    )
    .controller('VatGridCtrl',

      function ($scope, $translate, vatContext, vatMetadata, securityService, configOptionsService) {
        var self = this;
        self.metadata = vatMetadata;
        self.context = vatContext;

        var fiscalRegulationActive = !!(securityService.context.fiscalRegulation && securityService.context.fiscalRegulation.limitVatNumber);
        var singlePurposeVoucherMode = self.context.data.singlePurposeVoucherMode;

        var cellClass = function (grid, row, col, rowRenderIndex, colRenderIndex) {
          if (row && row.entity && row.entity.id && row.entity.hidden) {
            return 'hidden-entity';
          }
        };

        self.config = {
          urlRoute: '/financial/vat',
          enableSorting: true,
          enableAdd: true,
          canAdd: function (grid) {
            if (!fiscalRegulationActive) {
              return true;
            } else {
              return grid.options.data.length < 4;
            }
          },
          enableFiltering: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          cellEditableCondition: function($scope) {
            return !$scope.row.entity.hidden;
          },
          hideActions: function(entity) {
            return entity.hidden;
          },
          columns: [
            {
              name: (!fiscalRegulationActive ? 'id' : 'fiscalRegulationId'),
              displayName: 'discount.id',
              width: 60,
              enableCellEdit: false,
              visible: fiscalRegulationActive,
              cellClass: cellClass
            },
            {name: 'name', cellClass: cellClass},
            {name: 'value',
              width: 100,
              cellClass: cellClass,
              cellTemplate: "<div uib-tooltip=\"{{grid.appScope.context.data.singlePurposeVoucherMode ? ('vat.grid.value.SinglePurposeVoucher.tooltip' | translate) : ''}}\" tooltip-append-to-body=\"false\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD CUSTOM_FILTERS}}</div>",
              enableCellEdit: !singlePurposeVoucherMode
            },
            {
              name: 'mark', width: 100, cellClass: cellClass,
              validations: {
                'gf-uniquevatmark': {
                  'angular': {'gf-uniquevatmark': ''},
                  'errorMessage': 'de.icash.validator.UniqueVatMark.message'
                }
              }
            },
            {name: 'vatAccount', width: 100, visible: false, cellClass: cellClass},
            {name: 'account', width: 100, visible: false, cellClass: cellClass},
            {name: 'description', width: 200, visible: false, cellClass: cellClass},
            {
              name: 'operate',
              actions: ['edit', 'delete'],
              actionOptions: {
                'delete': {
                  isDisabled: configOptionsService.get('vatDeleteButton').isDisabled
                }
              }
            }
          ]
        };
      }
    )
})();
