(function() {
  'use strict';
  angular.module('gf.operator.data.restaurantGroup', ['ui.router'])

    .factory('RestaurantGroupMetadata', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant_group/metadata.json')
    })
    .factory('RestaurantGroupContext', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant_group/context.json')
    })
    .factory('RestaurantGroup', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant_group/:restaurantGroupId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('RestaurantMetadata', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/metadata.json')
    })

    .config(function($stateProvider) {

      $stateProvider
        .state('page.operator_dataRestaurant_group', {
          url: '/operator_data/restaurant_group',
          template: require('../template/operator-data/restaurant-group/restaurant_groups.html'),
          controller: 'RestaurantGroupGridCtrl',
          controllerAs: 'restaurantGroupGridCtrl',
          resolve: {
            restaurantGroupMetadata: function (RestaurantGroupMetadata) {
              return RestaurantGroupMetadata.get().$promise;
            },
            restaurantGroupContext: function (RestaurantGroupContext) {
              return RestaurantGroupContext.get().$promise;
            }
          },
          title: "restaurantGroup"
        })

        .state('page.operator_dataRestaurant_groupAdd', {
          url: '/operator_data/restaurant_group/add',
          template: require('../template/operator-data/restaurant-group/restaurant_group.html'),
          controller: 'RestaurantGroupAddCtrl',
          controllerAs: 'restaurantGroupCtrl',
          resolve: {
            restaurantGroupMetadata: function (RestaurantGroupMetadata) {
              return RestaurantGroupMetadata.get().$promise;
            },
            restaurantGroupContext: function (RestaurantGroupContext) {
              return RestaurantGroupContext.get().$promise;
            },
            restaurantGroup: function (RestaurantGroup, $stateParams) {
              return RestaurantGroup.load({restaurantGroupId: $stateParams.id || 'add'}).$promise;
            }
          },
          title: "restaurantGroup"
        })

        .state('page.operator_dataRestaurant_groupDetails', {
          url: '/operator_data/restaurant_group/:id',
          template: require('../template/operator-data/restaurant-group/restaurant_group.html'),
          controller: 'RestaurantGroupEditCtrl',
          controllerAs: 'restaurantGroupCtrl',
          resolve: {
            restaurantGroupMetadata: function (RestaurantGroupMetadata) {
              return RestaurantGroupMetadata.get().$promise;
            },
            restaurantGroupContext: function (RestaurantGroupContext) {
              return RestaurantGroupContext.get().$promise;
            },
            restaurantGroup: function (RestaurantGroup, $stateParams) {
              return RestaurantGroup.load({restaurantGroupId: $stateParams.id || 'add'}).$promise;
            }
          },
          title: 'restaurantGroup'
        })
    })

.factory('RestaurantGroupAdd', [
  function () {
    return function (self, $scope, $state, $translate, RestaurantGroup, notifyManager, restaurantGroup, restaurantGroupContext, restaurantGroupMetadata) {
      self.restaurantGroupContext = restaurantGroupContext;
      self.restaurantGroupMetadata = restaurantGroupMetadata;
      self.restaurantGroup = restaurantGroup;

      self.operatorData = true;

      angular.extend(self, restaurantGroupContext.data.dependencies);

      self.restaurantIds = {};
      angular.forEach(self.restaurants, function (value) {
        this[value.id] = false;
      }, self.restaurantIds);
      angular.forEach(self.restaurantGroup.restaurants, function (value) {
        this[value.id] = true;
      }, self.restaurantIds);

      self.restaurantsMap = {};
      angular.forEach(self.restaurants, function (restaurant, index) {
        this[restaurant.id] = restaurant;
      }, self.restaurantsMap);

      $scope.$watch(function () {
        return angular.toJson(self.restaurantIds);
      }, function () {
        self.restaurantGroup.restaurants = [];
        angular.forEach(self.restaurantIds, function (value, key) {
          if (value) {
            this.push(self.restaurantsMap[key]);
          }
        }, self.restaurantGroup.restaurants);
      });

      self.checkAll = false;
      self.columnCheckboxChanged = function () {
        angular.forEach(self.restaurantIds, function (v, k) {
          self.restaurantIds[k] = self.checkAll;
        });
      };

      self.save = function () {
        if (self.restaurantGroupForm.$valid && self.restaurantGroup.restaurants.length) {
          self.restaurantGroup.$save(function (data) {
            notifyManager.success($translate.instant('entity.save.successfully'));
            $state.go('page.operator_dataRestaurant_group');
          });
        } else {
          $scope.$broadcast('gf-submitted');
        }
      };
    }
  }
])
  .controller('RestaurantGroupAddCtrl',

    function ($scope, $state, $translate, RestaurantGroup, notifyManager, restaurantGroup, restaurantGroupContext, restaurantGroupMetadata, RestaurantGroupAdd) {
      var self = this;
      RestaurantGroupAdd(self, $scope, $state, $translate, RestaurantGroup, notifyManager, restaurantGroup, restaurantGroupContext, restaurantGroupMetadata);
    }
  )
  .controller('RestaurantGroupEditCtrl',

    function ($scope, $state, $translate, RestaurantGroup, notifyManager, restaurantGroup, restaurantGroupContext, restaurantGroupMetadata, RestaurantGroupAdd) {
      var self = this;
      RestaurantGroupAdd(self, $scope, $state, $translate, RestaurantGroup, notifyManager, restaurantGroup, restaurantGroupContext, restaurantGroupMetadata);

      self.save = function () {
        if (self.restaurantGroupForm.$valid && self.restaurantGroup.restaurants.length) {
          self.restaurantGroup.$edit({restaurantGroupId: self.restaurantGroup.id}, function (data) {
            notifyManager.success($translate.instant('entity.save.successfully'));
            $state.go('page.operator_dataRestaurant_group');
          });
        } else {
          $scope.$broadcast('gf-submitted');
        }
      };
    }
  )
  .controller('RestaurantGroupGridCtrl',

    function (restaurantGroupContext, restaurantGroupMetadata) {
      var self = this;
      self.metadata = restaurantGroupMetadata;
      self.context = restaurantGroupContext;

      self.config = {
        urlRoute: '/operator_data/restaurant_group',
        enableAdd: true,
        enableColumnMoving: true,
        enableColumnResizing: true,
        enableColumnsHiding: true,
        columns: [
          {name: 'name'},
          {name: 'description'},
          {name: 'restaurantCount', width: 150, enableCellEdit: false},
          {name: 'operate', actions: ['edit', 'delete']}
        ]
      };
    }
  )

})();
