import _ from 'lodash'

(function () {
  angular.module('gf.i18n', [])
  /**
   * Set text of element by eval content of directive.
   *
   * <button ng-text="'button.close' | translate + ' ' + 'restaurant' | translate" >Close Restaurant</button>
   */
    .directive('ngText',
      function () {
        return {
          restrict: "A",
          link: function ($scope, element, attrs) {
            element.text($scope.$eval(attrs.ngText));
          }
        };
      })
    /**
     * Set text of element by i18n key.
     *
     * <button gf-text="button.close">Close</button>
     */
    .directive('gfText',
      function ($translate) {
        return {
          restrict: "A",
          link: function ($scope, element, attrs) {
            element.text($translate.instant(attrs.gfText));
          }
        };
      })
    /**
     * Set placeholder of element by i18n key.
     *
     * <input gf-placeholder="article.name" placeholder="Close"/>
     */
    .directive('gfPlaceholder',
      function ($translate) {
        return {
          restrict: "A",
          link: function ($scope, element, attrs) {
            element.attr("placeholder", $translate.instant(attrs.gfPlaceholder));
          }
        };
      })
    /**
     * Set title of element by i18n key.
     *
     * <button gf-title="button.close" title="Close">x</button>
     */
    .directive('gfTitle',
      function ($translate) {
        return {
          restrict: "A",
          link: function ($scope, element, attrs) {
            element.attr("title", $translate.instant(attrs.gfTitle));
          }
        };
      })
  ;

  var URLS = {
    'UniqueUsername': '/user/:userId/:type/username/:username',
    'UniqueOperatorCode': '/operator/operator/:operatorId/customerCode/:customerCode',
    'UniqueOperatorDeviceUDID': '/operator/device/udid/:deviceId/:restaurantId/:udid',
    'UniqueOperatorDeviceUniqueIP': '/operator/device/ip_address/:deviceId/:restaurantId',
    'SwitchRestaurantAction': '/operator/restaurant/open/:restaurantId',
    'DeviceSwitchRestaurantAction': '/operator/device/open/:restaurantId',
    'OperatorImportSendFile': '/operator/import',
    'OperatorImportCustomerSendFile': '/operator/import/customer',
    'CustomerPhotoSendFile': '/customer/:customerId/photo',
    'OrderScreenValidate': '/masterdata/order_screen/:orderScreenId/:deviceScreenSizeId/:courseGroupId/validate',
    'UniqueWaiterLoginPin': '/masterdata/waiter/:waiterId/login_pin/:loginPin',
    'UniqueWaiterNumber': '/masterdata/waiter/:waiterId/personalNumber/:personalNumber',
    'UniqueVatMark': '/financial/vat/:id/mark/:mark',
    'UniqueVaiFiscalId': '/financial/vat/:id/fiscal/:fiscalId',
    'ArticleDeleteGroup': '/masterdata/article/deleteGroup',
    'ArticleUpdateGroup': '/masterdata/article/updateGroup',
    'ArticleValidConstraintParents': '/masterdata/article/valid_constraint_parents.json',
    'UniqueArticleSKU': '/masterdata/article/0/sku/:sku',
    'UniqueArticlePLU': '/masterdata/article/0/plu/:plu',
    'UniqueTablePLU': '/restaurant_data/table/0/plu/:plu',
    'UniqueArticleGroupPLU': '/masterdata/article_group/0/plu/:plu',
    'DownloadSVGRoom': '/restaurant_data/room/:roomId/download',
    'UniqueOperatorUsername': '/operator_data/user/:userId/username/:username',
    'UniqueOperatorUserMail': '/operator_data/user/:userId/mail?email=:mail',
    'SwitchUser': '/login/impersonate',
    'ExitUser': '/logout/impersonate',
    'DuplicateFieldsValidateUrl': '/operator_data/trash/:domain/validate',
    'ArticleDeactivateGroup': '/masterdata/article/deactivateGroup',
    'UniqueDeviceUDID': '/hardware/device/:deviceId/udid/:udid',
    'UniqueDeviceUniqueIP': '/hardware/device/:deviceId/ip_address',
    'UniqueReasonCategory': '/financial/reasons/:accountTypeId/:id/category/:category',
    'SinglePurposeVoucherCheck': '/financial/settlement_form/single_purpose_voucher_possible/:settlementFormId/validate',
    'CashPaymentTypeCheck': '/financial/settlement_form/cash_type/:settlementFormId/validate',
    'WaterValidateBalance': '/masterdata/waiter/:waiterId/validate_balance'
  };

  var RESOURCES = {
    /*'Login': '/login',*/
    'DashboardContext': '/dashboard/edit/context.json',

    'CustomerMetadata': '/customer/customer/metadata.json',
    'CustomerContext': '/customer/customer/context.json',

    'CustomerGroupMetadata': '/customer/customer_group/metadata.json',
    'CustomerGroupContext': '/customer/customer_group/context.json',

    'CustomerDiscountMetadata': '/customer/discount/metadata.json',
    'CustomerDiscountContext': '/customer/discount/context.json',

    'CustomerHistory': '/customer/customer/:customerId/history',

    'YoviteMetadata': '/customer/yovite/metadata.json',
    'YoviteContext': '/customer/yovite/context.json',

    'ArticleMetadata': '/masterdata/article/metadata.json',
    'ArticleContext': '/masterdata/article/context.json',
    'ArticleStockMetadata': '/masterdata/inventory/metadata.json',
    'ArticleStockContext': '/masterdata/inventory/context.json',
    'ArticleChangeId': '/masterdata/article/change_id',

    'ArticleConstraintCopyTypeahead': '/masterdata/article/article_constraint_copy/typeahead_article.json',

    'ArticleGroupUniqueId': '/masterdata/article_group/unique_id',
    'UniqueForeignCurrency': '/financial/settlement_form/unique_currency',

    'OrderScreenMetadata': '/masterdata/order_screen/metadata.json',
    'OrderScreenContext': '/masterdata/order_screen/context.json',
    'OrderScreenUsedEntities': '/masterdata/order_screen/used_entities/:orderScreenId.json',

    'ReportData': '/report/:typeId.json',
    'BranchReport': '/branch/:typeId.json',

    'Corrections': "/report/corrections/:transactionType/:date.json",
    'Correction': "/report/corrections/:transactionType/:date/:id.json",

    'Message': "/report/3/:messageId.json",

    'ReportFilterMetadata': '/report/report_filter/:typeId/metadata.json',
    'ReportFilterContext': '/report/report_filter/:typeId/context.json',

    'WaiterGroupMetadata': '/restaurant_data/waiter_group/metadata.json',
    'WaiterGroupContext': '/restaurant_data/waiter_group/context.json',

    'ExchangeRateMetadata': '/restaurant_data/exchange_rate/metadata.json',
    'ExchangeRateContext': '/restaurant_data/exchange_rate/context.json',

    'TaxMetadata': '/financial/tax/metadata.json',
    'TaxContext': '/financial/tax/context.json',

    'TaxAuthorityMetadata': '/financial/tax/taxAuthority/metadata.json',
    'TaxAuthorityContext': '/financial/tax/taxAuthority/context.json',

    'TaxLocationMetadata': '/financial/tax/taxLocation/metadata.json',
    'TaxLocationContext': '/financial/tax/taxLocation/context.json',

    'TaxRulesMetadata': '/financial/tax/taxRule/metadata.json',
    'TaxRulesContext': '/financial/tax/taxRule/:taxClassId/:taxAuthorityId/context.json',

    'AccountTypes': '/financial/reasons/accountTypes.json',
    'ReasonsMetadata': '/financial/reasons/metadata.json',
    'ReasonsContext': '/financial/reasons/:accountTypeId/context.json',

    'PriceLevelMetadata': '/financial/price_level/metadata.json',
    'PriceLevelContext': '/financial/price_level/context.json',

    'DatevMetadata': '/financial/datev/metadata.json',
    'DatevContext': '/financial/datev/context.json',

    'CubeMetadata': '/financial/cube/metadata.json',
    'CubeContext': '/financial/cube/context.json',

    'AdministratorMetadata': '/admin/admin/metadata.json',
    'AdministratorContext': '/admin/admin/context.json',

    'ResellerUserMetadata': '/admin/reseller_user/metadata.json',
    'ResellerUserContext': '/admin/reseller_user/context.json',

    'LowMarketRooms': '/restaurant_data/room/lowMarketRooms.json',
    'RoomMetadata': '/restaurant_data/room/metadata.json',
    'RoomContext': '/restaurant_data/room/context.json',

    'AdminTrash': '/control_room/trash/:number/:startIndex/:domain.json',

    'OperatorSettings': '/operator_data/operator_settings/operatorSettings.json',
    'CurrentOperatorSettingsMetadata': '/operator_data/operator_settings/metadata.json',
    'CurrentOperatorSettingsContext': '/operator_data/operator_settings/context.json',

    'ControlRoom': '/operator_data/control_room/gastronomicDay.json',

    'ProfileContext': '/user/profile/context.json',
    'Profile': '/user/profile/user',
    'ProfilePassword': '/user/profile/password',

    'RestaurantFeatures': '/activeFeatures.json',

    'RestaurantGroups': '/user/groups.json',

    'PaypalDataContext': '/feature/:belongsTo/paypal/context',
    'PaypalDataMetadata': '/feature/:belongsTo/paypal/metadata',

    'ZapperGetMultiplePaymentStatusByPosReferencesResponse': '/feature/zapper/GetMultiplePaymentStatusByPosReferences',

    'FeatureMetadata': '/permission/feature/metadata.json',
    'FeatureContext': '/permission/feature/context.json',
    'FeatureAssignmentMetadata': '/permission/feature/assignment/metadata.json',
    'FeatureAssignmentContext': '/permission/feature/assignment/:countryId/context.json',

    'OperatorContractTypeMetadata': '/permission/operator_contract_type/metadata.json',
    'OperatorContractTypeContext': '/permission/operator_contract_type/context.json',

    'ThemeMetadata': '/admin/theme/metadata.json',
    'ThemeContext': '/admin/theme/context.json',

    'CurrencyMetadata': '/admin/currency/metadata.json',
    'CurrencyContext': '/admin/currency/context.json',

    'LanguageMetadata': '/admin/language/metadata.json',
    'LanguageContext': '/admin/language/context.json',

    'DeviceTypeMetadata': '/admin/device_type/metadata.json',
    'DeviceTypeContext': '/admin/device_type/context.json',

    'PrinterDriverMetadata': '/admin/printer_driver/metadata.json',
    'PrinterDriverContext': '/admin/printer_driver/context.json',

    'TicketTypeMetadata': '/admin/ticket_type/metadata.json',
    'TicketTypeContext': '/admin/ticket_type/context.json',

    'InAppMessageMetadata': '/admin/in_app_message/metadata.json',
    'InAppMessageContext': '/admin/in_app_message/context.json',

    'UserPermissionMetadata': '/permission/user/metadata.json',
    'UserPermissionContext': '/permission/user/context.json',

    'WaiterPermissionMetadata': '/permission/waiter/metadata.json',
    'WaiterPermissionContext': '/permission/waiter/context.json',

    'MessageTypeMetadata': '/permission/message/metadata.json',
    'MessageTypeContext': '/permission/message/context.json',

    'MessageSenderTypeMetadata': '/permission/sender/metadata.json',
    'MessageSenderTypeContext': '/permission/sender/context.json',

    'ReportFilterTypeMetadata': '/report_data/report_filter_type/metadata.json',
    'ReportFilterTypeContext': '/report_data/report_filter_type/context.json',

    'ReportGroupMetadata': '/report_data/report_group/metadata.json',
    'ReportGroupContext': '/report_data/report_group/context.json',

    'ReportMetadata': '/report_data/report/metadata.json',
    'ReportContext': '/report_data/report/context.json',

    'CountryMetadata': '/address_setup/country/metadata.json',
    'CountryContext': '/address_setup/country/context.json',

    'CountryRegionMetadata': '/address_setup/country_region/metadata.json',
    'CountryRegionContext': '/address_setup/country_region/context.json',

    'ControlRoomInfo': '/control_room/info.json',
    'ControlRoomReportInfo': '/control_room/report_info.json',
    'SequentialNumbers': '/control_room/sequential_numbers.json',
    'GlobalSettings': '/control_room/global_settings',
    'StoredImageSettings': '/control_room/stored_image_settings',
    'StoredImageSettingsMetadata': '/control_room/global_settings/metadata_new.json',

    'OperatorContext': '/operator/operator/context.json',
    'OperatorMetadata': '/operator/operator/metadata.json',
    'OperatorSettingsContext': '/operator/operator/settings/:operatorId/context.json',
    'OperatorSettingsMetadata': '/operator/operator/settings/metadata.json',
    'CustomerCloneOperators': '/operator/operator/customer/clone/operators.json',

    'OperatorRestaurantContext': '/operator/restaurant/context.json',
    'OperatorRestaurantMetadata': '/operator/restaurant/metadata.json',

    'ZuoraRestaurantSettingsContext': '/zuora/restaurant/context.json',
    'ZuoraRestaurantSettingsMetadata': '/zuora/restaurant/metadata.json',

    'OperatorDeviceContext': '/operator/device/context.json',
    'OperatorDeviceMetadata': '/operator/device/metadata.json',


    'OperatorImportContext': '/operator/import/context.json',
    'OperatorImport': '/operator/import',
    'OperatorImportChange': '/operator/import/operators.json',

    'ZuoraProductsContext': '/zuora/context.json',
    'ZuoraProductsMetadata': '/zuora/metadata.json',

    'TsePrinterFirmwareContext': '/admin/tse_printer_firmware/context.json',
    'TsePrinterFirmwareMetadata': '/admin/tse_printer_firmware/metadata.json',

    'UniqueReasonCategoryAdd': '/financial/reasons/:accountTypeId/:id/category/:category',

    'CreateDefaultReasons': '/financial/reasons/defaultReasons'
  };

  angular.module('gf.resources', ['ngResource', 'gf.utils'])
    .config(function ($provide) {
      _.each(RESOURCES, function (jsonLocation, name) {
        $provide.factory(name, function ($resource, appConfig) {
          return $resource(appConfig.baseUrl + '/icash' + jsonLocation);
        })
      });
    })
    .config(["$provide", function ($provide) {
      _.each(URLS, function (jsonLocation, name) {
        $provide.constant(name, '/icash' + jsonLocation)
      });
    }])
    .run(function () {
      console.log('run');
    })
    .factory('icons', function ($resource) {
      return {
        'library': $resource('assets/library.json', undefined, {'get': {'method': 'GET', 'isArray': true}}),
        'default': $resource('assets/default.json', undefined, {'get': {'method': 'GET', 'isArray': true}}),
        'payment': $resource('assets/payment.json', undefined, {'get': {'method': 'GET', 'isArray': true}})
      };
    })
  ;
})();
