import moment from 'moment';

(function () {
  'use strict';
  angular.module('gf.setup.restaurant', ['ui.router'])

    .run(function ($templateCache) {
      $templateCache.put('app/template/timezoneTypeahead.html', require('../../../main/angular/app/template/timezoneTypeahead.html'));
    })

    .factory('CurrentRestaurantMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/restaurant/metadata.json')
    })
    .factory('CurrentRestaurantMetadataNew', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/restaurant/metadata_new.json')
    })
    .factory('CurrentRestaurantSettingsMetadataNew', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/restaurant/settings/metadata_new.json')
    })
    .factory('CurrentRestaurantSettings', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/restaurant/settings.json')
    })
    .factory('CurrentRestaurantContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/restaurant/context.json')
    })
    .factory('CurrentRestaurantSettingsMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/restaurant/settings/metadata.json')
    })
    .factory('FeatureTrial', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/restaurant/feature_trial', {}, {
        load: {method: 'GET', isArray: true}
      })
    })
    .factory('CurrentRestaurant', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/restaurant/restaurant.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })

    .factory('RestaurantMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/metadata.json')
    })
    .factory('RestaurantMetadataNew', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/:restaurantId/metadata_new.json')
    })
    .factory('RestaurantSettingsMetadataNew', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/:restaurantId/settings/metadata_new.json')
    })
    .factory('RestaurantSettingsMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/settings/metadata.json')
    })
    .factory('RestaurantGridContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/context.json')
    })
    .factory('RestaurantAddContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/add/context.json')
    })
    .factory('RestaurantEditContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/context/:restaurantId.json')
    })
    .factory('RestaurantSettings', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/:restaurantId/settings.json')
    })
    .factory('RestaurantCloneOperators', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/operators.json')
    })

    .factory('RestaurantForm', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/restaurant_form/:restaurantId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Restaurant', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/restaurant/:restaurantId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('GtcReceiver', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/gtc/receiver', {}, {
        load: {method: 'GET', isArray: true},
        save: {method: 'POST'}
      })
    })
    .factory('GtcReceiverAdd', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/gtc/receiver/add.json')
    })

    .config(function ($provide) {
      $provide.constant('RestaurantMasterSwitch', '/icash/operator_data/restaurant/master');
    })

    .config(function ($stateProvider) {

        var CurrentRestaurantCtrlResolve = {
          restaurantMetadata: ["CurrentRestaurantMetadata", function (CurrentRestaurantMetadata) {
            return CurrentRestaurantMetadata.get().$promise;
          }],
          restaurantMetadataNew: ["CurrentRestaurantMetadataNew", function (CurrentRestaurantMetadataNew) {
            return CurrentRestaurantMetadataNew.get().$promise;
          }],
          restaurantSettingsMetadataNew: ["CurrentRestaurantSettingsMetadataNew", function (CurrentRestaurantSettingsMetadataNew) {
            return CurrentRestaurantSettingsMetadataNew.get().$promise;
          }],
          restaurantSettingsMetadata: ["CurrentRestaurantSettingsMetadata", function (CurrentRestaurantSettingsMetadata) {
            return CurrentRestaurantSettingsMetadata.get().$promise;
          }],
          restaurantContext: ["CurrentRestaurantContext", function (CurrentRestaurantContext) {
            return CurrentRestaurantContext.get().$promise;
          }],
          restaurant: ["CurrentRestaurant", function (CurrentRestaurant) {
            return CurrentRestaurant.load().$promise;
          }],
          currentTour: ["Tour", function (Tour) {
            return Tour.get({page: 'setupRestaurant'}).$promise;
          }],
          trial: ['FeatureTrial', function (FeatureTrial) {
            return FeatureTrial.load().$promise;
          }],
          gtcReceiver: ["GtcReceiver", "securityService", function (GtcReceiver) {
            return GtcReceiver.load().$promise;
          }]
        };

        var RestaurantAddCtrlResolve = {
          restaurantMetadata: ['RestaurantMetadata', function (RestaurantMetadata) {
            return RestaurantMetadata.get().$promise;
          }],
          restaurantMetadataNew: ['RestaurantMetadataNew', function (RestaurantMetadataNew) {
            return RestaurantMetadataNew.get({restaurantId: 0}).$promise;
          }],
          restaurantSettingsMetadataNew: ["RestaurantSettingsMetadataNew", function (RestaurantSettingsMetadataNew) {
            return RestaurantSettingsMetadataNew.get({restaurantId: 0}).$promise;
          }],
          restaurantSettingsMetadata: ['RestaurantSettingsMetadata', function (RestaurantSettingsMetadata) {
            return RestaurantSettingsMetadata.get().$promise;
          }],
          restaurantContext: ['RestaurantAddContext', function (RestaurantAddContext) {
            return RestaurantAddContext.get().$promise;
          }],
          restaurant: ['RestaurantForm', function (RestaurantForm) {
            return RestaurantForm.load({restaurantId: 'add'}).$promise;
          }]
        };

        var RestaurantEditCtrlResolve = {
          restaurantMetadata: ['RestaurantMetadata', function (RestaurantMetadata) {
            return RestaurantMetadata.get().$promise;
          }],
          restaurantMetadataNew: ['RestaurantMetadataNew', "$stateParams", function (RestaurantMetadataNew, $stateParams) {
            return RestaurantMetadataNew.get({restaurantId: $stateParams.id}).$promise;
          }],
          restaurantSettingsMetadataNew: ["RestaurantSettingsMetadataNew", "$stateParams", function (CurrentRestaurantMetadataNew, $stateParams) {
            return CurrentRestaurantMetadataNew.get({restaurantId: $stateParams.id}).$promise;
          }],
          restaurantSettingsMetadata: ['RestaurantSettingsMetadata', function (RestaurantSettingsMetadata) {
            return RestaurantSettingsMetadata.get().$promise;
          }],
          restaurantContext: ['RestaurantEditContext', '$stateParams', function (RestaurantEditContext, $stateParams) {
            return RestaurantEditContext.get({restaurantId: $stateParams.id}).$promise;
          }],
          restaurant: ['RestaurantForm', '$stateParams', function (RestaurantForm, $stateParams) {
            return RestaurantForm.load({restaurantId: $stateParams.id}).$promise;
          }]
        };

        var RestaurantGridCtrlResolve = {
          restaurantMetadata: ['RestaurantMetadata', function (RestaurantMetadata) {
            return RestaurantMetadata.get().$promise;
          }],
          restaurantContext: ['RestaurantGridContext', function (RestaurantAddContext) {
            return RestaurantAddContext.get().$promise;
          }]
        };


        $stateProvider
          .state('page.setupRestaurant', {
            url: '/setup/restaurant',
            params: {
              '#': '0'
            },
            template: require('../template/page/setup/restaurant/restaurant.html'),
            controller: 'CurrentRestaurantCtrl',
            controllerAs: 'restaurantEditCtrl',
            resolve: CurrentRestaurantCtrlResolve,
            title: "restaurant"
          })

          .state('page.operator_dataRestaurant', {
            url: '/operator_data/restaurant',
            template: require('../template/page/setup/restaurant/restaurants.html'),
            controller: 'RestaurantGridCtrl',
            controllerAs: 'restaurantGridCtrl',
            resolve: RestaurantGridCtrlResolve,
            title: "restaurant"
          })

          .state('page.operator_dataRestaurantAdd', {
            url: '/operator_data/restaurant/add',
            template: require('../template/page/setup/restaurant/restaurant.html'),
            controller: 'RestaurantAddCtrl',
            controllerAs: 'restaurantEditCtrl',
            resolve: RestaurantAddCtrlResolve,
            title: "restaurant"
          })

          .state('page.operator_dataRestaurantDetails', {
            url: '/operator_data/restaurant/:id',
            template: require('../template/page/setup/restaurant/restaurant.html'),
            controller: 'RestaurantEditCtrl',
            controllerAs: 'restaurantEditCtrl',
            resolve: RestaurantEditCtrlResolve,
            title: "restaurant"
          })
      }
    )

    .run(function ($templateCache) {
      $templateCache.put('app/template/page/feature/barcode_settings.html', require('./../../gf-features/template/page/feature/barcode_settings.html'))
    })

    .factory('clientFeatureSettings', function ($http, $q, notifyManager, $translate) {
      return {
        BARCODE: {
          settings: {},
          template: 'app/template/page/feature/barcode_settings.html',
          prepareSettings: function (ctrl) {
            var self = this;
            ctrl.settings = self.settings;

            return $http.get('/icash/restaurant_data/' + ctrl.restaurant.id + '/barcode_settings').then(function (res) {
              self.settings.barcodeSettings = res.data;
            });
          },
          savePromise: function (restaurantId) {
            var self = this;
            return $http.post('/icash/restaurant_data/' + restaurantId + '/barcode_settings', self.settings.barcodeSettings)
              .then({}, function () {
                notifyManager.error($translate.instant('entity.edit.failedFeatureSettings'));
              });
          }
        }
      }
    })

    .controller('CurrentRestaurantCtrl',
      function ($scope, $location, $http, notifyManager, CurrentRestaurant, restaurant, restaurantContext,
                restaurantMetadata, restaurantMetadataNew, restaurantSettingsMetadata, restaurantSettingsMetadataNew, CurrentRestaurantSettings,
                Upload, CurrentPaypalLocation, $stateParams, dialogs, $uibModal, ZapperGetMultiplePaymentStatusByPosReferencesResponse,
                $translate, $timeout, securityService, currentTour, tourService, CurrentRestaurantSettingsMetadataNew,
                CurrentRestaurantContext, prepareReportSettings, prepareReportSettingsToSend, leafletData, osmNominatim,
                clientFeatureSettings, featureSettingsService, trial, gtcReceiver, GtcReceiver) {

        var self = this;

        self.gtcReceivers = gtcReceiver;

        self.currentTour = currentTour;
        tourService.startDetachedTour('setupRestaurant', currentTour);
        $scope.$on('beforeTourStart', function () {
          self.tabIndex = 0;
        });

        self.restaurant = restaurant.restaurant;
        self.restaurantContext = restaurantContext;
        self.restaurantMetadata = restaurantMetadata;
        self.metadataNew = restaurantMetadataNew;
        self.metadataSettingsNew = restaurantSettingsMetadataNew;
        self.restaurantSettingsMetadata = restaurantSettingsMetadata;
        self.restaurantSettings = restaurant.restaurantSettings;
        self.posLedgerSettings = restaurant.posLedgerSettings;
        self.restaurantData = true;
        self.usedFixedChangedAmount = false;
        self.tabIndex = parseInt($stateParams.tabIndex || $location.hash() || 0);
        self.featureSettings = clientFeatureSettings;

        self.multiselectDefaultSettings = $scope.multiselectDefaultSettings;
        self.multiselectText = $scope.multiselectText;

        angular.extend(self, restaurantContext.data.dependencies);
        self.newDataModel = restaurantContext.data.newDataModel;
        self.trial = trial;

        // todo create one controller for all cases where restaurant is edited
        // overridden because operator_data.js use the same template
        self.restaurantHasAnyFeature = function () {
          return securityService.hasAnyFeature.apply(securityService, arguments);
        };

        self.reportSettings = prepareReportSettings(self.restaurantContext.data.dependencies.reportSettings, restaurant.reportSettings);

        self.map = {};
        self.map.bounds = {};
        self.map.center = {};
        self.map.events = {};

        // extend metadata
        function extendMetadata() {
          self.metadataNew.fields['currency'].editable = self.restaurantContext.data.currencyEditable;
          self.metadataNew.fields['branchControl'].visible = self.restaurantHasAnyFeature('BRANCH_CONTROL')
            && securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_BRANCH_CONTROL');
          self.metadataNew.fieldGroups.branchManagement = {visible: self.restaurant.master && self.restaurantHasAnyFeature('BRANCH_CONTROL')};

          if (self.restaurantHasAnyFeature('TWO_LINE_CUSTOMER_DISPLAY') && !self.restaurantSettings.customerDisplayNotSignedFirstLine) {
            self.restaurantSettings.customerDisplayNotSignedFirstLine = $translate.instant('restaurantSettings.customerDisplayNotSignedFirstLine.default');
            self.restaurantSettings.customerDisplayNotSignedSecondLine = $translate.instant('restaurantSettings.customerDisplayNotSignedSecondLine.default');
            self.restaurantSettings.customerDisplayIdleTextFirstLine = $translate.instant('restaurantSettings.customerDisplayIdleTextFirstLine.default');
            self.restaurantSettings.customerDisplayIdleTextSecondLine = $translate.instant('restaurantSettings.customerDisplayIdleTextSecondLine.default');
            self.restaurantSettings.customerDisplayTotalText = $translate.instant('restaurantSettings.customerDisplayTotalText.default');
            self.restaurantSettings.customerDisplayChangeText = $translate.instant('restaurantSettings.customerDisplayChangeText.default');
          }
        }

        extendMetadata();


        // initially convert to string, it is because options don't work with number
        self.restaurant.logLevel = restaurant.restaurant.logLevel.toString();
        self.restaurantSettings.zapperTip = restaurant.restaurantSettings.zapperTip.toString();

        $scope.$watch("restaurantEditCtrl.restaurant.restaurantAddress.country.id", function (value) {
          self.regions = self.regionsByCountry[value];
        });

        self.prepareOpenHours = function () {
          self.openHours = {};
          angular.forEach(self.restaurantSettings.openHours, function (openHours) {
            self.openHours[openHours.day] = openHours;
          });

          for (var i = 1; i < 8; i++) {
            if (!self.openHours[i]) {
              self.openHours[i] = {day: i, active: true, from: 480, to: 1380};
              self.restaurantSettings.openHours.push(self.openHours[i]);
            }

            self.openHours[i].fromDate = new Date();
            self.openHours[i].fromDate.setHours(Math.floor(self.openHours[i].from / 60) % 24);
            self.openHours[i].fromDate.setMinutes(self.openHours[i].from % 60);

            self.openHours[i].toDate = new Date();
            self.openHours[i].toDate.setHours(Math.floor(self.openHours[i].to / 60) % 24);
            self.openHours[i].toDate.setMinutes(self.openHours[i].to % 60);
          }
        };
        self.prepareOpenHours();
        for (var i = 1; i < 8; i++) {
          $scope.$watch("[restaurantEditCtrl.openHours[" + i + "].fromDate, restaurantEditCtrl.openHours[" + i + "].day]", function (value) {
            self.openHours[value[1]].from = value[0].getHours() * 60 + value[0].getMinutes();
          }, true);

          $scope.$watch("[restaurantEditCtrl.openHours[" + i + "].toDate, restaurantEditCtrl.openHours[" + i + "].day]", function (value) {
            self.openHours[value[1]].to = value[0].getHours() * 60 + value[0].getMinutes();
          }, true);
        }


        //timepickers
        self.prepareTimePickers = function () {
          self.restaurant.gastronomicDayStartDate = new Date();
          self.restaurant.gastronomicDayStartDate.setHours(Math.floor(self.restaurant.gastronomicDayStart / 60) % 24);
          self.restaurant.gastronomicDayStartDate.setMinutes(self.restaurant.gastronomicDayStart % 60);

          self.restaurant.gastronomicDayEndDate = new Date();
          self.restaurant.gastronomicDayEndDate.setHours(Math.floor(self.restaurant.gastronomicDayEnd / 60) % 24);
          self.restaurant.gastronomicDayEndDate.setMinutes(self.restaurant.gastronomicDayEnd % 60);
        };
        self.prepareTimePickers();

        $scope.$watch("restaurantEditCtrl.restaurant.gastronomicDayStartDate", function (value) {
          self.restaurant.gastronomicDayStart = value.getHours() * 60 + value.getMinutes();
        });
        $scope.$watch("restaurantEditCtrl.restaurant.gastronomicDayEndDate", function (value) {
          self.restaurant.gastronomicDayEnd = value.getHours() * 60 + value.getMinutes();
        });

        self.save = function () {
          if (self.restaurantForm.$valid) {

            if (_.isEmpty(self.restaurantSettings.lightspeedpaymentsSubMerchantId)) {
              self.restaurantSettings.lightspeedpaymentsSubMerchantId = null;
            }

            var url = '/icash/restaurant_data/restaurant/restaurant.json';
            $http.put(url, {
                restaurant: self.restaurant,
                restaurantSettings: self.restaurantSettings,
                reportSettings: prepareReportSettingsToSend(self.reportSettings),
                posLedgerSettings: self.posLedgerSettings
              }
            ).then(function (response) {
              if (response.data.ok) {
                notifyManager.success($translate.instant('entity.edit.successfully'));
                featureSettingsService.saveAllSettings(clientFeatureSettings, response.data.payload.restaurant.id);
                self.settings = response.data.payload.restaurantSettings;
                self.restaurant = response.data.payload.restaurant;
                self.posLedgerSettings = response.data.payload.posLedgerSettings;
                self.restaurantSettings.zapperTip = response.data.payload.settings.zapperTip.toString();
                self.imageUpdated = new Date().getTime();
                self.restaurant.logLevel = response.data.payload.restaurant.logLevel.toString();
                self.prepareTimePickers();
                self.prepareOpenHours();
                self.metadataSettingsNew = CurrentRestaurantSettingsMetadataNew.get();
                self.restaurantContext = CurrentRestaurantContext.get();
                self.restaurantContext.$promise.then(function () {
                  self.reportSettings = prepareReportSettings(self.restaurantContext.data.dependencies.reportSettings, response.data.payload.reportSettings);
                });
                securityService.refreshContext().then(function () {
                  extendMetadata();
                });
              } else {
                notifyManager.error(response.data.message);
              }
            });
          } else {
            notifyManager.error($translate.instant('restaurantSettings.invalidForm'));
            $scope.$broadcast("gf-submitted");
          }
        };

        self.showExchangeRateWarning = false;

        self.currencyChanged = function () {
          self.showExchangeRateWarning = true;
        };

        self.forceLive = function () {
          $http.put("/icash/restaurant_data/restaurant/force_live")
            .then(function (resp) {
              if (resp.data.ok) {
                notifyManager.success($translate.instant('restaurant.goLive.success'));

                self.restaurant.live = true;
                securityService.refreshContext();
              } else {
                notifyManager.error($translate.instant(resp.data.message));
              }
            });
        };

        self.goLive = function () {
          $http.put("/icash/restaurant_data/restaurant/go_live")
            .then(function (resp) {
              if (resp.data.ok) {

                notifyManager.success($translate.instant('restaurant.goLive.success'));

                self.restaurant.live = true;
                securityService.refreshContext();
              } else {
                notifyManager.error($translate.instant(resp.data.message));
              }
            });

        };

        if (self.restaurant.longitude == 0 && self.restaurant.latitude == 0) {
          //fixed NPE
          if (self.restaurant.restaurantAddress) {
            if (self.restaurant.restaurantAddress.region) {
              self.restaurant.latitude = self.restaurant.restaurantAddress.region.latitude;
              self.restaurant.longitude = self.restaurant.restaurantAddress.region.longitude;
            } else if (self.restaurant.restaurantAddress.country) {
              self.restaurant.latitude = self.restaurant.restaurantAddress.country.latitude;
              self.restaurant.longitude = self.restaurant.restaurantAddress.country.longitude;
            }
          }
        }
        angular.extend(self.map, {
          center: {
            lat: self.restaurant.latitude,
            lng: self.restaurant.longitude,
            zoom: 16
          },
          bounds: {},
          markers: {
            marker: {
              lat: self.restaurant.latitude,
              lng: self.restaurant.longitude,
              id: self.restaurant.id,
              name: self.restaurant.name,
              draggable: true,
              icon: {
                type: 'awesomeMarker',
                icon: 'circle',
                prefix: 'fa',
                markerColor: 'orange'
              },
              label: {
                message: self.restaurant.name,
                options: {
                  noHide: true
                }
              },
              events: {
                markers: {
                  enable: ['dragend']
                }
              }
            }
          }
        });

        $scope.$on('leafletDirectiveMarker.dragend', function (event, args) {
          self.restaurant.latitude = args.model.lat;
          self.restaurant.longitude = args.model.lng;
        });

        self.addressTab = function () {
          self.showAddressTab = true;

          leafletData.getMap().then(function (map) {
            $timeout(function () {
              map.invalidateSize();
            }, 400);
          });
        };

        self.getAddressTypeahead = function (input) {
          return osmNominatim.search({
            q: input,
            "accept-language": securityService.context.currentLanguageTag
          }).then(function (data) {
            return data.data;
          })
        };

        self.onAddressSelect = function ($item, $model, $label) {
          self.restaurant.latitude = parseFloat($item.lat);
          self.restaurant.longitude = parseFloat($item.lon);

          angular.merge(self.map, {
            center: {
              lat: self.restaurant.latitude,
              lng: self.restaurant.longitude,
              zoom: 16
            },
            markers: {
              marker: {
                lat: self.restaurant.latitude,
                lng: self.restaurant.longitude
              }
            }
          });

          osmNominatim.reverse({
            osm_type: $item.osm_type[0].toUpperCase(),
            osm_id: $item.osm_id,
            zoom: 16,
            addressdetails: 1,
            "accept-language": securityService.context.currentLanguageTag
          }).then(function (data) {

            var address = data.data.address;

            if (!self.restaurant.restaurantAddress) {
              self.restaurant.restaurantAddress = {};
            }

            // assign data to restaurant
            if (address.country_code) {
              self.restaurant.restaurantAddress.country = _.find(self.countries, function (country) {
                return address.country_code.toUpperCase() == country.code.toUpperCase();
              });
            }


            if (self.restaurant.restaurantAddress.country) {
              self.restaurant.restaurantAddress.region = _.find(self.regionsByCountry[self.restaurant.restaurantAddress.country.id], function (region) {
                return address.state.toLowerCase() == region.name.toLowerCase();
              });
            }

            self.restaurant.restaurantAddress.street = (address.road ? address.road : "") + " " + (address.house_number ? address.house_number : "");

            self.restaurant.restaurantAddress.city = (address.city ? address.city : "") || (address.county ? address.county : "") || (address.state ? address.state : "");

            self.restaurant.restaurantAddress.zip = address.postcode ? address.postcode : "";

            return;
          });
        };

        self.updateMap = function (restaurant) {

          var lat = restaurant.latitude ? parseFloat(restaurant.latitude) : 0;
          var lng = restaurant.longitude ? parseFloat(restaurant.longitude) : 0;

          angular.merge(self.map, {
            center: {
              lat: lat,
              lng: lng,
              zoom: 16
            },
            markers: {
              marker: {
                lat: lat,
                lng: lng
              }
            }
          });
        };

        //todo o.logunov: There are 5 onFileSelect identical methods
        //todo o.logunov: create a directive and move this logic with pattern to it
        self.onFileSelect = function ($files, filename, maxWidth, maxHeight) {
          var imageType = /image.*/;
          var reader = new FileReader();
          var img = new Image();

          reader.onload = function () {
            img.src = reader.result;
          };

          img.onload = function () {

            if (!(img.width > maxWidth || img.height > maxHeight)) {
              self[filename + "Show"] = true;
              self.upload = Upload.upload({
                url: '/icash/restaurant_data/restaurant/upload?name=' + filename,
                method: 'POST',
                file: file,
                fileFormDataName: 'file'
              })
                .then((resp) => {
                  if (resp.data.status !== "OK") {
                    notifyManager.error($translate.instant('image.format.notsupported')
                     + " " + resp.data.message);
                  } else {
                    $("#" + filename + "Progress").css({width: "100%"});
                    $("#" + filename + "Progress").text("100 %");
                  }
                })
                .progress(function (evt) {
                    $("#" + filename + "Progress").css({width: parseInt(100.0 * evt.loaded / evt.total) + "%"});
                    $("#" + filename + "Progress").text(parseInt(100.0 * evt.loaded / evt.total) + " %");
                  }
                );
            } else {
              self.restaurantSettings[filename + 'Name'] = null;
              notifyManager.error($translate.instant('restaurantSettings.imageSection.error.size'));
            }
          };

          for (var i = 0; i < $files.length; i++) {
            var file = $files[i];
            if (file.type.match(imageType)) {
              reader.readAsDataURL(file);
            } else {
              notifyManager.error($translate.instant('restaurantSettings.imageSection.error.type'));
            }
          }
        };

        self.onFileDelete = function ($files, filename) {
          $http({
            method: 'DELETE',
            url: '/icash/restaurant_data/restaurant/upload',
            params: {
              'name': filename
            }
          }).then(function (resp) {
            if (resp.data.ok) {
              self.restaurantSettings[filename] = null;
              self.restaurantSettings[filename + 'Name'] = null;

              if (filename == 'logoImage') {
                self.restaurantSettings['headerImage'] = null;
              }

              self[filename + "Show"] = false;
              $("#" + filename + "Progress").css({width: "0%"});
            }
          });
        };

        self.reset = function () {
          //$("#imageForm")[0].reset();
          self.logoImageShow = false;
          $("#logoImageProgress").css({width: "0%"});
          self.promotionImageShow = false;
          $("#promotionImageProgress").css({width: "0%"});
          self.footerImageShow = false;
          $("#footerImageProgress").css({width: "0%"});

          self.paypalIconShow = false;
          $("#paypalIconProgress").css({width: "0%"});
          self.paypalLogoShow = false;
          $("#paypalLogoProgress").css({width: "0%"});
          self.paypalWallpaperShow = false;
          $("#paypalWallpaperProgress").css({width: "0%"});

          $http({
            url: '/icash/restaurant_data/restaurant/reset',
            method: 'DELETE'
          });
        };

        self.imageUpdated = new Date().getTime();

        $http({
          url: '/icash/restaurant_data/restaurant/reset',
          method: 'DELETE'
        });

        CurrentPaypalLocation.load(function (data) {
          $scope.safeApply(function () {
            self.paypalLocation = data;
          });
        });


        self.refreshLocation = function () {
          CurrentPaypalLocation.edit(function (data) {
            if (data.ok) {
              self.paypalLocation = data.payload.location;
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          });

        };

        self.createLocation = function () {
          CurrentPaypalLocation.save(function (data) {
            if (data.ok) {
              self.paypalLocation = data.payload.location;
              self.restaurantSettings = new CurrentRestaurantSettings(data.payload.settings);
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          });
        };

        self.deleteLocation = function () {
          CurrentPaypalLocation.remove(function (data) {
            if (data.ok) {
              self.paypalLocation = null;
              self.restaurantSettings = new CurrentRestaurantSettings(data.payload.settings);
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          });
        };

        self.paypalLocationId = function () {
          if (self.restaurantSettings.paypalLiveMode) {
            return self.restaurantSettings.paypalMerchantLocation;
          } else {
            return self.restaurantSettings.paypalTestMerchantLocation;
          }
        };

        self.uploadPaypalLogo = function () {
          $http.post("/icash/restaurant_data/restaurant/upload_paypal_logo", function (data) {
            if (data.ok) {
              self.paypalLocation = data.payload.location;
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          });
        };

        self.tabs = {};

        if ($location.hash()) {
          self.tabs[$location.hash()] = true;
        }

        self.referencePoses = [];

        self.generateZapper = function (zapperReference) {

          if (zapperReference) {

            self.referencePos = self.restaurant.id + "#" + moment().format('YYYYMMDD') + "#" + zapperReference;

            if (self.referencePoses.length > 4) {
              self.referencePoses = [];
            }

            self.referencePoses.push(self.referencePos);
          }
        };

        self.checkZapperStatus = function () {
          ZapperGetMultiplePaymentStatusByPosReferencesResponse.query({posreference: self.referencePoses}, function (data) {
            self.zapperResponse = data;
          });

        };

        self.onCountryChanged = function () {
          if (!self.restaurant.restaurantAddress.country) {
            return;
          }

          angular.forEach(self.countries, function (country) {
            if (country.id == self.restaurant.restaurantAddress.country.id) {
              self.restaurant.latitude = country.latitude;
              self.restaurant.longitude = country.longitude;

              angular.merge(self.map, {
                center: {
                  lat: self.restaurant.latitude,
                  lng: self.restaurant.longitude,
                  zoom: 16
                },
                markers: {
                  marker: {
                    lat: self.restaurant.latitude,
                    lng: self.restaurant.longitude
                  }
                }
              });
            }
          });
        };

        self.onCountryRegionChanged = function () {
          if (!self.restaurant.restaurantAddress.country) {
            return;
          }
          angular.forEach(self.regionsByCountry[self.restaurant.restaurantAddress.country.id], function (region) {
            if (region.id == self.restaurant.restaurantAddress.region.id) {
              self.restaurant.latitude = region.latitude;
              self.restaurant.longitude = region.longitude;

              angular.merge(self.map, {
                center: {
                  lat: self.restaurant.latitude,
                  lng: self.restaurant.longitude,
                  zoom: 16
                },
                markers: {
                  marker: {
                    lat: self.restaurant.latitude,
                    lng: self.restaurant.longitude
                  }
                }
              });
            }
          });
        };

        self.testGeneralExportSftp = function () {
          $http.get('/icash/daily_export/sftp').then(function (data) {
            if (data.data.ok) {
              notifyManager.success("OK");
            } else {
              notifyManager.error(data.data.message);
            }
          });
        };

        self.importZuoraSubscription = function () {
          $http.post('/icash/zuora/subscription').then(function (data) {
            if (data.data.ok) {
              notifyManager.success(data.data.message);
            } else {
              notifyManager.error(data.data.message);
            }
          });
        };

        self.addGroupToProfitList = function ($item) {
          self.restaurantSettings.profitArticleGroups.push({articleGroup: $item});
          self.restaurantSettings.addGroupItem = '';
        };

        self.addEmployeeToEmployeeCosts = function ($item) {
          self.restaurantSettings.performanceEmployeeCosts.push({waiter: $item});
          self.restaurantSettings.addEmployeeItem = '';
        };

        for (var i = 0; i < self.timezones.length; i++) {
          if (self.restaurant.timezone == self.timezones[i].id) {
            self.currentTimezone = self.timezones[i].name;
            break;
          }
        }

        self.changeTimezone = function ($item) {
          self.restaurant.timezone = $item.id;
        };

        self.removeGroup = function ($index) {
          self.restaurantSettings.profitArticleGroups.splice($index, 1);
        };

        self.removeEmployee = function ($index) {
          self.restaurantSettings.performanceEmployeeCosts.splice($index, 1);
        };

        self.migrate = function () {
          if (securityService.context.currentUser.email && securityService.context.currentUser.mailConfirmed) {
            dialogs.confirm($translate.instant('pleaseConfirm'), $translate.instant('migrateDataConfirmation'))
              .result.then(function () {
              $http({
                method: 'PUT',
                url: '/icash/restaurant_data/restaurant/migrate'
              }).then(function (response) {
                  if (response.data.status === 'OK') {
                    $uibModal.open({
                      template: require('../template/page/setup/restaurant/send_mail_migration_report.html'),
                      windowClass: 'gf-modal',
                      controller: 'MigrateReportMailCtrl',
                      controllerAs: 'migrateReportMailCtrl'
                    });
                  } else {
                    notifyManager.error($translate.instant('messages.migrateFailed'));
                  }
                }, function () {
                  notifyManager.error($translate.instant('messages.migrateFailed'));
                }
              );
            });
          } else {
            $uibModal.open({
              template: require('../../gf-dashboard/template/page/welcome/provideEmailPopup.html'),
              controller: 'ProvideEmailCtrl',
              controllerAs: '$ctrl',
              windowClass: 'gf-modal'
            });
          }
        };

        self.resetFiscalRegulation = function (enableFiscalRegulation) {
          if (!enableFiscalRegulation) {
            self.restaurantSettings.fiscalRegulation = null;
          }
        };

        // ICR-11511
        if (securityService.hasAnyFeature('SAFT')) {
          if (self.restaurantMetadata.columns.taxNumber) {
            self.restaurantMetadata.columns.taxNumber.angular = {'required': ''};
            self.restaurantMetadata.columns.taxNumber.validation = ['required'];
            self.restaurantMetadata.columns.taxNumber.errorMessages =
              [
                {
                  'keys': ['required'],
                  'message': 'org.hibernate.validator.constraints.NotBlank.message',
                  'name': 'Required'
                }
              ];
          }
        }

        if (self.metadataSettingsNew.fieldGroups['ibelsa'] && self.metadataSettingsNew.fieldGroups['ibelsa'].visible) {
          self.restaurantMetadata.columns.ibelsaCustomerGroup = {
            angular: {'required': ''},
            validation: ['required'],
            errorMessages:
              [
                {
                  'keys': ['required'],
                  'message': 'org.hibernate.validator.constraints.NotBlank.message',
                  'name': 'Required'
                }
              ]
          }
        }
        self.addGtcReceiver = function () {
          $uibModal.open({
            template: require('../../../main/angular/app/template/add_gtc_receiver_modal.html'),
            windowClass: 'gf-modal',
            controller: 'AddGtcReceiverCtrl',
            controllerAs: '$ctrl',
            resolve: {
              gtcReceiversNames: function () {
                return _.map(self.gtcReceivers, 'name');
              },
              receiver: function () {
                return {
                  name: '',
                  receiverUrl: '',
                  receiverServiceName: '',
                  secret: ''
                };
              }
            }
          }).result.then(function (newGtcReceiver) {
            GtcReceiver.save(newGtcReceiver, function (res) {
              self.gtcReceivers = self.gtcReceivers.concat(res);
              notifyManager.success($translate.instant('entity.save.successfully'));
            })
          })
        };
        self.editGtcReceiver = function (receiver) {
          $uibModal.open({
            template: require('../../../main/angular/app/template/add_gtc_receiver_modal.html'),
            windowClass: 'gf-modal',
            controller: 'AddGtcReceiverCtrl',
            controllerAs: '$ctrl',
            resolve: {
              gtcReceiversNames: function () {
                return _.map(self.gtcReceivers, 'name');
              },
              receiver: function () {
                return receiver;
              }
            }
          }).result.then(function (editedGtcReceiver) {
            $http.put('/icash/restaurant_data/gtc/receiver/' + receiver.id, editedGtcReceiver).then(function (res) {
              var index = _.findIndex(self.gtcReceivers, {'id': res.data.id});
              self.gtcReceivers.splice(index, 1, res.data);
              //self.gtcReceivers = _.replace(self.gtcReceivers, {'id': res.data.id}, res.data);
              notifyManager.success($translate.instant('entity.edit.successfully'));
            })
          })
        };
        self.deleteGtcReceiver = function (id) {
          $uibModal.open({
            template: require('../../../main/angular/app/template/confirm_delete_entity_modal.html'),
            windowClass: 'gf-modal',
            controller: 'DeleteEntityConfirmationCtrl',
            controllerAs: '$ctrl'
          }).result.then(function () {
            $http.delete('/icash/restaurant_data/gtc/receiver/' + id + '.json').then(function (res) {
              notifyManager.success($translate.instant('record.deleted'));
              self.gtcReceivers = _.remove(self.gtcReceivers, function (receiver) {
                return receiver.id !== id;
              });
            })
          })
        };
        self.downloadLogsFromDevice = function () {
          $http.post('/icash/internal/pos-telemetry/logs', {},{headers: {'X-Business-Units': securityService.context.currentRestaurant.id}})
            .then(function () {
              notifyManager.success($translate.instant('restaurant.settings.logFromDeviceDownloaded'));
          }, function () {
              notifyManager.error($translate.instant('restaurant.settings.logFromDeviceDownloadFailed'));
            })
        }
      }
    )
    .controller('MigrateReportMailCtrl',
      function ($scope, $uibModalInstance) {
        var self = this;
        self.close = function () {
          $uibModalInstance.close();
        };
      }
    )
    .controller('DeleteEntityConfirmationCtrl',
      function ($scope, $uibModalInstance) {
        var self = this;
        self.close = function () {
          $uibModalInstance.close();
        };
      }
    )
    .controller('AddGtcReceiverCtrl',
      function ($scope, $uibModalInstance, GtcReceiverAdd, gtcReceiversNames, receiver) {
        var self = this;
        self.gtcReceiversNames = gtcReceiversNames;
        self.gtcReceiver = _.clone(receiver);
        if (!self.gtcReceiver.name) {
          GtcReceiverAdd.get(function (res) {
            self.gtcReceiver.secret = res.secret;
          })
        }
        self.close = function () {
          if (!self.gtcReceiverForm.$valid) {
            return;
          }
          $uibModalInstance.close(self.gtcReceiver);
        };
      }
    )
    .directive('gfUniqueGtcName', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ctrl) {
          var initialName = scope.$ctrl.gtcReceiver.name;
          scope.$watch(attrs.ngModel, function (value) {
            if (value === initialName) return;
            if (_.includes(scope.$ctrl.gtcReceiversNames, value)) {
              ctrl.$setValidity('uniqueGtcName', false);
            } else {
              ctrl.$setValidity('uniqueGtcName', true);
            }
          })
        }
      }
    })
    .factory('RestaurantAdd',
      function (securityService, $location, $translate, $state, notifyManager, $timeout) {
        return function (self, $scope, restaurant, restaurantContext, restaurantMetadata, restaurantMetadataNew,
                         restaurantSettingsMetadata, restaurantSettingsMetadataNew, FEATURE_SETTINGS) {
          self.restaurantContext = restaurantContext;
          self.restaurantMetadata = restaurantMetadata;
          self.metadataNew = restaurantMetadataNew;
          self.restaurantSettingsMetadata = restaurantSettingsMetadata;
          self.metadataSettingsNew = restaurantSettingsMetadataNew;
          self.restaurant = restaurant.restaurant;
          self.posLedgerSettings = restaurant.posLedgerSettings;
          self.usedFixedChangedAmount = false;

          self.operatorData = true;

          self.multiselectDefaultSettings = $scope.multiselectDefaultSettings;
          self.multiselectText = $scope.multiselectText;
          self.featureSettings = FEATURE_SETTINGS;

          angular.extend(self, restaurantContext.data.dependencies);

          // initially convert to string, it is because options don't work with number
          self.restaurant.logLevel = restaurant.restaurant.logLevel.toString();

          $scope.$watch("restaurantEditCtrl.restaurant.restaurantAddress.country.id", function (value) {
            self.regions = self.regionsByCountry[value];
          });

          //timepickers
          self.restaurant.gastronomicDayStartDate = new Date();
          self.restaurant.gastronomicDayStartDate.setHours(Math.floor(self.restaurant.gastronomicDayStart / 60) % 24);
          self.restaurant.gastronomicDayStartDate.setMinutes(self.restaurant.gastronomicDayStart % 60);

          self.restaurant.gastronomicDayEndDate = new Date();
          self.restaurant.gastronomicDayEndDate.setHours(Math.floor(self.restaurant.gastronomicDayEnd / 60) % 24);
          self.restaurant.gastronomicDayEndDate.setMinutes(self.restaurant.gastronomicDayEnd % 60);

          $scope.$watch("restaurantEditCtrl.restaurant.gastronomicDayStartDate", function (value) {
            if (value) {
              self.restaurant.gastronomicDayStart = value.getHours() * 60 + value.getMinutes();
            }
          });
          $scope.$watch("restaurantEditCtrl.restaurant.gastronomicDayEndDate", function (value) {
            if (value) {
              self.restaurant.gastronomicDayEnd = value.getHours() * 60 + value.getMinutes();
            }
          });

          self.save = function () {
            if (self.restaurantForm.$valid) {
              self.restaurant.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $location.path('/operator_data/restaurant')
              });
            } else {
              notifyManager.error($translate.instant('restaurantSettings.invalidForm'));
              $scope.$broadcast("gf-submitted");
            }
          };
          // self.saveAndNew = function () {
          //   if (self.restaurantForm.$valid) {
          //     self.restaurant.$save(function (data) {
          //       notifyManager.success($translate.instant('entity.save.successfully'));
          //       $state.reload();
          //     });
          //   } else {
          //     notifyManager.error($translate.instant('restaurantSettings.invalidForm'));
          //     $scope.$broadcast("gf-submitted");
          //   }
          // };

          self.navigateToSalesforce = function () {
            var salesforceUrl = self.restaurant.salesforceUrl;
            if (salesforceUrl) {
              open(self.GF.url.salesforce + salesforceUrl);
            } else {
              notifyManager.info($translate.instant('message.restaurant.fillSalesForceData'));
            }
          };

          self.back = function () {
            $location.path('/operator_data/restaurant');
          };

          self.restaurantHasAnyFeature = function () {
            var features = restaurantContext.data.features || [];

            var userFeatures = arguments;

            if (angular.isArray(arguments[0])) {
              userFeatures = arguments[0];
            }

            for (var i = 0; i < userFeatures.length; i++) {
              if (features.indexOf(userFeatures[i]) > -1) {
                return true;
              }
            }

            return false;
          };

          // extend metadata
          self.metadataNew.fields['currency'].editable = self.restaurantContext.data.currencyEditable;
          self.metadataNew.fields['branchControl'].visible = self.restaurantHasAnyFeature('BRANCH_CONTROL') && securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_BRANCH_CONTROL');
          self.metadataNew.fieldGroups.branchManagement = {visible: self.restaurant.master && self.restaurantHasAnyFeature('BRANCH_CONTROL')};

          self.showExchangeRateWarning = false;

          self.currencyChanged = function () {
            self.showExchangeRateWarning = true;
          };

          if (restaurant.restaurant.restaurantAddress && restaurant.restaurant.longitude == 0 && restaurant.restaurant.latitude == 0) {
            //fixed NPE
            if (restaurant.restaurant.restaurantAddress) {
              if (restaurant.restaurant.restaurantAddress.region) {
                restaurant.restaurant.latitude = restaurant.restaurant.restaurantAddress.region.latitude;
                restaurant.restaurant.longitude = restaurant.restaurant.restaurantAddress.region.longitude;
              } else if (self.restaurant.restaurantAddress.country) {
                restaurant.restaurant.latitude = restaurant.restaurant.restaurantAddress.country.latitude;
                restaurant.restaurant.longitude = restaurant.restaurant.restaurantAddress.country.longitude;
              }
            }
          }

          self.removeGroup = function ($index) {
            self.restaurantSettings.profitArticleGroups.splice($index, 1);
          };

          self.tabs = {};

          if ($location.hash()) {
            self.tabs[$location.hash()] = true;
          }

          self.changeTimezone = function ($item) {
            self.restaurant.timezone = $item.id;
          };

          // ICR-11511
          if (securityService.hasAnyFeature('SAFT')) {
            if (self.restaurantMetadata.columns.taxNumber) {
              self.restaurantMetadata.columns.taxNumber.angular = {'required': ''};
              self.restaurantMetadata.columns.taxNumber.validation = ['required'];
              self.restaurantMetadata.columns.taxNumber.errorMessages =
                [
                  {
                    'keys': ['required'],
                    'message': 'org.hibernate.validator.constraints.NotBlank.message',
                    'name': 'Required'
                  }
                ];
            }
          }
        }
      }
    )
    .controller('RestaurantAddCtrl',
      function (RestaurantAdd, $scope, restaurant, restaurantContext, restaurantMetadata, restaurantMetadataNew, restaurantSettingsMetadata, restaurantSettingsMetadataNew) {

        var self = this;

        RestaurantAdd(self, $scope, restaurant, restaurantContext, restaurantMetadata, restaurantMetadataNew, restaurantSettingsMetadata, restaurantSettingsMetadataNew);
      }
    )
    .controller('RestaurantEditCtrl',
      function (RestaurantAdd, $scope, restaurant, restaurantContext, restaurantMetadata, restaurantMetadataNew, restaurantSettingsMetadata, restaurantSettingsMetadataNew,
                RestaurantSettings, notifyManager, $location, $http, RestaurantForm, $translate, securityService, dialogs,
                $uibModal, Upload, $resource, $stateParams, RestaurantEditContext,
                prepareReportSettings, prepareReportSettingsToSend, clientFeatureSettings, featureSettingsService) {

        var self = this;

        RestaurantAdd(self, $scope, restaurant, restaurantContext, restaurantMetadata, restaurantMetadataNew, restaurantSettingsMetadata, restaurantSettingsMetadataNew);

        self.restaurantSettings = restaurant.restaurantSettings;
        self.posLedgerSettings = restaurant.posLedgerSettings;
        // initially convert to string, it is because options don't work with number
        self.restaurantSettings.zapperTip = restaurant.restaurantSettings.zapperTip.toString();
        self.newDataModel = restaurantContext.data.newDataModel;
        self.featureSettings = clientFeatureSettings;

        self.reportSettings = prepareReportSettings(self.restaurantContext.data.dependencies.reportSettings, restaurant.reportSettings);

        if (self.restaurantHasAnyFeature('TWO_LINE_CUSTOMER_DISPLAY') && !self.restaurantSettings.customerDisplayNotSignedFirstLine) {
          self.restaurantSettings.customerDisplayNotSignedFirstLine = $translate.instant('restaurantSettings.customerDisplayNotSignedFirstLine.default');
          self.restaurantSettings.customerDisplayNotSignedSecondLine = $translate.instant('restaurantSettings.customerDisplayNotSignedSecondLine.default');
          self.restaurantSettings.customerDisplayIdleTextFirstLine = $translate.instant('restaurantSettings.customerDisplayIdleTextFirstLine.default');
          self.restaurantSettings.customerDisplayIdleTextSecondLine = $translate.instant('restaurantSettings.customerDisplayIdleTextSecondLine.default');
          self.restaurantSettings.customerDisplayTotalText = $translate.instant('restaurantSettings.customerDisplayTotalText.default');
          self.restaurantSettings.customerDisplayChangeText = $translate.instant('restaurantSettings.customerDisplayChangeText.default');
        }

        self.prepareOpenHours = function () {
          self.openHours = {};
          angular.forEach(self.restaurantSettings.openHours, function (openHours) {
            self.openHours[openHours.day] = openHours;
          });

          for (var i = 1; i < 8; i++) {
            if (!self.openHours[i]) {
              self.openHours[i] = {day: i, active: true, from: 480, to: 1380};
              self.restaurantSettings.openHours.push(self.openHours[i]);
            }

            self.openHours[i].fromDate = new Date();
            self.openHours[i].fromDate.setHours(Math.floor(self.openHours[i].from / 60) % 24);
            self.openHours[i].fromDate.setMinutes(self.openHours[i].from % 60);

            self.openHours[i].toDate = new Date();
            self.openHours[i].toDate.setHours(Math.floor(self.openHours[i].to / 60) % 24);
            self.openHours[i].toDate.setMinutes(self.openHours[i].to % 60);
          }
        };
        self.prepareOpenHours();
        for (var i = 1; i < 8; i++) {
          $scope.$watch("[restaurantEditCtrl.openHours[" + i + "].fromDate, restaurantEditCtrl.openHours[" + i + "].day]", function (value) {
            self.openHours[value[1]].from = value[0].getHours() * 60 + value[0].getMinutes();
          }, true);

          $scope.$watch("[restaurantEditCtrl.openHours[" + i + "].toDate, restaurantEditCtrl.openHours[" + i + "].day]", function (value) {
            self.openHours[value[1]].to = value[0].getHours() * 60 + value[0].getMinutes();
          }, true);
        }

        self.save = function () {
          if (self.restaurantForm.$valid) {
            var url = '/icash/operator_data/restaurant/restaurant_form/' + $stateParams.id;
            $http.put(url, {
                restaurant: self.restaurant,
                restaurantSettings: self.restaurantSettings,
                reportSettings: prepareReportSettingsToSend(self.reportSettings),
                posLedgerSettings: restaurant.posLedgerSettings
              }
            ).then(function (response) {
              if (response.data.ok) {
                notifyManager.success($translate.instant('entity.edit.successfully'));
                featureSettingsService.saveAllSettings(clientFeatureSettings, response.data.payload.restaurant.id);
                self.settings = response.data.payload.restaurantSettings;
                self.restaurant = response.data.payload.restaurant;
                self.posLedgerSettings = response.data.payload.posLedgerSettings;
                self.imageUpdated = new Date().getTime();
                self.restaurantContext = RestaurantEditContext.get();
                self.restaurantContext.$promise.then(function () {
                  prepareReportSettings(self.restaurantContext.data.dependencies.reportSettings, response.data.payload.reportSettings);
                });
                securityService.refreshContext();
                $location.path('/operator_data/restaurant');
              } else {
                notifyManager.error(response.data.message);
              }
            });

          } else {
            notifyManager.error($translate.instant('restaurantSettings.invalidForm'));
            $scope.$broadcast("gf-submitted");
          }
        };
        // self.saveAndNew = function () {
        //   if (self.restaurantForm.$valid) {
        //     self.restaurantSettings.$save({restaurantId: self.restaurant.id}, function (data) {
        //
        //       if (data.ok) {
        //         self.restaurantSettings = new RestaurantSettings(data.payload.settings);
        //       }
        //       self.restaurant.$edit({restaurantId: self.restaurant.id}, function (data) {
        //         notifyManager.success($translate.instant('entity.edit.successfully'));
        //         securityService.refreshContext();
        //         $location.path('/operator_data/restaurant/add');
        //       });
        //     });
        //
        //   } else {
        //     notifyManager.error($translate.instant('restaurantSettings.invalidForm'));
        //     $scope.$broadcast("gf-submitted");
        //   }
        // };

        self.goLive = function () {
          $http.put("/icash/operator_data/restaurant/" + self.restaurant.id + "/go_live")
            .then(function (resp) {
              if (resp.data.status == 'OK') {

                notifyManager.success($translate.instant('restaurant.goLive.success'));

                self.restaurant = new RestaurantForm(resp.data.payload.restaurant);

                //timepickers
                self.restaurant.gastronomicDayStartDate = new Date();
                self.restaurant.gastronomicDayStartDate.setHours(Math.floor(self.restaurant.gastronomicDayStart / 60) % 24);
                self.restaurant.gastronomicDayStartDate.setMinutes(self.restaurant.gastronomicDayStart % 60);

                self.restaurant.gastronomicDayEndDate = new Date();
                self.restaurant.gastronomicDayEndDate.setHours(Math.floor(self.restaurant.gastronomicDayEnd / 60) % 24);
                self.restaurant.gastronomicDayEndDate.setMinutes(self.restaurant.gastronomicDayEnd % 60);

              } else {
                notifyManager.error($translate.instant(resp.data.message));
              }
            });

        };

        self.forceLive = function () {
          $http.put("/icash/operator_data/restaurant/" + self.restaurant.id + "/force_live")
            .then(function (resp) {
              if (resp.data.status == 'OK') {
                notifyManager.success($translate.instant('restaurant.goLive.success'));

                self.restaurant.live = true;
              } else {
                notifyManager.error($translate.instant(resp.data.message));
              }
            });
        };

        self.clear = function () {
          if (securityService.context.currentUser.email && securityService.context.currentUser.mailConfirmed) {
            dialogs.confirm($translate.instant('pleaseConfirm'), $translate.instant('restaurant.clear.confirm'))
              .result.then(function () {
              $http.put("/icash/operator_data/restaurant/" + self.restaurant.id + "/clear")
                .then(function (resp) {
                  if (resp.data.status == 'OK') {

                    self.restaurant = new RestaurantForm(resp.data.payload.restaurant);

                    //timepickers
                    self.restaurant.gastronomicDayStartDate = new Date();
                    self.restaurant.gastronomicDayStartDate.setHours(Math.floor(self.restaurant.gastronomicDayStart / 60) % 24);
                    self.restaurant.gastronomicDayStartDate.setMinutes(self.restaurant.gastronomicDayStart % 60);

                    self.restaurant.gastronomicDayEndDate = new Date();
                    self.restaurant.gastronomicDayEndDate.setHours(Math.floor(self.restaurant.gastronomicDayEnd / 60) % 24);
                    self.restaurant.gastronomicDayEndDate.setMinutes(self.restaurant.gastronomicDayEnd % 60);

                    $uibModal.open({
                      template: require('../template/page/setup/restaurant/send_mail_clearing_report.html'),
                      windowClass: 'gf-modal',
                      controller: 'ClearDataReportCtrl',
                      controllerAs: 'clearDataReportCtrl'
                    });

                  } else {
                    notifyManager.error($translate.instant(resp.data.message));
                  }
                });

            });
          } else {
            $uibModal.open({
              template: require('../../gf-dashboard/template/page/welcome/provideEmailPopup.html'),
              controller: 'ProvideEmailCtrl',
              controllerAs: '$ctrl',
              windowClass: 'gf-modal'
            });
          }
        };

        self.back = function () {
          $location.path('/operator_data/restaurant');
        };

        self.setAsMaster = function () {
          $http.post('/icash/operator_data/restaurant/master?id=' + $stateParams.id)
            .then(function (resp) {
              $scope.safeApply(function () {
                if (resp.data.ok) {
                  self.restaurant.master = true;
                }
              });
            });
        };

        self.onFileSelect = function ($files, filename, maxWidth, maxHeight) {
          var imageType = /image.*/;
          var reader = new FileReader();
          var img = new Image();

          reader.onload = function () {
            img.src = reader.result;
          };

          img.onload = function () {

            if (!(img.width > maxWidth || img.height > maxHeight)) {
              self[filename + "Show"] = true;
              self.upload = Upload.upload({
                url: '/icash/operator_data/restaurant/upload?name=' + filename,
                method: 'POST',
                file: file,
                fileFormDataName: 'file'
              })
                .then((resp) => {
                  if (resp.data.status !== "OK") {
                    notifyManager.error($translate.instant('image.format.notsupported')
                      + " " + resp.data.message);
                  } else {
                    $("#" + filename + "Progress").css({width: "100%"});
                    $("#" + filename + "Progress").text("100 %");
                  }
                })
                .progress(function (evt) {
                    $("#" + filename + "Progress").css({width: parseInt(100.0 * evt.loaded / evt.total) + "%"});
                    $("#" + filename + "Progress").text(parseInt(100.0 * evt.loaded / evt.total) + " %");
                  }
                );
            } else {
              self.restaurantSettings[filename + 'Name'] = null;
              notifyManager.error($translate.instant('restaurantSettings.imageSection.error.size'));
            }
          };

          for (var i = 0; i < $files.length; i++) {
            var file = $files[i];
            if (file.type.match(imageType)) {
              reader.readAsDataURL(file);
            } else {
              notifyManager.error($translate.instant('restaurantSettings.imageSection.error.type'));
            }
          }
        };

        self.onFileDelete = function ($files, filename) {
          $http({
            method: 'DELETE',
            url: '/icash/operator_data/restaurant/upload',
            params: {
              'name': filename
            }
          }).then(function (resp) {
            if (resp.data.ok) {
              self.restaurantSettings[filename] = null;
              self.restaurantSettings[filename + 'Name'] = null;

              if (filename == 'logoImage') {
                self.restaurantSettings['headerImage'] = null;
              }

              self[filename + "Show"] = false;
              $("#" + filename + "Progress").css({width: "0%"});
            }
          });
        };

        self.reset = function () {
          //$("#imageForm")[0].reset();
          self.logoImageShow = false;
          $("#logoImageProgress").css({width: "0%"});
          self.promotionImageShow = false;
          $("#promotionImageProgress").css({width: "0%"});
          self.footerImageShow = false;
          $("#footerImageProgress").css({width: "0%"});

          self.paypalIconShow = false;
          $("#paypalIconProgress").css({width: "0%"});
          self.paypalLogoShow = false;
          $("#paypalLogoProgress").css({width: "0%"});
          self.paypalWallpaperShow = false;
          $("#paypalWallpaperProgress").css({width: "0%"});

          $http({
            url: '/icash/operator_data/restaurant/reset',
            method: 'DELETE'
          });
        };

        self.imageUpdated = new Date().getTime();

        $http({
          url: '/icash/operator_data/restaurant/reset',
          method: 'DELETE'
        });

        var PaypalLocation = $resource('/icash/operator_data/restaurant/:restaurantId/location', {restaurantId: self.restaurant.id}, {
          load: {method: 'GET'},
          edit: {method: 'PUT'},
          save: {method: 'POST'},
          remove: {method: 'DELETE'}
        });

        PaypalLocation.load(function (data) {
          $scope.safeApply(function () {
            self.paypalLocation = data;
          });
        });


        self.refreshLocation = function () {
          PaypalLocation.edit(function (data) {
            if (data.ok) {
              self.paypalLocation = data.payload.location;
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          });

        };

        self.createLocation = function () {
          PaypalLocation.save(function (data) {
            if (data.ok) {
              self.paypalLocation = data.payload.location;
              self.restaurantSettings = new RestaurantSettings(data.payload.settings);
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          });
        };

        self.deleteLocation = function () {
          PaypalLocation.remove(function (data) {
            if (data.ok) {
              self.paypalLocation = null;
              self.restaurantSettings = new RestaurantSettings(data.payload.settings);
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          });
        };

        self.paypalLocationId = function () {
          if (self.restaurantSettings.paypalLiveMode) {
            return self.restaurantSettings.paypalMerchantLocation;
          } else {
            return self.restaurantSettings.paypalTestMerchantLocation;
          }
        };

        self.uploadPaypalLogo = function () {
          $http.post("/icash/operator_data/restaurant/" + self.restaurant.id + "/upload_paypal_logo", function (data) {
            if (data.ok) {
              self.paypalLocation = data.payload.location;
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          });
        };

        self.testGeneralExportSftp = function () {
          $http.get('/icash/daily_export/' + self.restaurant.id + '/sftp/').then(function (data) {
            if (data.data.ok) {
              notifyManager.success("OK");
            } else {
              notifyManager.error(data.data.message);
            }
          });
        };
        self.importZuoraSubscription = function () {
          $http.post('/icash/zuora/subscription').then(function (data) {
            if (data.data.ok) {
              notifyManager.success(data.data.message);
            } else {
              notifyManager.error(data.data.message);
            }
          });
        };
        for (var i = 0; i < self.timezones.length; i++) {
          if (self.restaurant.timezone == self.timezones[i].id) {
            self.currentTimezone = self.timezones[i].name;
            break;
          }
        }

        self.resetFiscalRegulation = function (enableFiscalRegulation) {
          if (!enableFiscalRegulation) {
            self.restaurantSettings.fiscalRegulation = null;
          }
        };
      }
    )
    .controller('ClearDataReportCtrl',
      function ($scope, $uibModalInstance) {
        var self = this;
        self.close = function () {
          $uibModalInstance.close();
        };
      }
    )
    .controller('RestaurantGridCtrl',

      function ($scope, $location, $http, $translate, notifyManager, restaurantContext, restaurantMetadata,
                securityService) {

        var self = this;

        self.metadata = restaurantMetadata;
        self.context = restaurantContext;

        var registeredActions = [
          {
            name: 'cloneRestaurant',
            template: 'app/template/grid/clone_action.html',
            controller: 'RestaurantCloneOperatorActionCtrl'
          },
          {
            name: 'master',
            template: 'app/template/grid/master_restaurant_action.html',
            controller: 'RestaurantMasterActionCtrl'
          }
        ];

        var actions = ['edit'];

        if ((securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER'))) {

          actions = [];

          if (securityService.hasAnyFeature('BRANCH_CONTROL')) {
            actions.push('master');
          }

          if (securityService.hasAnyRole('ROLE_SUPERVISOR', 'ACTIVATE_DEACTIVATE_BUSINESS')) {
            actions.push('activate');
          }

          actions.push('edit');

          if (securityService.hasAnyRole('ROLE_SUPERVISOR', 'CLONE_BUSINESS')) {
            actions.push('cloneRestaurant');
          }

          if (securityService.hasAnyRole('ROLE_SUPERVISOR', 'DELETE_BUSINESS')) {
            actions.push('delete');
          }
        }

        self.config = {
          urlRoute: '/operator_data/restaurant',
          registerActions: registeredActions,
          enableSorting: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          enableAdd: false,
          columns: [
            {name: 'id', width: 80, enableCellEdit: false, visible: false},
            {name: 'name', width: 166},
            {name: 'realName', width: 166},
            {name: 'address', width: 166},
            {name: 'language', width: 126},
            {name: 'currency', width: 121},
            {name: 'live', width: 88, enableCellEdit: false},
            {name: 'liveDate', width: 131, cellFilter: "date:'short'", enableCellEdit: false},
            {name: 'modifiedMasterData', width: 158, cellFilter: "date:'short'", enableCellEdit: false},
            {name: 'operate', actions: actions}
          ]
        };
      }
    )
    .controller('RestaurantCloneOperatorActionCtrl',
      function ($uibModal, notifyManager, $translate, $timeout, entity, gridApi, CloneRestaurantOperator, RestaurantCloneOperators) {

        $uibModal.open({
          template: require('../../../main/angular/app/template/page/admin/restaurant_clone_form.html'),
          controller: 'RestaurantCloneOperatorFormCtrl',
          windowClass: 'gf-modal',
          resolve: {
            cloneRestaurant: CloneRestaurantOperator.load({restaurantId: entity.id}).$promise,
            operators: RestaurantCloneOperators.get().$promise
          }
        }).result.then(function (cloneRestaurant) {
          var pleasWaiteDialog = $uibModal.open({
            template: require('../../../main/angular/app/template/page/please_wait.html'),
            windowClass: 'gf-modal'
          });

          cloneRestaurant.$clone({restaurantId: entity.id}, function (data) {
            if (data.ok) {
              notifyManager.success($translate.instant('message.restaurant.cloned.subject'));
              gridApi.grid.options.reloadData();
            } else {
              notifyManager.error($translate.instant(data.message));
            }

            $timeout(function () {
              pleasWaiteDialog.close();
            }, 0);
          });

        });
      }
    )
    .controller('RestaurantCloneOperatorFormCtrl',
      function ($scope, $uibModalInstance, cloneRestaurant, operators) {
        $scope.cloneRestaurant = cloneRestaurant;
        angular.extend($scope, operators.payload);

        $scope.ok = function () {
          $uibModalInstance.close(cloneRestaurant);
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
      }
    )
    .controller('RestaurantMasterActionCtrl',
      function ($scope, $resource, RestaurantMasterSwitch, uiGridConstants, entity, gridApi) {
        if (!entity.active || !entity.branchControl || entity.master) {
          return;
        }
        var switchMaster = $resource(RestaurantMasterSwitch, {id: entity.id});
        switchMaster.save(function (data) {
          if (data.ok) {
            // reset old
            var previousMasterRow = _.find(gridApi.grid.rows, function (row) {
              return row.entity.master;
            });
            if (previousMasterRow) {
              previousMasterRow.entity.master = false;
            }

            // set up new
            entity.master = true;

            gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
          }
        });
      }
    )

    .factory('prepareReportSettings', function () {
      return function (allReportSettings, activeReportSettings) {
        return _
          .chain(allReportSettings)
          .map(function (obj) {
            obj.active = _.some(activeReportSettings, {id: obj.id});
            return obj;
          })
          .groupBy(function (obj) {
            if (obj.name.indexOf('Z_REPORT') >= 0) {
              return 'ZReports';
            }
            if (obj.name.indexOf('X_REPORT') >= 0) {
              return 'XReports';
            }
            return 'other';
          })
          .value();
      }
    })

    .factory('prepareReportSettingsToSend', function () {
      return function (reportSettings) {
        return _
          .chain(reportSettings)
          .flatMap(function (obj) {
            return obj;
          })
          .filter(['active', true])
          .value();
      }
    })
})
();
