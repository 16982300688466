import React from 'react';
import {DataGrid} from '@material-ui/data-grid';
import {useTranslation} from 'react-i18next';

function LSDataGrid({
                      columns,
                      rows,
                      paginationMode,
                      sortingMode,
                      onPageChange,
                      onSortModelChange,
                      loading,
                      pageSize,
                      rowCount,
                      hideFooterPagination,
                      sortModel,
                      page
                    }) {
  const {t} = useTranslation();

  const applyColumnsTranslate = columns => (columns.map(col => (
    {
      renderHeader: params => (
        <div className={'MuiDataGrid-colCellTitle'}>
          {t(params.colDef.headerName)}
        </div>
      ),
      ...col
    }
  )))

  const onSortChange = data => {
    onSortModelChange({
      sort: data.sortModel.length ? data.sortModel[0].sort : undefined,
      field: data.sortModel.length ? data.sortModel[0].field : undefined
    })
  }

  return <DataGrid
    rows={rows}
    page={page}
    columns={applyColumnsTranslate(columns)}
    paginationMode={paginationMode || 'server'}
    sortingMode={sortingMode || 'server'}
    rowCount={rowCount}
    pageSize={pageSize || 20}
    onPageChange={onPageChange}
    onSortModelChange={onSortModelChange && onSortChange}
    sortModel={sortModel?.field && sortModel?.sort ? [sortModel] : []}
    loading={loading}
    autoHeight
    disableColumnMenu
    disableSelectionOnClick
    disableColumnSelector
    hideFooterPagination={hideFooterPagination || false}
  />
}

export default LSDataGrid;
