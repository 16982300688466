import React from 'react';
import ReactDOM from 'react-dom';
import {MuiThemeProvider} from '@material-ui/core';
import theme from './config.theme';
import {SnackbarProvider} from 'notistack';

import FiscalDevice from '../gf-setup-tse/js/FiscalDevice';
import TsePrinterFirmware from '../gf-adminarea-tse-printer-firmware/js/TsePrinterFirmware';
import News from '../gf-news/js/News';
import NewReport from '../gf-report-new/js/NewReport';
import axios from "axios";
import CompanyInfo from '../gf-setup-tse/js/company_info/CompanyInfo';
import PaymentReportingAdapter
  from '../gf-report-new/js/payment-reporting/PaymentReportingAdapter';
import FinancialServices from '../gf-financial-services/js/FinancialServices';

(function () {
  'use strict';
  angular.module('gf.react', [])

    .directive('reactComponent', function (securityService, $state) {
      return {
        template: '<div id="reactapp" class="react-part" data-testid="react-app"></div>',
        scope: {
          name: '@name'
        },
        link: function (scope, el, attrs) {

          const ReactComponents = {
            TsePrinterFirmware,
            News,
            NewReport,
            FiscalDevice,
            CompanyInfo,
            PaymentReportingAdapter,
            FinancialServices
          };

          const Component = ReactComponents[scope.name];

          // Add a response interceptor
          // todo: move to config near the $http interceptor
          axios.interceptors.response.use(function (response) {
            return response;
          }, function (error) {
            if ([401, 403].indexOf(error.response.status) !== -1) {
              $state.go('clean.login');
            }
            return Promise.reject(error);
          });

          const componentRef = React.createRef();

          ReactDOM.render(
            <MuiThemeProvider theme={theme}>
              <SnackbarProvider>
                <Component security={securityService} $state={$state} data-testid="react-component"/>
              </SnackbarProvider>
            </MuiThemeProvider>,
            document.getElementById('reactapp')
          );

          scope.$on('$destroy', () => {
            if (scope.name !== "FinancialServices") {
              return;
            }
              if (componentRef.current && componentRef.current.cleanup) {
                componentRef.current.cleanup();
              }
              ReactDOM.unmountComponentAtNode(
                document.getElementById("reactapp")
              );
          });
        }
      }
    })
})();
