import React, {useState} from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {IconButton, Paper, TableCell} from '@material-ui/core';
import TableBody from '@material-ui/core/TableBody';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Card from './Card';
import Currency from "./Currency";
import {useTranslation} from "react-i18next";

// todo Replace this custom implementation of collapsible table by common component once we need the same somewhere
function SettlementSummaryTable({data}) {
  const {t} = useTranslation();
  return (
    <TableContainer component={ContainerComponent}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{width: 30}}/>
            <TableCell>{t('lsp.dateCaptured')}</TableCell>
            <TableCell align="right">{t('lsp.sales')}</TableCell>
            <TableCell align="right">{t('lsp.refunds')}</TableCell>
            <TableCell align="right">{t('lsp.fees')}</TableCell>
            <TableCell align="right">{t('lsp.net')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(data) && data.map((row) => (
            <CollapsibleRow row={row}/>
          ))}
          {
            _.isEmpty(data) &&
            <>
              <TableRow align="center">
                <TableCell colspan="12" align="center">No rows</TableCell>
              </TableRow>
            </>
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function ContainerComponent({children}) {
  return <Paper variant={'outlined'} elevation={0}>{children}</Paper>
}

function CollapsibleRow({row}) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <ArrowDropDownIcon/> : <ArrowRightIcon/>}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.date}</TableCell>
        <TableCell align="right"><Currency value={row.amount} currency={row.currency}/></TableCell>
        <TableCell align="right"><Currency value={row.refunds} currency={row.currency}/></TableCell>
        <TableCell align="right"><Currency value={row.fees} currency={row.currency}/></TableCell>
        <TableCell align="right"><Currency value={row.netDeposits} currency={row.currency}/></TableCell>
      </TableRow>
      {
        open &&
        row.details.map((childRow, index) => (
            <HiddenRow row={childRow} currency={row.currency} {...(index === 0
              ? {firstRowSpan: row.details.length} : {})}/>
          )
        )
      }
    </>
  )
}

function HiddenRow({row, currency, firstRowSpan}) {
  return (
    <TableRow style={{background: '#F9F9F9'}}>
      {firstRowSpan && <TableCell rowSpan={firstRowSpan}/>}
      <TableCell component="th" scope="row"><Card type={row.cardType}/></TableCell>
      <TableCell align="right"><Currency value={row.amountGross} currency={currency}/></TableCell>
      <TableCell align="right"><Currency value={row.amountRefunds} currency={currency}/></TableCell>
      <TableCell align="right"><Currency value={row.amountFees} currency={currency}/></TableCell>
      <TableCell align="right"><Currency value={row.amountNet} currency={currency}/></TableCell>
    </TableRow>
  )
}

export default SettlementSummaryTable;
