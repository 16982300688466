(function () {
  'use strict';
  angular.module('gf.open.api', ['ui.grid.selection'])

    .run(function ($templateCache) {
      $templateCache.put('app/template/terms_and_conditions/terms_en.html', require('./../template/terms_and_conditions/terms_en.html'));
      $templateCache.put('app/template/terms_and_conditions/terms_de.html', require('./../template/terms_and_conditions/terms_de.html'));
      $templateCache.put('app/template/faq/faq_en.html', require('./../template/faq/faq_en.html'));
      $templateCache.put('app/template/faq/faq_de.html', require('./../template/faq/faq_de.html'));
      $templateCache.put('app/template/faq/faq_packages/faq_en.html', require('./../template/faq/faq_packages/faq_en.html'));
      $templateCache.put('app/template/faq/faq_packages/faq_de.html', require('./../template/faq/faq_packages/faq_de.html'));
      $templateCache.put('app/template/getting-started/getting_started_en.html', require('./../template/getting-started/getting_started_en.html'));
      $templateCache.put('app/template/getting-started/getting_started_de.html', require('./../template/getting-started/getting_started_de.html'));
    })

    .factory('Partners', function ($resource, appConfig, securityService) {
      return $resource(appConfig.baseUrl + '/icash/open_api/partners', {}, {
        load: {method: 'GET',
          isArray: true,
          headers: {
            'X-Business-Units': securityService.context.currentRestaurant.id,
            'X-Operator': securityService.context.currentOperator.id
          }}
      })
    })
    .factory('Categories', function ($resource, appConfig, securityService) {
      return $resource(appConfig.baseUrl + '/icash/open_api/categories', {}, {
        load: {method: 'GET',
          isArray: true,
          headers: {
            'X-Business-Units': securityService.context.currentRestaurant.id,
            'X-Operator': securityService.context.currentOperator.id
          }}
      })
    })
    .factory('Partner', function ($resource, appConfig, securityService) {
      return $resource(appConfig.baseUrl + '/icash/open_api/partners/:partnerId', {}, {
        get: {
          method: 'GET',
          headers: {
            'X-Business-Units': securityService.context.currentRestaurant.id,
            'X-Operator': securityService.context.currentOperator.id
          }
        }
      });
    })
    .factory('OperatorRestaurants', function ($resource, appConfig, securityService) {
      return $resource(appConfig.baseUrl + '/icash/open_api/restaurants', {}, {
        load: {method: 'GET',
          isArray: true,
          headers: {
            'X-Business-Units': securityService.context.currentRestaurant.id,
            'X-Operator': securityService.context.currentOperator.id
          }}
      })
    })
    .factory('OperatorTokens', function ($resource, appConfig, securityService) {
      return $resource(appConfig.baseUrl + '/icash/open_api/operators/:operatorId/tokens', {}, {
        load: {method: 'GET',
          isArray: true,
          headers: {
            'X-Business-Units': securityService.context.currentRestaurant.id,
            'X-Operator': securityService.context.currentOperator.id
          }}
      });
    })
    .factory('Apis', function ($resource, appConfig, securityService) {
      return $resource(appConfig.baseUrl + '/icash/open_api/services', {}, {
        load: {method: 'GET',
          isArray: true,
          headers: {
            'X-Business-Units': securityService.context.currentRestaurant.id,
            'X-Operator': securityService.context.currentOperator.id
          }}
      });
    })
    .factory('ApiCurrentData', function ($resource, appConfig, securityService) {
      return $resource(appConfig.baseUrl + '/icash/open_api/change_plan', {}, {
        load: {method: 'GET', headers: {
            'X-Business-Units': securityService.context.currentRestaurant.id,
            'X-Operator': securityService.context.currentOperator.id
          }}
      });
    })
    .factory('Subscription', function ($resource, appConfig, securityService) {
      return $resource(appConfig.baseUrl + '/icash/open_api/operators/:operatorId/package-subscriptions', {}, {
        load: {method: 'GET',
          isArray: true,
          headers: {
            'X-Business-Units': securityService.context.currentRestaurant.id,
            'X-Operator': securityService.context.currentOperator.id
          }
        }
      });
    })
    .factory('Package', function ($resource, appConfig, securityService) {
      return $resource(appConfig.baseUrl + '/icash/open_api/packages', {}, {
        load: {method: 'GET',
          isArray: true,
          headers: {
            'X-Business-Units': securityService.context.currentRestaurant.id,
            'X-Operator': securityService.context.currentOperator.id
          }}
      });
    })
    .factory('Currency', function ($resource, appConfig, securityService) {
      return $resource(appConfig.baseUrl + '/icash/open_api/currency', {}, {
        load: {method: 'GET',
          isArray: true,
          headers: {
            'X-Business-Units': securityService.context.currentRestaurant.id,
            'X-Operator': securityService.context.currentOperator.id
          }}
      });
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.apiConnection', {
          url: '/api_connection',
          template: require('../template/api_connections.html'),
          controller: 'ApiConnectionGridCtrl',
          controllerAs: '$ctrl',
          resolve: {
            partners: function (Partners) {
              return Partners.load().$promise;
            },
            categories: function (Categories) {
              return Categories.load().$promise;
            },
            subscriptions: function (Subscription, securityService) {
              return Subscription.load({operatorId: securityService.context.currentOperator.id}).$promise;
            },
            packages: function (Package) {
              return Package.load().$promise;
            },
            currencies: function (Currency) {
              return Currency.load().$promise;
            },
            tokens: function (OperatorTokens, securityService) {
              return OperatorTokens.load({operatorId: securityService.context.currentOperator.id}).$promise;
            }
          },
          title: "connections"
        })
        .state('page.apiConnectionDetails', {
          url: '/api_connection/:id',
          template: require('../template/api_connection.html'),
          controller: 'ApiConnectionCtrl',
          controllerAs: '$ctrl',
          resolve: {
            partner: function (Partner, $stateParams) {
              return Partner.get({partnerId: $stateParams.id}).$promise;
            },
            restaurants: function (OperatorRestaurants) {
              return OperatorRestaurants.load().$promise;
            },
            tokens: function (OperatorTokens, securityService) {
              return OperatorTokens.load({operatorId: securityService.context.currentOperator.id}).$promise;
            },
            subscriptions: function (Subscription, securityService) {
              return Subscription.load({operatorId: securityService.context.currentOperator.id}).$promise;
            }
          },
          title: "connection"
        })
        .state('page.apiForDevelopers', {
          url: '',
          abstract: true,
          resolve: {
            restaurants: function (OperatorRestaurants) {
              return OperatorRestaurants.load().$promise;
            },
            subscriptions: function (Subscription, securityService) {
              return Subscription.load({operatorId: securityService.context.currentOperator.id}).$promise;
            },
            packages: function (Package) {
              return Package.load().$promise;
            },
            currencies: function (Currency) {
              return Currency.load().$promise;
            }
          },
          controller: 'ApiForDevelopersCtrl',
          views: {
            '': {
              template: require('../template/api_tabs.html'),
              controller: 'ApiForDevelopersCtrl',
              controllerAs: '$ctrl'
            }
          }
        })
        .state('page.apiForDevelopers.start', {
          url: '/api_for_developers',
          template: require('../template/getting-started/main_page.html'),
          controller: 'ApiForDevelopersCtrl',
          controllerAs: '$ctrl',
          title: "developers"
        })
        .state('page.apiForDevelopers.faq', {
          url: '/api_for_developers',
          template: require('../template/faq/main_page.html'),
          controller: 'ApiForDevelopersCtrl',
          controllerAs: '$ctrl',
          title: "developers"
        })
        .state('page.apiForDevelopers.developersTokens', {
          url: '/api_for_developers',
          template: require('../template/api_for_developers.html'),
          controller: 'DevelopersTokensCtrl',
          controllerAs: '$ctrl',
          resolve: {
            tokens: function (OperatorTokens, securityService) {
              return OperatorTokens.load({operatorId: securityService.context.currentOperator.id}).$promise;
            },
            apis: function (Apis) {
              return Apis.load().$promise;
            }
          },
          title: "developers"
        })
        .state('page.apiTariff', {
          url: '/api_pay_tariff',
          template: require('../template/api_pay/api_tariff_page.html'),
          resolve: {
            apis: function (Apis) {
              return Apis.load().$promise;
            },
            subscriptions: function (Subscription, securityService) {
              return Subscription.load({operatorId: securityService.context.currentOperator.id}).$promise;
            },
            packages: function (Package) {
              return Package.load().$promise;
            },
            currencies: function (Currency) {
              return Currency.load().$promise;
            }
          },
          controller: 'ApiTariffPageCtrl',
          controllerAs: '$ctrl',
          title: 'tariff'
        })
        .state('clean.openApiTerms', {
          url: '/terms_and_conditions/{lang:en|de}',
          templateUrl: function (params) {
            return 'app/template/terms_and_conditions/terms_' + params.lang + '.html';
          },
          controller: function (securityService) {
            var self = this;
            var currentLanguage = securityService.context.currentLanguageTag;
            self.themeTermsAndConditionsPage = function () {
              switch (_.toLower(securityService.context.theme.brand)) {
                case 'culinaro':
                  if (currentLanguage === 'de') {
                    return 'https://www.chefsculinar.com/pages/datenschutzbestimmungen.htm';
                  } else {
                    return 'https://www.chefsculinar.com/en/pages/privacystatement.htm';
                  }
                case 'sumup':
                  if (currentLanguage === 'de') {
                    return 'https://sumup.de/datenschutzbestimmungen/';
                  } else {
                    return 'https://sumup.com/privacy/';
                  }
                default:
                  if (currentLanguage === 'de') {
                    return 'https://www.lightspeedhq.de/allgemeine-geschaeftsbedingungen/';
                  } else {
                    return 'https://www.lightspeedhq.de/allgemeine-geschaeftsbedingungen/';
                  }
              }
            }
          },
          controllerAs: '$ctrl'
        })
        .state('clean.openApiTermsDefault', {
          url: '/terms_and_conditions/:lang',
          controller: function ($state) {
            $state.go('clean.openApiTerms', {lang: 'en'});
          }
        })
      ;
    })

    .controller('ApiTariffPageCtrl',
      function ($uibModal, apis, securityService, subscriptions, packages, subscriptionService, $filter, currencies,
                packageService, $location, $anchorScroll, themeService) {

        var self = this;

        self.apis = _.orderBy(apis, 'displayOrder');

        self.country = securityService.context.contractType.country
          ? securityService.context.contractType.country.code
          : 'DE';

        self.currentLanguage = securityService.context.currentLanguageTag.toUpperCase();

        function getAndExtendSubscription() {
          var currentSubscription = subscriptionService.getCurrentSubscription(subscriptions);
          var currentPackage = _.find(packages, {'_id': currentSubscription.packageId});
          return _.extend({}, currentSubscription, {packagePosition: currentPackage.packagePosition});
        }

        self.subscription = subscriptions.length > 0 ? getAndExtendSubscription() : '';

        self.packages = _.sortBy(packages, ['packagePosition']);

        self.platform = themeService.mapPlatform();

        self.scrollTo = function (id) {
          $location.hash(id);
          $anchorScroll();
        };

        var promoCounter = 0;
        self.packages = _.map(self.packages, function (pack) {
          var platformPrice = pack.price[self.platform];
          if (!platformPrice) return;
          var country = platformPrice[self.country] ? self.country : 'DE';
          if (!_.isEmpty(platformPrice[country].promotions)) {
            promoCounter = promoCounter + 1;
          }
          return _.extend({}, pack, {
            priceData: packageService.preparePriceData(pack, currencies),
            promoIndex: !_.isEmpty(platformPrice[country].promotions) ? promoCounter : ''
          });
        });

        self.terminatePlan = function (subscription) {
          subscriptionService.terminatePlan(subscription);
        };

        self.faqBlock = function (lang) {
          switch (lang) {
            case 'en':
              return 'app/template/faq/faq_packages/faq_en.html';
            case 'de':
              return 'app/template/faq/faq_packages/faq_de.html';
            default:
              return 'app/template/faq/faq_packages/faq_en.html';
          }
        };
      })

    .controller('ApiForDevelopersCtrl',
      function ($location, $state, $translate, securityService, restaurants, subscriptions, subscriptionService,
                packages, currencies, themeService) {
        var self = this;

        self.activeTab = $state.current.name === 'page.apiForDevelopers.start' ? 0 : 1;

        self.registeredBy = (securityService.context.contractType.country && securityService.context.contractType.country.name === 'Norway') ? 'norway' : securityService.context.theme.name;

        self.currencies = currencies;

        self.gtcSpecLink = '/client/assets/api/gtc_specs-v1.0-2020-03-03.pdf';

        self.gettingStartedPage = function (lang) {
          switch (lang) {
            case 'en':
              return 'app/template/getting-started/getting_started_en.html';
            case 'de':
              return 'app/template/getting-started/getting_started_de.html';
            default:
              return 'app/template/getting-started/getting_started_en.html';
          }
        };

        self.faqPage = function (lang) {
          switch (lang) {
            case 'en':
              return 'app/template/faq/faq_en.html';
            case 'de':
              return 'app/template/faq/faq_de.html';
            default:
              return 'app/template/faq/faq_en.html';
          }
        };

        self.subscription = subscriptionService.getCurrentSubscription(subscriptions);

        self.packages = _.sortBy(packages, ['packagePosition']);

        self.demoUrl = themeService.resolveDemoRegPageUrl();
      }
    )
    .controller('ApiConnectionGridCtrl',
      function (partners, $state, tokenService, $http, $scope, securityService, categories, $translate, subscriptions,
                packages, subscriptionService, $filter, currencies, tokens) {
        var self = this;

        self.currencies = currencies;

        self.subscription = subscriptionService.getCurrentSubscription(subscriptions);

        self.packages = _.sortBy(packages, ['packagePosition']);

        var currentLanguage = securityService.context.currentLanguageTag.toUpperCase();

        self.partners = preparePartnersTable();

        self.platform = _.toLower(securityService.context.brand);

        function getPartnerPackage(partner, packages) {
          var partnerPackages = _.map(partner.compatiblePackages, function (pack) {
            return pack.packageName;
          });
          return _.find(_.sortBy(packages, ['packagePosition']), function (pack) {
            return _.includes(partnerPackages, pack.name);
          })
        }

        function preparePartnersTable() {
          var filteredPartners = _.filter(partners, function (partner) {
            return _.includes(_.map(partner.displayAt, _.method('toLowerCase')), securityService.context.theme.brand.toLowerCase())
          });
          return _.map(filteredPartners, function (partner) {
            partner.description = partner.description[currentLanguage] ? partner.description[currentLanguage] : partner.description['EN'];
            var partnerPackage = getPartnerPackage(partner, packages);
            return _.extend({}, partner, {
              'package': partnerPackage ? partnerPackage.name : '',
              'token': _.find(tokens, {partnerId: partner._id})
            });
          })
        }

        function isActivatedPartner(entity) {
          if (!self.subscription) {
            return false;
          }
          return entity.token && _.difference(entity.enabledApis, self.subscription.enabledApis).length === 0;
        }

        function isEditablePartner(entity) {
          if (!self.subscription) {
            return;
          }
          return _.difference(entity.enabledApis, self.subscription.enabledApis).length === 0;
        }

        var allCategories = $translate.instant('openApi.allServices');
        self.categories = [allCategories];
        self.category = allCategories;
        var usedCategories = _.map(_.filter(categories, function (c) {
          c.name = c.name[currentLanguage] ? c.name[currentLanguage] : c.name['EN'];
          return _.some(self.partners, function (p) {
            return p.category === c._id
          })
        }), 'name');
        self.categories = self.categories.concat(usedCategories);

        self.config = {
          showOnlyActive: true,
          rowHeight: 40,
          excessRows: 200,
          columnDefs: [
            {
              name: 'id',
              field: 'id',
              visible: false
            },
            {
              name: 'name',
              field: 'name',
              width: 300
            },
            {
              name: 'description',
              field: 'description',
              cellTemplate: "<div uib-tooltip=\"{{row.entity.description}}\" tooltip-append-to-body=\"true\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD}}</div>"
            },
            {
              name: 'category',
              field: 'category',
              visible: false
            },
            {
              name: 'package',
              field: 'package',
              width: 100,
              visible: true
            },
            {
              name: ' ',
              width: 100,
              cellTemplate: '<div class="action-cell-api" uib-tooltip="{{\'openApi.apiConnections.createSubscription\' | translate}}" tooltip-enable="!grid.options.actions.isEditable(row.entity)"><button type="button" class="action-button btn btn-xs btn-transparent" ' +
                'ng-class="{\'action-btn-success\': grid.options.actions.isActivated(row.entity),\'action-btn-danger\': !grid.options.actions.isActivated(row.entity)}" ' +
                'id="{{\'grid_activate_deactivate_action_button-\' + row.entity._id}}"' +
                'ng-click="grid.options.actions.deleteToken(row.entity)" ng-style="{\'visibility\': grid.options.actions.isActivated(row.entity)?\'visible\':\'hidden\'}">\n' +
                '<i uib-tooltip="{{\'openApi.apiConnections.tokenGenerated\' | translate}}" class="fa" ng-class="{\'fa-check-square-o\': grid.options.actions.isActivated(row.entity), \'fa-ban\': !grid.options.actions.isActivated(row.entity)}"></i>\n' +
                '</button>' +
                '<button id="{{\'grid_edit_action_button-\' + row.entity._id}}" type="button" class="action-button btn btn-transparent btn-xs" ' +
                'ng-click="grid.options.actions.editPartner(row.entity)" ' +
                'uib-tooltip="{{\'openApi.setup\' | translate}}" ng-disabled="!grid.options.actions.isEditable(row.entity)"><i class="fa fa-pencil"></i></button></div>'
            }
          ],
          data: self.partners,
          actions: {
            editPartner: function (entity) {
              $state.go('page.apiConnectionDetails', {id: entity._id})
            },
            deleteToken: function (entity) {
              tokenService.deleteToken(entity.token._id);
            },
            isActivated: function (entity) {
              return isActivatedPartner(entity);
            },
            isEditable: function (entity) {
              return isEditablePartner(entity);
            }
          },
          onRegisterApi: function (gridApi) {
            self.gridApi = gridApi;
          }
        };
        self.chooseCategory = function (category) {
          if (category === allCategories) {
            self.config.data = self.partners;
            return;
          }
          self.config.data = _.filter(self.partners, function (p) {
            return _.some(categories, function (c) {
              return c.name === category && p.category === c._id;
            })
          });
        };
        self.expandClass = 'gf-expanded-panel';
        self.changeExpandClass = function () {
          self.expandClass = self.expandClass === 'gf-expanded-panel' ? 'gf-no-expand' : 'gf-expanded-panel';
        };
        $scope.$on('deleteToken', function (event, tokenId) {
          self.partners = _.forEach(self.partners, function (p) {
            if (p.token && p.token._id === tokenId) {
              p.token = undefined;
            }
          })
        });
        $scope.$on('changeSubscription', function (event, subscription) {
          self.subscription = subscription.packageSubscription;
        });
        $scope.$on('deleteSubscription', function () {
          self.subscription = null;
        })
      }
    )

    .controller('ApiConnectionCtrl',
      function (restaurants, partner, tokens, tokenService, securityService, $translate, notifyManager, $scope,
                dateFormatService, $state, $uibModal, subscriptions, subscriptionService, packageService, $anchorScroll, themeService) {

        var self = this;

        self.restaurants = restaurants;
        self.partner = partner;

        self.subscription = subscriptionService.getCurrentSubscription(subscriptions);

        var defaultTokenData = {
          name: self.partner.name,
          partnerId: self.partner._id,
          operatorId: securityService.context.currentOperator.id,
          businessUnitsIds: [],
          platform: securityService.context.tenant
        };

        self.tokenData = _.find(tokens, {'partnerId': self.partner._id}) || defaultTokenData;

        self.config = {
          showOnlyActive: true,
          rowHeight: 40,
          enableSelectAll: true,
          multiSelect: true,
          selectionRowHeaderWidth: 37,
          excessRows: 200,
          // isRowSelectable: function (row) {
          //   return !self.partner.tokenId;
          // },
          columnDefs: [
            {
              displayName: $translate.instant("openApi.businessId"),
              name: 'id',
              field: 'id',
              width: 200
            },
            {
              name: 'name',
              field: 'name'
            }
          ],
          data: self.restaurants,
          onRegisterApi: function (gridApi) {
            self.gridApi = gridApi;
            if (self.tokenData._id) {
              selectBusinesses();
              // this.enableSelectAll = false;
              // this.noUnselect = true;
            }
          }
        };

        function selectBusinesses() {
          if (self.tokenData.businessUnitsIds.length) {
            self.gridApi.grid.modifyRows(self.config.data);
            _.forEach(self.tokenData.businessUnitsIds, function (unit) {
              self.gridApi.selection.selectRow(_.find(self.config.data, {'id': unit}));
            })
          }
        }

        self.formatDate = function (date) {
          return dateFormatService.formatDate(date);
        };
        self.deleteToken = function (tokenId) {
          tokenService.deleteToken(tokenId);
        };
        self.generateToken = function () {
          self.tokenData.businessUnitsIds = _.map(self.gridApi.selection.getSelectedRows(), 'id');
          if (!self.tokenData.businessUnitsIds || !self.tokenData.businessUnitsIds.length) {
            notifyManager.error($translate.instant('openApi.noBusinessUnit'));
            return;
          }

          self.unsubscribedBusinesses = _.difference(self.tokenData.businessUnitsIds, self.subscription.businessUnitsIds);

          if (self.unsubscribedBusinesses.length > 0) {
            $uibModal.open({
              windowClass: 'gf-modal',
              template: require('../template/api_pay/api_expand_package_modal.html'),
              resolve: {
                unsubscribedBusinesses: function () {
                  return self.unsubscribedBusinesses;
                }
              },
              controllerAs: '$ctrl',
              controller: function (unsubscribedBusinesses) {
                var self = this;

                self.unsubscribedBusinesses = unsubscribedBusinesses;
              }
            }).result.then(function (pack) {
              $state.go('page.apiTariff');
              $anchorScroll();
            })
          } else {
            tokenService.generateToken(self.tokenData, 'by_partner').then(function (response) {
              self.tokenData = response;
            })
          }
        };
        $scope.$on('deleteToken', function () {
          self.tokenData = defaultTokenData;
          self.gridApi.selection.clearSelectedRows();
        })
      })

    .controller('DevelopersTokensCtrl',
      function ($scope, restaurants, tokens, apis, securityService, $translate, notifyManager, tokenService, $uibModal,
                dateFormatService, subscriptions, subscriptionService, $state, packageService, $anchorScroll, themeService) {
        var self = this;

        self.subscription = subscriptionService.getCurrentSubscription(subscriptions);
        self.tokenPlatform = securityService.context.tenant;

        self.restaurants = restaurants;
        self.apis = _.filter(apis, {isAvailable: true});
        self.devTokens = getDevelopersTokens(tokens);
        self.demoUrl = '<a href=' + themeService.resolveDemoRegPageUrl() + '" target=\'_blank\'><strong>'
          + $translate.instant("openApi.registerTestAccount") + '</strong></a>.';

        function getDevelopersTokens(tokens) {
          return _.filter(tokens, function (t) {
            if (t.enabledApis) {
              t.enabledApis = changeApiName(t.enabledApis);
              return t;
            }
          })
        }

        function changeApiName(arr) {
          var apiNames = [];
          _.forEach(apis, function (a) {
            if (_.includes(arr, a.name)) {
              apiNames.push(a.displayedName)
            }
          });
          return apiNames;
        }

        self.tokenData = {
          name: '',
          operatorId: securityService.context.currentOperator.id,
          businessUnitsIds: [],
          enabledApis: [],
          platform: securityService.context.tenant
        };

        self.businessGridConfig = {
          showOnlyActive: true,
          rowHeight: 40,
          enableSelectAll: true,
          multiSelect: true,
          selectionRowHeaderWidth: 37,
          excessRows: 200,
          columnDefs: [
            {
              name: 'id',
              field: 'id',
              width: 200
            },
            {
              name: 'name',
              field: 'name'
            }
          ],
          data: self.restaurants,
          onRegisterApi: function (gridApi) {
            self.businessGridApi = gridApi;
          }
        };

        self.apiGridConfig = {
          showOnlyActive: true,
          rowHeight: 40,
          enableSelectAll: true,
          multiSelect: true,
          selectionRowHeaderWidth: 37,
          excessRows: 200,
          columnDefs: [
            {
              name: 'id',
              field: 'id',
              visible: false
            },
            {
              name: 'name',
              width: 200,
              field: 'displayedName'
            },
            {
              name: 'description',
              field: 'description["' + securityService.context.currentLanguageTag.toUpperCase() + '"]'
            },
            {
              name: ' ',
              width: 50,
              cellTemplate: '<div class="action-cell-api">' +
                '<button type="button" class="action-button btn btn-transparent btn-xs open-api-download-button" ' +
                'ng-click="grid.options.actions.downloadApiVersion(row.entity)">' +
                '<i class="fa fa-download"></i></button></div>'
            }
          ],
          data: _.orderBy(self.apis, 'displayOrder'),
          onRegisterApi: function (gridApi) {
            self.apiGridApi = gridApi;
          },
          actions: {
            downloadApiVersion: function (entity) {
              $uibModal.open({
                windowClass: 'gf-modal',
                template: require('../template/api_version_modal.html'),
                controller: function () {
                  var self = this;
                  self.api = entity;
                  self.hasAdditionalDocs = self.api.documentation && !_.isEmpty(self.api.documentation);
                },
                controllerAs: '$ctrl',
                backdrop: 'static'
              })
            }
          }
        };

        self.formatDate = function (date) {
          return dateFormatService.formatDate(date);
        };

        self.deleteToken = function (tokenId) {
          tokenService.deleteToken(tokenId);
        };

        self.generateToken = function () {
          if (!self.subscription) {
            $uibModal.open({
              windowClass: 'gf-modal',
              template: require('../template/api_pay/api_subscribe_modal.html'),
              controllerAs: '$ctrl',
              controller: function (securityService) {
                var self = this;
              }
            }).result.then(function (pack) {
              $state.go('page.apiTariff');
              $anchorScroll();
            });
            return;
          }
          self.tokenData.businessUnitsIds = _.map(self.businessGridApi.selection.getSelectedRows(), 'id');
          self.tokenData.enabledApis = _.map(self.apiGridApi.selection.getSelectedRows(), 'name');
          if (!self.tokenData.name) {
            self.noNameError = true;
            return;
          }
          if (!self.tokenData.businessUnitsIds.length || !self.tokenData.enabledApis.length) {
            notifyManager.error($translate.instant('openApi.noBusinessUnitAndApi'));
            return;
          }
          self.unsubscribedBusinesses = self.subscription ? _.difference(self.tokenData.businessUnitsIds, self.subscription.businessUnitsIds) : [];
          self.unsubscribedApis = self.subscription ? _.difference(self.tokenData.enabledApis, self.subscription.enabledApis) : [];

          if (self.unsubscribedBusinesses.length || self.unsubscribedApis.length) {
            $uibModal.open({
              windowClass: 'gf-modal',
              template: require('../template/api_pay/api_expand_package_modal.html'),
              resolve: {
                unsubscribedBusinesses: function () {
                  return self.unsubscribedBusinesses;
                },
                unsubscribedApis: function () {
                  return self.unsubscribedApis;
                }
              },
              controllerAs: '$ctrl',
              controller: function (unsubscribedBusinesses, unsubscribedApis) {
                var self = this;

                self.unsubscribedApis = unsubscribedApis;
                self.unsubscribedBusinesses = unsubscribedBusinesses;
              }
            }).result.then(function (pack) {
              $state.go('page.apiTariff');
              $anchorScroll();
            })
          } else {
            tokenService.generateToken(self.tokenData, 'by_api').then(function (response) {
              var newToken = response;
              newToken.enabledApis = changeApiName(response.enabledApis);
              self.devTokens = _.concat(self.devTokens, newToken);
              self.tokenValue = newToken.value;
            })
          }
        };

        $scope.$on('deleteToken', function (event, tokenId) {
          self.devTokens = _.filter(self.devTokens, function (t) {
            return t._id !== tokenId;
          });
        });
        $scope.$on('changeSubscription', function (event, subscription) {
          self.subscription = subscription.packageSubscription;
          self.devTokens = getDevelopersTokens(subscription.token);
        });
        $scope.$on('deleteSubscription', function () {
          self.subscription = null;
        })
      }
    )

    .factory('tokenService', function ($uibModal, $http, $translate, notifyManager, $rootScope, securityService) {
      return {
        deleteToken: function (tokenId) {
          if (!tokenId) return;
          $uibModal.open({
            windowClass: 'gf-modal',
            template: require('../template/token_delete_modal.html'),
            controller: function () {
              var self = this;
              self.tokenId = tokenId;
            },
            controllerAs: '$ctrl',
            backdrop: 'static'
          }).result.then(function (tokenId) {
            $http.delete('/icash/open_api/tokens/' + tokenId, {
              headers: {
                'X-Business-Units': securityService.context.currentRestaurant.id,
                'X-Operator': securityService.context.currentOperator.id
              }
            }).then(function () {
              notifyManager.success($translate.instant('openApi.tokenDeleted'));
              $rootScope.$broadcast('deleteToken', tokenId);
            }, function () {
              notifyManager.error($translate.instant('error.general'));
              $rootScope.$broadcast('deleteToken', tokenId);
            })
          })
        },
        generateToken: function (tokenData, tokenType) {
          return $http.post('/icash/open_api/tokens/' + tokenType, tokenData, {
            headers: {'X-Business-Units': tokenData.businessUnitsIds.join(',')}
          }).then(function (res) {
            notifyManager.success($translate.instant('openApi.tokenGenerated'));
            return res.data;
          }, function () {
            notifyManager.error($translate.instant('error.general'));
          });
        }
      }
    })

    .directive('apiPricing', function (securityService, $uibModal, $state, subscriptionService, packageService, themeService) {
      return {
        restrict: 'A',
        template: require('../template/api_pay/api_pricing_packages.html'),
        scope: {
          subscription: '=',
          packages: '=',
          currencies: '='
        },
        controller: function ($scope, $anchorScroll, securityService) {
          var self = this;
          self.subscription = $scope.subscription;
          self.securityService = securityService

          self.country = securityService.context.contractType.country
            ? securityService.context.contractType.country.code
            : 'DE';

          self.platform = themeService.mapPlatform();

          self.packages = _.map($scope.packages, function (pack) {
            return _.extend({}, pack, {priceData: packageService.preparePriceData(pack, $scope.currencies)});
          });

          self.currentLanguage = securityService.context.currentLanguageTag.toUpperCase();

          self.terminatePlan = function () {
            subscriptionService.terminatePlan(self.subscription);
          };

          self.showTariffPage = function () {
            $state.go('page.apiTariff');
            $anchorScroll();
          };

          $scope.$on('deleteSubscription', function () {
            self.subscription = undefined;
          })
        },
        controllerAs: '$ctrl'
      }
    })
    .component('gfApiSubscribe', {
      template: require('../template/api_pay/api_subscribe_button.html'),
      bindings: {
        package: '=',
        subscription: '='
      },
      controller: function ($uibModal, securityService, $scope, $http, subscriptionService) {
        var self = this;

        self.subscribeOrExpand = function () {
          subscriptionService.subscribeOrExpand(self.package, self.subscription);
        };

        $scope.$on('changeSubscription', function (event, subscription) {
          self.subscription = subscription.packageSubscription;
        });
        $scope.$on('deleteSubscription', function () {
          self.subscription = undefined;
        })
      }
    })
    .factory('subscriptionService', function ($rootScope, $uibModal, $http, securityService, notifyManager, $translate, themeService) {

      var country = securityService.context.contractType.country
        ? securityService.context.contractType.country.code
        : 'DE';
      var platform = securityService.context.tenant;
      var operatorId = securityService.context.currentOperator.id;

      function calculatePrice(rows, priceData) {
        if (priceData.discount) {
          var price = 0;
          var maxDiscountPrice = priceData.price - priceData.price * priceData.discountCutOff / 100;
          for (var i = 0; i < rows; i++) {
            var calculatedPrice = (priceData.price - priceData.price * (priceData.discount / 100 * i));
            if (calculatedPrice < maxDiscountPrice) {
              price = price + maxDiscountPrice;
            } else {
              price = price + calculatedPrice;
            }
          }
        } else {
          for (var i = 0; i < rows; i++) {
            price = priceData.price * rows;
          }
        }
        return Math.round(price * 100) / 100;
      }

      return {
        getCurrentSubscription: function (subscriptions) {
          return _.find(subscriptions, {
            'operatorId': securityService.context.currentOperator.id,
            'platform': platform
          });
        },
        subscribeOrExpand: function (newPackage, subscription) {
          /* new plan subscribe modal */
          $uibModal.open({
            windowClass: 'gf-modal',
            template: require('../template/api_pay/api_business_units_modal.html'),
            resolve: {
              businessUnits: function (OperatorRestaurants) {
                return OperatorRestaurants.load().$promise;
              },
              tokens: function (OperatorTokens, securityService) {
                return OperatorTokens.load({operatorId: securityService.context.currentOperator.id}).$promise;
              }
            },
            controller: function (businessUnits, $scope, tokens, securityService, $uibModalInstance) {
              var self = this;
              self.package = newPackage;
              self.businessUnits = businessUnits;
              self.subscription = subscription;
              self.price = self.subscription ? self.subscription.price : _.clone(self.package.priceData.price);
              self.currency = self.package.priceData.currency;
              self.tokens = tokens;
              self.promoDescription = !_.isEmpty(newPackage.priceData.promoDescription) ? newPackage.priceData.promoDescription : '';
              self.termsCheckbox = {agreeWithTerms: false};
              self.termValues = {
                brand: securityService.context.theme.brand,
                link1: '<a id="terms_link" href="#/terms_and_conditions/' + securityService.context.currentLanguageTag + '" target="_blank">',
                link1end: '</a>'
              };

              self.config = {
                showOnlyActive: true,
                rowHeight: 40,
                enableSelectAll: true,
                multiSelect: true,
                selectionRowHeaderWidth: 37,
                excessRows: 200,
                columnDefs: [
                  {
                    name: 'id',
                    field: 'id',
                    visible: true
                  },
                  {
                    name: 'name',
                    field: 'name',
                    width: 300
                  }],
                data: self.businessUnits,
                onRegisterApi: function (gridApi) {
                  self.gridApi = gridApi;
                  selectBusinesses();
                  if (self.subscription && (self.subscription.packagePosition !== self.package.packagePosition)) {
                    self.price = calculatePrice(self.subscription.businessUnitsIds.length, self.package.priceData);
                  }
                  gridApi.selection.on.rowSelectionChanged($scope, function (row) {
                    changeSelectedAndCalculate(self.gridApi)
                  });
                  gridApi.selection.on.rowSelectionChangedBatch($scope, function (row) {
                    changeSelectedAndCalculate(self.gridApi)
                  })
                }
              };

              function changeSelectedAndCalculate(gridApi) {
                var selectedRows = gridApi.selection.getSelectedCount();
                self.selectedBusinessUnits = gridApi.selection.getSelectedRows();
                self.price = (selectedRows > 0) ? calculatePrice(selectedRows, newPackage.priceData) : 0;
              }

              function selectBusinesses() {
                if (self.businessUnits.length) {
                  /* if subscription exists - select business units from the subscription, otherwise - current business unit */
                  if (self.subscription) {
                    self.gridApi.grid.modifyRows(self.config.data);
                    _.forEach(self.subscription.businessUnitsIds, function (unit) {
                      self.gridApi.selection.selectRow(_.find(self.config.data, {'id': unit}));
                      self.selectedBusinessUnits = self.gridApi.selection.getSelectedRows();
                    })
                  } else {
                    var currentBusinessUnitId = securityService.context.currentRestaurant.id;
                    self.gridApi.grid.modifyRows(self.config.data);
                    self.gridApi.selection.selectRow(_.find(self.config.data, {'id': currentBusinessUnitId}));
                    self.selectedBusinessUnits = self.gridApi.selection.getSelectedRows();
                  }
                }
              }

              self.submitBusinessUnitForm = function (subscriptionData) {
                if (!subscriptionData.selectedBusinessUnits.length) {
                  notifyManager.error($translate.instant('openApi.noBusinessUnit'));
                } else {
                  $uibModalInstance.close(subscriptionData);
                }
              }
            },
            controllerAs: '$ctrl',
            backdrop: 'static'
          }).result.then(function (data) {
            var subscriptionData = {
              "businessUnitsIds": _.map(data.selectedBusinessUnits, 'id'),
              "country": country,
              "platform": platform,
              "packageId": data.package._id,
              "language": securityService.context.currentLanguageTag.toUpperCase(),
              "operatorId": operatorId,
              "extendTokens": false
            };

            function checkUsedBusinessUnits(tokens, selectedBusinessUnits) {
              return _.map(_.filter(tokens, function (token) {
                return _.difference(token.businessUnitsIds, selectedBusinessUnits).length > 0;
              }), 'name');
            }

            function showExpandTokenModal(pack, subscription) {
              $uibModal.open({
                windowClass: 'gf-modal',
                template: require('../template/api_pay/api_expand_token_modal.html'),
                controllerAs: '$ctrl',
                controller: function () {
                  var self = this;
                  self.subscription = subscription;
                  self.package = pack;
                }
              }).result.then(function (data) {
                subscriptionData.extendTokens = data.expandExistingTokens;
                $http.put('/icash/open_api/package-subscriptions/' + data.subscription._id, subscriptionData, {
                  headers: {
                    'X-Business-Units': subscriptionData.businessUnitsIds.join(','),
                    'X-Operator': securityService.context.currentOperator.id
                  }
                }).then(function (res) {
                  notifyManager.success($translate.instant('openApi.paymentPlanChanged'));
                  $rootScope.$broadcast('changeSubscription', _.extend({}, res.data, {packagePosition: data.package.packagePosition}));
                })
              })
            }

            function changePlan(newPackage, subscription, selectedBusinessUnits) {
              /* change plan modal */
              $uibModal.open({
                windowClass: 'gf-modal',
                template: require('../template/api_pay/api_change_plan_modal.html'),
                resolve: {
                  apiCurrentData: function (ApiCurrentData) {
                    return ApiCurrentData.load().$promise;
                  }
                },
                controller: function (apiCurrentData) {
                  var self = this;
                  self.package = newPackage;
                  self.subscription = subscription;
                  self.selectedBusinessUnits = selectedBusinessUnits;

                  self.isUpgrade = self.subscription.packagePosition < self.package.packagePosition;

                  var currentPartners = _.filter(apiCurrentData.partners, 'tokenId');
                  var currentTokens = apiCurrentData.tokens;

                  self.deactivatingPartners = _.filter(currentPartners, function (partner) {
                    return _.difference(partner.enabledApis, newPackage.enabledApis).length > 0;
                  });
                  self.deactivatingTokens = _.filter(currentTokens, function (token) {
                    return _.difference(token.enabledApis, newPackage.enabledApis).length > 0;
                  });

                  self.extendWithBusinessUnits = _.difference(_.map(self.selectedBusinessUnits, 'id'), self.subscription.businessUnitsIds).length > 0;

                  self.isDeactivate = self.deactivatingPartners.length > 0 || self.deactivatingTokens.length > 0;
                },
                controllerAs: '$ctrl',
                backdrop: 'static'
              }).result.then(function (data) {
                var subscriptionData = {
                  "businessUnitsIds": _.map(data.selectedBusinessUnits, 'id'),
                  "country": country,
                  "platform": platform,
                  "packageId": data.package._id,
                  "language": securityService.context.currentLanguageTag.toUpperCase(),
                  "operatorId": operatorId,
                  "extendTokens": false
                };
                if (data.isExtend && data.isDeactivate && !data.isUpgrade) {
                  showExpandTokenModal(data.package, data.subscription);
                } else {
                  subscriptionData.extendTokens = data.expandExistingTokens;
                  $http.put('/icash/open_api/package-subscriptions/' + data.subscription._id, subscriptionData, {
                    headers: {
                      'X-Business-Units': subscriptionData.businessUnitsIds.join(','),
                      'X-Operator': securityService.context.currentOperator.id
                    }
                  }).then(function (res) {
                    notifyManager.success($translate.instant('openApi.paymentPlanChanged'));
                    $rootScope.$broadcast('changeSubscription', _.extend({}, res.data, {packagePosition: data.package.packagePosition}));
                  })
                }
              });
            }

            function expandOrReduceExistingPlan(pack, subscription, selectedBusinessUnits, deactivatedTokens) {
              if (_.difference(_.map(selectedBusinessUnits, 'id'), subscription.businessUnitsIds).length > 0
                && deactivatedTokens.length === 0) {
                showExpandTokenModal(pack, subscription)
              } else {
                $http.put('/icash/open_api/package-subscriptions/' + subscription._id, subscriptionData, {
                  headers: {
                    'X-Business-Units': subscriptionData.businessUnitsIds.join(','),
                    'X-Operator': securityService.context.currentOperator.id
                  }
                }).then(function (res) {
                  notifyManager.success($translate.instant('openApi.paymentPlanChanged'));
                  $rootScope.$broadcast('changeSubscription', _.extend({}, res.data, {packagePosition: pack.packagePosition}));
                })
              }
            }

            if (data.subscription) {
              var deactivatedTokens = checkUsedBusinessUnits(data.tokens, _.map(data.selectedBusinessUnits, 'id'));
              if (deactivatedTokens.length > 0) {
                $uibModal.open({
                  windowClass: 'gf-modal',
                  template: require('../template/api_pay/api_reduce_token_modal.html'),
                  controllerAs: '$ctrl',
                  controller: function () {
                    var self = this;
                    self.deactivatedTokens = deactivatedTokens;
                    self.subscription = data.subscription;
                    self.selectedBusinessUnits = data.selectedBusinessUnits;
                    self.package = data.package
                  }
                }).result.then(function (data) {
                  if (data.subscription.packagePosition !== data.package.packagePosition) {
                    changePlan(data.package, data.subscription, data.selectedBusinessUnits);
                  } else {
                    $http.put('/icash/open_api/package-subscriptions/' + data.subscription._id, subscriptionData, {
                      headers: {
                        'X-Business-Units': subscriptionData.businessUnitsIds.join(','),
                        'X-Operator': securityService.context.currentOperator.id
                      }
                    }).then(function (res) {
                      notifyManager.success($translate.instant('openApi.paymentPlanChanged'));
                      $rootScope.$broadcast('changeSubscription', _.extend({}, res.data, {packagePosition: data.package.packagePosition}));
                      if (_.difference(_.map(data.selectedBusinessUnits, 'id'), data.subscription.businessUnitsIds).length > 0) {
                        showExpandTokenModal(data.subscription)
                      }
                    })
                  }
                })
              } else {
                if (data.subscription.packagePosition !== data.package.packagePosition) {
                  changePlan(data.package, data.subscription, data.selectedBusinessUnits);
                } else {
                  expandOrReduceExistingPlan(data.package, data.subscription, data.selectedBusinessUnits, deactivatedTokens);
                }
              }
            } else {
              subscriptionData.extendTokens = true;
              $http.post('/icash/open_api/package-subscriptions', subscriptionData, {
                headers: {
                  'X-Business-Units': subscriptionData.businessUnitsIds.join(','),
                  'X-Operator': securityService.context.currentOperator.id
                }
              }).then(function (res) {
                notifyManager.success($translate.instant('openApi.paymentPlanCreated'));
                $rootScope.$broadcast('changeSubscription', _.extend({}, res.data, {packagePosition: data.package.packagePosition}));
              }, function () {
                notifyManager.error($translate.instant('openApi.failedToCreatePaymentPlan'));
              })
            }
          })
        },
        terminatePlan: function (subscription) {
          $uibModal.open({
            windowClass: 'gf-modal',
            template: require('../template/api_pay/api_terminate_plan_modal.html'),
            controller: function () {
              var self = this;
              self.subscription = subscription;
            },
            controllerAs: '$ctrl',
            backdrop: 'static'
          }).result.then(function (subscription) {
            $http.delete('/icash/open_api/package-subscriptions/' + subscription._id, {
              headers: {
                'X-Business-Units': securityService.context.currentRestaurant.id,
                'X-Operator': securityService.context.currentOperator.id
              }
            }).then(function (res) {
              notifyManager.success($translate.instant('openApi.paymentPlanTerminated'));
              $rootScope.$broadcast('deleteSubscription');
            });
          })
        }
      }
    })
    .factory('packageService', function (securityService, themeService) {

      var country = securityService.context.contractType.country
        ? securityService.context.contractType.country.code
        : 'DE';

      var platform = themeService.mapPlatform();

      return {
        preparePriceData: function (pack, currencies) {
          //by default use German price, which should be always configured
          var priceCountry = 'DE';
          if (!pack.price[platform]) {
            return
          }
          if (pack.price[platform][country]) {
            priceCountry = country;
          }
          var promo = pack.price[platform][priceCountry].promotions;
          if (!_.isEmpty(pack.price[platform][priceCountry].promotions)) {
            return {
              price: promo['promoPrice'],
              promoExpiryDate: promo['expiryDate'],
              promoDescription: promo['promoDescription'],
              currency: _.find(currencies, {'code': pack.price[platform][priceCountry]['currency']}).symbol
            }
          } else {
            return {
              price: pack.price[platform][priceCountry]['price'],
              discount: pack.price[platform][priceCountry]['discountSize'],
              discountCutOff: pack.price[platform][priceCountry]['discountCutOff'],
              currency: _.find(currencies, {'code': pack.price[platform][priceCountry]['currency']}).symbol
            }
          }
        }
      }
    })
    .factory('themeService', function (securityService) {
      return {
        resolveDemoRegPageUrl: function () {
          var platform = _.toLower(securityService.context.brand);
          var demoUrl = function () {
            switch (_.toLower(securityService.context.theme.brand)) {
              case 'culinaro':
                return 'https://demo.culinaro.de/register-open-api/';
              case 'sumup':
                return 'http://posdemo.sumup.com/register-open-api/';
              default:
                return 'https://demo-creating.gastrofix.com/openapilightspeeddemostaging/';
            }
          };
          return demoUrl() + '?registeredBy=' + platform;
        },
        mapPlatform: function () {
          switch (_.toLower(securityService.context.theme.brand)) {
            case 'culinaro':
              return 'CU';
            case 'sumup':
              return 'SU';
            default:
              return 'LS';
          }
        }
      }
    })

    .filter('apiAvailable', function () {
      return function (packageApis, currentApi) {
        return _.includes(packageApis, currentApi);
      }
    })
    .filter('partnerPackageFilter', function () {
      return function (partner, packages) {
        var partnerPackages = _.map(partner.compatiblePackages, function (pack) {
          return pack.packageName;
        });
        return _.find(_.sortBy(packages, ['packagePosition']), function (pack) {
          return _.includes(partnerPackages, pack.name);
        })
      }
    })
    .filter('languageDescription', function () {
      return function (descriptions, currentLanguage) {
        if (!descriptions) return;
        return descriptions[currentLanguage] ? descriptions[currentLanguage] : descriptions['EN'];
      };
    })
    .filter('priceFormat', function () {
      return function (price) {
        if (_.isUndefined(price)) return;
        return price.toFixed(2).replace('.', ',')
      }
    })
})();
