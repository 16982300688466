(function () {
  'use strict';
  angular.module('gf.bm.menu', [])

    .run(function ($rootScope, BM_MENU) {
      $rootScope.bmMenu = BM_MENU;
    })

    .constant('BM_MENU',
      [
        {
          translate: 'menu.dashboard',
          topmenu: 'dashboard',
          access: function (security) {
            return security.hasAccess("/bm_dashboard");
          },
          submenu: [
            {
              translate: 'date.today',
              sref: 'bm.dashboardDateType',
              stateParams: {dateType: "1"}
            },
            {
              translate: 'date.yesterday',
              sref: 'bm.dashboardDateType',
              stateParams: { dateType: '2' }
            },
            {
              translate: 'date.thisWeek',
              sref: 'bm.dashboardDateType',
              stateParams: { dateType: '3' }
            },
            {
              translate: 'date.lastWeek',
              sref: 'bm.dashboardDateType',
              stateParams: { dateType: '4' }
            },
            {
              translate: 'date.thisMonth',
              sref: 'bm.dashboardDateType',
              stateParams: { dateType: '5' }
            },
            {
              translate: 'date.lastMonth',
              sref: 'bm.dashboardDateType',
              stateParams: { dateType: '6' }
            },
            {
              translate: 'date.FromTo',
              sref: 'bm.dashboardDateType',
              stateParams: { dateType: '8' }
            }
          ]
        },
        {
          translate: 'jsp.menu.masterdata',
          topmenu: 'masterdata',
          access: function (security) {
            return security.hasAccess('/masterdata/');
          },
          submenu: [
            {
              translate: 'article',
              sref: 'bm.masterdataArticle',
            },
            {
              translate: 'articleGroup',
              sref: 'bm.masterdataArticle_group',
            },
            {
              translate: 'courseGroup',
              sref: 'bm.masterdataCourse_group',
            },
            {
              translate: 'orderScreen',
              sref: 'bm.masterdataOrder_screen',
            },
            {
              translate: 'waiter',
              sref: 'bm.masterdataWaiter',
            }
          ]
        },
        {
          translate: 'jsp.menu.reporting',
          topmenu: 'report',
          access: function (security) {
            return security.hasAccess('/report/');
          },
          submenu: [
            {
              translate: 'orderReports',
              sref: 'bm.branchType',
              stateParams: { typeId: "10" }
            },
            {
              translate: 'turnoverReports',
              sref: 'bm.branchType',
              stateParams: { typeId: "11" }
            },
            {
              translate: 'datevReports',
              sref: 'bm.branchType',
              stateParams: { typeId: "16" },
              disable: function (security) {
                return !security.hasAnyFeature('DATEV');
              }
            },
            {
              translate: 'customer',
              sref: 'bm.branchType',
              stateParams: {typeId: "14"},
              access: function (security) {
                return security.hasAccess('/branch/14');
              },
              disable: function (security) {
                return !security.hasAnyFeature('CUSTOMER');
              }
            }
          ]
        },
        {
          translate: 'jsp.menu.setup',
          topmenu: 'setup',
          access: function (security) {
            return security.hasAccess('/setup/') || security.hasAccess('/hardware/') || security.hasAccess('/financial/');
          },
          dropdown: [
            {
              translate: 'jsp.menu.setup',
              topmenu: 'setup',
              submenu: [
                {
                  translate: 'articleType',
                  sref: 'bm.setupArticle_type',
                },
                {
                  translate: 'waiterGroup',
                  sref: 'bm.setupWaiter_group',
                }
              ]
            },
            {
              translate: 'jsp.menu.hardware',
              topmenu: 'hardware',
              submenu: [
                {
                  translate: 'printers',
                  sref: 'bm.hardwarePrinter',
                },
                {
                  translate: 'ticketLayouts',
                  sref: 'bm.hardwareTicket_layout',
                }
              ]
            },
            {
              translate: 'jsp.menu.financial',
              topmenu: 'financial',
              submenu: [
                {
                  translate: 'settlementForms',
                  sref: 'bm.financialSettlement_form',
                },
                {
                  translate: 'vats',
                  sref: 'bm.financialVat',
                },
                {
                  translate: 'account',
                  sref: 'bm.financialAccount',
                },
                {
                  translate: 'costCenters',
                  sref: 'bm.financialCostCenter',
                },
                {
                  translate: 'discount',
                  sref: 'bm.financialDiscount',
                },
                {
                  translate: 'priceLevels',
                  sref: 'bm.financialPrice_level',
                },
                {
                  translate: 'servicePeriods',
                  sref: 'bm.financialService_period',
                }
              ]
            }
          ]
        }
      ])
})();
