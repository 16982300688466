import React from 'react';

const entryMethods = {
  swipe: 'lsp.swipe',
  emv: 'lsp.emv',
  manual: 'lsp.manual',
  instore: 'lsp.instore',
  ecom: 'lsp.ecom',
  moto: 'lsp.moto',
  nfc: 'lsp.nfc',
  cc_stored: 'lsp.cc_stored',
  unknown: 'lsp.unknown'
}

export function getEntryMethod(method){
  return entryMethods[method] || entryMethods.unknown;
}
