(function () {
  'use strict';
  angular.module('gf.masterdata.ticketLayout', ['ui.router'])

    .factory('TicketLayout2', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/ticket_layout/:ticketLayoutId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('TicketLayoutCurrent', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/ticket_layout/current.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('TicketLayoutMetadata2', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/ticket_layout/metadata.json');
    })
    .factory('TicketLayoutContext2', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/ticket_layout/context.json');
    })

    .config(function ($stateProvider) {

      var ticketLayoutMetadata =function (TicketLayoutMetadata2) {
        return TicketLayoutMetadata2.get().$promise;
      };

      var ticketLayoutContext = function (TicketLayoutContext2) {
        return TicketLayoutContext2.get().$promise;
      };

      $stateProvider
      .state('page.masterdataTicket_layout', {
        url: '/masterdata/ticket_layout',
        template: require('../template/masterdata/ticket-layout/ticket_layouts.html'),
        controller: 'TicketLayout2GridCtrl',
        controllerAs: 'ticketLayout2GridCtrl',
        resolve: {
          ticketLayoutMetadata: ticketLayoutMetadata,
          ticketLayoutContext: ticketLayoutContext
        },
        title: "ticketLayout"
      })

      .state('page.masterdataTicket_layoutAdd', {
        url: '/masterdata/ticket_layout/add',
        template: require('../template/masterdata/ticket-layout/ticket_layout.html'),
        controller: 'TicketLayout2AddCtrl',
        controllerAs: 'ticketLayoutCtrl',
        resolve: {
          ticketLayoutMetadata: ticketLayoutMetadata,
          ticketLayoutContext: ticketLayoutContext,
          ticketLayout: function (TicketLayout2) {
            return TicketLayout2.load({ticketLayoutId: "add"}).$promise;
          }
        },
        title: "ticketLayout"
      })

      .state('page.masterdataTicket_layoutCurrent', {
        url: '/masterdata/ticket_layout/current',
        template: require('../template/masterdata/ticket-layout/ticket_layout.html'),
        controller: 'TicketLayoutCurrentCtrl',
        controllerAs: 'ticketLayoutCtrl',
        resolve: {
          ticketLayoutMetadata: ticketLayoutMetadata,
          ticketLayoutContext: ticketLayoutContext,
          ticketLayout: function (TicketLayoutCurrent) {
            return TicketLayoutCurrent.load().$promise;
          }
        },
        title: "ticketLayout"
      })

      .state('page.masterdataTicket_layoutDetails', {
        url: '/masterdata/ticket_layout/:id',
        template: require('../template/masterdata/ticket-layout/ticket_layout.html'),
        controller: 'TicketLayout2EditCtrl',
        controllerAs: 'ticketLayoutCtrl',
        resolve: {
          ticketLayoutMetadata: ticketLayoutMetadata,
          ticketLayoutContext: ticketLayoutContext,
          ticketLayout: function (TicketLayout2, $stateParams) {
            return TicketLayout2.load({ticketLayoutId: $stateParams.id}).$promise;
          }
        },
        title: "ticketLayout"
      })
    })

    .factory('TicketLayout2Add', [
      function () {
        return function (self, $scope, $location, $interpolate, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata) {

          self.ticketLayout = ticketLayout;
          self.ticketLayoutMetadata = ticketLayoutMetadata;

          angular.extend(self, ticketLayoutContext.data.dependencies);
          angular.extend(self, ticketLayoutContext.data.dependencies.labels);

          self.type.forEach(function (type) {
            type.name = $translate.instant("ticketType." + type.name);
          });

          self.hidePanel = [];

          self.draggable = {
            appendTo: ".gf-ticket-layout-preview",
            connectToSortable: ".gf-ticket-layout-preview"
          };

          self.sortable = {
            cancel: "li.locked,input"
          };

          self.getLabel = function (name) {
            return $interpolate(name)(self);
          };

          self.save = function () {
            if (self.ticketLayoutForm.$valid) {
              self.ticketLayout.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go('page.masterdataTicket_layout')
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };
          self.saveAndNew = function () {
            if (self.ticketLayoutForm.$valid) {
              self.ticketLayout.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };

          self.goBack = function () {
            $state.go('page.masterdataTicket_layout')
          };
        }
      }
    ])
    .controller('TicketLayout2AddCtrl',

      function ($scope, $location, $interpolate, $translate, $state, notifyManager, ticketLayout,
                ticketLayoutContext, ticketLayoutMetadata, TicketLayout2Add) {
        var self = this;
        TicketLayout2Add(self, $scope, $location, $interpolate, $translate, $state, notifyManager, ticketLayout,
          ticketLayoutContext, ticketLayoutMetadata);
      }
    )
    .controller('TicketLayout2EditCtrl',

      function ($scope, $location, $interpolate, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata, TicketLayout2Add) {
        // Fill scope by service
        var self = this;
        TicketLayout2Add(self, $scope, $location, $interpolate, $translate, $state, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata);

        self.save = function () {
          if (self.ticketLayoutForm.$valid) {
            self.ticketLayout.$edit({ticketLayoutId: self.ticketLayout.id}, function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $state.go('page.masterdataTicket_layout')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
        self.saveAndNew = function () {
          if (self.ticketLayoutForm.$valid) {
            self.ticketLayout.$edit({ticketLayoutId: self.ticketLayout.id}, function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $state.go('page.masterdataTicket_layoutAdd')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

      }
    )

    .controller('TicketLayout2GridCtrl',

      function ($scope, ticketLayoutContext, ticketLayoutMetadata, Upload, $http, notifyManager, $translate) {

        var self = this;

        self.metadata = ticketLayoutMetadata;
        self.context = ticketLayoutContext;

        self.ticketLayoutData = ticketLayoutContext.data;

        self.config = {
          urlRoute: '/masterdata/ticket_layout',
          enableFiltering: true,
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'id', width: 60, enableCellEdit: false, visible: false},
            {name: 'name'},
            {
              name: 'type', width: 150,
              filterCellFiltered: true,
              sortCellFiltered: true,
              editDropdownFilter: 'ticketLayoutTypeFilter',
              cellFilter: 'ticketLayoutTypeFilter'
            },
            {name: 'operate', actions: ['edit', 'delete']}
          ]
        };

        self.onFileSelect = function ($files, filename, maxWidth, maxHeight) {
          var imageType = /image.*/;
          var reader = new FileReader();
          var img = new Image();

          reader.onload = function () {
            img.src = reader.result;
          };

          img.onload = function () {

            if (!(img.width > maxWidth || img.height > maxHeight)) {
              self[filename + "Show"] = true;
              self.upload = Upload.upload({
                url: '/icash/masterdata/ticket_layout/upload?name=' + filename,
                method: 'POST',
                file: file,
                fileFormDataName: 'file'
              })
                .progress(function (evt) {
                    $("#" + filename + "Progress").css({width: parseInt(100.0 * evt.loaded / evt.total) + "%"});
                    $("#" + filename + "Progress").text(parseInt(100.0 * evt.loaded / evt.total) + " %");
                  }
                );
            } else {
              self.ticketLayoutData[filename + 'Name'] = null;
              notifyManager.error($translate.instant('restaurantSettings.imageSection.error.size'));
            }
          };

          for (var i = 0; i < $files.length; i++) {
            var file = $files[i];
            if (file.type.match(imageType)) {
              reader.readAsDataURL(file);
            } else {
              notifyManager.error($translate.instant('restaurantSettings.imageSection.error.type'));
            }
          }
        };

        self.onFileDelete = function ($files, filename) {
          if (self.ticketLayoutData) {
            $http({
              method: 'DELETE',
              url: '/icash/masterdata/ticket_layout/upload',
              params: {
                'name': filename
              }
            }).then(function (resp) {
              if (resp.data.ok) {
                self.ticketLayoutData[filename] = null;
                self.ticketLayoutData[filename + 'Name'] = null;
                self[filename + "Show"] = false;
                $("#" + filename + "Progress").css({width: "0%"});
              }
            });
          }
        };

        self.reset = function () {
          $("#imageForm")[0].reset();
          self.logoImageShow = false;
          $("#logoImageProgress").css({width: "0%"});
          self.promotionImageShow = false;
          $("#promotionImageProgress").css({width: "0%"});
          self.footerImageShow = false;
          $("#footerImageProgress").css({width: "0%"});

          $http({
            url: '/icash/masterdata/ticket_layout/reset',
            method: 'DELETE'
          });
        };

        self.imageUpdated = new Date().getTime();
        self.save = function () {
          $http.post('/icash/masterdata/ticket_layout/updateImages')
            .then(function (resp) {
              if (resp.data.ok) {
                self.ticketLayoutData = resp.data.payload;
                self.imageUpdated = new Date().getTime();
                self.reset();
                for (var item in self.ticketLayoutData) {
                  if (self.ticketLayoutData[item]) {
                    notifyManager.success($translate.instant('restaurantSettings.imageSection.success'));
                    return;
                  }
                }
              } else {
                notifyManager.error($translate.instant('restaurantSettings.imageSection.fail') + ' ' + resp.data.message)
              }
            });
        };

        $http({
          url: '/icash/masterdata/ticket_layout/reset',
          method: 'DELETE'
        });
      }
    )
    .filter('ticketLayoutTypeFilter', ["$translate", function ($translate) {
      return function (input) {
        return input ? $translate.instant("ticketType." + input) : '';
      }
    }])

    .controller('TicketLayoutCurrentCtrl',

      function ($scope, $translate, $interpolate, notifyManager, ticketLayout, ticketLayoutContext, ticketLayoutMetadata, TicketLayoutCurrent) {
        var self = this;

        angular.extend(self, ticketLayoutContext.data);
        self.ticketLayout = ticketLayout;
        self.ticketLayoutMetadata = ticketLayoutMetadata;

        self.current = true;

        self.hidePanel = [];

        angular.extend(self, ticketLayoutContext.data.dependencies);
        angular.extend(self, ticketLayoutContext.data.dependencies.labels);

        self.draggable = {
          appendTo: ".gf-ticket-layout-preview",
          connectToSortable: ".gf-ticket-layout-preview"
        };

        self.sortable = {
          cancel: "li.locked,input"
        };

        self.getLabel = function (name) {
          return $interpolate(name)(self);
        };

        self.save = function () {
          if (self.ticketLayoutForm.$valid) {
            self.ticketLayout.$edit(function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              self.ticketLayout = new TicketLayoutCurrent(data.payload.ticketLayout);
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
      }
    )

})();
