import React from 'react';
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";

function BreakdownElement({field, value}){
  const {t} = useTranslation();

  return <>
    <Typography variant="body2">
      {t(field)}
    </Typography>
    <Typography variant="h6">
      {value}
    </Typography>
  </>
}

export default BreakdownElement
