//important
(function () {
  'use strict';
  angular.module('gf.operator.data.trash', [])

    .config(function ($provide) {
      var RESOURCES = {
        'TrashContext': '/operator_data/trash/:domain/context.json',
        'TrashMetadata': '/operator_data/trash/:domain/metadata.json',
        'TrashFilterContext': '/operator_data/trash/filter/:filterType/context.json',
        'TrashFilterMetadata': '/operator_data/trash/filter/:filterType/metadata.json',
        'TrashRestore': '/operator_data/trash/:domain/:itemId/restore',
        'TrashRestoreFilter': '/operator_data/trash/filter/:filterType/:itemId/restore',
        'TrashOrderScreenRestoreContext': '/operator_data/trash/restore/context'
      };

      _.each(RESOURCES, function (jsonLocation, name) {
        $provide.factory(name, function ($resource, appConfig) {
          return $resource(appConfig.baseUrl + '/icash' + jsonLocation, null, {'put': {method: 'PUT'}});
        })
      });
    })

    .config(function ($stateProvider) {

      var TrashCtrlResolve = {
        trashContext: ['TrashContext', 'TrashFilterContext', '$stateParams', function (TrashContext, TrashFilterContext, $stateParams) {
          if ($stateParams.domain) {
            return TrashContext.get({domain: $stateParams.domain}).$promise;
          } else {
            return TrashFilterContext.get({filterType: $stateParams.filterType}).$promise;
          }
        }],
        trashMetadata: ['TrashMetadata', 'TrashFilterMetadata', '$stateParams', function (TrashMetadata, TrashFilterMetadata, $stateParams) {
          if ($stateParams.domain) {
            return TrashMetadata.get({domain: $stateParams.domain}).$promise;
          } else {
            return TrashFilterMetadata.get({filterType: $stateParams.filterType}).$promise;
          }
        }]
      };

      $stateProvider
        .state('page.operator_dataTrashFilterFilterType', {
          url: '/operator_data/trash/filter/:filterType',
          template: require('../template/operator-data/trash/trash.html'),
          controller: 'TrashCtrl',
          controllerAs: 'trashCtrl',
          resolve: TrashCtrlResolve,
          title: "trashFilter"
        })

        .state('page.operator_dataTrashBranchFilterFilterType', {
          url: '/operator_data/trash/branch_filter/:filterType',
          template: require('../template/operator-data/trash/trash.html'),
          controller: 'TrashCtrl',
          controllerAs: 'trashCtrl',
          resolve: TrashCtrlResolve,
          title: "trashFilter"
        })

        .state('page.operator_dataTrash', {
          url: '/operator_data/trash/:tab/:domain',
          template: require('../template/operator-data/trash/trash.html'),
          controller: 'TrashCtrl',
          controllerAs: 'trashCtrl',
          resolve: TrashCtrlResolve,
          title: "trash"
        });
    })
    .controller('TrashCtrl',

      function ($scope, $http, $stateParams, $translate, notifyManager, trashContext, trashMetadata) {
        var self = this;

        self.entityName = trashContext.data.entityName;

        self.domain = $stateParams.domain || (trashContext.data.branchFilters.indexOf(Number($stateParams.filterType)) < 0 ? "filter" : "branch_filter");
        self.tab = $stateParams.tab || (trashContext.data.branchFilters.indexOf(Number($stateParams.filterType)) < 0 ? "filter" : "branch_filter");
        self.filterType = $stateParams.filterType;

        self.open = {};
        self.open[self.tab] = true;

        $scope.$watch('tab', function (newValue, oldValue) {
          if (oldValue != newValue) {
            self.open[oldValue] = false;
            self.open[newValue] = true;
          }
        });

        self.metadata = trashMetadata;
        self.context = trashContext;

        var actions = [
          {
            name: 'restore',
            template: 'app/template/grid/restore_action.html',
            controller: 'RestoreActionCtrl'
          }
        ];

        var columns = [];

        // id column
        if (self.domain == 'customer') {
          columns.push({name: 'number', width: 90, enableCellEdit: false});
          columns.push({name: 'id', displayName: 'operator.id', width: 300, enableCellEdit: false});
        } else if (self.domain == 'customer_group') {
          columns.push({name: 'id', displayName: 'operator.id', width: 300, enableCellEdit: false});
        } else if (self.domain == 'device') {
          columns.push({name: 'udid', width: 350, enableCellEdit: false});
        } else if (self.domain == 'restaurant_table') {
          columns.push({name: 'id', width: 100, enableCellEdit: false});
        } else if (self.domain == 'reason' || self.domain == 'restaurant_group' || self.domain == 'customer_discount') {
          columns.push({name: 'id', displayName: 'operator.id', width: 60, enableCellEdit: false});
        } else {
          columns.push({name: 'id', width: 60, enableCellEdit: false});
        }

        // id column
        if (self.domain == 'customer' || self.domain == 'waiter') {
          columns.push({name: "firstName", enableCellEdit: false});
          columns.push({name: "lastName", enableCellEdit: false});
        } else if (self.domain == 'exchange_rate') {
          columns.push({name: "name", field: 'to.name', filterPathFieldName: 'to.name', enableCellEdit: false});
        } else if (self.domain == 'reason') {
          columns.push({name: "description", enableCellEdit: false});
        } else {
          columns.push({name: "name", enableCellEdit: false});
        }

        columns.push({
          name: 'modified',
          enableFiltering: false,
          displayName: 'ui.titles.modified',
          cellFilter: 'modifiedTrashFilter:row.entity',
          width: 130,
          enableColumnResizing: true,
          enableCellEdit: false
        });
        columns.push({name: "actions", actions: ['restore', 'delete']});

        self.config = {
          urlRoute: '/operator_data/trash/' + self.domain,

          registerActions: actions,
          enableFiltering: true,
          enablePagination: true,
          enableSorting: true,
          enableColumnResizing: false,
          disableSaveState: true,
          columns: columns
        };

        self.removeAll = function () {
          if (self.domain == 'filter') {
            $http({method: 'DELETE', url: "/icash/operator_data/trash/filter/" + self.filterType + "/delete"})
              .then(function (resp) {
                if (resp.data.ok) {
                  self.config.reloadData();
                  notifyManager.success($translate.instant('record.deleted'));
                } else {
                  notifyManager.error($translate.instant(resp.data.message));
                }
              })
          } else {
            $http({method: 'DELETE', url: "/icash/operator_data/trash/" + self.domain + "/delete"})
              .then(function (resp) {
                if (resp.data.ok) {
                  self.config.reloadData();
                  notifyManager.success($translate.instant('record.deleted'));
                } else {
                  notifyManager.error($translate.instant(resp.data.message));
                }

              })
          }
        };
      }
    )
    .filter('modifiedTrashFilter', ['$filter', '$translate', function ($filter, $translate) {
      return function (input) {
        if (input) {
          return $filter('date')(input, 'HH.mm dd.MM.yyyy')
        }
        return $translate.instant('forms.temporal.never');
      }
    }])
    .controller('RestoreActionCtrl',

      function ($scope, $uibModal, notifyManager, $stateParams, $translate, TrashRestore, TrashRestoreFilter, TrashOrderScreenRestoreContext, entity) {
        $scope.domain = $stateParams.domain || "filter";

        if ($scope.domain == 'reason') {
          $scope.$itemId = entity.id + '-' + entity.accountType.id;
        } else {
          $scope.$itemId = entity.id;
        }
        if ($scope.domain == 'filter') {
          TrashRestoreFilter.put({filterType: $stateParams.filterType, itemId: $scope.$itemId}, {}, function (data) {
            if (data.ok) {
              if (!data.payload.duplicatedFields) {
                $scope.config.reloadData();
                notifyManager.success($translate.instant('record.restored'));
              } else {
                $uibModal.open({
                  template: require('../template/operator-data/trash/delete_duplicate_dialog.html'),
                  controller: 'DeleteDuplicateCtrl',
                  controllerAs: 'deleteDuplicateCtrl',
                  windowClass: 'gf-modal',
                  resolve: {
                    domain: function () {
                      return $scope.domain;
                    },
                    duplicatedFields: function () {
                      return data.payload.duplicatedFields;
                    },
                    nonEmptyFields: function () {
                      return data.payload.nonEmptyFields;
                    },
                    context: function () {
                      return null;
                    }
                  }
                }).result.then(function (duplicatedFields) {
                  TrashRestoreFilter.save({
                    filterType: $stateParams.filterType,
                    itemId: $scope.$itemId
                  }, duplicatedFields, function (data) {
                    if (data.ok) {
                      $scope.config.reloadData();
                      notifyManager.success($translate.instant('record.restored'));
                    } else {
                      notifyManager.error($translate.instant(data.message));
                    }
                  });
                });
              }
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          });
        } else if ($scope.domain == 'waiter') {
          // always ask about ne pin
          TrashRestore.put({domain: $scope.domain, itemId: $scope.$itemId}, {}, function (data) {
            // resetting/adding loginPin field
            var duplicatedFields = data.payload.duplicatedFields || [{loginPin: "0"}];
            var isLoginPinFind = false;
            duplicatedFields.forEach(function (item) {
              if (item.hasOwnProperty("loginPin")) {
                item.loginPin = "0";
                isLoginPinFind = true;
              }
            });

            if (!isLoginPinFind) {
              duplicatedFields.push({loginPin: "0"});
            }

            if (data.ok) {
              $uibModal.open({
                template: require('../template/operator-data/trash/delete_duplicate_dialog.html'),
                controller: 'DeleteDuplicateCtrl',
                controllerAs: 'deleteDuplicateCtrl',

                windowClass: "gf-modal",
                resolve: {
                  domain: function () {
                    return $scope.domain;
                  },
                  duplicatedFields: function () {
                    return duplicatedFields;
                  },
                  nonEmptyFields: function () {
                    return data.payload.nonEmptyFields;
                  },
                  context: function () {
                    return null;
                  }
                }
              }).result.then(function (duplicatedFields) {
                TrashRestore.save({domain: $scope.domain, itemId: $scope.$itemId}, duplicatedFields, function (data) {
                  if (data.ok) {
                    $scope.config.reloadData();
                    notifyManager.success($translate.instant('record.restored'));
                  } else {
                    notifyManager.error($translate.instant(data.message));
                  }
                });
              });
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          });
        } else {
          TrashRestore.put({domain: $scope.domain, itemId: $scope.$itemId}, {}, function (data) {
            if (data.ok) {
              if (!data.payload.duplicatedFields) {
                $scope.config.reloadData();
                notifyManager.success($translate.instant('record.restored'));
              } else {
                $uibModal.open({
                  template: require('../template/operator-data/trash/delete_duplicate_dialog.html'),
                  controller: 'DeleteDuplicateCtrl',
                  controllerAs: 'deleteDuplicateCtrl',
                  windowClass: 'gf-modal',
                  resolve: {
                    domain: function () {
                      return $scope.domain;
                    },
                    duplicatedFields: function () {
                      return data.payload.duplicatedFields;
                    },
                    nonEmptyFields: function () {
                      return data.payload.nonEmptyFields;
                    },
                    context: function () {
                      if ($scope.domain == 'order_screen' || $scope.domain == 'exchange_rate') {
                        return TrashOrderScreenRestoreContext.get().$promise;
                      } else {
                        return null;
                      }
                    }
                  }
                }).result.then(function (duplicatedFields) {
                  TrashRestore.save({domain: $scope.domain, itemId: $scope.$itemId}, duplicatedFields, function (data) {
                    if (data.ok) {
                      $scope.config.reloadData();
                      notifyManager.success($translate.instant('record.restored'));
                    } else {
                      notifyManager.error($translate.instant(data.message));
                    }
                  });
                });
              }
            } else {
              notifyManager.error($translate.instant(data.message));
            }
          })
        }
      }
    )
    .controller('DeleteDuplicateCtrl',

      function ($uibModalInstance, $translate, notifyManager, domain, duplicatedFields, nonEmptyFields, context) {
        var self = this;
        self.domain = domain;
        self.duplicatedFields = duplicatedFields;
        self.nonEmptyFields = nonEmptyFields || [];

        self.camelCaseDomain = toCamelCase(domain);
        function toCamelCase(str) {
          return str
            .replace(/_(.)/g, function ($1) {
              return $1.toUpperCase();
            })
            .replace(/_/g, '');
        }

        if (context != null) {
          if (context.ok) {
            self.context = [];
            angular.extend(self.context, context.payload);
          } else {
            notifyManager.error($translate.instant('fail.load.data'));
          }
        }

        // additional validators
        if (self.domain == 'device') {
          self.validators = {
            'ipAddress': {
              'pattern': /^([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])$/,
              'message': $translate.instant('de.icash.validator.IPAddress.message')
            }
          };
        } else if (self.domain == 'operator_user') {
          self.validators = {
            'email': {
              'pattern': /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              'message': $translate.instant('org.hibernate.validator.constraints.Email.message')
            }
          }
        } else if (self.domain == 'vat') {
          self.validators = {
            'mark': {
              'pattern': /^.$/,
              'message': $translate.instant('javax.validation.constraints.Size.message')
            }
          }
        } else if (self.domain == 'waiter') {
          self.validators = {
            'loginPin': {
              'pattern': /^(([1-9])|([\d][\d]{1,3}))$/,
              'message': $translate.instant('javax.validation.constraints.Min.message', {'value': 1})
            }
          }
        }

        self.updateModel = function (group, key, value) {
          group[key] = value;
        };

        self.yes = function () {
          $uibModalInstance.close(self.duplicatedFields);
        };

        self.no = function () {
          $uibModalInstance.dismiss('cancel');
        };

      }
    )
})();
