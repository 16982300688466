import {createMuiTheme} from '@material-ui/core';
import COMMON_THEME_SETTINGS from './common.theme.settings';

export const themeColors = {
  themeOrangeColor: '#fca115',
  btnStandardHoverColor: '#f7ac1a',
  btnTextColor: '#b07922',
  errorColor: '#f44335',
  redTextColor: '#e64c45',
  greenTextColor: '#7cca6e',
  themeGrayColor: '#555',
  linkColor: '#1976d2',
};

export default createMuiTheme({
  ...COMMON_THEME_SETTINGS,
  typography: {
    fontSize: 20,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: themeColors.themeOrangeColor,
    },
    secondary: {
      main: themeColors.themeGrayColor
    }
  },
  errorText: {
    color: themeColors.errorColor,
    paddingTop: 5,
  },
  linkText: {
    color: themeColors.linkColor
  },
  props: {
    MuiButton: {
      color: 'primary'
    }
  }
})
;
