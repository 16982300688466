import React, {useEffect, useState} from 'react';
import GFAlert from '../../../gf-react-components/alert/GFAlert';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {useForm, Controller} from "react-hook-form";
import * as yup from 'yup';
import {CircularProgress, TextField} from '@material-ui/core';
import ConfirmCompanyInfoDialog from './ConfirmCompanyInfoDialog';
import Tooltip from '@material-ui/core/Tooltip';
import {useTranslation} from 'react-i18next';
import {useSnackbar} from 'notistack';
import {isUndefined} from 'lodash';

const useStyles = makeStyles(theme => ({
  buttonsRoot: {
    textAlign: 'center'
  },
  loaderWrapper: {
    textAlign: 'center'
  }
}))

const EMPTY_VALUE = 'Wird nachgereicht'

function CompanyInfo() {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [organization, setOrganization] = useState({});
  const [countries, setCountries] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const orgSchema = yup.object().shape({
    name: yup.string().required(t('validation.requiredField')).min(3, t('minLength', {0: 3})),
    address: yup.string().required(t('validation.requiredField')).min(3, t('minLength', {0: 3})),
    zip: yup.string()
      .required(t('validation.requiredField'))
      .typeError(t('expect.number'))
      .test('len', t('expect.number.lenght', {0: 5}), val => val.toString().length === 5),
    city: yup.string().required(t('validation.requiredField')).min(2, t('minLength', {0: 2})),
    country: yup.object().nullable(true).required(t('validation.requiredField')),
    taxNumber: yup.lazy(value => {
      return yup.string().when(['ustid', 'noTaxNumber'], {
        is: (ustid, noTaxNumber) => ((!ustid || value) && !noTaxNumber),
        then: yup.string()
          .required(t('validation.requiredField'))
          .min(10, t('minLength', {0: 10}))
      })
    }),
    ustid: yup.lazy(value => {
      return yup.string().when(['taxNumber', 'noTaxNumber'], {
          is: (taxNumber, noTaxNumber) => ((!taxNumber || value) && !noTaxNumber),
          then: yup.string()
            .required(t('validation.requiredField'))
            .matches(/^DE[0-9]{9}$/, t('companyInfo.ustid.validation.format'))
        })
      }),
    noTaxNumber: yup.boolean(),
  }, ['taxNumber', 'ustid'])


  const locSchema = yup.object().shape({
    name: yup.string().required(t('validation.requiredField')).min(3, t('minLength', {0: 3})),
    address: yup.string().required(t('validation.requiredField')).min(3, t('minLength', {0: 3})),
    zip: yup.string()
      .required(t('validation.requiredField'))
      .typeError(t('expect.number'))
      .test('len', t('expect.number.lenght', {0: 5}), val => val.toString().length === 5),
    city: yup.string().required(t('validation.requiredField')).min(2, t('minLength', {0: 2})),
    country: yup.object().nullable(true).required(t('validation.requiredField')),
    ustid: yup.string().when('noTaxNumber', {
      is: false,
      then: yup.string()
        .required(t('validation.requiredField'))
        .matches(/^DE[0-9]{9}$/, t('companyInfo.ustid.validation.format'))
    }),
    noTaxNumber: yup.boolean()
  })

  const {
    handleSubmit: orgHandleSubmit,
    errors: orgErrors,
    control: orgControl,
    watch: orgWatch,
    setValue: setOrgValue,
    clearError: clearOrgError
  } = useForm({
    validationSchema: orgSchema
  });
  const {
    handleSubmit: locHandleSubmit,
    errors: locErrors,
    control: locControl,
    watch: locWatch,
    setValue: setLocValue,
    clearError: clearLocError
  } = useForm({
    validationSchema: locSchema
  });

  useEffect(() => {
    axios.get('/icash/restaurant_data/organization').then(resp => {
      setOrganization(resp.data);
      axios.get('/icash/restaurant_data/organization/countries').then(resp => {
        setCountries(resp.data);
        setLoading(false);
      })
    })
  }, [])

  const submitOrgForm = callback => {
    orgHandleSubmit(data => {
      setOrganization({
        ...organization,
        ...data
      })
      callback()
    })()
  }

  const submitLocForm = callback => {
    locHandleSubmit(data => {
      setOrganization({
        ...organization,
        location: data
      })
      callback()
    })()
  }

  const handleStep = step => () => {
    if (step === 0) {
      submitLocForm(() => setActiveStep(step))
    } else {
      submitOrgForm(() => setActiveStep(step))
    }
  }

  const handleNextStep = () => {
    submitOrgForm(() => setActiveStep(1))
  }

  const handleSave = () => {
    submitLocForm(() => setShowConfirmModal(true))
  }

  const handleCloseDialog = () => {
    setShowConfirmModal(false)
  }

  const handleConfirmSaving = () => {
    axios.post('/icash/restaurant_data/organization', organization)
      .then(resp => {
        enqueueSnackbar(t('companyInfo.saved'), {variant: 'success'});
        setShowConfirmModal(false)
      }, () => {
        enqueueSnackbar(t('companyInfo.error'), {variant: 'error'});
      })
  }

  const handleChangeOrgNoTaxCheckbox = ([, checked]) => {
    if (checked) {
      setOrgValue([
        {taxNumber: EMPTY_VALUE},
        {ustid: EMPTY_VALUE}
      ], true)
    } else {
      setOrgValue([
        {taxNumber: ''},
        {ustid: ''}
      ], true)
    }
    return checked
  }

  const handleChangeLocNoTaxCheckbox = ([, checked]) => {
    clearLocError('ustid')
    if (checked) {
      setLocValue('ustid', EMPTY_VALUE)
    } else {
      setLocValue('ustid', '')
    }
    return checked;
  }

  const handleChangeOrgTaxNumber = ([event]) => {
    clearOrgError('ustid')
    return event.target.value
  }

  const handleChangeOrgUstid = ([event]) => {
    clearOrgError('taxNumber')
    return event.target.value
  }

  return (
    <>
      {
        loading
          ?
          <div className={classes.loaderWrapper}><CircularProgress size={48}/></div>
          :
          <Grid container spacing={3}>
            <Grid item container>
              <Grid item md={4}/>
              <Grid item md={4}>
                <Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
                  <Step>
                    <StepButton onClick={handleStep(0)}>
                      {t('companyInfo.organization.step.name')}
                    </StepButton>
                  </Step>
                  <Step>
                    <StepButton onClick={handleStep(1)}>
                      {t('companyInfo.location.step.name')}
                    </StepButton>
                  </Step>
                </Stepper>
              </Grid>
            </Grid>
            <Grid item>
              <GFAlert type='info'>{t('companyInfo.alert.text')}</GFAlert>
            </Grid>
            <Grid item container>
              <Grid item md={3}/>
              <Grid item md={6}>
                {
                  activeStep === 0
                    ?
                    <Grid container spacing={4} key={1}>
                      <Grid item xs={12}>
                        <Controller
                          as={TextField}
                          control={orgControl}
                          fullWidth
                          name='name'
                          defaultValue={organization.name || ''}
                          label={t('companyInfo.organization.name')}
                          error={!!orgErrors.name}
                          helperText={!!orgErrors.name && orgErrors.name.message}
                          InputProps={{
                            endAdornment: <InfoIconAdornment text={t('companyInfo.organization.name.helpText')}/>
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          as={TextField}
                          control={orgControl}
                          fullWidth
                          name='address'
                          defaultValue={organization.address || ''}
                          label={t('companyInfo.organization.address')}
                          error={!!orgErrors.address}
                          helperText={!!orgErrors.address && orgErrors.address.message}
                          InputProps={{
                            endAdornment: <InfoIconAdornment text={t('companyInfo.organization.address.helpText')}/>
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          as={TextField}
                          control={orgControl}
                          fullWidth
                          name='zip'
                          type='number'
                          defaultValue={organization.zip || ''}
                          label={t('companyInfo.organization.zip')}
                          error={!!orgErrors.zip}
                          helperText={!!orgErrors.zip && orgErrors.zip.message}
                          InputProps={{
                            endAdornment: <InfoIconAdornment text={t('companyInfo.organization.zip.helpText')}/>
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          as={TextField}
                          control={orgControl}
                          fullWidth
                          name='city'
                          defaultValue={organization.city || ''}
                          label={t('companyInfo.organization.city')}
                          error={!!orgErrors.city}
                          helperText={!!orgErrors.city && orgErrors.city.message}
                          InputProps={{
                            endAdornment: <InfoIconAdornment text={t('companyInfo.organization.city.helpText')}/>
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth error={!!orgErrors.country}>
                          <InputLabel id='country-label'>
                            {t('companyInfo.organization.country')}
                          </InputLabel>
                          <Controller
                            as={
                              <Select>
                                {countries.map(country => (
                                  <MenuItem key={country.id} value={country}>{country.name}</MenuItem>
                                ))}
                              </Select>
                            }
                            control={orgControl}
                            name='country'
                            defaultValue={organization.country ?
                              countries.find(c => c.id === organization.country.id) : ''}
                          />
                          <FormHelperText>
                            {!!orgErrors.country && orgErrors.country.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4} alignItems={'center'}>
                          <Grid item xs={6}>
                            <Grid container spacing={4}>
                              <Grid item xs={12}>
                                <Controller
                                  // key={'taxNumber' + !!orgWatch('ustid')}
                                  as={TextField}
                                  control={orgControl}
                                  fullWidth
                                  name='taxNumber'
                                  defaultValue={organization.taxNumber || ''}
                                  label={t('companyInfo.organization.taxNumber')}
                                  error={!!orgErrors.taxNumber}
                                  helperText={!!orgErrors.taxNumber && orgErrors.taxNumber.message}
                                  InputProps={{
                                    endAdornment: <InfoIconAdornment
                                      text={t('companyInfo.organization.taxNumber.helpText')}/>
                                  }}
                                  onChange={handleChangeOrgTaxNumber}
                                  disabled={isUndefined(orgWatch('noTaxNumber')) ? organization.noTaxNumber : orgWatch('noTaxNumber')}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Controller
                                  // key={'ustid' + !!orgWatch('taxNumber')}
                                  as={TextField}
                                  control={orgControl}
                                  fullWidth
                                  name='ustid'
                                  defaultValue={organization.ustid || ''}
                                  label={t('companyInfo.organization.ustid')}
                                  error={!!orgErrors.ustid}
                                  helperText={!!orgErrors.ustid && orgErrors.ustid.message}
                                  InputProps={{
                                    endAdornment: <InfoIconAdornment
                                      text={t('companyInfo.organization.ustid.helpText')}/>
                                  }}
                                  onChange={handleChangeOrgUstid}
                                  disabled={isUndefined(orgWatch('noTaxNumber')) ? organization.noTaxNumber : orgWatch('noTaxNumber')}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlLabel
                              control={
                                <Controller
                                  as={Checkbox}
                                  control={orgControl}
                                  name='noTaxNumber'
                                  type='checkbox'
                                  defaultValue={organization.noTaxNumber || false}
                                  color='primary'
                                  onChange={handleChangeOrgNoTaxCheckbox}
                                />
                              }
                              label={t('companyInfo.organization.noTaxNumber.label')}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    :

                    <Grid container spacing={4} key={2}>
                      <Grid item xs={12}>
                        <Controller
                          as={TextField}
                          control={locControl}
                          fullWidth
                          name='name'
                          defaultValue={organization.location.name || ''}
                          label={t('companyInfo.location.name')}
                          error={!!locErrors.name}
                          helperText={!!locErrors.name && locErrors.name.message}
                          InputProps={{
                            endAdornment: <InfoIconAdornment text={t('companyInfo.location.name.helpText')}/>
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          as={TextField}
                          control={locControl}
                          fullWidth
                          name='address'
                          defaultValue={organization.location.address || ''}
                          label={t('companyInfo.location.address')}
                          error={!!locErrors.address}
                          helperText={!!locErrors.address && locErrors.address.message}
                          InputProps={{
                            endAdornment: <InfoIconAdornment text={t('companyInfo.location.address.helpText')}/>
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          as={TextField}
                          control={locControl}
                          fullWidth
                          name='zip'
                          type='number'
                          defaultValue={organization.location.zip || ''}
                          label={t('companyInfo.location.zip')}
                          error={!!locErrors.zip}
                          helperText={!!locErrors.zip && locErrors.zip.message}
                          InputProps={{
                            endAdornment: <InfoIconAdornment text={t('companyInfo.location.zip.helpText')}/>
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Controller
                          as={TextField}
                          control={locControl}
                          fullWidth
                          name='city'
                          defaultValue={organization.location.city || ''}
                          label={t('companyInfo.location.city')}
                          error={!!locErrors.city}
                          helperText={!!locErrors.city && locErrors.city.message}
                          InputProps={{
                            endAdornment: <InfoIconAdornment text={t('companyInfo.location.city.helpText')}/>
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl fullWidth error={!!locErrors.country}>
                          <InputLabel id='loc-country-label'>
                            {t('companyInfo.location.country')}
                          </InputLabel>
                          <Controller
                            as={
                              <Select>
                                {countries.map(country => (
                                  <MenuItem key={country.id} value={country}>{country.name}</MenuItem>
                                ))}
                              </Select>
                            }
                            control={locControl}
                            name='country'
                            defaultValue={organization.location && organization.location.country ?
                              countries.find(c => c.id === organization.location.country.id) : ''}
                          />
                          <FormHelperText>
                            {!!locErrors.country && locErrors.country.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      <Grid item container xs={12} spacing={4} alignItems={'center'}>
                        <Grid item container xs={6} spacing={4}>
                          <Grid item xs={12}>
                            <Controller
                              as={TextField}
                              control={locControl}
                              fullWidth
                              name='ustid'
                              defaultValue={organization.location.ustid || ''}
                              label={t('companyInfo.location.ustid')}
                              error={!!locErrors.ustid}
                              helperText={!!locErrors.ustid && locErrors.ustid.message}
                              InputProps={{
                                endAdornment: <InfoIconAdornment text={t('companyInfo.location.ustid.helpText')}/>
                              }}
                              disabled={isUndefined(locWatch('noTaxNumber')) ? organization.location.noTaxNumber : locWatch('noTaxNumber')}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Controller
                                as={Checkbox}
                                control={locControl}
                                name='noTaxNumber'
                                type='checkbox'
                                defaultValue={organization.location.noTaxNumber || false}
                                color='primary'
                                onChange={handleChangeLocNoTaxCheckbox}
                              />
                            }
                            label={t('companyInfo.location.noTaxNumber.label')}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                }
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonsRoot}>
              {activeStep === 0 && <Button variant='contained' color='primary' onClick={handleNextStep}>
                {t('companyInfo.nextStep.button')}
              </Button>}
              {activeStep === 1 && <Button variant='contained' color='primary' onClick={handleSave}>
                {t('companyInfo.save.button')}
              </Button>}
            </Grid>
          </Grid>
      }

      {
        showConfirmModal &&
        <ConfirmCompanyInfoDialog
          companyInfo={organization}
          handleClose={handleCloseDialog}
          handleSubmit={handleConfirmSaving}
        />
      }
    </>
  )
}

export default CompanyInfo;


const useInfoStyles = makeStyles(() => ({
  infoIcon: {
    cursor: 'pointer'
  }
}))

function InfoIconAdornment({text}) {
  const classes = useInfoStyles();
  return (
    <InputAdornment position='end'>
      <Tooltip title={text} placement='right'>
        <HelpOutlineIcon className={classes.infoIcon}/>
      </Tooltip>
    </InputAdornment>
  )
}
