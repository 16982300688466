(function () {
  'use strict';
  angular.module('gf.bm.rules.grid')

    .factory('permissionsService', function (securityService) {

      var adminRoles = ['ROLE_SUPERVISOR', 'ROLE_RESELLER_USER'];

      var permissionsDefaults = {
        isAbleToActivate: function() {
          return securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'BM_ACTIVATE_DEACTIVATE');
        },
        enableRuleEdit: function () {
          return securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'BM_CHANGE_ENTITY');
        }
      };

      return {
        applyPermissions: applyPermissions

      };

      function applyPermissions(gridConfig, permissions) {

        _.defaults(gridConfig, permissionsDefaults);

        gridConfig.columnDefs.forEach(function (column) {
          if (!_.isUndefined(column.enableCellEdit)) {
            return;
          }
          var colPerm = permissions ? permissions.columnsPermissions[column.name] : null;
          if (!colPerm) {
            column.enableCellEdit = permissionsDefaults.enableRuleEdit();
            return;
          }
          var userRoles = adminRoles.concat(colPerm.editPermissions);
          column.enableCellEdit = securityService.hasAnyRole(userRoles);
        });
      }
    })
})();
