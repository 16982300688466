const statuses = {
  UNINITIALIZED: 'UNINITIALIZED',
  INITIALIZED: 'INITIALIZED',
  SHUTDOWN: 'SHUTDOWN',
  NOT_DEFINED: 'NOT_DEFINED',
  CREATED: 'CREATED',
  DISABLED: 'DISABLED',
  DELETED: 'DELETED',
  DEFECTIVE: 'DEFECTIVE'
}

statuses.getDeletedStatuses = () => {
  return [statuses.SHUTDOWN, statuses.DISABLED, statuses.DELETED, statuses.DEFECTIVE];
}

export default statuses
