
var RoomAddCtrlResolve = {
  roomMetadata: ['RoomMetadata', function (RoomMetadata) {
    return RoomMetadata.get().$promise;
  }],
  roomContext: ['RoomContext', function (RoomContext) {
    return RoomContext.get().$promise;
  }],
  roomPositions: ['RoomPositions', function (RoomPositions) {
    return RoomPositions.load().$promise;
  }],
  restaurantSettings: ['CurrentRestaurantSettings', function (CurrentRestaurantSettings) {
    return CurrentRestaurantSettings.get().$promise;
  }]
};

var WaiterGroupAddCtrlResolve = {
  waiterGroupMetadata: ['WaiterGroupMetadata', function (WaiterGroupMetadata) {
    return WaiterGroupMetadata.get().$promise;
  }],
  waiterGroupContext: ['WaiterGroupContext', function (WaiterGroupContext) {
    return WaiterGroupContext.get().$promise;
  }],
  waiterGroup: ['WaiterGroup', '$stateParams', function (WaiterGroup, $stateParams) {
    return WaiterGroup.load({waiterGroupId: $stateParams.id || "add"}).$promise;
  }]
};

var WaiterGroupGridCtrlResolve = {
  waiterGroupMetadata: ['WaiterGroupMetadata', function (WaiterGroupMetadata) {
    return WaiterGroupMetadata.get().$promise;
  }],
  waiterGroupContext: ['WaiterGroupContext', function (WaiterGroupContext) {
    return WaiterGroupContext.get().$promise;
  }],
  waiterGroups: ['WaiterGroup', function (WaiterGroup) {
    return WaiterGroup.query().$promise;
  }]
};

(function () {
  'use strict';
  angular.module('gf.setup', ['gf.security'])

    .config(function($stateProvider) {

      $stateProvider
        .state('page.setupRoom', {
          url: '/setup/room',
          template: require('./../../template/page/restaurant_data/rooms.html'),
          controller: 'RoomGridCtrl',
          resolve: RoomAddCtrlResolve,
          title: 'room'
        })

        .state('page.setupRoomAdd', {
          url: '/setup/room/add',
          template: require('./../../template/page/restaurant_data/room.html'),
          controller: 'RoomAddCtrl',
          resolve: RoomAddCtrlResolve,
          title: 'room'
        })

        .state('page.setupRoomDetails', {
          url: '/setup/room/:id',
          template: require('./../../template/page/restaurant_data/room.html'),
          controller: 'RoomEditCtrl',
          resolve: RoomAddCtrlResolve,
          title: 'room'
        })

        .state('page.setupWaiter_group', {
          url: '/setup/waiter_group',
          template: require('./../../template/page/restaurant_data/waiter_groups.html'),
          controller: 'WaiterGroupGridCtrl',
          resolve: WaiterGroupGridCtrlResolve,
          title: 'waiterGroup'
        })

        .state('page.setupWaiter_groupAdd', {
          url: '/setup/waiter_group/add',
          template: require('./../../template/page/restaurant_data/waiter_group.html'),
          controller: 'WaiterGroupAddCtrl',
          resolve: WaiterGroupAddCtrlResolve,
          title: 'waiterGroup'
        })

        .state('page.setupWaiter_groupDetails', {
          url: '/setup/waiter_group/:id',
          template: require('./../../template/page/restaurant_data/waiter_group.html'),
          controller: 'WaiterGroupEditCtrl',
          resolve: WaiterGroupAddCtrlResolve,
          title: 'waiterGroup'
        })

    })

    .factory('RoomAdd',
      function () {
        return function ($scope, roomContext, roomMetadata) {
          $scope.roomContext = roomContext;
          $scope.roomMetadata = roomMetadata;
        }
      }
    )
    .controller('RoomAddCtrl',
      function ($scope, roomContext, roomMetadata, RoomAdd) {
        RoomAdd($scope, roomContext, roomMetadata);
        $scope.roomResource = '/icash/restaurant_data/room/add';
      }
    )
    .controller('RoomEditCtrl',
      function ($scope, roomContext, roomMetadata, $stateParams, RoomAdd) {
        RoomAdd($scope, roomContext, roomMetadata);
        $scope.roomResource = '/icash/restaurant_data/room/' + $stateParams.id;
      }
    )
    .controller('RoomGridCtrl',

      function ($scope, roomContext, roomMetadata, roomPositions, RoomPositions, restaurantSettings, RestaurantSettings) {

        $scope.metadata = roomMetadata;
        $scope.context = roomContext;

        var actions = [
          {
            name: 'downloadSvg',
            template: 'app/template/grid/download_svg_action.html',
            controller: 'DownloadSvgRoomActionCtrl'
          }
        ];

        $scope.config = {
          registerActions: actions,
          urlRoute: '/setup/room',
          enableSorting: true,
          enableAdd: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'id', width: 60, enableCellEdit: false, visible: false},
            {name: 'name'},
            {name: 'description', visible: false, width: 250},
            {name: 'operate', actions: ['activate', 'downloadSvg', 'edit', 'delete']}
          ]
        };

        $scope.roomPositions = roomPositions;
        $scope.rooms = $scope.roomPositions.positions;

        $scope.savePositions = function () {
          $scope.roomPositions.$save(function (data) {
            if (data.ok) {

              $scope.roomPositions = new RoomPositions(data.payload.positions);
              $scope.rooms = $scope.roomPositions.positions;
            }
          });
        };

        $scope.refreshPositions = function () {
          RoomPositions.load(function (data) {
            $scope.roomPositions = data;
            $scope.rooms = $scope.roomPositions.positions;

          });
        };

        $scope.restaurantSettings = new RestaurantSettings(restaurantSettings);

        $scope.saveSettings = function () {
          if ($scope.tableStatusColorsForm.$valid) {
            $scope.restaurantSettings.$save({restaurantId: $scope.restaurantSettings.belongsTo.id}, function (data) {
              if (data.ok) {
                $scope.restaurantSettings = new RestaurantSettings(data.payload.settings);
              }
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        }
      }
    )
    .controller('DownloadSvgRoomActionCtrl',
      function ($scope, $translate, $resource, notifyManager, entity, DownloadSVGRoom) {

        var resource = $resource(DownloadSVGRoom, {roomId: entity.id}, {
          head: {method: 'HEAD'}
        });

        resource.head(
          function () {
            window.location = DownloadSVGRoom.replace(':roomId', entity.id);
          }, function () {
            notifyManager.error($translate.instant('message.downloadSvg.layoutNotConfigured'));
          });
      }
    )

    .controller('RoomLowMarketGridCtrl',
      function ($scope, $http, rooms) {

        function init(rooms) {
          $scope.rooms = rooms;
          var selectedRoom = _.find($scope.rooms, function (room) {
            return room.activeLowMarket;
          });
          $scope.selectedRoomId = selectedRoom ? selectedRoom.id : null;
        }

        if (rooms.ok) {
          init(rooms.payload.rooms);
        }


        $scope.changeDefault = function (room) {
          $scope.selectedRoomId = room.id;
          $http({
            method: 'PUT',
            url: '/icash/restaurant_data/room/low_market/' + room.id + '/default'
          }).then(function (resp) {
              if (resp.data.ok) {
                init(resp.data.payload.rooms);
              }
            }
          ).error(function () {
              init($scope.rooms)
            }
          );
        }
      }
    )
    .factory('WaiterGroupAdd', [
      function () {
        return function ($scope, $location, $state, $http, $translate, notifyManager, waiterGroup, waiterGroupContext, waiterGroupMetadata) {
          $scope.waiterGroupContext = waiterGroupContext;
          $scope.waiterGroupMetadata = waiterGroupMetadata;
          $scope.waiterGroup = waiterGroup;

          angular.extend($scope, waiterGroupContext.data.dependencies);

          $scope.permissionsIds = {};
          angular.forEach($scope.permissions, function (value, key) {
            this[key] = false;
          }, $scope.permissionsIds);
          angular.forEach($scope.waiterGroup.permissions, function (value) {
            this[value.id] = true;
          }, $scope.permissionsIds);

          $scope.permissionsMap = {};
          angular.forEach($scope.permissions, function (permission, index) {
            this[permission.id] = permission;
          }, $scope.permissionsMap);

          $scope.$watch(function () {
            return angular.toJson($scope.permissionsIds);
          }, function () {
            $scope.waiterGroup.permissions = [];
            angular.forEach($scope.permissionsIds, function (value, key) {
              if (value) {
                this.push($scope.permissionsMap[key]);
              }
            }, $scope.waiterGroup.permissions);
          });

          $scope.checkAll = false;
          $scope.columnCheckboxChanged = function () {
            angular.forEach($scope.permissionsIds, function (v, k) {
              $scope.permissionsIds[k] = $scope.checkAll;
            });
          };

          $scope.save = function () {
            if ($scope.waiterGroupForm.$valid) {
              if (!_.isEmpty($scope.waiterGroup.permissions)) {
                $scope.waiterGroup.$save(function (data) {
                  notifyManager.success($translate.instant('entity.save.successfully'));
                  $location.path('/setup/waiter_group')
                });
              } else {
                notifyManager.error($translate.instant('error.Permissions.message'));
              }
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          $scope.saveAndNew = function () {
            if ($scope.waiterGroupForm.$valid) {
              if (!_.isEmpty($scope.waiterGroup.permissions)) {
                $scope.waiterGroup.$save(function (data) {
                  notifyManager.success($translate.instant('entity.save.successfully'));
                  $state.reload();
                });
              } else {
                notifyManager.error($translate.instant('error.Permissions.message'));
              }
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };

          $scope.tabs = _.range($scope.permissionGroups.length).map(function () {
            return true;
          });

          $scope.isExpand = function () {
            return !$scope.tabs.reduce(function (a, b) {
              return a && b;
            });
          };

          $scope.toggleTabs = function () {
            var expand = $scope.isExpand();

            for (var i = 0; i < $scope.tabs.length; i++) {
              $scope.tabs[i] = expand;
            }
          };
        }
      }
    ])
    .controller('WaiterGroupAddCtrl',

      function ($scope, $location, $state, $http, $translate, notifyManager, waiterGroup, waiterGroupContext, waiterGroupMetadata, WaiterGroupAdd) {
        WaiterGroupAdd($scope, $location, $state, $http, $translate, notifyManager, waiterGroup, waiterGroupContext, waiterGroupMetadata);
      }
    )
    .controller('WaiterGroupEditCtrl',

      function ($scope, $location, $state, $http, $translate, notifyManager, waiterGroup, waiterGroupContext, waiterGroupMetadata, WaiterGroupAdd) {
        WaiterGroupAdd($scope, $location, $state, $http, $translate, notifyManager, waiterGroup, waiterGroupContext, waiterGroupMetadata);

        $scope.save = function () {
          if ($scope.waiterGroupForm.$valid) {
            if (!_.isEmpty($scope.waiterGroup.permissions)) {
              $scope.waiterGroup.$edit({waiterGroupId: $scope.waiterGroup.id}, function (data) {
                notifyManager.success($translate.instant('entity.edit.successfully'));
                $location.path('/setup/waiter_group')
              });
            } else {
              notifyManager.error($translate.instant('error.Permissions.message'));
            }
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        $scope.saveAndNew = function () {
          if ($scope.waiterGroupForm.$valid) {
            if (!_.isEmpty($scope.waiterGroup.permissions)) {
              $scope.waiterGroup.$edit({waiterGroupId: $scope.waiterGroup.id}, function (data) {
                notifyManager.success($translate.instant('entity.edit.successfully'));
                $location.path('/setup/waiter_group/add')
              });
            } else {
              notifyManager.error($translate.instant('error.Permissions.message'));
            }
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

      }
    )
    .controller('WaiterGroupGridCtrl',

      function ($scope, $location, waiterGroupContext, waiterGroupMetadata, waiterGroups, $timeout, configOptionsService) {
        $scope.permissionGroups = waiterGroupContext.data.dependencies.permissionGroups;
        $scope.waiterGroups = waiterGroups;

        $scope.metadata = waiterGroupMetadata;
        $scope.context = waiterGroupContext;

        var cellClass = function (grid, row, col, rowRenderIndex, colRenderIndex) {
          if (row && row.entity && row.entity.id && row.entity.hidden) {
            return 'hidden-entity';
          }
        };

        $scope.config = {
          urlRoute: '/setup/waiter_group',
          enableSorting: true,
          enableAdd: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          isRowSelectable: function (row) {
            return row.entity.id != null && !row.entity.hidden;
          },
          cellEditableCondition: function($scope) {
            return !$scope.row.entity.hidden;
          },
          hideActions: function(entity) {
            return entity.hidden;
          },
          columns: [
            {name: 'id', width: 60, enableCellEdit: false, visible: false, cellClass: cellClass},
            {name: 'name', width: 300, cellClass: cellClass},
            {name: 'description', cellClass: cellClass},
            {
              name: 'operate',
              actions: ['edit', 'delete'],
              actionOptions: {
                'delete': {
                  isDisabled: configOptionsService.get('waiterGroupDeleteButton').isDisabled
                }
              }
            }
          ]
        };

        $timeout(function () {
          $scope.showGrid = true;
        }, 0);

        $scope.tabs = {};
        if ($location.hash()) {
          $scope.tabs.featureView = true;
        }
      }
    )
})();
