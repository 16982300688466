(function () {
  'use strict';
  angular.module('gf.report', ['gf.report.config', 'gf.report.popupFilter', 'gf.large.bills.amount'])

    .run(function ($templateCache) {
      $templateCache.put('app/template/customerReportTypeahead.html', require('../../../main/angular/app/template/customerReportTypeahead.html'));
      $templateCache.put('app/template/report/confirmNotRemind.html', require('../template/report/confirmNotRemind.html'));
      $templateCache.put('app/template/report/emailNotConfirmed.html', require('../template/report/emailNotConfirmed.html'));
    })

    .constant('ReportWorkSpaceConfig',
      {
        default: {
          config: ['DOWNLOAD_PDF', 'DOWNLOAD_CSV', 'DOWNLOAD_CSV_DROPDOWN', 'DOWNLOAD_XLS', 'MAIL_PDF', 'MAIL_CSV', 'MAIL_CSV_DROPDOWN', 'MAIL_XLS', 'PREVIEW', 'FILTER']
        },
        2: {
          4: {
            48: {
              config: ['DOWNLOAD_RAW_CSV', 'DOWNLOAD_XLSX', 'MAIL_RAW_CSV', 'MAIL_XLSX', 'SALES_EXPORT_README']
            }
          }
        },
        5: {
          config: ['DOWNLOAD_NEW_DATEV', 'DOWNLOAD_DATEV_DROPDOWN', 'DOWNLOAD_DATEV', 'MAIL_NEW_DATEV', 'MAIL_DATEV_DROPDOWN', 'MAIL_DATEV', 'DATEV_README']
        },
        16: {
          config: ['DOWNLOAD_NEW_DATEV', 'DOWNLOAD_DATEV_DROPDOWN', 'DOWNLOAD_DATEV', 'MAIL_NEW_DATEV', 'MAIL_DATEV_DROPDOWN', 'MAIL_DATEV', 'DATEV_README']
        },
        19: {
          config: ['DOWNLOAD_PDF', 'DOWNLOAD_CSV', 'DOWNLOAD_CSV_DROPDOWN', 'DOWNLOAD_XLS', 'MAIL_PDF', 'MAIL_CSV', 'MAIL_CSV_DROPDOWN', 'MAIL_XLS', 'PREVIEW']
        }
      })

    .controller('ReportCtrl',
      function ($scope, $location, $cookies, $sce, $filter, $http, $analytics, $translate, notifyManager,
                reportData, $uibModal, dialogs, $timeout, $locale, prepareReportOrCorrections, profile,
                securityService, appConfig, Upload, $q, $stateParams, reportHelper, $httpParamSerializer, moment, ReportWorkSpaceConfig, LargeAmountBillsService) {

        $scope.notContains = function (value, array) {
          return $.inArray(value, array) < 0;
        };

        $scope.showButton = function (typeId, buttonType) {
          function getConfig(typeId, superGrId, grId, reportId) {
            return _.get(ReportWorkSpaceConfig, [typeId, superGrId, grId, reportId, "config"]) ||
              _.get(ReportWorkSpaceConfig, [typeId, superGrId, grId, "config"]) ||
              _.get(ReportWorkSpaceConfig, [typeId, superGrId, "config"]) ||
              _.get(ReportWorkSpaceConfig, [typeId, "config"]);
          }

          var config = getConfig(typeId, $scope.selectedReportState.selectedSuperGroup.id, $scope.selectedReportState.selectedGroup.id, $scope.selectedReportState.selectedReport.id);
          if (config) {
            return _.includes(config, buttonType);
          }
          return _.includes(_.get(ReportWorkSpaceConfig, ["default", "config"]), buttonType);
        };

        $scope.contains = function (value, array) {
          return $.inArray(value, array) >= 0;
        };

        $scope.checkIfDocumentArchive = function () {
          return $location.search().r == '236';
        };

        $scope.dateFormat = $locale.DATETIME_FORMATS.shortDate;

        $scope.profile = profile;

        $scope.additionalFilterParams = {
          filteredGroupIds: []
        };

        $scope.$watch('additionalFilterParams.filteredGroupIds', function (newValue, oldValue) {
          if (oldValue !== newValue) {
            $scope.previewState.setState('default');
          }
        });

        $scope.reportingDate = {
          dateId: 1,
          from: null,
          to: null
        };

        function filterDatesList() {
          $scope.dates = reportData.payload.dates;
          if ($scope.datepickerState.isDayReport) {
            $scope.dates = _.filter($scope.dates, function (date) {
              return _.includes($scope.datepickerState.dayReportDates, date.id);
            })
          } else if ($scope.datepickerState.isMonthReport) {
            $scope.dates = _.filter($scope.dates, function (date) {
              return _.includes($scope.datepickerState.monthReportDates, date.id);
            })
          }
        }

        $scope.$watch('selectedReportState.selectedReportGroup', function (newValue) {
          $scope.datepickerState.setInitialDatepickerState();
          if (newValue && newValue.id === 156) {
            $scope.reportingDate.dateId = 2;
            $scope.datepickerState.isDayReport = true;
          } else if (newValue && newValue.id === 158) {
            $scope.reportingDate.dateId = 5;
            $scope.datepickerState.isMonthReport = true;
          }
          filterDatesList();
        });

        $scope.datepickerState = {
          isDayReport: false,
          isMonthReport: false,
          dayReportDates: [2, 8],
          monthReportDates: [5, 6, 8],
          setInitialDatepickerState: function () {
            this.isDayReport = false;
            this.isMonthReport = false
          }
        };

        $scope.selectedReportState = {
          selectedSuperGroup: null,
          selectedGroup: null,
          selectedReportGroup: null,
          selectedReport: {},
          changeState: function (superGroup, group, reportGroup, report) {
            this.selectedSuperGroup = superGroup;
            this.selectedGroup = group;
            this.selectedReportGroup = reportGroup;
            this.selectedReport = report;
          },
          isSimpleGroup: function () {
            return this.selectedGroup === this.selectedReportGroup;
          }
        };

        $scope.updateReport = function (reportId) {
          prepareReportOrCorrections.setValue('r', reportId, $scope.filterType.id);
          $scope.previewState.setState('default');
        };

        $scope.updateGroup = function (report, reportGroup, group, superGroup) {
          $scope.selectedReportState.changeState(superGroup, group, reportGroup, report);

          prepareReportOrCorrections.setValue('r', $scope.selectedReportState.selectedReport.id, $scope.filterType.id);

          $scope.viewReport = false;

          $scope.previewState.setState('default');

          $analytics.eventTrack('View Report', {
            category: $translate.instant(group.name),
            label: securityService.context.currentOperator.name
          });

          //for POS Ledger filters we reset filter for every new report
          if ($stateParams.typeId == 19) {
            $scope.filter = 0;
          }
        };

        $scope.updateFilter = function (filter) {
          prepareReportOrCorrections.setValue('f', filter, $scope.filterType.id);
          $scope.filter = filter;

          $scope.previewState.setState('default');
        };

        $scope.updateBill = function (bill) {
          //hack
          $scope.bill = bill;
          prepareReportOrCorrections.setValue('bill', bill, $scope.filterType.id);

          $scope.previewState.setState('default');
        };

        $scope.clearCustomer = function () {
          $scope.customer = null;
          $scope.setCustomerOptionalFields(null, null);
          prepareReportOrCorrections.setValue('customer', null, $scope.filterType.id);
          $scope.viewReport = false;
          $scope.previewState.setState('default');
        };

        $scope.updateSelectedRestaurants = function (selectedRestaurants) {
          $scope.selectedRestaurants = selectedRestaurants;

          var restaurantIds = null;

          if (selectedRestaurants && (selectedRestaurants.length != 0)) {
            restaurantIds = _.map(selectedRestaurants, 'id');
          }
          prepareReportOrCorrections.setValue('rid', restaurantIds, $scope.filterType.id);

          $scope.previewState.setState('default');
        };

        $scope.autocomplete = function (val) {

          return $http.get("/icash/customer/autocomplete", {params: {term: val}}).then(function (data) {
            return data.data;
          });
        };

        $scope.autocompleteRestaurants = function (val) {
          if (val.length < 2) {
            return function () {
              return []
            };
          }
          return $http.get("/icash/report/autocomplete", {params: {term: val}}).then(function (data) {
            if (data.data.ok) {
              return data.data.payload.restaurants;
            }
          });
        };

        $scope.onCustomerSelect = function (item) {
          var customer = item.customer;

          if (customer) {
            prepareReportOrCorrections.setValue('customer', customer, $scope.filterType.id);
            //hack for iframe src update
            $scope.customer = customer;
            $scope.setCustomerOptionalFields(null, null);
          }
        };

        $scope.updateDate = function () {
          if ($scope.reportingDate.from && $scope.reportingDate.dateId === 8) {
            prepareReportOrCorrections.setValue('fd', $filter("date")($scope.reportingDate.from, 'yyyy-MM-dd'), $scope.filterType.id, true);
            //$scope.from = from;
          } else {
            $location.search('fd', null);
          }
          if ($scope.reportingDate.to && $scope.reportingDate.dateId === 8) {
            prepareReportOrCorrections.setValue('td', $filter("date")($scope.reportingDate.to, 'yyyy-MM-dd'), $scope.filterType.id, true);
            //$scope.to = to;
            if ($scope.datepickerState.isMonthReport) {
              $scope.reportingDate.to = moment($scope.reportingDate.to).endOf('month').toDate();
            }
          } else {
            $location.search('td', null);
          }
          prepareReportOrCorrections.setValue('d', $scope.reportingDate.dateId, $scope.filterType.id, true);
          //$scope.date = date;

          $scope.viewReport = false;
          $scope.previewState.setState('default');
        };

        $scope.updateCustomer = function (customer) {
          $scope.viewReport = false;
          $scope.previewState.setState('default');

          $scope.customer = customer;
          $scope.setCustomerOptionalFields(null, null);
        };

        $scope.setCustomerOptionalFields = function (customerCompany, customerDepartment) {
          $scope.customerCompany = customerCompany;
          $scope.customerDepartment = customerDepartment;
        };


        /*
         Creating parameters for report request.
         Format is file type
         */
        function prepareParameters(format) {
          var data = {
            date: $scope.reportingDate.dateId,
            filter: $scope.filter || 0,
            type: $scope.typeId,
            format: format
          };
          if ($scope.reportingDate.dateId === 8) {
            data.from = $filter("date")($scope.reportingDate.from, 'yyyy-MM-dd');
            data.to = $filter("date")($scope.reportingDate.to, 'yyyy-MM-dd');
          }
          if ($scope.typeId === 12) {
            data.bill = $scope.bill || '';
            if ($scope.customer) {
              data.customer = $scope.customer;
            }
          }
          if ($scope.contains($scope.typeId, [13, 14]) && $scope.customer) {
            data.customer = $scope.customer;
          }
          if ($scope.contains($scope.typeId, [4, 15]) && $scope.selectedRestaurants) {
            data.restaurantIds = _.map($scope.selectedRestaurants, 'id') + "";
          }
          //add filteredBranchGroupIds parameter for Branch Group reports
          if ($scope.additionalFilterParams.filteredGroupIds && $scope.additionalFilterParams.filteredGroupIds.length > 0) {
            data.filteredGroupId = $scope.additionalFilterParams.filteredGroupIds;
          }
          if (data.customer) {
            if ($scope.customerCompany)
              data.customerCompany = $scope.customerCompany;
            if ($scope.customerDepartment)
              data.customerDepartment = $scope.customerDepartment;
          }
          return data;
        }

        /**
         * Returns parameter string for datev report download url.
         */
        $scope.prepareDatevParameters = function () {
          var data = {
            date: $scope.reportingDate.dateId
          };

          if (data.date == 8) {
            data.from = $filter("date")($scope.reportingDate.from, 'yyyy-MM-dd');
            data.to = $filter("date")($scope.reportingDate.to, 'yyyy-MM-dd');
          }

          return $.param(data);
        };

        function prepareReportUrl(type) {
          // todo make default value and override it for branch management
          if ($stateParams.groupId && securityService.context.masterUnit) {
            return appConfig.baseUrl + "/icash/bm/" + securityService.context.masterUnit.id + "/"
              + $stateParams.groupId + "/proxy/report/jr/" + $scope.selectedReportState.selectedReport.file
              + "/" + type + '?' + $httpParamSerializer(prepareParameters())
          } else {
            return appConfig.baseUrl + "/icash/report/jr/" + $scope.selectedReportState.selectedReport.file + "/" + type + '?' + $.param(prepareParameters());
          }
        }

        $scope.getReportUrl = function (type) {
          var deferred = $q.defer();

          if ($scope.reportingDate.dateId === 8 && !($scope.reportingDate.from && $scope.reportingDate.to)) {
            deferred.resolve("about:blank");
          } else {
            $http.get(prepareReportUrl(type))
              .then(function (response) {
                if (response.data.ok) {
                  deferred.resolve(response.data.payload.location);
                } else if (response.data.message === 'Limit_Exceeded') {
                  $scope.previewState.setState('limitExceeded');
                }
              }, function (response) {
                if (response.status == 503) {
                  $scope.previewState.setState('serviceIsBusy');
                  deferred.reject('503');
                } else if (response.status == 422) {
                  if (response.data.payload.errorMessage) {
                    $scope.previewState.setState(JSON.parse(response.data.payload.errorMessage).message);
                    deferred.reject('422');
                  }
                } else {
                  deferred.reject('exception');
                }
              });
          }
          return deferred.promise;
        };

        $scope.mailDatev = function () {
          return checkAndSendEmail('/icash/report/datev/mail/' + $scope.typeId + '/turnover?' + $scope.prepareDatevParameters());
        };

        $scope.mailReport = function (type) {
          return checkAndSendEmail('/icash/report/mail/' + $scope.selectedReportState.selectedReport.file + '/' + type + '?', type);
        };

        function checkAndSendEmail(url, format) {
          preHandle(format).then(function () {
            if (!profile.email || $scope.reportingDate.dateId === 8 && !($scope.reportingDate.from && $scope.reportingDate.to)) {

            } else {
              if (profile.mailConfirmed) {
                if ($cookies.get('reportNotRemind')) {
                  sendEmailReport();
                } else {
                  dialogs.create('app/template/report/confirmNotRemind.html', 'ReportDialogCtrl',
                    {email: profile.email})
                    .result.then(sendEmailReport);
                }
              } else {
                dialogs.create('app/template/report/emailNotConfirmed.html', 'ReportEmailDialogCtrl',
                  {email: profile.email});
              }
            }

            function sendEmailReport() {
              if (!_.isNil(format)) {
                url += $.param(prepareParameters());
              }
              $http.get(appConfig.baseUrl + url)
                .then(function (resp) {
                  if (resp.data.ok) {
                    notifyManager.success($translate.instant("report.mail.success"));
                  } else {
                    notifyManager.error($translate.instant(resp.data.message));
                  }
                });
            }
          }, function () {
          });
        };

        /* Object which control preview area */
        $scope.previewState = {
          state: 'default',
          frameUrl: '',
          downloadUrl: {},
          tooLongTime: 200000,
          timeout: null,
          isState: function (state) {
            return this.state == state;
          },
          setState: function (state) {
            switch (state) {
              case ('default'):
                this.state = 'default';
                this.frameUrl = '';
                this.downloadUrl = {};
                break;
              case ('loading'):
                this.state = 'loading';
                break;
              case ('loadingDownload'):
                this.state = 'loadingDownload';
                break;
              case ('loaded'):
                this.state = 'loaded';
                break;
              case ('downloadIsReady'):
                this.state = 'downloadIsReady';
                break;
              case ('tooLong'):
                this.state = 'tooLong';
                this.frameUrl = '';
                this.downloadUrl = {};
                break;
              case ('wrongFilter'):
                this.state = 'wrongFilter';
                break;
              case ('adyenIsUnavailable'):
                this.state = 'adyenIsUnavailable';
                break;
              case ('serviceIsBusy'):
                this.state = 'serviceIsBusy';
                break;
              case ('limitExceeded'):
                this.state = 'limitExceeded';
                break;
              default:
              // console.log('Wrong state type: ' + state);
            }
          },
          handlePreparingTime: function () {
            /* Stop current timer */
            $timeout.cancel($scope.previewState.timeout);

            /* Handle time for preparing download or preview */
            $scope.previewState.timeout = $timeout(function () {
              if ($scope.previewState.isState('loading')) {
                $scope.previewState.setState('tooLong');
              }
            }, $scope.previewState.tooLongTime);

            /* Destroy timer when location change */
            var stopListener = $scope.$on('$locationChangeStart', function () {
              $timeout.cancel($scope.previewState.timeout);
              /* Destroy event after call function*/
              stopListener();
            });
          }
        };

        function preHandle(format) {
          var defer = $q.defer();
          if (_.includes(['csv', 'xls'], format)) {
            defer.resolve();
          } else {
            var reportId = $scope.selectedReportState.selectedReport.id;
            if (LargeAmountBillsService.isCustomerOrBill($scope.bill, $scope.customer, reportId)) {
              LargeAmountBillsService.preHandleReportRequest(prepareParameters(), reportId)
                .then(function (result) {
                  if (result.isReloadReportLink) {
                    $scope.viewReport = false;
                    $scope.previewState.setState('default');
                  }
                  $scope.customer = result.customerNumber;
                  $scope.setCustomerOptionalFields(result.customerCompany, result.customerDepartment);
                  defer.resolve()
                }, function () {
                  defer.reject()
                });
            } else {
              defer.resolve()
            }
          }

          return defer.promise;
        }

        $scope.showPreview = function () {
          preHandle().then(function () {
            if ($scope.isRenderReport()) {
              if ($scope.selectedReportState.selectedReport.file === 'customer_log' || $scope.selectedReportState.selectedReport.file === 'Turnover_cloud_messages') {
                $scope.previewState.frameUrl = appConfig.baseUrl + "/icash/report/jr/view/" + $scope.selectedReportState.selectedReport.file + ".html" + '?' + $.param(prepareParameters());
              } else {
                $scope.getReportUrl('html').then(function (url) {
                  $scope.previewState.frameUrl = $sce.trustAsResourceUrl(url);
                })
              }
            } else {
              $scope.previewState.frameUrl = $sce.trustAsResourceUrl('about:blank');
            }
            $scope.previewState.handlePreparingTime();
            $scope.previewState.setState('loading');
          }, function () {
          });
        };

        $scope.prepareDownload = function (format) {
          preHandle(format).then(function () {
            $scope.format = format;
            if (!$scope.previewState.downloadUrl[format]) {

              $scope.getReportUrl(format).then(function (url) {
                $scope.previewState.downloadUrl[format] = url;
                $scope.startDownload = $scope.previewState.downloadUrl[format];
                $scope.previewState.setState('downloadIsReady');
              });

              $scope.previewState.handlePreparingTime();
              $scope.previewState.setState('loadingDownload');

            } else {
              $scope.previewState.setState('downloadIsReady');
              $scope.startDownload = $scope.previewState.downloadUrl[format] + "?v=" + Math.random();
            }
          }, function () {
          });
        };

        if (reportData.ok) {
          angular.extend($scope, reportData.payload);

          $scope.filter = parseInt(prepareReportOrCorrections.fetchAndSetValue('f', null, $scope.filterType.id));

          if (!$scope.filter || !_.find($scope.filters, function (filter) {
            return filter.id == $scope.filter
          })) {
            prepareReportOrCorrections.setValue('f', 0, $scope.filterType.id);
          }

          var stringDateFrom = prepareReportOrCorrections.getValue('fd', $scope.filterType.id, true) || prepareReportOrCorrections.fetchAndSetValue('fd', $scope.reportingDate.from, $scope.filterType.id, true);
          var stringDateTo = prepareReportOrCorrections.getValue('td', $scope.filterType.id, true) || prepareReportOrCorrections.fetchAndSetValue('td', $scope.reportingDate.to, $scope.filterType.id, true);
          $scope.reportingDate.dateId = parseInt(prepareReportOrCorrections.getValue('d', $scope.filterType.id, true) || prepareReportOrCorrections.fetchAndSetValue('d', $scope.reportingDate.dateId, $scope.filterType.id, true));
          $scope.reportingDate.from = prepareReportOrCorrections.createDate(stringDateFrom);
          $scope.reportingDate.to = prepareReportOrCorrections.createDate(stringDateTo);
          if ($scope.reportingDate.dateId != 8) {
            $location.search('fd', null);
            $location.search('td', null);
          }


          var ids = [];
          var items = prepareReportOrCorrections.fetchAndSetValue('rid', [], $scope.filterType.id, true);
          if (items instanceof Array) {
            ids = items;
          } else {
            ids.push(items);
          }
          var preselectedRestaurantIds = _.map(ids, function (id) {
            return parseInt(id);
          });

          if (preselectedRestaurantIds && preselectedRestaurantIds.length > 0) {
            $http.get("/icash/report/getSelectedRestaurants", {params: {rid: preselectedRestaurantIds}}).then(function (data) {
              if (data.data.ok) {
                $scope.selectedRestaurants = data.data.payload.restaurants;
              }
            });
          }

          // Only Today for Product and Bills reports
          if ($scope.typeId == 6 || $scope.typeId == 12) {
            $scope.reportingDate.dateId = 1;
          }
          //for bill report
          if ($scope.typeId == 12) {
            $scope.bill = parseInt(prepareReportOrCorrections.fetchAndSetValue('bill', $scope.bill || 0, $scope.filterType.id));
          }
          if ($scope.contains($scope.typeId, [13, 14])) {
            $scope.customer = prepareReportOrCorrections.fetchAndSetValue('customer', "", $scope.filterType.id);
          }

          var selectedReportId = function () {
            if (_.isEmpty($scope.groups)) {
              return null;
            }
            var firstReportId;
            if (_.isEmpty($scope.groups[0].groups)) {
              firstReportId = $scope.groups[0].reports[0].reports[0].id;
            } else {
              firstReportId = $scope.groups[0].groups[0].reports[0].reports[0].id;
            }
            return parseInt(prepareReportOrCorrections.fetchAndSetValue('r', firstReportId, $scope.filterType.id));
          };

          $scope.isOpen = [];

          //set selected report, group and super group
          var found = reportHelper.findReport($scope.groups, selectedReportId());
          if (found) {
            $scope.selectedReportState
              .changeState(found.selectedSuperGroup, found.selectedGroup, found.selectedReportGroup, found.selectedReport);
            $scope.isOpen[found.selectedSuperGroup.id] = true;
          }
        }

        $scope.viewReport = false;

        var ONE_DAY = 60 * 60 * 24 * 1000;

        function isMoreThanSelectedDateRange(value) {

          //check day
          if (value < 1) {
            return true;
          }

          //check week
          if (value < 7 && ($scope.reportingDate.dateId == 3 || $scope.reportingDate.dateId == 4)) {
            return true;
          }

          //check month
          if (value < 30 && ($scope.reportingDate.dateId == 5 || $scope.reportingDate.dateId == 6)) {
            return true;
          }

          //check year
          if ($scope.reportingDate.dateId == 7) {
            //always true for year report.
            return true;
          }

          //check from - to
          return $scope.reportingDate.dateId == 8 && (new Date($scope.reportingDate.to).getTime() - new Date($scope.reportingDate.from).getTime()) / ONE_DAY > value;

        }

        $scope.isLongRunningReport = function () {
          return isMoreThanSelectedDateRange($scope.selectedReportState.selectedReportGroup.longRunning);
        };

        $scope.isImpossibleDownloadReport = function () {
          return isMoreThanSelectedDateRange($scope.selectedReportState.selectedReportGroup.maxDownload);
        };

        $scope.isRenderReport = function () {
          return $scope.viewReport || !$scope.isLongRunningReport();
        };

        $scope.renderReport = function () {
          $scope.viewReport = true;
        };

        $scope.uploadDone = function () {
          $scope.previewState.setState('loaded');
          $scope.$apply();

          setIFrameHeight();
        };

        /* Set iframe height when onload */
        function setIFrameHeight() {
          var iFrameID = document.getElementById('previewIFrame');
          if (iFrameID) {
            // here you can make the height, I delete it first, then I make it again
            iFrameID.height = "";
            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + "px";
          }
        }

        /*added a possibility to add and change logo from Reporting - Bills section*/

        $scope.onFileSelect = function ($files, filename, maxWidth, maxHeight) {
          if (!$files || !$files[0]) {
            return;
          }
          var img = new Image();
          var reader = new FileReader();
          $scope.logoFile = $files[0];

          reader.onload = function () {
            img.src = reader.result;
            var photoContainer = angular.element(document).find('#photoImg');
            photoContainer.empty();
            photoContainer.append(img);
          };

          img.onload = function () {
            if (!(img.width > maxWidth || img.height > maxHeight)) {
              $scope.$apply(function () {
                $scope.showLogo = true;
              });
            } else {
              $scope.logoFile = null;
              $scope.showLogo = false;
              notifyManager.error($translate.instant('restaurantSettings.imageSection.error.size'));
            }
          };

          reader.readAsDataURL($scope.logoFile);
        };
        $scope.onLogoFileSave = function ($files, filename, maxWidth, maxHeight) {
          var imageType = /image.*/;
          var img = new Image();
          var reader = new FileReader();
          if (!(img.width > maxWidth || img.height > maxHeight)) {
            $scope.upload = Upload.upload({
              url: '/icash/report/upload',
              method: 'POST',
              file: $scope.logoFile,
              fileFormDataName: 'file'
            }).then(function () {
              notifyManager.success($translate.instant('entity.edit.successfully'))
            });
          } else {
            notifyManager.error($translate.instant('restaurantSettings.imageSection.error.size'));
          }

          var file = $scope.logoFile;
          if (file.type.match(imageType)) {
            reader.readAsDataURL(file);
          } else {
            notifyManager.error($translate.instant('restaurantSettings.imageSection.error.type'));
          }
        };
        $scope.onLogoFileDelete = function () {
          $scope.showLogo = false;
          $scope.logoFile = null;
          $scope.upload = Upload.upload({
            url: '/icash/report/upload',
            method: 'DELETE',
            file: $scope.logoFile,
            fileFormDataName: 'file'
          }).then(function () {
            notifyManager.success($translate.instant('entity.edit.successfully'))
          });
        };
        $scope.onFileDelete = function () {
          $scope.showLogo = false;
          $scope.logoFile = null;

          angular.element(document).find('#photoImg').empty();
        };

        $scope.addFilters = function () {
          $uibModal.open({
            template: require('../template/report/report_filter_popup.html'),
            windowClass: 'gf-modal',
            controller: 'ReportFilterAddPopupController',
            scope: $scope,
            backdrop: 'static',
            resolve: {
              reportFilterMetadata: function (ReportFilterMetadata, $stateParams) {
                return ReportFilterMetadata.get({typeId: $stateParams.typeId || 1}).$promise;
              },
              reportFilterContext: function (ReportFilterContext, $stateParams) {
                return ReportFilterContext.get({typeId: $stateParams.typeId || 1}).$promise;
              },
              reportFilter: function (ReportFilter, $stateParams) {
                return ReportFilter.get({
                  typeId: $stateParams.typeId || 1,
                  reportFilterId: $scope.filter || 'add'
                }).$promise;
              },
              reportData: function (ReportData, $stateParams) {
                return ReportData.get({typeId: $stateParams.typeId || 1}).$promise;
              },
              reportId: function () {
                return Number($location.search().r);
              }
            }
          }).result.then(function (filterId) {
            $scope.filter = filterId;
            $scope.previewState.setState('default');
          });
        };

        $scope.updateSubGroup = function (group, superGroup) {
          var report = group.reports[0].reports[0];
          $scope.updateGroup(report, group.reports[0], group, superGroup);
        };

        $scope.updateGroupSelect = function (reportGroup, group, superGroup) {
          var report = reportGroup.reports[0];
          $scope.updateGroup(report, reportGroup, group, superGroup);
        };

        // It is possible only hide filters for some reasons (BM reports as example)
        $scope.showReportFilter = $scope.showReportFilter !== false
          ? securityService.hasAnyRole('ACCESS_REPORTS_GROUP', 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER')
          : false;

        /*--added a possibility to add and change logo from Reporting - Bills section--*/

      }
    )


    .controller('ReportDialogCtrl',
      function ($scope, $uibModalInstance, data, $cookies) {

        $scope.email = data.email;
        $scope.notRemindMe = false;

        $scope.no = function () {
          $uibModalInstance.dismiss('no');
        };

        $scope.yes = function () {
          if ($scope.notRemindMe) {

            var tomorrow = new Date();
            tomorrow.setDate((new Date()).getDate() + 1);

            $cookies.put('reportNotRemind', 'true', {expires: tomorrow});
          }
          $uibModalInstance.close('yes');
        };
      }
    )
    .controller('ReportEmailDialogCtrl',
      function ($scope, $uibModalInstance, data, $http, notifyManager, $translate) {

        $scope.email = data.email;

        $scope.close = function () {
          $uibModalInstance.close();
        };

        $scope.confirm = function () {
          $http.post("/icash/user/-1/email/confirm")
            .then(function (resp) {
              if (resp.data.ok) {
                notifyManager.success($translate.instant('user.mailConfirmed.notification'));
              } else {
                notifyManager.error($translate.instant(resp.data.message));
              }
              $uibModalInstance.close();
            });

        };
      }
    )
    .factory('ReportFilterAdd',
      function () {
        return function ($scope, $location, $stateParams, $filter, $translate, $http, notifyManager, reportFilter, reportFilterContext, reportFilterMetadata, moment) {
          $scope.reportFilter = reportFilter;
          $scope.reportFilterMetadata = reportFilterMetadata;

          angular.extend($scope, reportFilterContext.data.dependencies);

          $scope.isShowTime = reportFilterContext.data.isShowTime;
          //show amount fields only for review reports
          $scope.isShowAmount = $stateParams.params.typeId == 19;
          $scope.isShowOpenTables = $stateParams.params.typeId == 19;

          $scope.typeId = $stateParams.params.typeId || 1;

          $scope.preSave = function (reportFilter) {
            angular.forEach(reportFilter.timePeriods, function (timePeriod) {
              timePeriod.from = $filter("date")(timePeriod.fromTime, 'yyyy-MM-dd HH:mm');
              timePeriod.to = $filter("date")(timePeriod.toTime, 'yyyy-MM-dd HH:mm');
            });
          };

          $scope.postLoad = function (reportFilter) {
            angular.forEach(reportFilter.timePeriods, function (timePeriod) {
              timePeriod.fromTime = moment(timePeriod.from).toISOString();
              timePeriod.toTime = moment(timePeriod.to).toISOString();
            });
          };

          $scope.postLoad($scope.reportFilter);

          $scope.addTime = function () {
            $scope.reportFilter.timePeriods.push({
              "fromTime": new Date(1, 1, 1, 8),
              "toTime": new Date(1, 1, 1, 23)
            })
          };

          $scope.removeTime = function (index) {
            $scope.reportFilter.timePeriods.splice(index, 1);
          };

          $scope.save = function () {
            if ($scope.reportFilterForm.$valid) {
              $scope.preSave($scope.reportFilter);
              $scope.reportFilter.$save({typeId: $scope.typeId}, function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                var subMenu = $scope.menu.subMenu == $scope.typeId ? "" : '/' + $scope.menu.subMenu;
                $location.path('/' + $scope.menu.topMenu + subMenu + '/' + $scope.typeId);
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };

          $scope.goBack = function () {
            var subMenu = $scope.menu.subMenu == $scope.typeId ? "" : '/' + $scope.menu.subMenu;
            $location.path('/' + $scope.menu.topMenu + subMenu + '/' + $scope.typeId);
          };

          $scope.autocompleteOperators = function (val) {
            if (val.length < 2) {
              return function () {
                return []
              };
            }
            return $http.get("/icash/report/report_filter/autocomplete", {params: {term: val}}).then(function (data) {
              if (data.data.ok) {
                return data.data.payload.operators;
              }
            });
          };
        }
      }
    )

    .controller('ReportFilterAddCtrl',
      function ($scope, $location, $state, $filter, $translate, $http, notifyManager, reportFilter, reportFilterContext, reportFilterMetadata, ReportFilterAdd, moment) {
        ReportFilterAdd($scope, $location, $state, $filter, $translate, $http, notifyManager, reportFilter, reportFilterContext, reportFilterMetadata, moment);
      }
    )
    .controller('ReportFilterEditCtrl',
      function ($scope, $location, $state, $filter, $translate, $http, notifyManager, reportFilter, reportFilterContext, reportFilterMetadata, ReportFilterAdd, moment) {

        ReportFilterAdd($scope, $location, $state, $filter, $translate, $http, notifyManager, reportFilter, reportFilterContext, reportFilterMetadata, moment);

        $scope.save = function () {
          if ($scope.reportFilterForm.$valid) {
            $scope.preSave($scope.reportFilter);
            $scope.reportFilter.$edit({
              typeId: $scope.typeId,
              reportFilterId: $scope.reportFilter.id
            }, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              var subMenu = $scope.menu.subMenu == $scope.typeId ? "" : '/' + $scope.menu.subMenu;
              $location.path('/' + $scope.menu.topMenu + subMenu + '/' + $scope.typeId);
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
      }
    )
    .controller('ReportFilterGridCtrl',

      function ($scope, reportFilterContext, reportFilterMetadata, $stateParams) {

        var urlRoute = "";
        switch ($stateParams.typeId) {
          case "4":
          case "15":
            urlRoute = "/admin/filter/" + $stateParams.typeId;
            break;
          default :
            urlRoute = "/filter/" + $stateParams.typeId;
            break;
        }


        $scope.metadata = reportFilterMetadata;
        $scope.context = reportFilterContext;

        $scope.config = {
          urlRoute: urlRoute,
          enableSorting: true,
          enableAdd: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: "id", width: 60, enableCellEdit: false, visible: false},
            {name: "name", enableCellEdit: true, enableHiding: false},
            {name: "description", enableCellEdit: true, enableHiding: false},
            {name: "operate", actions: ['edit', 'delete'], enableHiding: false}
          ]
        };

      }
    )

    .controller('CorrectionsCtrl',

      function ($scope, $cookies, $location, $filter, corrections, $locale, prepareReportOrCorrections) {

        $scope.notContains = function (value, array) {
          return $.inArray(value, array) < 0;
        };

        $scope.contains = function (value, array) {
          return $.inArray(value, array) >= 0;
        };

        $scope.dateFormat = $locale.DATETIME_FORMATS.shortDate;

        $scope.reportingDate = {
          dateId: 1,
          from: null,
          to: null
        };

        $scope.entityUrl = function (id) {
          return "#/report/corrections/" + $scope.transactionType + "/" + $scope.reportingDate.dateId + "/" + id;
        };

        $scope.updateDate = function () {
          if ($scope.reportingDate.from && $scope.reportingDate.dateId == 8) {
            prepareReportOrCorrections.setValue('fd', $filter("date")($scope.reportingDate.from, 'yyyy-MM-dd'), $scope.filterType ? $scope.filterType.id : '', true);
            //$scope.from = from;
          } else {
            $location.search('fd', null);
          }
          if ($scope.reportingDate.to && $scope.reportingDate.dateId == 8) {
            prepareReportOrCorrections.setValue('td', $filter("date")($scope.reportingDate.to, 'yyyy-MM-dd'), $scope.filterType ? $scope.filterType.id : '', true);
            //$scope.to = to;
          } else {
            $location.search('td', null);
          }
          prepareReportOrCorrections.setValue('d', $scope.reportingDate.dateId, $scope.filterType ? $scope.filterType.id : '', true);
          //$scope.date = date;

          $location.path("/report/corrections/" + $scope.transactionType + "/" + $scope.reportingDate.dateId);
        };

        $scope.updateType = function (type) {
          $location.path("/report/corrections/" + type + "/" + $scope.reportingDate.dateId);
        };

        if (corrections.ok) {
          angular.extend($scope, corrections.payload);
          var stringDateFrom = prepareReportOrCorrections.getValue('fd', $scope.filterType ? $scope.filterType.id : '', true) || prepareReportOrCorrections.fetchAndSetValue('fd', $scope.reportingDate.from, $scope.filterType ? $scope.filterType.id : '', true);
          var stringDateTo = prepareReportOrCorrections.getValue('td', $scope.filterType ? $scope.filterType.id : '', true) || prepareReportOrCorrections.fetchAndSetValue('td', $scope.reportingDate.to, $scope.filterType ? $scope.filterType.id : '', true);
          $scope.reportingDate.dateId = parseInt(prepareReportOrCorrections.getValue('d', $scope.filterType ? $scope.filterType.id : '', true) || prepareReportOrCorrections.fetchAndSetValue('d', $scope.reportingDate.dateId, $scope.filterType ? $scope.filterType.id : '', true));
          $scope.reportingDate.from = prepareReportOrCorrections.createDate(stringDateFrom);
          $scope.reportingDate.to = prepareReportOrCorrections.createDate(stringDateTo);
          if ($scope.reportingDate.dateId != 8) {
            $location.search('fd', null);
            $location.search('td', null);
          }
        }
      }
    )
    .controller('CorrectionCtrl',

      function ($scope, $location, $stateParams, correction) {
        $scope.goBack = function () {
          $location.path("/report/corrections/" + $stateParams.transactionType + "/" + $stateParams.date);
        };

        if (correction.ok) {
          angular.extend($scope, correction.payload);

          $scope.positions = $scope.transaction.transaction_pay_reopen_pos || $scope.transaction.transaction_pay_adjustment_pos;
        }
      }
    )

    .controller('MessageCtrl',

      function ($scope, message) {
        if (message.ok) {
          angular.extend($scope, message.payload);
        }
      }
    )

    .factory('reportHelper', function () {
      return {
        findReport: findReport
      };

      function findInGroup(reports, selectedReportId) {
        return _.find(reports, function (report) {
          return +report.id === +selectedReportId;
        });
      }

      function findInSuperGroup(superGroup, selectedReportId) {
        var result = null;
        _.forEach(superGroup.reports, function (group) {
          var report = findInGroup(group.reports, selectedReportId);
          if (report) {
            result = {
              selectedReport: report,
              selectedReportGroup: group
            };
            return false; //to break
          }
        });
        return result;
      }

      function findReport(superGroups, selectedReportId) {
        var result = null;
        _.forEach(superGroups, function (superGroup) {
          var found = findInSuperGroup(superGroup, selectedReportId);
          if (found) {
            result = found;
            result.selectedGroup = result.selectedReportGroup;
            result.selectedSuperGroup = superGroup;
            return false;  //to break
          }
        });
        if (result) {
          return result;
        }
        _.forEach(superGroups, function (superGroup) {
          var found = findReport(superGroup.groups, selectedReportId);
          if (found) {
            result = found;
            result.selectedGroup = result.selectedSuperGroup;
            result.selectedSuperGroup = superGroup;
            return false;  //to break
          }
        });
        return result;
      }
    })

    .directive('gfDocumentArchive', function (prepareReportOrCorrections, $http, $stateParams, $filter, $location, $uibModal, $translate, notifyManager) {

      return {
        template: require('../template/report/document_archive.html'),
        link: function ($scope, element, attrs, controller) {
          $scope.reportingDate.dateId = 5;
          $scope.filter = {
            waiterId: null,
            reasonIds: [],
            amountStart: null,
            amountEnd: null,
            from: null,
            to: null
          };

          $scope.accountTypeGroup = function (item) {
            return $scope.payload.reasons.indexOf(item) > -1
              ? $translate.instant("AccountType." + item.accountType.name)
              : undefined;
          };

          $scope.isAnotherAccountTypeChosen = function (item) {
            if ($scope.filter.reasonIds && $scope.filter.reasonIds.length) {
              var chosenItem = _.find($scope.payload.reasons, ['id', $scope.filter.reasonIds[0]]);
              return chosenItem.accountType.id != item.accountType.id;
            } else {
              return false;
            }
          };

          $scope.getDocumentArchive = function () {
            var reportUrl = '/icash/report/archive/' + $scope.reportingDate.dateId + '.json';
            if ($scope.reportingDate.dateId == 8) {
              if (!$scope.filter.from || !$scope.filter.to) {
                notifyManager.error($translate.instant('rksv.dep.chooseDates'));
                return;
              } else {
                $scope.filter.from = $filter("date")($scope.filter.from, 'yyyy-MM-dd');
                $scope.filter.to = $filter("date")($scope.filter.to, 'yyyy-MM-dd');
              }
            } else {
              $scope.filter.from = null;
              $scope.filter.to = null;
            }
            if (!$scope.filter.reasonIds && !$scope.filter.reasonIds.length) {
              $scope.filter.reasonIds = null;
            }
            $http.get(reportUrl, {
              params: $scope.filter
            }).then(function (response) {
              $scope.payload = response.data.payload;
              $scope.filter.from = new Date($scope.payload.from);
              $scope.filter.to = new Date($scope.payload.to);
            });
          };

          $scope.getDocumentArchive($scope.reportingDate.dateId);

          $scope.updateDate = function (from, to) {
            if (from && $scope.reportingDate.dateId == 8) {
              $scope.filter.from = from;

            } else {
              $scope.filter.from = null;
            }
            if (to && $scope.reportingDate.dateId == 8) {
              $scope.filter.to = to;

            } else {
              $scope.filter.to = null;
            }
            //$scope.date = date;
          };

          $scope.previewAttachment = function (transaction) {
            $uibModal.open({
              template: require('../template/report/attachment_preview_modal.html'),
              windowClass: 'gf-modal',
              controller: function ($scope, $uibModalInstance) {
                $scope.transaction = transaction;
                $scope.getImageUrl = function (item) {
                  return '/icash/report/archive/' + transaction.id + '/images/' + item.sequenceNumber + '.jpg';
                };
                $scope.lineItems = transaction.lineItems;
                $scope.close = function () {
                  $uibModalInstance.close();
                }
              }
            });
          };
        }
      }
    })
    .filter('propsFilter', function () {
      return function (items, props) {
        var out = [];

        if (angular.isArray(items)) {
          var keys = Object.keys(props);

          items.forEach(function (item) {
            var itemMatches = false;

            for (var i = 0; i < keys.length; i++) {
              var prop = keys[i];
              var text = props[prop].toLowerCase();
              if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                itemMatches = true;
                break;
              }
            }
            if (itemMatches) {
              out.push(item);
            }
          });
        } else {
          // var the output be the input untouched
          out = items;
        }
        return out;
      };
    });

})
();


