import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import {CloudDownload} from "@material-ui/icons";
import {CircularProgress, Tooltip, Typography} from "@material-ui/core";
import axios from "axios";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {getAlertMessage} from "./Alert";
import FileSaver from 'file-saver';

function CsvReportButton({type, from, to, disabled}) {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);

  const downloadCSV = () => {
    setLoading(true);
    enqueueSnackbar(t('report.download.loading'), {variant: 'warning'})

    resolveReportUrl(type, from, to)
      .then(resp => {
        const csvData = new Blob([resp.data], {type: 'text/csv;charset=utf-8;'});
        FileSaver.saveAs(csvData, getCsvFileName());

        enqueueSnackbar(t('report.download.success.short'), {variant: 'success'});
      })
      .catch(error => {
        enqueueSnackbar(t(getAlertMessage(error)), {variant: 'error'});
      })
      .finally(() => {
        setLoading(false);
      })
  }

  const getCsvFileName = () => {
    var period = '_' + from + (from !== to && to ? '_-_' + to : '');
    return 'LS_Pay_' + type + period + '.csv';
  }

  return (<div style={{position: 'relative'}}>
    <Tooltip title={<Typography variant="body2">{t('lsp.download.csv.tooltip')}</Typography>} arrow placement="bottom-end">
      <Button variant="outlined"
              startIcon={<CloudDownload/>}
              onClick={downloadCSV}
              disabled={disabled || loading}>{t('report.download.csv')}</Button>
    </Tooltip>
    {loading && <CircularProgress size={24} style={{
      position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12,
    }}/>}
  </div>)
}

export default CsvReportButton

function resolveReportUrl(type, from, to) {
  return axios.post('/icash/ls_payments/' + type + '/export', {
    startDate: moment(from).unix(),
    endDate: moment(to).unix()
  })
}
