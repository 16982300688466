import _ from "lodash";
import axios from "axios";
import moment from "moment";

export function setUrlParameters(parameterName, parameterValue) {
  let url = window.location.href;
  if (getExistingParameter(url, parameterName)) {
    window.location = updateURLParameter(url, parameterName, encodeURIComponent(parameterValue));
  } else {
    if (!parameterValue) return;
    window.location = url + (url.split('?')[1] ? '&' : '?') + parameterName + '=' + encodeURIComponent(parameterValue);
  }
}

export function getQueryParams() {
  let params = {};
  window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    params[key] = value;
  });
  return params;
}

export function urlToFilter(urlParams, filters, filtersMetadata, translate) {
  let normalFiltersArray = [];
  for (let [key, value] of Object.entries(JSON.parse(urlParams))) {
    let filterValue = [];
    _.forEach(filters[key], function (val) {
      if (_.find(value, val)) {
        filterValue.push(val);
      } else if (filtersMetadata && filtersMetadata[key] && filtersMetadata[key].javaType === 'boolean' && _.isEqual(val, value)) {
        filterValue = value;
      }
    });
    if (filterValue.length || !_.isEmpty(filterValue)) {
      normalFiltersArray.push({
        'filter': key,
        'value': filterValue,
        'label': translate('report.filter' + key) + ": " + (filterValue.length ? getFilterValuesList(key, filterValue, translate) : translate(filterValue.names[0])),
        'type': filtersMetadata && filtersMetadata[key] && filtersMetadata[key].javaType ? filtersMetadata[key].javaType : 'multiselect'
      })
    }
  }
  return normalFiltersArray;
}

export function getFilterValuesList(filterName, filterValues, translate) {
  return _.map(filterValues, function (f) {
    return typeof f.value === 'boolean' ? translate(f.name) : (filterName === 'Article' ? '[' + f.id + '] ' + f.name : f.name);
  }).join(', ');
}

function getExistingParameter(url, params) {
  //this expression is to get the query strings
  let reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i');
  let queryString = reg.exec(url);
  return queryString ? queryString[1] : null;
}

function updateURLParameter(url, param, paramVal) {
  let newAdditionalURL = "";
  let tempArray = url.split("?");
  let baseURL = tempArray[0];
  let additionalURL = tempArray[1];
  let temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split('=')[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }

  let rows_txt = temp + '' + (paramVal ? param + '=' + paramVal : '');
  return baseURL + '?' + newAdditionalURL + rows_txt;
}

export function getFiltersForReportType(allFilters, filtersMetadata, typeFilters) {
  let actualFilters = _.pickBy(allFilters, function (value, key) {
    return _.includes(typeFilters, key)
  });
  let filters = {};

  function getValues(value, type) {
    let val = [];
    if (type === 'boolean') {
      val = value;
    } else {
      _.forEach(value, function (v) {
        val.push({id: v.id, name: v.names.join(', ')})
      });
    }
    return val;
  }

  for (let [key, value] of Object.entries(actualFilters)) {
    if (value.length) {
      let filterType = filtersMetadata && filtersMetadata[key] && filtersMetadata[key].javaType
        ? filtersMetadata[key].javaType : 'multiselect';
      filters[key] = getValues(value, filterType);
      filters[key]['filterType'] = filterType;
    }
  }
  return filters;
}

export function getFilters(chosenDate, filters) {
  let fromToPeriod = resolveFromToForPeriods(chosenDate);
  if (!isAutopreviewPeriod(chosenDate)) {
    filters = {};
  }
  return axios.post('/icash/report/report_filter/filters/' + fromToPeriod.from + '/' + fromToPeriod.to, filters);
}

export function getReportTypes() {
  return axios.get('/icash/report/report_filter/types/');
}

export function resolveFromToForPeriods(predefinedDate) {
  switch (+predefinedDate.id) {
    case 1:
      return {
        from: moment().format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      };
    case 2:
      return {
        from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        to: moment().subtract(1, 'days').format('YYYY-MM-DD')
      };
    case 3:
      return {
        from: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      };
    case 4:
      return {
        from: moment().subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'),
        to: moment().subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD')
      };
    case 5:
      return {
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      };
    case 6:
      return {
        from: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        to: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      };
    case 7:
      return {
        from: moment().startOf('year').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      };
    default:
      return {
        from: predefinedDate.from,
        to: predefinedDate.to
      }
  }
}

export function isAutopreviewPeriod(period) {
  if (!period) return false;
  let fromToPeriods = resolveFromToForPeriods(period);
  let start = moment(fromToPeriods.from, 'YYYY-MM-DD');
  let end = moment(fromToPeriods.to, 'YYYY-MM-DD');
  return end.diff(start, 'days') < 31;
}

export function filterToObject(filters, allFilters) {
  function prepareFilters(values, filter) {
    return _.filter(allFilters[filter], function (item) {
      return values.includes(item.id)
    })
  }

  return filters.reduce((obj, item) => {
    return {
      ...obj,
      [item['filter']]: item.type === 'boolean' ? item.value.id : prepareFilters(_.map(item.value, 'id'), item.filter)
    }
  }, {})
}

export function filterToUrl(filters) {
  return filters.reduce((obj, item) => {
    return {
      ...obj,
      [item['filter']]: item.value,
    };
  }, {})
}

export function getAvailableForType(chosenFilters, typeFilters) {
  if (!chosenFilters.length || _.isEmpty(typeFilters)) {
    return [];
  }
  return _.filter(chosenFilters, f => {
    return typeFilters.includes(f.filter);
  })
}
