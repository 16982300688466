var ProfileCtrlResolve = {
  context: ["ProfileContext", function (ProfileContext) {
    return ProfileContext.get().$promise;
  }],
  profile: ["Profile", function (Profile) {
    return Profile.get().$promise;
  }]
};

var ManualCtrlResolve = {
  manualUrl: ['$resource', '$stateParams', function ($resource, $stateParams) {
    return $resource('/icash/manual/' + $stateParams.manualType).get().$promise;
  }]
};

(function () {
  'use strict';
  angular.module('gf.main', ['gf.utils'])

    .run(function ($templateCache) {
      $templateCache.put('app/template/switchRestaurantTypeahead.html', require('./../../../app/template/switchRestaurantTypeahead.html'));
    })

    .config(function($stateProvider) {

      $stateProvider

        .state('page.manualType', {
          url: '/manual/:manualType',
          template: require('./../../template/page/manual/manual.html'),
          controller: 'ManualCtrl',
          resolve: ManualCtrlResolve,
          title: 'jsp.menu.manual'
        })

        .state('page.manualLink', {
          url: '/manual_link/manual',
          template: require('./../../template/page/manual/manual_link.html'),
          controller: function ($scope) {
          },
          title: 'jsp.menu.manual'
        })

        .state('page.userProfile', {
          url: '/user/profile',
          template: require('./../../template/page/user/profile.html'),
          controller: 'ProfileCtrl',
          resolve: ProfileCtrlResolve,
          title: 'jsp.user.settings'
        })

    })

    .controller('AppCtrl',

      function ($scope, $uibModal, $http, $location, securityService, SwitchOperators, SwitchRestaurant, notifyManager,
                isCleanPage, Info, $rootScope, logoutService, ExitUser, $state, $translate) {
        $scope.redirectInfo = Info.payload;

        $scope.goToWelcome = function () {
          if ($state.current.url !== '/welcome') {
            $state.go('page.welcome');
          }
        };

        $rootScope.showReports = function () {
          return securityService.hasAccess('/report/')
        };
        /* ICR-6909 */
        $rootScope.showFilters = securityService.hasAccess('/filter/') && !securityService.context.settings.enableLowEndMarket;
        $rootScope.showBranch = securityService.hasAccess('/branch/') && !securityService.context.settings.enableLowEndMarket && securityService.hasAnyFeature('BRANCH_CONTROL');
        $rootScope.showBranchFilters = securityService.hasAccess('/filter_branch/') && !securityService.context.settings.enableLowEndMarket && securityService.hasAnyFeature('BRANCH_CONTROL');

        $rootScope.onlyReports = $rootScope.showReports && !$rootScope.showFilters && !$rootScope.showBranch && !$rootScope.showBranchFilters;
        // });

        $scope.cleanPage = function () {
          return isCleanPage.clean();
        };

        $scope.changeRestaurant = function () {
          var modalDialog = $uibModal.open({
            template: require('./../../template/page/user/switch_restaurant.html'),
            controller: 'ChangeRestaurantCtrl',
            windowClass: 'gf-modal',
            resolve: {
              switchOperators: [function () {
                return SwitchOperators.load().$promise;
              }],
              switchRestaurant: [function () {
                return SwitchRestaurant.load().$promise;
              }]
            }

          });

          modalDialog.result.then(function (switchRestaurant) {
          });
        };

        $scope.init = function () {
          if ($scope.redirectInfo.redirectMessage) {
            switch ($scope.redirectInfo.redirectStyle) {
              case 'info':
                notifyManager.info($scope.redirectInfo.redirectMessage);
                break;
              case 'error':
                notifyManager.error($scope.redirectInfo.redirectMessage);
                break;
              default:
                notifyManager.success($scope.redirectInfo.redirectMessage);
            }
          }
        };

        $scope.changeRestaurantGroup = function () {
          $uibModal.open({
            template: require('./../../template/switch_group.html'),
            controller: 'ChangeRestaurantGroupCtrl',
            windowClass: 'gf-modal',
            resolve: {
              switchRestaurantGroup: ["SwitchRestaurantGroup", function (SwitchRestaurantGroup) {
                return SwitchRestaurantGroup.load().$promise;
              }],
              restaurantGroups: ["RestaurantGroups", function (RestaurantGroups) {
                return RestaurantGroups.query().$promise;
              }]
            }
          });
        };

        $scope.changeBranchGroup = function () {
          $uibModal.open({
            template: require('./../../template/switch_branch_group.html'),
            controller: 'ChangeBranchGroupCtrl',
            windowClass: "gf-modal",
            resolve: {
              branchGroups: function (BranchGroup) {
                return BranchGroup.get().$promise;
              }
            }
          }).result.then(function (master) {
            $state.go('bm.dashboardDateType', {groupId: master.id});
          });
        };

        $scope.switchToMaster = function () {
          $http.post("/icash/user/master").then(function () {
            location.reload();
          });
        };

        $scope.logout = logoutService.logout;

        $scope.userExit = function () {
          $http.get(ExitUser).then(function () {
            securityService.refreshContext().then(function () {
              $location.path("/");
            });
          });
        };

        function formTermsLink() {
          var termValues = {
            link1: '<a id="terms_link" href="' + $translate.instant('termsAndConditionsUrl') + '" target="_blank">',
            link1end: '</a>'
          };
          return $translate.instant('jsp.copyright', {link1: termValues.link1, link1end: termValues.link1end});
        }

        $translate.onReady().then(function () {
          $scope.copyright = formTermsLink();
        });
        $rootScope.$on('$translateChangeSuccess', function () {
          $scope.copyright = formTermsLink();
        });
      }
    )
    .controller('ChangeRestaurantCtrl',

      function ($scope, $uibModalInstance, $http, $location, switchOperators, switchRestaurant, SwitchOperators) {
        $scope.switchOperators = switchOperators;
        $scope.switchRestaurant = switchRestaurant;

        $scope.autocomplete = function (val) {
          return $http.get("/icash/user/autocomplete", {params: {term: val}}).then(function (data) {
            return data.data;
          });
        };

        $scope.onSelectRestaurant = function (item) {

          if (item.restaurant) {
            $scope.switchRestaurant.restaurant.id = item.restaurant;
            $scope.change();
          }
        };

        $scope.changeType = function () {
          SwitchOperators.load({"type": $scope.switchRestaurant.type.id}, function (data) {
            $scope.switchOperators = data;

            if ($scope.switchOperators.operators.length > 0) {
              $scope.switchRestaurant.operator.id = $scope.switchOperators.operators[0].id;
            } else {
              $scope.switchRestaurant.operator.id = 0;
            }
            if ($scope.switchOperators.restaurants.length > 0) {
              $scope.switchRestaurant.restaurant.id = $scope.switchOperators.restaurants[0].id;
            } else {
              $scope.switchRestaurant.restaurant.id = 0;
            }

          });
        };

        $scope.changeOperator = function () {
          SwitchOperators.load({"operator": $scope.switchRestaurant.operator.id}, function (data) {
            $scope.switchOperators = data;

            if ($scope.switchOperators.restaurants.length > 0) {
              $scope.switchRestaurant.restaurant.id = $scope.switchOperators.restaurants[0].id;
            } else {
              $scope.switchRestaurant.restaurant.id = 0;
            }

          });
        };

        $scope.ok = function () {
          $uibModalInstance.close();
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.change = function () {
          $scope.switchRestaurant.$change(function () {
            // todo o.logunov update after fix ICR-5528
            var path = $location.absUrl().split('#')[0];
            window.open(path, '_self', "", true);
          });
        }
      }
    )
    .controller('ChangeRestaurantGroupCtrl',

      function ($scope, $uibModalInstance, $location, switchRestaurantGroup, restaurantGroups) {
        $scope.switchRestaurantGroup = switchRestaurantGroup;
        $scope.restaurantGroups = restaurantGroups;

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.clear = function () {
          $scope.switchRestaurantGroup.restaurantGroup = null;
          $scope.switchRestaurantGroup.$change(function () {
            // todo o.logunov update after fix ICR-5528
            var path = $location.absUrl().split('#')[0];
            window.open(path, '_self', "", true);
          });
        };

        $scope.change = function () {
          $scope.switchRestaurantGroup.$change(function () {
            // todo o.logunov update after fix ICR-5528
            var path = $location.absUrl().split('#')[0];
            window.open(path, '_self', "", true);
          });
        }
      }
    )
    .controller('ChangeBranchGroupCtrl',

      function ($scope, $uibModalInstance, $state, branchGroups, gridTreeHelper) {

        $scope.master = {};
        $scope.branchGroups = _.filter(gridTreeHelper.flattenData(branchGroups.root), {'hasAccess': true});

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.change = function () {
          $uibModalInstance.close($scope.master);
        }
      }
    )
    .controller('ProfileChangePasswordCtrl',

      function ($scope, $uibModalInstance, ProfilePassword) {

        $scope.changePassword = new ProfilePassword({});
        $scope.passwordMetadata = {
          fields: {
            ps: {
              validators: [
                {
                  name: 'gf-password',
                  message: 'error.password.groupRequirements'
                },
                {
                  name: 'ng-minlength',
                  value: 8,
                  message: 'error.password.lengthRequirements'
                },
                {
                  name: 'required',
                  message: 'error.password.required'
                }
              ]
            },
            psc: {
              validators: [
                {
                  name: 'required',
                  message: 'error.password.required'
                }
              ]
            }
          }};

        $scope.ok = function () {
          $uibModalInstance.close();
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.change = function () {
          $scope.changePassword.$save(
            function (data) {
              if (data.ok) {
                $uibModalInstance.close(true);
              } else {
                $uibModalInstance.close();
              }
            });
        }
      }
    )
    .controller('ProfileCtrl',

      function ($scope, $uibModal, $translate, $http, notifyManager, context, profile, Profile, securityService) {

        $scope.masterProfile = angular.copy(profile);

        $scope.profile = profile;
        $scope.metadata = context.payload.metadata;
        $scope.languages = context.payload.languages;

        //add required to 'Email' field
        $scope.metadata.columns.email.angular = {'required': ''};
        $scope.metadata.columns.email.validation = ['required'];
        $scope.metadata.columns.email.errorMessages =
          [
            {
              'keys': ['required'],
              'message': 'org.hibernate.validator.constraints.NotBlank.message',
              'name': 'Required'
            }
          ];

        $scope.save = function () {
          if ($scope.profileForm.$valid) {
            $scope.profile.$save(function (data) {
              if (data.ok) {
                $scope.masterProfile = angular.copy(data.payload.profile);
                $scope.profile = new Profile(data.payload.profile);
                notifyManager.success($translate.instant('entity.edit.successfully'));
                $scope.profileForm.$setPristine();

                securityService.refreshContext();
              }
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

        $scope.reset = function () {
          $scope.profile = new Profile(angular.copy($scope.masterProfile));
          $scope.profileForm.$setPristine();
        };

        $scope.confirm = function () {
          $http.post("/icash/user/-1/email/confirm")
            .then(function (resp) {
              if (resp.data.ok) {
                notifyManager.success($translate.instant('user.mailConfirmed.notification'));
              } else {
                notifyManager.error($translate.instant(resp.data.message));
              }
            });

        };

        $scope.changePassword = function () {
          var modalDialog = $uibModal.open({
            template: require('./../../template/page/change_password.html'),
            windowClass: 'gf-modal',
            controller: 'ProfileChangePasswordCtrl'
          });

          modalDialog.result.then(function (user) {
            if (user) {
              notifyManager.success($translate.instant('changePassword.success'));
            }
          });
        };

        $scope.mailChanged = function () {
          $scope.profile.mailConfirmed = false;
        }
      }
    )
    .controller('ManualCtrl', ['$scope', 'manualUrl',
      function ($scope, manualUrl) {
        $scope.manualType = manualUrl.payload.manualType;
        $scope.pathToManual = manualUrl.payload.pathToManual;
      }
    ]);

})();
