import React, {useState} from 'react';
import Grid from '@material-ui/core/Grid';
import {Tab} from '@material-ui/core';
import moment from 'moment';
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import Transactions from './Transactions';
import withStyles from '@material-ui/core/styles/withStyles';
import DatePicker from '../common-components/DatePicker';
import ReportPeriod from '../common-components/ReportPeriod';
import Settlements from './Settlements';
import {useTranslation} from "react-i18next";
import CsvReportButton from "./CsvReportButton";

const reportTypes = {
  TRANSACTIONS: 'transactions',
  SETTLEMENTS: 'settlements'
}

function Adyen({
                 onChangeReportType,
                 initialReportType,
                 onChangeReportPeriod,
                 onChangeReportSortModel,
                 onChangeReportPage,
                 initialReportPeriodId,
                 initialReportFrom,
                 initialReportTo,
                 goToDetails,
                 goToBill,
                 sortModel,
                 page
               }) {
  const {t} = useTranslation();

  const [reportType, setReportType] = useState(
    (initialReportType && reportTypes[initialReportType.toUpperCase()])
    || reportTypes.TRANSACTIONS);

  const [reportPeriod, setReportPeriod] = useState(makeReportPeriod(initialReportPeriodId, initialReportFrom, initialReportTo));
  const [noData, setNoData] = useState(true);

  const onChangeType = (event, newValue) => {
    setReportType(newValue);
    onChangeReportType(newValue);
    setNoData(true);
  };

  const onChangePeriod = reportPeriod => {
    setReportPeriod(reportPeriod);
    onChangeReportPeriod(reportPeriod);
    setNoData(true);
  };

  return (
    <Grid container>
      <Grid item container xs={12}>
        <Grid item xs={8}>
          <DatePicker variant='outlined' color='default' value={reportPeriod}
                      onSelect={onChangePeriod}/>
        </Grid>
        <Grid item container xs={4}>
          <Grid item container xs={12} justify="flex-end">
            <CsvReportButton type={reportType}
                               from={moment(reportPeriod.getPeriod().from).format('YYYY-MM-DD')}
                               to={moment(reportPeriod.getPeriod().to).format('YYYY-MM-DD')}
                               disabled={noData}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TabContext value={reportType}>
          <TabList
            indicatorColor='primary'
            textColor='primary'
            onChange={onChangeType}
          >
            <Tab label={t('lsp.transactions')}
                 value={reportTypes.TRANSACTIONS}/>
            <Tab label={t('lsp.settlements')}
                 value={reportTypes.SETTLEMENTS}/>
          </TabList>
          <TabPanelNoSpacing value={reportTypes.TRANSACTIONS}>
            <Transactions from={reportPeriod.getPeriod().from}
                          to={reportPeriod.getPeriod().to}
                          goToDetails={goToDetails}
                          goToBill={goToBill}
                          onDataLoad={data => {
                            setNoData(!data?.length);
                          }}
                          onChangeReportSortModel={onChangeReportSortModel}
                          onChangeReportPage={onChangeReportPage}
                          sortModel={sortModel}
                          page={page}
            />
          </TabPanelNoSpacing>
          <TabPanelNoSpacing value={reportTypes.SETTLEMENTS}>
            <Settlements from={reportPeriod.getPeriod().from}
                         to={reportPeriod.getPeriod().to}
                         goToDetails={goToDetails}
                         onDataLoad={data => {
                           setNoData(!data?.length);
                         }}
                         onChangeReportSortModel={onChangeReportSortModel}
                         onChangeReportPage={onChangeReportPage}
                         sortModel={sortModel}
                         page={page}
            />
          </TabPanelNoSpacing>
        </TabContext>
      </Grid>
    </Grid>
  )
}

export default Adyen;

const TabPanelNoSpacing = withStyles(theme => ({
  root: {
    padding: 0,
    paddingTop: theme.spacing(1)
  }
}))(TabPanel);

const makeReportPeriod = (initialReportPeriodId, initialReportFrom, initialReportTo) => {
  const periodId = (initialReportPeriodId && +initialReportPeriodId) || 3;
  const period = ReportPeriod.fromPeriodId(periodId);
  if (periodId === 8) {
    period.from = (initialReportFrom && +initialReportFrom) || undefined;
    period.to = (initialReportFrom && +initialReportTo) || undefined;
  }
  return period;
}


