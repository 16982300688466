(function () {
  'use strict';
  angular.module('gf.bm.ticketLayout', ['gf.bm.groupView'])

    .factory('BMTicketLayoutMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/ticket_layout/metadata.json')
    })
    .factory('BMTicketLayoutContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/ticket_layout/context.json')
    })
    .factory('BMTicketLayoutRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.hardwareTicket_layout', {
          url: '/hardware/ticket_layout',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMHardwareTicketLayout',
          controllerAs: '$ctrl',
          resolve: {
            bmTicketLayoutMetadata: function (BMTicketLayoutMetadata, $stateParams, securityService) {
              return BMTicketLayoutMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmTicketLayoutContext: function (BMTicketLayoutContext, $stateParams, securityService) {
              return BMTicketLayoutContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmTicketLayoutRules: function (BMTicketLayoutRules, $stateParams, securityService) {
              return BMTicketLayoutRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "hardwareTicketLayout"
        })
    })

    .controller('BMHardwareTicketLayout',
      function (bmTicketLayoutMetadata, bmTicketLayoutContext, securityService, $stateParams, bmTicketLayoutRules) {
        var self = this;

        self.metadata = bmTicketLayoutMetadata;
        self.context = bmTicketLayoutContext;
        self.masterdataRules = bmTicketLayoutRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name', enableCellEdit: false},
            {name: 'type', enableCellEdit: false, cellFilter: "gfProperty:'name' | prefixFilter:'ticketType.' | translate"}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/ticket_layout';
          }
        };

      }
    )

})();
