import React from 'react';
import {TextField} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    color: 'inherit !important'
  }
});

function GfStaticFormControl(props) {
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      margin='normal'

      {...props}

      readOnly
      disabled
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.root
        }
      }}
    />
  );
}

export default GfStaticFormControl;
