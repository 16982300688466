const { MERP_COUNTRIES, isBusinessGroup, MERP_USER_ROLES, isWhitelabel, makeCapitalBannerKey } = require('../../../merp-helpers');

(function () {
  'use strict';
  angular.module('gf.dashboard', ['gf.services'])

    .run(function ($templateCache) {
      $templateCache.put('app/template/page/dashboard/widgets/bestsellers.html', require('../template/page/dashboard/widgets/bestsellers.html'));
      $templateCache.put('app/template/page/dashboard/widgets/businessDayPopoverTemplate.html', require('../template/page/dashboard/widgets/businessDayPopoverTemplate.html'));
      $templateCache.put('app/template/page/dashboard/widgets/contactInfoPopoverTemplate.html', require('../template/page/dashboard/widgets/contactInfoPopoverTemplate.html'));
      $templateCache.put('app/template/page/dashboard/widgets/discount.html', require('../template/page/dashboard/widgets/discount.html'));
      $templateCache.put('app/template/page/dashboard/widgets/gastronomicDay.html', require('../template/page/dashboard/widgets/gastronomicDay.html'));
      $templateCache.put('app/template/page/dashboard/widgets/lastOrders.html', require('../template/page/dashboard/widgets/lastOrders.html'));
      $templateCache.put('app/template/page/dashboard/widgets/performanceTracking.html', require('../template/page/dashboard/widgets/performanceTracking.html'));
      $templateCache.put('app/template/page/dashboard/widgets/summary.html', require('../template/page/dashboard/widgets/summary.html'));
      $templateCache.put('app/template/page/dashboard/widgets/performance/costs_popover.html', require('../template/page/dashboard/widgets/performance/costs_popover.html'));
      $templateCache.put('app/template/page/dashboard/widgets/performance/earnings_popover.html', require('../template/page/dashboard/widgets/performance/earnings_popover.html'));
      $templateCache.put('app/template/page/dashboard/widgets/performance/no_enough_data_popover.html', require('../template/page/dashboard/widgets/performance/no_enough_data_popover.html'));
      $templateCache.put('app/template/page/dashboard/widgets/performance/performance_template.html', require('../template/page/dashboard/widgets/performance/performance_template.html'));
      $templateCache.put('app/template/page/dashboard/charts/chartsTemplate.html', require('../template/page/dashboard/charts/chartsTemplate.html'));
      $templateCache.put('app/template/page/dashboard/charts/map_pop_up.html', require('../template/page/dashboard/charts/map_pop_up.html'));
      $templateCache.put('app/template/page/dashboard/charts/openTables.html', require('../template/page/dashboard/charts/openTables.html'));
      $templateCache.put('app/template/page/dashboard/charts/restaurantsMap.html', require('../template/page/dashboard/charts/restaurantsMap.html'));
      $templateCache.put('app/template/forecast.html', require('../../../main/angular/app/template/forecast.html'));
    })

    .config(function ($stateProvider) {

      var dateTypes = ['1', '2', '3', '4', '5', '6', '8'];

      $stateProvider
        .state('page.dashboardApp_version', {
          url: '/dashboard/app_version',
          template: require('../template/page/dashboard/app_versions.html'),
          controller: 'AppVersionsCtrl',
          resolve: {
            appVersions: function (AppVersions) {
              return AppVersions.load().$promise;
            }
          },
          title: 'menu.dashboard'
        })

        .state('page.dashboardEdit', {
          url: '/dashboard/edit',
          template: require('../template/page/dashboard/dashboard_edit.html'),
          controller: 'DashboardEditCtrl',
          resolve: {
            dashboard: function (DashboardEntry) {
              return DashboardEntry.get().$promise;
            },
            dashboardContext: function (DashboardContext) {
              return DashboardContext.get().$promise;
            },
            currentTour: function (Tour) {
              return Tour.get({page: 'dashboardEdit'}).$promise;
            }
          },
          title: 'menu.dashboard'
        })

        .state('page.dashboardDateType', {
          url: '/dashboard/:dateType',
          template: require('../template/page/dashboard/main_dashboard.html'),
          controller: 'DashboardCtrl',
          controllerAs: '$ctrl',
          resolve: {
            dashboard: function (Dashboard, $stateParams) {
              var normalDateType = _.parseInt($stateParams.dateType).toString();
              $stateParams.dateType = _.includes(dateTypes, normalDateType) ? normalDateType : '1';
              return Dashboard.load({dateType: $stateParams.dateType}).$promise;
            },
            currentTour: function (Tour) {
              return Tour.get({page: 'dashboardDateType'}).$promise;
            }
          },
          title: 'menu.dashboard',
          reloadOnSearch: false
        })

        .state('page.dashboardDateTypeChartType', {
          url: '/dashboard/:dateType/:chartType',
          template: require('../template/page/dashboard/details.html'),
          controller: 'DashboardCtrl',
          controllerAs: '$ctrl',
          resolve: {
            dashboard: function (DashboardDetails, $stateParams) {
              return DashboardDetails.load({
                dateType: $stateParams.dateType,
                chartType: $stateParams.chartType
              }).$promise;
            },
            currentTour: function (Tour) {
              return Tour.get({page: 'dashboardDateType'}).$promise;
            }
          },
          title: 'menu.dashboard',
          reloadOnSearch: false
        })

        .state('page.welcome', {
          url: '/welcome',
          template: require('../template/page/welcome/empty.html'),
          controller: 'WelcomeCtrl',
          resolve: {
            welcome: function (appConfig, $resource) {
              return $resource(appConfig.baseUrl + '/icash/user/profile/welcome').get().$promise;
            },
            trial: function ($http) {
              return $http.get('/icash/feature_trial');
            },
            whatsNew: function ($http) {
              return $http.get('/icash/dashboard/news');
            }
          },
          title: 'welcome'
        })
      ;
    })

    .controller('DashboardEditCtrl',
      function ($scope, securityService, $translate, notifyManager, dashboard, dashboardContext, DashboardEntry, $state,
                tourService) {

        var currentTour = $state.$current.locals.globals.currentTour;
        if (currentTour) {
          tourService.startDetachedTour('dashboardEdit', currentTour);
        }

        function findType(typeId, list, propertyName) {
          var item;
          _.find(list, function (widgetGroup) {
            item = _.find(widgetGroup[propertyName], function (model) {
              return model.id == typeId;
            });
            return item;
          });
          return item;
        }

        var getSortableParameters = function (models, list, propertyName) {
          return {
            forcePlaceholderSize: true,
            tolerance: 'pointer',
            placeholder: 'dashboard-edit-placeholder',
            stop: function (event, ui) {

              $scope.$apply(function () {

                var startIndex = ui.item.attr('ng-index');
                var stopIndex = ($(ui.item).index());

                var move = !ui.item.attr("gf-chart-id");

                if (move) {
                  if (startIndex != stopIndex) {
                    models.splice(stopIndex, 0, models.splice(startIndex, 1)[0]);
                    ui.item.detach();
                  }
                } else {
                  var typeId = ui.item.attr("gf-chart-id");
                  var newModelItem = findType(typeId, list, propertyName);
                  var newModel = {
                    enabled: true,
                    position: 0,
                    type: newModelItem
                  };

                  models.splice(stopIndex, 0, angular.copy(newModel));
                  ui.item.detach();
                }

              });
            }
          };
        };

        angular.extend($scope, dashboardContext.payload);

        $scope.dashboard = dashboard;

        $scope.save = function () {
          $scope.dashboard.$save(function (data) {
            $scope.dashboard = new DashboardEntry(data.payload.dashboard);
            notifyManager.success($translate.instant('dashboard.save.successfully'));

            $scope.sortableWidgets = getSortableParameters($scope.dashboard.widgets, $scope.widgetGroups, 'widgets');
            $scope.sortableCharts = getSortableParameters($scope.dashboard.charts, $scope.chartGroups, 'charts');
          });
        };

        $scope.draggableWidgets = {
          appendTo: ".dashboard-widgets-list",
          connectToSortable: ".dashboard-widgets-list"
        };

        $scope.draggableCharts = {
          appendTo: ".dashboard-charts-list",
          connectToSortable: ".dashboard-charts-list"
        };

        $scope.sortableWidgets = getSortableParameters($scope.dashboard.widgets, $scope.widgetGroups, 'widgets');
        $scope.sortableCharts = getSortableParameters($scope.dashboard.charts, $scope.chartGroups, 'charts');

        $scope.removeItem = function (list, index) {
          list.splice(index, 1);
        }

      }
    )

    .controller('VersionInfoPasswordCtrl',

      function ($scope, $uibModalInstance) {

        $scope.ok = function () {
          $uibModalInstance.close();
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };
      }
    )

    .controller('PermissionsCtrl',

      function ($scope, $uibModalInstance, securityService, logoutService) {
        $scope.logout = function () {
          logoutService.logout()
            .then(function () {
              $uibModalInstance.close();
            });
        };
      }
    )

    .controller('WelcomeCtrl',

      function ($scope, $uibModal, $location, $cookies, securityService, welcome, trial, $http, whatsNew, $filter) {

        $scope.features = welcome.payload.features;
        $scope.showProvideEmail = welcome.payload.showProvideEmail;

        //filter news for user
        var news = (whatsNew.data && whatsNew.data.length) ? $filter('newsFilter')(whatsNew.data) : [];

        function showNewsModal() {
          if (news && news.length) {
            $uibModal.open({
              component: 'newsModal',
              resolve: {
                news: function () {
                  return news;
                },
                user: function () {
                  return securityService.context.currentUser;
                },
                security: function() {
                  return securityService;
                }
              }
            }).result.then(function () {
              if (securityService.context.currentUser.email && securityService.context.currentUser.mailConfirmed) {
                showFeatureTrialModal();
              }
            });
          }
        }

        function showFeatureTrialModal() {
          var trials = _.filter(trial.data, function (item) {
            return !_.includes($scope.security.context.features, item);
          });
          if (trials.length > 0 && trials.includes('POS_LEDGER')) {
            $http.get('/icash/feature_trial/POS_LEDGER')
              .then(function (response) {
                $uibModal.open({
                  component: 'featureTrialModal',
                  resolve: {
                    feature: function () {
                      return response.data;
                    }
                  }
                });
              });
          }
        }

        if (!securityService.availablePath()) {
          $uibModal.open({
            template: require('../template/page/welcome/permissionsPopup.html'),
            controller: 'PermissionsCtrl',
            windowClass: 'gf-modal',
            scope: $scope,
            backdrop: 'static',
            keyboard: false
          });
        } else if (!(securityService.context.currentUser.email && securityService.context.currentUser.mailConfirmed)) {

          $uibModal.open({
            template: require('../template/page/welcome/provideEmailPopup.html'),
            controller: 'ProvideEmailCtrl',
            controllerAs: '$ctrl',
            windowClass: 'gf-modal',
            backdrop: 'static',
            keyboard: false
          }).result
            .finally(function () {
              $location.path(securityService.availablePath('/dashboard/1'));
              showNewsModal();
            });
        } else {
          $scope.$applyAsync(function () {
            $location.path(securityService.availablePath('/dashboard/1'))
          });
          showNewsModal();
        }
      }
    )

    .controller('WelcomeDialogCtrl',

      function ($scope, $uibModalInstance, Welcome) {
        $scope.ok = function (redirect) {
          $uibModalInstance.close({redirect: redirect});
        };

        $scope.close = function () {
          $uibModalInstance.dismiss();
        };

        $scope.okAndDontShowIt = function () {
          $uibModalInstance.close({});
          Welcome.save({doNotShow: true});
        };
      }
    )

    .controller('InstructionsPopupCtrl',

      function ($scope, $uibModalInstance, code) {
        $scope.code = code.payload.code;
        $scope.close = function () {
          $uibModalInstance.dismiss();
        };
      }
    )

    .constant('DASHBOARD_DEFAULT_OPTIONS', {
      isBranchMode: false,
      baseUrl: '/icash/dashboard/',
      filterParams: {}
    })

    .component('gfDashboard', {
      template: require('../template/page/dashboard/dashboard.html'),
      bindings: {
        dashboardState: '<',
        dashboardOptions: '<'
      },
      controller: function ($scope, $filter, $uibModal, $translate, $analytics, notifyManager,
                            $timeout, $state, $http, $locale, $q, securityService, appConfig, WizardCode,
                            $stateParams, mapService, leafletBoundsHelpers, leafletData, osmNominatim, $rootScope,
                            DASHBOARD_DEFAULT_OPTIONS, moment, $interval) {

        var self = this;

        self.security = securityService;
        self.scrollTo = $rootScope.scrollTo;
        self.stateParams = $stateParams;

        self.currentTour = $state.$current.locals.globals.currentTour;

        self.dateFormat = $locale.DATETIME_FORMATS.shortDate;
        self.timeFormat = "EEE " + $locale.DATETIME_FORMATS.shortTime;
        self.fullDate = $locale.DATETIME_FORMATS.fullDate;
        self.fullDateTime = $locale.DATETIME_FORMATS.mediumDate + " " + $locale.DATETIME_FORMATS.shortTime;
        self.businessDayTemplateUrl = "app/template/page/dashboard/widgets/businessDayPopoverTemplate.html";
        self.contactInfoTemplateUrl = "app/template/page/dashboard/widgets/contactInfoPopoverTemplate.html";
        self.map = {
          bounds: {},
          center: {},
          data: {},
          events: {}
        };

        function buildDashboardUrl(path) {
          return self.dashboardOptions.baseUrl + path;
        }

        function loadWeatherWidget(restaurant, selector) {
          self.noLocationFound = false;
          if (+restaurant.latitude === 0 && +restaurant.longitude === 0) {
            self.noLocationFound = true;
            return;
          }

          var options = {
            country: false,
            humidity: false,
            highlow: false,
            wind: false,
            link: false,
            showerror: true,
            woeid: true,
            conditionCodes: $rootScope.weatherConditionCodes,
            translations: {
              unavailable: $translate.instant('weather.unavailable')
            }
          };

          var latitude = restaurant.latitude;
          var longitude = restaurant.longitude;

          var now = new Date();

          osmNominatim.reverse({
            lat: latitude,
            lon: longitude,
            zoom: 16,
            addressdetails: 1,
            'accept-language': securityService.context.currentLanguageTag
          }).then(function (response) {

            if (!response.data.display_name) {
              notifyManager.error($translate.instant('dashboardWidgetType.restaurantInfo.lacationFoundError'));
              self.noLocationFound = true;
              return;
            }

            var query = 'select * from geo.places(1) where text="' + response.data.display_name + '"';
            var weatherApiUrl = 'https://query.yahooapis.com/v1/public/yql?q=' + encodeURIComponent(query)
              + '&rnd=' + now.getFullYear() + now.getMonth() + now.getDay() + now.getHours()
              + '&format=json&env=store%3A%2F%2Fdatatables.org%2Falltableswithkeys';

            $http.get(weatherApiUrl).then(function (response) {

              var results = response.data.query.results;
              var woeid = results.place.length ? results.place[0].woeid : results.place.woeid;

              $timeout(function () {
                var weather = $(selector);
                weather.empty();
                weather.weatherfeed([woeid], options, function (data) {
                  self.forecastUrl = "app/template/forecast.html";
                  self.forecast = [];
                  for (var i = 1; i < data.item.forecast.length && i <= 4; i++) {
                    self.forecast.push({
                      temp: data.item.forecast[i].high,
                      name: $filter('date')(new Date(data.item.forecast[i].date), 'EEE'),
                      fImg: {
                        'background-image': 'url(http://l.yimg.com/a/i/us/nws/weather/gr/' + data.item.forecast[i].code + 'd.png)',
                        'background-repeat': 'no-repeat',
                        'background-position': 'left 1px top 15px',
                        'background-size': '87px 62px'
                      }
                    });
                  }
                });
              }, 0);
            });
          });
        }

        $scope.$on('$includeContentLoaded', function (event, url) {
          if (url === 'app/template/page/dashboard/widgets/gastronomicDay.html') {
            loadWeatherWidget(self.restaurant, '#weather');
          }
        });

        function drawMap(restaurants, restaurant) {
          // save data for postponed loading
          self.map.selectedAll = false;

          self.map.selectedRestaurant = _.find(restaurants, function (item) {
            return item.id === restaurant.id;
          });

          self.map.markers = mapService.getMapMarkers(restaurants, $scope);

          $scope.$on("leafletDirectiveMarker.click", function (event, args) {
            _.forEach(self.map.markers, function (marker) {
              if (args.model.id === marker.id) {
                marker.focus = args.model.focus;
              }
            });

            self.map.data.name = args.model.name;

            var weatherModel = {
              latitude: args.model.lat,
              longitude: args.model.lng
            };

            $http.get(appConfig.baseUrl + "/icash/dashboard/mapInfo.json", {params: {restaurantId: args.model.id}})
              .then(function (resp) {
                //todo check why have we written so
                // if (!$scope.data) {
                //   $scope.data = {};
                // }
                angular.extend(self.map.data, resp.data.payload);

                self.map.data.startedShow = resp.data.payload.started && !resp.data.payload.closed ? "" : "ng-hide";

                self.map.data.closedShow = resp.data.payload.closed ? "" : "ng-hide";

                self.map.data.modifiedShow = resp.data.payload.modified ? "" : "ng-hide";

                $('#weatherMap').empty();
                $timeout(function () {
                  loadWeatherWidget(weatherModel, '#weatherMap');
                }, 10);

              });
          });

          if (self.map.selectedRestaurant && self.map.selectedRestaurant.latitude !== 0 && self.map.selectedRestaurant.longitude !== 0) {
            leafletData.getMap().then(function (map) {
              $timeout(function () {
                map.invalidateSize();
                self.map.goToRestaurant(self.map.selectedRestaurant);
              }, 400);
            });
          }


        }


        self.setLocation = function () {
          $uibModal.open({
            template: require('../template/page/dashboard/setLocationPopup.html'),
            controller: 'SetLocationCtrl',
            controllerAs: 'setLocationCtrl',
            windowClass: "gf-modal",
            size: 'lg',

            resolve: {
              restaurant: function () {
                return angular.copy(self.restaurant);
              },

              locationContext: function () {
                return $http.get(appConfig.baseUrl + "/icash/dashboard/locationContext.json");
              }
            }
          }).result.then(function (restaurant) {
            notifyManager.success($translate.instant("dashboardWidgetType.restaurantInfo.setLocation.successfully"));
            self.restaurant = angular.copy(restaurant, self.restaurant);
            loadWeatherWidget(self.restaurant, '#weather');

            self.map.restaurants.push(self.restaurant);
            drawMap(self.map.restaurants, self.restaurant);
            self.map.goToRestaurant(restaurant);
          });
        };

        self.dateChanged = function () {
          if (self.from && self.to && +$stateParams.dateType === 8) {
            self.updateDashboard($stateParams.dateType, $stateParams.chartType, self.from, self.to);
            self.fromTimestamp = new Date(self.from).getTime();
            self.toTimestamp = new Date(self.to).getTime();
          }
        };

        self.noTransactionsModal = function () {
          $uibModal.open({
            template: require('../template/page/dashboard/instructionsPopup.html'),
            controller: 'InstructionsPopupCtrl',
            windowClass: 'gf-modal',
            resolve: {
              code: function () {
                return WizardCode.save().$promise;
              }
            }
          });
        };

        self.initDashboard = function (dashboard) {
          angular.extend(self, dashboard);
          // initial parse and convert to Data object
          self.from = self.from || moment(self.from, 'yyyy-MM-dd');
          self.to = self.to || moment(self.to, 'yyyy-MM-dd');
          self.updateDashboard(self.dateType, self.chartType, self.from, self.to);
        };

        self.updateDashboard = function (dateType, chartType, from, to) {

          dateType = dateType || 1;

          var params = self.dashboardOptions.filterParams || {};

          if (from && to && +dateType === 8) {
            params.from = $filter('date')(from, 'yyyy-MM-dd');
            params.to = $filter('date')(to, 'yyyy-MM-dd');
          }

          if (self.dashboard) {
            angular.forEach(self.dashboard.widgets, function (widget) {
              if (!widget.status || widget.status !== 'loaded') {
                widget.status = 'loading';
              }
              var canceler = $q.defer();
              $http.get(buildDashboardUrl(dateType + widget.type.url), {
                params: params,
                timeout: canceler.promise
              }).then(function (response) {
                widget.status = response.data.length === 0 ? 'nodata' : 'loaded';
                widget.data = response.data.payload;
              }, function () {
                widget.status = 'failed';
              });

              var stopListener = $scope.$on('$stateChangeStart', function () {
                canceler.resolve();

                /* Destroy event after call function*/
                stopListener();
              });
            });

            angular.forEach(self.dashboard.charts, function (chart) {
              if (!chart.status || chart.status !== 'loaded') {
                chart.status = 'loading';
              }
              var canceler = $q.defer();
              $http.get(buildDashboardUrl(dateType + chart.type.url), {
                params: params,
                timeout: canceler.promise
              }).then(function (response) {

                if (chart.type.type === "map") {
                  chart.data = response.data.payload;

                  self.map.showAllRestaurants = function () {
                    self.map.selectedAll = true;
                    angular.extend(self.map, {
                      bounds: mapService.showAll(self.map.markers),
                      center: {}
                    });
                  };

                  self.map.goToRestaurant = function (restaurant) {
                    if (!restaurant) {
                      self.map.showAllRestaurants();
                    } else {
                      self.map.selectedAll = false;

                      var point = {
                        "lat": restaurant.latitude,
                        "lng": restaurant.longitude
                      };

                      angular.extend(self.map, {
                        center: mapService.centering(point, self.map.markers),
                        bounds: {}
                      });

                    }
                  };

                  self.map.restaurants = chart.data.restaurants;
                  drawMap(chart.data.restaurants, chart.data.restaurant);

                  return;
                }

                chart.status = response.data.length === 0 ? 'nodata' : 'loaded';
                chart.data = response.data;
              }, function () {
                chart.status = 'failed';
              });

              var stopListener = $scope.$on('$stateChangeStart', function () {
                canceler.resolve();

                /* Destroy event after call function*/
                stopListener();
              });
            });
          }

          if (self.chart) {
            self.chart.status = 'loading';
            $http.get(buildDashboardUrl(dateType + self.chart.type.url), {params: params})
              .then(function (response) {
                self.chart.status = response.data.length === 0 ? 'nodata' : 'loaded';
                self.chart.data = response.data;
              }, function () {
                self.chart.status = 'failed';
              });
          }
        };

        $scope.$watch(function () {
          return self.dashboardOptions.filterParams.filteredGroupId;
        }, function (newValue, oldValue) {
          if (newValue !== oldValue) {
            self.updateDashboard(self.dateType, self.chartType, self.from, self.to);
          }
        }, true);

        self.getPreviousData = function (chart) {
          chart.showPrevious = !chart.showPrevious;
          if (!chart.showPrevious) {
            return
          }

          if (chart.previousInitialized) {
            return;
          }

          if (chart.statusPrevious !== 'loaded' && chart.statusPrevious !== 'nodata') {
            chart.statusPrevious = 'loading';
          }

          var dateType;

          switch (self.dateType) {
            case 3:
              dateType = 4;
              break;
            case 5:
              dateType = 8;
              var data = chart.data[0].data;

              var length = data.length;

              var from = data[0][0];
              var to = data[length - 1][0];

              var daysNumber = (to - from) / 1000 / 60 / 60 / 24;

              var dateFrom = new Date(from);
              var weekDay = dateFrom.getDay();

              var prevFromDate = new Date(dateFrom.getFullYear(), dateFrom.getMonth() - 1, dateFrom.getDate());

              var prevWeekFromDay = prevFromDate.getDay();

              var daysShift = weekDay - prevWeekFromDay;

              daysShift = daysShift <= 0 ? daysShift : daysShift - 7;

              prevFromDate.setDate(prevFromDate.getDate() + daysShift);

              var prevFromTo = new Date(prevFromDate.getFullYear(), prevFromDate.getMonth(), prevFromDate.getDate() + daysNumber);

              var params = self.dashboardOptions.filterParams || {};
              params.from = $filter('date')(prevFromDate, 'yyyy-MM-dd');
              params.to = $filter('date')(prevFromTo, 'yyyy-MM-dd');
              break;
            default :
              return;
          }

          $http.get(buildDashboardUrl(dateType + chart.type.url), {params: params})
            .then(function (response) {
              chart.statusPrevious = response.data.length === 0 ? 'nodata' : 'loaded';
              chart.previousInitialized = true;
              chart.previousData = response.data;
            }, function () {
              chart.statusPrevious = 'failed';
            });
        };

        var newTransactionsTimestamp = moment().valueOf();

        function checkNewTransactions() {
          $http
            .get(buildDashboardUrl('newTransactions.json'), {
              params: {timestamp: newTransactionsTimestamp}
            })
            .then(function (result) {
              if (result.data.newTransactions) {
                self.updateDashboard($stateParams.dateType, $stateParams.chartType, self.from, self.to);
              }
              newTransactionsTimestamp = result.data.timestamp;
            });
        }

        self.isMobile = function () {
          return /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
        };

        self.deleteOpenTable = function (tableNumber, tableParty, chartData) {

          $analytics.eventTrack('Delete Open Table', {label: securityService.context.currentRestaurant.name});

          $http({
            method: 'DELETE',
            url: appConfig.baseUrl + '/icash/dashboard/openTables',
            params: {
              'number': tableNumber,
              'party': tableParty
            }
          }).then(function (resp) {
            if (resp.data.ok) {
              self.openTables = resp.data.payload.openTables;
              chartData.tables = resp.data.payload.openTables.tables;
              notifyManager.success($translate.instant('record.deleted'));
            } else {
              notifyManager.error($translate.instant(resp.data.message));
            }
          });
        };

        /*Add openTable chart only if there are any open tables*/
        self.filterChart = function (chart) {
          // check if any coordinates set
          var isMapShow = chart && chart.data
            ? !!_.find(chart.data.restaurants, function (item) {
              return item.latitude !== 0 && item.longitude !== 0;
            })
            : false;

          return (chart.type.template !== 'openTables' || chart.data.tables && chart.data.tables.length > 0) && (chart.type.type !== "map" || isMapShow);
        };

        self.$onInit = function () {
          _.defaults(self.dashboardOptions, DASHBOARD_DEFAULT_OPTIONS);

          if (self.dashboardState.ok) {

            self.initDashboard(self.dashboardState.payload);

            if (+self.dashboardState.payload.dateType === 1 && self.dashboardState.payload.rooms && self.dashboardState.payload.rooms.length > 0) {
              self.selectedRoom = self.dashboardState.payload.rooms[0].id;

              self.updateRoom = function (roomId) {
                self.selectedRoom = roomId;
              };
            }
          }

          if (+self.dateType === 1) {
            var checkNewTrInterval = $interval(checkNewTransactions, appConfig.dashboard.refreshInterval);

            $scope.$on('$stateChangeStart', function () {
              $interval.cancel(checkNewTrInterval);
            });
          }
        };

      }
    })

    .controller('DashboardCtrl',
      function (dashboard, securityService) {
        var self = this;

        self.dashboard = dashboard;

        self.options = {
          isBranchMode: securityService.context.selectedRestaurantIds.length > 1,
          getChartDetailsState: function (dateType, chartIndex) {
            return 'page.dashboardDateTypeChartType({dateType: ' + dateType + ', chartType: ' + chartIndex + '})';
          },
          getDashboardState: function (dateType) {
            return 'page.dashboardDateType({dateType: ' + dateType + '})';
          }
        }
      }
    )

    .controller(
      "DashboardCapitalCtrl",
      function ($scope, $location, securityService, appConfig, $http) {

        $scope.offer = undefined;

        this.$onInit = function () {
          const id = securityService.context.currentRestaurant?.id;

          if (MERP_USER_ROLES.some(role => securityService.context.roles.includes(role))) {
            $http.get(appConfig.baseUrl + '/icash/lightspeed-financial/capital-offer/' + id)
              .then(function (res) {
                const offer = res.data?.offer;

                if (!offer) {
                  $scope.offer = { offer: null }
                  return
                }

                const formattedOfferAmount = new Intl.NumberFormat(offer.country, {
                  style: 'currency',
                  currency: offer.currency,
                  minimumFractionDigits: 0
                }).format(offer.maxAmount / 100);

                $scope.offer = { offer: formattedOfferAmount };
              });
          } else {
            $scope.offer = { offer: null }
          }
        }
        $scope.openCapitalOnboarding = function () {
          $location
            .path("/financialServices")
            .search({ view: 'capital' });
        };
        $scope.isCapitalBannerHidden = function () {
          const now = new Date().getTime();
          const lsKey = makeCapitalBannerKey(securityService.context.currentUser?.id, securityService.context.currentRestaurant?.id);
          const showBannerAt = localStorage.getItem(lsKey);

          // Need to also hide banner for white label customers
          const countryCode = securityService.context.contractType?.country?.code;
          const showBannerForCountry = countryCode && MERP_COUNTRIES.indexOf(countryCode) !== -1 || false;

          const isGroup = isBusinessGroup(securityService);

          const whitelabel = isWhitelabel(securityService);
          const lsFinancialEnabled = securityService.context.lsFinancialEnabled !== false;

          const showBannerForUserRole =
            securityService.context.roles && securityService.context.roles.length > 0 &&
            MERP_USER_ROLES.some(role => securityService.context.roles.includes(role));

          if (!lsFinancialEnabled || !showBannerForUserRole || !showBannerForCountry || isGroup || whitelabel) {
            return true;
          }

          if (!showBannerAt) {
            return false;
          }

          if (now > Number(showBannerAt)) {
            localStorage.removeItem(lsKey);
            return false;
          }

          return true;
        };
        $scope.hideCapitalBanner = function () {
          const lsKey = makeCapitalBannerKey(securityService.context.currentUser?.id, securityService.context.currentRestaurant?.id);
          const userId = securityService.context.currentUser?.id;
          // 1 second * 60 seconds in a minute * 60 minutes in a hour * 24 hours in a day * 30 days
          const then_delta = 1000 * 60 * 60 * 24 * 30;
          const now = new Date().getTime();

          const then = now + then_delta;

          localStorage.setItem(lsKey, then);
        };
      }
    )

    .filter('widgetFilter', function (securityService) {
      return function (widgets) {
        var result = [];
        angular.forEach(widgets, function (widget) {
          var feature = widget.type ? widget.type.feature : widget.feature;
          if (feature && !securityService.hasAnyFeature(feature)) {
            return;
          }
          if (feature && feature === 'PERFORMANCE_TRACKING' && securityService.context.currentGroup) {
            return;
          }
          result.push(widget);
        });
        return result;
      };
    })
    .factory('mapService', function (leafletBoundsHelpers) {

      function hideAllMarkersPopups(markers) {
        _.forEach(markers, function (marker) {
          marker.focus = false;
        });
      }

      function centering(point, markers) {
        hideAllMarkersPopups(markers);
        var center = {
          lat: point.lat,
          lng: point.lng,
          zoom: 16
        };
        return center;

      }

      function showAll(markers) {

        hideAllMarkersPopups(markers);

        var xPoints = _.map(markers, function (point) {
          return point.lat;
        });

        var yPoints = _.map(markers, function (point) {
          return point.lng;
        });

        var coordinates = [[_.max(xPoints), _.max(yPoints)], [_.min(xPoints), _.min(yPoints)]];

        var bounds = leafletBoundsHelpers.createBoundsFromArray(coordinates);

        return bounds;
      }


      function getMapMarkers(restaurants, $scope) {

        return _.map(restaurants, function (restaurant) {
          return {
            "lat": restaurant.latitude,
            "lng": restaurant.longitude,
            "id": restaurant.id,
            "name": restaurant.name,
            "getMessageScope": function () {
              return $scope;
            },
            "icon": {
              "type": 'awesomeMarker',
              "icon": 'circle',
              "prefix": 'fa',
              "markerColor": 'orange'
            },
            "message": "<div ng-include src=\"'app/template/page/dashboard/charts/map_pop_up.html'\"></div>",
            "label": {
              "message": restaurant.name,
              "options": {
                "noHide": true
              }
            }
          }
        });
      }

      return {
        getMapMarkers: getMapMarkers,
        centering: centering,
        showAll: showAll
      }
    })

    .controller('AppVersionsCtrl',

      function ($scope, appVersions) {
        $scope.manifests = appVersions;
      }
    )

    .controller("SetLocationCtrl",
      function ($scope, $uibModalInstance, $http, securityService, leafletData, osmNominatim, restaurant, locationContext, $timeout, appConfig) {
        var self = this;

        angular.extend(self, locationContext.data.payload);
        self.restaurant = restaurant;

        self.map = {};
        angular.extend(self.map, {
          center: {
            lat: self.restaurant.latitude,
            lng: self.restaurant.longitude,
            zoom: 16
          },
          bounds: {},
          markers: {
            marker: {
              lat: self.restaurant.latitude,
              lng: self.restaurant.longitude,
              id: self.restaurant.id,
              name: self.restaurant.name,
              draggable: true,
              icon: {
                type: 'awesomeMarker',
                icon: 'circle',
                prefix: 'fa',
                markerColor: 'orange'
              },
              label: {
                message: self.restaurant.name,
                options: {
                  noHide: true
                }
              },
              events: {
                markers: {
                  enable: ['dragend']
                }
              }
            }
          }
        });

        $scope.$on('leafletDirectiveMarker.dragend', function (event, args) {
          self.restaurant.latitude = args.model.lat;
          self.restaurant.longitude = args.model.lng;
        });

        self.getAddressTypeahead = function (input) {
          return osmNominatim.search({
            q: input,
            "accept-language": securityService.context.currentLanguageTag
          }).then(function (data) {
            return data.data;
          })
        };

        self.onAddressSelect = function ($item, $model, $label) {
          self.restaurant.latitude = parseFloat($item.lat);
          self.restaurant.longitude = parseFloat($item.lon);

          angular.merge(self.map, {
            center: {
              lat: self.restaurant.latitude,
              lng: self.restaurant.longitude,
              zoom: 16
            },
            markers: {
              marker: {
                lat: self.restaurant.latitude,
                lng: self.restaurant.longitude
              }
            }
          });

          osmNominatim.reverse({
            osm_type: $item.osm_type[0].toUpperCase(),
            osm_id: $item.osm_id,
            zoom: 16,
            addressdetails: 1,
            "accept-language": securityService.context.currentLanguageTag
          }).then(function (data) {

            var address = data.data.address;

            if (!self.restaurant.restaurantAddress) {
              self.restaurant.restaurantAddress = {};
            }

            // assign data to restaurant
            if (address.country_code) {
              self.restaurant.restaurantAddress.country = _.find(self.countries, function (country) {
                return address.country_code.toUpperCase() == country.code.toUpperCase();
              });
            }


            if (self.restaurant.restaurantAddress.country) {
              self.restaurant.restaurantAddress.region = _.find(self.regionsByCountry[self.restaurant.restaurantAddress.country.id], function (region) {
                return address.state.toLowerCase() == region.name.toLowerCase();
              });
            }

            self.restaurant.restaurantAddress.street = (address.road ? address.road : "") + " " + (address.house_number ? address.house_number : "");

            self.restaurant.restaurantAddress.city = (address.city ? address.city : "") || (address.county ? address.county : "") || (address.state ? address.state : "");

            self.restaurant.restaurantAddress.zip = address.postcode ? address.postcode : "";


          });
        };

        leafletData.getMap().then(function (map) {
          $timeout(function () {
            map.invalidateSize();
          }, 400);
        });

        self.yes = function () {

          $http.post(appConfig.baseUrl + "/icash/dashboard/address", self.restaurant)
            .then(function (data) {
              $uibModalInstance.close(self.restaurant);
            }, function (data) {
              $uibModalInstance.dismiss(data);
            });
        };

        self.no = function () {
          self.restaurant.latitude = 0;
          self.restaurant.longitude = 0;

          $uibModalInstance.dismiss();
        };

        self.isSaveDisable = function () {
          return !self.restaurant.latitude || !self.restaurant.longitude;
        };
      }
    )

    .directive('performanceTracking',
      function () {
        return {
          restrict: 'E',
          require: "ngModel",
          scope: {
            widget: '=ngModel'
          },
          template: require('../template/page/dashboard/widgets/performance/performance_template.html'),
          controller: function ($scope, $interval, $http, appConfig, $filter) {

            //refresh performance tracker every 10 minutes
            var interval = 10 * 60 * 1000;

            function refresh() {
              $http.get(appConfig.baseUrl + "/icash/dashboard/1" + $scope.widget.type.url)
                .then(function (response) {
                  $scope.widget.status = response.data.length === 0 ? 'nodata' : 'loaded';
                  $scope.widget.data = response.data.payload;
                }, function () {
                  $scope.widget.status = 'failed';
                });
            }

            var refreshInterval = $interval(refresh, interval);
            $scope.$on('$stateChangeStart', function () {
              $interval.cancel(refreshInterval);
            });
            //end refresh


            $scope.$watch('widget.data.status', function (newValue) {
              if (newValue && $scope.performanceTrackingConfig
                && typeof $scope.widget.data.status.widgetPercentValue === 'number') {
                // $scope.performanceTrackingConfig.series[0].data = [$scope.widget.data.status.widgetPercentValue];
              }
            });

            $scope.performanceTrackingConfig = {

              chart: {
                type: 'gauge',
                plotBackgroundColor: null,
                plotBackgroundImage: null,
                plotBorderWidth: 0,
                plotShadow: false,
                spacingTop: 0,
                spacingLeft: 0,
                spacingRight: 0,
                spacingBottom: 0,
                width: 180,
                height: 200
              },
              pane: {
                startAngle: -150,
                endAngle: 150,
                background: [{
                  backgroundColor: {
                    linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
                    stops: [
                      [0, '#FFF'],
                      [1, '#333']
                    ]
                  },
                  borderWidth: 0,
                  outerRadius: '109%'
                }, {
                  backgroundColor: {
                    linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1},
                    stops: [
                      [0, '#333'],
                      [1, '#FFF']
                    ]
                  },
                  borderWidth: 1,
                  outerRadius: '107%'
                }, {
                  // default background
                }, {
                  backgroundColor: '#DDD',
                  borderWidth: 0,
                  outerRadius: '105%',
                  innerRadius: '103%'
                }]
              },

              credits: {
                enabled: false
              },
              exporting: {
                enabled: false
              },
              tooltip: {
                enabled: false
              },

              // the value axis
              yAxis: {
                min: 0,
                max: 100,

                minorTickInterval: 'auto',
                minorTickWidth: 1,
                minorTickLength: 10,
                minorTickPosition: 'inside',
                minorTickColor: '#666',

                tickPixelInterval: 30,
                tickWidth: 2,
                tickPosition: 'inside',
                tickLength: 10,
                tickColor: '#666',
                labels: {
                  enabled: false
                },

                plotBands: [{
                  from: 0,
                  to: 40,
                  color: '#DF5353' // red
                }, {
                  from: 40,
                  to: 60,
                  color: '#DDDF0D' // yellow
                }, {
                  from: 60,
                  to: 100,
                  color: '#55BF3B' // green
                }]
              },

              title: {
                text: false,
                align: 'left'
              },

              series: [{
                name: 'Performance tracking',
                data: [50],

                dataLabels: {
                  formatter: function () {
                    var result = 0;
                    if ($scope.widget.data.status && _.isNumber($scope.widget.data.status.widgetStatusValue)) {
                      if ($scope.widget.data.status.currencySymbol) {
                        result = $filter('currency')($scope.widget.data.status.widgetStatusValue, $scope.widget.data.status.currencySymbol);
                      } else {
                        result = $filter('currency')($scope.widget.data.status.widgetStatusValue);
                      }
                    }
                    return result;
                  }
                }
              }]
            };

          }
        }
      }
    )

    .filter('newsFilter', function (securityService, $templateCache) {
      return function (news) {

        var currentOperator = securityService.context.currentOperator;
        var contractType = securityService.context.contractType;
        var country = securityService.context.contractType.country;

        function hasRestrictions(showItems, notShowItems) {
          var hasRestrictions = false;
          _.forOwn(showItems, function (value, key) {
            if (value.length) {
              hasRestrictions = true;
            }
          });
          _.forOwn(notShowItems, function (value, key) {
            if (value.length) {
              hasRestrictions = true;
            }
          });
          return hasRestrictions;
        }

        var availableNews = [];
        if (!news.length) {
          return [];
        }
        _.forEach(news, function (item) {
          if (!hasRestrictions(item.showTo, item.notShowTo)) {
            availableNews.push(item);
          } else {
            if (item.showTo.country && item.showTo.country.length) {
              if ((country && country.id) && _.includes(_.map(item.showTo.country, 'id'), country.id) && !_.includes(availableNews, item)) {
                availableNews.push(item);
              }
            }
            if (item.showTo.contractType && item.showTo.contractType.length) {
              if (_.includes(_.map(item.showTo.contractType, 'id'), contractType.id) && !_.includes(availableNews, item)) {
                availableNews.push(item);
              }
            }
            if (item.showTo.operator && item.showTo.operator.length) {
              if (_.includes(_.map(item.showTo.operator, 'id'), currentOperator.id) && !_.includes(availableNews, item)) {
                availableNews.push(item);
              }
            }
            if (item.notShowTo.country && item.notShowTo.country.length) {
              if ((country && country.id) && _.includes(_.map(item.notShowTo.country, 'id'), country.id)) {
                _.remove(availableNews, function (el) {
                  return el.id === item.id;
                })
              }
            }
            if (item.notShowTo.contractType && item.notShowTo.contractType.length) {
              if (_.includes(_.map(item.notShowTo.contractType, 'id'), contractType.id)) {
                _.remove(availableNews, function (el) {
                  return el.id === item.id;
                })
              }
            }
            if (item.notShowTo.operator && item.notShowTo.operator.length) {
              if (_.includes(_.map(item.notShowTo.operator, 'id'), currentOperator.id)) {
                _.remove(availableNews, function (el) {
                  return el.id === item.id;
                })
              }
            }
          }
        });
        var visibleForUser = [];
        var hiddenNews = securityService.context.currentUser.additionalSettings.hiddenNews;
        if (hiddenNews && hiddenNews.length && availableNews.length) {
          _.forEach(availableNews, function (item) {
            if (!_.includes(hiddenNews, item.id)) {
              visibleForUser.push(item);
            }
          })
        } else {
          visibleForUser = availableNews;
        }
        //check if template present
        if (visibleForUser) {
          visibleForUser = _.filter(visibleForUser, function (news) {
            return (news.template && $templateCache.get('app/template/' + news.template) || (!news.template && news.content))
          });
        }
        return _.orderBy(visibleForUser, ['priority']);
      }
    })

})();

angular.module('dashboardFilters', [])
  .filter('dashboardWidgetFilter', function () {
    // size need for run filter during changes
    return function (selectedItems, items, size) {

      _.map(selectedItems, function (type) {
        type.selected = false;
        var findType = _.find(items, function (item) {
          return item.type.id == type.id;
        });
        if (findType) {
          type.selected = true;
        }
      });

      return selectedItems;
    };
  });
