(function () {
  'use strict';
  angular.module('gf.masterdata.article', [])

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.masterdataArticle', {
          url: '/masterdata/article',
          template: require('../template/page/masterdata/articles.html'),
          controller: 'MasterdataArticleGridCtrl',
          controllerAs: 'masterdataArticleGridCtrl',
          resolve: {
            articleContext: function (ArticleContext) {
              return ArticleContext.get().$promise;
            },
            articleMetadata: function (ArticleMetadata) {
              return ArticleMetadata.get().$promise;
            },
            default_icons: function (icons) {
              return icons['default'].get().$promise;
            },
            currentTour: function (Tour) {
              return Tour.get({page: 'masterdataArticle'}).$promise;
            }
          },
          title: "article"
        })

        .state('page.masterdataArticleAdd', {
          url: '/masterdata/article/add',
          template: require('../template/page/masterdata/article.html'),
          controller: 'MasterdataArticleAddCtrl',
          controllerAs: 'masterdataArticleEditCtrl',
          resolve: {
            articleContext: function (ArticleContext) {
              return ArticleContext.get().$promise;
            },
            articleMetadata: function (ArticleMetadata) {
              return ArticleMetadata.get().$promise;
            },
            article: function (Article) {
              return Article.load({articleId: 'add'}).$promise;
            },
            articleStock: function (ArticleStock, securityService) {
              if (securityService.hasAnyFeature('INVENTORY_MANAGEMENT')) {
                var articleStock = new ArticleStock();
                articleStock.amount = 0;
                return articleStock;
              } else {
                return null;
              }
            },
            currentTour: function (Tour) {
              return Tour.get({page: 'masterdataArticleAdd'}).$promise;
            }
          },
          title: "article"
        })

        .state('page.masterdataArticleDetails', {
          url: '/masterdata/article/{id:int}',
          template: require('../template/page/masterdata/article.html'),
          controller: 'MasterdataArticleEditCtrl',
          controllerAs: 'masterdataArticleEditCtrl',
          resolve: {
            articleContext: function (ArticleContext) {
              return ArticleContext.get().$promise;
            },
            articleMetadata: function (ArticleMetadata) {
              return ArticleMetadata.get().$promise;
            },
            article: function (Article, $stateParams) {
              return Article.load({articleId: $stateParams.id}).$promise;
            },
            articleStock: function (ArticleStock, securityService, $stateParams) {
              if (securityService.hasAnyFeature('INVENTORY_MANAGEMENT')) {
                return ArticleStock.get({articleId: $stateParams.id}).$promise;
              } else {
                return null;
              }
            },
            isUsedAsAggregatedItem: function ($http, $stateParams, appConfig) {
              if (!$stateParams.id) return false;
              return $http.get(appConfig.baseUrl + '/icash/masterdata/article/' + $stateParams.id + '/is_aggregated_item');
            }
          },
          title: "article"
        })
    })
    .factory('ValidationDuplicatePLUMessageService', function ($translate) {
      return {
        // article - duplicated article
        getMessage: function (article) {
          if (!article) {
            return '';
          }
          var activeMsg = article.active ? $translate.instant('article.change.duplicated.plu.active') : $translate.instant('article.change.duplicated.plu.inactive');
          var url = '/client/#/masterdata/article/' + article.id;

          return $translate.instant('article.change.duplicated.plu.validation', {
            plu: article.id,
            active: activeMsg,
            articleName: article.name,
            url: url
          });

        }
      }
    })
    .factory('ArticleStock', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/inventory/:articleId.json', {}, {
        get: {method: 'GET'},
        put: {method: 'PUT'}
      });
    })

    .controller('MasterdataArticleGridCtrl',

      function ($scope, $uibModal, $http, $timeout, $translate, notifyManager, articleMetadata, articleContext,
                PluService, $resource, ArticleDeactivateGroup, ArticleUpdateGroup, ArticleValidConstraintParents,
                securityService, default_icons, dialogs, currentTour, tourService, configOptionsService) {
        var self = this;

        self.currentTour = currentTour;
        tourService.startDetachedTour('masterdataArticle', currentTour);

        // set min limit for id
        var minMessage = _.find(articleMetadata.columns['id'].errorMessages, function (item) {
          return item.name === 'Min';
        });

        if (minMessage) {
          minMessage.args.value = '1';
        }
        articleMetadata.columns.id.annotations['javax.validation.constraints.Min'] =
          {payload: [], groups: [], value: 1, message: '{javax.validation.constraints.Min.message}'};
        articleMetadata.columns.id.angular.min = 1;

        self.metadata = articleMetadata;
        self.context = articleContext;

        // actions
        var actions = [
          {
            name: 'serverCopy',
            template: 'app/template/grid/copy_article_action.html',
            controller: 'CopyArticleActionCtrl'
          }
        ];

        // group actions
        var groupActions = [
          {
            title: 'deactivateArticles',
            action: function (entities) {
              $uibModal.open({
                template: require('../template/deactivate_group_confirmation.html'),
                scope: $scope,
                windowClass: 'gf-modal'
              }).result.then(function () {

                var ids = entities.map(function (entity) {
                  return entity.id;
                });

                var ArticleDeactivateGroupResource = $resource(ArticleDeactivateGroup, {}, {
                  deactivateGroup: {'method': 'POST'}
                });

                ArticleDeactivateGroupResource.deactivateGroup(ids, function (data) {
                  if (data.ok) {
                    self.config.reloadData();
                    notifyManager.info($translate.instant('messages.article.deactivated.success'));
                  } else {
                    notifyManager.error($translate.instant(data.message));
                  }
                }, function () {
                  notifyManager.error($translate.instant('error.general'));
                });
              });
            }
          },
          {
            title: 'moveToArticleGroup',
            action: function (entities) {
              $uibModal.open({
                template: require('../template/super_group_selection_dialog.html'),
                windowClass: 'gf-modal',
                controller: 'ChangeArticleGroupCtrl',
                controllerAs: 'changeArticleGroupCtrl',
                resolve: {
                  articleContext: articleContext
                }
              }).result.then(function (articleGroup) {

                // don't update before successful save
                var copiedEntities = angular.copy(entities);

                copiedEntities.forEach(function (entity) {
                  return entity.articleGroup = articleGroup;
                });

                var ArticleUpdateGroupResource = $resource(ArticleUpdateGroup, {}, {
                  assignGroup: {'method': 'POST'}
                });

                ArticleUpdateGroupResource.assignGroup(copiedEntities, function (data) {
                  if (data.ok) {
                    self.config.reloadData();
                    notifyManager.info($translate.instant('messages.article.moved.to.article.group.success'));
                  } else {
                    notifyManager.error($translate.instant(data.message));
                  }
                });
              });

            }
          },
          {
            title: 'addConstraints',
            action: function (entities) {
              $uibModal.open({
                template: require('../template/add_constraints_dialog.html'),
                windowClass: 'gf-modal',
                controller: 'AddConstraintsGroupCtrl',
                controllerAs: 'addConstraintsGroupCtrl',
                resolve: {
                  entityIds: function () {
                    return entities.map(function (item) {
                      return item.id
                    });
                  }
                }
              }).result.then(function (constraints) {

                var pleaseWaitDialog = $uibModal.open({
                  template: require('../../../main/angular/app/template/page/please_wait.html'),
                  windowClass: 'gf-modal',
                  backdrop: 'static',
                  keyboard: false
                });

                entities.forEach(function (entity) {
                  entity.constraints = constraints;
                  entity.mode = 1; //todo aggregated items support (make mode choosing using the same component as in article edit form)
                });

                $http
                  .post(ArticleUpdateGroup, entities)
                  .then(function (resp) {
                    if (resp.data.ok) {
                      notifyManager.info($translate.instant('messages.add.constraints.success'));
                    } else {
                      notifyManager.error($translate.instant(resp.data.message));
                    }
                  }, function () {
                    notifyManager.error($translate.instant('error.general'));
                  })
                  .finally(function () {
                    pleaseWaitDialog.close();
                  });
              })
            }
          },
          {
            title: 'copyConstraints',
            action: function (entities) {

              $http
                .post(ArticleValidConstraintParents,
                  entities.map(function (item) {
                    return item.id
                  }))
                .then(function (resp) {
                  if (!resp.data) {
                    return notifyManager.error($translate.instant('error.general'));
                  }
                  var validArticleIds = resp.data;
                  if (validArticleIds.length > 0) {

                    $uibModal.open({
                      template: require('../template/copy_constraints_dialog.html'),
                      windowClass: 'gf-modal',
                      controller: 'CopyConstraintsGroupCtrl',
                      controllerAs: 'copyConstraintsGroupCtrl',
                      resolve: {
                        entityIds: function () {
                          return validArticleIds;
                        }
                      }
                    }).result.then(function (constraints) {

                      var pleaseWaitDialog = $uibModal.open({
                        template: require('../../../main/angular/app/template/page/please_wait.html'),
                        windowClass: 'gf-modal',
                        backdrop: 'static',
                        keyboard: false
                      });


                      var filteredEntities = _.filter(entities, function (entity) {
                        return validArticleIds.indexOf(entity.id) > -1;
                      });

                      //todo create function
                      filteredEntities.forEach(function (entity) {
                        entity.constraints = constraints;
                        entity.mode = 1; //todo aggregated items support (make mode choosing using the same component as in article edit form)
                      });

                      $http
                        .post(ArticleUpdateGroup, filteredEntities)
                        .then(function (resp) {
                          if (resp.data.ok) {
                            notifyManager.info($translate.instant('messages.copy.constraints.success', {articleIds: validArticleIds.join(", ")}));
                          } else {
                            notifyManager.error($translate.instant(resp.data.message));
                          }
                        }, function () {
                          notifyManager.error($translate.instant('error.general'));
                        })
                        .finally(function () {
                          pleaseWaitDialog.close();
                        });
                    })
                  } else {
                    notifyManager.error($translate.instant('constraints.cant.copy.to.articles'));
                  }
                });
            }
          }
        ];

        var cellClass = function (grid, row, col, rowRenderIndex, colRenderIndex) {
          if (row && row.entity && row.entity.id && row.entity.hidden) {
            return 'hidden-entity';
          } else if (row && row.entity && row.entity.id && !row.entity.active) {
            return 'deactivated-entity';
          }
        };

        // columns
        var columns = [
          {
            name: "id",
            width: 70,
            cellClass: cellClass,
            preFillValue: function (colDef, entity) {
              var url = "/icash" + self.context.refreshEndpoint + "/plu";
              if (colDef.name !== 'id') {
                return PluService.acquire(url);
              } else {
                PluService.lock(url, entity.id);
                return entity.id;
              }
            },
            cellEditableCondition: function ($scope) {
              return !$scope.row.entity.id || $scope.row.entity.notValidColumns && _.find($scope.row.entity.notValidColumns, function (item) {
                return "id" === item;
              });
            },
            validations: {
              'gf-uniquearticlesku': {
                'angular': {'gf-uniquearticlesku': ''},
                'errorMessage': "de.icash.validator.UniquePLU.Article.message",
                'errorMessageArg': "plu"
              }
            },
            headerCellTemplate: 'app/template/tour/header_cell_tour.html'
          },
          {
            name: "name", cellClass: cellClass,
            preFillValue: function (colDef, entity) {
              if (colDef.name === "name") {
                return entity.name;
              }
              return colDef.name === 'shortName' ? entity.shortName : entity.name;
            },
            headerCellTemplate: 'app/template/tour/header_cell_tour.html'
          },
          {
            name: "shortName", cellClass: cellClass,
            preFillValue: function (colDef, entity) {
              if (colDef.name === "shortName") {
                return entity.name;
              }
              return colDef.name === 'name' ? entity.name : entity.shortName;
            }
          },
          {name: "articleGroup",
            filterPathFieldName: 'articleGroup.shortName',
            width: 150,
            cellClass: cellClass,
            validations: {
              'gf-measurementunit': {
                'angular': {'gf-measurementunit': ''},
                'errorMessage': "de.icash.validator.noMeasurementUnit"
              }
            }
          },
          {
            name: "articleType",
            filterPathFieldName: 'articleType.name',
            width: 150,
            cellClass: cellClass,
            headerCellTemplate: 'app/template/tour/header_cell_tour.html',
            validations: {
              'gf-measurementunit': {
                'angular': {'gf-measurementunit': ''},
                'errorMessage': "de.icash.validator.noMeasurementUnitForMeasureType"
              }
            }
          }
        ];

        if (securityService.hasAnyFeature('NETTO_PRICE')) {
          columns.push({name: "priceNetto", width: 178, cellClass: cellClass});
        } else {
          columns.push({name: "price", width: 178, cellClass: cellClass});
        }

        // add price level columns
        self.context.data.dependencies.allPriceLevels.forEach(function (priceLevel, index) {
          if (priceLevel.active) {
            columns.push({
              name: priceLevel.name + index,
              displayName: priceLevel.name,
              field: "article2PriceLevels[" + index + "].price",
              width: 100,
              visible: false,
              cellClass: cellClass,
              enableSorting: false,
              enableFiltering: false,
              type: 'number',
              cellFilter: "number:2",
              editableCellTemplate: "<div><form name=\"inputForm\"><input ignore-mouse-wheel number-input type=\"INPUT_TYPE\" step=\"0.01\" ng-class=\"'colt' + col.uid\" ui-grid-editor ng-model=\"MODEL_COL_FIELD\"></form></div>"
            });
          }
        });

        columns.push({name: "description", visible: false, cellClass: cellClass});

        default_icons.forEach(function (icon) {
          icon.name = $translate.instant("icon." + icon.name);
        });
        default_icons = _.sortBy(default_icons, function (icon) {
          return icon.name;
        });
        columns.push({
          name: "image",
          visible: false,
          editDropdownIdLabel: 'path',
          editDropdownValueLabel: 'name',
          cellFilter: "mapImageArticleFilter:grid.appScope.context.data.dependencies.icons",
          editableCellTemplate: "<div><form name=\"inputForm\"><select class=\"form-control\" ng-class=\"'colt' + col.uid\" ui-grid-edit-dropdown ng-model=\"MODEL_COL_FIELD\" ng-options=\"field[editDropdownIdLabel] as field[editDropdownValueLabel]  CUSTOM_FILTERS for field in editDropdownOptionsArray\"><option value=\"\" translate=\"option.select\"></option></select></form></div>",
          editDropdownOptionsArray: default_icons,
          enableFiltering: false,
          enableSorting: false,
          cellClass: cellClass
        });

        // create hash map for mapImageArticleFilter
        self.context.data.dependencies.icons = {};
        default_icons.forEach(function (icon) {
          self.context.data.dependencies.icons[icon.path] = icon.name;
        });

        columns.push({name: "colorCode", visible: false, cellClass: cellClass});

        columns.push({
          name: "plus",
          visible: true,
          cellFilter: "plusArticleFilter:row.entity",
          enableFiltering: false,
          enableSorting: false,
          cellClass: cellClass,
          cellEditableCondition: function ($scope) {
            return $scope.row.entity.id == null || $scope.row.entity.notValidColumns;
          },
          editableCellTemplate: '<div><form name="inputForm"><input format-plus type="INPUT_TYPE" ng-class="\'colt\' + col.uid" ui-grid-editor ng-model="MODEL_COL_FIELD"></form></div>',
          validations: {
            'gf-uniquearticleplu': {
              'angular': {'gf-uniquearticleplu': ''},
              'errorMessage': "validate.notUniquePLUs"
            }
          }
        });

        columns.push({
          name: "measures",
          visible: false,
          cellFilter: 'measureArticleFilter:row.entity:grid.appScope.context.data.dependencies.articleGroupMeasure',
          cellEditableCondition: function ($scope) {
            return $scope.row.entity.articleType && $scope.row.entity.articleType.measure;
          },
          cellClass: cellClass,
          cellTemplate: "<div uib-tooltip=\"{{(row.entity.id!=null||row.entity.filled)&&!row.entity.articleType.measure ? ('article.measure.required' | translate) : ''}}\" tooltip-append-to-body=\"false\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD CUSTOM_FILTERS}}</div>"
        });

        columns.push({
          name: 'operate',
          actions: ['activate', 'edit', 'serverCopy'],
          headerCellTemplate: 'app/template/tour/header_cell_tour.html',
          actionOptions: {
            'activate': {
              isDisabled: configOptionsService.get('articleActivateButton').isDisabled
            }
          }
        });

        self.config = {
          urlRoute: '/masterdata/article',
          registerActions: actions,
          registerGroupActions: groupActions,
          enableSorting: true,
          enableAdd: true,
          enableFiltering: true,
          enablePagination: true,
          enableLastModifiedFilter: true,
          enableShowOnlyActiveFilter: true,
          enableAddRow: true,
          enableRowSelection: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          isRowSelectable: function (row) {
            return row.entity.id != null && !row.entity.hidden;
          },
          cellEditableCondition: function ($scope) {
            return !$scope.row.entity.hidden;
          },
          hideActions: function (entity) {
            return entity.hidden;
          },
          columns: columns,
          prepareData: function (data, context) {
            // synchronize priceLevels with grid structure
            data.forEach(function (article) {
              var fullPriceLevelList = [];
              context.data.dependencies.allPriceLevels.forEach(function (priceLevel, index) {
                var found = _.find(article.article2PriceLevels, function (article2PriceLevel) {
                  return article2PriceLevel.priceLevel && (article2PriceLevel.priceLevel.id == priceLevel.id);
                });

                if (found) {
                  fullPriceLevelList.splice(index, 0, found)
                } else {
                  var article2PriceLevel = priceLevel.active ? {priceLevel: priceLevel, price: undefined} : {};
                  fullPriceLevelList.splice(index, 0, article2PriceLevel);
                }

              });
              article.article2PriceLevels = fullPriceLevelList;
            });
          }
        };

        self.copyToSlaves = function () {
          if (securityService.context.currentUser.email && securityService.context.currentUser.mailConfirmed) {
            dialogs.confirm($translate.instant('pleaseConfirm'), $translate.instant('article.branchControl.confirm'))
              .result.then(function () {
              $http.post("/icash/masterdata/article/branch_control")
                .then(function (resp) {
                  if (resp.data.status === 'OK') {
                    $uibModal.open({
                      template: require('../template/send_mail_confirmation.html'),
                      windowClass: "gf-modal",
                      controller: 'ArticleBranchControlCtrl',
                      controllerAs: 'articleBranchControlCtrl'
                    });
                  } else {
                    notifyManager.error(resp.data.message);
                  }
                });
            });
          } else {
            $uibModal.open({
              template: require('../../gf-dashboard/template/page/welcome/provideEmailPopup.html'),
              controller: 'ProvideEmailCtrl',
              controllerAs: '$ctrl',
              windowClass: 'gf-modal'
            });
          }
        };

        self.forceUpdateMasterData = function () {
          $http.post("/icash/masterdata/article/force_update_master_data")
            .then(function (resp) {
              if (resp.data.ok) {
                securityService.refreshContext().then(function () {
                  notifyManager.success($translate.instant('restaurant.forceUpdateMasterData.success'));
                });
              } else {
                notifyManager.error(resp.data.message);
              }
            })
        };

      }
    )
    .directive('formatPlus', function () {
      return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
          ngModel.$parsers.push(function toModel(input) {
            return [input];
          });
        }
      }
    })
    .filter('measureArticleFilter', function ($filter, $translate) {
      return function (input, article, indexedMeasureList) {
        if (!article.id) {
          return '';
        }
        if (article.articleType && article.articleType.measure) {
          if (!article.articleGroup || (article.articleGroup && !article.articleGroup.measurementUnit)) {
            return;
          }
          var measurementUnit = indexedMeasureList[article.articleGroup.id] ? $translate.instant('measurementUnit.' + indexedMeasureList[article.articleGroup.id]) : '';
          return $filter('number')(input, 2) + measurementUnit;
        } else {
          return '--';
        }
      }
    })
    .filter('plusArticleFilter', function () {
      return function (input, entity) {
        if (entity && _.isArray(entity.plus)) {
          return entity.plus.join(', ');
        } else {
          return null;
        }
      }
    })
    .filter('mapImageArticleFilter', function () {
      return function (input, icons) {
        return icons[input];
      }
    })
    .controller('ChangeArticleGroupCtrl',
      function ($uibModalInstance, articleContext) {
        var self = this;

        angular.extend(self, articleContext.data.dependencies);

        self.yes = function () {
          $uibModalInstance.close(self.group);
        };

        self.no = function () {
          $uibModalInstance.dismiss('cancel');
        };
      }
    )
    .controller('AddConstraintsGroupCtrl',
      function ($translate, $uibModalInstance, entityIds) {

        var self = this;

        self.article = {constraints: []};
        self.constraintSettings = {
          newPos: false,
          deleteOriginal: false
        };

        self.parentsIds = entityIds;

        self.addConstraint = function () {
          self.article.constraints = [{name: $translate.instant('articleConstraint.step'), articles: [], step: 0}];
        };

        self.yes = function () {
          if (self.article.constraints) {
            self.article.constraints.forEach(function (constraint) {
              constraint.newPos = self.constraintSettings.newPos;
              constraint.deleteOriginal = self.constraintSettings.deleteOriginal;
            });
          }

          $uibModalInstance.close(self.article.constraints);
        };

        self.no = function () {
          $uibModalInstance.dismiss('cancel');
        };

        self.isDisabled = function () {
          if (!self.article || !self.article.constraints || self.article.constraints.length == 0) {
            return true;
          }

          var constraintsWithoutArticles = _.find(self.article.constraints, function (constraint) {
            return !constraint.articles || constraint.articles.length == 0;
          });

          return constraintsWithoutArticles;
        };
      }
    )
    .controller('CopyConstraintsGroupCtrl',
      function ($translate, $uibModalInstance, ArticleConstraintCopyTypeahead, entityIds) {

        var self = this;

        self.constraintSettings = {
          newPos: false,
          deleteOriginal: false
        };

        self.parentsIds = entityIds;

        self.addConstraint = function () {
          self.article.constraints = [{name: $translate.instant('articleConstraint.step'), articles: [], step: 0}];
        };

        self.getArticlesTypeahead = function (val) {
          if (!val) {
            return;
          }
          return ArticleConstraintCopyTypeahead.query({
            keyword: val
          }).$promise.then(function (data) {
            // 0. data has articles that have now constraint's articles
            return _.filter(data, function (item) {
              // 1. there are constraints
              if (item.constraints && item.constraints.length > 0) {
                var constraintsArticles = [];
                item.constraints.forEach(function (constraint) {
                  constraintsArticles = constraintsArticles.concat(constraint.articles);
                });

                // 2. check if there is articles
                if (constraintsArticles.length === 0) {
                  return false;
                }

                var constraintArticleIds = _.map(constraintsArticles, function (article) {
                  return article.id;
                });

                // 3. there are no constraint's articles in parents
                return !_.intersection(constraintArticleIds, self.parentsIds).length > 0;
              }

              return false;
            })
          });
        };

        self.onArticleSelect = function (value) {
          self.article = value;
          if (value.constraints.length > 0) {
            self.constraintSettings = {
              newPos: value.constraints[0].newPos,
              deleteOriginal: value.constraints[0].deleteOriginal
            };
          }
        };

        self.yes = function () {
          if (self.article.constraints) {
            self.article.constraints.forEach(function (constraint) {
              constraint.newPos = self.constraintSettings.newPos;
              constraint.deleteOriginal = self.constraintSettings.deleteOriginal;
            });
          }

          $uibModalInstance.close(self.article.constraints);
        };

        self.isDisabled = function () {
          if (!self.article || !self.article.constraints || self.article.constraints.length == 0) {
            return true;
          }

          var constraintsWithoutArticles = _.find(self.article.constraints, function (constraint) {
            return !constraint.articles || constraint.articles.length == 0;
          });

          return constraintsWithoutArticles;
        };

        self.no = function () {
          $uibModalInstance.dismiss('cancel');
        };
      }
    )
    .controller('CopyArticleActionCtrl',
      function ($scope, $uibModal, $translate, ArticleChangeId, notifyManager, Article, entity, gridApi) {
        $scope.gridApi = gridApi;
        $scope.entity = entity;

        var modalDialog = $uibModal.open({
          template: require('../template/enter_plu.html'),
          windowClass: "gf-modal",
          controller: 'ArticleEnterIdCtrl',
          controllerAs: 'articleEnterIdCtrl',
          scope: $scope
        });

        modalDialog.result.then(function (articleEnterId) {
          var newEntity = angular.copy(entity);
          newEntity.id = articleEnterId.id;

          var articleResource = new Article(newEntity);


          articleResource.$change({articleId: newEntity.id}, function (data) {
            var index = _.findIndex($scope.gridApi.grid.options.data, function (item) {
              return item.id == $scope.entity.id;
            });
            // insert new article to the next position
            $scope.gridApi.grid.options.data.splice(index + 1, 0, data.payload.entity);

            notifyManager.success($translate.instant('entity.save.successfully'));
          });
        });
      }
    )

    .factory('MasterdataArticleEdit',
      function ($location, $uibModal, $translate, notifyManager, Article, $timeout, $state, ArticleChangeId,
                $stateParams, $http, ValidationDuplicatePLUMessageService, articleModeCode, configOptionsService,
                securityService, ArticleStock, $q) {
        return function (self, $scope, article, articleMetadata, articleContext, articleStock) {
          self.article = article;
          self.articleContext = articleContext;
          self.isArticleRestored = self.article.id < 0;
          //self.getUniquePluMessage = ValidationDuplicatePLUMessageService.getMessage;
          self.isActivable = !configOptionsService.get('articleActivateButton').isDisabled();

          self.articleStock = articleStock;

          self.isDuplicateSku = false;
          self.isDuplicatePlu = false;

          // set min limit for id
          var minMessage = _.find(articleMetadata.columns['id'].errorMessages, function (item) {
            return item.name === 'Min';
          });

          if (minMessage) {
            minMessage.args.value = '1';
          }
          articleMetadata.columns.id.annotations['javax.validation.constraints.Min'] =
            {payload: [], groups: [], value: 1, message: "{javax.validation.constraints.Min.message}"};
          articleMetadata.columns.id.angular.min = 1;

          self.articleMetadata = articleMetadata;

          angular.extend(self, articleContext.data.dependencies);

          self.priceLevelsMap = {};
          angular.forEach(self.priceLevels, function (priceLevel) {
            self.priceLevelsMap[priceLevel.id] = priceLevel;
          });

          function updatePriceLevels() {
            self.article2PriceLevels = [];

            angular.forEach(self.priceLevelsMap, function (priceLevel, id) {
              var found = false;

              angular.forEach(self.article.article2PriceLevels, function (article2PriceLevel) {
                if (article2PriceLevel.priceLevel.id.toString() === id) {
                  found = true;
                  self.article2PriceLevels.push(article2PriceLevel);
                }
              });

              if (!found) {
                var article2PriceLevel = {priceLevel: priceLevel, price: 0};
                self.article2PriceLevels.push(article2PriceLevel);
                self.article.article2PriceLevels.push(article2PriceLevel);
              }

            });
          }

          updatePriceLevels();

          self.isAggregated = function () {
            return self.article ? articleModeCode.isAggerated(self.article.mode) : false;
          };

          function beforeSave() {
            if (self.isAggregated()) {
              self.article.price = 0;
            }
          }

          function isValidArticleMode() {

            // if article mode CONSTRAINTS and there is step without articles
            if (self.article.modeTextCode === articleModeCode.CONSTRAINTS && self.article.constraints) {
              var constraintsWithoutArticles = _.find(self.article.constraints, function (constraint) {
                return !constraint.articles || constraint.articles.length === 0;
              });
              if (constraintsWithoutArticles) {
                notifyManager.error($translate.instant('entity.edit.save.constraints.without.articles'));
                return false;
              }
            }

            // if article mode AGGREGATED but without any articles
            if (self.article.modeTextCode === articleModeCode.AGGREGATED &&
              self.article.constraints &&
              self.article.constraints.length === 0) {
              notifyManager.error($translate.instant('entity.edit.save.aggregated.without.articles'));
              return false;
            }

            return true;
          }

          self.skuDuplicatesPlu = false;

          function validatePlu() {
            var deferred = $q.defer();

            if (!self.article.plus.length) {
              $http.get('/icash/masterdata/article/' + self.article.id + '/plu/' + self.article.id).then(function (res) {
                if (_.isEmpty(res.data.payload)) {
                  self.article.plus.push(self.article.id);
                  self.skuDuplicatesPlu = false;
                  deferred.resolve();
                } else {
                  self.skuDuplicatesPlu = true;
                  deferred.reject();
                }
              })
            } else {
              deferred.resolve();
            }
            return deferred.promise;
          }

          function saveArticle() {
            var deferred = $q.defer();

            if (self.articleForm.$valid && isValidArticleMode()) {

              if (self.isAggregated()) {
                self.article.price = 0;
              }
              validatePlu().then(function () {
                self.article.$change({articleId: self.article.id}, function (data) {
                  if (data.ok) {
                    self.article = data.payload.entity;
                    if (self.newItem && self.articleStock) {
                      self.articleStock.article = data.payload.entity;
                      self.articleStock.$put({articleId: self.articleStock.article.id}, function (sr) {
                        if (sr.ok) {
                          self.articleStock = sr.payload.entity;
                          notifyManager.success($translate.instant('entity.edit.successfully'));
                          deferred.resolve(data.payload.entity);
                        } else {
                          notifyManager.error($translate.instant(data.message));
                          deferred.reject();
                        }
                      });
                    } else {
                      notifyManager.success($translate.instant('entity.edit.successfully'));
                      deferred.resolve(data.payload.entity);
                    }
                  } else {
                    notifyManager.error($translate.instant(data.message));
                    deferred.reject();
                  }
                });
              })
            } else {
              notifyManager.error($translate.instant('entity.form.not.valid'));
              $scope.$broadcast("gf-submitted");
              deferred.reject();
            }

            return deferred.promise;
          }

          self.save = function () {
            saveArticle().then(function () {
              $state.go('page.masterdataArticle');
            });
          };

          self.saveAndNew = function () {
            saveArticle().then(function () {
              self.articleForm.$setPristine(true);
              self.article = new Article();
              angular.extend(self.article, articleContext.template);
              $state.go('page.masterdataArticleAdd');
            });
          };

          self.back = function () {
            // release plus
            $state.go('page.masterdataArticle')
          };

          // clear button
          self.clear = function () {
            Article.load({articleId: "add"}, function (articleTemplate) {
              articleTemplate.id = self.article.id;
              self.article.constraintSettings.deleteOriginal = false;
              self.article.constraintSettings.newPos = false;
              self.article = articleTemplate;
              updatePriceLevels();
            });
          };
          // this confirmation message  depends on pre-loaded model state and don't depend edited state
          self.getClearConfirmMsg = function () {
            return self.article.active ? $translate.instant('article.clearConfirmation.active') : $translate.instant('article.clearConfirmation.inactive');
          };

          self.editAmount = function () {
            var modalDialog = $uibModal.open({
              template: require('../template/change_article_amount.html'),
              windowClass: 'gf-modal',
              controller: 'EditArticleStockCtrl',
              controllerAs: 'editArticleStockCtrl',
              resolve: {
                articleStock: function () {
                  return ArticleStock.get({articleId: self.article.id}).$promise;
                }
              }
            });

            modalDialog.result.then(function (articleStock) {
              if (articleStock) {
                notifyManager.success($translate.instant('article.amount.changed'));
                self.articleStock = articleStock;
              }
            });
          };

          self.article.newPlu = '';
          self.addPlu = function (newPlu) {
            self.isDuplicatePlu = false;
            self.isDuplicateSku = false;
            if (self.article.plus.indexOf(newPlu) === -1 && self.article.newPlu !== '') {
              $http.get('/icash/masterdata/article/' + self.article.id + '/plu/' + newPlu).then(function (res) {
                if (_.isEmpty(res.data.payload)) {
                  self.article.plus.push(newPlu);
                } else {
                  if (res.data.payload.isPlu) {
                    self.isDuplicatePlu = true;
                    self.newPlu = newPlu;
                    self.duplicateArticleName = res.data.payload.name;
                  } else {
                    self.isDuplicateSku = true;
                    self.newPlu = newPlu;
                    self.isAlertVisible = true;
                    self.article.plus.push(newPlu);
                  }
                }
              })
            }
            self.article.newPlu = '';
          };

          self.removePlu = function ($index) {
            self.article.plus.splice($index, 1);
          };

          self.getTaxClasses = function (searchStr) {
            var params = {
              term: searchStr
            };
            $http.get('/icash/financial/tax/find', {params: params})
              .then(function (resp) {
                self.taxClasses = resp.data.payload.taxClasses
              });
          }
        }
      }
    )

    .controller('MasterdataArticleAddCtrl',
      function ($scope, article, articleMetadata, articleContext, articleStock, MasterdataArticleEdit, currentTour, tourService) {

        var self = this;

        MasterdataArticleEdit(self, $scope, article, articleMetadata, articleContext, articleStock);

        self.newItem = true;
        self.currentTour = currentTour;
        tourService.startDetachedTour('masterdataArticleAdd', currentTour);
      }
    )
    .controller('MasterdataArticleEditCtrl',

      function ($scope, article, articleMetadata, articleContext, articleStock, MasterdataArticleEdit, articleModeCode,
                aggregatedPriceCalculator, isUsedAsAggregatedItem) {

        var self = this;

        if (isUsedAsAggregatedItem && isUsedAsAggregatedItem.data && isUsedAsAggregatedItem.data.ok) {
          self.isUsedAsAggregatedItem = isUsedAsAggregatedItem.data.payload.isUsed;
        }

        MasterdataArticleEdit(self, $scope, article, articleMetadata, articleContext, articleStock);

        if (articleModeCode.isAggerated(article.mode)) {
          article.calculatedTotalPrice = aggregatedPriceCalculator.calculateTotalPrice(article.constraints);
        }
      }
    )
    // .controller('ArticleChangeIdCtrl',
    //
    //   function ($rootScope, $uibModalInstance, $resource, $stateParams, $translate, notifyManager, articleChangeId,
    //             ValidationDuplicatePLUMessageService) {
    //
    //     var self = this;
    //
    //     self.getUniquePluMessage = ValidationDuplicatePLUMessageService.getMessage;
    //
    //     self.articleChangeId = articleChangeId;
    //
    //     self.ok = function () {
    //       $uibModalInstance.close();
    //     };
    //
    //     self.cancel = function () {
    //       $uibModalInstance.dismiss('cancel');
    //     };
    //
    //     $rootScope.$on('$stateChangeSuccess', function () {
    //       $uibModalInstance.dismiss('cancel');
    //     });
    //
    //     self.change = function () {
    //       self.articleChangeId.$save(
    //         function (data) {
    //           if (data.ok) {
    //             $uibModalInstance.close(data.payload.article);
    //           } else {
    //             notifyManager.error($translate.instant(data.message));
    //             $uibModalInstance.close();
    //           }
    //         });
    //     }
    //
    //   }
    // )

    .controller('EditArticleStockCtrl',
      function ($scope, $uibModalInstance, $translate, notifyManager, articleStock) {
        var self = this;

        self.articleStock = articleStock;

        self.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        self.change = function () {
          self.articleStock.$put({articleId: self.articleStock.article.id}, function (result) {
            if (result.ok) {
              $uibModalInstance.close(result.payload.entity);
            } else {
              notifyManager.error($translate.instant(result.message));
              $uibModalInstance.dismiss('error');
            }
          }, function () {
            notifyManager.error($translate.instant('error.general'));
            $uibModalInstance.dismiss('error');
          });
        };
      })

    .controller('ArticleBranchControlCtrl',
      function ($scope, $uibModalInstance) {
        var self = this;
        self.close = function () {
          $uibModalInstance.close();
        };
      }
    )

    .controller('ArticleEnterIdCtrl',

      function ($rootScope, $uibModalInstance, PluService, ValidationDuplicatePLUMessageService) {
        var self = this;
        self.getUniquePluMessage = ValidationDuplicatePLUMessageService.getMessage;


        self.articleEnterId = {id: PluService.acquire("/icash/masterdata/article/plu")};

        self.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $rootScope.$on('$stateChangeSuccess', function () {
          $uibModalInstance.dismiss('cancel');
        });

        self.use = function () {
          $uibModalInstance.close(self.articleEnterId);
        }
      }
    )

})();
