import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  detailsRoot: {
    marginTop: theme.spacing(3)
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  detailsPropName: {
    flex: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: 'right'
  },
  detailsPropValue: {
    flex: 1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  confirmAction: {
    textAlign: 'center',
    marginTop: theme.spacing(3)
  }
}));

function ConfirmCompanyInfoDialog({handleClose, handleSubmit, companyInfo}) {
  const {t} = useTranslation();
  const classes = useStyles();
  const [confirm, setConfirm] = useState(false);

  const handleChangeConfirm = () => {
    setConfirm(!confirm);
  }

  return (
    <Dialog open={true} onClose={handleClose}>
      <DialogTitle id='form-dialog-title'>{t('companyInfo.confirm.dialog.header')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('companyInfo.confirm.dialog.text')}
        </DialogContentText>
        <div className={classes.detailsRoot}>
          <div className={classes.detailsRow}>
            <span className={classes.detailsPropName}>{t('companyInfo.organization.name')}</span>
            <span className={classes.detailsPropValue}>{companyInfo.name}</span>
          </div>
          <div className={classes.detailsRow}>
            <span className={classes.detailsPropName}>{t('companyInfo.organization.address')}</span>
            <span className={classes.detailsPropValue}>{companyInfo.address}</span>
          </div>
          <div className={classes.detailsRow}>
            <span className={classes.detailsPropName}>{t('companyInfo.organization.zip')}</span>
            <span className={classes.detailsPropValue}>{companyInfo.zip}</span>
          </div>
          <div className={classes.detailsRow}>
            <span className={classes.detailsPropName}>{t('companyInfo.organization.city')}</span>
            <span className={classes.detailsPropValue}>{companyInfo.city}</span>
          </div>
          <div className={classes.detailsRow}>
            <span className={classes.detailsPropName}>{t('companyInfo.organization.country')}</span>
            <span className={classes.detailsPropValue}>{companyInfo.country.name}</span>
          </div>
          {
            companyInfo.taxNumber &&
            <div className={classes.detailsRow}>
              <span className={classes.detailsPropName}>{t('companyInfo.organization.taxNumber')}</span>
              <span className={classes.detailsPropValue}>{companyInfo.taxNumber}</span>
            </div>
          }
          {
            companyInfo.ustid &&
            <div className={classes.detailsRow}>
              <span className={classes.detailsPropName}>{t('companyInfo.organization.ustid')}</span>
              <span className={classes.detailsPropValue}>{companyInfo.ustid}</span>
            </div>
          }
        </div>
        <div className={classes.detailsRoot}>
          <div className={classes.detailsRow}>
            <span className={classes.detailsPropName}>{t('companyInfo.location.name')}</span>
            <span className={classes.detailsPropValue}>{companyInfo.location.name}</span>
          </div>
          <div className={classes.detailsRow}>
            <span className={classes.detailsPropName}>{t('companyInfo.location.address')}</span>
            <span className={classes.detailsPropValue}>{companyInfo.location.address}</span>
          </div>
          <div className={classes.detailsRow}>
            <span className={classes.detailsPropName}>{t('companyInfo.location.zip')}</span>
            <span className={classes.detailsPropValue}>{companyInfo.location.zip}</span>
          </div>
          <div className={classes.detailsRow}>
            <span className={classes.detailsPropName}>{t('companyInfo.location.city')}</span>
            <span className={classes.detailsPropValue}>{companyInfo.location.city}</span>
          </div>
          <div className={classes.detailsRow}>
            <span className={classes.detailsPropName}>{t('companyInfo.location.country')}</span>
            <span className={classes.detailsPropValue}>{companyInfo.location.country.name}</span>
          </div>
          <div className={classes.detailsRow}>
            <span className={classes.detailsPropName}>{t('companyInfo.location.ustid')}</span>
            <span className={classes.detailsPropValue}>{companyInfo.location.ustid}</span>
          </div>
        </div>
        <div className={classes.confirmAction}>
          <FormControlLabel
            control={
              <Checkbox
                name='confirm'
                type='checkbox'
                color='primary'
                onChange={handleChangeConfirm}
              />
            }
            label={t('companyInfo.confirm.checkbox.label')}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color='primary'>
          {t('button.cancel')}
        </Button>
        <Button onClick={handleSubmit} variant='contained' color='primary' disabled={!confirm}>
          {t('button.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmCompanyInfoDialog;
