(function () {
  'use strict';
  angular.module('gf.unsubscribe', [])

    .controller("UnsubscribeCtrl",
      function ($scope, $http, $location) {
        var self = this;
        self.unsubscribeSuccess = false;
        self.unsubscribeError = false;
        var m = $location.search()['m'];
        var s = $location.search()['s'];

        $http.get('/icash/util/unsubscribe?m=' + encodeURIComponent(m) + '&s=' + s)
          .then(function(result) {
            if(result.data.ok) {
              self.payload = result.data.payload.profileForm;
            } else if (result.data.status == 'FAIL') {
              self.unsubscribeError = true;
              self.hasError = true;
              self.errorMessage = result.data.message;
            }

          });

        this.submitUnsubscribe = function() {
          $http.post('/icash/util/unsubscribe?m=' + encodeURIComponent(m) + '&s=' + s, self.payload)
            .then(function (result) {
              if(result.data.ok) {
                self.unsubscribeSuccess = true;
              } else if (result.data.status == 'FAIL') {
                self.unsubscribeError = true;
                self.hasError = true;
                self.errorMessage = result.data.message;
              }
            });
        }
      }
    )
})();
