angular.module('gastrofix')

  .controller('ProvideEmailCtrl',

    function ($uibModalInstance, securityService, $http, appConfig, $timeout) {
      var self = this;

      self.profile = {email: securityService.context.currentUser.email};

      self.enableButton = false;
      $timeout(function () {
        self.enableButton = true;
      }, 5000);

      self.yes = function (form) {
        if (form.$valid) {
          $http.post(appConfig.baseUrl + '/icash/user/profile/email', self.profile)
            .then(function () {
              $uibModalInstance.close('yes');
            });
        }
      };

      self.no = function () {
        $uibModalInstance.dismiss('no');
      };
    }
  )

;
