import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslation} from "react-i18next";

function DeleteConfirmationDialog({open, handleClose, onDelete, text}) {

  const { t } = useTranslation();

  const handleCloseDialog = () => {
    handleClose();
  };

  const handleDelete = () => {
    onDelete();
  };

  return (
    <div>
      <Dialog
        id='confirm-delete-dialog'
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id='delete-dialog-title'>{t('modal.confirmDeleteTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-dialog-description'>
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button id='delete-dialog-cancel-btn' onClick={handleCloseDialog} color='secondary'>
            {t("button.cancel")}
          </Button>
          <Button id='delete-dialog-confirm-btn' onClick={handleDelete} color='secondary' variant='contained'
                  autoFocus>
            {t("button.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteConfirmationDialog;
