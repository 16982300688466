(function () {
  'use strict';
  angular.module('gf.directives', ['colorpicker.module', 'gf.utils', 'gf.validations', 'gf.dnd'])
    .run(['$rootScope', '$location', '$templateCache', '$anchorScroll', function ($rootScope, $location, $templateCache, $anchorScroll) {
      $rootScope.menu = {topMenu: "", subMenu: ""};

      $rootScope.deserializePrimaryKeyToken = function (token) {
        try {
          return JSON.parse(Base64.decode(token));
        } catch (e) {
          return undefined;
        }
      };

      $rootScope.go = function (path) {
        $location.url(path);
      };

      $rootScope.scrollTo = function (hash) {
        $location.hash(hash);

        $anchorScroll();
      };

      $rootScope.range = function (start, end) {
        var l = end - start + 1, r = new Array(l);
        for (var i = 0; i < l; i++) {
          r[i] = start + i;
        }
        return r;
      };

      $rootScope.$on('$stateChangeSuccess', function () {
        $rootScope.menu.topMenu = '';
        $rootScope.menu.subMenu = '';
        $rootScope.menu.subMenuAdditional = '';
        var split = $location.path().split("/");
        if (split.length > 1) {
          $rootScope.menu.topMenu = split[1];
        }
        if (split.length > 2) {
          $rootScope.menu.subMenu = split[2];
        }
        if (split.length > 3) {
          $rootScope.menu.subMenuAdditional = split[3];
        }
      });

      /**
       * Safe Apply from https://coderwall.com/p/ngisma
       */
      $rootScope.safeApply = function (fn) {
        // if $root is null then the scope has been destroyed, no needs to apply function
        if (this.$root != null) {
          var phase = this.$root.$$phase;
          if (phase === '$apply' || phase === '$digest') {
            if (fn && (typeof (fn) === 'function')) {
              fn();
            }
          } else {
            this.$apply(fn);
          }
        }
      };

      $templateCache.put("multiselect.tmpl.html", "<div class=\"btn-group\">\n" +
        "<button type=\"button\" class=\"btn btn-default dropdown-toggle\" data-toggle=\"dropdown\" ng-click=\"toggleSelect()\" ng-disabled=\"disabled\" ng-class=\"{'error': !valid()}\">\n" +
        "{{header}} <i class=\"fa fa-sort\"></i>\n" +
        "</button>\n" +
        "<ul class=\"dropdown-menu gf-scrollable-dropdown\">\n" +
        "    <li>\n" +
        "        <input type=\"search\" ng-model=\"searchText.label\" autofocus=\"autofocus\" placeholder=\"Filter\" class=\"form-control input-sm\" />\n" +
        "    </li>\n" +
        "    <li ng-show=\"multiple\" role=\"presentation\" class=\"\">\n" +
        "        <button type=\"button\" ng-click=\"checkAll()\" class=\"btn btn-link btn-xs\"><i class=\"fa fa-check fa-lg fa-fw\"></i> {{'multiselect.checkAllText' | translate}}</button>\n" +
        "        <button type=\"button\" ng-click=\"uncheckAll()\" class=\"btn btn-link btn-xs\"><i class=\"fa fa-times fa-lg fa-fw\"></i> {{'multiselect.uncheckAllText' | translate}}</button>\n" +
        "    </li>\n" +
        "    <li class=\"divider\"></li>\n" +
        "    <li ng-repeat=\"i in items | filter:searchText\">\n" +
        "        <a ng-click=\"select(i); focus()\">\n" +
        "            <i class='fa' ng-class=\"{'fa-check': i.checked, 'empty': !i.checked}\"></i> {{i.label}}</a>\n" +
        "    </li>\n" +
        "</ul>\n" +
        "</div>");

      $templateCache.put('/dialogs/confirm.html', '<div class="modal-header dialog-header-confirm">'
        + '<button type="button" class="close" ng-click="no()" tooltip="{{\'button.cancel\' | translate}}"><i class="fa fa-times fa-fw"></i></button>'
        + '<h4 class="modal-title gf-modal-heading"><i class="fa fa-check-square-o fa-lg"></i> {{header}}</h4>'
        + '</div><div class="modal-body" ng-bind-html="msg"></div><div class="modal-footer">'
        + '<button type="button" class="btn btn-standard" ng-click="no()">{{"button.cancel" | translate}}</button>'
        + '<button type="button" class="btn btn-default" ng-click="yes()">{{"button.ok" | translate}}</button>'
        + '</div>');

    }])

    .constant('featureDisableNotificationMessages',
      {
        58: 'feature.GTC.disable.message'
      })

    // .directive('navMenu', [function () {
    //   function isActive(navMenu, scope) {
    //     if (!navMenu) {
    //       return false;
    //     }
    //
    //     var split = navMenu.split("/");
    //
    //     if (split.length > 1) {
    //       return split[0] === scope.menu.subMenu && split[1] === scope.menu.subMenuAdditional;
    //     } else {
    //       return navMenu === scope.menu.subMenu
    //     }
    //   }
    //
    //   return function (scope, element, attrs) {
    //     scope.$watch("menu.subMenu + menu.subMenuAdditional", function () {
    //       if (isActive(attrs.navMenu, scope)) {
    //         element.addClass("active");
    //       } else {
    //         element.removeClass("active");
    //       }
    //     });
    //   };
    // }])
    // .directive('topMenu', [function () {
    //   return function (scope, element, attrs) {
    //     scope.$watch("menu.topMenu", function () {
    //       if (element.prop('nodeName') == 'UL') {
    //         if (attrs.topMenu && attrs.topMenu == scope.menu.topMenu) {
    //           element.show();
    //         } else {
    //           element.hide();
    //         }
    //       } else {
    //         var tab = element.parents("li.dropdown");
    //         if (attrs.topMenu && attrs.topMenu == scope.menu.topMenu) {
    //           element.addClass("active");
    //           $("div#top-menu li.dropdown").removeClass("active");
    //           tab.addClass("active");
    //         } else {
    //           element.removeClass("active");
    //         }
    //       }
    //     });
    //   };
    // }])
    // .directive('gfDropdownMenu', ["$translate", function ($translate) {
    //   return {
    //     link: function ($scope, element, attrs) {
    //
    //       $scope.$watch("menu.topMenu", function () {
    //         var text = element.find("li[top-menu='" + $scope.menu.topMenu + "'] a").text();
    //
    //         if (text) {
    //           element.find(".dropdown-menu-text").text(text);
    //         } else {
    //           $translate(attrs.gfDropdownMenu).then(function (transtation) {
    //             element.find(".dropdown-menu-text").text(transtation);
    //           });
    //         }
    //       });
    //     }
    //   }
    // }])
    .directive('gridPager', [function () {
      return {
        template: require('../../template/widgets/grid_pager_bootstrap.html'),
        controller: ["$scope", "$element", function ($scope, $element) {
          _.extend($scope, {
            setPageSize: function (size) {
              $scope.grid.setPageSize(size, true);
            },

            setPageOffset: function (offset) {
              $scope.grid.loadPage(offset);
            }
          })
        }],
        link: function (scope, element, attrs) {
          var grid = scope.grid;
          scope.paging = grid.getPagingInfo();

          scope.$on('grid:loadPage', function () {
            scope.safeApply(function () {
              scope.paging = grid.getPagingInfo();
            });
          });
        }
      };
    }])
    // https://groups.google.com/forum/#!msg/angular/LH0Q1A-qTVo/eyVIjJsFZGcJ
    .directive('ngModelOnblur', [function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elm, attr, ngModelCtrl) {
          if (attr.type === 'radio' || attr.type === 'checkbox') return;

          elm.unbind('input').unbind('keydown').unbind('change');
          elm.bind('blur', function () {
            scope.safeApply(function () {
              ngModelCtrl.$setViewValue(elm.val());
            });
          });
        }
      };
    }])
    .directive('gfIconSelect',
      function ($rootScope, icons, gridUtils, $q, $translate, securityService, appConfig) {
        return {
          scope: {
            iconPath: '=ngModel',
            iconSet: '@'
          },
          template: require('../../../../../main/angular/app/template/widgets/icon_select.html'),
          transclude: true,
          link: function ($scope) {
            var iconsPromise = gridUtils.jsonPromise($q, icons[$scope.iconSet]);

            $scope.libraryUrl = appConfig.libraryUrl;

            // TODO (oleksii, 18.11.2013): remove after upgrade to angular 1.2
            $scope.value = {path: 'default/beer.png'};

            $scope.selectIcon = function (icon) {
              $scope.value = icon;
              $scope.iconPath = icon.path;
            };

            $scope.selectNone = function () {
              $scope.value = {path: 'default/beer.png'};

              $scope.iconPath = undefined;
            };

            $scope.isNone = function () {
              return !$scope.iconPath;
            };

            $scope.iconName = function (name) {
              return name ? $translate.instant('icon.' + name) : '';
            };

            iconsPromise.then(function (defaultIcons) {
              // find actual value
              for (var l = defaultIcons.length, i = 0; i < l; i++) {
                if (defaultIcons[i].path === $scope.iconPath) {
                  $scope.value = defaultIcons[i];
                }
              }

              $scope.icons = defaultIcons.sort(function (a, b) {
                return $scope.iconName(a.name).localeCompare($scope.iconName(b.name));
              });
            })
          }
        }
      }
    )
    .directive('gfOrderScreenButton', function (appConfig) {
      return {
        transclude: true,
        scope: {
          item: '=',
          isGroup: "=",
          hideTip: "=hideTip"
        },
        template: require('../../../../../main/angular/app/template/widgets/orderscreen_button.html'),
        link: function ($scope) {

          $scope.hasNoIcon = function () {
            return !$scope.item || !$scope.item.image || ($scope.hideTip && $scope.item.image === '');
          };

          $scope.getIconStyle = function () {
            if (!$scope.item || !$scope.item.image) {
              return '';
            }

            return {
              'background-image': "url('" + appConfig.baseUrl + appConfig.libraryUrl + '/' + $scope.item.image + "')"
            };
          };

          $scope.getTip = function () {
            return !$scope.hideTip ? "Button-Preview" : "";
          };

        }
      }
    })
    .controller("EnableTrialFeatureCtrl", ["$scope", "$uibModalInstance", "feature", function ($scope, $uibModalInstance, feature) {
      $scope.feature = feature;

      $scope.ok = function () {
        $uibModalInstance.close({});
      };

      $scope.close = function () {
        $uibModalInstance.dismiss();
      };
    }])

    .directive('gfFeatureTable',
      function (RestaurantFeatures, $location, dialogs, $translate, $uibModal, $state, $resource, securityService,
                appConfig, $stateParams, featureTableService, featureDisableNotificationMessages) {
        return {
          transclude: true,
          template: require('../../template/featureTable.html'),
          require: 'ngModel',
          scope: {
            features: "=ngModel",
            featureSettings: "=featureSettings",
            restaurant: "=restaurant",
            operatorMode: "=operatorMode",
            current: "@",
            enableSettingsButton: "=enableSettingsButton",
            operatorId: "=operatorId",
            trial: "=trial"
          },
          link: function ($scope) {

            var savedFeatures = [];
            $scope.$watch('features', function () {
              savedFeatures = _.clone($scope.features);
            });

            $scope.isDisabledSettingsButton = function (feature) {
              return !feature.selected || !_.find(savedFeatures, {name: feature.name});
            };

            $scope.brand = {
              brand: securityService.context.brand
            };

            $scope.security = securityService;
            $scope.stateParams = $stateParams;

            var featureOptions = {
              restaurant: $scope.restaurant,
              isOperatorMode: $scope.operatorMode
            };

            $scope.confirmDataMigration = function () {
              $uibModal.open({
                template: require('../../template/confirm_action_modal.html'),
                windowClass: 'gf-modal',
                controller: function ($scope, $http, $stateParams, notifyManager, $uibModalInstance) {
                  $scope.title = 'gtc.migration.title';
                  $scope.message = 'gtc.migration.message';
                  $scope.confirm = function () {
                    $http.post('/icash/gtc/migrate/operator/' + $stateParams.id).then(function (res) {
                      notifyManager.success($translate.instant('messages.migrateGtcSuccessfully'));
                      $uibModalInstance.close();
                    }, function () {
                      notifyManager.error($translate.instant('messages.migrateGtcFailed'));
                      $uibModalInstance.close();
                    })
                  }
                }
              })
            };

            // check state for checkbox
            var contractType;
            var country;
            var isZuoraContractType;
            var countryFeature;

            $scope.isDisabled = function (feature) {
              if ($scope.operatorMode) {
                var ContractTypeResource = $resource(appConfig.baseUrl + "/icash" + $state.current.url + "/contract_type.json");
                var CountryFeatures = $resource(appConfig.baseUrl + "/icash/permission/feature/assignment/:countryId/context.json");

                //var operatorId = $state.current.params.operatorId;
                if ($stateParams.id) {
                  // for old check zuora
                  contractType = contractType != undefined ? contractType : ContractTypeResource.get({id: $stateParams.id}).$promise.then(function (data) {
                    return data.payload.contractType;
                  });

                  if (_.isEqual(contractType.$$state.status, 1)){
                    country = country != undefined ? country : (contractType.$$state.value.country ? contractType.$$state.value.country : null);

                    if (country){
                      countryFeature = countryFeature != undefined ? countryFeature : CountryFeatures.get({countryId: country.id}).$promise.then(function (data) {
                        return data.data.dependencies.countryFeatures;
                      });

                      if (_.isEqual(countryFeature.$$state.status, 1)) {
                        if (_.find(countryFeature.$$state.value, {id: feature.id})) {
                          feature.selected = true;
                          return true;
                        }
                      }
                    }

                    isZuoraContractType = isZuoraContractType!= undefined ? isZuoraContractType : (contractType.$$state.value.zuora ? contractType.$$state.value.zuora : true);

                    // disabled before loading contract type
                    return isZuoraContractType && feature.zuoraBilling
                      || (feature.upselling && !securityService.hasAnyRole('ROLE_SUPERVISOR', 'ACCESS_PAID_FEATURES_OPERATOR'))
                      || featureTableService.isDisabled(feature, featureOptions);
                  }
                  return true;

                } else {
                  // for new operators think that zuora is active
                  return feature.zuoraBilling
                    || (feature.upselling && !securityService.hasAnyRole('ROLE_SUPERVISOR', 'ACCESS_PAID_FEATURES_OPERATOR'))
                    || featureTableService.isDisabled(feature, featureOptions);
                }
              }

              return !(feature.editable)
                || (feature.forActivation && isSelected(feature)) || featureTableService.isDisabled(feature, featureOptions);
            };

            $scope.tooltipText = function (feature) {
              if (feature.zuoraBilling) {
                if (feature.selected) {
                  return $translate.instant('zuora.feature.tooltip.active');
                } else {
                  return $translate.instant('zuora.feature.tooltip.inactive', {brand: $scope.brand.brand});
                }
              } else if (feature.operatorLevel) {
                return $translate.instant('feature.operatorLevel.desc');
              } else if (featureTableService.tooltipTrigger(feature, featureOptions)) {
                return featureTableService.tooltipText(feature);
              }
            };

            $scope.tooltipTrigger = function (feature) {
              if (!$scope.operatorMode && (feature.operatorLevel
                || feature.zuoraBilling
                || featureTableService.tooltipTrigger(feature, featureOptions))) {
                return 'mouseenter';
              } else {
                return 'none';
              }
            };

            $scope.hideFeature = function (feature) {
              return featureTableService.isHidden(feature, featureOptions);
            };

            function isSelected(feature) {
              return $scope.features.filter(function (val) {
                return val.name === feature.name;
              }).length > 0
            }

            $scope.isFeatureSaved = function (feature) {
              var trial = $scope.findTrial(feature);
              return (_.find(savedFeatures, {name: feature.name}) || feature.operatorLevel) && !trial
                || trial && trial.finished;
            };

            $scope.isTrialDeactivated = function (feature) {
              var trial = $scope.findTrial(feature);
              return trial ? trial.deactivate : false;
            };

            $scope.isTrialActive = function (feature) {
              var trial = $scope.findTrial(feature);
              return trial && !trial.deactivate && !trial.finished;
            };

            $scope.findTrial = function (feature) {
              return _.find($scope.trial, function (t) {
                return t.feature.name === feature.name;
              })
            };

            $scope.$on('activatedTrial', function (event, trial) {
              var trialFeature = findFeatureInGroups(trial.feature.name);
              if (!trialFeature) {
                return;
              }

              trialFeature.selected = true;
              $scope.trial.push(trial);
            });

            $scope.$on('deactivatedTrial', function (event, trial) {
              var trialFeature = findFeatureInGroups(trial.feature.name);
              if (!trialFeature) {
                return;
              }

              trialFeature.selected = false;

              _.chain($scope.trial)
                .find({id: trial.id, feature: {name: trial.feature.name}})
                .set('deactivate', true)
                .value();
            });

            function findFeatureInGroups(featureName) {
              return _
                .chain($scope.groups)
                .flatMap('features')
                .flatMap(function (feature) {
                  if (feature.children) {
                    return [feature].concat(feature.children);
                  } else {
                    return feature
                  }
                })
                .find({name: featureName})
                .value();
            }

            var requestParam = {};
            if ($scope.operatorId) {
              requestParam = {"operatorId": $scope.operatorId}
            } else if ($scope.current || $scope.operator) {
              requestParam = {"belongsTo": "0"}
            } else {
              requestParam = {"belongsTo": $scope.restaurant ? $scope.restaurant.id : "0"}
            }

            RestaurantFeatures.get(requestParam, function (data) {

              if (data.ok) {
                $scope.groups = data.payload.groups
                  .map(group => {
                    group.features = group.features
                      .filter(feature => {
                        return !featureTableService.isHidden(feature, featureOptions);
                      })
                      .map(feature => {
                        feature.selected = isSelected(feature) || feature.operatorLevel;

                        feature.children
                          .map(child => {
                            child.selected = isSelected(child) || child.operatorLevel;
                            return child;
                          });

                        return feature;
                      });
                    return group;
                  })
                  .filter(group => group.features.length > 0)

                $scope.tabs = _.range($scope.groups.length).map(function () {
                  return true;
                });
              }

            });

            $scope.selectFeature = function (feature) {
              $scope.features.push({id: feature.id, name: feature.name});
            };

            $scope.getFeatureDisableNotificationMessage = function (featureId) {
              return _.get(featureDisableNotificationMessages, featureId);
            };

            $scope.toggleFeature = function (feature) {
              var featureIndex = $scope.features.map(function (val) {
                return val.name;
              }).indexOf(feature.name);

              if (feature.selected && featureIndex < 0) {
                feature.showSettings = true;

                if (feature.forActivation) {

                  $uibModal.open({
                    template: require('../../template/enableTrialFeature.html'),
                    controller: 'EnableTrialFeatureCtrl',
                    resolve: {
                      feature: function () {
                        return feature;
                      }
                    },
                    windowClass: 'gf-modal'
                  }).result.then(function () {
                    $scope.selectFeature(feature);
                  }, function () {
                    feature.selected = false;
                  });

                  return;
                }

                //check for app version
                if (feature.outdated) {

                  dialogs.confirm($translate.instant('pleaseConfirm'), $translate.instant('feature.versionOutdated.body', {appVersion: feature.appVersion}))
                    .result.then(function () {
                    $scope.selectFeature(feature);
                  }, function () {
                    feature.selected = false;
                  });

                  return;
                }

                $scope.selectFeature(feature);

              } else if (!feature.selected && featureIndex >= 0) {

                var message = $scope.getFeatureDisableNotificationMessage(feature.id);
                if (message && _.find(savedFeatures, {id: feature.id})) {
                  feature.selected = true;
                  $uibModal.open({
                    template: require('../../template/disableFeatureModal.html'),
                    controller: function ($uibModalInstance) {
                      var self = this;
                      self.message = message;
                      self.cancel = function () {
                        $uibModalInstance.dismiss();
                      };
                      self.disable = function () {
                        $uibModalInstance.close();
                      }
                    },
                    controllerAs: '$ctrl',
                    windowClass: 'gf-modal'
                  }).result.then(function () {
                    feature.selected = false;

                    $scope.features.splice(featureIndex, 1);
                    feature.showSettings = false;

                  }, function () {});
                } else {
                  $scope.features.splice(featureIndex, 1);
                  feature.showSettings = false;
                }
              }
            };

            $scope.isExpand = function () {
              return !$scope.tabs.reduce(function (a, b) {
                return a && b;
              });
            };

            $scope.toggleTabs = function () {
              var expand = $scope.isExpand();

              for (var i = 0; i < $scope.tabs.length; i++) {
                $scope.tabs[i] = expand;
              }
            };

            $scope.goToPage = function (url) {
              $location.url(url.replace('{belongsTo}', $scope.restaurant.id));

              // if same page with parameters we need reload state, because location can't load it
              // update hash
              if ($location.hash()) {
                $stateParams['#'] = $location.hash();
              }
              $state.reload();
            };

            $scope.featureHasSettings = function (feature) {
              return _.find($scope.featureSettings, feature);
            };

            $scope.getFeatureSettingsUrl = function (feature) {
              return _.get(_.find($scope.featureSettings, feature), [feature, 'url']);
            };

          }
        }
      })
    .directive('gfRoleTable', function ($location, notifyManager, $http, $translate) {
      return {
        transclude: true,
        template: require('../../template/roleTable.html'),
        require: 'ngModel',
        scope: {
          groups: "=ngModel",
          permissionGroups: "=groups",
          settings: "=options"
        },

        link: function ($scope) {

          var defaultOptions = {
            url: '/icash/restaurant_data/waiter_group/all',
            permission: 'waiterPermission',
            permissionPrefix: 'waiterPermission.',
            groupPrefix: 'waiterPermissionGroup.'
          };


          if ($scope.settings) {
            $scope.options = angular.extend(defaultOptions, $scope.settings);
          } else {
            $scope.options = defaultOptions;
          }


          $scope.permissionGroupsWithGroups = [];

          angular.forEach($scope.permissionGroups, function (permissionGroup) {

            var groupByPermissions = [];

            $scope.permissionGroupsWithGroups.push({
              permissionGroup: permissionGroup,
              groupByPermissions: groupByPermissions
            });

            angular.forEach(permissionGroup.permissions, function (permission) {

              var groupPermissions = [];

              groupByPermissions.push({permission: permission, groupPermissions: groupPermissions});

              angular.forEach($scope.groups, function (group) {
                var selected = group.permissions.filter(function (val) {
                  return val.name === permission.key;
                }).length > 0;

                groupPermissions.push({group: group, selected: selected, permission: permission})
              });

            });
          });

          $scope.toggleRole = function (groupPermission) {

            var permission = groupPermission.permission;
            var group = groupPermission.group;

            var permissionIndex = group.permissions.map(function (val) {
              return val.name;
            }).indexOf(permission.key);

            if (groupPermission.selected && permissionIndex < 0) {
              group.permissions.push({id: permission.id, name: permission.key});
            }

            if (!groupPermission.selected && permissionIndex >= 0) {
              group.permissions.splice(permissionIndex, 1);
            }
          };

          $scope.isExpand = function () {

            var result = true;

            angular.forEach($scope.tabs, function (value) {
              result = result && value;
            });

            return !result;
          };

          $scope.toggleTabs = function () {
            var expand = $scope.isExpand();


            angular.forEach($scope.tabs, function (value, key) {
              $scope.tabs[key] = expand;
            });
          };

          $scope.tabs = {};
          angular.forEach($scope.permissionGroups, function (group) {
            $scope.tabs[group.name] = true;
          });

          if ($location.hash()) {
            $scope.toggleTabs();
            $scope.tabs[$location.hash()] = true;
          }

          $scope.save = function () {
            $http.post($scope.options.url, $scope.groups).then(function (data) {
              if (data.data.ok) {
                notifyManager.success($translate.instant('entity.edit.successfully'));
              }
            });
          }

        }
      }
    })
    .directive('gfChart', function ($filter, $translate) {
      return {
        scope: {
          gfChart: "=",
          ngModel: "=",
          currency: "=",
          from: "=",
          to: "=",
          dateType: "="
        },
        link: function ($scope, element, attrs) {

          function formatPrice(front, amount, symbol) {
            return front ? symbol + amount : amount + symbol;
          }

          var oneDay = 1 * 24 * 60 * 60 * 1000;
          var oneMonth = 30 * oneDay;
          var oneYear = 12 * oneMonth;

          var stackSettings = {
            series: {
              stack: true,
              lines: {show: false, fill: true, steps: false},
              bars: {show: true, barWidth: oneDay}
            },
            xaxis: {
              mode: "time",
              tickSize: [1, "day"]
            },
            yaxis: {
              min: 0
            },
            legend: {
              show: true
            }
          };

          var pieSettings = {
            series: {
              pie: {
                show: true,
                radius: 1,
                combine: {
                  color: '#999',
                  threshold: 0.025,
                  label: $translate.instant('jsp.other') + '<curr> '
                },
                label: {
                  show: true,
                  radius: 2 / 3,
                  formatter: function (label, series) {
                    var turnover = series.data[0][1];
                    var labels = series.label.split('<curr>');
                    return '<div style="color: #FEF8E4;font-weight: bold;">' + $filter("currency")(turnover, labels[1]) + '</div>';
                  }
                }
              }
            },
            grid: {
              clickable: true
            }
          };

          var type = $scope.gfChart;
          var chartContainer = element.find(".gf-chart-container");
          var legendContainer = element.find(".gf-dashboard-legend");
          var nodataContainer = element.siblings(".gf-nodata-panel");
          var pDetails = false;

          $scope.$watch('ngModel', function (data) {

            if (data.length === 0) {
              nodataContainer.show();
              element.hide();
            } else {
              nodataContainer.hide();
              element.show();

              if (type === 'pie' && data.length > 0) {
                pieSettings.series.pie.combine.label = $translate.instant('jsp.other') + '<curr> ' + $scope.currency.symbol;
                $.plot(chartContainer, data, angular.extend(pieSettings, {
                  legend: {
                    container: legendContainer,
                    labelFormatter: function (label, series) {
                      var labels = series.label.split('<curr>');
                      var turnover = series.data[0][1];
                      return '<div style="font-size: small;">' + _.escape(labels[0]) + ' ' + $filter("currency")(turnover, labels[1]) + '</div>';
                    }
                  }
                }));
              }
              if (type === 'stack' && data.length > 0) {
                var tickSize = [1, "day"];
                switch ($scope.dateType) {
                  case 5:
                  case 6:
                    tickSize = pDetails ? [2, "day"] : [7, "day"];
                    break;
                  case 7:
                    tickSize = [1, "month"];
                    break;
                  case 8:
                    if ($scope.to - $scope.from > 2 * oneYear) {
                      tickSize = [1, "year"];
                      break;
                    }
                    if ($scope.to - $scope.from > 19 * oneMonth) {
                      tickSize = pDetails ? [2, "month"] : [1, "month"];
                      break;
                    }
                    if ($scope.to - $scope.from > 2 * oneMonth) {
                      tickSize = [1, "month"];
                      break;
                    }
                    if ($scope.to - $scope.from > oneMonth) {
                      tickSize = pDetails ? [2, "day"] : [7, "day"];
                      break;
                    }
                    if ($scope.to - $scope.from > 19 * oneDay) {
                      tickSize = pDetails ? [2, "day"] : [1, "day"];
                      break;
                    }
                }
                $.plot(chartContainer, data, angular.extend(stackSettings,
                  {
                    xaxis: {
                      mode: "time",
                      tickSize: tickSize,
                      min: $scope.from - oneDay,
                      max: $scope.to + 2 * oneDay
                    },
                    legend: {
                      container: legendContainer,
                      labelFormatter: function (label, series) {
                        var labels = series.label.split('<curr>');
                        var turnover = series.data[0][1];
                        return '<div style="font-size: small;">' + _.escape(labels[0]) + '</div>';
                      }
                    }
                  }));

              }
            }
          });
        }
      }
    })
    .directive('gfConfirmClick', ['dialogs', '$translate',
      function (dialogs, $translate) {
        return {
          link: function ($scope, element, attr) {
            var clickAction = attr.gfConfirmClick;
            var msg = attr.confirmClick ? $scope.$eval(attr.confirmClick) : $translate.instant('jsp.delete');
            element.bind('click', function (event) {
              dialogs.confirm($translate.instant('pleaseConfirm'), msg)
                .result.then(function () {
                $scope.$eval(clickAction)
              });
            });
          }
        };
      }])

    .directive("gfDisabled", [function () {
      return {
        restrict: "A",
        link: function (scope, element, attrs) {
          var disableClick = function () {
            return false;
          };
          scope.$watch(attrs.gfDisabled, function (value) {
            if (value) {
              element.attr("disabled", "disabled");
              element.on("click", disableClick);
            } else {
              element.removeAttr("disabled");
              element.off("click", disableClick);
            }
          });
        }
      }
    }])
    /**
     * Fix for datepicker-popup european date format ICR-4914
     */
    .directive("gfDate", ["$locale", function ($locale, moment) {
      return {
        require: 'ngModel',
        restrict: "A",
        link: function ($scope, element, attrs, ngModel) {

          function parseDate(viewValue) {
            try {
              var date = moment(ngModel.$viewValue, $locale.DATETIME_FORMATS.shortDate).toDate();

              if (!isNaN(date)) {
                ngModel.$setValidity('date', true);
                return date;
              }

            } catch (e) {
            }
            return viewValue
          }

          ngModel.$parsers.unshift(parseDate);
        }
      }
    }])
    /**
     * Uses for local date parsing and formatting with uib-datepicker
     * Code example from https://gist.github.com/weberste/354a3f0a9ea58e0ea0de
     */
    .directive('gfLocaldate', ['$parse', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: link
      };

      function link(scope, element, attr, ngModel) {
        if (!ngModel) return;

        ngModel.$parsers.push(function (viewValue) {
          viewValue.setMinutes(viewValue.getMinutes() - viewValue.getTimezoneOffset());
          return viewValue;
        });

        ngModel.$formatters.push(function (modelValue) {
          if (!modelValue) {
            return undefined;
          }
          var dt = new Date(modelValue);
          dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
          return dt;
        });
      }
    }])
    /**
     * Adds handler for Enter keypress
     */
    .directive('ngEnter', [function () {
      return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
          if (event.which === 13) {
            scope.$apply(function () {
              scope.$eval(attrs.ngEnter);
            });

            event.preventDefault();
          }
        });
      };
    }])
    /**
     * Make element appear focused, useful for ng-show/hide inputs
     */
    .directive('focusOn', ["$timeout", function ($timeout) {
      return function (scope, elem, attr) {
        scope.$on(attr.focusOn, function (e) {
          $timeout(function () {
            elem[0].focus();
          }, 0, false);
        });
      };
    }])
    .directive('gfShowParent', [function () {
      return {
        scope: {
          "gfShowParent": "="
        },
        link: function ($scope, element, attrs) {
          $scope.$watch(function () {
            return $scope.gfShowParent;
          }, function (value) {
            if (value) {
              element.parent().show();
            } else {
              element.parent().hide();
            }
          })
        }
      }
    }])
    .directive('tooltipNoUploadPopup', function () {
      return {
        restrict: 'EA',
        replace: true,
        scope: {content: '@', placement: '@', animation: '&', isOpen: '&'},
        template: require('../../../../../components/gf-dashboard/template/page/dashboard/noUploadPopup.html')
      };
    })
    .directive('tooltipNoUpload', function ($uibTooltip) {
      return $uibTooltip('tooltipNoUpload', 'tooltip', 'mouseenter');
    })
    .directive('gfRoom', function ($timeout, $filter, $compile, $sce, $http, $translate) {
      return {
        restrict: "E",
        replace: true,
        scope: {
          roomId: "=room",
          tableList: "=tables",
          curr: "=currency"
        },
        link: function ($scope, element, attrs, controller) {

          function getSvg(roomId) {
            $http
              .get('/icash/dashboard/room/' + roomId + '.svg')
              .then(function (response) {
                element.html(response.data);
              })
          }

          var onClickSVGTable = function (tableId, element, action) {

            return function () {
              $(element).popover(action);
            }
          };

          function isTable(elem) {
            // skip anything that isn't an element and elements that don't have party number attribute
            if (!(elem.nodeType == 1 && elem.hasAttribute("party_number"))) {
              return false;
            }

            var pnum = elem.getAttribute("party_number"),
              pnumparts = pnum.split("/");

            // table should have valid part number
            return pnumparts.length == 2;
          }

          function getAllTables(node) {

            if (node) {

              var children = node.childNodes;
              var nodeTables = [];
              var element;

              for (var i = 0, l = children.length; i < l; i++) {
                element = children[i];

                if (element.nodeType != 1) continue;

                if (isTable(element)) {
                  nodeTables.push(element);
                } else {
                  nodeTables = nodeTables.concat(getAllTables(element));
                }
              }

              return nodeTables;
            } else {
              return [];
            }
          }

          function closeAllTables(svg) {

            getAllTables(svg).map(function (table) {
              table.setAttribute("fill", "url(#table-regular)");
              table.setAttribute("fill-opacity", "1");

              var $table = $(table);

              $table.removeAttr("onclick");
              $table.unbind("click");
              //$table.popover('destroy');
            });
          }

          function openTableByID(svg, id, popover) {
            getAllTables(svg).map(function (table) {
              var tableId = table.getAttribute("party_number");
              //remove leading 0
              if (tableId == id.replace(/^[0]+/g, "")) {
                table.setAttribute("fill", "url(#table-open)");

                $scope["table" + tableId] = $sce.trustAsHtml(popover);
                table.setAttribute("uib-popover-html", "this['table" + tableId + "']");
                table.setAttribute("popover-placement", "top");
                table.setAttribute("popover-trigger", "'mouseenter'");
                $compile(table)($scope);
              }
            });
          }

          function appendSvgDefs(svg) {
            var table_defs = svg.defs("tables");

            if (!svg.root().getAttribute("viewBox")) {
              svg.root().setAttribute("viewBox", "0 0 " + svg.root().getAttribute("width") + " " + svg.root().getAttribute("height"));
            }
            svg.root().setAttribute("width", "100%");
            svg.root().setAttribute("height", "100%");


            svg.linearGradient(table_defs, "table-regular",
              [
                [0.05, "#333333"],
                [0.95, "#464646"]
              ], 0, 1, 0.18, 0,
              {gradientTransform: "matrix(1,0,0,1,0,0)"}
            );

            svg.linearGradient(table_defs, "table-selected",
              [
                [0.05, "#fdd888"],
                [0.95, "#fdb61c"]
              ], 0, 1, 0.18, 0,
              {gradientTransform: "matrix(1,0,0,1,0,0)"}
            );

            svg.linearGradient(table_defs, "table-open",
              [
                [0.05, "#466f98"],
                [0.95, "#7fa5d4"]
              ], 0, 1, 0.18, 0,
              {gradientTransform: "matrix(1,0,0,1,0,0)"}
            );

            var decoration_defs = svg.defs("decorations");

            svg.linearGradient(decoration_defs, "divider",
              [
                [0.05, "#dddddd"],
                [0.95, "#cccccc"]
              ], 0, 1, 0.18, 0,
              {gradientTransform: "matrix(1,0,0,1,0,0)"}
            );
          }

          var preparePopover = function (parties) {

            var result = "";

            angular.forEach(parties, function (party) {

              if (party.positions) {
                result += "<table>";
                if (parties.length > 1) {
                  result += "<tr><td><strong>" + $translate.instant('paymentEntity.tableParty') + " "
                    + party.tableParty + "</strong></td></tr>";
                }

                var maxArticleName = party.positions.reduce(function (a, b) {
                  return a > b ? b : a;
                }, 0);

                angular.forEach(party.positions, function (position) {

                  result += "<tr><td>";

                  if (position.amount > 1) {
                    result += position.amount + "x ";
                  }
                  result += position.articleName + '</td><td style="padding-left: 5px">' + $filter("currency")(position.amount * position.price, $scope.curr.symbol);

                  result += "</td></tr>";
                });

                result += "<tr><td></td><td><b>" + $filter("currency")(party.amount, $scope.curr.symbol) + "</b></td></tr>";

                result += "</table>";
              }
            });
            return result;
          };

          var tablesWithParties = _.chain($scope.tableList)
            .groupBy('tableNumber')
            .map(function (value, key) {
              return {
                tableNumber: key,
                parties: _.map(value)
              }
            }).value();

          var colorTables = function () {

            var svg = element.parent().find('svg');

            if (svg.length > 0) {

              closeAllTables(svg[0]);

              angular.forEach(tablesWithParties, function (table) {
                openTableByID(svg[0], table.parties[0].table, preparePopover(table.parties))
              });
            } else {
              $timeout(colorTables, 500);
            }
          };


          var init = function () {

            var $svg = element.parent().find('svg');

            if ($svg.length > 0) {
              $svg.svg({
                onLoad: colorTables
              });
              appendSvgDefs($svg.svg('get'));

            } else {
              $timeout(init, 200);
            }
          };

          init();

          $scope.$watch("roomId", function () {
            $timeout(init, 500);
            getSvg($scope.roomId);
          });

          $scope.$watch("tableList", function () {
            $timeout(colorTables, 500);
          });

        }
      }
    })
    .directive('gfOrderLayout', ["$translate", "$timeout", "$http", function ($translate, $timeout) {
      return {
        restrict: "E",
        replace: true,
        require: 'ngModel',
        scope: {
          orderScreen: "=ngModel",
          selectItems: "=selectItems",
        },
        template: require('../../../../../main/angular/app/template/orderLayout.html'),
        link: function ($scope) {
          $scope.articleGroupsOpen = true;
          $scope.articlesOpen = true;
          $scope.loadedItems = [];

          $scope.articlePageUrl = '/icash/masterdata/order_screen/autocomplete/article';
          $scope.articleGroupPageUrl = '/icash/masterdata/order_screen/autocomplete/article_group';


          var calculateGridWidth = function () {
            return {"width": $scope.orderScreen.size * 100 + 10 + "px"}
          };

          var findArticle = function (type, number, findInLoaded) {
            var itemsCollection = findInLoaded ? $scope.loadedItems : $scope.selectItems;
            if (type == 1) {
              return _.find(itemsCollection.articleGroups, function (group) {
                return group.id == number;
              });
            } else {
              return _.find(itemsCollection.articles, function (articles) {
                return articles.id == number;
              });
            }
          };

          var getArticle = function (layout) {
            return findArticle(layout.cellType, layout.number);
          };

          function updateGrid() {
            if (!$scope.orderScreen) {
              return;
            }
            var prepareOrderScreen = function () {
              _.map($scope.orderScreen.layouts, function (layout) {
                layout.index = layout.row * $scope.orderScreen.size + layout.column;
                layout.article = getArticle(layout);
                var article = layout.article;
                if (!article) {
                  layout.number = 0;
                  layout.cellType = 0;
                }
              });
              _.sortBy($scope.orderScreen.layouts, 'index');

              _.map($scope.selectItems.articles, function (article) {
                if (article.articleGroup.applyColorToArticles && !article.colorCode) {
                  article.colorCode = article.articleGroup.colorCode;
                }
                if (article.articleGroup.applyImageToArticles && !article.image) {
                  article.image = article.articleGroup.image;
                }
              });
            };

            prepareOrderScreen();
            $scope.gridWidth = calculateGridWidth();
          }

          updateGrid();
          $scope.$watch("orderScreen", function () {
            updateGrid();
          });

          $scope.draggable = {
            cursorAt: {top: 40, left: 40},
            delay: 0
          };

          $scope.draggableGrid = {
            cursorAt: {top: 40, left: 40},
            delay: 0,
            start: function (event, ui) {

              var timeOutPromise;
              var body = $("body");
              body.mousemove(
                function (mouseEvent) {
                  var clientX = mouseEvent.clientX;
                  var clientY = mouseEvent.clientY;
                  var bounds = event.currentTarget.parentElement.getBoundingClientRect();
                  $(event.currentTarget).addClass("selected-opacity");
                  if (clientY < bounds.top - 45 || clientY > bounds.bottom + 45
                    || clientX < bounds.left - 45 || clientX > bounds.right + 35) {
                    if (!timeOutPromise) {
                      timeOutPromise = $timeout(function () {
                        $(event.currentTarget).draggable("option", "revert", false);
                        $(".ui-draggable-dragging").addClass("remove-opacity");
                      }, 700);
                    }
                  } else {
                    if (timeOutPromise) {
                      $timeout.cancel(timeOutPromise);
                      timeOutPromise = null;
                    }
                    $(".ui-draggable-dragging").removeClass("remove-opacity");
                    $(event.currentTarget).draggable("option", "revert", 'invalid');
                  }
                });


              body.mouseup(function () {
                if (timeOutPromise) {
                  $timeout.cancel(timeOutPromise);
                  timeOutPromise = null;
                }
              });

            },
            stop: function (event, ui) {

              $("body").off("mousemove");
              $(this).removeClass("selected-opacity");

              if ($(ui.helper[0]).hasClass("remove-opacity")) {
                var replaceableItemIndex = $(this).attr('ng-index');
                var item = $scope.orderScreen.layouts[replaceableItemIndex];
                $scope.$apply(function () {
                  $scope.deleteItem(item)
                });
              }
            }

          };

          $scope.droppable = {
            hoverClass: "hide-droppable",
            greedy: "li",
            tolerance: "pointer",
            drop: function (event, ui) {
              var replaceableItemIndex = $(this).attr('ng-index');

              var item = $scope.orderScreen.layouts[replaceableItemIndex];

              if (ui.draggable.attr('gf-item-id')) {
                item.number = ui.draggable.attr('gf-item-id');
                item.cellType = parseInt(ui.draggable.attr('gf-item-type'));
                item.article = findArticle(item.cellType, item.number, true);
              } else {
                var oldItemNumber = item.number;
                var oldItemCellTyped = item.cellType;
                var oldItemArticle = item.article;

                var oldItemIndex = ui.draggable.attr('ng-index');

                var newModel = $scope.orderScreen.layouts[oldItemIndex];

                item.number = newModel.number;
                item.cellType = newModel.cellType;
                item.article = newModel.article;

                newModel.number = oldItemNumber;
                newModel.cellType = oldItemCellTyped;
                newModel.article = oldItemArticle;
              }

            }
          };

          $scope.deleteItem = function (item) {
            item.article = undefined;
            item.cellType = 0;
            item.number = 0;
          };

          var oldColumnSize = $scope.orderScreen.size;
          $scope.$watch("orderScreen.size", function (newValue, oldValue) {
            if (newValue && (newValue != oldValue)) {
              for (var row = 0; row < $scope.orderScreen.rowSize; row++) {
                for (var column = 0; column < $scope.orderScreen.size; column++) {
                  var layout = $scope.orderScreen.layouts[row * $scope.orderScreen.size + column];
                  // need add empty cells
                  // layout.row > row - inside array, layout == undefined in the end of array, where there is not exist layouts
                  if (layout == undefined || layout.row > row) {
                    var startIndex = row * $scope.orderScreen.size + column;
                    $scope.orderScreen.layouts.splice(startIndex, 0,
                      {
                        column: column,
                        row: row,
                        article: undefined,
                        cellType: 0,
                        number: 0
                      }
                    );
                  }

                  // need delete cells
                  if (layout && (layout.row < row)) {
                    var startIndex = row * $scope.orderScreen.size + column;
                    var count = oldColumnSize - $scope.orderScreen.size;
                    $scope.orderScreen.layouts.splice(startIndex, count);
                  }

                  // if it is the last cell, but exist more, it should be deleted
                  if ((row == $scope.orderScreen.rowSize - 1)
                    && column == ($scope.orderScreen.size - 1)
                    && $scope.orderScreen.layouts[$scope.orderScreen.rowSize * $scope.orderScreen.size]) {

                    var startIndex = $scope.orderScreen.rowSize * $scope.orderScreen.size;
                    var count = $scope.orderScreen.layouts.length - startIndex;
                    $scope.orderScreen.layouts.splice(startIndex, count);
                  }

                }
              }

              updateGrid();
              calculateGridWidth();
              oldColumnSize = $scope.orderScreen.size;
            }
          });

          // save old value separately because value can became undefined and it breaks logic
          var oldRowSize = $scope.orderScreen.rowSize;
          $scope.$watch("orderScreen.rowSize", function (newValue) {
            if (newValue) {

              // add cells to the end
              if (newValue > oldRowSize) {
                for (var row = $scope.orderScreen.layouts.length / $scope.orderScreen.size; row < newValue; row++) {
                  for (var column = 0; column < $scope.orderScreen.size; column++) {
                    $scope.orderScreen.layouts.push({
                      column: column,
                      row: row,
                      article: undefined,
                      cellType: 0,
                      number: 0
                    });
                  }

                }

              }

              if (newValue < oldRowSize) {
                var startIndex = newValue * $scope.orderScreen.size;
                var count = $scope.orderScreen.layouts.length - startIndex;
                $scope.orderScreen.layouts.splice(startIndex, count);
              }

              oldRowSize = newValue;
            }
          });
        }
      }
    }
    ])

    .directive('gfInfiniteScroll', function () {
      return {
        restrict: 'A',
        scope: {
          getPage: '<'
        },
        link: function (scope, element) {
          var raw = element[0];
          element.bind('scroll', function () {
            if (raw.scrollTop + raw.offsetHeight >= raw.scrollHeight) { //at the bottom
              scope.getPage();
            }
          })
        }
      }
    })

    .component('gfPanelWithSearch', {
      template: require('../../../../../main/angular/app/template/search_panel.html'),
      bindings: {
        url: '<',
        entity: '<',
        loadedItems: '=',
        draggable: '<'
      },
      controller: function ($http) {
        var self = this;

        var pagination = {
          from: 0,
          limit: 25,
          filters: [],
          sorts: [
            {
              field: 'shortName',
              order: 'ASC'
            }
          ]
        };

        self.items = [];

        self.getEntityPage = function () {
          return $http.post(self.url, pagination)
        };

        self.$onInit = function () {
          self.getEntityPage().then(function (response) {
            self.items = response.data;
            pagination.from = pagination.from + response.data.length;
            self.loadedItems[self.entity] = response.data;
          });
        };

        self.filterItems = function (val) {
          pagination.from = 0;
          pagination.filters = [];
          if (val) {
            pagination.filters = [{
              '@class': 'de.icash.pagination.filter.Like',
              field: 'shortName',
              value: val
            }];
            if (self.entity === 'articles' && parseInt(val)) {
              pagination.filters.push({
                '@class': 'de.icash.pagination.filter.Equals',
                field: 'id',
                value: parseInt(val)
              });
            }
          }
          self.getEntityPage().then(function (response) {
            self.items = response.data;
            pagination.from = pagination.from + response.data.length;
            self.loadedItems[self.entity] = response.data;
          });
        };

        self.getNextPage = function () {
          self.getEntityPage().then(function (response) {
            self.items = self.items.concat(response.data);
            pagination.from = pagination.from + response.data.length;
            self.loadedItems[self.entity] = self.loadedItems[self.entity].concat(response.data);
          });
        }
      }
    })

    .directive('gfHighChart', ["$translate", "$filter", "$timeout", function ($translate, $filter, $timeout) {
      return {
        restrict: "E",
        replace: true,
        scope: {
          "ngModel": "=ngModel",
          "type": "=type",
          "from": "=from",
          "to": "=to",
          "status": "=status",
          "previousData": "=previousData",
          "showPrevious": "=showPrevious",
          "statusPrevious": "=statusPrevious"
        },
        template: require('../../template/chart.html'),
        link: function ($scope) {

          var currentChartType = '';
          var savedPrevious;

          $scope.showPrevious = $scope.showPrevious ? $scope.showPrevious : false;

          // wait and update main chart
          $scope.$watch('ngModel', function (data) {
            // check if data exist
            if (!data || data.length === 0) {
              return;
            }

            $scope.data = data;

            var minMain;
            var maxMain;
            if ($scope.chart) {
              minMain = $scope.chart.xAxis[0].min;
              maxMain = $scope.chart.xAxis[0].max;
              setInitialSeriesVisibility($scope.data, $scope.chart.series);
            }

            if (!$scope.from && (data[0] && !!data[0].data)) {
              $scope.from = data[0].data[0][0];
            }
            if (!$scope.to && (data[0] && !!data[0].data)) {
              $scope.to = data[0].data[data[0].data.length - 1][0]
            }

            $scope.chartConfig = drawChart($scope.data, $scope.from, $scope.to, minMain, maxMain, -1, true, '', false);
          });

          function setInitialSeriesVisibility(data, series) {
            var oldSeriesVisibility = getOldSeriesSelectedStatuses(series);

            _.map(data, function (item) {
              var find = _.find(oldSeriesVisibility, function (oldItem) {
                return oldItem.name === item.name;
              });
              item.visible = find ? find.visible : true;
            });
          }

          function getOldSeriesSelectedStatuses(series) {
            var result = [];

            _.each(series, function (item) {
              result.push({
                name: item.name,
                visible: item.visible
              });
            });

            return result;
          }

          // wait and update previous period chart
          $scope.$watch('previousData', function (data) {
            if (!data || !data.length) {
              return;
            }

            savedPrevious = angular.copy(data);
            $scope.previousDataInner = calculateDelta($scope.data, savedPrevious);
            $scope.delta = true;

            var length = $scope.previousDataInner[0].data.length;

            $scope.prevFrom = $scope.previousDataInner[0].data[0][0];

            $scope.prevTo = $scope.previousDataInner[0].data[length - 1][0];

            $scope.zoomCorrection = $scope.from - $scope.prevFrom;

            var minMain = $scope.chart.xAxis[0].min - $scope.zoomCorrection;
            var maxMain = $scope.chart.xAxis[0].max - $scope.zoomCorrection;
            setInitialSeriesVisibility($scope.previousDataInner, $scope.chart.series);

            $scope.chartConfigPrevious = drawChart($scope.previousDataInner, $scope.prevFrom, $scope.prevTo, minMain, maxMain, 1, false, $translate.instant('chart.previousPeriod'), true);
          });

          function calculateDelta(data1, data2) {
            var result = [];
            _.map(data1, function (item) {
              var oldItem = _.find(data2, function (oldItem) {
                return item.name === oldItem.name;
              });

              var deltaItem = oldItem ? angular.copy(oldItem) : angular.copy(data2[0]);
              if (oldItem) {
                _.forEach(deltaItem.data, function (dataItem, index) {
                  dataItem[1] = item.data[index][1] - dataItem[1];
                });
              } else {
                _.forEach(deltaItem.data, function (dataItem, index) {
                  dataItem[1] = item.data[index][1];
                });

                deltaItem.name = item.name;
              }

              result.push(deltaItem);

            });

            return result;
          }

          function drawChart(data, min, max, minCurrent, maxCurrent, zoomCorrection, needButtons, title, previousChart) {

            // timeline charts
            if ($scope.type === "stack" && data && data.length > 0) {
              // select the type ot timeline chart
              if (currentChartType === "line") {
                return drawLineChart(data, min, max, minCurrent, maxCurrent, zoomCorrection, needButtons, title, previousChart);
              } else if (currentChartType === "area") {
                return drawAreaChart(data, min, max, minCurrent, maxCurrent, zoomCorrection, needButtons, title, previousChart);
              } else {
                return drawStackChart(data, min, max, minCurrent, maxCurrent, zoomCorrection, needButtons, title, previousChart);
              }
            }

            // pie chart
            if ($scope.type === "pie" && $scope.data && $scope.data.length > 0) {
              return drawPieChart(data);
            }

            // sunburst
            if ($scope.type === 'sunburst' && data.payload.data) {
              return drawSunburstChart(data.payload.data);
            }

          }

          var drawStackChart = function (data, min, max, minCurrent, maxCurrent, zoomCorrection, needButtons, title, previousChart) {
            var areaPlotOptions = {
              column: {
                stacking: 'normal',
                pointPadding: 0,
                groupPadding: 0
              }
            };
            var buttons = needButtons ? {
              lineChartButton: {
                theme: {
                  style: {font: 'normal normal normal 14px/1 FontAwesome'}
                },
                text: '\uf201',
                onclick: function () {
                  currentChartType = "line";
                  switchChartType();
                },
                _titleKey: 'line_key'
              },
              areaChartButton: {
                theme: {
                  style: {font: 'normal normal normal 14px/1 FontAwesome'}
                },
                text: '\uf1fe',
                onclick: function () {
                  currentChartType = "area";
                  switchChartType();
                },
                _titleKey: 'area_key'
              }
            } : !$scope.delta ? {
              stackChartButton: {
                theme: {
                  style: {font: 'normal normal normal 15px/1 FontAwesome'}
                },
                text: '\u0394 ',
                onclick: function () {
                  switchDataType();
                },
                _titleKey: 'delta_key'
              }
            } : {
              stackChartButton: {
                theme: {
                  style: {font: 'normal normal normal 15px/1 FontAwesome'}
                },
                text: 'Abs',
                onclick: function () {
                  switchDataType();
                },
                _titleKey: 'absolute_key'
              }
            };

            // set stacking property
            // workaround for highchart bug
            _.map(data, function (item) {
              item.stacking = 'normal';
              item.stack = "stack_1";
              return item;
            });

            return drawTimeLineChart('column', areaPlotOptions, buttons, data, min, max, minCurrent, maxCurrent, zoomCorrection, title, previousChart);
          };

          function switchChartType() {
            // reset y levels before redraw charts

            setInitialSeriesVisibility($scope.data, $scope.chart.series);
            var minMain = $scope.chart.xAxis[0].min;
            var maxMain = $scope.chart.xAxis[0].max;
            $scope.chartConfig = drawChart($scope.data, $scope.from, $scope.to, minMain, maxMain, -1, true, '', false);
            $scope.chart.redraw();
            if ($scope.chartPrevious) {
              setInitialSeriesVisibility($scope.previousDataInner, $scope.chartPrevious.series);
              var minPrev = $scope.chartPrevious.xAxis[0].min;
              var maxPrev = $scope.chartPrevious.xAxis[0].max;
              $scope.chartConfigPrevious = drawChart($scope.previousDataInner, $scope.prevFrom, $scope.prevTo, minPrev, maxPrev, 1, false, $translate.instant('chart.previousPeriod'), true);
              $scope.chartPrevious.redraw();
            }
          }

          function switchDataType() {
            var temp = angular.copy($scope.previousDataInner);
            $scope.previousDataInner = angular.copy(savedPrevious);
            savedPrevious = temp;
            $scope.delta = !$scope.delta;

            setInitialSeriesVisibility($scope.previousDataInner, $scope.chart.series);

            // save zoom parameters
            var minPrev = $scope.chartPrevious.xAxis[0].min;
            var maxPrev = $scope.chartPrevious.xAxis[0].max;

            $scope.chartConfigPrevious = drawChart($scope.previousDataInner, $scope.prevFrom, $scope.prevTo, minPrev, maxPrev, 1, false, $translate.instant('chart.previousPeriod'), true);
            $scope.chartPrevious.redraw();
          }

          var drawLineChart = function (data, min, max, minCurrent, maxCurrent, zoomCorrection, needButtons, title, previousChart) {

            var buttons = needButtons ? {
              lineChartButton: {
                theme: {
                  style: {font: 'normal normal normal 14px/1 FontAwesome'}
                },
                text: '\uf1fe',
                onclick: function () {
                  currentChartType = "area";
                  switchChartType();
                },
                _titleKey: 'area_key'
              },
              areaChartButton: {
                theme: {
                  style: {font: 'normal normal normal 14px/1 FontAwesome'}
                },
                text: '\uf080',
                onclick: function () {
                  currentChartType = "";
                  switchChartType();
                },
                _titleKey: 'stack_key'
              }
            } : !$scope.delta ? {
              stackChartButton: {
                theme: {
                  style: {font: 'normal normal normal 15px/1 FontAwesome'}
                },
                text: '\u0394 ',
                onclick: function () {
                  switchDataType();
                },
                _titleKey: 'delta_key'
              }
            } : {
              stackChartButton: {
                theme: {
                  style: {font: 'normal normal normal 15px/1 FontAwesome'}
                },
                text: 'Abs',
                onclick: function () {
                  switchDataType();
                },
                _titleKey: 'absolute_key'
              }
            };

            // reset stacking property for line chart
            // workaround for highchart bug
            _.map(data, function (item) {
              item.stacking = '';
              item.stack = "stack_1";
              return item;
            });

            return drawTimeLineChart('line', {}, buttons, data, min, max, minCurrent, maxCurrent, zoomCorrection, title, previousChart);
          };

          var drawAreaChart = function (data, min, max, minCurrent, maxCurrent, zoomCorrection, needButtons, title, previousChart) {
            var areaPlotOptions = {};

            var buttons = needButtons ? {
              lineChartButton: {
                theme: {
                  style: {font: 'normal normal normal 14px/1 FontAwesome'}
                },
                text: '\uf201',
                onclick: function () {
                  currentChartType = "line";
                  switchChartType();
                },
                _titleKey: 'line_key'
              },
              stackChartButton: {
                theme: {
                  style: {font: 'normal normal normal 14px/1 FontAwesome'}
                },
                text: '\uf080',
                onclick: function () {
                  currentChartType = "";
                  switchChartType();
                },
                _titleKey: 'stack_key'
              }
            } : !$scope.delta ? {
              stackChartButton: {
                theme: {
                  style: {font: 'normal normal normal 15px/1 FontAwesome'}
                },
                text: '\u0394 ',
                onclick: function () {
                  switchDataType();
                },
                _titleKey: 'delta_key'
              }
            } : {
              stackChartButton: {
                theme: {
                  style: {font: 'normal normal normal 15px/1 FontAwesome'}
                },
                text: 'Abs',
                onclick: function () {
                  switchDataType();
                },
                _titleKey: 'absolute_key'
              }
            };

            // set stacking property
            // workaround for highchart bug (0 - break stack)
            _.map(data, function (item) {
              item.stacking = 'normal';
              item.stack = "stack_1";
              return item;
            });

            return drawTimeLineChart('area', areaPlotOptions, buttons, data, min, max, minCurrent, maxCurrent, zoomCorrection, title, previousChart);
          };

          function drawTimeLineChart(chartType, plotOptions, buttons, data, min, max, minCurrent, maxCurrent, zoomCorrection, title, previousChart) {
            var symbol = data[0].name.split('<curr>')[1];

            var calculateTotal = function (series) {
              return _.reduce(series, function (memo, serie) {

                var add = !serie.visible ? 0 : getSeriesTotal(serie);

                return memo + add;
              }, 0);
            };


            // Total recalculation and updating during hiding/showing items
            var recalculateTotal = function (serie) {
              var lineTotal = getSeriesTotal(serie);
              serie.chart.total += serie.visible ? -lineTotal : lineTotal;
              serie.chart.totalLabel.textStr = getTotalLabelText(serie.chart.total, symbol);
              serie.chart.totalLabel.add();
            };

            function selectSerie(chart, selectedSerie) {
              var series = chart.series;
              var otherSeries = _.find(series, function (item) {
                return selectedSerie.name === item.name;
              });
              if (otherSeries) {
                // do it before change state
                chart.recalculateTotal(otherSeries);
                if (otherSeries.visible) {
                  otherSeries.hide();
                } else {
                  otherSeries.show();
                }
              }
            }

            angular.extend(plotOptions,
              {
                series: {
                  events: {
                    legendItemClick: function (event) {
                      var selectedItem = event.target;
                      recalculateTotal(selectedItem);
                      if ($scope.chartPrevious) {
                        var otherChart = $scope.chart === this.chart ? $scope.chartPrevious : $scope.chart;
                        selectSerie(otherChart, selectedItem);
                      }
                    }
                  },
                  // disable hover marker - ICR-10193
                  marker: {states: {hover: {enabled: false}}}
                }
              });

            // Series total calculation
            function getSeriesTotal(serie) {
              var min = serie.xAxis.min;
              var max = serie.xAxis.max;

              var total = 0;
              for (var i = 0; i < serie.xData.length; i++) {
                total += ((!min || serie.xData[i] >= min) && (!max || serie.xData[i] <= max)) ? serie.yData[i] : 0;
              }
              return total;
            }

            var redrawTotalLabel = function (chart) {
              $timeout(function () {
                if (!chart || !chart.legend || !chart.legend.group) {
                  return;
                }

                var legend = chart.legend;
                var group = legend.group;
                if (chart.totalLabel) {
                  chart.totalLabel.destroy();
                }
                chart.totalLabel = chart.renderer.label(getTotalLabelText(chart.total, symbol),
                  group.translateX + 26,
                  group.translateY + legend.legendHeight,
                  null, null, null, true
                );
                chart.totalLabel.add();

              }, 0);
            };

            function syncXExtremesAfterZoom(e) {
              // No corrections if exist only one chart or synchronize previous first time
              if (!$scope.chartPrevious || e.stopZoomUpdate) {
                return;
              }

              var thisChart = this.chart;

              _.each([$scope.chart, $scope.chartPrevious], function (chart) {
                if (chart.xAxis[0].setExtremes) {

                  var min = e.min ? e.min + zoomCorrection * $scope.zoomCorrection : e.min;
                  var max = e.max ? e.max + zoomCorrection * $scope.zoomCorrection : e.max;

                  if (chart !== thisChart) {
                    chart.xAxis[0].setExtremes(min, max, true, true, {stopZoomUpdate: true});
                  }
                  if (min && max) {
                    chart.showResetZoom();
                  } else if (!!chart.resetZoomButton) {
                    chart.resetZoomButton.hide();
                  }
                }
              });
            }

            if ($scope.chart) {
              $scope.chart.options.exporting.buttons = {};
            }
            buttons.contextButton = {enabled: false};

            return {

              chart: {
                type: chartType,
                zoomType: 'x',
                events: {
                  redraw: function () {
                    this.total = calculateTotal(this.series);
                    redrawTotalLabel(this);
                  },
                  load: function () {

                    // todo calculation for months with different amount of days
                    if ((minCurrent || maxCurrent) && (minCurrent !== min || maxCurrent !== max)) {
                      this.showResetZoom();
                    }

                    // zoom to current position
                    this.xAxis[0].setExtremes(minCurrent, maxCurrent, true, true, {stopZoomUpdate: true});
                    this.yAxis[0].setExtremes(null, null, true, false, {stopZoomUpdate: true});

                    this.zoomOut();

                    this.recalculateTotal = recalculateTotal;
                    if (zoomCorrection < 0) {
                      $scope.chart = this;
                    } else {
                      $scope.chartPrevious = this;
                    }

                    // need correction for previous period
                    if (!$scope.delta && zoomCorrection > 0) {
                      $timeout(function () {
                        updateYMax(undefined, this);
                      }, 100);
                    }
                  }
                },
                style: {
                  width: '100%',
                  height: '100%'
                }
              },
              plotOptions: plotOptions,
              legend: {
                layout: 'vertical',
                y: 30,
                align: 'right',
                verticalAlign: 'top',
                floating: false,
                backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false,
                useHTML: true,
                labelFormatter: function () {
                  return getLegendLabel(this, getSeriesTotal(this));
                }
              },
              credits: {
                enabled: false
              },
              exporting: {
                allowHTML: true,
                buttons: buttons
              },
              tooltip: {
                formatter: function () {
                  var date = new Date(this.x);
                  var labels = this.series.name.split('<curr>');
                  return '<b>' + $filter("date")(date, 'shortDate') + '</b><br/>' +
                    _.escape(labels[0]) + ': ' + $filter("currency")(this.y, labels[1]) + '<br/>';
                }
              },
              lang: {
                //buttons tooltips
                line_key: $translate.instant('chart.title.line'),
                area_key: $translate.instant('chart.title.stackedArea'),
                stack_key: $translate.instant('chart.title.stackedColumn'),
                delta_key: $translate.instant('chart.title.delta'),
                absolute_key: $translate.instant('chart.title.absolute')
              },
              xAxis: {
                type: 'datetime',
                min: min,
                max: max,
                minPadding: 0,
                dateTimeLabelFormats: {
                  day: '%a %e'
                },
                events: {
                  setExtremes: syncXExtremesAfterZoom,
                  afterSetExtremes: function () {
                    var chart = this.chart;

                    // hide current tooltip - ICR-10193
                    chart.tooltip.hide(1);

                    // recalculation totals after zooming
                    var legend = chart.legend;

                    var sum = 0;

                    for (var i in legend.allItems) {
                      var item = legend.allItems[i];
                      var seriesTotal = getSeriesTotal(item);

                      if (item.visible) {
                        sum += seriesTotal;
                      }

                      var newText = getLegendLabel(item, seriesTotal);
                      item.legendItem.attr({text: newText})
                    }

                    chart.total = sum;
                    redrawTotalLabel(chart);

                    if (!$scope.delta) {
                      updateYMax(this.chart);
                    }
                  }
                }
              },
              yAxis: {
                title: {
                  text: ''
                }
              },
              title: {
                text: title,
                align: 'left'
              },
              series: data
            };
          }

          // correction Y max after zooming or add previous period chart
          function updateYMax(chart, previousChart) {
            if (!$scope.chartConfigPrevious && !previousChart) {
              return;
            }

            previousChart = previousChart || $scope.chartPrevious;

            if (chart) {
              chart.afterExtreameSet = true;

              if (!$scope.chart.afterExtreameSet || !previousChart.afterExtreameSet) {
                return;
              }
            }

            var maxLevel = $scope.chart.yAxis[0].dataMax > previousChart.yAxis[0].dataMax
              ? $scope.chart.yAxis[0].dataMax
              : previousChart.yAxis[0].dataMax;

            var max;

            if ($scope.chart.yAxis[0].max > previousChart.yAxis[0].max) {
              max = maxLevel < previousChart.yAxis[0].max
                ? previousChart.yAxis[0].max
                : $scope.chart.yAxis[0].max;
            } else if ($scope.chart.yAxis[0].max < previousChart.yAxis[0].max) {
              max = maxLevel < $scope.chart.yAxis[0].max
                ? $scope.chart.yAxis[0].max
                : previousChart.yAxis[0].max;
            } else {
              max = $scope.chart.yAxis[0].max;
            }

            previousChart.yAxis[0].setExtremes(0, max, true, false, {stopZoomUpdate: true});
            $scope.chart.yAxis[0].setExtremes(0, max, true, false, {stopZoomUpdate: true});

            $scope.chart.afterExtreameSet = false;
            previousChart.afterExtreameSet = false;
          }

          var drawPieChart = function () {
            // calculation total
            $scope.totalLabel;
            $scope.changed = true;
            $scope.total = _.reduce($scope.data, function (memo, item) {
              return memo + item.y;
            }, 0);

            // Create other section
            var threshold = $scope.total * 0.025;

            var newData = [];
            var other = 0.0;
            var otherCount = 0;
            for (var item in $scope.data) {

              if ($scope.data[item].y < threshold) {
                other += $scope.data[item].y;
                otherCount++;
              } else {
                newData.push($scope.data[item]);
              }
            }
            var symbol = $scope.data[0].name.split('<curr>')[1];
            if (otherCount > 0 && other > 0) {
              newData.push({
                y: other,
                name: $translate.instant('jsp.other') + '<curr> ' + symbol,
                id: 0
              });
            }

            return {

              chart: {
                type: 'pie',
                options3d: {
                  enabled: true,
                  alpha: 45,
                  beta: 0
                },
                events: {
                  redraw: function () {
                    // update total after show/hide item
                    var chart1 = this;
                    var legend = chart1.legend;
                    var group = legend.group;
                    if ($scope.changed) {
                      $scope.changed = false;
                      if ($scope.totalLabel) {
                        $scope.totalLabel.destroy();
                      }
                      $scope.totalLabel = chart1.renderer.label(getTotalLabelText($scope.total, symbol),
                        group.translateX + 26,
                        group.translateY + legend.legendHeight,
                        null, null, null, true
                      );
                      $scope.totalLabel.add();
                    }
                  },
                  load: function () {
                    $scope.changed = true;
                    this.redraw();
                  }
                }
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  depth: 35,
                  dataLabels: {
                    enabled: false
                  },
                  showInLegend: true,
                  point: {
                    events: {
                      legendItemClick: function (event) {
                        // recalculate total
                        $scope.total += event.target.visible ? -event.target.y : event.target.y;
                        $scope.changed = true;
                      }
                    }
                  }
                }
              },
              legend: {
                layout: 'vertical',
                y: 30,
                align: 'right',
                verticalAlign: 'top',
                floating: false,
                backgroundColor: (Highcharts.theme && Highcharts.theme.background2) || 'white',
                borderColor: '#CCC',
                borderWidth: 1,
                shadow: false,
                useHTML: true,
                labelFormatter: function () {
                  return getLegendLabel(this, this.y);
                }
              },
              credits: {
                enabled: false
              },
              exporting: {
                allowHTML: true,
                enabled: false
              },
              tooltip: {
                headerFormat: '',
                pointFormatter: function () {
                  var labels = this.name.split('<curr>');
                  return '<span style="">' + _.escape(labels[0]) + '</span>: <b>' + $filter("currency")(this.y, labels[1]) + '</b>';
                }
              },


              title: {
                text: ''
              },
              series: [
                {
                  name: 'Value',
                  colorByPoint: true,
                  data: newData,
                  animation: false
                }
              ]
            };
          };

          var drawSunburstChart = function (data) {
            var sunburstData = [];

            var prepareSunburstData = function (data, parent) {
              return _.forEach(data, function (d) {
                d.parent = parent;
                if (!_.find(sunburstData, {'id': d.id})) {
                  d.id = d.id.toString();
                  sunburstData.push(d);
                }
                if (d.children) {
                  prepareSunburstData(d.children, d.id);
                }
              })
            };

            prepareSunburstData(data, '');

            return {
              chart: {
                height: '100%'
              },
              title: {
                text: ''
              },
              credits: {
                enabled: false
              },
              navigation: {
                buttonOptions: {
                  enabled: false
                }
              },
              series: [{
                type: 'sunburst',
                data: sunburstData,
                allowDrillToNode: true,
                cursor: 'pointer',
                dataLabels: {
                  format: '{point.name} <br> {point.value}',
                  filter: {
                    property: 'innerArcLength',
                    operator: '>',
                    value: 16
                  }
                },
                levels: [{
                  level: 1,
                  levelIsConstant: false,
                  dataLabels: {
                    rotationMode: 'parallel',
                    filter: {
                      property: 'outerArcLength',
                      operator: '>',
                      value: 64
                    }
                  }
                }, {
                  level: 2,
                  colorByPoint: true,
                  dataLabels: {
                    rotationMode: 'parallel'
                  }
                }, {
                  level: 3,
                  colorVariation: {
                    key: 'brightness',
                    to: -0.5
                  }
                }, {
                  level: 4,
                  colorVariation: {
                    key: 'brightness',
                    to: 0.5
                  }
                }]
              }]
            }
          };

          function getTotalLabelText(total, symbol) {
            return '<div class="chart-legend-container">'
              + '<span><div class="chart-legend-text-cut">' + $translate.instant('chart.total') + '</div></span>'
              + '<span class="pull-right chart-legend-value">&nbsp;' + $filter("currency")(total, symbol) + '</span>'
              + '</div>';
          }

          var getLegendLabel = function (series, total) {
            var labels = series.name.split('<curr>');

            return '<div class="chart-legend-container">'
              + '<span title="' + _.escape(labels[0]) + ' "><div class="chart-legend-text-cut">' + _.escape(labels[0]) + '</div></span>'
              + '<span class="pull-right chart-legend-value">&nbsp;' + $filter("currency")(total, labels[1]) + '</span>'
              + '</div>';
          }

        }
      }
    }])
    /**
     * Directive for adding to input clear button.
     * Work correctly with input which "display: block;" or "width: 100%"
     * Clear all input margin in css ".gf-clear-wrapper input {margin: 0 !important;}"
     *
     * todo add property to define input type (block or inline-block) and move style attr from input to wrapper
     */
    .directive('gfClearableInput', ['$compile', '$filter', function ($compile, $filter) {
      return {
        restrict: "A",
        require: 'ngModel',
        scope: {},

        link: function ($scope, element, attrs, modelCtrl) {
          var wrapper = $compile('<div class="gf-clear-wrapper"></div>')($scope);
          var clearTranslate = $filter('translate')('button.clear');
          var button = $compile('<button type="button" title="' + clearTranslate + '" ng-click="clear($event)" ng-show="isNotEmpty()"><i class="fa fa-times"></i></button>')($scope);
          element.wrap(wrapper);
          element.after(button);

          $scope.clear = function ($event) {
            modelCtrl.$setViewValue('');
            modelCtrl.$render();
            // event should not close dropdown or something else
            $event.stopPropagation()
          };

          $scope.isNotEmpty = function () {
            return !modelCtrl.$isEmpty(element.val());
          };

        }
      }
    }])
    .directive('percentInput', function () {
      return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModel) {
          ngModel.$parsers.unshift(
            function (viewValue) {
              var perc = parseFloat(viewValue);
              if (perc < 0 || !isFinite(perc)) {
                return null;
              }
              return perc / 100;
            }
          );
          ngModel.$formatters.unshift(
            function (modelValue) {
              if (!isFinite(modelValue)) {
                return "";
              }
              return _.round(modelValue * 100, 2);
            }
          );
        }

      }
    })
    // format money input to show with coins
    //todo should depend on restaurant currency
    .directive('numberInput', function () {
      return {
        require: '?ngModel',
        link: function (scope, elem, attrs, ngModel) {
          if (!ngModel || !attrs.step) return;

          var decimalPart = attrs.step.split('.')[1].length;

          function formatNumber(modelValue) {
            return (+modelValue).toFixed(decimalPart);
          }

          ngModel.$formatters.unshift(function () {
            return formatNumber(ngModel.$modelValue);
          });

          elem.bind('blur', function () {
            var value = ngModel.$viewValue;
            if (_.isUndefined(value)) {
              value = 0;
            }
            ngModel.$viewValue = formatNumber(value);
            ngModel.$commitViewValue();
            ngModel.$render();
          });
        }
      }
    })
    .directive('ngDropdownMultiselectDisabled', function () {
      return {
        restrict: 'A',
        controller: ['$scope', '$element', '$attrs', function ($scope, $element, $attrs) {
          var $btn;
          $btn = $element.find('button');
          return $scope.$watch($attrs.ngDropdownMultiselectDisabled, function (newVal) {
            return $btn.attr('disabled', newVal);
          });
        }]
      };
    })
    .directive("disableDirective", function (securityService) {
      if (securityService.context.userTourEnabled) {
        return;
      }

      function compile(el, attr) {
        var hasDirective = el[0].querySelectorAll("[" + attr.disableDirective + "]");

        [].forEach.call(hasDirective, function (el) {
          el.removeAttribute(attr.disableDirective);
        });
      }

      return {
        priority: 100000,
        compile: compile
      };
    })
    .directive('datepickerDateString', function (moment) {
      return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
          ngModel.$parsers.push(function toModel(input) {
            return moment(input).format("YYYY-MM-DDTHH:mm:ss");
          });
        }
      }
    })

    .directive("gfOverlapPeriods", function () {
      return {
        require: 'gfGrid',
        priority: 100,
        link: function ($scope, element, attributes, ctrl) {

          var gridData = [];

          function prepareDataAndFindOverlap(newGridData) {
            gridData = _.filter(newGridData, function (v) {
              return !_.isNil(v.id);
            });
            $scope.$ctrl.isOverlapping = findOverlappingRanges(gridData);
          }

          function checkOverlap(range, nextRange) {
            return (range.timeEnd > nextRange.timeStart
              || (range.timeStart > range.timeEnd && nextRange.timeStart < nextRange.timeEnd)
              || (range.timeStart > range.timeEnd && nextRange.timeStart > nextRange.timeEnd))
              && checkDays(range, nextRange);
          }

          function checkDays(range, nextRange) {
            var daysOfWeek = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            var rangeDays = _.pick(range, daysOfWeek);
            var res = _.reduce(rangeDays, function (result, value, key) {
              return !_.isEqual(value, nextRange[key]) ?
                result : value ? result.concat(key) : result;
            }, []);
            return res.length > 0;
          }

          function findOverlappingRanges(gridData) {
            var sortedByTime = _.orderBy(gridData, ['timeStart'], ['asc']);
            for (var i = 0; i < sortedByTime.length - 1; i++) {
              if (checkOverlap(sortedByTime[i], sortedByTime[i + 1])) {
                return true;
              }
            }
            return false;
          }

          $scope.$watch(function () {
            return ctrl.gridOptions.data;
          }, function (newValue) {
            if (newValue.length > 0) {
              prepareDataAndFindOverlap(newValue);
            }
          });

          $scope.$on('gfSaveRow', function (event, opt) {
            var index = _.findIndex(gridData, {id: opt.newEntity.id});

            // if index < 0 then a new entity added in grid, otherwise - an existing entity is edited
            index < 0 ? gridData.push(opt.newEntity) : gridData.splice(index, 1, opt.newEntity);

            $scope.$ctrl.isOverlapping = findOverlappingRanges(gridData);
          });

          $scope.$on('deleteEntity', function (event, opt) {
            prepareDataAndFindOverlap(opt.gridData);
          });
        }
      };
    });
})();
