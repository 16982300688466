import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import {Box, Divider, Paper} from '@material-ui/core';
import SettledTransactionsTable from './SettledTransactionsTable';
import SettlementSummaryTable from './SettlementSummaryTable';
import axios from "axios";
import {useTranslation} from "react-i18next";
import BreakdownElement from "./BreakdownElement";
import Loader from "../common-components/Loader";
import Currency from "./Currency";
import PayoutStatus, {getPayoutStatus} from "./PayoutStatus";
import {getAlertMessage} from "./Alert";
import {Alert, AlertTitle} from "@material-ui/lab";

function SettlementDetails({id, onGoBack, goToDetails}) {
  const {t} = useTranslation();

  const [loading, setLoading] = useState({});
  const [data, setData] = useState({});
  const [transactions, setTransactions] = useState({});
  const [transactionsByDate, setTransactionsByDate] = useState({});
  const [isError, setIsError] = useState(true);
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    setLoading(true);
    axios.post('/icash/ls_payments/settlements/' + id, {
      include: ['payoutTransactions'],
    }).then(resp => {
      setData(normalizeData(resp.data.data));
      var transactions = getTransactions(resp.data.included);
      setTransactions(transactions);
      setTransactionsByDate(normalizeTransactionsByDate(transactions.data));
      setIsError(false);
      setLoading(false);
    }, error => {
      setIsError(true);
      var message = getAlertMessage(error);
      setErrorMessage(t(message));
      setLoading(false);
    });
  }, [id]);

  const getTransactionDateRange = () => {
    var from = _.head(transactionsByDate).date;
    var to = _.last(transactionsByDate).date;

    if (moment(from).isSame(to, 'day')) {
      return moment(from).format('ll')
    }
    return moment(from).format('ll') + ' - ' + moment(to).format('ll')
  }

  return <>
    {loading
      ? <Loader/>
      :
      <Grid container spacing={4} direction={'column'}>
        <Grid item>
          <Button
            onClick={() => onGoBack()}
            startIcon={<ArrowBackIosIcon/>}>Back to settlements
            transactions</Button>
        </Grid>
        {isError
          ?
          <Grid item>
            <Alert severity="error">
              <AlertTitle>{t('validation.title.error')}</AlertTitle>
              {errorMessage}
            </Alert>
          </Grid>
          : <>
            <Grid item>
              <Typography variant="h6">
                <Box display="inline">
                  Settlement summary for {moment(data?.payoutDate).format('lll')}
                </Box>
                <Box display="inline" p={1}>
                  <PayoutStatus status={getPayoutStatus(data?.status)}/>
                </Box>
              </Typography>
              <Typography variant="subtitle2">
                {t('lsp.fullBreakdown')}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="h6" gutterBottom>
                    {t('lsp.settlementDetails.summary')}
                  </Typography>
                  <Typography variant="subtitle2">
                    {t('lsp.settlementDetails.summary.details')}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid container direction={'column'} spacing={3}>
                    <Grid item>
                      <Paper variant={'outlined'} elevation={0}>
                        <Grid container spacing={1}>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Grid container direction={'column'} spacing={3}>
                                <Grid item>
                                  <BreakdownElement field={'lsp.status'}
                                                    value={t(getPayoutStatus(data?.status)?.name)}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.settlementDetails.date'}
                                    value={moment(data?.payoutDate).format('lll')}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.settlementDetails.totalDeposits'}
                                    value={<Currency value={Math.abs(data?.amountNet)} currency={data?.currency}/>}/>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                          <Divider orientation={'vertical'} flexItem
                                   style={{marginRight: "-1px"}}/>
                          <Grid item xs={8}>
                            <Box p={2}>
                              <Grid container direction={'column'} spacing={3}>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.settlementDetails.dateRange'}
                                    value={getTransactionDateRange()}/>
                                </Grid>
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.settlementDetails.transactionsIncluded'}
                                    value={transactions?.data?.length}/>
                                </Grid>
                                {/*<Grid item>*/}
                                {/*  <BreakdownElement*/}
                                {/*    field={'lsp.settlementDetails.bankAccount'}*/}
                                {/*    value={'???'}/>*/}
                                {/*</Grid>*/}
                                <Grid item>
                                  <BreakdownElement
                                    field={'lsp.settlementDetails.settlementId'}
                                    value={data?.id}/>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <SettlementSummaryTable data={transactionsByDate}/>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Divider/>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <Typography variant="h6" gutterBottom>
                    {t('lsp.settlementDetails.settledTransactions')}
                  </Typography>
                  <Typography variant="subtitle2">
                    {t('lsp.settlementDetails.settledTransactions.details')}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <SettledTransactionsTable id={id} goToDetails={goToDetails} data={transactions?.data}/>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      </Grid>
    }
  </>
}

export default SettlementDetails;

function normalizeData(data) {
  return {
    id: getField(data.id),
    payoutDate: getField(data.attributes.payoutDate),
    processor: getField(data.attributes.processor),
    partner: getField(data.attributes.partner),
    processorReference: getField(data.attributes.processorMerchantReference),
    processorMerchantReference: getField(
      data.attributes.processorMerchantReference),
    status: getField(data.attributes.status),
    amountGross: data.attributes.amountGross,
    amountFees: data.attributes.amountFees,
    amountNet: data.attributes.amountNet,
    amountCredit: data.attributes.amountCredit,
    amountDebit: data.attributes.amountDebit,
    currency: getField(data.attributes.currency),
  }
}

function getTransactions(included) {
  return normalizeTransactions(_.filter(included, function (i) {
    return i.type === "transactions";
  }));
}

function normalizeTransactions(transactions) {
  if (_.isEmpty(transactions)) {
    return {
      data: []
    };
  }
  return {
    from: _.head(transactions).attributes.transactionDate,
    to: _.last(transactions).attributes.transactionDate,
    data: transactions.map(el => {
        return {
          id: el.id,
          transactionDate: el.attributes.transactionDate,
          card: el.attributes.cardLastFourDigits,
          cardType: el.attributes.cardType,
          type: el.attributes.type,
          status: el.attributes.status,
          payoutId: el.attributes.payoutId,
          paymentLink: el.links.self,
          currency: el.attributes.currency,
          amountFees: Number(el.attributes.amountFees) || 0,
          amountGross: Number(el.attributes.amountGross) || 0,
          amountNet: Number(el.attributes.amountNet) || 0
        }
      }
    )
  }
}

function normalizeTransactionsByDate(transactions) {
  if (_.isEmpty(transactions)) {
    return [];
  }

  var result = _.chain(transactions)
    // Group the elements of Array based on `transactionDate` property
    .groupBy(function (t) {
      return moment(t.transactionDate).format('LL')
    })
    // `key` is group's name (transactionDate), `value` is the array of objects
    .map((transactionsByDate, date) => {
      return {
        date: date,
        amount: _.sumBy(transactionsByDate, tr => getGross(tr)),
        fees: -1 * _.sumBy(transactionsByDate, 'amountFees'),
        netDeposits: _.sumBy(transactionsByDate, 'amountNet'),
        refunds: _.sumBy(transactionsByDate, tr => getRefunds(tr)),
        currency: _.uniq(_.map(transactionsByDate, 'currency'))[0],
        details: _.chain(transactionsByDate).groupBy("cardType").map((value, type) => {
          return {
            cardType: type,
            amountGross: _.sumBy(value, tr => getGross(tr)),
            amountFees: -1 * _.sumBy(value, 'amountFees'),
            amountNet: _.sumBy(value, 'amountNet'),
            amountRefunds: _.sumBy(value, tr => getRefunds(tr)),
          }
        }).value()
      }
    })
    .value()

  return _.orderBy(result, tr => moment(tr.date), 'asc');
}

function getGross(transaction) {
  return transaction.type !== 'refund' ? Math.abs(transaction.amountGross) : 0;
}

function getRefunds(transaction) {
  return transaction.type === 'refund' ? -Math.abs(transaction.amountGross) : 0;
}

function getField(field) {
  return field || 'N/A';
}
