(function () {
  'use strict';
  angular.module('gf.mainMenu', ['ui.router', 'gf.menuConf'])

    .run(function ($templateCache) {
      $templateCache.put('app/template/dropdown_menu.html', require('../template/dropdown_menu.html'));
      $templateCache.put('app/template/single_menu.html', require('../template/single_menu.html'));
    })

    .directive('mainMenu', function () {
      return {
        restrict: 'E',
        template: require('../template/menu.html'),
        transclude: true,
        scope: {
          menu: '=',
          security: '='
        },
        controller: function ($state, $scope, partnerMenuConst, menuService) {
          var self = this;
          self.state = $state;

          self.preparePartnerMenu = function (menu, partnerContent) {
            if (!partnerContent) {
              return;
            }

            var prepareTopMenu = function () {
              partnerContent.topmenu.forEach(function (item) {
                var menuObj = {};
                menuObj.translate = item.name;
                menuObj.topmenu = item.id;
                menuObj.access = function (security) {
                  return security.hasAnyFeature(item.feature);
                };
                menu.push(menuObj);
              });
            };

            var prepareSubMenu = function () {
              partnerContent.submenu.forEach(function (item) {
                var topMenu = _.find(menu, function (topMenuItem) {
                  return topMenuItem.topmenu === item.parent;
                });
                var subItem = {};
                subItem.translate = item.name;
                subItem.sref = 'page.partnerPage';
                subItem.stateParams = {
                  partner: topMenu.topmenu,
                  menuId: item.id
                };
                subItem.nav = item.id;
                if (!topMenu.submenu) {
                  topMenu.submenu = [];
                }
                topMenu.submenu.push(subItem);
              });
            };

            prepareTopMenu();

            prepareSubMenu();
            return menu;
          };

          var menu = angular.copy($scope.menu);

          self.menu = partnerMenuConst ? self.preparePartnerMenu(menu, partnerMenuConst) : menu;

          menuService.addDefaultAccessFn(menu);

          function findAndSetActiveItem(item) {
            if (_.find(item.submenu, function (s) {
              return s.sref.includes($state.current.name) || $state.current.name.includes(s.sref);
            })) {
              self.activeItem = item;
            }
          }

          var searchActiveItem = function () {
            self.menu.forEach(function (item) {
              findAndSetActiveItem(item);
              if (item.dropdown) {
                item.dropdown.forEach(function (item) {
                  findAndSetActiveItem(item);
                })
              }
            })
          };

          searchActiveItem();

          $scope.$on("$stateChangeSuccess", function () {
            searchActiveItem();
          });

          function checkActiveState(item) {
            return (item.stateParams && !item.ignoreParamsWhenSelect ? _.some([$state.params], item.stateParams) : true)
              && (_.isArray(item.nav) ? _.includes(item.nav, $state.current.name) : item.sref === $state.current.name);
          }

          self.isActiveTopmenuItem = function (item) {
            if (item.dropdown) {
              return _.find(item.dropdown, function (d) {
                return _.find(d.submenu, function (s) {
                  return checkActiveState(s);
                })
              })
            } else if (item.sref && item.sref === 'page.financialServices') {
              return checkActiveState(item);
            } else {
              return _.find(item.submenu, function (i) {
                return checkActiveState(i);
              })
            }
          };

          self.isActiveSubmenuItem = function (item) {
            return checkActiveState(item);
          };
        },
        controllerAs: 'menuCtrl'
      }
    })

    .directive('topMenu', function ($templateRequest, $compile, securityService) {
      return {
        restrict: 'EA',
        require: '^mainMenu',
        scope: {
          item: '=',
          security: '='
        },
        controllerAs: 'topMenuCtrl',
        controller: function ($scope, $element) {
          var mainMenuCtrl = $element.parent().controller('mainMenu');
          var self = this;

          self.dropdownTranslate = function (item) {
            return item.dropdown.indexOf(mainMenuCtrl.activeItem) !== -1 ? mainMenuCtrl.activeItem.translate : item.translate;
          };

          self.setActiveItem = function (item) {
            mainMenuCtrl.activeItem = item;
          };

          function findFirstVisible(menuItem) {
            return _.chain(menuItem.submenu).filter(function (item) {
              return _.isUndefined(item.access) || item.access(securityService)
            }).first().value();
          }

          self.getMenuItem = function (menuItem) {
            if (menuItem.sref) {
              return menuItem;
            }
            return findFirstVisible(menuItem);
          };
        },

        link: function (scope, elem) {
          var templateToUse = '';
          if (scope.item.dropdown) {
            templateToUse = 'app/template/dropdown_menu.html';
          } else {
            templateToUse = 'app/template/single_menu.html';
          }
          $templateRequest(templateToUse).then(function (html) {
            var template = angular.element(html);
            elem.append(template);
            $compile(template)(scope);
          });
        }
      }
    })

    .factory('menuService', function (MENU, $injector) {

      function getFlatMenuItems(menuList) {
        return _.chain(menuList)
          .map(function (item) {
            return item.dropdown ? getFlatMenuItems(item.dropdown) : item.submenu;
          })
          .flatten()
          .value();
      }

      function getUrlKey(menuItem) {
        if (!menuItem) {
          return;
        }
        // use $injector to get $state service to avoid circular dependency
        var stateService = $injector.get('$state');
        var url = stateService.href(menuItem.sref, menuItem.stateParams);
        return url ? url.replace('#', '') : url;
      }

      return {
        getPaths: function () {
          return _.map(getFlatMenuItems(MENU), function (item) {
            return getUrlKey(item);
          });
        },
        addDefaultAccessFn: function (menu) {
          var menuItems = getFlatMenuItems(menu);
          _.forEach(menuItems, function (item) {
            if (!item) {
              return;
            }
            if (_.isUndefined(item.access)) {
              item.access = function (security) {
                return security.hasAccess(getUrlKey(item));
              }
            }
          })
        }
      }
    })

})();
