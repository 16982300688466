(function () {
  'use strict';
  angular.module('gf.bm.report', [])

    .run(function ($templateCache) {
      $templateCache.put('app/template/report/report.html', require('./../../gf-report/template/report/report.html'))
    })

    .config(function ($stateProvider, securityServiceProvider) {

      securityServiceProvider
        .securePath('/branch_management', function (security) {
          return security.hasAnyFeature('BRANCH_MANAGEMENT');
        });

      $stateProvider
        .state('bm.branchType', {
          url: '/branch_report/{typeId:[0-9]+}',
          template: require('../template/bm/group-view/bm_report.html'),
          controller: 'ReportCtrl',
          controllerAs: '$ctrl',
          resolve: {
            reportData: function (ReportData, $stateParams) {
              return ReportData.get({typeId: $stateParams.typeId || 1, isBranch: true}).$promise;
            },
            profile: function (Profile) {
              return Profile.get().$promise;
            }
          },
          title: "branchReport"
        })
    })
})();
