import React, {useEffect, useState} from 'react';
import {Button, CircularProgress, Grid, Typography} from '@material-ui/core';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import AdvancedSettings from './AdvancedSettings';
import GfStaticFormControl from '../../gf-react-components/form/GfStaticFormControl';
import {makeStyles} from '@material-ui/core/styles';
import GFAlertTitle from '../../gf-react-components/alert/GFAlertTitle';
import GFAlert from '../../gf-react-components/alert/GFAlert';
import {
  disableCloudTSS,
  getCloudTSEConfig,
  initCloudTSE,
  getCloudTSEJob
} from './components/FiscalDeviceClient';
import _ from 'lodash';
import statuses from './TseStatuses'
import {normalizeState} from './FiscalDevice'
import moment from "moment";


const useStyles = makeStyles(theme => ({
  successText: {
    color: theme.palette.success.main
  },
  errorText: {
    color: theme.palette.error.main
  },
  initText: {
    marginBottom: theme.spacing(2)
  },
  bottomSpacing: {
    marginBottom: theme.spacing(3)
  }
}));

const initialCloudTSE = {
  configuration: {}
};

const initialStatus = {
  tseInitializationState: statuses.UNINITIALIZED,
  isDeletedStatus: false
};

function CloudTSE({security}) {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();
  const [device, setDevice] = useState(initialCloudTSE);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isInit, setIsInit] = useState(false);
  const [tseStatus, setTseStatus] = useState(initialStatus);
  const [isLoader, setLoader] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const delay = 3000;

  useEffect(() => {
    setLoader(true);
    setErrorMessage(null);
    setIsAdmin(security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER'));
    getCloudTSEJob()
      .then(resp => {
        if (resp.status === 202) {
          getJobResult(resp.headers.location);
        } else {
          setData(resp.data);
          setLoader(false);
        }
      }, error => {
        showError(error);
      });
  }, []);

  const initializeTSE = () => {
    setLoader(true);
    initCloudTSE()
      .then(resp => {
        getJobResult(resp.headers.location);
      }, error => {
        showError(error);
      });
  }

  const getJobResult = url => {
    setErrorMessage(null);
    getCloudTSEJob(url)
      .then(respJob => {
        if (respJob.data === 'In progress') {
          setTimeout(() => {
            getJobResult(url);
          }, delay);

        } else {
          setData(respJob.data);
          setLoader(false);
        }
      }, errorJob => {
        if (errorJob.response.status === 503) {
          setErrorMessage(errorJob.response.data.term ? t(errorJob.response.data.term) : errorJob.response.data.message);
          getCloudTSEConfig()
            .then(respConfig => {
              setData(respConfig.data);
              showError(errorJob);
            }, errorConfig => {
              showError(errorConfig);
            })
        } else {
          showError(errorJob);
        }
      })
  }

  const showError = (error) => {
    enqueueSnackbar(error.response.data.term ? t(error.response.data.term) : error.response.data.message, {variant: 'error'});
    setLoader(false);
  }

  const disableTSE = () => {
    setLoader(true);
    disableCloudTSS()
      .then(resp => {
        getJobResult(resp.headers.location);
      }, error => {
        showError(error);
      });
  }

  const setData = (data) => {
    setDevice(data || initialCloudTSE);
    setIsInit(!_.isEmpty(data));
    if (!_.isEmpty(data)) {
      setTseStatus(!_.isEmpty(data.configuration.state) ? normalizeState({tseInitializationState: data.configuration.state}) : initialStatus);
    }
  }

  return (
    <>
      {
        !isLoader && !isInit &&
        <GFAlert type='warning' className={classes.bottomSpacing}>
          <GFAlertTitle align={'center'}>{t('tse.alert.attention')}</GFAlertTitle>
          {t('tse.alert.cloudTSE.noConfig')}
        </GFAlert>
      }
      {
        !isLoader && isInit && !_.isNil(errorMessage) &&
        <GFAlert type='error' className={classes.bottomSpacing}>
          <GFAlertTitle align={'center'}>{errorMessage}</GFAlertTitle>
          <div align={'center'}>Sorry, there were some technical issues while processing your request. Please try again</div>
        </GFAlert>
      }
      <Grid container>
        <Grid item sm={3} xs={1} implementation='css'/>
        <Grid item sm={6} xs={10}>
          <Typography
            component="h3"
            variant="h5"
            color="textSecondary"
            className={classes.bottomSpacing}
          >
            {t('tse.configuration.for', {1: t('feature.CLOUD_TSE')})}
          </Typography>
          {
            !isInit && !isLoader
              ?
              <div style={{margin: '30px 0', textAlign: 'center'}}>
                <Typography className={classes.initText}>{t('tse.cloudTSE.noSetUp.text')}</Typography>
                <Button onClick={initializeTSE} color='primary'
                        variant='contained'>{t('tse.cloudTSE.setUp.button')}</Button>
              </div>
              :
              !isLoader && <>
                <GfStaticFormControl
                  id='status'
                  label={t('tse.status')}
                  value={t('tse.status.' + tseStatus.tseInitializationState)}
                  className={tseStatus.tseInitializationState === statuses.INITIALIZED ? classes.successText
                    : tseStatus.isDeletedStatus ? classes.errorText : ''}
                />
                {
                  isAdmin && tseStatus.tseInitializationState !== statuses.SHUTDOWN &&
                  <AdvancedSettings>
                    <GfStaticFormControl
                      id='organizationId'
                      label={t('tse.cloud.organization.id')}
                      value={device.configuration.organizationId || ''}
                    />
                    <GfStaticFormControl
                      id='apiKey'
                      label={t('tse.cloud.organization.name')}
                      value={device.configuration.organizationName || ''}
                    />
                    <GfStaticFormControl
                      id='tseSerialNumber'
                      label={t('tse.serialNumber')}
                      value={device.configuration.serialNumber || 'N/A'}
                    />
                    <GfStaticFormControl
                      id='tssId'
                      label={t('tse.cloud.tss.id')}
                      value={device.configuration.tssId || 'N/A'}
                    />
                    <GfStaticFormControl
                      id='clientId'
                      label={t('tse.cloud.client.id')}
                      value={device.configuration.clientId || 'N/A'}
                    />
                    <GfStaticFormControl
                      id='timeInit'
                      label={t('tse.cloud.tss.timeInit')}
                      value={device.configuration.initTime ? moment(device.configuration.initTime * 1000).format("DD MMM YYYY hh:mm a") : 'N/A'}
                    />
                    <Grid item container xs={6}>
                      <Grid item container xs={6} justify="flex-start">
                        <Button onClick={initializeTSE} color='primary'
                                disabled={!security.context.currentRestaurant.live}
                                variant='contained'>{t('tse.cloud.init.button')}</Button>
                      </Grid>
                      <Grid item container xs={6} justify="flex-start">
                        <Button onClick={disableTSE} color='primary'
                                disabled={tseStatus.isDeletedStatus || device.configuration.isOutlet}
                                variant='contained'>{t('tse.cloud.disable.button')}</Button>
                      </Grid>
                    </Grid>
                  </AdvancedSettings>
                }
              </>
          }
          {isLoader &&
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                bottom: 0,
                right: 0,
                left: 0
              }}>
              <CircularProgress style={{margin: 'auto'}} size={55}/>
            </div>
          }
        </Grid>
      </Grid>
    </>
  );
}

export default CloudTSE;
