import React, {useEffect, useState, forwardRef} from 'react';
import MaterialTable from 'material-table';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import _ from 'lodash';
import {TextField} from '@material-ui/core';
import {
  AddBox,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FirstPage,
  LastPage,
  Search,
  ArrowDownward
} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';

export default function TsePrinterFirmwareTable() {

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
  };

  const {t} = useTranslation();

  const initialEntity = {
    printer: {
      required: true
    },
    firmware: {
      required: true
    }
  };
  const cols = [
    {
      title: t('tsePrinterFirmware.printer'),
      field: 'printer',
      editComponent: rowData => (
        <TextField
          error={rowData.value === ''}
          helperText={rowData.value === '' ? t('validation.requiredField') : ''}
          type='text'
          value={rowData.value}
          name='printer'
          onChange={(event) => handleChange(event, rowData)}
        />
      )
    },
    {
      title: t('tsePrinterFirmware.firmware'),
      field: 'firmware',
      editComponent: rowData => (
        <TextField
          error={rowData.value === ''}
          helperText={rowData.value === '' ? t('validation.requiredField') : ''}
          type='text'
          value={rowData.value}
          name='firmware'
          onChange={(event) => handleChange(event, rowData)}
        />
      )
    },
  ]
  const [tsePrinterFirmware, setTsePrinterFirmware] = useState({
    columns: cols,
    data: [],
  });
  const {enqueueSnackbar} = useSnackbar();

  const isUniquePrinterName = (tseFirmwareData, oldData) => {
    return (oldData && tseFirmwareData.printer === oldData.printer)
      || !_.find(tsePrinterFirmware.data, {'printer': tseFirmwareData.printer});
  };

  const isValidEntity = (data, oldData) => {
    let missedFields = _.map(initialEntity, (value, key) => {
      if (value.required && !data[key]) {
        data[key] = '';
        return true;
      }
      return false;
    });
    if (_.includes(missedFields, true)) {
      enqueueSnackbar(t('tse.tseFormValidation.invalidFields '), {variant: 'error'});
      return false;
    } else if (!isUniquePrinterName(data, oldData)) {
      enqueueSnackbar(t('tse.tseFormValidation.tsePrinterExists'), {variant: 'error'});
      return false;
    } else {
      return true;
    }
  };

  const handleChange = (event, rowData) => {

    var data = {...rowData};
    data.rowData[event.target.name] = event.target.value;
    rowData.onRowDataChange(data.rowData);
  };

  //getting data for the page
  useEffect(() => {
    getTsePrinterFirmware().then(function (res) {
      setTsePrinterFirmware({...tsePrinterFirmware, 'data': res.data});
    })
  }, []);

  return (
    <MaterialTable
      title={t('tse.tsePrinterFirmware')}
      columns={cols}
      data={tsePrinterFirmware.data}
      icons={tableIcons}
      options={{
        paging: false,
        showTitle: false,
        actionsColumnIndex: -1
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            if (!isValidEntity(newData)) {
              reject();
            } else {
              addTsePrinterFirmware(newData).then(function (res) {
                enqueueSnackbar(t('tse.tseFormValidation.tsePrinterFirmwareAdded'), {variant: 'success'});
                setTsePrinterFirmware(prevState => {
                  const data = [...prevState.data];
                  data.push(res.data.payload.entity);
                  return {...prevState, data};
                });
                resolve();
              }, function () {
                enqueueSnackbar(t('entity.save.failed'), {variant: 'error'});
                reject();
              });
            }
          }),

        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            if (!isValidEntity(newData, oldData)) {
              reject();
            } else {
              if (oldData) {
                editTsePrinterFirmware(newData).then(function (res) {
                  enqueueSnackbar(t('tse.tseFormValidation.tsePrinterFirmwareChanged'), {variant: 'success'});
                  setTsePrinterFirmware(prevState => {
                    const data = [...prevState.data];
                    data[data.indexOf(oldData)] = res.data.payload.entity;
                    return {...prevState, data};
                  });
                  resolve();
                }, function () {
                  enqueueSnackbar(t('entity.save.failed'), {variant: 'error'});
                  reject();
                })
              }
            }
          }),

        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              deleteTsePrinterFirmware(oldData).then(function (res) {
                enqueueSnackbar(t('tse.tseFormValidation.tsePrinterFirmwareDeleted'), {variant: 'success'});
                setTsePrinterFirmware(prevState => {
                  const data = [...prevState.data];
                  data.splice(data.indexOf(oldData), 1);
                  return {...prevState, data};
                });
              });
              resolve();
            }, 600);
          }),
      }}
      localization={{
        body: {
          emptyDataSourceMessage: t('tse.tseFormValidation.noFirmwareAdded'),
          editRow: {
            deleteText: t('tse.tsePrinterFirmware.deleteFirmwareConfirm')
          }
        },
        header: {
          actions: t('jsp.actions')
        },
        toolbar: {
          searchPlaceholder: t('button.search')
        }
      }}
    />
  );
}

function getTsePrinterFirmware() {
  return axios.get('/icash/admin/tse_printer_firmware')
}

function addTsePrinterFirmware(tsePrinterFirmware) {
  return axios.post('/icash/admin/tse_printer_firmware', tsePrinterFirmware)
}

function editTsePrinterFirmware(tsePrinterFirmware) {
  return axios.put('/icash/admin/tse_printer_firmware/' + tsePrinterFirmware.id, tsePrinterFirmware)
}

function deleteTsePrinterFirmware(tsePrinterFirmware) {
  return axios.delete('/icash/admin/tse_printer_firmware/' + tsePrinterFirmware.id)
}
