// Mui-theme setting and overrides common for all the themes
const COMMON_THEME_SETTINGS = {
  overrides: {
    MuiButton: {
      outlined: {
        color: '#555'
      }
    }
  }
}

export default COMMON_THEME_SETTINGS;
