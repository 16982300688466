import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {Button, ClickAwayListener, Fade, Grid, List, ListItem, Paper, Popper} from "@material-ui/core";
import {getFilters, setUrlParameters, getFiltersForReportType} from "./ReportUtils";
import {useTranslation} from "react-i18next";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import {useSnackbar} from "notistack";
import _ from "lodash";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(1),
  },
  sectionLabel: {
    marginTop: 40,
    marginBottom: 10,
    color: '#888',
  },
  noDataText: {
    textAlign: 'center',
    color: '#e64c45',
    marginTop: 20
  }
}));

export default function ReportPeriod({
                                       predefinedDates, chosenDate, setChosenDate, selectedDate, setSelectedDate,
                                       setSettingChanged, expanded, setTypeFilters, filtersMetadata, setFilterLoader,
                                       setShowNoDataMessage, showNoDataMessage, reportTypes, setAllFilters
                                     }) {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const classes = useStyles();
  const [isFromTo, setFromTo] = useState(false);
  const [anchorElPeriod, setAnchorElPeriod] = useState(null);
  const openPeriod = Boolean(anchorElPeriod);

  const handleOpenPeriod = event => {
    setAnchorElPeriod(event.currentTarget);
  };

  const handleDateChange = (name, date) => {
    setSelectedDate({...selectedDate, [name]: date});
  };

  const handleChoosePeriod = (period, event) => {
    if (period && period.id === 8) {
      setFromTo(true)
    } else {
      setChosenDate(period);
      setFromTo(false);
      setAnchorElPeriod(null);
      setSelectedDate({from: new Date(), to: new Date()});
      setUrlParameters('date', period.id);
      if (chosenDate.id === 8) {
        setUrlParameters('from', null);
        setUrlParameters('to', null);
      }
      setSettingChanged(true);
      getFiltersForPeriod(period);
    }
  };

  const handlePeriodClose = () => {
    if (isFromTo) {
      return false;
    }
    setAnchorElPeriod(null);
  };

  const handleSetPeriod = () => {
    if (!selectedDate.from || !selectedDate.to) {
      enqueueSnackbar(t('report.fromToDatesRequired'), {variant: 'error'});
      return;
    }
    if (!moment(selectedDate.from).isValid() || !moment(selectedDate.to).isValid()) {
      enqueueSnackbar(t('report.fromToDatesInvalidFormat'), {variant: 'error'});
      return;
    }
    if (moment(selectedDate.to).isBefore(moment(selectedDate.from))) {
      enqueueSnackbar(t('report.fromToDatesError'), {variant: 'error'});
      return;
    }
    let chosenDateObj = {
      id: 8,
      key: moment(selectedDate.from).format('DD.MM.YYYY') + ' - ' + moment(selectedDate.to).format('DD.MM.YYYY'),
      from: moment(selectedDate.from).format('YYYY-MM-DD'),
      to: moment(selectedDate.to).format('YYYY-MM-DD')
    };
    setChosenDate(chosenDateObj);
    setAnchorElPeriod(null);
    setUrlParameters('date', chosenDateObj.id);
    setUrlParameters('from', chosenDateObj.from);
    setUrlParameters('to', chosenDateObj.to);
    setSettingChanged(true);
    getFiltersForPeriod(chosenDateObj);
  };

  function getFiltersForPeriod(chosenDateObj) {
    setFilterLoader(true);
    getFilters(chosenDateObj, {}).then(function (res) {
      setFilterLoader(false);
      setAllFilters(res.data);
      if (_.isEmpty(res.data)) {
        setShowNoDataMessage(true);
      } else {
        setShowNoDataMessage(false);
      }
      setTypeFilters(expanded ? getFiltersForReportType(res.data, filtersMetadata, _.find(reportTypes, {name: expanded}).filters) : {});
    }, function (res) {
      setFilterLoader(false);
    })
  }

  return (
    <>
      <Typography className={classes.sectionLabel} style={{marginTop: 0}}>{t('report.label.date')}:</Typography>
      <Button id="choose_date_button" variant={"contained"} style={{background: '#fff', color: '#555'}} startIcon={<DateRangeIcon/>}
              onClick={handleOpenPeriod}>
        {t(chosenDate.key)}
      </Button>
      <Popper id="date_popper" open={openPeriod} anchorEl={anchorElPeriod} placement={'top'} transition>
        {({TransitionProps}) => (
          <ClickAwayListener onClickAway={handlePeriodClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Grid item xs={12} container>
                  <Grid item xs={6}>
                      <List id="dates_list" component="nav" aria-label="secondary" style={{minWidth: 150}}>
                      {predefinedDates.map((period, index) => (
                        <ListItem key={index} button onClick={(event) => handleChoosePeriod(period, event)}
                                  selected={period.id === chosenDate.id}>
                          {t(period.key)}
                        </ListItem>
                      ))}
                    </List>
                  </Grid>

                  {isFromTo && <Grid item xs={6} id={'dateCollapse'}>
                    <div id="from_to_dates" style={{paddingRight: 15, paddingLeft: 15}}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container justify="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date_from_picker"
                            label={t('reportFilter.fromTime')}
                            value={selectedDate.from}
                            onChange={(event) => handleDateChange('from', event)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          /></Grid>
                        <Grid container justify="space-around">
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date_to_picker"
                            label={t('reportFilter.toTime')}
                            value={selectedDate.to}
                            onChange={(event) => handleDateChange('to', event)}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <div style={{position: 'absolute', right: 15, bottom: 15}}>
                        <Button id="date_save_button" variant='contained' onClick={handleSetPeriod}
                                size={'small'}>{t("button.ok")}</Button>
                      </div>
                    </div>
                  </Grid>}
                </Grid>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      {showNoDataMessage &&
      <div id="no_data_message" className={classes.noDataText}>{t('report.noData')}</div>}
    </>
  );
}
