(function () {
  'use strict';
  angular.module('gf.report.config', [])

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.reportCorrectionsTransactionTypeDate', {
          url: '/report/corrections/:transactionType/:date?fd&td',
          template: require('../template/report/corrections.html'),
          controller: 'CorrectionsCtrl',
          resolve: {
            corrections: function ($stateParams, $filter, Corrections) {
              return Corrections.get({
                transactionType: $stateParams.transactionType || 'adjustment',
                date: $stateParams.date || 1,
                from: $filter("date")($stateParams.fd, 'yyyy-MM-dd'),
                to: $filter("date")($stateParams.td, 'yyyy-MM-dd')
              }).$promise;
            }
          },
          title: "jsp.menu.reporting"
        })

        .state('page.reportCorrectionsTransactionTypeDateDetails', {
          url: '/report/corrections/:transactionType/:date/:id',
          template: require('../template/report/correction.html'),
          controller: 'CorrectionCtrl',
          resolve: {
            correction: function ($stateParams, Correction) {
              return Correction.get({
                transactionType: $stateParams.transactionType || 'adjustment',
                date: $stateParams.date || 1,
                id: $stateParams.id
              }).$promise;
            }
          },
          title: "jsp.menu.reporting"
        })

        .state('page.reportType', {
          url: '/report/{typeId:[0-9]+}',
          template: require('../template/report/report.html'),
          controller: 'ReportCtrl',
          resolve: {
            reportData: function (ReportData, $stateParams) {
              return ReportData.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            profile: function (Profile) {
              return Profile.get().$promise;
            }
          },
          reloadOnSearch: false,
          title: "jsp.menu.reporting"
        })

        .state('page.report3MessageId', {
          url: '/report/3/:messageId',
          template: require('../template/report/message.html'),
          controller: 'MessageCtrl',
          resolve: {
            message: function ($stateParams, Message) {
              return Message.get({messageId: $stateParams.messageId}).$promise;
            }
          },
          title: "jsp.menu.reporting"
        })

        .state('page.branchType', {
          url: '/branch/:typeId',
          template: require('../template/report/report.html'),
          controller: 'ReportCtrl',
          resolve: {
            reportData: function (ReportData, $stateParams) {
              return ReportData.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            profile: function (Profile) {
              return Profile.get().$promise;
            }
          },
          reloadOnSearch: false,
          title: "jsp.menu.reporting"
        })

        .state('page.billTypeId', {
          url: '/bill/:typeId',
          template: require('../template/report/report.html'),
          controller: 'ReportCtrl',
          resolve: {
            reportData: function (ReportData, $stateParams) {
              return ReportData.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            profile: function (Profile) {
              return Profile.get().$promise;
            }
          },
          reloadOnSearch: false,
          title: 'jsp.menu.reporting'
        })

        .state('page.filterType', {
          url: '/filter/:typeId',
          template: require('../../../main/angular/app/template/page/gf-grid.html'),
          controller: 'ReportFilterGridCtrl',
          resolve: {
            reportFilterMetadata: function (ReportFilterMetadata, $stateParams) {
              return ReportFilterMetadata.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilterContext: function (ReportFilterContext, $stateParams) {
              return ReportFilterContext.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilter: function (ReportFilter, $stateParams) {
              return ReportFilter.get({
                typeId: $stateParams.typeId || 1,
                reportFilterId: $stateParams.id || 'add'
              }).$promise;
            }
          },
          title: 'jsp.menu.reportFilters'
        })

        .state('page.filterTypeAdd', {
          url: '/filter/:typeId/add',
          template: require('../template/report/report_filter.html'),
          controller: 'ReportFilterAddCtrl',
          resolve: {
            reportFilterMetadata: function (ReportFilterMetadata, $stateParams) {
              return ReportFilterMetadata.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilterContext: function (ReportFilterContext, $stateParams) {
              return ReportFilterContext.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilter: function (ReportFilter, $stateParams) {
              return ReportFilter.get({
                typeId: $stateParams.typeId || 1,
                reportFilterId: $stateParams.id || 'add'
              }).$promise;
            }
          },
          title: 'jsp.menu.reportFilters'
        })

        .state('page.filterTypeDetails', {
          url: '/filter/:typeId/:id',
          template: require('../template/report/report_filter.html'),
          controller: 'ReportFilterEditCtrl',
          resolve: {
            reportFilterMetadata: function (ReportFilterMetadata, $stateParams) {
              return ReportFilterMetadata.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilterContext: function (ReportFilterContext, $stateParams) {
              return ReportFilterContext.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilter: function (ReportFilter, $stateParams) {
              return ReportFilter.get({
                typeId: $stateParams.typeId || 1,
                reportFilterId: $stateParams.id || 'add'
              }).$promise;
            }
          },
          title: 'jsp.menu.reportFilters'
        })

        .state('page.filter_branchType', {
          url: '/filter_branch/:typeId',
          template: require('../../../main/angular/app/template/page/gf-grid.html'),
          controller: 'ReportFilterGridCtrl',
          resolve: {
            reportFilterMetadata: function (ReportFilterMetadata, $stateParams) {
              return ReportFilterMetadata.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilterContext: function (ReportFilterContext, $stateParams) {
              return ReportFilterContext.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilter: function (ReportFilter, $stateParams) {
              return ReportFilter.get({
                typeId: $stateParams.typeId || 1,
                reportFilterId: $stateParams.reportFilterId || 'add'
              }).$promise;
            }
          },
          title: "jsp.menu.reportFilters"
        })

        .state('page.filter_branchTypeAdd', {
          url: '/filter_branch/:typeId/add',
          template: require('../template/report/report_filter.html'),
          controller: 'ReportFilterAddCtrl',
          resolve: {
            reportFilterMetadata: function (ReportFilterMetadata, $stateParams) {
              return ReportFilterMetadata.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilterContext: function (ReportFilterContext, $stateParams) {
              return ReportFilterContext.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilter: function (ReportFilter, $stateParams) {
              return ReportFilter.get({
                typeId: $stateParams.typeId || 1,
                reportFilterId: $stateParams.reportFilterId || 'add'
              }).$promise;
            }
          },
          title: 'jsp.menu.reportFilters'
        })

        .state('page.filter_branchTypeDetails', {
          url: '/filter_branch/:typeId/:id',
          template: require('../template/report/report_filter.html'),
          controller: 'ReportFilterEditCtrl',
          resolve: {
            reportFilterMetadata: function (ReportFilterMetadata, $stateParams) {
              return ReportFilterMetadata.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilterContext: function (ReportFilterContext, $stateParams) {
              return ReportFilterContext.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilter: function (ReportFilter, $stateParams) {
              return ReportFilter.get({
                typeId: $stateParams.typeId || 1,
                reportFilterId: $stateParams.id || 'add'
              }).$promise;
            }
          },
          title: 'jsp.menu.reportFilters'
        })

        .state('page.adminFilter', {
          url: '/admin/filter/:typeId',
          template: require('../../../main/angular/app/template/page/gf-grid.html'),
          controller: 'ReportFilterGridCtrl',
          resolve: {
            reportFilterMetadata: function (ReportFilterMetadata, $stateParams) {
              return ReportFilterMetadata.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilterContext: function (ReportFilterContext, $stateParams) {
              return ReportFilterContext.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilter: function (ReportFilter, $stateParams) {
              return ReportFilter.get({
                typeId: $stateParams.typeId || 1,
                reportFilterId: $stateParams.id || 'add'
              }).$promise;
            }
          },
          title: "jsp.menu.reportFilters"
        })

        .state('page.adminFilterAdd', {
          url: '/admin/filter/:typeId/add',
          template: require('../template/report/report_filter.html'),
          controller: 'ReportFilterAddCtrl',
          resolve: {
            reportFilterMetadata: function (ReportFilterMetadata, $stateParams) {
              return ReportFilterMetadata.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilterContext: function (ReportFilterContext, $stateParams) {
              return ReportFilterContext.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilter: function (ReportFilter, $stateParams) {
              return ReportFilter.get({
                typeId: $stateParams.typeId || 1,
                reportFilterId: $stateParams.id || 'add'
              }).$promise;
            }
          },
          title: 'jsp.menu.reportFilters'
        })

        .state('page.adminFilterDetails', {
          url: '/admin/filter/:typeId/:id',
          template: require('../template/report/report_filter.html'),
          controller: 'ReportFilterEditCtrl',
          resolve: {
            reportFilterMetadata: function (ReportFilterMetadata, $stateParams) {
              // console.log($stateParams);
              return ReportFilterMetadata.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilterContext: function (ReportFilterContext, $stateParams) {
              return ReportFilterContext.get({typeId: $stateParams.typeId || 1}).$promise;
            },
            reportFilter: function (ReportFilter, $stateParams) {
              return ReportFilter.get({
                typeId: $stateParams.typeId || 1,
                reportFilterId: $stateParams.id || 'add'
              }).$promise;
            }
          },
          title: 'jsp.menu.reportFilters'
        })

        .state('page.control_roomReportDetails', {
          url: '/control_room/report/:id',
          template: require('../template/report/report.html'),
          controller: 'ReportCtrl',
          resolve: {
            reportData: function (ReportData, $stateParams) {
              return ReportData.get({typeId: $stateParams.id || 1}).$promise;
            },
            profile: function (Profile) {
              return Profile.get().$promise;
            }
          },
          reloadOnSearch: false,
          title: "jsp.menu.reporting"
        })
    })

})();


