import React, {useEffect, useState, useRef} from 'react';
import {useTranslation} from "react-i18next";
import {
  FormControl, Grid, MenuItem, Paper, Button, ButtonGroup, ClickAwayListener,
  Popper, Divider, Typography, Grow, MenuList, CircularProgress,
} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import axios from "axios";
import ReportType from "./ReportType";
import ReportFilter from "./ReportFilter";
import {
  setUrlParameters,
  getQueryParams,
  urlToFilter,
  getFiltersForReportType,
  getFilters,
  resolveFromToForPeriods,
  isAutopreviewPeriod,
  filterToObject,
  getReportTypes
} from './ReportUtils';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import {useSnackbar} from "notistack";
import AutopreviewSwitch from "./AutopreviewSwitch";
import ReportPeriod from "./ReportPeriod";
import RefreshIcon from '@material-ui/icons/Refresh';

function NewReport(security) {
  const {t} = useTranslation();

  const useStyles = makeStyles({
    formControl: {
      width: '100%',
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    sectionLabel: {
      marginTop: 40,
      marginBottom: 10,
      color: '#888',
    },
    reportTypePaper: {
      background: '#fafafa', padding: 15, marginRight: 20
    },
    loaderWrapper: {
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      background: '#ffffff9c'
    },
  });

  const SecondaryButton = withStyles({
    root: {
      background: '#fff',
      color: '#555',
      textTransform: 'uppercase',
      borderColor: '#aaa',
      '&:hover': {
        borderColor: '#555',
      }
    },
  })(Button);

  const initialReportTypes = [
    {
      name: 'Order',
      groups: ['Article', 'ArticleGroup', 'ArticleSuperGroup', 'Waiter'],
      filters: []
    },
    // {
    //   name: 'Revenue',
    //   groups: ['Article', 'ArticleGroup', 'ArticleSuperGroup', 'Waiter', 'PaymentMethod'],
    //   filters: []
    // },
    // {
    //   name: 'Void',
    //   groups: ['Article', 'ArticleGroup', 'ArticleSuperGroup', 'Waiter'],
    //   filters: []
    // },
    {
      name: 'Payment',
      groups: ['CreditCard'],
      filters: []
    }
  ];

  const classes = useStyles();

  const [predefinedDates, setPredefinedDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState({from: new Date(), to: new Date()});
  const [expanded, setExpanded] = useState(initialReportTypes[0].name);
  const [anchorElFilter, setAnchorElFilter] = useState(null);
  const [chosenDate, setChosenDate] = useState('');
  const [groupBy, setGroupBy] = useState('');
  const [chosenFilters, setChosenFilters] = useState([]);
  const [typeFilters, setTypeFilters] = useState({});
  const [filtersMetadata, setFiltersMetadata] = useState({});
  const [reportUrl, setReportUrl] = useState('');
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedIndexMail, setSelectedIndexMail] = useState(0);
  const [openDownload, setOpenDownload] = useState(false);
  const [openMail, setOpenMail] = useState(false);
  const [isLoader, setLoader] = useState(false);
  const anchorRefDownload = useRef(null);
  const anchorRefMail = useRef(null);
  const {enqueueSnackbar} = useSnackbar();
  const [isAutopreview, setAutopreview] = useState(false);
  const [settingChanged, setSettingChanged] = useState(false);
  const [showNoDataMessage, setShowNoDataMessage] = useState(false);
  const [isFilterLoader, setFilterLoader] = useState(false);
  const [reportTypes, setReportTypes] = useState(initialReportTypes);
  const [allFilters, setAllFilters] = useState({});
  const [isPreviewDownloading, setPreviewDownloading] = useState(false);
  const [isDocDownloading, setDocDownloading] = useState(false);

  const downloadFormats = ['pdf', 'csv'];

  // get data for the page and set url parameters to filters
  useEffect(() => {
    let queryParams = getQueryParams();
    let chosenPeriod = queryParams.date ? {id: queryParams.date, from: queryParams.from, to: queryParams.to} : {id: 1};
    let chosenType = queryParams.type || expanded;
    Promise
      .all([
        getPredefinedDates(),
        getFilters(chosenPeriod, {}),
        getFilterMetadata(),
        getReportTypes()
      ])
      .then(([resDates, resFilters, resFiltersMetadata, resTypes]) => {
        setPredefinedDates(resDates.data);
        setChosenDate(resDates.data[0]);
        setAllFilters(resFilters.data);
        setTypeFilters(getFiltersForReportType(resFilters.data, resFiltersMetadata.data.fields, resTypes.data[chosenType]));
        setFiltersMetadata(resFiltersMetadata.data.fields);
        setReportTypes(_.map(initialReportTypes, function (t) {
          return {...t, filters: resTypes.data[t.name]}
        }));

        // show no data message if no transactions for the period
        if (_.isEmpty(resFilters.data)) {
          setShowNoDataMessage(true);
        }

        //parse url
        prepareAndSetUrlParameters(resDates.data, resFilters.data, resFiltersMetadata.data.fields, resTypes.data[chosenType]);
      })
  }, []);

  function prepareAndSetUrlParameters(dates, filters, metadata, typeFilters) {
    let queryParams = getQueryParams();
    if (queryParams) {
      if (queryParams.type) {
        setExpanded(queryParams.type);
      } else {
        setUrlParameters('type', expanded);
      }
      if (queryParams.date) {
        if (+queryParams.date === 8) {
          setChosenDate({
            id: 8,
            key: moment(queryParams.from).format('DD.MM.YYYY') + ' - ' + moment(queryParams.to).format('DD.MM.YYYY'),
            from: moment(queryParams.from).format('YYYY-MM-DD'),
            to: moment(queryParams.to).format('YYYY-MM-DD')
          });
          setSelectedDate({
            ...selectedDate, 'from': moment(queryParams.from).format('YYYY-MM-DD'),
            'to': moment(queryParams.to).format('YYYY-MM-DD')
          });
        } else {
          setChosenDate(_.find(dates, {'id': +queryParams.date}))
        }
      }
      if (queryParams.filter) {
        let decoded = decodeURIComponent(queryParams.filter);
        setChosenFilters(urlToFilter(decoded, getFiltersForReportType(filters, metadata, typeFilters), metadata, t));
      }
      if (queryParams.groupBy) {
        setGroupBy(queryParams.groupBy)
      }
    } else {
      setUrlParameters('type', expanded);
    }
  }

  // preview
  const handleGetPreview = () => {
    setPreviewDownloading(true);
    setLoader(true);
    resolveReportUrl(expanded, groupBy, chosenDate, 'html', false, chosenFilters, allFilters).then(function (res) {
      setReportUrl(res.data.payload.location);
      setLoader(false);
      setSettingChanged(false);
      setPreviewDownloading(false);
    }, function () {
      enqueueSnackbar(t('rksv.dep.failToDownloadReport'), {variant: 'error'});
      setLoader(false);
      setPreviewDownloading(false);
    })
  };

  //download
  const handleDownloadClick = () => {
    setDocDownloading(true);
    setLoader(true);
    resolveReportUrl(expanded, groupBy, chosenDate, downloadFormats[selectedIndex], false, chosenFilters, allFilters).then(function (res) {
      setDownloadUrl(res.data.payload.location);
      setLoader(false);
      setDocDownloading(false);
    }, function () {
      setLoader(false);
      setDocDownloading(false);
      enqueueSnackbar(t('rksv.dep.failToDownloadReport'), {variant: 'error'})
    })
  };

  const handleDownloadToggle = () => {
    setOpenDownload(prevOpen => !prevOpen);
  };

  const handleMenuItemDownloadClick = (event, index) => {
    setSelectedIndex(index);
    setOpenDownload(false);
  };

  const handleDownloadClose = event => {
    if (anchorRefDownload.current && anchorRefDownload.current.contains(event.target)) {
      return;
    }
    setOpenDownload(false);
  };

  const handleChangeAutopreview = event => {
    setAutopreview(event.target.checked);
  };

  //mail
  const handleMailClick = () => {
    if (security.security.context.currentUser && !security.security.context.currentUser.mailConfirmed) {
      enqueueSnackbar(t('report.message.emailNotConfirmed'), {variant: 'error'});
      return;
    }
    resolveReportUrl(expanded, groupBy, chosenDate, downloadFormats[selectedIndexMail], true, chosenFilters, allFilters).then(function (res) {
      enqueueSnackbar(t('report.mail.success'), {variant: 'success'});
    }, function () {
      enqueueSnackbar(t('rksv.dep.failToSendReport'), {variant: 'error'});
    })
  };

  const handleMailToggle = () => {
    setOpenMail(prevOpen => !prevOpen);
  };

  const handleMenuItemMailClick = (event, index) => {
    setSelectedIndexMail(index);
    setOpenMail(false);
  };

  const handleMailClose = event => {
    if (anchorRefMail.current && anchorRefMail.current.contains(event.target)) {
      return;
    }
    setOpenMail(false);
  };

  useEffect(() => {
    if (expanded && isAutopreview && isAutopreviewPeriod(chosenDate)) {
      handleGetPreview();
    }
  }, [chosenDate]);

  return (
    <div className={classes.root}>
      <Grid item xs={12} container id="new_report_section">
        <Grid item sm={3} xs={12}>
          <Paper className={classes.reportTypePaper}>
            <FormControl className={classes.formControl}>
              <ReportPeriod predefinedDates={predefinedDates} chosenDate={chosenDate} setChosenDate={setChosenDate}
                            selectedDate={selectedDate} setSelectedDate={setSelectedDate} chosenFilters={chosenFilters}
                            setSettingChanged={setSettingChanged} expanded={expanded} setTypeFilters={setTypeFilters}
                            filtersMetadata={filtersMetadata} typeFilters={typeFilters}
                            setFilterLoader={setFilterLoader}
                            showNoDataMessage={showNoDataMessage} setShowNoDataMessage={setShowNoDataMessage}
                            reportTypes={reportTypes} setAllFilters={setAllFilters}/>
            </FormControl>
            <Grid item xs={12}>
              <Typography className={classes.sectionLabel}>{t('report.label.reportType')}:</Typography>
              <ReportType reportTypes={reportTypes} setTypeFilters={setTypeFilters} chosenFilters={chosenFilters}
                          setChosenFilters={setChosenFilters} expanded={expanded}
                          setExpanded={setExpanded} groupBy={groupBy} setGroupBy={setGroupBy}
                          filtersMetadata={filtersMetadata} handleGetPreview={handleGetPreview} chosenDate={chosenDate}
                          isAutopreview={isAutopreview} setSettingChanged={setSettingChanged} allFilters={allFilters}/>
            </Grid>
          </Paper>
        </Grid>
        <Grid item sm={9} xs={12}>
          <ReportFilter typeFilters={typeFilters} setAnchorElFilter={setAnchorElFilter} anchorElFilter={anchorElFilter}
                        chosenFilters={chosenFilters} handleGetPreview={handleGetPreview} chosenDate={chosenDate}
                        setChosenFilters={setChosenFilters} expanded={expanded} isFilterLoader={isFilterLoader}
                        isAutopreview={isAutopreview} setSettingChanged={setSettingChanged}/>
          <Divider/>
          <Grid item xs={12} container style={{paddingTop: 20}}>
            <Grid item sm={2} xs={6}>
              <SecondaryButton
                id="preview_button"
                disabled={!expanded || isPreviewDownloading}
                variant='outlined'
                onClick={handleGetPreview}>
                {t('report.preview')}{Boolean(reportUrl) && settingChanged
              && (!isAutopreview || isAutopreview && !isAutopreviewPeriod(chosenDate)) && <>&nbsp;
                <RefreshIcon/></>}
              </SecondaryButton>
            </Grid>
            <Grid item sm={4} xs={6}>
              <AutopreviewSwitch isAutopreview={isAutopreview} chosenDate={chosenDate}
                                 handleChangeAutopreview={handleChangeAutopreview}/>
            </Grid>
            <Grid item sm={6} xs={12} style={{textAlign: 'right'}}>
              <ButtonGroup variant="outlined" ref={anchorRefDownload} aria-label="split button" color={"secondary"}>
                <SecondaryButton disabled={!expanded || isDocDownloading} id="download_button"
                        onClick={handleDownloadClick}>{t('report.download.' + downloadFormats[selectedIndex])}</SecondaryButton>
                <SecondaryButton
                  id="download_option_button"
                  size="small"
                  aria-controls={openDownload ? 'split-button-menu' : undefined}
                  aria-expanded={openDownload ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleDownloadToggle}
                >
                  <ArrowDropDownIcon/>
                </SecondaryButton>
              </ButtonGroup>
              <Popper open={openDownload} anchorEl={anchorRefDownload.current} role={undefined} transition
                      disablePortal style={{zIndex: 1}}>
                {({TransitionProps, placement}) => (
                  <Grow
                    {...TransitionProps}
                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleDownloadClose}>
                        <MenuList id="download_option_menu">
                          {downloadFormats.map((option, index) => (
                            <MenuItem style={{zIndex: 9}}
                                      key={option}
                                      selected={index === selectedIndex}
                                      onClick={event => handleMenuItemDownloadClick(event, index)}
                            >
                              {t('report.download.' + option)}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
              <ButtonGroup variant="outlined" ref={anchorRefMail} aria-label="split button" style={{marginLeft: 10}}
                           color={"secondary"}>
                <SecondaryButton disabled={!expanded} id="mail_button"
                        onClick={handleMailClick}>{t('report.download.mail.' + downloadFormats[selectedIndexMail])}</SecondaryButton>
                <SecondaryButton
                  id="mail_option_button"
                  size="small"
                  aria-controls={openMail ? 'split-button-menu' : undefined}
                  aria-expanded={openMail ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={handleMailToggle}
                >
                  <ArrowDropDownIcon/>
                </SecondaryButton>
              </ButtonGroup>
              <Popper open={openMail} anchorEl={anchorRefMail.current} role={undefined} transition disablePortal
                      style={{zIndex: 1}}>
                {({TransitionProps, placement}) => (
                  <Grow
                    {...TransitionProps}
                    style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleMailClose}>
                        <MenuList id="mail_option_menu">
                          {downloadFormats.map((option, index) => (
                            <MenuItem
                              key={option}
                              selected={index === selectedIndexMail}
                              onClick={event => handleMenuItemMailClick(event, index)}
                            >
                              {t('report.download.mail.' + option)}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
          <div style={{padding: 30, position: 'relative'}} id="report_preview_section">
            {isLoader &&
            <div className={classes.loaderWrapper}><CircularProgress style={{margin: 'auto'}} size={55}/></div>}
            <iframe height={600} width={'100%'} align="center" src={reportUrl} id="preview_iframe"></iframe>
            {downloadUrl && <iframe style={{display: 'none'}} src={downloadUrl}></iframe>}
          </div>
        </Grid>
      </Grid>
    </div>)
}

export default NewReport;

function getPredefinedDates() {
  return axios.get('/icash/report/taxaudit/dates');
}

function resolveReportUrl(type, groupBy, date, format, isMail, chosenFilters, allFilters) {
  return axios.post('/icash/report/nr/' + type + (groupBy ? '_' : '') + groupBy
    + '/' + format + '/' + (isMail ? 'mail' : 'jr'),
    {period: resolveFromToForPeriods(date), filters: filterToObject(chosenFilters, allFilters)});
}

function getFilterMetadata() {
  return axios.get('/icash/report/report_filter/nr/metadata.json');
}
