(function () {
  'use strict';
  angular.module('gf.hardware.paymentDevice', ['ui.router'])

    .run(function ($templateCache) {
      $templateCache.put('app/template/hardware/payment-device/payment_device_info_alert.html', require('../template/hardware/payment-device/payment_device_info_alert.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/sixtim_config.html', require('../template/hardware/payment-device/payment-device-config/sixtim_config.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/pay_nl_config.html', require('../template/hardware/payment-device/payment-device-config/pay_nl_config.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/verifone_config.html', require('../template/hardware/payment-device/payment-device-config/verifone_config.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/lightspeed_payments_config.html', require('../template/hardware/payment-device/payment-device-config/lightspeed_payments_config.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/pay-nl-tooltips/api_code_en.html', require('../template/hardware/payment-device/payment-device-config/pay-nl-tooltips/api_code_en.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/pay-nl-tooltips/api_code_nl.html', require('../template/hardware/payment-device/payment-device-config/pay-nl-tooltips/api_code_nl.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/pay-nl-tooltips/api_token_en.html', require('../template/hardware/payment-device/payment-device-config/pay-nl-tooltips/api_token_en.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/pay-nl-tooltips/api_token_nl.html', require('../template/hardware/payment-device/payment-device-config/pay-nl-tooltips/api_token_nl.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/pay-nl-tooltips/location_id_en.html', require('../template/hardware/payment-device/payment-device-config/pay-nl-tooltips/location_id_en.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/pay-nl-tooltips/location_id_nl.html', require('../template/hardware/payment-device/payment-device-config/pay-nl-tooltips/location_id_nl.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/pay-nl-tooltips/terminal_id_en.html', require('../template/hardware/payment-device/payment-device-config/pay-nl-tooltips/terminal_id_en.html'));
      $templateCache.put('app/template/hardware/payment-device/payment-device-config/pay-nl-tooltips/terminal_id_nl.html', require('../template/hardware/payment-device/payment-device-config/pay-nl-tooltips/terminal_id_nl.html'));
    })

    .factory('PaymentDevice', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/payment_device/:id', {}, {
        edit: {method: 'PUT'}
      });
    })
    .factory('PaymentDeviceMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/payment_device/metadata');
    })
    .factory('PaymentDeviceContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/hardware/payment_device/context');
    })

    .config(function ($stateProvider) {

      var paymentDeviceMetadataResolve = function (PaymentDeviceMetadata) {
        return PaymentDeviceMetadata.get().$promise;
      };

      var paymentDeviceContextResolve = function (PaymentDeviceContext) {
        return PaymentDeviceContext.get().$promise;
      };

      $stateProvider
        .state('page.hardwarePaymentDevice', {
          url: '/hardware/payment_device',
          template: require('../template/hardware/payment-device/payment_devices.html'),
          controller: 'PaymentDeviceGridCtrl',
          controllerAs: '$ctrl',
          resolve: {
            paymentDeviceMetadata: paymentDeviceMetadataResolve,
            paymentDeviceContext: paymentDeviceContextResolve
          },
          title: 'paymentDevice'
        })
        .state('page.hardwarePaymentDeviceDetails', {
          url: '/hardware/payment_device/:id',
          template: require('../template/hardware/payment-device/payment_device.html'),
          controller: 'PaymentDeviceCtrl',
          controllerAs: '$ctrl',
          resolve: {
            paymentDeviceMetadata: paymentDeviceMetadataResolve,
            paymentDeviceContext: paymentDeviceContextResolve,
            paymentDevice: function (PaymentDevice, $stateParams) {
              return PaymentDevice.get({id: $stateParams.id}).$promise;
            }
          },
          title: 'paymentDevice'
        })
        .state('page.hardwarePaymentDeviceAdd', {
          url: '/hardware/payment_device/add',
          template: require('../template/hardware/payment-device/payment_device.html'),
          controller: 'PaymentDeviceCtrl',
          controllerAs: '$ctrl',
          resolve: {
            paymentDeviceMetadata: paymentDeviceMetadataResolve,
            paymentDeviceContext: paymentDeviceContextResolve,
            paymentDevice: function (PaymentDevice) {
              return PaymentDevice.get({id: 'add'}).$promise;
            }
          },
          title: 'paymentDevice'
        })
    })

    .controller('PaymentDeviceGridCtrl',
      function (paymentDeviceMetadata, paymentDeviceContext, securityService) {
        var self = this;

        self.metadata = paymentDeviceMetadata;
        self.context = paymentDeviceContext;


        var columns = [
          {
            name: 'id',
            displayName: 'paymentDevice.id',
            width: 70,
            visible: false
          },
          {
            name: 'type',
            displayName: 'paymentDevice.type',
            width: 200,
            cellFilter: "prefixFilter:'paymentDevice.' | translate",
            editDropdownFilter: "prefixFilter:'paymentDevice.' | translate",
            enableCellEdit: false
          },
          {
            name: 'name',
            displayName: 'paymentDevice.name'
          }
        ];

        if (securityService.hasAnyFeature('SIXTIM') || securityService.hasAnyFeature('VERIFONE')) {
          columns.push({
            name: 'configuration.address',
            field: 'configuration.address',
            displayName: 'paymentDevice.address',
            cellEditableCondition: function ($scope) {
              return $scope.row.entity.type.name === 'SIXTIM' || $scope.row.entity.type.name === 'VERIFONE';
            }
          })
        }

        if (securityService.hasAnyFeature('VERIFONE')) {
          columns.push({
            name: 'configuration.serialNumber',
            field: 'configuration.serialNumber',
            displayName: 'paymentDevice.serialNumber',
            cellEditableCondition: function ($scope) {
              return $scope.row.entity.type.name === 'VERIFONE';
            }
          })
        }

        if (securityService.hasAnyFeature('PAY_NL')) {
          columns.push({
              name: 'configuration.locationId',
              field: 'configuration.locationId',
              displayName: 'paymentDevice.locationId',
              cellEditableCondition: function ($scope) {
                return $scope.row.entity.type.name === 'PAY_NL';
              }
            },
            {
              name: 'configuration.serialNumber',
              field: 'configuration.serialNumber',
              displayName: 'paymentDevice.serialNumber',
              cellEditableCondition: function ($scope) {
                return $scope.row.entity.type.name === 'PAY_NL';
              }
            },
            {
              name: 'configuration.apiCode',
              field: 'configuration.apiCode',
              displayName: 'paymentDevice.apiCode',
              cellEditableCondition: function ($scope) {
                return $scope.row.entity.type.name === 'PAY_NL';
              }
            },
            {
              name: 'configuration.apiToken',
              field: 'configuration.apiToken',
              displayName: 'paymentDevice.apiToken',
              cellEditableCondition: function ($scope) {
                return $scope.row.entity.type.name === 'PAY_NL';
              }
            })
        }

        if (securityService.hasAnyFeature('LIGHTSPEED_PAYMENTS')) {
          columns.push(
            {
              name: 'ipAddress',
              field: 'configuration.ipAddress',
              displayName: 'paymentDevice.address',
              cellEditableCondition: function ($scope) {
                return $scope.row.entity.type.name === 'LIGHTSPEED_PAYMENTS';
              }
            },
            {
              name: 'terminalId',
              field: 'configuration.terminalId',
              displayName: 'paymentDevice.terminalId',
              cellEditableCondition: function ($scope) {
                return $scope.row.entity.type.name === 'LIGHTSPEED_PAYMENTS';
              }
            },
            {
              name: 'handlesPrinting',
              field: 'configuration.handlesPrinting',
              displayName: 'paymentDevice.handlesPrinting',
              cellEditableCondition: function ($scope) {
                return $scope.row.entity.type.name === 'LIGHTSPEED_PAYMENTS';
              }
            }
          )
        }

        columns.push({
          name: 'operate',
          actions: ['edit', 'delete']
        });

        self.config = {
          urlRoute: '/hardware/payment_device',
          enableAdd: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: columns
        };
      })

    .controller('PaymentDeviceCtrl',
      function (paymentDevice, paymentDeviceMetadata, paymentDeviceContext, notifyManager, $translate, $state, $scope, $uibModal, $sce, securityService) {
        var self = this;

        self.advanced = false;
        self.advancedMode = false;

        self.paymentDevice = paymentDevice;
        self.metadata = paymentDeviceMetadata;
        self.context = paymentDeviceContext;

        self.getPopoverTemplate = function (name) {
          switch (securityService.context.currentLanguageTag) {
            case 'en':
              return 'app/template/hardware/payment-device/payment-device-config/pay-nl-tooltips/' + name + '_en.html';
            case 'nl':
              return 'app/template/hardware/payment-device/payment-device-config/pay-nl-tooltips/' + name + '_nl.html';
            default:
              return 'app/template/hardware/payment-device/payment-device-config/pay-nl-tooltips/' + name + '_en.html';
          }
        };

        if (!self.paymentDevice.configuration) {
          self.paymentDevice.configuration = {};
        }

        self.save = function () {
          if (self.paymentDeviceForm.$valid) {
            createOrUpdate(self.paymentDevice)
              .then(function () {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go('page.hardwarePaymentDevice')
              }, function () {
                notifyManager.error($translate.instant('settings.save.failed'));
              });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

        self.goBack = function () {
          $state.go('page.hardwarePaymentDevice')
        };

        function createOrUpdate(paymentDevice) {
          return paymentDevice.id ?
            paymentDevice.$edit({id: self.paymentDevice.id}) :
            paymentDevice.$save();
        }

        self.switchAdvancedMode = function () {
          if (self.advanced) {
            self.previousConfiguration = _.clone(self.paymentDevice.configuration);
            self.advancedMode = true;
          } else {
            if (!self.paymentDeviceForm.configuration.$error.validjson) {
              self.advancedMode = false;
              return
            }
            self.advanced = true;
            $uibModal.open({
              template: require('../template/hardware/payment-device/revert_advanced_editing.html'),
              windowClass: 'gf-modal'
            }).result
              .then(function () {
                self.advanced = false;
                self.advancedMode = false;
                self.paymentDevice.configuration = self.previousConfiguration;
              });
          }
        };

        self.switchType = function () {
          self.paymentDevice.configuration = {};
          if (self.paymentDevice.type.name === 'PAY_NL') {
            self.paymentDevice.configuration = {
              server: 'https://rest-api.pay.nl/v7/'
            }
          }
        }
      })

    .directive('jsonEditor', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, modelCtrl) {

          modelCtrl.$parsers.push(function (modelValue) {
            try {
              var result = JSON.parse(modelValue);
            } catch (e) {
              modelCtrl.$setValidity('validjson', false);
              return;
            }
            modelCtrl.$setValidity('validjson', true);
            return result;
          });

          modelCtrl.$formatters.push(function (modelValue) {
            return JSON.stringify(modelValue, undefined, 4);
          });
        }
      }
    })

})();
