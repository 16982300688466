(function () {
  'use strict';
  angular.module('gf.masterdata.article')

    .constant('articleModeCode', {
      REGULAR: 'REGULAR',
      CONSTRAINTS: 'CONSTRAINTS',
      AGGREGATED: 'AGGREGATED',

      numberToTextCode: function (numberCode) {
        switch (numberCode) {
          case 0:
            return this.REGULAR;
          case 1:
            return this.CONSTRAINTS;
          case 2:
            return this.AGGREGATED;
          case 3:
            return this.AGGREGATED;
        }
      },

      textToNumberCode: function (textCode, isAggShown) {
        switch (textCode) {
          case (this.REGULAR):
            return 0;
          case (this.CONSTRAINTS):
            return 1;
          case (this.AGGREGATED):
            return !isAggShown ? 2 : 3;
        }
      },

      isAggerated: function (mode) {
        return this.numberToTextCode(mode) === this.AGGREGATED;
      }
    })

    .constant('TYPE_AHEAD_DEFAULT_URL', '/icash/masterdata/article')

    .component('gfArticleModeSetup', {
      template: require('../template/page/masterdata/article_mode_setup.html'),
      bindings: {
        article: '<',
        isUsedAsAggregatedItem: '<',
        config: '<'
      },
      controller: function ($uibModal, $translate, articleModeCode, securityService, $http, $q, notifyManager) {
        var self = this;

        self.security = securityService;

        this.$onInit = function () {
          self.constraintSettings = {
            deleteOriginal: self.article.constraints[0] ? self.article.constraints[0].deleteOriginal : false,
            newPos: self.article.constraints[0] ? self.article.constraints[0].newPos : false
          };

          self.article.modeTextCode = articleModeCode.numberToTextCode(self.article.mode);

          if (_.isNil(self.config)) {
            self.config = {};
          }

          if (_.isNil(self.config.isEnabledAggregated)) {
            self.config.isEnabledAggregated = self.security.hasAnyFeature('SET_OF_ARTICLES');
          }
        };

        function clearConstraintSettings() {
          self.constraintSettings = {
            deleteOriginal: false,
            newPos: false
          };
        }

        self.setDeleteOriginal = function (value) {
          self.article.constraints.forEach(function (constraint) {
            constraint.deleteOriginal = value;
          })
        };

        self.setNewPos = function (value) {
          self.article.constraints.forEach(function (constraint) {
            constraint.newPos = value;
          })
        };

        self.addConstraint = function () {
          self.article.constraints = [
            {
              name: $translate.instant('articleConstraint.step'),
              articles: [],
              step: 0,
              deleteOriginal: self.constraintSettings.deleteOriginal,
              newPos: self.constraintSettings.newPos
            }
          ];
        };

        self.changeMode = function (mode) {
          if (!self.article.constraints || self.article.constraints.length === 0 ||
            self.article.modeTextCode === articleModeCode.REGULAR) {
            switchMode(mode);
            return;
          }

          $uibModal.open({
            template: require('../template/change_article_mode_confirmation.html'),
            windowClass: 'gf-modal',
            controller: function ($uibModalInstance) {
              this.ok = $uibModalInstance.close;
              this.cancel = $uibModalInstance.dismiss;
            },
            controllerAs: '$ctrl'
          }).result.then(function () {
            self.article.constraints = [];
            switchMode(mode);
          });
        };

        self.fixMode = function (mode) {
          confirmFixing(mode)
            .then(function () {
              $http({
                method: 'POST',
                url: '/icash/masterdata/article/' + self.article.id + '/fix_mode',
                params: {mode: mode}
              }).then(function () {
                notifyManager.success($translate.instant('article.mode.fixed'));

                if (mode === articleModeCode.REGULAR) {
                  self.article.constraints = [];
                }
                switchMode(mode);
              }, function () {
                notifyManager.error($translate.instant('error.general'));
              })
            })
        };

        function confirmFixing(mode) {
          var deffer = $q.defer();

          if (mode === articleModeCode.REGULAR) {
            $uibModal.open({
              template: require('../template/fix_article_mode_confirmation.html'),
              windowClass: 'gf-modal',
              controller: function ($uibModalInstance) {
                this.ok = $uibModalInstance.close;
                this.cancel = $uibModalInstance.dismiss;
              },
              controllerAs: '$ctrl'
            }).result.then(function () {
              deffer.resolve();
            });
          } else {
            deffer.resolve();
          }

          return deffer.promise;
        }

        function switchMode(textModeCode) {
          self.article.modeTextCode = textModeCode;
          self.article.mode = articleModeCode.textToNumberCode(textModeCode);
          clearConstraintSettings();
        }

        self.modeCode = articleModeCode;

      }
    })
    .component('gfAggregated', {
      template: require('../template/page/masterdata/article_mode_aggregated.html'),
      bindings: {
        items: '=',
        article: '<',
        config: '<'
      },
      controller: function ($stateParams, articleModeCode, aggregatedPriceCalculator, $uibModal,
                            notifyManager, $translate, $q, $resource, TYPE_AHEAD_DEFAULT_URL) {
        var self = this;

        var ArticleForAggregation;

        this.$onInit = function () {
          self.showAggregated = self.article.mode === 3;
          self.article.calculatedTotalPrice = aggregatedPriceCalculator.calculateTotalPrice(self.items);

          var typeAheadBaseUrl = _
            .chain(self.config)
            .get('baseUrl')
            .defaultTo(TYPE_AHEAD_DEFAULT_URL)
            .value();
          ArticleForAggregation = $resource(typeAheadBaseUrl + '/:articleId/aggregated/typeahead.json');
        };

        self.resolveArticleMode = function () {
          self.article.mode = articleModeCode.textToNumberCode(articleModeCode.AGGREGATED, self.showAggregated);
        };

        self.getArticlesTypeahead = function (val, articleId) {
          return ArticleForAggregation.query({
            articleId: articleId || 0,
            keyword: val
          }).$promise;
        };

        self.onArticleSelect = function (article) {

          // don't add the item if it has been already added
          var isDuplicate = !!_.find(self.items, function (item) {
            return item.articles[0].id === article.id;
          });
          if (isDuplicate) {
            self.newArticleItem = null;
            return;
          }

          isPossibleToAdd(self.article, article)
            .then(function () {
              definePrice(article)
                .then(function (price) {
                  addNewItem(article, price);
                });
            }, function (message) {
              self.newArticleItem = null;
              notifyManager.error($translate.instant(message));
            });

        };

        function isPossibleToAdd(parentArticle, itemArticle) {
          var deffer = $q.defer();

          if (!isRegularArticle(itemArticle)) {
            deffer.reject('message.article.aggregated.only.regular');
            return deffer.promise;
          }

          if (!isSameVatRates(parentArticle, itemArticle)) {
            deffer.reject('message.article.aggregated.same.vat');
            return deffer.promise;
          }

          if (!isSameDiscountType(parentArticle, itemArticle)) {
            deffer.reject('message.article.aggregated.same.discount.type');
            return deffer.promise;
          }

          deffer.resolve();
          return deffer.promise;
        }

        function isRegularArticle(article) {
          return article.mode === 0;
        }

        function isSameVatRates(article1, article2) {
          var group1 = article1.articleGroup;
          var group2 = article2.articleGroup;
          if (group1 && group2) {
            return group1.internalVatRate === group2.internalVatRate &&
              group1.externalVatRate === group2.externalVatRate;
          }
          return false;
        }

        function isSameDiscountType(article1, article2) {
          var group1 = article1.articleGroup;
          var group2 = article2.articleGroup;
          if (group1 && group2) {
            return group1.discountable === group2.discountable;
          }
          return false;
        }

        function addNewItem(article, price) {
          self.items.push({
            name: article.name,
            articles: [article],
            step: self.items.length ? _.last(self.items).step + 1 : 0,
            price: price
          });

          self.newArticleItem = null;
          self.updateTotalPrice(); //todo update total price by event or watch
        }

        function definePrice(article) {
          var deffer = $q.defer();
          if (!article.fixedPrice) {
            $uibModal
              .open({
                template: require('../template/add_price_to_aggregated_item.html'),
                windowClass: 'gf-modal',
                controller: function ($scope, $uibModalInstance) {
                  var self = this;
                  self.article = article;
                  this.ok = function () {
                    if ($scope.setPriceForm.$valid) {
                      $uibModalInstance.close(self.price);
                      return;
                    }
                  };
                  this.cancel = $uibModalInstance.dismiss;
                },
                controllerAs: '$ctrl'
              })
              .result
              .then(function (price) {
                deffer.resolve(price);
              }, function () {
                deffer.reject();
              });
          } else {
            deffer.resolve(article.price);
          }
          return deffer.promise;
        }

        self.removeAll = function () {
          self.items.length = 0; // clear array
          self.updateTotalPrice();
        };

        self.removeItem = function (index) {
          self.items.splice(index, 1);
          self.updateTotalPrice();
        };

        self.updateTotalPrice = function () {
          self.article.calculatedTotalPrice = aggregatedPriceCalculator.calculateTotalPrice(self.items);
        };

        self.prorateTotalPrice = function () {
          aggregatedPriceCalculator.prorateTotalPrice(self.article.calculatedTotalPrice, self.items); //todo find more elegant solution
        };

        self.validateAndFixTotalPrice = function () {
          var freePriceTotal = aggregatedPriceCalculator.calculateFreePriceTotal(self.items);
          if (freePriceTotal > self.article.calculatedTotalPrice) {
            notifyManager.error($translate.instant('message.article.aggregated.total.price.min', {0: freePriceTotal}));
            self.article.calculatedTotalPrice = freePriceTotal; //todo think about move fixing to another method
          }
        };

        self.resetCalculator = function () {
          aggregatedPriceCalculator.clearProportions();
        };
      }
    })
    .service('aggregatedPriceCalculator',
      function () {
        //save proportions until prorating is finished
        var proportions = [];

        function calculateProportions(items, total) {
          if (proportions.length === 0) {
            var checkSum = 0;
            items.forEach(function (item) {
              var proportion = item.price && total ? item.price / total : 0;
              proportions.push(proportion);
              checkSum += proportion;
            });
            if (checkSum === 0 && proportions.length > 0) {
              _.fill(proportions, 1 / proportions.length)
            }
          }
          return proportions;
        }

        function clearProportions() {
          proportions.length = 0;
        }

        function isFixedPriceArticle(article) {
          if (_.isUndefined(article.fixedPrice)) {
            return true;
          }
          return article.fixedPrice;
        }

        return {
          calculateTotalPrice: function (items) {
            return _.sumBy(items, 'price');
          },
          calculateFreePriceTotal: function (items) {
            return _
              .chain(items)
              .filter(function (item) {
                return !isFixedPriceArticle(item.articles[0]);
              })
              .sumBy('price')
              .value();
          },
          prorateTotalPrice: function (totalPrice, items) {

            var lastTotal = this.calculateTotalPrice(items);
            var freePriceTotal = this.calculateFreePriceTotal(items);

            var totalForProrating = totalPrice - freePriceTotal;
            var itemsForProrating = _.filter(items, function (item) {
              return isFixedPriceArticle(item.articles[0]);
            });

            var proportionsArr = calculateProportions(itemsForProrating, (lastTotal - freePriceTotal));
            itemsForProrating.forEach(function (item, index) {
              item.price = totalForProrating > 0 ? _.round(totalForProrating * proportionsArr[index], 2) : 0;
            });

            if (totalForProrating > 0) {
              var calculatedTotal = _.chain(itemsForProrating).sumBy('price').round(2).value();
              var precision = _.round(totalForProrating - calculatedTotal, 2);
              _.last(itemsForProrating).price = _.round(_.last(itemsForProrating).price + precision, 2);
            }
          },
          clearProportions: clearProportions
        }
      }
    )
    .directive('gfArticleConstraint',
      function ($http, $stateParams, dialogs, $translate, $resource, TYPE_AHEAD_DEFAULT_URL) {
        return {
          transclude: true,
          scope: {
            article: '=',
            step: '=',
            parentsIds: '=',
            groupMode: '=',
            config: '='
          },
          template: require('../template/page/masterdata/article_constraint.html'),
          link: function ($scope) {

            $scope.constraint = $scope.article.constraints[$scope.step];

            var typeAheadBaseUrl = _
              .chain($scope.config)
              .get('baseUrl')
              .defaultTo(TYPE_AHEAD_DEFAULT_URL)
              .value();

            var ArticleTypeahead = $resource(typeAheadBaseUrl + '/:articleId/article_constraint/typeahead_article.json');

            $scope.getArticlesTypeahead = function (val, articleId) {
              //var stateParams = $state.current.params;
              return ArticleTypeahead.query({
                articleId: articleId || 0,
                keyword: val
              }).$promise.then(function (data) {
                if (!$scope.groupMode) {
                  return data;
                }

                return _.filter(data, function (item) {
                  return ($scope.parentsIds.indexOf(item.id) === -1) && (!item.constraints || item.constraints.length === 0 || !_.find(item.constraints, function (item) {
                    return item.articles && item.articles.length > 0
                  }));
                })
              });
            };

            $scope.onArticleSelect = function (value) {
              var chosenArticles = $scope.article.constraints[$scope.step].articles;
              // don't add the item if it has been already added
              for (var i = 0; i < chosenArticles.length; i++) {
                if (value.id === chosenArticles[i].id) {
                  $scope.article.newArticleItem = null;
                  return;
                }
              }

              chosenArticles.push(value);

              // clear input after select
              $scope.article.newArticleItem = null;
            };

            $scope.removeAll = function () {
              dialogs.confirm($translate.instant('pleaseConfirm'), $translate.instant('jsp.delete'))
                .result.then(function () {
                $scope.article.constraints[$scope.step].articles = [];
              });
            };

            $scope.removeArticle = function ($index) {
              $scope.article.constraints[$scope.step].articles.splice($index, 1);
            };

            $scope.addAnotherConstraint = function () {
              var nextStep = $scope.article.constraints[$scope.article.constraints.length - 1].step + 1;
              $scope.article.constraints.push({
                name: $translate.instant('articleConstraint.step'),
                articles: [],
                step: nextStep,
                deleteOriginal: $scope.article.constraints[0].deleteOriginal,
                newPos: $scope.article.constraints[0].newPos
              });
            };

            $scope.removeStep = function () {
              $scope.article.constraints.splice($scope.step, 1);
            };

            $scope.toggleEditTitleMode = function () {
              $scope.editTitleMode = !$scope.editTitleMode;
              if ($scope.editTitleMode) {
                $scope.$broadcast('editTitleMode');
              }
            }
          }
        }
      });
})();
