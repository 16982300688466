import React, {useEffect} from 'react';
import {
  Grid,
  Typography,
  FormControlLabel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  FormLabel,
  RadioGroup,
  Radio,
  ExpansionPanel,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {makeStyles} from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  setUrlParameters,
  getFiltersForReportType,
  isAutopreviewPeriod,
  filterToUrl,
  getAvailableForType
} from './ReportUtils';

export default function ReportType({
                                     reportTypes, setTypeFilters, setChosenFilters, expanded, setExpanded, groupBy,
                                     setGroupBy, filtersMetadata, handleGetPreview, chosenDate, isAutopreview,
                                     setSettingChanged, chosenFilters, allFilters
                                   }) {

  const useStyles = makeStyles({
    legend: {
      borderBottom: 'none',
      paddingTop: 10
    }
  });
  const {t} = useTranslation();

  const classes = useStyles();

  const handleExpand = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel.name : false);
    setUrlParameters('type', isExpanded ? panel.name : '');

    let typeFilters = _.find(reportTypes, {name: panel.name}).filters;
    setTypeFilters(getFiltersForReportType(allFilters, filtersMetadata, typeFilters));
    setChosenFilters(getAvailableForType(chosenFilters, typeFilters));
    storeDataForReportType(panel, isExpanded);

    setSettingChanged(true);
  };

  function storeDataForReportType(currentType, isExpanded) {
    let savedSettings = [];
    let previousTypeState = {
      type: expanded,
      groupBy: groupBy,
      filters: chosenFilters
    };
    let settingsFromStorage = sessionStorage.getItem('reportTypeState');
    if (!settingsFromStorage) {
      setGroupBy('');
      sessionStorage.setItem('reportTypeState', JSON.stringify([previousTypeState]));
    } else {
      savedSettings = JSON.parse(settingsFromStorage);
      saveTypeToStorage();
      if (isExpanded) {
        getTypeFromStorage();
      }
    }

    function saveTypeToStorage() {
      var index = _.findIndex(savedSettings, {type: expanded});
      index > -1 ? savedSettings.splice(index, 1, previousTypeState) : savedSettings.push(previousTypeState);
      sessionStorage.setItem('reportTypeState', JSON.stringify(savedSettings));
    }

    function getTypeFromStorage() {
      var currentTypeIndex = _.findIndex(savedSettings, {type: currentType.name});
      if (currentTypeIndex > -1) {
        setGroupBy(savedSettings[currentTypeIndex].groupBy);
        setChosenFilters(savedSettings[currentTypeIndex].filters);
        setUrlParameters('groupBy', savedSettings[currentTypeIndex].groupBy);
        setUrlParameters('filter', JSON.stringify(filterToUrl(savedSettings[currentTypeIndex].filters)));
      } else {
        setChosenFilters(_.filter(chosenFilters, function (f) {
          return _.includes(_.find(reportTypes, {name: currentType.name}).filters, f.filter)
        }));
        setGroupBy('');
      }
    }
  }

  const handleGroupByChange = event => {
    setGroupBy(event.target.value);
    setUrlParameters('groupBy', event.target.value);
    setSettingChanged(true);
  };

  useEffect(() => {
    if (expanded && isAutopreview && isAutopreviewPeriod(chosenDate)) {
      handleGetPreview();
    }
  }, [groupBy]);

  return (
    <div id="report_type_panel">
      {reportTypes.map((type, index) => (
        <ExpansionPanel key={index} expanded={expanded === type.name}
                        onChange={handleExpand(type)} id={"report_type_" + type.name}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls={type.name + "_content"}
            id={type.name + "_header"}
          >
            <Typography>{t('report.reportType' + type.name)}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container item xs={12}>
              <RadioGroup id={type.name + '_groups'} aria-label="groupBy" name="groupBy" value={groupBy}
                          onChange={handleGroupByChange}>
                <FormControlLabel value=''
                                  control={<Radio color="primary"/>}
                                  label={t('option.default')}/>
                <FormLabel component="legend" className={classes.legend}>{t('report.label.groupBy')}:</FormLabel>
                {type.groups.map((group, index) => (
                  <FormControlLabel id={type.name + '_group_' + group} key={index} value={group}
                                    control={<Radio color="primary"/>}
                                    label={t('report.reportGroup' + group)}/>
                ))}
              </RadioGroup>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </div>
  );
}
