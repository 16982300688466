(function () {
  'use strict';
  angular.module('gf.validations', ['gf.utils', 'gf.resources'])
    .factory('gfStandardValidations', ['UniqueUsername', 'UniqueOperatorUsername', 'UniqueOperatorCode', 'UniqueOperatorDeviceUDID',
      'UniqueOperatorDeviceUniqueIP', 'UniqueWaiterLoginPin', 'UniqueWaiterNumber', 'UniqueVatMark', 'UniqueTablePLU',
      'UniqueDeviceUDID', 'UniqueDeviceUniqueIP', 'UniqueArticleSKU', 'UniqueArticlePLU', 'UniqueArticleGroupPLU', 'OrderScreenValidate',
      'UniqueOperatorUserMail', '$cookies', 'UniqueReasonCategory', 'SinglePurposeVoucherCheck', 'CashPaymentTypeCheck',

      function (UniqueUsername, UniqueOperatorUsername, UniqueOperatorCode, UniqueOperatorDeviceUDID,
                UniqueOperatorDeviceUniqueIP, UniqueWaiterLoginPin, UniqueWaiterNumber, UniqueVatMark, UniqueTablePLU,
                UniqueDeviceUDID, UniqueDeviceUniqueIP, UniqueArticleSKU, UniqueArticlePLU, UniqueArticleGroupPLU, OrderScreenValidate,
                UniqueOperatorUserMail, $cookies, UniqueReasonCategory, SinglePurposeVoucherCheck, CashPaymentTypeCheck) {
        function isUndefined(value) {
          return typeof value == 'undefined';
        }

        function isEmpty(value) {
          return isUndefined(value) || value === '' || value === null || value !== value
            || ($.isArray(value) && value.length <= 0);
        }

        return {
          "ng-pattern": function (ctrl, regexp) {
            return function (value) {
              if (isEmpty(value) || new RegExp(regexp).test(value)) {
                ctrl.$setValidity('pattern', true);
                ctrl.$setValidity('ng-pattern', true);
                return value;
              } else {
                ctrl.$setValidity('pattern', false);
                ctrl.$setValidity('ng-pattern', false);
                return value;
              }
            }
          },
          "ng-minlength": function (ctrl, minlength) {
            return function (value) {
              if (!isEmpty(value) && value.length < minlength) {
                ctrl.$setValidity('minlength', false);
                ctrl.$setValidity('ng-minlength', false);
                return value;
              } else {
                ctrl.$setValidity('minlength', true);
                ctrl.$setValidity('ng-minlength', true);
                return value;
              }
            }
          },
          "ng-maxlength": function (ctrl, maxlength) {
            return function (value) {
              if (!isEmpty(value) && value.length > maxlength) {
                ctrl.$setValidity('maxlength', false);
                ctrl.$setValidity('ng-maxlength', false);
                return value;
              } else {
                ctrl.$setValidity('maxlength', true);
                ctrl.$setValidity('ng-maxlength', true);
                return value;
              }
            }
          },
          "min": function (ctrl, min) {
            return function (value) {
              if (!isEmpty(value) && value < min) {
                ctrl.$setValidity('min', false);
                return value;
              } else {
                ctrl.$setValidity('min', true);
                return value;
              }
            }
          },
          "max": function (ctrl, max) {
            return function (value) {
              if (!isEmpty(value) && value > max) {
                ctrl.$setValidity('max', false);
                return value;
              } else {
                ctrl.$setValidity('max', true);
                return value;
              }
            }
          },
          "required": function (ctrl) {
            return function (value) {
              if (isEmpty(value)) {
                ctrl.$setValidity('required', false);
                return value;
              } else {
                ctrl.$setValidity('required', true);
                return value;
              }
            }
          },
          "gf-uniquename": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueUsername.replace(':userId', entity.id).replace(':type', validator.type).replace(':username', value),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniquename', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniqueoperatorname": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueOperatorUsername.replace(':userId', entity.id ? entity.id : '0').replace(':username', value),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniqueoperatorname', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-email": function (ctrl) {
            var validator = function (value) {
              var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              ctrl.$setValidity('gf-email', re.test(String(value).toLowerCase()));
              return value;
            };
            return validator;
          },
          "gf-password": function (ctrl) {
            var validator = function (value) {
              var result = false
              if (value) {
                var groupCount =
                  ~~/[a-z]/.test(value) +
                  ~~/[A-Z]/.test(value) +
                  ~~/[0-9]/.test(value) +
                  ~~/(?=.*?[!"#$%&'()*+,-./:;<=>?@\\\[\]^_`{|}~])/.test(value);
                result = (groupCount >= 3);
              }
              ctrl.$setValidity('gf-password', result);
              return value;
            };
            return validator;
          },
          "gf-uniqueoperatorusermail": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueOperatorUserMail.replace(':userId', entity.id ? entity.id : '0').replace(':mail', value ? value : ''),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniqueoperatorusermail', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniquecode": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueOperatorCode.replace(':customerCode', value).replace(':operatorId', entity.id),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniquecode', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniqueoperatordeviceudid": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueOperatorDeviceUDID.replace(':udid', value).replace(':deviceId', entity.id).replace(':restaurantId', entity.belongsTo.id),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniqueoperatordeviceudid', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniqueoperatordeviceipaddress": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueOperatorDeviceUniqueIP.replace(':deviceId', entity.id).replace(':restaurantId', entity.belongsTo.id) + '?ipAddress=' + value,
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniqueoperatordeviceipaddress', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniquewaiternumber": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueWaiterNumber.replace(':waiterId', entity.id ? entity.id : 0).replace(':personalNumber', value),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniquewaiternumber', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniquewaiterloginpin": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueWaiterLoginPin.replace(':waiterId', entity.id ? entity.id : 0).replace(':loginPin', value),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniquewaiterloginpin', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniquevatmark": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueVatMark.replace(':id', entity.id ? entity.id : 0).replace(':mark', value),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniquevatmark', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniquedeviceudid": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueDeviceUDID.replace(':udid', value).replace(':deviceId', entity.id).replace(':restaurantId', entity.belongsTo.id),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniquedeviceudid', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniquedeviceipaddress": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueDeviceUniqueIP.replace(':deviceId', entity.id),
                //+ entity.ipAddress + '&ipPort=' + entity.ipPort
                async: false,
                data: {
                  ipAddress: entity.ipAddress,
                  ipPort: entity.ipPort
                },
                success: function (data) {
                  ctrl.$setValidity('gf-uniquedeviceipaddress', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniquearticlesku": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueArticleSKU.replace(':sku', entity.id),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniquearticlesku', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniquearticleplu": function (ctrl) {
            var validator = function (value, entity) {
              var plus = _.filter(entity.plus, function (plu) {
                return !!plu
              });
              if (plus.length > 0) {
                $.ajax({
                  url: UniqueArticlePLU.replace(':plu', plus),
                  async: false,
                  success: function (data) {
                    ctrl.$setValidity('gf-uniquearticleplu', !data.payload.isPlu);
                  }
                });
              }
            };
            return validator;
          },
          "gf-uniquetebleplu": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueTablePLU.replace(':plu', entity.id),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniquetebleplu', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniquearticlegroupplu": function (ctrl) {
            var validator = function (value, entity) {
              $.ajax({
                url: UniqueArticleGroupPLU.replace(':plu', entity.id),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniquearticlegroupplu', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-singlepurposevouchermodeflag": function (ctrl) {
            var validator = function (value, entity) {
              if (value) {
                $.ajax({
                  url: SinglePurposeVoucherCheck.replace(':settlementFormId', entity.id),
                  async: false,
                  success: function (data) {
                    ctrl.$setValidity('gf-singlepurposevouchermodeflag', data.payload.result);
                  }
                });
              }
            };
            return validator;
          },
          "gf-uniquecoursegroupperscreensize-coursegroup": function (ctrl) {
            var validator = function (value, entity) {
              if (!value || !entity.deviceScreenSize) {
                ctrl.$setValidity('gf-uniquecoursegroupperscreensize-coursegroup', true);
                return;
              }

              $.ajax({
                url: OrderScreenValidate.replace(':orderScreenId', entity.id).replace(':deviceScreenSizeId', entity.deviceScreenSize.id).replace(':courseGroupId', value.id),
                async: false,
                dataType: 'json',
                contentType: 'application/json',
                method: 'GET',
                beforeSend: function (request) {
                  request.setRequestHeader('X-XSRF-TOKEN', $cookies.get('XSRF-TOKEN'));
                },
                success: function (data) {
                  ctrl.$setValidity('gf-uniquecoursegroupperscreensize-coursegroup', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-uniquecoursegroupperscreensize-screensize": function (ctrl) {
            var validator = function (value, entity) {
              if (!value || !entity.courseGroup) {
                ctrl.$setValidity('gf-uniquecoursegroupperscreensize-screensize', true);
                return;
              }

              $.ajax({
                url: OrderScreenValidate.replace(':orderScreenId', entity.id).replace(':courseGroupId', entity.courseGroup.id).replace(':deviceScreenSizeId', value.id),
                async: false,
                dataType: 'json',
                contentType: 'application/json',
                method: 'GET',
                beforeSend: function (request) {
                  request.setRequestHeader('X-XSRF-TOKEN', $cookies.get('XSRF-TOKEN'));
                },
                success: function (data) {
                  ctrl.$setValidity('gf-uniquecoursegroupperscreensize-screensize', data.payload.result);
                }
              });
            };
            return validator;
          },
          "gf-nonselfreferencingarticlegroup": function (ctrl) {
            var validator = function (value, entity, context) {

              if (value == undefined) {
                ctrl.$setValidity('gf-nonselfreferencingarticlegroup', true);
                return;
              }

              // check for self reference
              var itemId = entity.id;
              if (itemId == value.id) {
                ctrl.$setValidity('gf-nonselfreferencingarticlegroup', false);
                return;
              }

              // check for loops
              var looped = false;
              var parent = value.id;
              var articleGroupsModel = context.data.dependencies['parentMap'];
              do {
                if (parent == undefined) {
                  break;
                }

                looped = (parent === itemId);

                if (looped) {
                  break;
                }

                parent = articleGroupsModel[parent];
              } while (parent || looped);

              ctrl.$setValidity('gf-nonselfreferencingarticlegroup', !looped);
              return;
            };
            return validator;
          },
          "gf-uniqueexchangerate": function (ctrl) {
            var validator = function (value, entity, context) {
              var validateEntity = angular.copy(entity);
              if (value instanceof Object) {
                validateEntity.to = value;
              }

              var state = _.find(context.data.dependencies['exchangeRates'], function (exchangeRate) {
                return exchangeRate.to.id == validateEntity.to.id && validateEntity.id != exchangeRate.id;
              });
              ctrl.$setValidity('gf-uniqueexchangerate', !state);
            };
            return validator;
          },
          "gf-fiscalitalyparentgrouprestriction": function (ctrl) {
            var validator = function (value, entity) {
              if (1 <= entity.id && entity.id <= 10) {
                ctrl.$setValidity('gf-fiscalitalyparentgrouprestriction', !value);
              } else {
                ctrl.$setValidity('gf-fiscalitalyparentgrouprestriction', true);
              }
            };
            return validator;
          },
          "gf-fiscalitalysubgrouprestriction": function (ctrl) {
            var validator = function (value, entity) {
              if (11 <= entity.id && entity.id <= 99) {
                ctrl.$setValidity('gf-fiscalitalysubgrouprestriction', !!value);
              } else {
                ctrl.$setValidity('gf-fiscalitalysubgrouprestriction', true);
              }
            };
            return validator;
          },
          "gf-fiscalitalyidparentrestriction": function (ctrl) {
            var validator = function (value, entity) {
              if (value > 10) {
                ctrl.$setValidity('gf-fiscalitalyidparentrestriction', true);
                return;
              }

              ctrl.$setValidity('gf-fiscalitalyidparentrestriction', entity.addRow && !entity.parent && (1 <= value) && (value <= 10));
            };
            return validator;
          },
          "gf-fiscalitalyidsubrestriction": function (ctrl) {
            var validator = function (value, entity) {
              if (11 < value && value > 99) {
                ctrl.$setValidity('gf-fiscalitalyidsubrestriction', true);
                return;
              }
              ctrl.$setValidity('gf-fiscalitalyidsubrestriction', entity.addRow && !!entity.parent && (11 <= value) && (value <= 99));
            };
            return validator;
          },
          "gf-fiscalitalyidlimitrestriction": function (ctrl) {
            var validator = function (value, entity) {
              ctrl.$setValidity('gf-fiscalitalyidlimitrestriction', entity.addRow && (1 <= value) && (value <= 99));
            };
            return validator;
          },
          "gf-paymentmethodtypevalidate": function (ctrl) {
            var validator = function (value, entity, context) {
              if (value.name == 'CASH') {
                $.ajax({
                  url: CashPaymentTypeCheck.replace(':settlementFormId', entity.id),
                  async: false,
                  success: function (data) {
                    ctrl.$setValidity('gf-paymentmethodtypevalidate', data.payload.result);
                  }
                });
              }
            };
            return validator;
          },
          "gf-minpaymentamount": function (ctrl) {
            var validator = function (value, entity) {
              ctrl.$setValidity('gf-minpaymentamount', value == 0 || entity.maxPaymentAmount == 0 || entity.maxPaymentAmount > value);
            };
            return validator;
          },
          "gf-maxpaymentamount": function (ctrl) {
            var validator = function (value, entity) {
              ctrl.$setValidity('gf-maxpaymentamount', value == 0 || entity.minPaymentAmount == 0 || entity.minPaymentAmount < value);
            };
            return validator;
          },
          "gf-customerdiscountvalue": function (ctrl) {
            var validator = function (value, entity) {
              ctrl.$setValidity('gf-customerdiscountvalue', entity.absolute || value <= 100);
            };
            return validator;
          },
          "gf-customerdiscountabsolute": function (ctrl) {
            var validator = function (value, entity) {
              ctrl.$setValidity('gf-customerdiscountabsolute', value || entity.value <= 100);
            };
            return validator;
          },
          "gf-uniquereasoncategory": function (ctrl) {
            var validator = function (value, entity) {
              if (!entity.categoryCode) {
                return;
              }
              $.ajax({
                url: UniqueReasonCategory
                  .replace(':category', entity.categoryCode.id)
                  .replace(':accountTypeId', entity.accountType.id)
                  .replace(':id', entity.id),
                async: false,
                success: function (data) {
                  ctrl.$setValidity('gf-uniquereasoncategory', data.payload.result);
                }
              });
            };
            return validator;
          },
          'gf-discount-max-value': function (ctrl) {
            return function (value, entity) {
              ctrl.$setValidity('gf-discount-max-value', entity.absolute ? true : value <= 100);
            }
          },
          "gf-financialaccounttype": function (ctrl) {
            var validator = function (value, entity, context) {
              if (value) {
                $.ajax({
                  url: '/icash/financial/reasons/account?id=' + entity.id,
                  async: false,
                  success: function (data) {
                    ctrl.$setValidity('gf-financialaccounttype', !data)
                  }
                });
              }
            };
            return validator;
          },
          "gf-measurementunit": function (ctrl) {
            return function (value, entity) {
              if (entity.articleType && entity.articleType.measure) {
                ctrl.$setValidity('gf-measurementunit', entity.articleGroup.measurementUnit);
              }
            }
          },
          "gf-measureflagforarticletype": function (ctrl) {
            var validator = function (value, entity, context) {
              if (value) {
                $.ajax({
                  url: '/icash/restaurant_data/article_type/articles?id=' + entity.id,
                  async: false,
                  success: function (data) {
                    ctrl.$setValidity('gf-measureflagforarticletype', !data)
                  }
                });
              }
            };
            return validator;
          }
        };
      }]
    )
    /**
     * gf-valid="entityMetadata"
     * data-valid-options={form:'.form-group', name:'fieldName', formName:'subForm'}
     */
    .directive('gfValid',
      function ($compile, $translate, gfStandardValidations) {

        var config = {
          form: '.form-group'
        };

        return {
          restrict: 'A',
          require: "ngModel",
          link: function (scope, iElement, attrs, controller) {
            var opts = angular.extend(config, scope.$eval(attrs.validOptions) || {});
            var subForm = iElement.parents(opts.form);
            var name = iElement.attr("name");
            var metadata = scope.$eval(attrs.gfValid);
            var validations = metadata.columns[name] ? metadata.columns[name].angular : [];
            var messages = metadata.columns[name] ? metadata.columns[name].errorMessages : [];

            angular.forEach(validations, function (value, key) {
              controller.$parsers.push(gfStandardValidations[key](controller, value));
              controller.$formatters.push(gfStandardValidations[key](controller, value));
            });

            scope.$watch(function () {
              return (controller.$submitted || controller.$dirty) && !controller.$valid;
            }, function () {
              subForm.toggleClass("has-error", (controller.$submitted || controller.$dirty) && !controller.$valid);
            });

            angular.forEach(messages, function (message, index) {
              var elId = name + message.name + "Error";
              var el = iElement.find("#" + elId);
              var messageValue = $translate.instant(message.message, message.args);
              if (el.length === 0) {
                el = $("<small id='" + elId + "' class='help-block gf-error-message'></small>");
                iElement.after(el);


              }
              el.html('<i class="fa fa-times-circle fa-lg"></i>' + messageValue);

              scope.$watch(function () {
                return (controller.$submitted || controller.$dirty) && (controller.$error[message.keys[0]] || controller.$error[message.keys[1]]);
              }, function () {
                if ((controller.$submitted || controller.$dirty) && (controller.$error[message.keys[0]] || controller.$error[message.keys[1]])) {
                  el.show();
                } else {
                  el.hide();
                }
              });
            });

            scope.$on('gf-submitted', function () {
              controller.$submitted = true;
            });
          }
        }
      })
    /**
     * gf-valid-new="entityMetadata"
     * data-valid-options={form:'.form-group', name:'fieldName', formName:'subForm'}
     */
    .directive('gfValidNew',
      function ($compile, $translate, gfStandardValidations) {

        var config = {
          form: '.form-group'
        };

        return {
          restrict: 'A',
          require: "ngModel",
          link: function (scope, iElement, attrs, controller) {
            var subForm = iElement.parents(config.form);
            var name = iElement.attr("name");
            var metadata = scope.$eval(attrs.gfValidNew);
            var validations = metadata.fields[name].validators;

            // add standard validators by key
            angular.forEach(validations, function (value) {
              controller.$parsers.push(gfStandardValidations[value.name](controller, value.value));
              controller.$formatters.push(gfStandardValidations[value.name](controller, value.value));
            });

            scope.$watch(function () {
              return (controller.$submitted || controller.$dirty) && !controller.$valid;
            }, function () {
              subForm.toggleClass('has-error', (controller.$submitted || controller.$dirty) && !controller.$valid);
            });

            angular.forEach(validations, function (validation) {
              var elId = name + validation.name + 'Error';
              var el = iElement.find('#' + elId);
              if (validation.message) {
                if (el.length === 0) {
                  el = $("<small id='" + elId + "' class='help-block gf-error-message'></small>");
                  iElement.after(el);
                }
                el.html("<i class='fa fa-times-circle fa-lg'></i>&nbsp;<span translate='" + validation.message + "' translate-values='" + JSON.stringify(validation.args) + "'></span>");
                $compile(el)(scope);

                scope.$watch(function () {
                  return (controller.$submitted || controller.$dirty) && (controller.$error[validation.name]);
                }, function () {
                  if ((controller.$submitted || controller.$dirty) && (controller.$error[validation.name])) {
                    el.show();
                  } else {
                    el.hide();
                  }
                });
              }
            });

            scope.$on('gf-submitted', function () {
              controller.$submitted = true;
            });
          }
        }
      })
    .directive('gfUniqueIp', function ($http, $stateParams, $rootScope) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(attrs.ngModel, function (value) {

            var entity = $scope.$eval(attrs.gfUniqueIp);

            if (!entity.ipAddress || !entity.ipPort) {
              return;
            }

            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {
              //var routeParams = $state.current.params;
              var config = {
                params: {
                  ipAddress: entity.ipAddress,
                  ipPort: entity.ipPort
                }
              };
              // call to some API that returns { isValid: true } or { isValid: false }
              $http.get('/icash/hardware/device/' + ($stateParams.id || 0) + '/ip_address', config)
                .then(function (resp) {
                  //set the validity of the field
                  $scope.safeApply(function () {
                    $scope.$emit('gfChangeIpOrPort', {result: resp.data.payload.result});
                  });
                });
              $rootScope.$on('gfChangeIpOrPort', function (event, attrs) {
                ctrl.$setValidity('uniqueIP', attrs.result);
              })
            }, 200);
          })
        }
      }
    })
    .directive('gfActiveDevices', function ($http, $stateParams) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(attrs.ngModel, function (value) {
            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            if (!value) {
              ctrl.$setValidity('activeDevices', true);
              return;
            }

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {
              //var routeParams = $state.current.params;

              // call to some API that returns { isValid: true } or { isValid: false }
              $http.get('/icash/hardware/device/' + ($stateParams.id || 0) + '/activate')
                .then(function (resp) {
                  //set the validity of the field
                  $scope.safeApply(function () {
                    ctrl.$setValidity('activeDevices', resp.data.result);
                  });
                });
            }, 200);
          })
        }
      }
    })
    .directive('gfActivePrinters', function ($http, $stateParams) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(attrs.ngModel, function (value) {


            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            if (!value) {
              ctrl.$setValidity('activePrinters', true);
              return;
            }

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {
              //var routeParams = $state.current.params;

              // call to some API that returns { isValid: true } or { isValid: false }
              $http.get('/icash/hardware/printer/' + ($stateParams.id || 0) + '/activate')
                .then(function (resp) {
                  //set the validity of the field
                  $scope.safeApply(function () {
                    ctrl.$setValidity('activePrinters', resp.data.result);
                  });
                });
            }, 200);
          })
        }
      }
    })
    .directive('gfUniqueUdid', function ($http, $stateParams) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(attrs.ngModel, function (value) {

            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {
              //var routeParams = $state.current.params;

              // call to some API that returns { isValid: true } or { isValid: false }
              $http.get('/icash/hardware/device/' + ($stateParams.id || 0) + '/udid/' + value)
                .then(function (resp) {
                  //set the validity of the field
                  $scope.safeApply(function () {
                    ctrl.$setValidity('uniqueUDID', resp.data.payload.result);
                  });
                });
            }, 200);
          })
        }
      }
    })
    .directive('gfUniqueSku', function ($resource, $rootScope, appConfig, $stateParams) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          ngModel: '=ngModel',
          gfUniqueSkuArticle: '=gfUniqueSkuArticle'
        },
        link: function ($scope, element, attrs, ctrl) {
          var url = attrs.gfUniqueSku;
          $scope.$watch("ngModel", function (value) {

            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            if (!value) {
              $scope.gfUniqueSkuArticle = null;
              ctrl.$setValidity('uniquePlu', true);
              return;
            }

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {
              //var routeParams = $state.current.params;
              var resource = $resource(appConfig.baseUrl + url + "/" + ($stateParams.id || $stateParams.articleGroupId || 0) + "/sku/" + value);

              // call to some API that returns { isValid: true } or { isValid: false }
              resource.get(function (data) {
                // return conflict article
                $scope.gfUniqueSkuArticle = data.payload.article;
                //set the validity of the field
                $rootScope.safeApply(function () {
                  ctrl.$setValidity('uniquePlu', data.payload.result);
                });
              });
            }, 200);
          });
        }
      }
    })
    .directive('gfUniqueForeignCurrency', function (UniqueForeignCurrency, $stateParams, $rootScope) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(attrs.ngModel, function (value) {

            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            if (!value || !angular.isNumber(value.id)) {
              ctrl.$setValidity('uniqueCurrency', true);
              return;
            }

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {
              UniqueForeignCurrency.get({
                currencyId: value.id,
                settlementFormId: $stateParams.id ? $stateParams.id : 0
              }, function (data) {

                //set the validity of the field
                $rootScope.safeApply(function () {
                  if (data.ok) {
                    ctrl.$setValidity('uniqueCurrency', data.payload.unique);
                  }
                });
              });
            }, 200);
          })
        }
      }
    })
    .directive('gfNonSelfReferencingArticleGroup', function ($rootScope) {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          context: '=context',
          entity: '=entity',
          ngModel: '=ngModel'
        },
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch('ngModel', function (value) {

            if (value == undefined) {
              ctrl.$setValidity('nonSelfReferencing', true);
              return;
            }

            // check for self reference
            var itemId = $scope.entity.id;
            if (itemId == value.id) {
              ctrl.$setValidity('nonSelfReferencing', false);
              return;
            }

            // check for loops
            var looped = false;
            var parent = value.id;
            var articleGroupsModel = $scope.context.data.dependencies['parentMap'];
            do {
              if (parent == undefined) {
                break;
              }

              looped = (parent === itemId);

              if (looped) {
                break;
              }

              parent = articleGroupsModel[parent];
            } while (parent || looped);

            $rootScope.safeApply(function () {
              ctrl.$setValidity('nonSelfReferencing', !looped);
            });
          })
        }
      }
    })
    .directive('gfItalyFiscalArticleGroupParent', function ($rootScope, securityService) {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          entity: '=entity',
          ngModel: '=ngModel'
        },
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch('ngModel', function (value) {
            var fiscalEnable = securityService.context.fiscalRegulation && securityService.context.fiscalRegulation.enableArticleGroupsRestrictions;
            var validity = !fiscalEnable || !!value || ((1 <= $scope.entity.id) && ($scope.entity.id <= 10));

            $rootScope.safeApply(function () {
              ctrl.$setValidity('italyFiscalArticleGroupParent', validity);
            });
          })
        }
      }
    })
    .directive('gfItalyFiscalArticleGroupSub', function ($rootScope, securityService) {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          entity: '=entity',
          ngModel: '=ngModel'
        },
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch('ngModel', function (value) {
            var fiscalEnable = securityService.context.fiscalRegulation && securityService.context.fiscalRegulation.enableArticleGroupsRestrictions;
            var validity = !fiscalEnable || !value || ((11 <= $scope.entity.id) && ($scope.entity.id <= 99));

            $rootScope.safeApply(function () {
              ctrl.$setValidity('italyFiscalArticleGroupSub', validity);
            });
          })
        }
      }
    })
    .directive('gfUniquePersonalNumber', function ($http, $stateParams) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(attrs.ngModel, function (value) {

            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {
              //var routeParams = $state.current.params;

              // call to some API that returns { isValid: true } or { isValid: false }
              $http.get('/icash/masterdata/waiter/' + ($stateParams.id || 0) + '/personalNumber/' + value)
                .then(function (resp) {
                  //set the validity of the field
                  $scope.safeApply(function () {
                    ctrl.$setValidity('uniquePersonalNumber', resp.data.payload.result);
                  });
                });
            }, 200);
          })
        }
      }
    })
    .directive('gfUniqueLoginPin', function ($http, $stateParams, $rootScope) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          var id = $scope.$eval(attrs.gfUniqueLoginPin);
          $scope.$watch(attrs.ngModel, function (value) {

            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            if (!value) {
              ctrl.$setValidity('uniqueLoginPin', true);
              return;
            }

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {
              //var routeParams = $state.current.params;

              // call to some API that returns { isValid: true } or { isValid: false }
              $http.get('/icash/masterdata/waiter/' + ($stateParams.id || id || 0) + '/login_pin/' + value)
                .then(function (resp) {
                  //set the validity of the field
                  $rootScope.safeApply(function () {
                    ctrl.$setValidity('uniqueLoginPin', resp.data.payload.result);
                  });
                });
            }, 200);
          })
        }
      }
    }).directive('gfUniqueVatMark', function ($http, $stateParams) {
    var toId;
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function ($scope, element, attrs, ctrl) {
        $scope.$watch(attrs.ngModel, function (value) {

          // if there was a previous attempt, stop it.
          if (toId) clearTimeout(toId);

          if (!value) {
            ctrl.$setValidity('uniqueVatMark', true);
            return;
          }

          // start a new attempt with a delay to keep it from
          // getting too "chatty".
          toId = setTimeout(function () {
            //var routeParams = $state.current.params;

            // call to some API that returns { isValid: true } or { isValid: false }
            $http.get('/icash/financial/vat/' + ($stateParams.id || 0) + '/mark/' + value)
              .then(function (resp) {
                //set the validity of the field
                $scope.safeApply(function () {
                  if (resp.data.ok) {
                    ctrl.$setValidity('uniqueVatMark', resp.data.payload.result);
                  }
                });
              });
          }, 200);
        })
      }
    }
  })
    .directive('gfUniqueEmail', function ($http) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(attrs.ngModel, function (value) {

            var userId = attrs.gfUniqueEmail ? $scope.$eval(attrs.gfUniqueEmail) : -1;
            var admin = attrs.gfAdminData || false;

            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            if (!value) {
              ctrl.$setValidity('uniqueEmail', true);
              return;
            }

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {
              //var routeParams = $state.current.params;

              // call to some API that returns { ok: true } or { ok: false }
              $http.get('/icash/user/' + userId + "/email?email=" + encodeURIComponent(value) + "&admin=" + admin)
                .then(function (resp) {
                  //set the validity of the field
                  $scope.safeApply(function () {
                    ctrl.$setValidity('uniqueEmail', resp.data.ok);
                  });
                });
            }, 200);
          })
        }
      }
    })
    .directive('gfPasswordConfirm', [function () {
      return {
        require: "ngModel",
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch('password + ' + attrs.ngModel,
            function (value) {
              var password = $scope.$eval(attrs.gfPasswordConfirm);
              var confirmPassword = $scope.$eval(attrs.ngModel);
              var valid = password === confirmPassword;
              if ($scope.cpf) {
                $scope.cpf.ps.passwordConfirmed = valid;
              }
              if ($scope.resetPasswordForm) {
                $scope.resetPasswordForm.passwordConfirmed = valid;
              }
            });
        }
      };
    }])
    .directive('gfUniqueUsername', function ($http, $resource, $stateParams) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(attrs.ngModel, function (value) {

            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            var type = attrs.gfUniqueUsername;

            if (!value) {
              ctrl.$setValidity('uniqueUsername', true);
              return;
            }

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {
              //var routeParams = $state.current.params;

              var UniqueUsername;
              var userId;
              switch (type) {
                case 'admin':
                  UniqueUsername = $resource('/icash/admin/admin/:userId/username/:username',
                    {userId: ($stateParams.id || 0), username: value});
                  break;
                case 'reseller':
                  UniqueUsername = $resource('/icash/admin/reseller_user/:userId/username/:username',
                    {userId: ($stateParams.id || 0), username: value});
                  break;
                default :
                  UniqueUsername = $resource('/icash/operator_data/user/:userId/username/:username',
                    {userId: ($stateParams.id || 0), username: value});
                  break;
              }

              // call to some API that returns { isValid: true } or { isValid: false }
              UniqueUsername.get(function (data) {

                //set the validity of the field
                $scope.safeApply(function () {
                  ctrl.$setValidity('uniqueUsername', data.payload.result);
                });
              });
            }, 200);
          })
        }
      }
    })
    .directive('gfUniqueTableNr', function ($http, $stateParams) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(attrs.ngModel, function (value) {

            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            if (!value) {
              ctrl.$setValidity('uniqueTableNr', true);
              return;
            }

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {
              //var routeParams = $state.current.params;

              // call to some API that returns { isValid: true } or { isValid: false }
              $http.get('/icash/restaurant_data/table/' + ($stateParams.id || 0) + '/plu/' + value)
                .then(function (resp) {
                  //set the validity of the field
                  $scope.safeApply(function () {
                    ctrl.$setValidity('uniqueTableNr', resp.data.payload.result);
                  });
                });
            }, 200);
          })
        }
      }
    })
    .directive('gfUniqueCustomerCode',
      function ($http, $resource, UniqueOperatorCode, $stateParams) {
        var toId;
        return {
          restrict: 'A',
          require: 'ngModel',
          link: function ($scope, element, attrs, ctrl) {
            $scope.$watch(attrs.ngModel, function (value) {

              // if there was a previous attempt, stop it.
              if (toId) clearTimeout(toId);

              if (!value) {
                ctrl.$setValidity('uniqueCode', true);
                return;
              }

              // start a new attempt with a delay to keep it from
              // getting too "chatty".
              toId = setTimeout(function () {
                //var routeParams = $state.current.params;

                var resource = $resource(UniqueOperatorCode, {
                  operatorId: ($stateParams.id || 0),
                  customerCode: value
                });

                // call to some API that returns { isValid: true } or { isValid: false }
                resource.get(function (data) {

                  //set the validity of the field
                  $scope.safeApply(function () {
                    ctrl.$setValidity('uniqueCode', data.payload.result);
                  });
                });
              }, 200);
            })
          }
        }
      })

    .directive('gfUniqueCourseGroupPerScreenSize',
      function ($http, $resource, $rootScope, OrderScreenValidate) {
        return {
          restrict: 'A',
          require: 'ngModel',
          scope: {
            connectedField: "=connectedField",
            ngModel: '=ngModel'
          },
          link: function ($scope, element, attrs, ctrl) {
            var validate = function () {
              // if there was a previous attempt, stop it.

              var orderScreen = $scope.$parent.$eval(attrs.gfUniqueCourseGroupPerScreenSize);
              if (!orderScreen || !orderScreen.courseGroup || !orderScreen.deviceScreenSize) {
                ctrl.$setValidity('uniqueCourseGroupPerScreenSize', true);
                return;
              }

              ctrl.$setDirty(true);

              // start a new attempt with a delay to keep it from
              var validator = $resource(OrderScreenValidate, {
                orderScreenId: orderScreen.id || 0,
                courseGroupId: orderScreen.courseGroup.id,
                deviceScreenSizeId: orderScreen.deviceScreenSize.id
              }, {
                validate: {method: 'GET'}
              });

              var validatorInstance = new validator(orderScreen);
              validatorInstance.$validate(function (data) {

                //set the validity of the field
                $rootScope.safeApply(function () {
                  ctrl.$setValidity('uniqueCourseGroupPerScreenSize', data.payload.result);
                });
              });
            };

            $scope.$watch("ngModel", function (value) {
              validate();
            });

            $scope.$watch("connectedField", function (value) {
              validate();
            });

          }
        }
      })
    .directive('gfUniqueVaiFiscalId',
      function ($http, $resource, UniqueVaiFiscalId, $stateParams) {
        var toId;
        return {
          restrict: 'A',
          require: 'ngModel',
          link: function ($scope, element, attrs, ctrl) {
            $scope.$watch(attrs.ngModel, function (value) {

              // if there was a previous attempt, stop it.
              if (toId) clearTimeout(toId);

              if (!value) {
                ctrl.$setValidity('uniqueVaiFiscalId', true);
                return;
              }

              // start a new attempt with a delay to keep it from
              // getting too "chatty".
              toId = setTimeout(function () {

                var resource = $resource(UniqueVaiFiscalId, {id: ($stateParams.id || 0), fiscalId: value});

                // call to some API that returns { isValid: true } or { isValid: false }
                resource.get(function (data) {

                  //set the validity of the field
                  $scope.safeApply(function () {
                    ctrl.$setValidity('uniqueVaiFiscalId', data.payload.result);
                  });
                });
              }, 200);
            })
          }
        }
      }
    )
    .directive('gfMultiselectValidate', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {

          $scope[attrs.name + 'Count'] = null;
          $scope.$watchCollection(attrs.selectedModel, function (newCollection) {
            $scope[attrs.name + 'Count'] = (newCollection && newCollection.length) ? newCollection.length : null;
          });
        }
      }
    })
    .directive('gfTimezonesValidate', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          timezones: "=gfTimezonesValidate",
          model: "=ngModel"
        },
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch('model', function (newValue) {
            if (!newValue) {
              ctrl.$setValidity('gfTimezonesValidate', true);
              return;
            }
            for (var i = 0; i < $scope.timezones.length; i++) {
              if (element.context.value == $scope.timezones[i].name.trim()) {
                ctrl.$setValidity('gfTimezonesValidate', true);
                return;
              }
            }
            ctrl.$setValidity('gfTimezonesValidate', false);
          })
        }
      }
    })
    .directive('gfDuplicateFieldsValidate',
      function ($rootScope, $http, $resource, DuplicateFieldsValidateUrl) {
        var toId;
        return {
          restrict: 'A',
          require: 'ngModel',
          scope: {
            group: "=group",
            domain: "=domain",
            ngModel: '=ngModel'
          },
          link: function ($scope, element, attrs, ctrl) {
            ctrl.$setValidity('duplicateFieldsValidate', false);
            $scope.$watch("group", function (value, oldValue) {

              if (oldValue === value) {
                return;
              }

              // if there was a previous attempt, stop it.
              if (toId) clearTimeout(toId);

              // start a new attempt with a delay to keep it from
              // getting too "chatty".
              toId = setTimeout(function () {

                var resource = $resource(DuplicateFieldsValidateUrl, {domain: $scope.domain}, {
                  validate: {method: 'POST'}
                });

                var resourceEntity = new resource($scope.group);

                // call to some API that returns { isValid: true } or { isValid: false }
                resourceEntity.$validate(function (data) {

                    if (data.ok) { //set the validity of the field
                      $rootScope.safeApply(function () {
                        ctrl.$setValidity('duplicateFieldsValidate', data.payload.valid);
                        ctrl.$setDirty(true);
                      });
                    }
                  }
                );
              }, 200);
            }, true)
          }
        }
      }
    )
    .directive('gfIbanValidate',
      function () {
        var CODE_LENGTHS = {
          AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
          CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
          FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
          HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
          LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
          MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
          RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
        };
        return {
          restrict: 'A',
          require: 'ngModel',
          scope: {
            model: "=ngModel"
          },
          link: function (scope, element, attrs, ctrl, $filter) {
            scope.$watch('model', function (newValue) {
              // if it's not european country and we can't use this algorithm for it
              if (!newValue) {
                ctrl.$setValidity('gfIbanValidate', true);
                return;
              }
              if (!CODE_LENGTHS.hasOwnProperty(newValue.slice(0, 2).toUpperCase())) {
                ctrl.$setValidity('gfIbanValidate', true);
                return;
              }

              function mod97(string) {
                var checksum = string.slice(0, 2), fragment;
                for (var offset = 2; offset < string.length; offset += 7) {
                  fragment = String(checksum) + string.substring(offset, offset + 7);
                  checksum = parseInt(fragment, 10) % 97;
                }
                if (!checksum) {
                  ctrl.$setValidity('gfIbanValidate', false);
                } else {
                  ctrl.$setValidity('gfIbanValidate', true);
                }
              }

              var iban = String(newValue).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
                code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
                digits;
              // check syntax and length
              if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
                ctrl.$setValidity('gfIbanValidate', false);
                return false;
              }
              // rearrange country code and check digits, and convert chars to ints
              digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
                return letter.charCodeAt(0) - 55;
              });
              // final check
              return mod97(digits);
            })
          }
        }
      }
    )
    .directive('gfPaymentMethodTypeValidate', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          hasCashType: "=hasCashType",
          model: "=ngModel"
        },
        link: function (scope, element, attrs, ctrl) {
          if (scope.model && scope.model.name == 'CASH') {
            return;
          }
          scope.$watch('model', function (newValue) {
            if (!newValue) {
              ctrl.$setValidity('gfPaymentMethodTypeValidate', true);
              return;
            }
            if (newValue.name == 'CASH' && scope.hasCashType) {
              ctrl.$setValidity('gfPaymentMethodTypeValidate', false);
            } else {
              ctrl.$setValidity('gfPaymentMethodTypeValidate', true);
            }
          }, true)

        }
      }
    })

    // Directive to support old masterdata for forms
    // Temporary solution before we migrate to metadata new
    .directive('formMetadata', function () {
      return {
        restrict: 'A',
        require: 'form',
        link: function (scope, iElement, iAttributes, ctrl) {

          var metadata = scope.$eval(iAttributes.formMetadata);
          var metadataFields = metadata ? metadata.columns : {};

          // support dynamically added fields and to avoid problem with uib-tabset in article form
          scope.$watch(function () {
            return iElement.find('[name]').length;
          }, function () {
            applyMetadataOptions(iElement.find('[name]'));
          });

          function applyMetadataOptions(fields) {
            _.forEach(fields, function (field) {
              var element = angular.element(field);
              var attrName = element.attr('name');
              if (metadataFields[attrName]) {
                if (!metadataFields[attrName].editable) {
                  element.removeAttr('ng-disabled');
                  element.attr('disabled', 'disabled');
                }
              }
            });
          }
        }
      }
    })

    .directive('ngFormValidation', function ($compile) {
      return {
        restrict: 'A',
        terminal: true,
        bindToController: {
          metadata: '=ngFormValidation'
        },
        controller: function () {
        },
        controllerAs: 'valCtrl',
        link: function (scope, elem, attrs, ctrl) {

          var fieldsArray = elem.find('[name]');
          var metadata = ctrl.metadata.fields;

          angular.forEach(fieldsArray, function (item) {
            var element = angular.element(item);
            var attrName = element.attr('name');
            if (metadata[attrName]) {
              element.attr('gf-valid-new', 'valCtrl.metadata');
              element.attr('ng-disabled', '!valCtrl.metadata.fields["' + attrName + '"].editable');
              findParentBySelector(element, '.form-group')
                .attr("ng-if", 'valCtrl.metadata.fields["' + attrName + '"].visible');

              //todo think about asterisk adding, showing it in css :after pseudo class
              if (metadata[attrName].required) {
                showAsterisk(element);
              }
            }
          });

          $compile(elem.children())(scope);

          function findParentBySelector(elm, selector) {
            var all = Array.prototype.slice.call(document.querySelectorAll(selector));
            var cur = elm.parent();

            while (cur && all.indexOf(cur[0]) === -1) { //keep going up until you find a match
              cur = cur.parent(); //go up
            }
            return angular.element(cur); //will return null if not found
          }

          //todo show asterisk also without additional span (label should not have col-sm-* class)
          function showAsterisk(field) {
            if (!findParentBySelector(field, '.form-group').find('label')) {
              return;
            }
            var inputLabel = findParentBySelector(field, '.form-group').find('label span');
            var asterisk = angular.element('<span> *</span>');
            asterisk.insertAfter(inputLabel);
          }
        }
      }

    })
    .directive('gfMinPaymentAmountValidate', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          model: "=ngModel",
          maxPaymentAmount: "=maxPaymentAmount"
        },
        link: function (scope, element, attrs, ctrl) {
          var modelValue = scope.model;
          var maxPaymentAmountValue = scope.maxPaymentAmount;
          scope.$watch('model', function (newValue) {
            modelValue = newValue;
            if (newValue == 0 || maxPaymentAmountValue == 0 || !newValue || !maxPaymentAmountValue) {
              ctrl.$setValidity('gfMinPaymentAmountValidate', true);
              return;
            }

            var valid = maxPaymentAmountValue > newValue;
            ctrl.$setValidity('gfMinPaymentAmountValidate', valid);
            ctrl.$setDirty(true);
          }, true);

          scope.$watch('maxPaymentAmount', function (newValue) {
            maxPaymentAmountValue = newValue;
            if (newValue == 0 || modelValue == 0 || !newValue || !modelValue) {
              ctrl.$setValidity('gfMinPaymentAmountValidate', true);
              return;
            }

            var valid = modelValue < newValue;
            ctrl.$setValidity('gfMinPaymentAmountValidate', valid);
            ctrl.$setDirty(true);
          }, true);

        }
      }
    })
    .directive('gfMaxPaymentAmountValidate', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          model: "=ngModel",
          minPaymentAmount: "=minPaymentAmount"
        },
        link: function (scope, element, attrs, ctrl) {
          var modelValue = scope.model;
          var minPaymentAmountValue = scope.minPaymentAmount;

          scope.$watch('model', function (newValue) {
            modelValue = newValue;
            if (newValue == 0 || minPaymentAmountValue == 0 || !newValue || !minPaymentAmountValue) {
              ctrl.$setValidity('gfMaxPaymentAmountValidate', true);
              return;
            }

            var isValid = minPaymentAmountValue < newValue;
            ctrl.$setValidity('gfMaxPaymentAmountValidate', isValid);
            ctrl.$setDirty(true);
          }, true);

          scope.$watch('minPaymentAmount', function (newValue) {
            minPaymentAmountValue = newValue;
            if (newValue == 0 || modelValue == 0 || !newValue || !modelValue) {
              ctrl.$setValidity('gfMaxPaymentAmountValidate', true);
              return;
            }

            var isValid = newValue < modelValue;
            ctrl.$setValidity('gfMaxPaymentAmountValidate', isValid);
            ctrl.$setDirty(true);
          }, true);

        }
      }
    })
    .directive('gfUniqueReasonCategory', function (UniqueReasonCategoryAdd, $stateParams, $rootScope) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(attrs.ngModel, function (value) {
            if (!$scope.reasonsAddGridCtrl.reasons.accountType) {
              return;
            }
            var accountTypeId = $scope.reasonsAddGridCtrl.reasons.accountType.id;
            var id = $scope.reasonsAddGridCtrl.reasons.id;

            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            if (!value) {
              ctrl.$setValidity('uniqueReasonCategory', true);
              return;
            }
            toId = setTimeout(function () {
              UniqueReasonCategoryAdd.get({
                accountTypeId: accountTypeId,
                id: id,
                category: value.id ? value.id : 0
              }, function (data) {

                //set the validity of the field
                $rootScope.safeApply(function () {
                  if (data.ok) {
                    ctrl.$setValidity('uniqueReasonCategory', data.payload.result);
                  }
                });
              });
            }, 200);
          })
        }
      }
    })
    .directive('gfStandardValidation', function (gfStandardValidations) {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          model: '=ngModel',
          entity: '=entity',
          standardValidation: '=gfStandardValidation'
        },
        link: function (scope, element, attrs, ctrl) {
          var validation = gfStandardValidations[scope.standardValidation];
          if (validation) {
            scope.$watch('entity', function () {
              validation(ctrl)(scope.model, scope.entity);
            }, true);
          }
        }
      }
    })
    .directive('gfArticleTemplateRangeOrNumber', function ($rootScope, securityService) {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          model: '=ngModel'
        },
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch('model', function (value) {
            //var fiscalEnable = securityService.context.fiscalRegulation && securityService.context.fiscalRegulation.enableArticleGroupsRestrictions;
            var validity = false;//!fiscalEnable || !!value || ((1 <= $scope.entity.id) && ($scope.entity.id <= 10));

            $rootScope.safeApply(function () {
              //ctrl.$setValidity('italyFiscalArticleGroupParent', validity);
            });
          })
        }
      }
    })
    .directive('gfCustomerDiscountValueValidate', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          model: "=ngModel",
          absolute: "=absolute"
        },
        link: function (scope, element, attrs, ctrl) {

          scope.$watch('model', function (newValue) {
            ctrl.$setValidity('gfCustomerDiscountValueValidate', scope.absolute || newValue <= 100);
            ctrl.$setDirty(true);
          }, true);

          scope.$watch('absolute', function (absolute) {
            ctrl.$setValidity('gfCustomerDiscountValueValidate', absolute || scope.model <= 100);
            ctrl.$setDirty(true);
          }, true);

        }
      }
    })
    .directive('gfSinglePurposeVoucherPossibleFlag',
      function ($http, $resource, SinglePurposeVoucherCheck, $stateParams) {
        return {
          restrict: 'A',
          require: 'ngModel',
          link: function ($scope, element, attrs, ctrl) {
            $scope.$watch(attrs.ngModel, function (value) {
              if (!value) {
                ctrl.$setValidity('gfSinglePurposeVoucherPossibleFlag', true);
                return;
              }
              var resource = $resource(SinglePurposeVoucherCheck, {settlementFormId: $stateParams.id || 0}, {validate: {method: 'GET'}});

              resource.validate(function (data) {

                //set the validity of the field
                $scope.safeApply(function () {
                  ctrl.$setValidity('gfSinglePurposeVoucherPossibleFlag', data.payload.result);
                });
              });
            })
          }
        }
      }
    )
    .directive('gfMeasurementUnit',
      function () {
        return {
          restrict: 'A',
          require: 'ngModel',
          scope: {
            model: '=ngModel',
            groups: '=groups',
            currentGroup: '=currentGroup',
            types: '=types',
            articleType: '=articleType'
          },
          link: function (scope, element, attrs, ctrl) {
            var initValue = scope.currentGroup;
            scope.$watch('model', function (newValue) {
              if (newValue === initValue) {
                ctrl.$setValidity('gfMeasurementUnit', true);
                return;
              }
              if (_.find(scope.types, {id: scope.articleType.id}).measure) {
                ctrl.$setValidity('gfMeasurementUnit', _.find(scope.groups, {id: newValue}).measurementUnit || false);
              }
            });
            scope.$watch('articleType', function (value) {
              if (!_.find(scope.types, {id: value.id}).measure) {
                ctrl.$setValidity('gfMeasurementUnit', true);
              }
            }, true)
          }
        }
      }
    )
    .directive('gfUnitRequired', function ($http) {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          model: '=ngModel',
          currentGroup: '=currentGroup'
        },
        link: function (scope, element, attrs, ctrl) {
          scope.$watch('model', function (value) {
            if (!value) {
              $http.get('/icash/masterdata/article_group/articles?id=' + scope.currentGroup.id).then(function (res) {
                ctrl.$setValidity('gfUnitRequired', !res.data)
              })
            }
            ctrl.$setValidity('gfUnitRequired', true);
          })
        }
      }
    })
    .directive('gfMeasurementType', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          model: '=ngModel',
          articleGroups: '=articleGroups',
          articleTypes: '=articleTypes',
          articleGroup: '=articleGroup',
        },
        link: function (scope, element, attrs, ctrl) {
          scope.$watch('model', function (value) {
            if (_.find(scope.articleTypes, {id: value}).measure) {
              ctrl.$setValidity('gfMeasurementType', _.find(scope.articleGroups, {id: scope.articleGroup.id}).measurementUnit || false);
              return;
            }
            ctrl.$setValidity('gfMeasurementType', true);
          });
          scope.$watch('articleGroup', function (value) {
            if (_.find(scope.articleGroups, {id: value.id}).measurementUnit)
              ctrl.$setValidity('gfMeasurementType', true);
          }, true);
        }
      }
    })
    .directive('gfArticleTypeMeasure', function ($http) {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          model: '=ngModel',
          articleType: '=articleType'
        },
        link: function (scope, element, attrs, ctrl) {
          scope.$watch('model', function (value) {
            if (value) {
              $http.get('/icash/restaurant_data/article_type/articles?id=' + scope.articleType.id).then(function (res) {
                ctrl.$setValidity('gfArticleTypeMeasure', !res.data)
              })
            }
            ctrl.$setValidity('gfArticleTypeMeasure', true);
          })
        }
      }
    })
    .directive('gfAccountTypeByReason', function ($http) {
      return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
          model: '=ngModel',
          accountId: "=accountId",
          accountTypeValue: "=accountTypeValue"
        },
        link: function (scope, element, attrs, ctrl) {
          var initType = scope.accountTypeValue;

          scope.$watch('model', function (value) {
            if (initType.id === value.id) {
              ctrl.$setValidity('gfAccountTypeByReason', true);
              return;
            }

            $http.get('/icash/financial/reasons/account?id=' + scope.accountId)
              .then(function (resp) {
                ctrl.$setValidity('gfAccountTypeByReason', !resp.data);
              });
          })
        }
      }
    })
})();
