(function() {
  'use strict';
  angular.module('gf.financial.discount', ['ui.router'])

    .factory('Discount', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/discount/:discountId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('DiscountMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/discount/metadata.json');
    })
    .factory('DiscountContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/discount/context.json');
    })

    .config(function($stateProvider) {

      $stateProvider
        .state('page.financialDiscount', {
          url: '/financial/discount',
          template: require('../../../main/angular/app/template/page/gf-grid.html'),
          controller: 'DiscountGridCtrl',
          controllerAs: 'discountGridCtrl',
          resolve: {
            discountMetadata: ['DiscountMetadata', function (DiscountMetadata) {
              return DiscountMetadata.get().$promise;
            }],
            discountContext: ['DiscountContext', function (DiscountContext) {
              return DiscountContext.get().$promise;
            }]
          },
          title: 'discount'
        })

        .state('page.financialDiscountAdd', {
          url: '/financial/discount/add',
          template: require('../template/page/financial/discount.html'),
          controller: 'DiscountAddCtrl',
          controllerAs: 'discountCtrl',
          resolve: {
            discountMetadata: ['DiscountMetadata', function (DiscountMetadata) {
              return DiscountMetadata.get().$promise;
            }],
            discountContext: ['DiscountContext', function (DiscountContext) {
              return DiscountContext.get().$promise;
            }],
            discount: ['Discount', function (Discount) {
              return Discount.load({discountId: 'add'}).$promise;
            }]
          },
          title: 'discount'
        })

        .state('page.financialDiscountDetails', {
          url: '/financial/discount/:id',
          template: require('../template/page/financial/discount.html'),
          controller: 'DiscountEditCtrl',
          controllerAs: 'discountCtrl',
          resolve: {
            discountMetadata: ['DiscountMetadata', function (DiscountMetadata) {
              return DiscountMetadata.get().$promise;
            }],
            discountContext: ['DiscountContext', function (DiscountContext) {
              return DiscountContext.get().$promise;
            }],
            discount: ['Discount', '$stateParams', function (Discount, $stateParams) {
              return Discount.load({discountId: $stateParams.id}).$promise;
            }]
          },
          title: 'discount'
        })

    })
    .factory('DiscountAdd',
      function ($location, $http, $translate, $state, notifyManager) {
        return function ($scope, ctrlObj, discount, discountContext, discountMetadata) {
          ctrlObj.discountContext = discountContext;
          ctrlObj.discountMetadata = discountMetadata;
          ctrlObj.discount = discount;

          ctrlObj.discountMetadata.columns.value.errorMessages.push(
            {
              'keys': ['gf-discount-max-value'],
              'message': 'de.icash.validator.discountPercentMaxValue.message'
            }
          );

          ctrlObj.save = function () {
            if ($scope.discountForm.$valid) {
              ctrlObj.discount.$save(function () {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $location.path('/financial/discount')
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };
          ctrlObj.saveAndNew = function () {
            if ($scope.discountForm.$valid) {
              ctrlObj.discount.$save(function () {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };
        }
      }
    )
    .controller('DiscountAddCtrl',
      function ($scope, discount, discountContext, discountMetadata, DiscountAdd) {
        var self = this;
        DiscountAdd($scope, self, discount, discountContext, discountMetadata);
      }
    )
    .controller('DiscountEditCtrl',
      function ($scope, $location, $translate, notifyManager, discount, discountContext, discountMetadata, DiscountAdd) {

        var self = this;

        DiscountAdd($scope, self, discount, discountContext, discountMetadata);

        self.saveAndNew = function () {
          if ($scope.discountForm.$valid) {
            self.discount.$edit({discountId: $scope.discount.id}, function () {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $location.path('/financial/discount/add')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

      }
    )
    .controller('DiscountGridCtrl',

      function ($scope, discountContext, discountMetadata, configOptionsService) {

        $scope.metadata = discountMetadata;
        $scope.context = discountContext;

        $scope.config = {
          urlRoute: '/financial/discount',
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'id', width: 60, enableCellEdit: false, visible: false},
            {name: 'name'},
            {
              name: 'value',
              width: 200,
              validations: {
                'gf-discount-max-value': {
                  'angular': {'gf-discount-max-value': ''},
                  'errorMessage': 'de.icash.validator.discountPercentMaxValue.message'
                }
              },
              dependOn: [
                'absolute'
              ]
            },
            {name: 'absolute', width: 100},
            {name: 'checkDiscountable', width: 100, visible: false},
            {
              name: 'operate',
              actions: ['edit', 'delete'],
              actionOptions: {
                'delete': {
                  isDisabled: configOptionsService.get('discountDeleteButton').isDisabled
                }
              }
            }
          ]
        };
      }
    )
})();
