(function () {
  'use strict';
  angular.module('gf.plu.service', [])
    .factory("PluService",
      function ($log, notifyManager, $cookies) {

        return {
          acquire: function (url) {
            var plu;
            // use jQuery before Angular add synchronous request
            $.ajax({
              url: url,
              type: 'put',
              beforeSend: function(request) {
                request.setRequestHeader('X-XSRF-TOKEN', $cookies.get('XSRF-TOKEN'));
              },
              contentType: 'application/json',
              async: false,
              success: function (data) {
                plu = parseInt(data, 10);
                $log.info('acquired PLU ' + plu);
              },
              error: function (jqXHR, textStatus, errorThrown) {
                notifyManager.error(textStatus);
              }
            });
            return plu;
          },
          lock: function (url, plu) {
            var result;
            // use jQuery before Angular add synchronous request
            $.ajax({
              url: url + '/' + plu,
              type: 'put',
              beforeSend: function(request) {
                request.setRequestHeader('X-XSRF-TOKEN', $cookies.get('XSRF-TOKEN'));
              },
              async: false,
              contentType: 'application/json',
              success: function (data) {
                if (data.ok) {
                  $log.info('locked PLU ' + plu);
                } else {
                  $log.info(plu + ' is taken');
                }
                result = data;
              },
              error: function (jqXHR, textStatus, errorThrown) {
                notifyManager.error(textStatus);
              }
            });
            return result;
          }
        }
      })

})();
