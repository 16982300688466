(function () {
  'use strict';
  angular.module('gf.bm.settlementForm', ['gf.bm.groupView'])

    .factory('BMSettlementFormMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/settlement_form/metadata.json')
    })
    .factory('BMSettlementFormContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/settlement_form/context.json')
    })
    .factory('BMSettlementFormRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.financialSettlement_form', {
          url: '/financial/settlement_form',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMFinancialSettlementForm',
          controllerAs: '$ctrl',
          resolve: {
            bmSettlementFormMetadata: function (BMSettlementFormMetadata, $stateParams, securityService) {
              return BMSettlementFormMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmSettlementFormContext: function (BMSettlementFormContext, $stateParams, securityService) {
              return BMSettlementFormContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmSettlementFormRules: function (BMSettlementFormRules, $stateParams, securityService) {
              return BMSettlementFormRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "financialSettlementForm"
        })
    })

    .controller('BMFinancialSettlementForm',
      function (bmSettlementFormMetadata, bmSettlementFormContext, securityService, $stateParams, bmSettlementFormRules) {
        var self = this;

        self.metadata = bmSettlementFormMetadata;
        self.context = bmSettlementFormContext;
        self.masterdataRules = bmSettlementFormRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name', enableCellEdit: false},
            {
              name: 'billLayouts',
              field: 'billLayouts',
              dependenciesField: 'ticketLayout',
              ruleField: 'settlementForm2BillLayouts',
              ruleRelationField: 'billLayout'
            },
            {name: 'addSystem', enableCellEdit: false},
            {name: 'addUser', enableCellEdit: false},
            {name: 'type', enableCellEdit: false, cellFilter: "gfProperty:'name' | prefixFilter:'settlementFormType.' | translate"},
            {name: 'account'}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/settlement_form';
          }
        };

        self.permissionsConfig = {
          columnsPermissions: {
            'billLayouts': {
              editPermissions: ['BM_PAYMENT_METHOD_CHANGE_BILL_LAYOUT']
            }
          }
        }
      }
    )

})();
