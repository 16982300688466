import i18n from "../../../../components/react_components/i18n";
import moment from 'moment-timezone';
import { MERP_USER_ROLES } from "../../../../merp-helpers";

(function () {
  'use strict';

  /* App Module */
  angular.module('gastrofix', ['ngSanitize', 'ngCookies', 'ngLocale', 'ui.bootstrap', 'ngDragDrop', 'toggle-switch',
    'angular-loading-bar', 'ui.sortable', 'frapontillo.bootstrap-switch', 'ngFileUpload',
    'angulartics', 'angulartics.google.analytics', 'pascalprecht.translate', 'dialogs.main', 'ngOnload', 'angularMoment',
    'treeControl', 'LocalStorageModule', 'tmh.dynamicLocale', 'ngAnimate', 'gf.cropper', 'ui.router', 'ui.select', 'bm.uiTour',
    'ui-leaflet', 'osm.nominatim', 'ui.grid.draggable-rows', 'ui.grid.selection', 'gf.context.header',
    //controllers
    'gf.main', 'gf.masterdata', 'gf.dashboard', 'gf.operatorData', 'gf.financial', 'gf.hardware', 'gf.setup',
    'gf.customer', 'gf.features', 'gf.login', 'gf.error', 'gf.passwordRecovery', 'gf.unsubscribe',
    //services
    'gf.resources', 'gf.services', 'gf.directives', 'gf.i18n', 'gf.security', 'gf.plu.service', 'angularjs-dropdown-multiselect',
    'oi.select', 'masterdataFilters', 'dashboardFilters', 'highcharts-ng',
    'ui.grid', 'ui.grid.edit', 'ui.grid.pagination', 'ui.grid.resizeColumns', 'ui.grid.rowEdit', 'ui.grid.selection',
    'ui.grid.selection', 'ui.grid.cellNav', 'ui.grid.autoResize', 'ui.grid.moveColumns', 'ui.grid.saveState', 'gf.grid', 'gf.reportUtils',
    //components
    'gf.hardware.printer', 'gf.operator.data.trash', 'gf.setup.history', 'gf.masterdata.waiter',
    'gf.icons.library', 'gf.tour', 'gf.masterdata.ticketLayout', 'gf.hardware.ticketLayout', 'gf.hardware.paymentDevice',
    'gf.masterdata.article', 'gf.setup.rksv', 'gf.operator.data.user', 'gf.operator.data.userGroup', 'gf.operator.data.operatorSettings',
    'gf.masterdata.course', 'gf.financial.vat', 'gf.financial.costCenter', 'gf.partnerMenuConf', 'gf.mainMenu', 'gf.report', 'gf.report.running',
    'gf.masterdata.inventory', 'gf.financial.settlement.form', 'gf.operator.data.restaurantGroup', 'gf.setup.exchangeRate',
    'gf.setup.articleType', 'gf.financial.account', 'gf.hardware.device', 'gf.setup.restaurant', 'gf.masterdata.article.group', 'gf.setup.table',
    'gf.operator.data.salesforce', 'gf.financial.discount', 'gf.common.filters', 'gf.financial.servicePeriod', 'gf.branchManagement',
    'gf.financial.reasons', 'gf.open.api', 'gf.react', 'gf.news', 'gf.financial.services'
  ])

    .constant('appConfig', {
      baseUrl: '',
      libraryUrl: 'assets/images/icons',
      salesforce: 'https://eu1.salesforce.com/',
      dashboard: {
        refreshInterval: 60 * 1000
      },
      defaultPage: '/dashboard/1'
    })

    .config(function ($stateProvider, $compileProvider, $httpProvider, securityServiceProvider, $urlMatcherFactoryProvider,
                      $uibTooltipProvider, $analyticsProvider, $translateProvider, dialogsProvider, $controllerProvider,
                      SecurityContext, tmhDynamicLocaleProvider, $urlRouterProvider, $qProvider, $locationProvider) {

      $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|itms-services):/);

      // This needed for saving SecurtyContext resourse to security service
      securityServiceProvider.setSecurityContext(SecurityContext);
      console.log('Security Context: ', SecurityContext);

      $qProvider.errorOnUnhandledRejections(false);

      $locationProvider.hashPrefix('');

      securityServiceProvider
        .securePath("/dashboard/", function (security) {
          var isNotMasterUnit = !security.hasAnyFeature('BRANCH_MANAGEMENT') ||
            _.isUndefined(security.context.masterUnit) ||
            security.context.masterUnit.id !== security.context.currentRestaurant.id;

          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_DASHBOARD_GROUP') && isNotMasterUnit;
        })
        .securePath("/bm_dashboard", function (security) {
          return security.hasAnyFeature('BRANCH_MANAGEMENT');
        })
        .securePath("/masterdata/article", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_MASTERDATA_GROUP')
        .securePath("/report/1", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_REPORTS_GROUP', 'ACCESS_REPORT_VIEW')
        .securePath("/financial/vat", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_RESTAURANT_DATA_GROUP', 'ACCESS_FINANCIAL_GROUP')
        .securePath("/financial/tax", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER')
        .securePath("/manual/icash", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_MANUAL_GROUP')
        .securePath("/dashboard/edit", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_RESTAURANT_DATA_GROUP', 'ACCESS_SETUP_GROUP')
        .securePath("/masterdata/", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_MASTERDATA_GROUP')
        .securePath("/masterdata/inventory", function (security) {
          return security.hasAnyFeature('INVENTORY_MANAGEMENT');
        })
        .securePath("/masterdata/ticket_layout", function (security) {
          return !security.context.settings.enableLowEndMarket && (security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER') || ((!security.context.fiscalRegulation
            || !security.context.fiscalRegulation.prohibitTicketLayout) && security.hasAnyRole('TICKET_LAYOUT_WIZARD')));
        })
        .securePath("/masterdata/ticket_layout/current", function (security) {
          return (!security.context.fiscalRegulation || !security.context.fiscalRegulation.prohibitTicketLayout) && security.context.settings.enableLowEndMarket;
        }).securePath("/masterdata/choose_room", function (security) {
        return security.context.settings.enableLowEndMarket;
      })
        .securePath("/dashboard/8", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER') || !security.context.settings.enableLowEndMarket;
        })
        .securePath("/report/", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_REPORTS_GROUP', 'ACCESS_REPORT_VIEW');
        })
        .securePath("/report/3", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_REPORTS_GROUP')
        .securePath("/report/5", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_REPORTS_GROUP')
        .securePath("/report/13", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_CUSTOMER_DATA')
        .securePath("/report/17", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_REPORTS_GROUP')
        .securePath("/report/19", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'REVIEW_SECTION') && security.hasAnyFeature('POS_LEDGER');
        })
        .securePath("/dynamic_reporting/", function (security) {
          return security.hasAnyFeature('GTC');
        })
        .securePath("/branch/", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_BRANCH_GROUP')
        .securePath("/branch/14", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_CUSTOMER_DATA')
        .securePath("/filter_branch/", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_BRANCH_GROUP')
        .securePath("/filter_branch/14", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_CUSTOMER_DATA')
        .securePath("/filter/", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_REPORTS_GROUP')
        .securePath("/filter/13", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_CUSTOMER_DATA')
        .securePath("/report/running", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_REPORTS_GROUP')
        .securePath("/setup/", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_RESTAURANT_DATA_GROUP', 'ACCESS_SETUP_GROUP')
        .securePath("/setup/restaurant", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_RESTAURANT_DATA_GROUP', 'ACCESS_SETUP_GROUP')
            && !(security.hasAnyFeature('BRANCH_MANAGEMENT') && security.context.masterUnit && security.context.masterUnit.id === security.context.currentRestaurant.id);
        })
        .securePath("/setup/waiter_group", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER') || !security.context.settings.enableLowEndMarket;
        })
        .securePath("/setup/article_type", 'ROLE_SUPERVISOR', 'ARTICLE_TYPE_ACCESS')
        .securePath("/setup/exchange_rate", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER') || (!security.context.fiscalRegulation
            || !security.context.fiscalRegulation.prohibitExchangeRate);
        })
        .securePath("/setup/room", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER') || !security.context.settings.enableLowEndMarket;
        })
        .securePath("/setup/table", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER') || !security.context.settings.enableLowEndMarket;
        })
        .securePath("/setup/tse", function (security) {
          return security.hasAnyFeature('GOBD');
        })
        .securePath("/setup/companyInfo", function (security) {
          return security.hasAnyFeature('GOBD');
        })
        .securePath("/setup/history/setup/fiscal_device", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER');
        })
        .securePath('/branch_management', function (security) {
          return security.hasAnyFeature('BRANCH_MANAGEMENT')
            && security.hasAnyRole('BM_MANAGE_BRANCH_GROUPS');
        })
        .securePath("/financial/", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_RESTAURANT_DATA_GROUP', 'ACCESS_FINANCIAL_GROUP')
        .securePath("/financial/tax", function (security) {
          return security.hasAnyFeature('TAX') && security.hasAnyRole('ACCESS_FINANCIAL_GROUP', 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER');
        })
        .securePath("/financial/vat", function (security) {
          return !security.hasAnyFeature('TAX') && security.hasAnyRole('ACCESS_FINANCIAL_GROUP', 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER');
        })
        .securePath("/financial/account", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER') || !security.context.settings.enableLowEndMarket;
        })
        .securePath("/financial/reasons", function (security) {
          //hid reasons until we release 3.0
          return security.hasAnyFeature('POS_LEDGER') && security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'REASON_MANAGEMENT');/*security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER') || !security.context.settings.enableLowEndMarket*/
          ;
        })
        .securePath("/financial/cost_center", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER') || !security.context.settings.enableLowEndMarket;
        })
        .securePath("/financial/datev", function (security) {
          return security.hasAnyFeature('DATEV');
        })
        .securePath("/financial/cube", function (security) {
          return security.hasAnyFeature('GET_CUBE');
        })
        .securePath("/financial/correction", function (security) {
          return security.hasAnyFeature('CORRECTION_JOURNAL') && security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_CORRECTION_JOURNAL');
        })
        .securePath("/financial/invoices", function (security) {
          return security.context.zuoraBilling;
        })
        .securePath("/hardware/", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_RESTAURANT_DATA_GROUP', 'ACCESS_HARDWARE_GROUP')
        .securePath("/hardware/device", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_RESTAURANT_DATA_GROUP', 'ACCESS_HARDWARE_GROUP')
            && !(security.hasAnyFeature('BRANCH_MANAGEMENT') && security.context.masterUnit && security.context.masterUnit.id === security.context.currentRestaurant.id);
        })
        .securePath("/hardware/payment_device", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_RESTAURANT_DATA_GROUP', 'ACCESS_HARDWARE_GROUP')
        .securePath("/hardware/printer/printers_list", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER') || (!security.context.fiscalRegulation
            || !security.context.fiscalRegulation.prohibitPrinters);
        })
        .securePath("/hardware/ticket_layout", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER')
            || ((!security.context.fiscalRegulation || !security.context.fiscalRegulation.prohibitTicketLayout)
              && !security.context.settings.enableLowEndMarket && !security.hasAnyFeature('SAFT'));
        })
        .securePath("/manual/", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_RESTAURANT_DATA_GROUP', 'ACCESS_MANUAL_GROUP')
            && ['culinaro', 'sumup', 'shore'].includes(THEME);
        })
        .securePath("/manual_link/", function (security) {
          return security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_RESTAURANT_DATA_GROUP', 'ACCESS_MANUAL_GROUP')
            && !['culinaro', 'sumup', 'shore'].includes(THEME);
        })
        .securePath("/operator_data/", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_OPERATOR_DATA_GROUP')
        .securePath("/operator_data/restaurant", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_OPERATOR_DATA_GROUP')
        .securePath("/operator_data/restaurant_group", function (security) {
          return security.hasAnyFeature('BRANCH_CONTROL') && security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_BRANCH_CONTROL');
        })
        .securePath("/operator_data/control_room", 'ROLE_SUPERVISOR', "ACCESS_CONTROL_ROOM")
        .securePath("/financialServices", function (security) {
          return security.hasAnyRole(...MERP_USER_ROLES);
        })
        .securePath("/customer/", 'ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'ACCESS_CUSTOMER_DATA')
        .securePath("/customer/yovite", function (security) {
          return security.hasAnyFeature('YOVITE_VOUCHER');
        })
        .securePath("/api_connection", function (security) {
          return security.context.contractType && security.context.contractType.openApi;
        })
        .securePath("/admin/", 'ROLE_SUPERVISOR')
      ;

      $urlRouterProvider.otherwise("/welcome");

      $urlMatcherFactoryProvider.strictMode(false);

      $stateProvider

        //abstract state for pages with header and footer
        .state('page', {
          url: '',
          abstract: true,
          views: {
            '': {
              template: require('./../template/page.html')
            },
            'header@page': {
              template: require('./../template/header.html')
            },
            'footer@page': {
              template: require('./../template/footer.html')
            }
          }
        })

        //abstract state for pages without header and footer
        .state('clean', {
          url: '',
          abstract: true,
          views: {
            '': {
              template: require('./../template/cleanPage.html')
            }
          }
        })

        .state('clean.login', {
          url: '/login',
          template: SecurityContext.theme.name === 'lightspeed' ? require('./../template/page/login/lightspeed-login.html') : require('./../template/page/login/login.html'),
          controller: 'LoginCtrl',
          title: "login"
        })


        .state('page.masterdataOrder_screen', {
          url: '/masterdata/order_screen',
          template: require('./../template/page/masterdata/order_screens.html'),
          controller: 'OrderScreenGridCtrl',
          resolve: {
            orderScreenMetadata: function (OrderScreenMetadata) {
              return OrderScreenMetadata.get().$promise;
            },
            orderScreenContext: function (OrderScreenContext) {
              return OrderScreenContext.get().$promise;
            }
          },
          title: "orderScreen"
        })

        .state('page.masterdataOrder_screenAdd', {
          url: '/masterdata/order_screen/add',
          template: require('./../template/page/masterdata/order_screen.html'),
          controller: 'OrderScreenAddCtrl',
          resolve: {
            orderScreenMetadata: function (OrderScreenMetadata) {
              return OrderScreenMetadata.get().$promise;
            },
            orderScreenContext: function (OrderScreenContext) {
              return OrderScreenContext.get().$promise;
            },
            orderScreen: function (OrderScreen, $stateParams) {
              return OrderScreen.load({orderScreenId: $stateParams.id || "add"}).$promise;
            }
          },
          title: "orderScreen"
        })

        .state('page.masterdataOrder_screenDetails', {
          url: '/masterdata/order_screen/:id',
          template: require('./../template/page/masterdata/order_screen.html'),
          controller: 'OrderScreenEditCtrl',
          resolve: {
            orderScreenMetadata: function (OrderScreenMetadata) {
              return OrderScreenMetadata.get().$promise;
            },
            orderScreenContext: function (OrderScreenContext) {
              return OrderScreenContext.get().$promise;
            },
            orderScreen: function (OrderScreen, $stateParams) {
              return OrderScreen.load({orderScreenId: $stateParams.id || "add"}).$promise;
            },
            usedEntities: function (OrderScreenUsedEntities, $stateParams) {
              return OrderScreenUsedEntities.get({orderScreenId: $stateParams.id}).$promise;
            }
          },
          title: "orderScreen"
        })

        .state('page.masterdataChoose_room', {
          url: '/masterdata/choose_room',
          template: require('./../template/page/restaurant_data/choose_room.html'),
          controller: 'RoomLowMarketGridCtrl',
          resolve: {
            rooms: function (LowMarketRooms) {
              return LowMarketRooms.get().$promise;
            }
          },
          title: "room"
        })

        .state('page.financialTaxGrid', {
          url: '/financial/tax',
          template: require('./../template/page/financial/tax/taxes.html'),
          controller: 'TaxGridCtrl',
          controllerAs: 'taxGridCtrl',
          resolve: {
            taxMetadata: function (TaxMetadata) {
              return TaxMetadata.get().$promise;
            },
            taxContext: function (TaxContext) {
              return TaxContext.get().$promise;
            }
          },
          title: 'tax'
        })

        .state('clean.error', {
          url: '/error',
          template: require('./../template/page/error/error.html'),
          controller: 'ErrorCtrl',
          title: 'Error Page'
        })

        .state('clean.password_recovery', {
          url: '/password_recovery',
          template: require('./../template/page/password_recovery/password_recovery.html'),
          controller: 'PasswordRecoveryCtrl',
          title: 'password recovery'
        })

        .state('clean.reset_password', {
          url: '/reset_password',
          template: require('./../template/page/password_recovery/reset_password.html'),
          controller: 'PasswordRecoveryCtrl',
          resolve: {
            reset: function ($location, $q) {
              var deferred = $q.defer();
              if (!($location.search()['m'] && $location.search()['s'])) {
                deferred.reject({status: 401});
              } else {
                deferred.resolve();
              }
              return deferred.promise;
            }
          },
          title: 'reset password'
        })

        .state('clean.unsubscribe', {
          url: '/unsubscribe',
          template: require('./../template/unsubscribe.html'),
          controller: 'UnsubscribeCtrl',
          controllerAs: 'unsubscribeCtrl',
          resolve: {
            unsubscribe: function ($location, $q) {
              var deferred = $q.defer();
              if (!($location.search()['m'] && $location.search()['s'])) {
                deferred.reject({status: 401});
              } else {
                deferred.resolve();
              }
              return deferred.promise;
            }
          },
          title: 'unsubscribe'
        })

        .state('clean.unsubscribeSuccess', {
          url: '/unsubscribe_success',
          template: require('./../template/unsubscribe_success.html'),
          title: 'unsubscribe'
        })

        .state('page.setupTse', {
          url: '/setup/tse',
          template: require('./../../../../components/gf-setup-tse/tse.html'),
          title: 'tse'
        })

        .state('page.companyInfo', {
          url: '/setup/company_info',
          template: '<react-component name="CompanyInfo"></react-component>',
          title: 'companyInfo'
        })

        .state('page.dynamicReporting', {
          url: '/dynamic_reporting/report',
          template: require('./../../../../components/gf-report-new/report_new.html'),
          title: 'dynamicReporting'
        })

        .state('page.paymentReporting', {
          url: '/dynamic_reporting/payments/:type/:id?periodId&from&to&orderBy&order&page',
          template: '<react-component name="PaymentReportingAdapter" />',
          title: 'paymentReporting'
        })

        .state('page.paymentReportingSettlement', {
          url: '/dynamic_reporting/payments/settlements/:id/transactions/:trId?periodId&from&to&orderBy&order&page',
          template: '<react-component name="PaymentReportingAdapter" />',
          title: 'paymentReportingSettlement'
        })

        .state('page.billReporting', {
          url: '/report/:id?bill&r&f',
          template: require('./../../../../components/gf-report/template/report/report.html'),
          title: 'billReporting'
        });

      $uibTooltipProvider.options({
        appendToBody: true
      });
      $uibTooltipProvider.setTriggers({'inStockEvent': 'inStockEvent'});

      $translateProvider
        .useSanitizeValueStrategy(null)
        .useStaticFilesLoader({
          prefix: '/icash/util/translations/',
          suffix: '.json'
        })
        .uniformLanguageTag('bcp47')
        .useMessageFormatInterpolation()
        .preferredLanguage(SecurityContext.currentLanguageTag);

      tmhDynamicLocaleProvider
        .localeLocationPattern('angular-i18n/angular-locale_{{locale | lowercase}}.js')
        .defaultLocale(SecurityContext.currentLanguageTag);

      dialogsProvider.useClass('gf-modal');

      $controllerProvider.allowGlobals();
    })

    .run(function ($rootScope, $state, $translate, $locale, uibDatepickerConfig, uibDatepickerPopupConfig,
                   uibTimepickerConfig, securityService, $location, errorService, $analytics, amMoment) {

      amMoment.changeLocale(securityService.context.currentLanguageTag);
      moment.tz.setDefault(securityService.context.currentRestaurant?.timezone);

      $(document).on('click', '.go-top', function (e) {
        $("html, body").animate({
          scrollTop: 0
        }, "fast");
        e.preventDefault();
      });


      uibDatepickerConfig.startingDay = 1;

      uibDatepickerPopupConfig.dateFormat = 'shortDate';
      uibDatepickerPopupConfig.showButtonBar = false;

      uibTimepickerConfig.showMeridian = false;

      $rootScope.security = securityService;
      $rootScope.state = $state;


      /* Highcharts config */

      var highchartsOptions = {
        colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9',
          '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1',
          '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
          '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a',
          '#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE',
          '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92'
        ],
        global: {
          useUTC: true,
          timezoneOffset: -securityService.context.timezoneOffset
        },
        lang: {
          decimalPoint: $locale.NUMBER_FORMATS.DECIMAL_SEP,
          months: $locale.DATETIME_FORMATS.MONTH,
          shortMonths: $locale.DATETIME_FORMATS.SHORTMONTH,
          weekdays: $locale.DATETIME_FORMATS.DAY
        }
      };

      Highcharts.setOptions(highchartsOptions);

      $rootScope.$on('$translateChangeSuccess', function () {
        $translate(['highchart.contextButtonTitle', 'highchart.downloadJPEG', 'highchart.downloadPDF',
          'highchart.downloadPNG', 'highchart.downloadSVG', 'highchart.loading', 'highchart.printChart',
          'highchart.resetZoom', 'highchart.resetZoomTitle'])
          .then(function (translations) {
            highchartsOptions.lang.contextButtonTitle = translations['highchart.contextButtonTitle'];
            highchartsOptions.lang.downloadJPEG = translations['highchart.downloadJPEG'];
            highchartsOptions.lang.downloadPDF = translations['highchart.downloadPDF'];
            highchartsOptions.lang.downloadPNG = translations['highchart.downloadPNG'];
            highchartsOptions.lang.downloadSVG = translations['highchart.downloadSVG'];
            highchartsOptions.lang.loading = translations['highchart.loading'];
            highchartsOptions.lang.printChart = translations['highchart.printChart'];
            highchartsOptions.lang.resetZoom = translations['highchart.resetZoom'];
            highchartsOptions.lang.resetZoomTitle = translations['highchart.resetZoomTitle'];
          });
      });


      /* Yahoo weather config*/

      // Translation for yahoo weather codes

      $rootScope.$on('$translateChangeSuccess', function () {
        $translate(['yahoo.weather.code.0', 'yahoo.weather.code.1', 'yahoo.weather.code.2',
          'yahoo.weather.code.3', 'yahoo.weather.code.4', 'yahoo.weather.code.5', 'yahoo.weather.code.6',
          'yahoo.weather.code.7', 'yahoo.weather.code.8', 'yahoo.weather.code.9', 'yahoo.weather.code.10',
          'yahoo.weather.code.11', 'yahoo.weather.code.12', 'yahoo.weather.code.13', 'yahoo.weather.code.14',
          'yahoo.weather.code.15', 'yahoo.weather.code.16', 'yahoo.weather.code.17', 'yahoo.weather.code.18',
          'yahoo.weather.code.19', 'yahoo.weather.code.20', 'yahoo.weather.code.21', 'yahoo.weather.code.22',
          'yahoo.weather.code.23', 'yahoo.weather.code.24', 'yahoo.weather.code.25', 'yahoo.weather.code.26',
          'yahoo.weather.code.27', 'yahoo.weather.code.28', 'yahoo.weather.code.29', 'yahoo.weather.code.30',
          'yahoo.weather.code.31', 'yahoo.weather.code.32', 'yahoo.weather.code.33', 'yahoo.weather.code.34',
          'yahoo.weather.code.35', 'yahoo.weather.code.36', 'yahoo.weather.code.37', 'yahoo.weather.code.38',
          'yahoo.weather.code.39', 'yahoo.weather.code.40', 'yahoo.weather.code.41', 'yahoo.weather.code.42',
          'yahoo.weather.code.43', 'yahoo.weather.code.44', 'yahoo.weather.code.45', 'yahoo.weather.code.46',
          'yahoo.weather.code.47', 'yahoo.weather.code.3200'])
          .then(function (translations) {
            $rootScope.weatherConditionCodes = {
              0: translations['yahoo.weather.code.0'],
              1: translations['yahoo.weather.code.1'],
              2: translations['yahoo.weather.code.2'],
              3: translations['yahoo.weather.code.3'],
              4: translations['yahoo.weather.code.4'],
              5: translations['yahoo.weather.code.5'],
              6: translations['yahoo.weather.code.6'],
              7: translations['yahoo.weather.code.7'],
              8: translations['yahoo.weather.code.8'],
              9: translations['yahoo.weather.code.9'],
              10: translations['yahoo.weather.code.10'],
              11: translations['yahoo.weather.code.11'],
              12: translations['yahoo.weather.code.12'],
              13: translations['yahoo.weather.code.13'],
              14: translations['yahoo.weather.code.14'],
              15: translations['yahoo.weather.code.15'],
              16: translations['yahoo.weather.code.16'],
              17: translations['yahoo.weather.code.17'],
              18: translations['yahoo.weather.code.18'],
              19: translations['yahoo.weather.code.19'],
              20: translations['yahoo.weather.code.20'],
              21: translations['yahoo.weather.code.21'],
              22: translations['yahoo.weather.code.22'],
              23: translations['yahoo.weather.code.23'],
              24: translations['yahoo.weather.code.24'],
              25: translations['yahoo.weather.code.25'],
              26: translations['yahoo.weather.code.26'],
              27: translations['yahoo.weather.code.27'],
              28: translations['yahoo.weather.code.28'],
              29: translations['yahoo.weather.code.29'],
              30: translations['yahoo.weather.code.30'],
              31: translations['yahoo.weather.code.31'],
              32: translations['yahoo.weather.code.32'],
              33: translations['yahoo.weather.code.33'],
              34: translations['yahoo.weather.code.34'],
              35: translations['yahoo.weather.code.35'],
              36: translations['yahoo.weather.code.36'],
              37: translations['yahoo.weather.code.37'],
              38: translations['yahoo.weather.code.38'],
              39: translations['yahoo.weather.code.39'],
              40: translations['yahoo.weather.code.40'],
              41: translations['yahoo.weather.code.41'],
              42: translations['yahoo.weather.code.42'],
              43: translations['yahoo.weather.code.43'],
              44: translations['yahoo.weather.code.44'],
              45: translations['yahoo.weather.code.45'],
              46: translations['yahoo.weather.code.46'],
              47: translations['yahoo.weather.code.47'],
              3200: translations['yahoo.weather.code.3200']
            };
          });
      });

      $rootScope.$watch('security.context.currentUser', function (currentUser) {
        if (!currentUser) {
          $state.go('clean.login');
        }
      });

      $rootScope.$watch('security.context.currentOperator', function (currentOperator) {
        if (currentOperator) {
          $analytics.setUsername(currentOperator.id);
        }
      });

      $translate.onReady().then(function () {
        var resources = $translate.getTranslationTable(securityService.context.currentLanguageTag);
        i18n.addResources(securityService.context.currentLanguageTag, 'translation', resources);
        i18n.changeLanguage(securityService.context.currentLanguageTag);
      });

    });
})();

