import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCcAmex, faCcDinersClub, faCcDiscover, faCcMastercard, faCcVisa} from '@fortawesome/free-brands-svg-icons';
import {faCreditCard} from '@fortawesome/free-regular-svg-icons'

const cardTypes = {
  americanexpress: {
    icon: <FontAwesomeIcon icon={faCcAmex}/>,
    name: "American Express"
  },
  conecs: {
    name: "Conecs"
  },
  debit: {
    name: "Debit"
  },
  dinersclub: {
    icon: <FontAwesomeIcon icon={faCcDinersClub}/>,
    name: "Diners Club"
  },
  discover: {
    icon: <FontAwesomeIcon icon={faCcDiscover}/>,
    name: "Discover"
  },
  eftposau: {
    name: "EFTPOSAU"
  },
  interac: {
    name: "Interac"
  },
  maestro: {
    name: "Maestro"
  },
  mastercard: {
    icon: <FontAwesomeIcon icon={faCcMastercard}/>,
    name: "Mastercard"
  },
  "v-pay": {
    name: "V-Pay"
  },
  visa: {
    icon: <FontAwesomeIcon icon={faCcVisa}/>,
    name: "VISA"
  },
  giropay: {
    name: "GiroPay"
  },
  ideal: {
    name: "Ideal"
  },
  bancontact: {
    name: "Bancontact"
  },
  cartesbancaires: {
    name: "Cartes Bancaires"
  },
  unknown: {
    name: "Unknown"
  },
  other: {
    name: "Other"
  },
}

export function getCardType(type){
  return cardTypes[type] || cardTypes.unknown;
}

function Card({type, value}) {
  return <>
    {cardTypes[type]?.icon || <FontAwesomeIcon
      icon={faCreditCard}/>}&nbsp;&nbsp;{value || cardTypes[type]?.name || type}
  </>
}

export default Card;
