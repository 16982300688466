(function () {
  'use strict';
  angular.module('gf.grid.validation', ['gf.validations'])

    .directive('gfValidCell',
      function (gfStandardValidations, notifyManager, $translate) {
        return {
          restrict: 'A',
          require: 'ngModel',
          link: function (scope, iElement, attrs, modelCtrl) {

            var validators = scope.$eval(attrs.gfValidCell);

            // add standard validators by key
            _.forEach(validators, function (validator) {
              var gfValidator = gfStandardValidations[validator.name](modelCtrl, validator.value);
              modelCtrl.$parsers.push(gfValidator);
              modelCtrl.$formatters.push(gfValidator);
            });

            scope.$on('uiGridEventCancelCellEdit', function (event) {
              _.forOwn(modelCtrl.$error, function (value, key) {
                var failedValidator = _.find(validators, {name: key});
                if (failedValidator) {
                  notifyManager.error($translate.instant(failedValidator.message, failedValidator.args));
                }
              })
            })
          }
        }
      })
})
();
