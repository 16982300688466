(function () {
  'use strict';
  angular.module('gf.partnerMenuConf', [])

    .constant('partnerMenuConst',
      {
        topmenu: [
          {
            id: "necta",
            name: "necta",
            feature: ["NECTA_INTEGRATION"],
            height: 750
          },
          {
            id: "culinaro",
            name: "WaWi",
            feature: ["CHEFS_CULINAR"],
            height: 750
          }
        ],
        submenu: [
          {
            id: "necta_sub",
            name: "necta",
            parent: "necta",
            feature: ["NECTA_INTEGRATION"],
            url: "https://necta.gastrofix.com"
          },
          {
            id: "culinaro_sub",
            name: "WaWi",
            parent: "culinaro",
            feature: ["CHEFS_CULINAR"],
            url: "https://www.chefsculinar.de/genusswelt-637.htm"
          }
        ],
        tab: [
          {
            id: "culinar",
            name: "Zubereitung & Zutaten",
            parent: "article",
            feature: ["CHEFS_CULINAR"],
            url: "https://develop.gastrofix.com/static/doc/culinar/culinar_zubereitung.html"
          },
          {
            id: "culinar",
            name: "Nährwerte & Gesundheit",
            parent: "article",
            feature: ["CHEFS_CULINAR"],
            url: "https://develop.gastrofix.com/static/doc/culinar/culinar_nahrwerte.html"
          }
        ]
      }
    )
    .run(function (securityService, partnerMenuConst) {
        securityService.context.partner = partnerMenuConst;
    })
    .config(function ($stateProvider) {

      $stateProvider
        .state('page.partnerPage', {
          url:'/partner_:partner/:menuId',
          template: require('../template/iframePage.html'),
          controller: ['$scope', '$stateParams', '$sce', 'partnerMenuConst', function ($scope, $stateParams, $sce, partnerMenuConst) {
            var self = this;
            if (!partnerMenuConst) {
              return;
            }
            self.iframeUrlObj = _.filter(partnerMenuConst.submenu, function (item) {
              return item.id === $stateParams.menuId;
            });
            self.iframeLink = $sce.trustAsResourceUrl(self.iframeUrlObj[0].url);

            var partnerIframeElem = document.getElementById('partnerIframe');
            //default iframe height if partner's content height is unknown
            partnerIframeElem.style.height = partnerMenuConst.topmenu[0].height + 'px';
            //use postMessage for getting partner's content height
            var partnerIframe = function (event) {
              self.a = document.createElement('a');
              self.a.href = self.iframeLink;
              if (event.origin !== self.a.origin) {
                return;
              }
              if (partnerIframeElem) {
                partnerIframeElem.style.height = event.data + "px";
              }
            };
            if (window.addEventListener) {
              window.addEventListener("message", partnerIframe, false);
            } else if (window.attachEvent) {
              window.attachEvent("onmessage", partnerIframe);
            }
          }],
          controllerAs: 'iframeCtrl',
          title: 'partner'
        })
    })
    // directive for adding partner tabs to 'restaurant' and 'article' pages
    .directive('partnerTab', function (partnerMenuConst) {
      return {
        restrict: 'E',
        scope: {
          type: '=',
          security: '='
        },
        replace: true,
        template: '<uib-tab ng-if="security.hasAnyFeature('+"'CHEFS_CULINAR'"+')" ng-repeat="item in partnerTabCtrl.partnerTabs" heading="{{item.name | translate}}">' +
        '<div class="col-md-12 gf-restaurant-content"><iframe ng-src="{{item.url | trustAsResourceUrl}}" height="550px;" width="100%;"></iframe></div></uib-tab>',
        controllerAs: 'partnerTabCtrl',
        controller: ['$scope', function ($scope) {
          var self = this;
          if (partnerMenuConst && partnerMenuConst.tab) {
            self.partnerTabs = _.filter(partnerMenuConst.tab, function (item) {
              return item.parent == $scope.type;
            });
          }
        }]
      }
    })
})();
