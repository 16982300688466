(function () {
  'use strict';
  angular.module('gf.bm.course', ['gf.bm.groupView'])

    .factory('BMCourseGroupMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/course_group/metadata.json')
    })
    .factory('BMCourseGroupContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/course_group/context.json')
    })
    .factory('BMCourseGroupRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.masterdataCourse_group', {
          url: '/masterdata/course_group',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMMasterdataCourseGroup',
          controllerAs: '$ctrl',
          resolve: {
            bmCourseGroupMetadata: function (BMCourseGroupMetadata, $stateParams, securityService) {
              return BMCourseGroupMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmCourseGroupContext: function (BMCourseGroupContext, $stateParams, securityService) {
              return BMCourseGroupContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmCourseGroupRules: function (BMCourseGroupRules, $stateParams, securityService) {
              return BMCourseGroupRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "masterdataCourseGroup"
        })
    })

    .controller('BMMasterdataCourseGroup',
      function (bmCourseGroupMetadata, bmCourseGroupContext, securityService, $stateParams, bmCourseGroupRules) {
        var self = this;

        self.metadata = bmCourseGroupMetadata;
        self.context = bmCourseGroupContext;
        self.masterdataRules = bmCourseGroupRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name', enableCellEdit: false}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/course_group';
          }
        };

      }
    )

})();
