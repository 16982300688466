import React from 'react';
import {ExpansionPanel, ExpansionPanelSummary} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles({
  expansionPanel: {
    width: '100%',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    }
  },
  noPadding: {
    padding: '0'
  },
  detailsRoot: {
    display: 'block'
  }
});

function AdvancedSettings({children}) {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <ExpansionPanel className={classes.expansionPanel}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>} className={classes.noPadding}>
        <Typography>{t('tse.advancedSettings')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.noPadding} classes={{root: classes.detailsRoot}}>
        {children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default AdvancedSettings;
