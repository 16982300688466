(function() {
  'use strict';
  angular.module('gf.operator.data.userGroup', ['ui.router'])
    .factory('UserGroupMetadata', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/user_group/metadata.json')
    })
    .factory('UserGroupContext', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/user_group/context.json')
    })
    .factory('UserGroup', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/user_group/:userGroupId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .config(function($stateProvider) {

      $stateProvider
        .state('page.operator_dataUser_group', {
          url: '/operator_data/user_group',
          template: require('../template/operator-data/user-group/user_groups.html'),
          controller: 'UserGroupGridCtrl',
          controllerAs: 'userGroupGridCtrl',
          resolve: {
            userGroupMetadata: function (UserGroupMetadata) {
              return UserGroupMetadata.get().$promise;
            },
            userGroupContext: function (UserGroupContext) {
              return UserGroupContext.get().$promise;
            },
            userGroups: function (UserGroup) {
              return UserGroup.query().$promise;
            }
          },
          title: "userGroup"
        })

        .state('page.operator_dataUser_groupAdd', {
          url: '/operator_data/user_group/add',
          template: require('../template/operator-data/user-group/user_group.html'),
          controller: 'UserGroupAddCtrl',
          controllerAs: 'userGroupCtrl',
          resolve: {
            userGroupMetadata: function (UserGroupMetadata) {
              return UserGroupMetadata.get().$promise;
            },
            userGroupContext: function (UserGroupContext) {
              return UserGroupContext.get().$promise;
            },
            userGroup: function (UserGroup, $stateParams) {
              return UserGroup.load({userGroupId: $stateParams.id || 'add'}).$promise;
            }
          },
          title: "userGroup"
        })

        .state('page.operator_dataUser_groupDetails', {
          url: '/operator_data/user_group/:id',
          template: require('../template/operator-data/user-group/user_group.html'),
          controller: 'UserGroupEditCtrl',
          controllerAs: 'userGroupCtrl',
          resolve: {
            userGroupMetadata: function (UserGroupMetadata) {
              return UserGroupMetadata.get().$promise;
            },
            userGroupContext: function (UserGroupContext) {
              return UserGroupContext.get().$promise;
            },
            userGroup: function (UserGroup, $stateParams) {
              return UserGroup.load({userGroupId: $stateParams.id || 'add'}).$promise;
            }
          },
          title: "userGroup"
        })
    })
    .factory('UserGroupAdd', [
      function () {
        return function (self, $scope, $location, $translate, $state, notifyManager, userGroup, userGroupContext, userGroupMetadata) {
          self.userGroupContext = userGroupContext;
          self.userGroupMetadata = userGroupMetadata;
          self.userGroup = userGroup;
          angular.extend(self, userGroupContext.data.dependencies);

          self.permissionGroups = self.userGroupContext.data.permissionGroups;

          self.permissionsIds = {};
          angular.forEach(self.permissions, function (value) {
            this[value.id] = false;
          }, self.permissionsIds);
          angular.forEach(self.userGroup.permissions, function (value) {
            this[value.id] = true;
          }, self.permissionsIds);

          self.permissionsMap = {};
          angular.forEach(self.permissions, function (permission, index) {
            this[permission.id] = permission;
          }, self.permissionsMap);

          $scope.$watch(function () {
            return angular.toJson(self.permissionsIds);
          }, function () {
            self.userGroup.permissions = [];
            angular.forEach(self.permissionsIds, function (value, key) {
              if (value) {
                this.push(self.permissionsMap[key]);
              }
            }, self.userGroup.permissions);
          });

          self.checkAll = false;
          self.columnCheckboxChanged = function () {
            angular.forEach(self.permissionsIds, function (v, k) {
              self.permissionsIds[k] = self.checkAll;
            });
          };

          self.save = function () {
            if (self.userGroupForm.$valid && userGroup.permissions.length) {
              self.userGroup.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go('page.operator_dataUser_group');
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          self.saveAndNew = function () {
            if (self.userGroupForm.$valid && userGroup.permissions.length) {
              self.userGroup.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };

          $scope.tabs = _.range(self.permissionGroups.length).map(function () {
            return true;
          });

          self.isExpand = function () {
            return !$scope.tabs.reduce(function (a, b) {
              return a && b;
            });
          };

          self.toggleTabs = function () {
            var expand = self.isExpand();

            for (var i = 0; i < $scope.tabs.length; i++) {
              $scope.tabs[i] = expand;
            }
          };
        }
      }
    ])
    .controller('UserGroupAddCtrl',

      function ($scope, $location, $translate, $state, notifyManager, userGroup, userGroupContext, userGroupMetadata, UserGroupAdd) {
        var self = this;
        UserGroupAdd(self, $scope, $location, $translate, $state, notifyManager, userGroup, userGroupContext, userGroupMetadata);
      }
    )
    .controller('UserGroupEditCtrl',

      function ($scope, $location, $translate, $state, notifyManager, userGroup, userGroupContext, userGroupMetadata, UserGroupAdd) {
        var self = this;
        UserGroupAdd(self, $scope, $location, $translate, $state, notifyManager, userGroup, userGroupContext, userGroupMetadata);

        self.save = function () {
          if (self.userGroupForm.$valid && userGroup.permissions.length) {
            self.userGroup.$edit({userGroupId: self.userGroup.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.operator_dataUser_group');
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        self.saveAndNew = function () {
          if (self.userGroupForm.$valid && userGroup.permissions.length) {
            self.userGroup.$edit({userGroupId: self.userGroup.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.operator_dataUser_groupAdd');
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
      }
    )
    .controller('UserGroupGridCtrl',

      function ($scope, $location, userGroupContext, userGroupMetadata, userGroups, UserGroup, $timeout) {
        var self = this;

        // standard view tab
        self.metadata = userGroupMetadata;
        self.context = userGroupContext;

        self.config = {
          urlRoute: '/operator_data/user_group',
          enableSorting: true,
          enableAdd: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'id', width: 60, enableCellEdit: false, visible: false},
            {name: 'name', width: 200, enableCellEdit: false},
            {name: 'description', enableCellEdit: false},
            {name: 'operate', actions: ['edit', 'delete']}
          ]
        };
        $timeout(function () {
          self.showGrid = true;
        }, 0);

        // feature view tab
        self.userGroups = userGroups;
        self.permissionGroups = userGroupContext.data.permissionGroups;

        self.options = {
          url: '/icash/operator_data/user_group/all',
          permission: 'permission',
          permissionPrefix: 'userPermission.',
          groupPrefix: 'permissionGroup.'
        };

        $scope.tabs = {};
        if ($location.hash()) {
          $scope.tabs.featureView = true;
        }
      }
    )

})();
