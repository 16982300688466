(function () {
  'use strict';
  angular.module('gf.report')

    .run(function ($templateCache) {
      $templateCache.put('app/template/tax-audit/tax_audit_button.html', require('../template/tax-audit/tax_audit_button.html'));
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.taxAudit', {
          url: '/report/tax_audit',
          template: require('../template/tax-audit/tax_audit.html'),
          controller: 'TaxAuditCtrl',
          controllerAs: '$ctrl',
          resolve: {
            dates: function ($http) {
              return $http.get('/icash/report/taxaudit/dates');
            }
          },
          title: "taxAudit"
        })
    })

    .controller('TaxAuditCtrl',
      function (dates, $http, $filter, $httpParamSerializer, $translate, notifyManager, $sce, moment, dateService, $uibModal, securityService) {

        var self = this;
        var manualsUrl = '/icash/manual/';
        var tseLogUrl = '/icash/internal/';

        self.isLoading = false;
        self.longReportPeriod = false;
        self.date = 5;
        self.dates = dates.data;
        self.filter = {
          from: null,
          to: null
        };

        var tseStatus = null;

        function getTseStatus() {
          $http.get('/icash/internal/status/pos', {headers: {'X-Business-Units': securityService.context.currentRestaurant.id}})
            .then(function (res) {
              tseStatus = res.data;
            }, function () {
              tseStatus = null;
            });
        }

        self.version = "";
        $http.get('/icash/internal/dsfinvk/version', {headers: {'X-Business-Units': securityService.context.currentRestaurant.id}}).then(function (res) {
          self.version = res.data.version;
        });

        if (securityService.hasAnyFeature('GOBD')) {
          getTseStatus();
        }

        var chosenDates = dateService.getPeriodDates(5);

        function isImpossibleDownloadReport() {
          var ONE_DAY = 60 * 60 * 24 * 1000;
          //quantity of days for more than which show only mail buttons
          var value = 31;
          //check year
          if (self.date === 7) {
            //always true for year report.
            return true;
          }
          //check from - to
          return self.date === 8 && (moment(self.filter.to).valueOf() - moment(self.filter.from).valueOf()) / ONE_DAY > value;
        }

        self.updateDate = function (date, from, to) {
          self.longReportPeriod = isImpossibleDownloadReport();
          chosenDates = dateService.getPeriodDates(date, from, to);
          if (from && date === 8) {
            self.filter.from = from;
          } else {
            self.filter.from = null;
          }
          if (to && date === 8) {
            self.filter.to = to;
          } else {
            self.filter.to = null;
          }
          self.date = date;
        };

        function prepareParameters() {
          var data = {
            dateId: self.date,
            filter: self.filter || 0
          };
          if (self.date === 8) {
            data.from = $filter("date")(self.filter.from, 'yyyy-MM-dd');
            data.to = $filter("date")(self.filter.to, 'yyyy-MM-dd');
          } else {
            self.filter.from = null;
            self.filter.to = null;
          }
          return data;
        }

        function getDocumentUrl(format, name) {
          return '/icash/report/taxaudit/' + name + '/download?' + $httpParamSerializer(prepareParameters());
        }

        function sendToMail(format, name) {
          return $http.get('/icash/report/taxaudit/' + name + '/mail?' + $httpParamSerializer(prepareParameters()));
        }

        function downloadReport(format, name) {
          $http.get(getDocumentUrl(format, name)).then(function (response) {
            self.documentUrl = $sce.trustAsResourceUrl(response.data.payload.location);
          });
        }

        function downloadManualAndXKSV(name, href) {
          var element = document.createElement('a');
          element.href = href;
          element.download = name;
          document.body.appendChild(element);
          element.click();
        }

        function downloadManual(name) {
          $http.get(manualsUrl + name).then(function (response) {
            downloadManualAndXKSV(name, response.data.payload.pathToManual);
          });
        }

        function downloadXKSVDocs(format, name) {
          self.isLoading = true;
          var params = {
            locationId: securityService.context.currentRestaurant.id,
            startDate: dateService.getPeriodDates(self.date, self.filter.from, self.filter.to).startDate,
            endDate: dateService.getPeriodDates(self.date, self.filter.from, self.filter.to).endDate,
          };
          $http.get(tseLogUrl + name + '/' + params.startDate + '/' + params.endDate, {headers: {'X-Business-Units': params.locationId}})
            .then(function (response) {
              self.isLoading = false;
              if (_.isEmpty(response.data) || _.isNull(response.data[Object.keys(response.data)[0]])) {
                $uibModal.open({
                  windowClass: 'gf-modal',
                  template: require('../template/tax-audit/gtc_docs_period_modal.html'),
                  controllerAs: '$ctrl',
                  resolve: {
                    dsfinvk: function ($http) {
                      if (name === 'dsfinvk') {
                        return $http.get('/icash/internal/api/transaction/business_periods', {headers: {'X-Business-Units': params.locationId}});
                      }
                    }
                  },
                  controller: function (dsfinvk) {
                    var self = this;
                    self.reportName = name === 'dsfinvk' ? 'DSFinV-K' : 'TSE Log';
                    if (!_.isEmpty(tseStatus)) {
                      self.tseRegisteredOn = tseStatus.tseInformation.initializedOn;
                      self.isBeforeDeviceRegistered = moment(self.tseRegisteredOn, 'YYYY-MM-DD').isAfter(moment(params.endDate, 'YYYY-MM-DD'))
                    } else {
                      if (dsfinvk.data.businessPeriods && dsfinvk.data.businessPeriods.length) {
                        self.startGtcDate = _.reduce(_.map(dsfinvk.data.businessPeriods, 'businessDay'), function (a, b) {
                          return moment(a, 'YYYY-MM-DD').isBefore(moment(b, 'YYYY-MM-DD')) ? a : b;
                        });
                        self.isBeforeGtc = moment(self.startGtcDate, 'YYYY-MM-DD').isAfter(moment(params.endDate, 'YYYY-MM-DD'))
                      }
                    }
                  }
                })
              } else {
                for (var key in response.data) {
                  if (response.data.hasOwnProperty(key)) {
                    var href = 'data:text/plain;base64,' + response.data[key];
                    downloadManualAndXKSV(key + '.' + format, href);
                  }
                }
              }
            }, function () {
              self.isLoading = false;
              notifyManager.error($translate.instant('failedToConnectToServer'));
            })
        }

        self.prepareDownload = function (item) {
          self.documentUrl = null;
          self.manualUrl = null;
          if (self.date === 8) {
            if (!self.filter.from || !self.filter.to) {
              notifyManager.error($translate.instant('rksv.dep.chooseDates'));
              return;
            }
          }
          if (self.longReportPeriod && item.hasMail || name === 'all') {
            sendToMail(item.format, item.name).then(function () {
              notifyManager.success($translate.instant('taxAudit.reportIsSentToMail'));
            });
          } else {
            item.download();
          }
        };

        self.getIcon = function (item) {
          if (self.longReportPeriod && item.hasMail) {
            return 'fa-envelope-o';
          } else {
            return item.buttonDownloadIcon;
          }
        };

        self.getButtonTitle = function (item) {
          return self.longReportPeriod && item.hasMail ? item.buttonMailTitle : item.buttonDownloadTitle;
        };

        self.taxAuditConfig = {
          col1: [
            {
              id: 'tax-audit-tse-log',
              name: 'tse',
              title: 'taxAudit.tseLog',
              description: 'taxAudit.tseLog.description',
              buttonDownloadTitle: 'report.download.tar',
              buttonDownloadIcon: 'fa-download',
              format: 'tar',
              hasMail: false,
              buttonMailTitle: 'report.mailZip',
              isVisible: function (security) {
                return security.hasAnyFeature('GOBD') && tseStatus && !_.isEmpty(tseStatus);
              },
              download: function () {
                downloadXKSVDocs(this.format, this.name);
              }
            },
            {
              id: 'tax-audit-report-gobd',
              name: 'gobd',
              title: 'taxAudit.gobdExport',
              description: 'taxAudit.gobdExport.description',
              buttonDownloadTitle: 'report.download.zip',
              buttonDownloadIcon: 'fa-download',
              format: 'zip',
              hasMail: true,
              buttonMailTitle: 'report.mailZip',
              isVisible: function (security) {
                return security.hasAnyFeature('GOBD');
              },
              download: function () {
                downloadReport(this.format, this.name);
              }
            },
            {
              id: 'tax-audit-manual-gobd',
              name: 'gobd-export',
              title: 'taxAudit.gobdExportDescr',
              description: 'taxAudit.gobdExportDescr.description',
              buttonDownloadTitle: 'report.download.pdf',
              buttonDownloadIcon: 'fa-file-pdf-o',
              isVisible: function (security) {
                if (security.context.theme.name === 'shore' || security.context.theme.name === 'sumup')
                  return false;
                return security.hasAnyFeature('GOBD');
              },
              download: function () {
                downloadManual(this.name);
              }
            },
            {
              id: 'tax-audit-report-saft',
              name: 'saft',
              title: 'taxAudit.saftExport',
              description: 'taxAudit.saftExport.description',
              buttonDownloadTitle: 'report.download.mail.xml',
              buttonDownloadIcon: 'fa-envelope-o',
              format: 'zip',
              isVisible: function (security) {
                return security.hasAnyFeature('SAFT');
              },
              download: function () {
                $http.get('/icash/restaurant_data/norway/saft/' + self.date + '/mail?' + $httpParamSerializer(prepareParameters())).then(function (res) {
                  if (res.data.ok) {
                    notifyManager.success($translate.instant('rksv.dep.reportSent'));
                  } else {
                    notifyManager.error($translate.instant('rksv.dep.failToSendReport'));
                  }
                });
              }
            },
            {
              id: 'tax-audit-report-pos_ledger',
              name: 'pos_ledger',
              title: 'taxAudit.cashbookExport',
              description: 'taxAudit.cashbookExport.description',
              buttonDownloadTitle: 'report.download.pdf',
              buttonDownloadIcon: 'fa-download',
              format: 'zip',
              hasMail: true,
              buttonMailTitle: 'report.download.mail.pdf',
              isVisible: function (security) {
                return security.hasAnyFeature('POS_LEDGER');
              },
              download: function () {
                downloadReport(this.format, this.name);
              }
            },
            {
              id: 'tax-audit-report-history',
              name: 'history2',
              title: 'taxAudit.history',
              description: 'taxAudit.history.description',
              buttonDownloadTitle: 'report.download.zip',
              buttonDownloadIcon: 'fa-download',
              format: 'zip',
              hasMail: true,
              buttonMailTitle: 'report.mailZip',
              isVisible: function () {
                return true;
              },
              download: function () {
                downloadReport(this.format, this.name);
              }
            },
            {
              id: 'tax-audit-manual-gobd',
              name: 'history',
              title: 'taxAudit.historyDescr',
              description: 'taxAudit.historyDescr.description',
              buttonDownloadTitle: 'report.download.pdf',
              buttonDownloadIcon: 'fa-file-pdf-o',
              isVisible: function (security) {
                return security.context.theme.name === 'lightspeed' || security.context.theme.name === 'culinaro';
              },
              download: function () {
                downloadManual(this.name);
              }
            }
          ],
          col2: [
            {
              id: 'tax-audit-dsfinv-k',
              name: 'dsfinvk',
              title: 'taxAudit.dsfinvK',
              description: 'taxAudit.dsfinvK.description',
              buttonDownloadTitle: 'report.download.zip',
              buttonDownloadIcon: 'fa-download',
              format: 'zip',
              isVisible: function (security) {
                return security.hasAnyFeature('GOBD');
              },
              download: function () {
                downloadXKSVDocs(this.format, this.name);
              },
              version: true
            },
            {
              id: 'tax-audit-manual-cloud',
              name: 'cloud',
              title: 'taxAudit.manualCloud',
              description: 'taxAudit.manualCloud.description',
              buttonDownloadTitle: 'report.download.pdf',
              buttonDownloadIcon: 'fa-file-pdf-o',
              isVisible: function (security) {
                return security.context.theme.name === 'default';
              },
              download: function () {
                downloadManual(this.name);
              }
            },
            {
              id: 'tax-audit-manual-icash',
              name: 'icash',
              title: 'taxAudit.manualApp',
              description: 'taxAudit.manualApp.description',
              buttonDownloadTitle: 'report.download.pdf',
              buttonDownloadIcon: 'fa-file-pdf-o',
              isVisible: function (security) {
                return security.context.theme.name === 'default';
              },
              download: function () {
                downloadManual(this.name);
              }
            },
            {
              id: 'tax-audit-manual-processdocumentation',
              name: 'processdocumentation',
              title: 'taxAudit.documentation',
              description: 'taxAudit.documentation.description',
              buttonDownloadTitle: 'report.download.pdf',
              buttonDownloadIcon: 'fa-file-pdf-o',
              isVisible: function (security) {
                return security.context.theme.name === 'lightspeed' || security.context.theme.name === 'culinaro';
              },
              download: function () {
                downloadManual(this.name);
              }
            }
          ],
          supportLink: {
            isVisible: function (security) {
              return security.context.theme.name === 'default';
            },
            getLinkUrl: function (security) {
              if (security.hasAnyFeature('SAFT')) {
                return 'http://support.barcontrol.no/';
              } else {
                return 'https://support.gastrofix.com/hc/de';
              }
            }
          }
        }
      }
    )
    .factory('dateService', function (moment) {
      return {
        checkChosenPeriod: function (period, from, to, ranges) {
          var chosenDates = this.getPeriodDates(period, from, to);
          var tsePeriod = _.find(ranges, function (range) {
            return moment(chosenDates.startDate, 'YYYY-MM-DD').isBetween(moment(range.start, 'YYYY-MM-DD'), moment(range.end, 'YYYY-MM-DD'))
              || moment(chosenDates.startDate, 'YYYY-MM-DD').isSame(moment(range.start, 'YYYY-MM-DD'))
          });
          return tsePeriod ? !moment(chosenDates.endDate, 'YYYY-MM-DD').isBetween(moment(tsePeriod.start, 'YYYY-MM-DD'), moment(tsePeriod.end, 'YYYY-MM-DD')) : true
        },
        getTsePeriods: function (periods) {
          var dates = [];
          var start, end;
          _.forEach(periods, function (period, index, array) {
            if (period.status === 'init') {
              start = period.tseLogDate;
            } else if (period.status === 'shutdown') {
              end = period.tseLogDate;
              dates.push({start: start, end: end})
            }
            if (index === array.length - 1 && period.status === 'init') {
              dates.push({start: period.tseLogDate, end: moment().format('YYYY-MM-DD')})
            }
          });
          return dates;
        },
        getPeriodDates: function (datePeriod, from, to) {
          switch (datePeriod) {
            //today
            case 1: {
              return {
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
              }
            }
            //yesterday
            case 2: {
              return {
                startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
                endDate: moment().subtract(1, 'days').format('YYYY-MM-DD')
              }
            }
            //this week
            case 3: {
              return {
                startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
              }
            }
            //last week
            case 4: {
              return {
                startDate: moment().weekday(-6).startOf('isoWeek').format('YYYY-MM-DD'),
                endDate: moment().weekday(-6).endOf('isoWeek').format('YYYY-MM-DD')
              }
            }
            //this month
            case 5: {
              return {
                startDate: moment().date(1).format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
              }
            }
            //last month
            case 6: {
              return {
                startDate: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
                endDate: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
              }
            }
            //this year
            case 7: {
              return {
                startDate: moment().startOf('year').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD')
              }
            }
            case 8: {
              return {
                startDate: moment(from).format('YYYY-MM-DD'),
                endDate: moment(to).format('YYYY-MM-DD')
              }
            }
          }
        }
      }
    })
})
();
