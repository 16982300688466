(function () {
  'use strict';
  angular.module('gf.report.popupFilter', [])

    .controller('ReportFilterAddPopupController', function ($scope, $uibModalInstance, notifyManager, $translate, $http,
                                                            reportFilterMetadata, reportFilterContext, reportFilter, reportData, reportId) {
      $scope.metadata = reportFilterMetadata;
      $scope.context = reportFilterContext.data.dependencies;
      $scope.reportId = reportId;
      $scope.reportFilter = reportFilter;
      $scope.filters = reportData.payload.filters;
      $scope.hasAppliedFilter = !!reportFilter.id;

      $scope.contains = function (array, value) {
        return _.includes(array, value);
      };

      function getFilterId(filters) {
        return _(filters)
          .filter(function (o) {
            return o.name == reportId;
          })
          .map('id')
          .first();
      }

      var filterId = getFilterId($scope.filters);

      function prepareNamesList(filterArray, contextList) {
        return _.map(filterArray, function (item) {
          _.assign(item, _.find(contextList, ['id', item.id]));
          return item.name;
        }).join(', ');
      }

      //show description in the POS Ledger reports instead of name
      function prepareDescription() {
        var description = '';
        if ($scope.reportFilter.waiters.length > 0) {
          description = $translate.instant('reportFilter.waiters') + ': '
            + prepareNamesList($scope.reportFilter.waiters, $scope.context.waiters)
        }
        if ($scope.reportFilter.openWaiters.length > 0) {
          description = (description ? description + '; ' : '') + $translate.instant('reportFilter.openWaiters') + ': '
            + prepareNamesList($scope.reportFilter.openWaiters, $scope.context.waiters)
        }
        if ($scope.reportFilter.closeWaiters.length > 0) {
          description = (description ? description + '; ' : '') + $translate.instant('reportFilter.closeWaiters') + ': '
            + prepareNamesList($scope.reportFilter.closeWaiters, $scope.context.waiters)
        }
        if ($scope.reportFilter.devices.length > 0) {
          description = (description ? description + '; ' : '') + $translate.instant('devices') + ': '
            + prepareNamesList($scope.reportFilter.devices, $scope.context.devices)
        }
        if ($scope.reportFilter.amountStart != 0) {
          description = (description ? description + '; ' : '') + $translate.instant('position.amount') + ' '
            + $translate.instant('from') + ': ' + $scope.reportFilter.amountStart;
        }
        if ($scope.reportFilter.amountEnd != 99999) {
          description = (description ? description + '; ' : '') + $translate.instant('position.amount') + ' '
            + $translate.instant('payAtTable.to') + ': ' + $scope.reportFilter.amountEnd;
        }
        if ($scope.reportFilter.openTables) {
          description = (description ? description + '; ' : '') + $translate.instant('reportFilter.openTables');
        }
        return description;
      }

      $scope.apply = function () {
        $scope.reportFilter.name = reportId;
        $scope.reportFilter.id = filterId;
        $scope.reportFilter.description = prepareDescription();
        if (filterId) {
          $http.put('/icash/report/report_filter/19/' + filterId + '.json', $scope.reportFilter).then(function (response) {
            if (response.data.ok) {
              notifyManager.success($translate.instant('reportFilter.filterAppliedSuccessfully'));
              $uibModalInstance.close(filterId);
            }
          });
        } else {
          $http.post('/icash/report/report_filter/19.json', $scope.reportFilter).then(function (response) {
            if (response.data.ok) {
              notifyManager.success($translate.instant('reportFilter.filterAppliedSuccessfully'));
              $uibModalInstance.close(response.data.payload.filterId);
            }
          });
        }
      };

      $scope.close = function () {
        $uibModalInstance.close(filterId);
      };

      $scope.reset = function () {
        filterId = 0;
        $uibModalInstance.close(filterId);
      }
    })

})();
