(function () {
  'use strict';
  angular.module('gf.financial.reasons', ['ui.router'])

    .factory('AccountTypes', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/reasons/accountTypes.json')
    })
    .factory('ReasonsMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/reasons/metadata.json')
    })
    .factory('ReasonsContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/reasons/:accountTypeId/context.json');
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.financialReasons', {
          url: '/financial/reasons',
          template: require('../template/reasons.html'),
          controller: 'ReasonsGridCtrl',
          controllerAs: 'reasonsGridCtrl',
          resolve: {
            account: function (AccountTypes) {
              return AccountTypes.get().$promise;
            },
            reasonsContext: function (ReasonsContext) {
              return ReasonsContext.get({accountTypeId: 0}).$promise;
            },
            reasonsMetadata: function (ReasonsMetadata) {
              return ReasonsMetadata.get().$promise;
            }
          },
          title: "reasons"
        })

        .state('page.financialReasonsAdd', {
          url: '/financial/reasons/add',
          template: require('../template/add_reasons.html'),
          controller: 'ReasonsAddCtrl',
          controllerAs: 'reasonsAddGridCtrl',
          resolve: {
            account: function (AccountTypes) {
              return AccountTypes.get().$promise;
            },
            reasonsContext: function (ReasonsContext) {
              return ReasonsContext.get({accountTypeId: 0}).$promise;
            },
            reasonsMetadata: function (ReasonsMetadata) {
              return ReasonsMetadata.get().$promise;
            },
            reasons: function (Reasons, $stateParams) {
              return Reasons.load({accountTypeId: $stateParams.id || 0, method: 'add.json'}).$promise;
            }
          },
          title: "reasons"
        })
    })

    .service('reasonsService', function () {
      var lastAddedAccountType = null;
      var setLastAccountType = function (accountType) {
        if (accountType) {
          lastAddedAccountType = accountType;
        } else {
          lastAddedAccountType = null;
        }
      };
      var getLastAccountType = function () {
        return lastAddedAccountType;
      };
      return {
        setLastAccountType: setLastAccountType,
        getLastAccountType: getLastAccountType
      }
    })

    .controller('ReasonsGridCtrl',

      function (account, ReasonsContext, $scope, $translate, $location, notifyManager, reasonsContext, reasonsMetadata, reasonsService, uiGridConstants, CreateDefaultReasons, $uibModal) {
        var self = this;
        self.accountTypes = account.payload.accountTypes;
        self.reasonInfoAlert = true;
        self.context = reasonsContext;
        self.metadata = reasonsMetadata;
        self.accountType = reasonsService.getLastAccountType();
        self.categoryCode = reasonsContext.data.dependencies.categoryCode;
        if (self.accountType) {
          ReasonsContext.get({accountTypeId: self.accountType.id}).$promise.then(function (data) {
            self.context = data
          });
          self.reasonInfoAlert = !self.accountType;
        }

        self.config = {
          urlRoute: '/financial/reasons',
          enableSorting: true,
          enableAdd: true,
          enableFiltering: true,
          enableAddRow: true,
          onRegisterApi: function (gridApi) {
            $scope.gridApi = gridApi;
            $scope.gridApi.edit.on.beginCellEdit($scope, function (rowEntity, colDef, newValue, oldValue) {
              if (colDef.name === 'categoryCode' && newValue !== oldValue) {
                self.disableUsedCategories(gridApi.grid.appScope.gridOptions.data, self.context.data.dependencies.categoryCode);
              }
            })
          },
          columns: [
            {
              name: 'description',
              sort: {
                direction: uiGridConstants.ASC
              },
              filter: {
                placeholder: $translate.instant('searchDescription')
              },
              cellEditableCondition: function () {
                return self.accountType ? true : false;
              },
              cellTemplate: '<div class="ui-grid-cell-contents"><div ng-show="COL_FIELD">{{COL_FIELD}}</div><div class="grid-placeholder" ng-hide="COL_FIELD" translate="addDescription">Add description</div></div>'
            },
            {
              name: 'account',
              filter: {
                placeholder: $translate.instant('searchAccount')
              },
              cellEditableCondition: function () {
                return self.accountType ? true : false;
              },
              width: 400
            },
            {
              name: 'categoryCode',
              filter: {
                placeholder: $translate.instant('searchCategory')
              },
              cellEditableCondition: function () {
                return self.context.data.dependencies.categoryCode;
              },
              validations: {
                'gf-uniquereasoncategory': {
                  'angular': {'gf-uniquereasoncategory': ''},
                  'errorMessage': "de.icash.validator.UniqueReasonCategory.Reason.message"
                }
              },
              width: 300,
              cellFilter: "prefixFilter:'reasonCategoryCode.' | translate",
              editDropdownFilter: "prefixFilter:'reasonCategoryCode.' | translate"
            },
            {name: 'safe', width: 100},
            {name: 'operate', actions: ['delete']}
          ]
        };

        if (self.context.data.isCreateDefaultReason) {
            $uibModal.open({
              template: require('../template/create_default_reasons_dialog.html'),
              windowClass: 'gf-modal'
            }).result
              .then(function () {
                var pleasWaiteDialog = $uibModal.open({
                  template: require('../../../main/angular/app/template/page/please_wait.html'),
                  windowClass: 'gf-modal'
                });
                CreateDefaultReasons.save(function (data) {
                  pleasWaiteDialog.close();
                  if (data.ok) {
                    notifyManager.success($translate.instant('reason.default.create.notify.success'));
                  } else {
                    notifyManager.error($translate.instant('reason.default.create.notify.error'));
                  }

                });

              })
          }

        self.disableUsedCategories = function (gridData, allCategories) {
          _.chain(allCategories)
            .map(function (obj) {
              obj.disabled = _.some(gridData, function (o) {
                return o.categoryCode ? o.categoryCode.name === obj.name : false
              });
              return obj;
            }).value();
        };

        self.accountTypeSelected = function () {
          self.reasonInfoAlert = !self.accountType;
          ReasonsContext.get({accountTypeId: self.accountType ? self.accountType.id : 0}).$promise.then(function (data) {
            self.context = data;
            if (self.accountType && self.accountType.id) {
              self.categoryCode = data.data.dependencies.categoryCode;
              reasonsService.setLastAccountType(_.filter(self.accountTypes, function (type) {
                return type.id == self.accountType.id;
              })[0]);
            } else {
              reasonsService.setLastAccountType();
            }
            $scope.$applyAsync(function () {
              self.config.reloadData();
            });
          });
        };
      }
    )

    .controller('ReasonsAddCtrl',

      function (account, ReasonsContext, $scope, $translate, reasons, $location, notifyManager, reasonsContext, reasonsMetadata, $state, reasonsService) {
        var self = this;
        self.accountTypes = account.payload.accountTypes;
        self.addReasonInfoAlert = true;
        self.reasons = reasons;
        self.context = reasonsContext;
        self.metadata = reasonsMetadata;
        self.reasons.accountType = reasonsService.getLastAccountType();
        if (self.reasons.accountType) {
          ReasonsContext.get({accountTypeId: self.reasons.accountType ? self.reasons.accountType.id : 0}).$promise
            .then(function (data) {
              self.addReasonInfoAlert = !self.reasons.accountType;
              self.context = data;
              self.accountList = self.context.data.dependencies.account;
              self.categoryCodeList = self.context.data.dependencies.categoryCode;
            });
        }

        /*delete client validation after adding on server side*/
        self.metadata.columns.accountType.angular = {"required": ""};
        self.metadata.columns.accountType.validation = ["required"];
        self.metadata.columns.accountType.errorMessages = {
          0: {
            'keys': ["required"],
            'message': "javax.validation.constraints.NotNull.message",
            'name': "Required"
          }
        };

        self.addReasonAccountTypeSelected = function () {
          ReasonsContext.get({accountTypeId: self.reasons.accountType ? self.reasons.accountType.id : 0}).$promise.then(function (data) {
            self.addReasonInfoAlert = !self.reasons.accountType;
            self.context = data;
            self.accountList = self.context.data.dependencies.account;
            self.categoryCodeList = self.context.data.dependencies.categoryCode;
            if (self.reasons.accountType && self.reasons.accountType.id) {
              reasonsService.setLastAccountType(_.filter(self.accountTypes, function (type) {
                return type.id == self.reasons.accountType.id;
              })[0]);
            } else {
              reasonsService.setLastAccountType();
            }
          })
        };

        $scope.save = function () {
          if (self.reasonsForm.$valid) {
            self.reasons.$save({accountTypeId: self.reasons.accountType ? self.reasons.accountType.id : 0}, function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $state.go('page.financialReasons')
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

        $scope.saveAndNew = function () {
          if (self.reasonsForm.$valid) {
            self.reasons.$save({accountTypeId: self.accountType ? self.accountType.id : 0}, function () {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $state.reload();
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
      }
    )
})();
