(function () {
  'use strict';
  angular.module('gf.masterdata.article.group', [])

    .factory('ArticleGroupContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/article_group/context.json');
    })
    .factory('ArticleGroupMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/article_group/metadata.json');
    })
    .factory('ArticleGroupMetadataNew', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/article_group/metadata_new.json');
    })
    .factory('ArticleGroupChangeId', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/article_group/change_id');
    })
    .factory('ArticleGroup', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/article_group/:articleGroupId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .config(function ($stateProvider) {

      $stateProvider
        .state('page.masterdataArticle_group', {
          url: '/masterdata/article_group',
          template: require('../template/page/masterdata/article_groups.html'),
          controller: 'ArticleGroupGridCtrl',
          controllerAs: 'articleGroupGridCtrl',
          resolve: {
            articleGroupContext: function (ArticleGroupContext) {
              return ArticleGroupContext.get().$promise;
            },
            articleGroupMetadata: function (ArticleGroupMetadata) {
              return ArticleGroupMetadata.get().$promise;
            },
            articleGroupMetadataNew: function (ArticleGroupMetadataNew) {
              return ArticleGroupMetadataNew.get().$promise;
            },
            articleGroup: function (ArticleGroup, $stateParams) {
              return ArticleGroup.load({articleGroupId: $stateParams.articleGroupId || 'add'}).$promise;
            },
            default_icons: function (icons) {
              return icons['default'].get().$promise;
            },
            currentTour: function (Tour) {
              return Tour.get({page: 'masterdataArticle_group'}).$promise;
            }
          },
          title: "articleGroup"
        })

        .state('page.masterdataArticle_groupAdd', {
          url: '/masterdata/article_group/add',
          template: require('../template/page/masterdata/article_group.html'),
          controller: 'ArticleGroupAddCtrl',
          controllerAs: 'articleGroupEditCtrl',
          resolve: {
            articleGroupContext: function (ArticleGroupContext) {
              return ArticleGroupContext.get().$promise;
            },
            articleGroupMetadata: function (ArticleGroupMetadata) {
              return ArticleGroupMetadata.get().$promise;
            },
            articleGroupMetadataNew: function (ArticleGroupMetadataNew) {
              return ArticleGroupMetadataNew.get().$promise;
            },
            articleGroup: function (ArticleGroup, $stateParams) {
              return ArticleGroup.load({articleGroupId: $stateParams.articleGroupId || "add"}).$promise;
            },
            currentTour: function (Tour) {
              return Tour.get({page: 'masterdataArticle_groupAdd'}).$promise;
            }
          },
          title: "articleGroup"
        })

        .state('page.masterdataArticle_groupDetails', {
          url: '/masterdata/article_group/:id',
          template: require('../template/page/masterdata/article_group.html'),
          controller: 'ArticleGroupEditCtrl',
          controllerAs: 'articleGroupEditCtrl',
          resolve: {
            articleGroupContext: function (ArticleGroupContext) {
              return ArticleGroupContext.get().$promise;
            },
            articleGroupMetadata: function (ArticleGroupMetadata) {
              return ArticleGroupMetadata.get().$promise;
            },
            articleGroupMetadataNew: function (ArticleGroupMetadataNew) {
              return ArticleGroupMetadataNew.get().$promise;
            },
            articleGroup: function (ArticleGroup, $stateParams) {
              return ArticleGroup.load({articleGroupId: $stateParams.id || "add"}).$promise;
            }
          },
          title: "articleGroup"
        })
    })
    .controller('ArticleGroupEnterIdCtrl',

      function ($scope, $uibModalInstance) {
        if (!'articleGroupEnterId' in $scope) {
          $scope.articleGroupEnterId = {};
        }

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.use = function () {
          $uibModalInstance.close();
        }
      }
    )
    .controller('ArticleGroupChangeIdCtrl',

      function ($scope, $uibModalInstance, $resource, $stateParams, $translate, notifyManager, ArticleGroupChangeId) {
        if (!$scope.articleGroupChangeId) {
          $scope.articleGroupChangeId = new ArticleGroupChangeId({oldId: $stateParams.articleGroupId});
        }

        $scope.ok = function () {
          $uibModalInstance.close();
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.change = function () {
          $scope.articleGroupChangeId.$save(
            function (data) {
              if (data.ok) {
                $uibModalInstance.close(data.payload.articleGroup);
                if ($scope.updateContext) {
                  $scope.updateContext(data.payload.articleGroup);
                }
              } else {
                notifyManager.error($translate.instant(data.message));
                $uibModalInstance.close();
              }
            });
        }
      }
    )
    .factory('ArticleGroupAdd',
      function (securityService, $http, $location, $state, $uibModal, $translate, notifyManager, PluService) {

        return function (self, $scope, ArticleGroup, articleGroup, articleGroupContext, articleGroupMetadata, articleGroupMetadataNew) {

          self.articleGroupContext = articleGroupContext;
          self.articleGroupMetadata = articleGroupMetadata;
          self.metadataNew = articleGroupMetadataNew;
          self.articleGroup = articleGroup;
          angular.extend(self, articleGroupContext.data.dependencies);
          self.newItem = true;

          self.multiselectDefaultSettings = $scope.multiselectDefaultSettings;
          self.multiselectText = $scope.multiselectText;

          // limit ids if fiscal restriction exist
          if (securityService.context.fiscalRegulation && securityService.context.fiscalRegulation.enableArticleGroupsRestrictions) {
            articleGroupMetadata.columns['id'].angular.max = '99';
            var maxErrorMessage = _.find(articleGroupMetadata.columns['id'].errorMessages, function (item) {
              return item.name == "Max";
            });
            maxErrorMessage.args.value = '99';
          }

          if (self.measurementUnit && self.measurementUnit instanceof Array && self.measurementUnit.length > 0) {
            self.measurementUnit.forEach(function (unit) {
              unit.label = $translate.instant('measurementUnit.' + unit.name) + ' (' + $translate.instant('measurementUnit.' + unit.name + '.desc') + ')';
            });
          }

          if (!self.articleGroup.id) {
            self.articleGroup.id = PluService.acquire("/icash/masterdata/article_group/plu");
          }

          self.save = function () {
            if (self.articleGroupForm.$valid) {
              self.articleGroup.$save(function (data) {
                self.articleGroup = data.payload.entity;
                notifyManager.success($translate.instant('entity.edit.successfully'));
                $state.go('page.masterdataArticle_group');
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };
          self.saveAndNew = function () {
            if (self.articleGroupForm.$valid) {
              self.articleGroup.$save(function (data) {
                self.articleGroup = data.payload.entity;
                notifyManager.success($translate.instant('entity.edit.successfully'));
                $state.go('page.masterdataArticle_groupAdd');
                self.articleGroupForm.$setPristine(true);
                self.articleGroup = new ArticleGroup();
                angular.extend(self.articleGroup, articleGroupContext.template);
                if (!self.articleGroup.id) {
                  self.articleGroup.id = PluService.acquire("/icash/masterdata/article_group/plu");
                }
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };

          self.getTaxClasses = function (searchStr) {
            var params = {
              term: searchStr
            };
            $http.get('/icash/financial/tax/find', {params: params})
              .then(function (resp) {
                self.taxClasses = resp.data.payload.taxClasses
              });
          };

          self.disableMainFields = function () {
            return !self.newItem && !self.articleGroupContext.data.superGroupMainInfoEditable && !self.articleGroup.parent;
          };

          self.defaultInternalVatName = securityService.context.currentRestaurant.internalVat ? securityService.context.currentRestaurant.internalVat.name : '';
          self.defaultExternalVatName = securityService.context.currentRestaurant.externalVat ? securityService.context.currentRestaurant.externalVat.name : '';
        }
      }
    )
    .controller('ArticleGroupAddCtrl',

      function ($scope, ArticleGroup, articleGroup, articleGroupContext, articleGroupMetadata, articleGroupMetadataNew,
                ArticleGroupAdd, currentTour, tourService) {

        var self = this;

        ArticleGroupAdd(self, $scope, ArticleGroup, articleGroup, articleGroupContext, articleGroupMetadata, articleGroupMetadataNew);

        self.currentTour = currentTour;
        tourService.startDetachedTour('masterdataArticle_groupAdd', currentTour);
      }
    )
    .controller('ArticleGroupEditCtrl',

      function ($scope, $location, $state, $uibModal, $translate, notifyManager, ArticleGroup, articleGroup,
                articleGroupContext, articleGroupMetadata, articleGroupMetadataNew, ArticleGroupAdd, securityService,
                ArticleGroupChangeId) {

        var self = this;

        ArticleGroupAdd(self, $scope, ArticleGroup, articleGroup, articleGroupContext, articleGroupMetadata, articleGroupMetadataNew);
        self.newItem = false;

        // adjust validations for parent field
        if (!self.articleGroupContext.data.superGroupMainInfoEditable && !self.articleGroup.parent) {
          self.metadataNew.fields.parent.validators =
            _.filter(self.metadataNew.fields.parent.validators, function (o) {
              return o.name !== "required";
            });
        }

        self.editId = function () {

          var isFiscalRegulated = securityService.context.fiscalRegulation && securityService.context.fiscalRegulation.enableArticleGroupsRestrictions;
          if (!isFiscalRegulated) {
            $scope.min = '1';
            $scope.max = '';
          } else {
            if ($scope.articleGroup.parent) {
              $scope.min = '11';
              $scope.max = '99';
            } else {
              $scope.min = '1';
              $scope.max = '10';
            }
          }

          $scope.articleGroupChangeId = new ArticleGroupChangeId({oldId: self.articleGroup.id});

          var modalDialog = $uibModal.open({
            template: require('../template/page/masterdata/change_id.html'),
            windowClass: 'gf-modal',
            controller: 'ArticleGroupChangeIdCtrl',
            scope: $scope
          });

          modalDialog.result.then(function (articleGroup) {
            if (articleGroup) {
              notifyManager.success($translate.instant('changeId.changes'));
              $location.path('/masterdata/article_group/' + articleGroup.id);
            }
          });
        };
      }
    )
    .controller('ArticleGroupGridCtrl',

      function ($scope, $uibModal, $http, $location, $translate, ArticleGroupChangeId, notifyManager,
                articleGroupContext, articleGroupMetadata, articleGroupMetadataNew, PluService, default_icons,
                securityService, currentTour, tourService, configOptionsService) {

        var self = this;

        self.currentTour = currentTour;
        tourService.startDetachedTour('masterdataArticle_group', currentTour);

        self.metadata = articleGroupMetadata;
        self.metadataNew = articleGroupMetadataNew;
        self.context = articleGroupContext;

        var isFiscalRegulated = securityService.context.fiscalRegulation && securityService.context.fiscalRegulation.enableArticleGroupsRestrictions;

        // actions
        var updateContext = function (entity) {
          if (isFiscalRegulated) {
            var id = entity.id;
            if (self.context.data.possibleSuperGroupIds && (1 <= id) && (id <= 10)) {
              var indexOf = self.context.data.possibleSuperGroupIds.indexOf(id);
              self.context.data.possibleSuperGroupIds.splice(indexOf, 1);

              if (self.context.data.possibleSuperGroupIds.length == 0) {
                self.context.data.possibleSuperGroupIds = null;
              }
            }

            if (self.context.data.possibleSubGroupIds && (11 <= id) && (id <= 99)) {
              var indexOf = self.context.data.possibleSubGroupIds.indexOf(id);
              self.context.data.possibleSubGroupIds.splice(indexOf, 1);

              if (self.context.data.possibleSubGroupIds.length == 0) {
                self.context.data.possibleSubGroupIds = null;
              }
            }
          }
        };
        var resolve = {
          context: self.context,
          updateContext: updateContext
        };

        var actions = [
          {
            name: 'changeId',
            template: 'app/template/grid/change_id_action.html',
            controller: 'ChangeIdArticleGroupActionCtrl',
            resolve: resolve
          },
          {
            name: 'copy',
            template: 'app/template/grid/copy_article_group_action.html',
            controller: 'CopyArticleGroupActionCtrl',
            resolve: resolve
          }
        ];

        var cellClass = function (grid, row, col, rowRenderIndex, colRenderIndex) {
          if (row && row.entity && row.entity.id && row.entity.hidden) {
            return 'hidden-entity';
          } else if (row && row.entity && !row.entity.addRow && !row.entity.parent) {
            return 'article-super-group';
          }
        };

        var idValidations = {
          'gf-uniquearticlegroupplu': {
            'angular': {'gf-uniquearticlegroupplu': ''},
            'errorMessage': 'de.icash.validator.UniquePLU.ArticleGroup.message',
            'errorMessageArg': "plu"
          }
        };

        if (isFiscalRegulated) {
          idValidations['gf-fiscalitalyidparentrestriction'] = {
            'angular': {'gf-fiscalitalyidparentrestriction': ''},
            'errorMessage': 'italy.fiscal.articleGroup.parent.wrong.id'
          };
          idValidations['gf-fiscalitalyidsubrestriction'] = {
            'angular': {'gf-fiscalitalyidsubrestriction': ''},
            'errorMessage': 'italy.fiscal.articleGroup.sub.wrong.id'
          };
          idValidations['gf-fiscalitalyidlimitrestriction'] = {
            'angular': {'gf-fiscalitalyidlimitrestriction': ''},
            'errorMessage': 'italy.fiscal.articleGroup.id.limit'
          };
        }

        var parentValidations = {
          'gf-nonselfreferencingarticlegroup': {
            'angular': {'gf-nonselfreferencingarticlegroup': ''},
            'errorMessage': 'de.icash.validator.NonSelfReferencing.message.1'
          }
        };

        if (isFiscalRegulated) {
          parentValidations['gf-fiscalitalyparentgrouprestriction'] = {
            'angular': {'gf-fiscalitalyparentgrouprestriction': ''},
            'errorMessage': 'italy.fiscal.articleGroup.parent.wrong.id'
          };
          parentValidations['gf-fiscalitalysubgrouprestriction'] = {
            'angular': {'gf-fiscalitalysubgrouprestriction': ''},
            'errorMessage': 'italy.fiscal.articleGroup.sub.wrong.id'
          };
        }

        if (self.metadataNew.fields['parent'].required) {
          parentValidations['required'] = {
            'angular': {'required': ''},
            'errorMessage': 'org.hibernate.validator.constraints.NotEmpty.message'
          };
        }

        var cellEditableConditionMainFields = function ($scope) {
          return $scope.row.entity.addRow || !$scope.row.entity.hidden && !(!self.context.data.superGroupMainInfoEditable && !$scope.row.entity.parent);
        };

        // columns
        var columns = [
          {
            name: 'id', width: 70, cellClass: cellClass, preFillValue: function (colDef, entity) {
              var url = '/icash' + self.context.refreshEndpoint + '/plu';
              if (colDef.name !== 'id') {
                if (!isFiscalRegulated) {
                  return PluService.acquire(url);
                }

                // calculate possible id, even if not possible suggest to create other type
                var parentId = self.context.data.possibleSuperGroupIds ? self.context.data.possibleSuperGroupIds[0] : self.context.data.possibleSubGroupIds[0];
                var subId = self.context.data.possibleSubGroupIds ? self.context.data.possibleSubGroupIds[0] : self.context.data.possibleSuperGroupIds[0];

                var id = entity.parent ? subId : parentId;
                updateContext({id: id});
                return id;
              } else {
                PluService.lock(url, entity.id);
                return entity.id;
              }
            },
            cellEditableCondition: function ($scope) {

              return $scope.row.entity.id == null || $scope.row.entity.notValidColumns && _.find($scope.row.entity.notValidColumns, function (item) {
                return 'id' === item;
              });
            },
            validations: idValidations,
            headerCellTemplate: 'app/template/tour/header_cell_tour.html'
          },
          {
            name: 'name', cellClass: cellClass, preFillValue: function (colDef, entity) {
              if (colDef.name === 'shortName') {
                return entity.name;
              }
              return colDef.name === 'name' ? entity.name : entity.shortName;
            },
            cellEditableCondition: cellEditableConditionMainFields
          },
          {
            name: 'shortName', cellClass: cellClass, preFillValue: function (colDef, entity) {
              if (colDef.name === 'shortName') {
                return entity.shortName;
              }
              return colDef.name === 'name' ? entity.name : entity.shortName;
            },
            headerCellTemplate: 'app/template/tour/header_cell_tour.html',
            cellEditableCondition: cellEditableConditionMainFields
          },
          {
            name: 'parent', cellClass: cellClass, width: 150, filterPathFieldName: 'parent.shortName',
            afterCellEdit: function (colDef, entity) {
              if (isFiscalRegulated) {
                if (entity.parent) {
                  if (self.context.data.possibleSubGroupIds) {
                    entity.id = self.context.data.possibleSubGroupIds[0];
                  }
                } else if (self.context.data.possibleSuperGroupIds) {
                  entity.id = self.context.data.possibleSuperGroupIds[0];
                }
              }
            },
            validations: parentValidations,
            headerCellTemplate: 'app/template/tour/header_cell_tour.html',
            cellEditableCondition: cellEditableConditionMainFields
          },
          {
            name: 'bonPrinters',
            cellClass: cellClass,
            width: 150,
            enableFiltering: false,
            multiselect: true,
            headerCellTemplate: 'app/template/tour/header_cell_tour.html'
          }
        ];

        var isFiscalRegulationRuleForVatActive = (securityService.context.fiscalRegulation && securityService.context.fiscalRegulation.limitVatNumber);
        columns.push({
          name: 'internalVat',
          width: 120,
          cellClass: cellClass,
          displayName: isFiscalRegulationRuleForVatActive ? $translate.instant('vat') : '',
          headerCellTemplate: 'app/template/tour/header_cell_tour.html',
          cellEditableCondition: cellEditableConditionMainFields,
          showEmpty: !_.isUndefined(securityService.context.currentRestaurant.internalVat),
          showEmptyText: defaultVatText(securityService.context.currentRestaurant.internalVat)
        });

        if (self.metadataNew.fields['externalVat'].visible) {
          columns.push({
            name: 'externalVat',
            width: 120,
            cellClass: cellClass,
            cellEditableCondition: cellEditableConditionMainFields,
            showEmpty: !_.isUndefined(securityService.context.currentRestaurant.externalVat),
            showEmptyText: defaultVatText(securityService.context.currentRestaurant.externalVat)
          });
        }

        function defaultVatText(vat) {
          if (!vat) {
            return '';
          }
          return $translate.instant('option.default') + ' (' + vat.name + ')'
        }

        columns.push({name: 'fixedAt', visible: false, cellClass: cellClass});
        columns.push({name: 'ticketLayout', visible: false, cellClass: cellClass});
        columns.push({name: 'ticketVoidLayout', visible: false, cellClass: cellClass});

        default_icons.forEach(function (icon) {
          icon.name = $translate.instant('icon.' + icon.name);
        });
        default_icons = _.sortBy(default_icons, function (icon) {
          return icon.name;
        });
        columns.push({
          name: 'image',
          visible: false,
          editDropdownIdLabel: 'path',
          editDropdownValueLabel: 'name',
          cellFilter: 'mapImageArticleFilter:grid.appScope.context.data.dependencies.icons',
          editableCellTemplate: "<div><form name=\"inputForm\"><select class=\"form-control\" ng-class=\"'colt' + col.uid\" ui-grid-edit-dropdown ng-model=\"MODEL_COL_FIELD\" ng-options=\"field[editDropdownIdLabel] as field[editDropdownValueLabel]  CUSTOM_FILTERS for field in editDropdownOptionsArray\"><option value=\"\" translate=\"option.select\"></option></select></form></div>",
          editDropdownOptionsArray: default_icons,
          enableFiltering: false,
          enableSorting: false,
          cellClass: cellClass
        });

        // create hash map for mapImageArticleFilter
        self.context.data.dependencies.icons = {};
        default_icons.forEach(function (icon) {
          self.context.data.dependencies.icons[icon.path] = icon.name;
        });

        columns.push({name: "colorCode", visible: false, cellClass: cellClass});
        columns.push({name: "applyColorToArticles", visible: false, cellClass: cellClass});
        columns.push({name: "applyImageToArticles", visible: false, cellClass: cellClass});
        columns.push({name: "articleType", visible: false, cellClass: cellClass});
        columns.push({name: "discountable", visible: false, cellClass: cellClass});
        columns.push({
          name: "measurementUnit",
          editDropdownFilter: 'measurementUnitFilter',
          cellFilter: 'measurementUnitFilter',
          visible: false,
          cellClass: cellClass
        });
        columns.push({
          name: "operate",
          actions: ['edit', 'changeId', 'copy', 'delete'],
          cellClass: cellClass,
          actionOptions: {
            'delete': {
              isDisabled: function (entity) {
                return (!entity.parent && !self.context.data.superGroupMainInfoEditable) || configOptionsService.get('articleGroupDeleteButton').isDisabled()
              }
            },
            'changeId': {
              isDisabled: function (entity) {
                return (!entity.parent && !self.context.data.superGroupMainInfoEditable) || configOptionsService.get('articleGroupChangeIdButton').isDisabled()
              }
            },
            'copy': {
              isDisabled: function (entity) {
                return (!entity.parent && !self.context.data.superGroupMainInfoEditable)
              }
            }
          }
        });


        var maxDataNumber = securityService.context.fiscalRegulation && securityService.context.fiscalRegulation.enableArticleGroupsRestrictions ? 50 : undefined;
        self.config = {
          urlRoute: '/masterdata/article_group',
          registerActions: actions,
          enableSorting: true,
          enableAdd: true,
          enableFiltering: true,
          enablePagination: true,
          enableLastModifiedFilter: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          maxDataNumber: maxDataNumber,
          cellEditableCondition: function ($scope) {
            return $scope.row.entity.addRow || !$scope.row.entity.hidden;
          },
          hideActions: function (entity) {
            return entity.hidden;
          },
          columns: columns
        };

        self.deleteAllArticleGroups = function () {
          $http.put("/icash/masterdata/article_group/deleteAll").then(function (resp) {
            if (resp.data.status == 'OK') {

              if (resp.data.payload.not_deleted > 0) {
                notifyManager.success($translate.instant('messages.articleGroups.deleted.success.partial',
                  {"1": resp.data.payload.not_deleted}));
              } else {
                notifyManager.success($translate.instant('messages.articleGroups.deleted.success.all'));
              }

              self.config.reloadData();
            } else {
              notifyManager.error($translate.instant('message.articleGroups.deleted.error'));
            }
          });
        };

      }
    )
    .filter('measurementUnitFilter', function ($translate) {
      return function (input) {
        return input ? $translate.instant('measurementUnit.' + input) + ' (' + $translate.instant('measurementUnit.' + input + '.desc') + ')' : '';
      }
    })
    .controller('ChangeIdArticleGroupActionCtrl',
      function ($scope, ArticleGroupChangeId, $translate, notifyManager, $uibModal, securityService, uiGridConstants, updateContext, entity, gridApi) {
        var isFiscalRegulated = securityService.context.fiscalRegulation && securityService.context.fiscalRegulation.enableArticleGroupsRestrictions;
        if (!isFiscalRegulated) {
          $scope.min = '1';
          $scope.max = '';
        } else {
          if (entity.parent) {
            $scope.min = '11';
            $scope.max = '99';
          } else {
            $scope.min = '1';
            $scope.max = '10';
          }
        }

        $scope.articleGroupChangeId = new ArticleGroupChangeId({oldId: entity.id});

        $scope.updateContext = updateContext;

        var modalDialog = $uibModal.open({
          template: require('../template/page/masterdata/change_id.html'),
          windowClass: "gf-modal",
          controller: 'ArticleGroupChangeIdCtrl',
          scope: $scope
        });

        modalDialog.result.then(function (articleGroup) {
          if (articleGroup) {
            entity.id = articleGroup.id;
            gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
            notifyManager.success($translate.instant('changeId.changes'));
          }
        });
      }
    )
    .controller('CopyArticleGroupActionCtrl',
      function ($scope, $uibModal, $translate, ArticleChangeId, PluService, notifyManager, ArticleGroup, securityService, context, updateContext, entity,
                gridApi) {

        var isFiscalRegulated = securityService.context.fiscalRegulation && securityService.context.fiscalRegulation.enableArticleGroupsRestrictions;
        if (!isFiscalRegulated) {
          $scope.articleGroupEnterId = {id: PluService.acquire("/icash/masterdata/article_group/plu")};
          $scope.min = '1';
          $scope.max = '';
        } else {
          if (entity.parent) {
            if (context.data.possibleSubGroupIds) {
              $scope.articleGroupEnterId = {id: context.data.possibleSubGroupIds[0]};
              $scope.min = '11';
              $scope.max = '99';
            } else {
              notifyManager.error($translate.instant('italy.fiscal.articleGroup.parent.limit.reached'));
              return;
            }
          } else {
            if (context.data.possibleSuperGroupIds) {
              $scope.articleGroupEnterId = {id: context.data.possibleSuperGroupIds[0]};
              $scope.min = '1';
              $scope.max = '10';
            } else {
              notifyManager.error($translate.instant('italy.fiscal.articleGroup.sub.limit.reached'));
              return
            }
          }
        }

        $scope.gridApi = gridApi;
        $scope.entity = entity;

        var modalDialog = $uibModal.open({
          template: require('../template/page/masterdata/enter_id.html'),
          windowClass: 'gf-modal',
          controller: 'ArticleGroupEnterIdCtrl',
          scope: $scope
        });

        modalDialog.result.then(function () {
          var newEntity = angular.copy(entity);
          newEntity.id = $scope.articleGroupEnterId.id;

          var articleResource = new ArticleGroup(newEntity);


          articleResource.$edit({articleGroupId: newEntity.id}, function (data) {
            var index = _.findIndex($scope.gridApi.grid.options.data, function (item) {
              return item.id == $scope.entity.id;
            });
            // remove add row
            $scope.gridApi.grid.options.dataItemsNumber++;
            if ($scope.gridApi.grid.options.dataItemsNumber == $scope.gridApi.grid.options.maxDataNumber) {
              $scope.gridApi.grid.options.data.pop();
            }

            // insert new article to the next position
            $scope.gridApi.grid.options.data.splice(index + 1, 0, data.payload.entity);

            notifyManager.success($translate.instant('entity.save.successfully'));
            updateContext(data.payload.entity);
          });
        });
      }
    )

    .directive('gfUniqueId', function (ArticleGroupUniqueId, $rootScope) {
      var toId;
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, element, attrs, ctrl) {
          $scope.$watch(attrs.ngModel, function (value) {

            // if there was a previous attempt, stop it.
            if (toId) clearTimeout(toId);

            if (!angular.isNumber(value)) {
              ctrl.$setValidity('uniqueId', true);
              return;
            }

            // start a new attempt with a delay to keep it from
            // getting too "chatty".
            toId = setTimeout(function () {

              ArticleGroupUniqueId.get({id: value}, function (data) {

                //set the validity of the field
                $rootScope.safeApply(function () {
                  if (data.ok) {
                    ctrl.$setValidity('uniqueId', data.payload.unique);
                  }
                });
              });
            }, 200);
          })
        }
      }
    })


})();
