(function() {
  'use strict';

  angular.module('gf.cropper', [])
    .directive('cropper', function () {
      return {
        restrict: 'E',
        scope: {
          imageIn: '=',
          cropped: '&'
        },
        template: '<img style="max-width: 100%;" />',
        link: function (scope, element, attrs) {
          $(element).cropper({
            minContainerHeight: 400
          });

          scope.$watch('imageIn', function (currentValue) {

            if (!currentValue) {
              return;
            }
            // to get cropped picture url
            var URL = window.URL || window.webkitURL;

            var blobURL;
            blobURL = URL.createObjectURL(currentValue);
            $(element).one('built.cropper', function () {

              // Revoke when load complete
              URL.revokeObjectURL(blobURL);
            }).cropper('reset').cropper('replace', blobURL);
          });

          $(element).on({
            'cropend.cropper': raiseCropped,
            'built.cropper': raiseCropped
          });

          // raised when crop area changes
          function raiseCropped() {
            $(element).cropper('getCroppedCanvas').toBlob(function (blob) {
              scope.$apply(function () {
                scope.cropped()(blob);
              });
            });
          }
        }
      };
    });
})();
