(function () {
  'use strict';
  angular.module('gf.bm.account', ['gf.bm.groupView'])

    .factory('BMAccountMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/account/metadata.json')
    })
    .factory('BMAccountContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/account/context.json')
    })
    .factory('BMAccountRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.financialAccount', {
          url: '/financial/account',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMFinancialAccount',
          controllerAs: '$ctrl',
          resolve: {
            bmAccountMetadata: function (BMAccountMetadata, $stateParams, securityService) {
              return BMAccountMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmAccountContext: function (BMAccountContext, $stateParams, securityService) {
              return BMAccountContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmAccountRules: function (BMAccountRules, $stateParams, securityService) {
              return BMAccountRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "financialAccount"
        })
    })

    .controller('BMFinancialAccount',
      function (bmAccountMetadata, bmAccountContext, securityService, $stateParams, bmAccountRules) {
        var self = this;

        self.metadata = bmAccountMetadata;
        self.context = bmAccountContext;
        self.masterdataRules = bmAccountRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false,width: 70},
            {name: 'name', enableCellEdit: false},
            {name: 'account', enableCellEdit: false},
            {name: 'chart', enableCellEdit: false},
            {name: 'code', enableCellEdit: false},
            {name: 'type', enableCellEdit: false, cellFilter: "gfProperty:'name' | prefixFilter:'AccountType.' | translate"},
            {name: 'value', enableCellEdit: false},
            {name: 'sort', enableCellEdit: false}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/account';
          }
        };

      }
    )

})();
