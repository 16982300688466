(function () {
  'use strict';
  angular.module('gf.setup.rksv', ['ui.router', 'ngclipboard', 'gf.security'])

    .factory('RksvCertificateMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/rksv/certificate/metadata.json');
    })
    .factory('RksvKeysMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/rksv/keys/metadata.json');
    })
    .factory('RksvGlobalTrustMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/rksv/global_trust/metadata.json');
    })
    .factory('RksvCertificate', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/rksv/certificate', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('RksvKeys', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/rksv/keys', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('RksvGlobalTrust', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/rksv/global_trust', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })
    .factory('Profile', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/user/profile/user');
    })
    .factory('RksvDep', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/rksv/dep/list');
    })

    .config(function ($stateProvider, securityServiceProvider) {

      securityServiceProvider
        .securePath('/setup/rksv', function (security) {
          return security.hasAnyFeature('RKSV');
        });

      $stateProvider
        .state('page.rksv', {
          url: '',
          abstract: true,
          views: {
            '': {
              template: require('../template/setup/rksv/rksv.html'),
              controller: 'RksvCtrl',
              controllerAs: 'rksvCtrl'
            },
            'certificate@page.rksv': {
              template: require('../template/setup/rksv/rksv_certificate.html'),
              controller: 'RksvCertificateCtrl',
              controllerAs: 'rksvCertificateCtrl',
              resolve: {
                rksvCertificateMetadata: function (RksvCertificateMetadata) {
                  return RksvCertificateMetadata.get().$promise;
                },
                rksvCertificate: function (RksvCertificate) {
                  return RksvCertificate.load().$promise;
                }
              },
              title: "rksvCertificate"
            },
            'keys@page.rksv': {
              template: require('../template/setup/rksv/rksv_keys.html'),
              controller: 'RksvKeysCtrl',
              controllerAs: 'rksvKeysCtrl',
              resolve: {
                rksvKeysMetadata: function (RksvKeysMetadata) {
                  return RksvKeysMetadata.get().$promise;
                },
                rksvKeys: function (RksvKeys) {
                  return RksvKeys.get().$promise;
                }
              },
              title: "rksvKeys"
            },
            'globalTrust@page.rksv': {
              template: require('../template/setup/rksv/rksv_global_trust.html'),
              controller: 'RksvGlobalTrustCtrl',
              controllerAs: 'rksvGlobalTrustCtrl',
              resolve: {
                rksvGlobalTrustMetadata: function (RksvGlobalTrustMetadata) {
                  return RksvGlobalTrustMetadata.get().$promise;
                },
                rksvGlobalTrust: function (RksvGlobalTrust) {
                  return RksvGlobalTrust.get().$promise;
                }
              },
              title: 'rksvGlobalTrust'
            },
            'dep@page.rksv': {
              template: require('../template/setup/rksv/rksv_dep.html'),
              controller: 'RksvDepCtrl',
              controllerAs: 'rksvDepCtrl',
              resolve: {
                profile: function (Profile) {
                  return Profile.get().$promise;
                },
                rksvList: function (RksvDep) {
                  return RksvDep.query().$promise;
                }
              },
              title: 'rksvDep'
            }
          }
        })

        .state('page.rksv.setupRksvCertificate', {
          url: '/setup/rksv/certificate',
          title: 'feature.RKSV'
        })

        .state('page.rksv.setupRksvKeys', {
          url: '/setup/rksv/keys'
        })

        .state('page.rksv.setupRksvGlobalTrust', {
          url: '/setup/rksv/global_trust'
        })

        .state('page.rksv.setupRksvDep', {
          url: '/setup/rksv/dep'
        })
    })

    .controller('RksvCtrl',
      function ($scope, $state) {
        var self = this;
        self.state = $state;
        var rksvStates = {
          0: 'page.rksv.setupRksvCertificate',
          1: 'page.rksv.setupRksvKeys',
          2: 'page.rksv.setupRksvGlobalTrust',
          3: 'page.rksv.setupRksvDep'
        };

        for (var index in rksvStates) {
          if (rksvStates[index] === $state.current.name) {
            self.activeTab = parseInt(index);
          }
        }
      }
    )

    .controller('RksvCertificateCtrl',
      function ($scope, $state, rksvCertificateMetadata, RksvCertificate, rksvCertificate, $translate, notifyManager) {
        var self = this;
        self.metadata = rksvCertificateMetadata;
        self.rksvCertificate = rksvCertificate;
        self.save = function () {
          if (self.certificateTabForm.$valid) {
            self.copiedCertificateData = angular.copy(self.rksvCertificate);
            self.rksvCertificate.$edit(function (data) {
              if (data.ok) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                self.rksvCertificate = new RksvCertificate(data.payload.form);
              } else {
                notifyManager.error($translate.instant('rksv.certificate.failedToGetCertificate'));
                self.rksvCertificate = self.copiedCertificateData;
              }
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        }
      }
    )

    .controller('RksvKeysCtrl',
      function ($scope, $state, rksvKeysMetadata, RksvKeys, rksvKeys, $translate, notifyManager) {
        var self = this;
        self.metadata = rksvKeysMetadata;
        self.rksvKeys = rksvKeys;
        self.save = function () {
          if (self.keysTabForm.$valid) {
            self.rksvKeys.$edit(function (data) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              self.rksvKeys = new RksvKeys(data.payload.form);
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        }
      }
    )

    .controller('RksvGlobalTrustCtrl',
      function ($scope, $state, rksvGlobalTrustMetadata, RksvGlobalTrust, rksvGlobalTrust, $translate, notifyManager, $http) {
        var self = this;
        self.metadata = rksvGlobalTrustMetadata;
        self.rksvGlobalTrust = rksvGlobalTrust;
        self.initialResourseValue = angular.copy(self.rksvGlobalTrust);
        self.isUserExist = rksvGlobalTrust.username;
        self.rksvGlobalTrust.username = 'Admin';
        // add required if create a user
        if (!self.isUserExist) {
          self.metadata.columns.password.angular = {'required': ''};
          self.metadata.columns.password.validation = ['required'];
          self.metadata.columns.password.errorMessages =
            [
              {
                'keys': ['required'],
                'message': 'org.hibernate.validator.constraints.NotEmpty.message',
                'name': 'Required'
              }
            ];
        }
        self.getCertificate = function () {
          if (self.globalTrustTabForm.$valid) {
            self.rksvGlobalTrust.$edit(function (data) {
              self.rksvGlobalTrust = new RksvGlobalTrust(data.payload.form);
              self.isUserExist = true;
              self.initialResourseValue = angular.copy(self.rksvGlobalTrust);
              self.globalTrustTabForm.password.$formatters.length = 0;
              $http.get('/icash/restaurant_data/rksv/global_trust/ask_certificate').then(function (response) {
                if (response.data.ok) {
                  notifyManager.success($translate.instant('rksv.message.certificateOrdered'));
                } else {
                  notifyManager.error($translate.instant('rksv.message.failedToOrderCertificate'));
                }
              });
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        }
      }
    )

    .controller('RksvDepCtrl',
      function ($scope, $state, $http, $stateParams, $location, $filter, $translate, notifyManager, profile, rksvList) {
        var self = this;
        self.profile = profile;
        self.rksvList = rksvList;

        self.prepareDownload = function (item) {
          var reportUrl = '/icash/restaurant_data/rksv/dep/export?v=' + Math.random();
          self.startDownload = item.shutdownId ? reportUrl + '&initialId=' + item.initialId + '&shutdownId=' + item.shutdownId
            : reportUrl + '&initialId=' + item.initialId;
        };

        self.mailReport = function (item) {
          var reportUrl = '/icash/restaurant_data/rksv/dep/mail';
          var downloadData = {
            initialDate: item.initialDate,
            shutdownDate: item.shutdownDate,
            initialId: item.initialId,
            shutdownId: item.shutdownId
          };
          $http.post(reportUrl, downloadData).then(function (response) {
            if (response.data.ok) {
              notifyManager.success($translate.instant('rksv.dep.reportSent'));
            } else {
              notifyManager.error($translate.instant('rksv.dep.failToSendReport'));
            }
          })
        }
      }
    )

})();
