(function () {
  'use strict';
  angular.module('gf.bm.priceLevel', ['gf.bm.groupView'])

    .factory('BMPriceLevelMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/price_level/metadata.json')
    })
    .factory('BMPriceLevelContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/price_level/context.json')
    })
    .factory('BMPriceLevelRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.financialPrice_level', {
          url: '/financial/price_level',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMFinancialPriceLevel',
          controllerAs: '$ctrl',
          resolve: {
            bmPriceLevelMetadata: function (BMPriceLevelMetadata, $stateParams, securityService) {
              return BMPriceLevelMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmPriceLevelContext: function (BMPriceLevelContext, $stateParams, securityService) {
              return BMPriceLevelContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmPriceLevelRules: function (BMPriceLevelRules, $stateParams, securityService) {
              return BMPriceLevelRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "masterdataArticle"
        })
    })

    .controller('BMFinancialPriceLevel',
      function (bmPriceLevelMetadata, bmPriceLevelContext, securityService, $stateParams, bmPriceLevelRules) {
        var self = this;

        self.metadata = bmPriceLevelMetadata;
        self.context = bmPriceLevelContext;
        self.masterdataRules = bmPriceLevelRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name', enableCellEdit: false},
            {name: 'monday', enableCellEdit: false},
            {name: 'tuesday', enableCellEdit: false},
            {name: 'wednesday', enableCellEdit: false},
            {name: 'thursday', enableCellEdit: false},
            {name: 'friday', enableCellEdit: false},
            {name: 'saturday', enableCellEdit: false},
            {name: 'sunday', enableCellEdit: false},
            {name: 'timeStart', enableCellEdit: false, cellFilter: "timePriceLevelFilter:row.entity"},
            {name: 'timeEnd', enableCellEdit: false, cellFilter: "timePriceLevelFilter:row.entity"}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/price_level';
          }
        };

      }
    )

})();
