(function () {
  'use strict';
  angular.module('gf.bm.groupManagement', [])

    .factory('BranchGroupMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/branch_group/metadata.json')
    })
    .factory('BranchGroupMetadataNew', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/branch_group/metadata_new.json')
    })
    .factory('BranchGroupContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/branch_group/context.json')
    })
    .factory('BranchGroup', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/branch_group/:branchGroupId');
    })

    .config(function ($stateProvider, securityServiceProvider) {

      //securityServiceProvider
      // .securePath('/branch_management', function (security) {
      //   return security.hasAnyFeature('BRANCH_MANAGEMENT')
      //     && security.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER', 'BM_MANAGE_BRANCH_GROUPS');
      // });

      $stateProvider
        .state('page.branchManagement', {
          url: '/branch_management/branch',
          template: require('../template/bm/group-management/bm_grid.html'),
          controller: 'BMGridCtrl',
          controllerAs: '$ctrl',
          resolve: {
            branchGroupMetadata: function (BranchGroupMetadata) {
              return BranchGroupMetadata.get().$promise;
            },
            branchGroupContext: function (BranchGroupContext) {
              return BranchGroupContext.get().$promise;
            },
            branchGroup: function (BranchGroup) {
              return BranchGroup.get().$promise;
            }
          },
          title: 'branchManagement'
        })
    })

    .controller('BMModalAddCtrl',
      function ($scope, branchGroupMetadataNew, $uibModalInstance) {
        var self = this;

        self.metadataNew = branchGroupMetadataNew;
        self.branchGroup = {};

        self.close = function () {
          $uibModalInstance.dismiss();
        };

        self.save = function () {
          if (self.branchGroupForm.$valid) {
            self.branchGroup.name = self.branchGroupName;
            $uibModalInstance.close(self.branchGroup);
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
      }
    )

    .controller('BMModalEditCtrl',
      function ($scope, branchGroupMetadataNew, $uibModalInstance, notifyManager, $translate, $http, branchGroup) {
        var self = this;

        self.metadataNew = branchGroupMetadataNew;
        self.branchGroup = branchGroup;
        self.branchGroupName = _.clone(branchGroup.name, self.branchGroupName);

        self.close = function () {
          $uibModalInstance.dismiss();
        };

        self.save = function () {
          if (self.branchGroupForm.$valid) {
            self.branchGroup.name = self.branchGroupName;
            $uibModalInstance.close(self.branchGroup);
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
      }
    )

    .controller('BMGridCtrl', function ($scope, $state, branchGroupMetadata, branchGroupContext,
                                        $http, branchGroup, $uibModal, gridTreeHelper, notifyManager, $translate, uiGridTreeBaseService) {
      var self = this;

      self.branchGroup = branchGroup.root;
      self.folderData = [branchGroup.root];
      self.expandedNodes = [self.folderData[0]];

      //filter for showing groups / restaurants / all entities
      self.visibilityFilter = {
        shown: 'all', //all value for showing all entities
        setFilter: function (value) {
          this.shown = value;
          self.gridApi.grid.refresh();
        },
        $filter: function (renderableRows) {
          if (self.visibilityFilter.shown !== 'all') {
            if (self.visibilityFilter.shown === 'groups') {
              renderableRows.forEach(function (row) {
                if (row.entity.leaf) {
                  row.visible = false;
                }
              });
            } else if (self.visibilityFilter.shown === 'restaurants') {
              renderableRows.forEach(function (row) {
                if (!row.entity.leaf) {

                  row.visible = false;
                }
              });
            }
          }
          return renderableRows;
        }
      };

      function updateGrid() {
        var selectedGroupId = self.branchGroup.id;
        self.gridConfig.data = _.remove(gridTreeHelper.flattenData(self.branchGroup), function (group) {
          return group.id !== selectedGroupId;
        });
        self.gridConfig.data = setAddressField(self.gridConfig.data);
      }

      function setAddressField(gridData) {
        gridData.forEach(function (item) {
          if (item.leaf && !_.isEmpty(item.leaf)) {

            item.leaf.displayedAddress = '';

            var zip = _.trim(item.leaf.address.zip);
            var city = _.trim(item.leaf.address.city);
            var street = _.trim(item.leaf.address.street);

            item.leaf.displayedAddress += zip;

            if (item.leaf.displayedAddress && city) {
              item.leaf.displayedAddress += ' ';
            }

            item.leaf.displayedAddress += city;

            if (item.leaf.displayedAddress && street) {
              item.leaf.displayedAddress += city ? ', ' : ' ';
            }

            item.leaf.displayedAddress += street;
          }
        });
        return gridData;
      }

      self.selectNode = function (node) {

        self.branchGroup = node;
        updateGrid();
        self.expandedNodes = self.expandedNodes.concat(node);
      };

      self.treeOptions = {
        nodeChildren: "children",
        allowDeselect: false,
        isLeaf: function isRestaurant(node) {
          return 'leaf' in node;
        },
        injectClasses: {
          label: "bm-label"
        }
      };

      self.gridConfig = {
        enableSorting: true,
        enableFiltering: true,
        enableSelectAll: false,
        virtualizationThreshold: 1000,
        multiSelect: true,
        selectionRowHeaderWidth: 37,
        showTreeRowHeader: false,
        columnDefs: [
          {name: 'Id', field: 'id', visible: false},
          {
            name: 'Name',
            field: 'name',
            enableCellEdit: true,
            cellTemplate: '<div ng-style="{\'padding-left\': grid.options.style.nameStyle(row.entity)}" class="ui-grid-cell-contents">' +
            '<div ng-click="grid.options.actions.treeButtonClick(row, $event)" class="bm-grid-triangle">' +
            '<i ng-class="{\'fa fa-caret-down\': ( ( grid.options.showTreeExpandNoChildren &amp;&amp; row.treeLevel > -1 ) ' +
            '|| ( row.treeNode.children &amp;&amp; row.treeNode.children.length > 0 ) ) &amp;&amp; row.treeNode.state === \'expanded\', \'fa fa-caret-up\': ' +
            '( ( grid.options.showTreeExpandNoChildren &amp;&amp; row.treeLevel > -1 ) || ( row.treeNode.children &amp;&amp; row.treeNode.children.length > 0 ) ) ' +
            '&amp;&amp; row.treeNode.state === \'collapsed\'}"></i></div>{{COL_FIELD}}</div>'
          },
          {name: 'Address', field: 'leaf.displayedAddress', sort: {direction: 'desc', priority: 0}},
          // {name: 'Ext.ID', field: 'extId'},
          // {name: 'Category', field: 'category'},
          {
            name: 'actions',
            cellTemplate: '<div class="action-cell"><div class="btn btn-default btn-ungroup bm-button" ng-click="grid.options.actions.ungroup(row.entity)"><span translate="bm.ungroup">Ungroup</span></div>' +
            '<button type="button" class="action-button btn btn-transparent btn-xs bm-button" ng-click="grid.options.actions.editGroup(row.entity)" uib-tooltip="Edit"><i class="fa fa-pencil"></i></button></div>'
          }
        ],
        onRegisterApi: function (gridApi) {
          self.gridApi = gridApi;
          gridApi.draggableRows.on.rowDragged($scope, function (info) {
            var selectedRows = gridApi.selection.getSelectedRows();
            if (selectedRows.length > 0) {
              info.draggedRowEntity = selectedRows;
            } else {
              info.draggedRowEntity = [info.draggedRowEntity];
            }
          });

          gridApi.draggableRows.on.rowDropped($scope, function (info) {
            if (checkIfChildTarget(info.draggedRowEntity, info.targetRowEntity)) {
              updateGrid();
              return false;
            }
            dropRow(info.draggedRowEntity, info.targetRowEntity).then(function () {
              gridApi.core.refresh();
              gridApi.selection.clearSelectedRows();
            });
          });
          gridApi.grid.registerRowsProcessor(self.visibilityFilter.$filter, 200);
        },
        useUiGridDraggableRowsHandle: true,
        rowTemplate: '<div grid="grid" class="ui-grid-draggable-row" draggable="true"><div ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'restaurant-header-cell\': row.entity.leaf }"  ui-grid-cell></div></div>',
        actions: {
          editGroup: function (entity) {
            editGroup(entity);
          },
          ungroup: function (entity) {
            ungroupGroup(entity);
          },
          treeButtonClick: function (row, evt) {
            uiGridTreeBaseService.toggleRowTreeState(self.gridApi.grid, row, evt);
          }
        },
        style: {
          nameStyle: function (entity) {
            if (entity.$$treeLevel > 0) {
              return entity.$$treeLevel * 10 + 10 + 'px';
            }
          }

        }
      };

      updateGrid();

      function checkIfChildTarget(draggedRows, targetRow) {
        return _.some(draggedRows, function (row) {
          if (targetRow.parent && targetRow.parent.id === row.id) {
            return true;
          }
          return targetRow.parent && checkIfChildTarget(draggedRows, targetRow.parent);
        })
      }

      self.addNewGroup = function () {
        $uibModal.open({
          template: require('../template/bm/group-management/bm_modal.html'),
          windowClass: "gf-modal",
          controller: 'BMModalAddCtrl',
          controllerAs: '$ctrl',
          resolve: {
            branchGroupMetadataNew: function (BranchGroupMetadataNew) {
              return BranchGroupMetadataNew.get().$promise;
            }
          }
        }).result.then(function (branchGroup) {
          //todo why don't we use BranchGroup $resource here?
          $http.post('/icash/branch_group/' + self.branchGroup.id + '/add', branchGroup).then(function (response) {
            if (response.data.ok) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              self.branchGroup.children.push(response.data.payload.entity);
              updateGrid();
            } else {
              notifyManager.error($translate.instant('entity.save.failed'));
            }
          });
        });
      };

      function editGroup(entity) {
        $uibModal.open({
          template: require('../template/bm/group-management/bm_modal.html'),
          windowClass: "gf-modal",
          controller: 'BMModalEditCtrl',
          controllerAs: '$ctrl',
          resolve: {
            branchGroupMetadataNew: function (BranchGroupMetadataNew) {
              return BranchGroupMetadataNew.get().$promise;
            },
            branchGroup: function () {
              return entity;
            }
          }
        }).result.then(function (branchGroup) {
          //todo why don't we use BranchGroup $resource here?
          $http.put('/icash/branch_group/' + branchGroup.id + '/edit', {'branchGroupName': branchGroup.name}).then(function (response) {
            if (response.data.ok) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              updateGrid();
            } else {
              notifyManager.error($translate.instant('entity.save.failed'));
            }
          });

        });
      }

      function ungroupGroup(entity) {
        $uibModal.open({
          template: require('../template/bm/group-management/delete_confirmation.html'),
          windowClass: 'gf-modal',
          controller: function ($uibModalInstance) {
            var self = this;
            self.ungroup = function () {
              $http.delete('/icash/branch_group/' + entity.id + '/' + entity.parent.id).then(function () {
                var index = entity.parent.children.indexOf(entity);
                entity.parent.children.splice(index, 1);
                entity.parent.children = entity.parent.children.concat(entity.children);
                updateGrid();
                $uibModalInstance.close();
              })
            }
          },
          controllerAs: '$ctrl'
        })
      }

      function dropRow(draggedRowEntities, targetRowEntity) {
        var groupIds = _.map(draggedRowEntities, 'id');
        //if target group is a restaurant - set parentGroupId of the restaurant's parent
        if (targetRowEntity.leaf) {
          targetRowEntity = targetRowEntity.parent;
        }

        return $http.put('/icash/branch_group/move_to/' + targetRowEntity.id, groupIds).then(function () {
          draggedRowEntities.forEach(function (entity) {
            var index = entity.parent.children.indexOf(entity);
            entity.parent.children.splice(index, 1);
            targetRowEntity.children.push(entity);
          });
          updateGrid();
        })
      }

      function filterGroups(group) {
        if (group.children.length > 0) {
          group.children = _.filter(group.children, function (g) {
            return !g.leaf;
          });
          group.children.forEach(function (item) {
            filterGroups(item);
          })
        }
        return group;
      }

      function filterRestaurants(group) {
        group.children = _.filter(group.children, 'leaf');
        return group;
      }
    })

    .factory('gridTreeHelper', function () {

      var flatArray;

      function addTreeLevel(data, level) {
        data.forEach(function (item) {
          item.$$treeLevel = level;
        });
      }

      function flat(gridData, level) {
        flatArray.push(gridData);
        addTreeLevel(gridData.children, level || 0);
        if (gridData.children.length > 0) {
          gridData.children.forEach(function (item) {
            item.parent = gridData;
            flat(item, (level || 0) + 1);
          });
        }
        return flatArray;
      }

      function flattenData(gridData, level) {
        flatArray = [];
        return flat(gridData, level);
      }

      return {
        flattenData: flattenData
      }
    })

})();
