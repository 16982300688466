import React from 'react';
import {useTranslation} from 'react-i18next';

function Currency({value, currency}) {
  const {i18n} = useTranslation();

  const formatCurrency = (currency, value) => {
    const currencyFormatter = new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: currency.toUpperCase(),
    });
    return currencyFormatter.format(Number(value) / 100)
  }

  return formatCurrency(currency, value)
}

export default Currency
