var CustomerAddCtrlResolve = {
  customerContext: ['CustomerContext', function (CustomerContext) {
    return CustomerContext.get().$promise;
  }],
  customerMetadata: ['CustomerMetadata', function (CustomerMetadata) {
    return CustomerMetadata.get().$promise;
  }],
  customer: ['Customer', '$q', '$stateParams', function (Customer, $q, $stateParams) {
    var deferred = $q.defer();
    Customer.load({customerId: $stateParams.id || 'add'}, function (customer) {
      var birthday = new Date(customer.birthday);
      customer.month = birthday.getMonth();
      customer.year = birthday.getFullYear();
      customer.day = birthday.getDate();
      deferred.resolve(customer);
    });
    return deferred.promise;
  }]
};

var CustomerGroupAddCtrlResolve = {
  customerGroupContext: ['CustomerGroupContext', function (CustomerGroupContext) {
    return CustomerGroupContext.get().$promise;
  }],
  customerGroupMetadata: ['CustomerGroupMetadata', function (CustomerGroupMetadata) {
    return CustomerGroupMetadata.get().$promise;
  }],
  customerGroup: ['CustomerGroup', '$stateParams', function (CustomerGroup, $stateParams) {
    return CustomerGroup.load({customerGroupId: $stateParams.id || 'add'}).$promise;
  }]
};

var CustomerDiscountAddCtrlResolve = {
  customerDiscountContext: ['CustomerDiscountContext', function (CustomerDiscountContext) {
    return CustomerDiscountContext.get().$promise;
  }],
  customerDiscountMetadata: ['CustomerDiscountMetadata', function (CustomerDiscountMetadata) {
    return CustomerDiscountMetadata.get().$promise;
  }],
  customerDiscount: ['CustomerDiscount', '$stateParams', function (CustomerDiscount, $stateParams) {
    return CustomerDiscount.load({customerDiscountId: $stateParams.id || 'add'}).$promise;
  }]
};

var YoviteCtrlResolve = {
  yoviteMetadata: ['YoviteMetadata', function (YoviteMetadata) {
    return YoviteMetadata.get().$promise;
  }],
  yoviteContext: ['YoviteContext', function (YoviteContext) {
    return YoviteContext.get().$promise;
  }],
  yovite: ['Yovite', function (Yovite) {
    return Yovite.get().$promise;
  }]
};

(function () {
  'use strict';
  angular.module('gf.customer', [])

    .config(function($stateProvider) {

      $stateProvider
        .state('page.customerCustomer', {
          url: '/customer/customer',
          template: require('./../../template/page/customer/customers.html'),
          controller: 'CustomerGridCtrl',
          resolve: CustomerAddCtrlResolve,
          title: "customer"
        })

        .state('page.customerCustomerAdd', {
          url: '/customer/customer/add',
          template: require('./../../template/page/customer/customer.html'),
          controller: 'CustomerAddCtrl',
          resolve: CustomerAddCtrlResolve,
          title: "customer"
        })

        .state('page.customerCustomerDetails', {
          url: '/customer/customer/:id',
          template: require('./../../template/page/customer/customer.html'),
          controller: 'CustomerEditCtrl',
          resolve: CustomerAddCtrlResolve,
          title: 'customer'
        })

        .state('page.customerCustomer_group', {
          url: '/customer/customer_group',
          template: require('./../../../../../main/angular/app/template/page/gf-grid.html'),
          controller: 'CustomerGroupGridCtrl',
          resolve: CustomerGroupAddCtrlResolve,
          title: 'customerGroup'
        })

        .state('page.customerCustomer_groupAdd', {
          url: '/customer/customer_group/add',
          template: require('./../../template/page/customer/customer_group.html'),
          controller: 'CustomerGroupAddCtrl',
          resolve: CustomerGroupAddCtrlResolve,
          title: 'customerGroup'
        })

        .state('page.customerCustomer_groupDetails', {
          url: '/customer/customer_group/:id',
          template: require('./../../template/page/customer/customer_group.html'),
          controller: 'CustomerGroupEditCtrl',
          resolve: CustomerGroupAddCtrlResolve,
          title: 'customerGroup'
        })

        .state('page.customerDiscount', {
          url: '/customer/discount',
          template: require('./../../../../../main/angular/app/template/page/gf-grid.html'),
          controller: 'CustomerDiscountGridCtrl',
          resolve: CustomerDiscountAddCtrlResolve,
          title: 'customerDiscount'
        })

        .state('page.customerDiscountAdd', {
          url: '/customer/discount/add',
          template: require('./../../template/page/customer/customer_discount.html'),
          controller: 'CustomerDiscountAddCtrl',
          resolve: CustomerDiscountAddCtrlResolve,
          title: 'customerDiscount'
        })

        .state('page.customerDiscountDetails', {
          url: '/customer/discount/:id',
          template: require('./../../template/page/customer/customer_discount.html'),
          controller: 'CustomerDiscountEditCtrl',
          resolve: CustomerDiscountAddCtrlResolve,
          title: 'customerDiscount'
        })

        .state('page.customerYovite', {
          url: '/customer/yovite',
          template: require('./../../template/page/customer/yovite.html'),
          controller: 'YoviteCtrl',
          resolve: YoviteCtrlResolve,
          title: 'yoviteAccount'
        })

    })

    .controller('GenerateCardCtrl',

      function ($scope, $uibModalInstance, CustomerCard) {
        $scope.card = new CustomerCard({credit: 0});
        $scope.ok = function () {
          $uibModalInstance.close();
        };

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.change = function () {
          $uibModalInstance.close($scope.card);
        }
      }
    )
    .factory('CustomerAdd', [
      function () {
        return function ($scope, $location, Upload, $translate, $state, notifyManager, customer, customerContext, customerMetadata, CustomerPhotoSendFile) {
          $scope.customerContext = customerContext;
          $scope.customerMetadata = customerMetadata;
          $scope.customer = customer;

          // initially convert to string, it is because options don't work with number
          $scope.customer.month = $scope.customer.month.toString();

          angular.extend($scope, customerContext.data.dependencies);

          $scope.addPhone = function () {
            $scope.customer.phones.push({
              type: $scope.phoneType[0]
            });
          };

          $scope.addEmail = function () {
            $scope.customer.emails.push({
              type: $scope.emailType[0]
            });
          };

          $scope.addUrl = function () {
            $scope.customer.urls.push({
              type: $scope.urlType[0]
            });

          };

          $scope.addSocial = function () {
            $scope.customer.socials.push({
              type: $scope.socialType[0]
            });
          };

          $scope.addAddress = function () {
            var address = $.extend({addressType: $scope.addressType[0]}, $scope.customerContext.data.customerAddressTemplate);
            $scope.customer.addresses.push(address);
          };

          $scope.addPhone();
          $scope.addEmail();
          $scope.addUrl();
          $scope.addSocial();

          if (!$scope.customer.addresses[0]) {
            $scope.addAddress();
            $scope.customer.addresses[0].byDefault = true;
            $scope.customer.addresses[0].invoice = true;
          }

          $scope.photoFile;
          $scope.croppedPhotoFile;
          $scope.cropped = false;
          $scope.needCrop = false;

          $scope.onCropped = function (blob) {

            // Cropped file must have .png extension
            var fileName = $scope.photoFile.name;
            blob.name = fileName.substr(0, fileName.lastIndexOf(".")) + ".png";
            blob.lastModifiedDate = new Date();
            $scope.croppedPhotoFile = blob;
          };

          $scope.finishCrop = function () {
            if (!$scope.croppedPhotoFile) {
              return;
            }

            $scope.onFileSelect([$scope.croppedPhotoFile]);
            $scope.cropped = true;
          };

          $scope.onFileSelect = function ($files) {
            if (!$files || !$files[0]) {
              return;
            }

            var img = new Image();

            $scope.photoFile = $files[0];
            $scope.showPhoto = true;

            var reader = new FileReader();

            reader.onload = function () {
              img.src = reader.result;
              var photoContainer = angular.element(document).find('#photoImg');
              photoContainer.empty();
              photoContainer.append(img);
            };

            reader.readAsDataURL($scope.photoFile);
            $scope.needCrop = true;
            $scope.cropped = false;
          };

          $scope.onFileDelete = function () {
            $scope.showPhoto = false;
            $scope.needCrop = false;
            $scope.cropped = false;
            $scope.photoFile = '';
            angular.element(document).find('#photoImg').empty();

          };

          // wtf???
          $scope.goReport = function () {
            location.href = '/icash/report/13/?customer=' + $scope.customer.number;
          };

          $scope.save = function () {
            if ($scope.customerForm.$valid) {
              if ($scope.customer.year && $scope.customer.month && $scope.customer.day) {
                var birthday = new Date();
                birthday.setFullYear($scope.customer.year, $scope.customer.month, $scope.customer.day);
                $scope.customer.birthday = birthday;
              }

              $scope.customer.phones = $scope.customer.phones.filter(function (el) {
                if (el.phone) return true;
              });
              $scope.customer.emails = $scope.customer.emails.filter(function (el) {
                if (el.email) return true;
              });
              $scope.customer.urls = $scope.customer.urls.filter(function (el) {
                if (el.url) return true;
              });
              $scope.customer.socials = $scope.customer.socials.filter(function (el) {
                if (el.social) return true;
              });

              $scope.customer.$save(function (data) {
                if (data.ok && $scope.photoFile) {
                  $scope.upload = Upload.upload({
                    url: CustomerPhotoSendFile.replace(':customerId', data.payload.entity.id),
                    method: 'POST',
                    file: $scope.photoFile,
                    fileFormDataName: 'file'
                  }).then(function (data) {
                    notifyManager.success($translate.instant('entity.save.successfully'));
                    $location.path('/customer/customer');
                  });
                } else {
                  notifyManager.success($translate.instant('entity.save.successfully'));
                  $location.path('/customer/customer');
                }
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };

          //todo fix copy past
          $scope.saveAndNew = function () {
            if ($scope.customerForm.$valid) {
              if ($scope.customer.year && $scope.customer.month && $scope.customer.day) {
                var birthday = new Date();
                birthday.setFullYear($scope.customer.year, $scope.customer.month, $scope.customer.day);
                $scope.customer.birthday = birthday;
              }
              $scope.customer.phones = $scope.customer.phones.filter(function (el) {
                if (el.phone) return true;
              });
              $scope.customer.emails = $scope.customer.emails.filter(function (el) {
                if (el.email) return true;
              });
              $scope.customer.urls = $scope.customer.urls.filter(function (el) {
                if (el.url) return true;
              });
              $scope.customer.socials = $scope.customer.socials.filter(function (el) {
                if (el.social) return true;
              });

              $scope.customer.$save(function (data) {
                if (data.ok && $scope.photoFile) {
                  $scope.upload = Upload.upload({
                    url: CustomerPhotoSendFile.replace(':customerId', data.payload.entity.id),
                    method: 'POST',
                    file: $scope.photoFile,
                    fileFormDataName: 'file'
                  }).then(function (data) {
                    notifyManager.success($translate.instant('entity.save.successfully'));
                    $state.reload();
                  });
                } else {
                  notifyManager.success($translate.instant('entity.save.successfully'));
                  $state.reload();
                }
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
        }
      }
    ])
    .controller('CustomerAddCtrl',

      function ($scope, $location, Upload, $translate, $state, notifyManager, customer, customerContext, customerMetadata, CustomerAdd, CustomerPhotoSendFile) {
        CustomerAdd($scope, $location, Upload, $translate, $state, notifyManager, customer, customerContext, customerMetadata, CustomerPhotoSendFile);
      })
    .controller('CustomerEditCtrl',

      function ($scope, $location, Upload, $http, $translate, $state, notifyManager, customer, customerContext, customerMetadata, CustomerAdd, CustomerPhotoSendFile, CustomerHistory, $filter) {

        //Fill scope by service
        CustomerAdd($scope, $location, Upload, $translate, $state, notifyManager, customer, customerContext, customerMetadata, CustomerPhotoSendFile);

        if (customer.photo) {
          $scope.showPhoto = true;
        }

        $scope.save = function () {
          if ($scope.customerForm.$valid) {

            if ($scope.customer.year && $scope.customer.month && $scope.customer.day) {
              var birthday = new Date();
              birthday.setFullYear($scope.customer.year, $scope.customer.month, $scope.customer.day);
              $scope.customer.birthday = birthday;
            }

            $scope.customer.phones = $scope.customer.phones.filter(function (el) {
              if (el.phone) return true;
            });
            $scope.customer.emails = $scope.customer.emails.filter(function (el) {
              if (el.email) return true;
            });
            $scope.customer.urls = $scope.customer.urls.filter(function (el) {
              if (el.url) return true;
            });
            $scope.customer.socials = $scope.customer.socials.filter(function (el) {
              if (el.social) return true;
            });

            $scope.customer.$edit({customerId: $scope.customer.id}, function (data) {
              if (data.ok && ($scope.photoFile)) {
                $scope.upload = Upload.upload({
                  url: CustomerPhotoSendFile.replace(':customerId', data.payload.entity.id),
                  method: 'POST',
                  file: $scope.photoFile,
                  fileFormDataName: 'file'
                }).then(function (data) {
                  notifyManager.success($translate.instant('entity.save.successfully'));
                  $location.path('/customer/customer');
                });
              } else if (data.ok && !$scope.showPhoto && data.payload.entity.photo) {
                $http({
                  method: 'DELETE',
                  url: CustomerPhotoSendFile.replace(':customerId', data.payload.entity.id)
                }).then(function () {
                  notifyManager.success($translate.instant('entity.save.successfully'));
                  $location.path('/customer/customer');
                });

              } else {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $location.path('/customer/customer');
              }
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        $scope.saveAndNew = function () {
          if ($scope.customerForm.$valid) {
            if ($scope.customer.year && $scope.customer.month && $scope.customer.day) {
              var birthday = new Date();
              birthday.setFullYear($scope.customer.year, $scope.customer.month, $scope.customer.day);
              $scope.customer.birthday = birthday;
            }

            $scope.customer.phones = $scope.customer.phones.filter(function (el) {
              if (el.phone) return true;
            });
            $scope.customer.emails = $scope.customer.emails.filter(function (el) {
              if (el.email) return true;
            });
            $scope.customer.urls = $scope.customer.urls.filter(function (el) {
              if (el.url) return true;
            });
            $scope.customer.socials = $scope.customer.socials.filter(function (el) {
              if (el.social) return true;
            });

            $scope.customer.$edit({customerId: $scope.customer.id}, function (data) {
              if (data.ok && $scope.photoFile) {
                $scope.upload = Upload.upload({
                  url: CustomerPhotoSendFile.replace(':customerId', data.payload.entity.id),
                  method: 'POST',
                  file: $scope.photoFile,
                  fileFormDataName: 'file'
                }).then(function (data) {
                  notifyManager.success($translate.instant('entity.save.successfully'));
                  $location.path('/customer/customer/add');
                });
              } else {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $location.path('/customer/customer/add');
              }
            });
          } else if (data.ok && !$scope.showPhoto && data.payload.entity.photo) {
            $http({
              method: 'DELETE',
              url: CustomerPhotoSendFile.replace(':customerId', data.payload.entity.id)
            }).then(function () {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $location.path('/customer/customer');
            });

          } else {
            $scope.$broadcast('gf-submitted');
          }

        };

        $scope.getHistory = function () {
          if ($scope.lastTransactions && $scope.popularArticles) {
            return;
          }
          $scope.loading = true;
          CustomerHistory.get({customerId: customer.id}, function (data) {
            $scope.loading = false;
            $scope.lastTransactions = data.payload.lastTransactions;
            $scope.lastTransactionsChart = data.payload.lastTransactionsChart;
            $scope.popularArticles = data.payload.popularArticles;

            //todo move common chart options to factory
            $scope.chartConfig = {

              options: {
                chart: {
                  type: 'column',
                  zoomType: 'x'
                },
                style: {
                  width: '100%',
                  height: '100%'
                },
                plotOptions: {
                  column: {
                    stacking: 'normal'
                  }
                },
                credits: {
                  enabled: false
                },
                exporting: {
                  enabled: false
                },
                tooltip: {
                  useHTML: true,
                  formatter: function () {
                    return '<div><b>' + $filter('date')(new Date(this.x), 'shortDate') + '</b></div>' +
                      this.series.name + ': ' + $filter('currency')(this.y, this.point.currency);
                  }
                }
              },
              xAxis: {
                type: 'datetime',
                minPadding: 0,
                dateTimeLabelFormats: {
                  day: '%a %e'
                }
              },
              yAxis: {
                title: {
                  text: ''
                }
              },
              title: {
                text: false,
                align: 'left'
              },

              series: $scope.lastTransactionsChart
            };
          });
        };

      })
    .controller('CustomerGridCtrl',

      function ($scope, $uibModal, $http, $translate, notifyManager, customerMetadata, customerContext, CustomerCard, $timeout) {
        $scope.metadata = customerMetadata;
        $scope.context = customerContext;

        angular.extend($scope, customerContext.data.dependencies);


        var columns = [
          {name: 'number', width: 179},
          {name: 'companyName', width: 110},
          {name: 'companyPosition', width: 117},
          {name: 'salutation', width: 110},
          {name: 'title', width: 101},
          {name: 'firstName', width: 127},
          {name: 'lastName', width: 135},
          {name: 'birthday', enableFiltering: false, width: 138, enableCellEdit: false},
          {name: 'customerGroup', width: 169},
          {name: 'language', visible: false, width: 150},
          {name: 'description', visible: false, width: 200},
          {name: 'operate', actions: ['activate', 'edit', 'delete']}
        ];

        $scope.config = {
          urlRoute: '/customer/customer',
          enableSorting: true,
          enableAdd: true,
          enableFiltering: true,
          enablePagination: true,
          enableLastModifiedFilter: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: columns
        };

        $scope.generateCards = function () {
          var modalDialog = $uibModal.open({
            template: require('./../../template/page/customer/generate_card.html'),
            windowClass: 'gf-modal',
            controller: 'GenerateCardCtrl',
            scope: $scope
          });

          modalDialog.result.then(function (card) {
            $scope.pleasWateDialog = $uibModal.open({
              template: require('./../../template/page/customer/generate_cards_please_wait.html'),
              scope: $scope,
              windowClass: 'gf-modal'
            });


            card.$save(function (data) {
              if (data.ok) {
                if (data.payload.generatedCards > 0) {
                  notifyManager.success(data.message);
                } else {
                  notifyManager.error($translate.instant('customer.cardsGenerating.error.noCardsGenerated'));
                }
                $scope.config.reloadData();
              } else {
                notifyManager.error(data.message);
              }
              $timeout(function () {
                $scope.pleasWateDialog.close();
              }, 0);
            });
          });

        };

        $scope.deleteAllCustomers = function () {
          $http.put('/icash/customer/deleteAll').then(function (resp) {
            if (resp.data.status == 'OK') {
              notifyManager.success($translate.instant('message.customers.deleted.success'));
              $scope.config.reloadData();
            } else {
              notifyManager.error($translate.instant('message.customers.deleted.failure'))
            }
          });
        };
      }
    )
    .factory('CustomerGroupAdd', [
      function () {
        return function ($scope, $location, $state, customerGroupContext, customerGroupMetadata, customerGroup) {
          $scope.customerGroupContext = customerGroupContext;
          $scope.customerGroupMetadata = customerGroupMetadata;
          $scope.customerGroup = customerGroup;

          angular.extend($scope, customerGroupContext.data.dependencies);


          $scope.save = function () {
            if ($scope.customerGroupName.$valid) {
              $scope.customerGroup.$save(function (data) {
                $location.path('/customer/customer_group');
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          $scope.saveAndNew = function () {
            if ($scope.customerGroupName.$valid) {
              $scope.customerGroup.$save(function (data) {
                $state.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
        }
      }
    ])
    .controller('CustomerGroupAddCtrl',

      function ($scope, $location, $state, customerGroupContext, customerGroupMetadata, customerGroup, CustomerGroupAdd) {
        CustomerGroupAdd($scope, $location, $state, customerGroupContext, customerGroupMetadata, customerGroup);
      }
    )
    .controller('CustomerGroupEditCtrl',

      function ($scope, $location, $state, customerGroupContext, customerGroupMetadata, customerGroup, CustomerGroupAdd) {

        // Fill scope by service
        CustomerGroupAdd($scope, $location, $state, customerGroupContext, customerGroupMetadata, customerGroup);

        $scope.save = function () {
          $scope.customerGroup.$edit({customerGroupId: $scope.customerGroup.id}, function (data) {
            $location.path('/customer/customer_group');
          });
        };
        $scope.saveAndNew = function () {
          $scope.customerGroup.$edit({customerGroupId: $scope.customerGroup.id}, function (data) {
            $location.path('/customer/customer_group/add');
          });
        };
      }
    )
    .controller('CustomerGroupGridCtrl',

      function ($scope, customerGroupMetadata, customerGroupContext) {
        $scope.metadata = customerGroupMetadata;
        $scope.context = customerGroupContext;

        $scope.config = {
          urlRoute: '/customer/customer_group',
          enableSorting: true,
          enableAdd: true,
          enableFiltering: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'name'},
            {name: 'discount'},
            {name: 'operate', actions: ['edit', 'delete']}
          ]
        };
      }
    )
    .factory('CustomerDiscountAdd', [
      function () {
        return function ($scope, $location, $state, customerDiscountContext, customerDiscountMetadata, customerDiscount, notifyManager, $translate) {
          $scope.customerDiscountContext = customerDiscountContext;
          $scope.customerDiscountMetadata = customerDiscountMetadata;
          $scope.customerDiscount = customerDiscount;

          $scope.customerDiscountMetadata.columns.value.errorMessages.push(
            {
              'keys': ['gf-discount-max-value'],
              'message': 'de.icash.validator.discountPercentMaxValue.message'
            }
          );

          $scope.save = function () {
            if ($scope.customerDiscountName.$valid) {
              $scope.customerDiscount.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $location.path('/customer/discount')
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          $scope.saveAndNew = function () {
            if ($scope.customerDiscountName.$valid) {
              $scope.customerDiscount.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };

        }
      }
    ])
    .controller('CustomerDiscountAddCtrl',

      function ($scope, $location, $state, customerDiscountContext, customerDiscountMetadata, customerDiscount, CustomerDiscountAdd, notifyManager, $translate) {
        CustomerDiscountAdd($scope, $location, $state, customerDiscountContext, customerDiscountMetadata, customerDiscount, notifyManager, $translate);
      }
    )
    .controller('CustomerDiscountEditCtrl',

      function ($scope, $location, $state, customerDiscountContext, customerDiscountMetadata, customerDiscount, CustomerDiscountAdd, notifyManager, $translate) {

        // Fill scope by service
        CustomerDiscountAdd($scope, $location, $state, customerDiscountContext, customerDiscountMetadata, customerDiscount, notifyManager, $translate);

        $scope.save = function () {
          if ($scope.customerDiscountName.$valid) {
            $scope.customerDiscount.$edit({customerDiscountId: $scope.customerDiscount.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $location.path('/customer/discount')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

        $scope.saveAndNew = function () {
          if ($scope.customerDiscountName.$valid) {
            $scope.customerDiscount.$edit({customerDiscountId: $scope.customerDiscount.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $location.path('/customer/discount/add')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
      }
    )
    .controller('CustomerDiscountGridCtrl',

      function ($scope, customerDiscountMetadata, customerDiscountContext, $translate) {

        $scope.metadata = customerDiscountMetadata;
        $scope.context = customerDiscountContext;

        var customerdiscountvalueValidator = {
          'gf-customerdiscountvalue': {
            'angular': {'gf-customerdiscountvalue': ''},
            'errorMessage': "javax.validation.constraints.DecimalMax.message",
            'errorMessageArg': "value",
            'errorMessageArgConstant': "100"
          }
        };

        var сustomerdiscountabsoluteValidator = {
          'gf-customerdiscountabsolute': {
            'angular': {'gf-customerdiscountabsolute': ''},
            'errorMessage': "Max",
            'errorMessageArgsConstants': {"0": $translate.instant('customerDiscount.value'), "1": "100"}
          }
        };
//:{{'customerDiscount.value'|translate}} :'100'
        $scope.config = {
          urlRoute: '/customer/discount',
          enableSorting: true,
          enableAdd: true,
          enableFiltering: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'name'},
            {name: 'value', validations: customerdiscountvalueValidator},
            {name: 'absolute', width: 80,
              cellTemplate: "<div ng-init=\"customerDiscountValue=('customerDiscount.value'|translate)\" uib-tooltip=\"{{(row.entity.id!=null||row.entity.filled)&&(row.entity.absolute&&row.entity.value>100) ? ('Max' | translate:{'0': customerDiscountValue, '1': '100'}) : ''}}\" tooltip-apend-to-body=\"false\"  class=\"ui-grid-cell-contents\" ng-show=\"row.entity.id!=null||row.entity.filled\"><i ng-show=\"COL_FIELD\" class=\"far fa-check-square\"></i><i ng-hide=\"COL_FIELD\" class=\"far fa-square\"></i></div>",
              cellEditableCondition: function($scope) {
                return !$scope.row.entity.absolute || $scope.row.entity.value <= 100;
              }},
            {name: 'checkDiscountable', width: 80, visible: false},
            {name: 'operate', actions: ['edit', 'delete']}
          ]
        };
      }
    )
    .controller('YoviteCtrl',

      function ($scope, $location, $translate, notifyManager, Yovite, yovite, yoviteContext, yoviteMetadata) {
        $scope.yovite = yovite;
        $scope.yoviteContext = yoviteContext;
        $scope.yoviteMetadata = yoviteMetadata;

        angular.extend($scope, yoviteContext.data.dependencies);


        $scope.save = function () {
          if ($scope.yoviteForm.$valid) {
            $scope.yovite.$edit(function (data) {
              $scope.yovite = new Yovite(data.payload.yovite);
              notifyManager.success($translate.instant('entity.edit.successfully'));
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

      }
    )

})();
