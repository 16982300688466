import React from 'react';
import LSChip, {shapes} from "../common-components/LSChip";
import {useTranslation} from "react-i18next";

const payoutStatuses = {
  pending: {
    name: 'lsp.pending',
    color: shapes.unknown
  },
  'in-transit': {
    name: 'lsp.in-transit',
    color: shapes.unknown
  },
  paid: {
    name: 'lsp.paid',
    color: shapes.success
  },
  failed: {
    name: 'lsp.failed',
    color: shapes.failed
  },
  cancelled: {
    name: 'lsp.cancelled',
    color: shapes.failed
  },
  unknown: {
    name: 'lsp.unknown',
    color: shapes.unknown
  }
}

export function getPayoutStatus(status){
  return payoutStatuses[status] || payoutStatuses.unknown;
}

function PayoutStatus({size, status}) {
  const {t} = useTranslation();

  return <LSChip size={size} type={t(status.name)} color={status.color}/>
}

export default PayoutStatus
