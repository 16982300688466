import React, {useEffect, useState} from 'react';
import LSDataGrid from '../common-components/LSDataGrid';
import axios from 'axios';
import moment from 'moment';
import {Link} from '@material-ui/core';
import Currency from "./Currency";
import {getAlertMessage} from "./Alert";
import {useSnackbar} from "notistack";
import {useTranslation} from "react-i18next";

const PAGE_SIZE = 20;

const columns = [
  {field: 'id', headerName: 'lsp.ID', hide: true},
  {
    field: 'payoutDate', headerName: 'lsp.date', flex: 0.4,
  },
  {
    field: 'amountSales',
    headerName: 'lsp.sales',
    flex: 0.15,
    renderCell: params => getCell(params, Math.abs(params.value))
  },
  {
    field: 'amountRefunds',
    headerName: 'lsp.refunds',
    flex: 0.15,
    renderCell: params => getCell(params, -Math.abs(params.value))
  },
  {
    field: 'amountFees',
    headerName: 'lsp.fees',
    flex: 0.15,
    renderCell: params => getCell(params, -Math.abs(params.value))
  },
  {
    field: 'amountNet',
    headerName: 'lsp.net',
    flex: 0.15,
    renderCell: params => getCell(params, Math.abs(params.value))
  }
]

function Settlements({
                       from,
                       to,
                       goToDetails,
                       onDataLoad,
                       onChangeReportSortModel,
                       onChangeReportPage,
                       sortModel,
                       page
                     }) {
  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState();

  // set renderCell for id column
  columns.find(col => col.field === 'payoutDate').renderCell = params =>
    <Link component="button" style={{overflow: 'hidden'}}
          onClick={() => {
            goToDetails({id: params.id})
          }}>
      {moment(params.value).format('lll')}
    </Link>

  useEffect(() => {
    setLoading(true);
    let respData = [];
    axios.post('/icash/ls_payments/settlements', {
        startDate: moment(from).unix(),
        endDate: moment(to).unix(),
        order: sortModel.sort,
        orderBy: sortModel.field,
        page: page + 1,
        perPage: PAGE_SIZE
      }
    ).then(resp => {
      setTotalRows(resp.data.meta.pagination.total);
      respData = normalizeData(resp.data.data);
      setData(respData);
    }).catch(error => {
      setTotalRows(0);
      setData(respData);
      enqueueSnackbar(t(getAlertMessage(error)), {variant: 'error'});
    }).finally(() => {
      setLoading(false);
      if (onDataLoad) {
        onDataLoad(respData);
      }
    });
  }, [from, to, sortModel.sort, sortModel.field, page]);

  const onPageChange = params => {
    onChangeReportPage(params?.page || params);
  }

  const onSortModelChange = sortModel => {
    onChangeReportSortModel(sortModel);
  }

  return <LSDataGrid columns={columns} rows={data} loading={loading}
                     onSortModelChange={onSortModelChange}
                     onPageChange={onPageChange} pageSize={PAGE_SIZE}
                     rowCount={totalRows} sortModel={sortModel} page={page}/>;
}

export default Settlements;


function normalizeData(data) {
  return data.map(el => {
    return {
      id: el.id,
      payoutDate: el.attributes.payoutDate,
      amountSales: el.attributes.amountCredit,
      amountRefunds: el.attributes.amountDebit,
      amountFees: el.attributes.amountFees,
      amountNet: el.attributes.amountNet,
      status: el.attributes.status,
      currency: el.attributes.currency
    }
  })
}

function getCell(params, value) {
  return value ? <Currency value={value} currency={params.row.currency}/> : "-";
}
