(function() {
  'use strict';
  angular.module('gf.operator.data.operatorSettings', ['ui.router'])

    .factory('OperatorSettings', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/operator_settings/operatorSettings.json')
    })
    .factory('CurrentOperatorSettingsMetadata', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/operator_settings/metadata.json')
    })
    .factory('CurrentOperatorSettingsContext', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/operator_settings/context.json')
    })

    .config(function($stateProvider) {

      $stateProvider
        .state('page.operator_dataOperator_settings', {
          url: '/operator_data/operator_settings',
          template: require('../template/operator-data/operator-settings/operator_settings.html'),
          controller: 'OperatorSettingsCtrl',
          controllerAs: 'operatorSettingsCtrl',
          resolve: {
            operatorSettings: function(OperatorSettings) {
              return OperatorSettings.get().$promise;
            },
            operatorSettingsMetadata: function(CurrentOperatorSettingsMetadata) {
              return CurrentOperatorSettingsMetadata.get().$promise;
            },
            operatorSettingsContext: function(CurrentOperatorSettingsContext) {
              return CurrentOperatorSettingsContext.get().$promise;
            }
          },
          title: "operatorSettings"
        })
    })


    .controller('OperatorSettingsCtrl',

      function ($scope, $translate, operatorSettings, operatorSettingsMetadata, operatorSettingsContext, notifyManager, OperatorSettings, $http) {
        var self = this;
        self.operatorSettings = operatorSettings;
        self.operatorSettingsContext = operatorSettingsContext;
        self.operatorSettingsMetadata = operatorSettingsMetadata;
        self.operatorSettingsData = operatorSettingsContext.data;

        angular.extend(self, operatorSettingsContext.data.dependencies);

        $scope.$watch('self.operatorSettings.address.country.id', function (value) {
          self.regions = operatorSettingsContext.data.dependencies.regionsByCountry[value];
        });

        self.save = function () {
          if (self.operatorSettingsForm.$valid) {
            self.operatorSettings.$save(function (data) {
              if (data.ok) {
                notifyManager.success($translate.instant('entity.edit.successfully'));
              }
              self.operatorSettings = new OperatorSettings(data.payload.operatorSettings);
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };

        self.unlink = function (t) {
          var test = !!t;

          $http.put('/icash/operator_data/operator_settings/unlink?test=' + test)
            .then(function (resp) {
              if (resp.data.ok) {
                self.operatorSettingsData = resp.data.payload;
              }
            });
        }
      }
    )

})();
