import React, {useEffect, useState} from 'react';
import {
  Grid,
  FormControlLabel,
  RadioGroup,
  Radio,
  IconButton,
  Popper,
  Fade,
  Paper,
  FormControl,
  TextField,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {makeStyles} from "@material-ui/core/styles";
import {
  setUrlParameters,
  getFilterValuesList,
  isAutopreviewPeriod,
  filterToUrl,
  getAvailableForType
} from './ReportUtils';
import PlusIcon from "@material-ui/icons/AddOutlined";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import theme from "../../react_components/config.theme";

export default function ReportFilter({
                                       typeFilters, setChosenFilters, expanded, chosenFilters, anchorElFilter, isFilterLoader,
                                       setAnchorElFilter, handleGetPreview, chosenDate, isAutopreview, setSettingChanged
                                     }) {

  const useStyles = makeStyles({
    formControl: {
      width: '100%',
    },
    chip: {
      margin: theme.spacing(0.5),
      maxWidth: 300
    },
    popperWrapper: {
      padding: 20, width: 350
    },
    inlineFilter: {
      display: 'inline-block'
    },
    fabProgress: {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
    },
    filterOption: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block'
    },
  });

  const classes = useStyles();
  const [newFilter, setNewFilter] = useState({});
  const [editFilter, setEditFilter] = useState(false);
  const openFilter = Boolean(anchorElFilter);
  const {t} = useTranslation();

  const handleOpenFilter = (data, event) => {
    setEditFilter(false);
    setNewFilter({});
    setAnchorElFilter(event.currentTarget);
    if (data !== 'add') {
      setEditFilter(true);
      setNewFilter({
        ...newFilter,
        'filterName': data.filter,
        'filterValue': _.find(chosenFilters, {'filter': data.filter}).value,
        'filterType': typeFilters[data.filter].filterType
      });
    }
  };

  const handleCloseFilter = () => {
    setAnchorElFilter(null);
    setEditFilter(false);
    setNewFilter({});
  };

  const handleAddNewFilter = (name, event, value) => {
    setNewFilter({
      ...newFilter, [name]: value,
      'filterValue': null,
      'filterType': typeFilters[value].filterType
    })
  };

  const handleAddNewFilterValue = (name, event, value) => {
    setNewFilter({...newFilter, [name]: value});
  };

  const addFilterToList = event => {
    function filterValues() {
      switch (newFilter.filterType) {
        case 'multiselect': {
          return getFilterValuesList(newFilter.filterName, newFilter.filterValue, t)
        }
        case 'boolean': {
          return t(newFilter.filterValue.names[0]);
        }
        default: {
          return ''
        }
      }
    }

    let newFilterObj = {
      filter: newFilter.filterName,
      value: newFilter.filterValue,
      label: t('report.filter' + newFilter.filterName) + ": " + filterValues(),
      type: newFilter.filterType
    };
    if (editFilter) {
      let existingIndex = _.findIndex(chosenFilters, {filter: newFilter.filterName});
      chosenFilters.splice(existingIndex, 1, newFilterObj)
    }
    let addedFilters = _.union(chosenFilters, [newFilterObj]);
    setChosenFilters(addedFilters);
    setAnchorElFilter(null);
    setNewFilter({});
    setEditFilter(false);
    setUrlParameters('filter', JSON.stringify(filterToUrl(addedFilters)));
    setSettingChanged(true);
  };

  const handleDeleteFilter = chipToDelete => () => {
    let filterObj = chosenFilters.filter(filter => filter.filter !== chipToDelete.filter);
    setChosenFilters(filterObj);
    setUrlParameters('filter', JSON.stringify(filterToUrl(filterObj)));
    setSettingChanged(true);
    setAnchorElFilter(null);
  };

  const filterKeyEvents = e => {
    if (e.keyCode === 13 && newFilter.filterValue) {
      addFilterToList();
    } else if (e.keyCode === 27) {
      handleCloseFilter();
    }
  };

  useEffect(() => {
    if (expanded && isAutopreview && isAutopreviewPeriod(chosenDate)) {
      handleGetPreview();
    }
  }, [chosenFilters]);

  return (
    <div>
      <div className={classes.inlineFilter}
           style={{color: '#888', marginRight: 10}}>{t('jsp.menu.reportFilters')}:
      </div>
      {chosenFilters.map(data => {
        return (
          <Chip
            id={'filter_' + data.filter}
            key={data.filter}
            label={data.label}
            onDelete={handleDeleteFilter(data)}
            className={classes.chip}
            onClick={(event) => _.isEmpty(typeFilters) ? '' : handleOpenFilter(data, event)}
          />
        )
      })}
      <div className={classes.inlineFilter} id="report_filter_section">
        <div onKeyDown={filterKeyEvents}>
          <IconButton id="add_filter_button" disabled={!expanded || _.isEmpty(typeFilters)} aria-label="addFilter"
                      size="medium"
                      onClick={(event) => handleOpenFilter('add', event)}>
            {isFilterLoader ? <MoreHorizIcon/> : <PlusIcon/>}
            {isFilterLoader && <><CircularProgress size={43} className={classes.fabProgress}/>
              <span style={{paddingLeft: 20, fontSize: 14}}>{t('report.loadingFilters')}</span></>}
          </IconButton>
          <Popper id="filter_popper" open={openFilter} anchorEl={anchorElFilter} placement={'bottom'} transition>
            {({TransitionProps}) => (
              <ClickAwayListener onClickAway={handleCloseFilter}>
                <Fade {...TransitionProps} timeout={350}>
                  <Paper className={classes.popperWrapper}>
                    <Grid item xs={12} container>
                      <Grid item xs={8} style={{paddingRight: 10}}>
                        {editFilter ?
                          <FormControl className={classes.formControl}>
                            <TextField
                              id="edit_filter_name"
                              label={t('report.label.field')}
                              defaultValue={t('report.filter' + newFilter.filterName)}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </FormControl> :
                          <FormControl className={classes.formControl}>
                            <Autocomplete
                              id='filter_name'
                              options={expanded ? excludeChosenFilters(Object.keys(typeFilters), chosenFilters, t) : []}
                              value={!_.isEmpty(newFilter) ? newFilter.filterName : null}
                              getOptionLabel={option => t('report.filter' + option)}
                              disableClearable
                              onChange={(event, value) => handleAddNewFilter('filterName', event, value)}
                              renderInput={params => (
                                <TextField {...params} label={t('report.label.field')} fullWidth/>
                              )}
                            />
                          </FormControl>
                        }
                      </Grid>
                      <Grid item xs={12}>
                        {!_.isEmpty(newFilter) && newFilter.filterType === 'multiselect' &&
                        <FormControl className={classes.formControl}>
                          <Autocomplete
                            multiple
                            id='filter_value'
                            filterSelectedOptions
                            options={typeFilters[newFilter.filterName]}
                            getOptionSelected={ (option, value) => {
                              return option.id === value.id
                            }}
                            classes={{
                              option: classes.filterOption,
                              input: classes.filterOption,
                            }}
                            value={!_.isEmpty(newFilter) && newFilter.filterValue && newFilter.filterValue.length ? newFilter.filterValue : []}
                            getOptionLabel={option => newFilter.filterName === 'Article' ? '[' + option.id + '] ' + option.name : option.name.toString()}
                            onChange={(event, value) => handleAddNewFilterValue('filterValue', event, value)}
                            renderInput={params => (
                              <TextField {...params} label={t('report.label.value')} fullWidth/>
                            )}
                          />
                        </FormControl>}
                        {
                          !_.isEmpty(newFilter) && newFilter.filterType === 'boolean' &&
                          <RadioGroup aria-label="filterValue" name="filter_value_flag"
                                      value={!_.isEmpty(newFilter) && newFilter.filterValue
                                        ? _.find(typeFilters[newFilter.filterName], {'id': newFilter.filterValue.id}) : {}}
                                      style={{marginTop: 20}} row>
                            <FormControlLabel
                              value={typeFilters[newFilter.filterName][0]}
                              control={<Radio color="primary"/>}
                              label={t(typeFilters[newFilter.filterName][0].names)}
                              labelPlacement="start"
                              onClick={(event) => handleAddNewFilterValue('filterValue',
                                event, typeFilters[newFilter.filterName][0])}
                            />
                            <FormControlLabel
                              value={typeFilters[newFilter.filterName][1]}
                              control={<Radio color="primary"/>}
                              label={t(typeFilters[newFilter.filterName][1].names)}
                              labelPlacement="start"
                              onClick={(event) => handleAddNewFilterValue('filterValue',
                                event, typeFilters[newFilter.filterName][1])}
                            /></RadioGroup>
                        }
                      </Grid>
                    </Grid>
                    <div style={{textAlign: "right", marginTop: 10}}>
                      <Button id="close_filter_button" size={'small'}
                              onClick={handleCloseFilter} style={{marginRight: 10}}>{t("button.cancel")}</Button>
                      <Button id="save_filter_button" variant='contained' size={'small'}
                              disabled={_.isEmpty(newFilter) || (!_.isEmpty(newFilter) && !newFilter.filterValue
                                || (newFilter.filterType === 'multiselect' && !newFilter.filterValue.length))}
                              onClick={addFilterToList}>{t("button.save")}</Button>
                    </div>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </div>
      </div>
    </div>
  );
}

function excludeChosenFilters(filters, chosenFilters, t) {
  filters.sort((a, b) => t('report.filter' + a).localeCompare(t('report.filter' + b)));
  const used = chosenFilters.map((filter) => {
    return filter.filter;
  });
  return filters.filter(item => !used.includes(item));
}
