import {createMuiTheme} from '@material-ui/core';
import COMMON_THEME_SETTINGS from './common.theme.settings';

export default createMuiTheme({
  ...COMMON_THEME_SETTINGS,
  typography: {
    fontSize: 20,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#2E61DE',
    },
    secondary: {
      main: '#2F3137'
    }
  },
  errorText: {
    color: '#f44335',
    paddingTop: 5,
  },
  linkText: {
    color: '#2E61DE'
  },
  props: {
    MuiButton: {
      color: 'primary'
    }
  }
})
;
