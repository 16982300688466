import React from 'react';
import LSChip, {shapes} from "../common-components/LSChip";
import {useTranslation} from "react-i18next";

const transactionTypes = {
  adjustment: {
    name: 'lsp.adjustment',
    color: shapes.unknown
  },
  authorization: {
    name: 'lsp.authorization',
    color: shapes.unknown
  },
  capture: {
    name: 'lsp.capture',
    color: shapes.unknown
  },
  chargeback: {
    name: 'lsp.chargeback',
    color: shapes.unknown
  },
  'chargeback-fee': {
    name: 'lsp.chargeback-fee',
    color: shapes.unknown
  },
  'chargeback-reversal': {
    name: 'lsp.chargeback-reversal',
    color: shapes.unknown
  },
  refund: {
    name: 'lsp.refund',
    color: shapes.warning
  },
  payout: {
    name: 'lsp.payout',
    color: shapes.unknown
  },
  sale: {
    name: 'lsp.sale',
    color: shapes.info
  },
  unknown: {
    name: 'lsp.unknown',
    color: shapes.unknown
  },
  void: {
    name: 'lsp.void',
    color: shapes.unknown
  },
}

export function getTransactionType(type){
  return transactionTypes[type] || transactionTypes.unknown;
}

//todo resolve TransactionType style by type and return Chip component or raw text

function TransactionType({type, size}) {
  const {t} = useTranslation();

  return <LSChip size={size} type={t(type.name)} color={type.color}/>
}

export default TransactionType;
