(function () {
  'use strict';
  angular.module('gf.bm.articleTemplate', [])

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.masterdataArticleTemplate', {
          url: '/masterdata/article/template',
          template: require('../template/bm/bm_article_template.html'),
          controller: 'BMArticleTemplate',
          controllerAs: '$ctrl',
          resolve: {
            articleContext: function (ArticleContext) {
              return ArticleContext.get().$promise;
            },
            articleMetadata: function (ArticleMetadata) {
              return ArticleMetadata.get().$promise;
            }
          },
          title: "articleTemplate"
        })
    })

    .controller('BMArticleTemplate',
      function (articleContext, articleMetadata, $http, ValidationDuplicatePLUMessageService, $state, notifyManager, $translate, $scope) {
        var self = this;

        self.articleMetadata = articleMetadata;
        self.getUniquePluMessage = ValidationDuplicatePLUMessageService.getMessage;
        self.dependencies = articleContext.data.dependencies;
        self.articleTemplate = {};

        self.save = function () {

          if (self.articleTemplateForm.$valid) {
            $http.put('/icash/masterdata/article/template/add.json', self.articleTemplate).then(function (response) {
              notifyManager.success($translate.instant('entity.save.successfully'));
              $state.go('page.masterdataArticle')
            }, function (response) {
              if (response.data.message) {
                notifyManager.error(response.data.message);
              } else {
                notifyManager.error($translate.instant('entity.save.failed'));
              }
            })
          } else {
            notifyManager.error($translate.instant('entity.form.not.valid'));
            $scope.$broadcast("gf-submitted");
          }
        };
        self.back = function () {
          $state.go('page.masterdataArticle')
        }

      })

})();
