export const MERP_COUNTRIES = ['DE'];

export function isWhitelabel(securityService) {
  if (THEME) {
    return ["culinaro", "sumup", "shore"].includes(THEME);
  } else if (securityService?.context?.theme?.brand) {
    return securityService.context.theme.brand.toLowerCase() !== "lightspeed";
  }
  return true;
}

export function isBusinessGroup(securityService) {
  return securityService.context.currentGroup;
};

export const MERP_USER_ROLES = ['ACCESS_SETUP_GROUP', 'ACCESS_RESTAURANT_DATA_GROUP', 'ROLE_RESELLER_USER']

const CAPITAL_BANNER_LS_KEY = "show-capital-offer-after";

export function makeCapitalBannerKey(userId, restaurantId) {
  return `${CAPITAL_BANNER_LS_KEY}-${userId}-${restaurantId}`;
}
