/*test resolver to get error page*/
var testErrorPageResolve = {
  restaurant: ['Restaurant', function (Restaurant) {
    return Restaurant.load('').$promise;
  }]
};

(function () {
  'use strict';
  angular.module('gf.error', [])

    .config(function($stateProvider) {

      $stateProvider
        .state('clean.test-error-page', {
          url: '/test-error-page',
          resolve: testErrorPageResolve
        });/*test url for error page*/
    })

    .controller('SendErrorReportCtrl',
      function ($scope, $http, $location, $uibModalInstance, securityService, $httpParamSerializer, BrowserInfoService) {
        $scope.browserInfo = BrowserInfoService.getBrowserInfo();
        $scope.userInfo = {
          email: securityService.context.currentUser.email
        };
        $scope.no = function () {
          $uibModalInstance.close();
        };
        $scope.sendErrorReport = function () {
          var errorData = {
            stackTrace: $scope.payload.stackTrace,
            rootCause: $scope.payload.rootCause,
            url: $scope.errorContext.config.url,
            method: $scope.errorContext.config.method,
            userEmail: $scope.userInfo.email,
            userComment: $scope.userInfo.comment,
            browserInfo: $scope.browserInfo
          };
          var request = {
            method: 'POST',
            url: '/icash/error_report',
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: $httpParamSerializer(errorData)
          };
          $http(request).then(function (result) {
            $uibModalInstance.close(result);
          });
        };
      }
    )

    .controller("ErrorCtrl",
      function ($scope, $state, errorService, securityService, $uibModal, $timeout) {
        $scope.errorContext = errorService.getLastError();
        if (!$scope.errorContext) {
          $state.go('page.welcome');
          return;
        }
        $scope.payload = ($scope.errorContext && $scope.errorContext.data && $scope.errorContext.data.payload) ? $scope.errorContext.data.payload : null;
        $scope.reportSent = false;
        $scope.homeUrl = '#/welcome';
        $scope.collapsed = false;
        if (securityService.hasAnyRole('ROLE_SUPERVISOR', 'ROLE_RESELLER_USER')) {
          $scope.isAdmin = true;
        }
        $scope.showSendErrorPopup = function () {
          $uibModal.open({
            template: require('./../../template/errorReportPopup.html'),
            controller: 'SendErrorReportCtrl',
            windowClass: 'gf-modal',
            scope: $scope,
            backdrop: 'static',
            keyboard: false
          }).result.then(function (result) {
            if (result && result.data.ok) {
              $scope.reportSent = true;
              $timeout(function () {
                $state.go('page.welcome');
              }, 5000);
            }
          })
        };
      }
    )
})();
