var ControlRoomCtrlResolve = {
  controlRoom: ["ControlRoom", function (ControlRoom) {
    return ControlRoom.get().$promise;
  }]
};

(function () {
  'use strict';
  angular.module('gf.operatorData', [])

    .config(function($stateProvider) {

      $stateProvider

        .state('page.operator_dataControl_room', {
          url: '/operator_data/control_room',
          template: require('./../../template/page/operator_data/control_room.html'),
          controller: 'ControlRoomCtrl',
          resolve: ControlRoomCtrlResolve,
          title: 'controlRoom'
        })
    })

    .controller('ControlRoomCtrl',

      function ($scope, $filter, $http, $translate, notifyManager, controlRoom, securityService) {
        $scope.gastronomicDay = controlRoom.payload.gastronomicDay;

        if ($scope.gastronomicDay) {
          $scope.gastronomicDay.gastronomicDay = $filter('date')(new Date($scope.gastronomicDay.gastronomicDay), 'dd.MM.yyyy');

          if ($scope.gastronomicDay.started) {
            $scope.gastronomicDay.started = $filter('date')(new Date($scope.gastronomicDay.started), 'HH:mm');
          }
          if ($scope.gastronomicDay.ended) {
            $scope.gastronomicDay.ended = $filter('date')(new Date($scope.gastronomicDay.ended), 'HH:mm');
          }
        }

        $scope.reopen = function () {
          $http.put('/icash/operator_data/control_room/' + $scope.gastronomicDay.id + '/reopen')
            .then(function (resp) {
              notifyManager.success($translate.instant('control_room.gastronomicDayReopen.success'));
              $scope.gastronomicDay = resp.data.payload.gastronomicDay;

              if ($scope.gastronomicDay) {
                if ($scope.gastronomicDay.started) {
                  $scope.gastronomicDay.started = $filter('date')(new Date($scope.gastronomicDay.started), 'dd.MM.yyyy HH:mm');
                }
                if ($scope.gastronomicDay.ended) {
                  $scope.gastronomicDay.ended = $filter('date')(new Date($scope.gastronomicDay.ended), 'dd.MM.yyyy HH:mm');
                }
              }
            });
        };

        $scope.gtcEnabled = function () {
          return _.includes(securityService.context.features, "GTC")
        };
      }
    )
    .controller('InvoicesCtrl',

      function ($scope, invoices) {
        $scope.invoices = invoices;
      }
    )
    .filter('invoiceFileName', function (moment, $translate) {
      return function (input, date) {
        if (!input) {
          return '';
        }
        var fileName = input.split('/').pop().split('#').shift().split('?').shift();
        if (fileName && fileName.indexOf('.') > 0) {
          return fileName;
        } else {
          return $translate.instant('invoice') + ' ' + moment(date).format('MMMM YYYY')
        }
      }
    })

})();
