import React, {useEffect, useState, forwardRef} from 'react';
import MaterialTable from 'material-table';
import {useSnackbar} from 'notistack';
import axios from 'axios';
import _ from 'lodash';
import {Button, TextField} from '@material-ui/core';
import {
  DeleteOutline,
  Edit,
  ArrowDownward,
  Done,
  Close
} from '@material-ui/icons';
import {useTranslation} from 'react-i18next';
import EditNews from './EditNews';
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import {makeStyles} from "@material-ui/core/styles";
import theme from "../../react_components/config.default.theme";

let sanitizeHtml = require('sanitize-html');

export default function WhatsNewsTable() {

  const useStyles = makeStyles({
    topButton: {
      marginBottom: theme.spacing(2),
    },
  });

  const tableIcons = {
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref}/>)
  };

  const {t} = useTranslation();
  const classes = useStyles();

  const cols = [
    {
      title: t('news.title'),
      field: 'title',
    },
    {
      title: t('news.template'),
      field: 'template',
    },
    {
      title: t('news.isActive'),
      field: 'active',
      render: rowData => rowData.active ? <Done/> : <Close/>
    },
    {
      title: t('news.priority'),
      field: 'priority',
    },
  ]
  const [news, setNews] = useState({
    data: [],
  });
  const {enqueueSnackbar} = useSnackbar();
  const [openEditPage, setOpenEditPage] = useState(false);
  const [currentNews, setCurrentNews] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const deleteText = t('news.deleteText');

  const goToEditPage = (rowData) => {
    setOpenEditPage(true);
    setCurrentNews(rowData);
  };

  const goToMainPage = () => {
    setOpenEditPage(false);
  };

  const deleteCurrentNews = (rowData) => {
    setOpenDeleteDialog(true);
    setCurrentNews(rowData);
  };
  const handleDeleteEntity = () => {
    deleteNews(currentNews).then((res) => {
      setOpenDeleteDialog(false);
      setNews(prevState => {
        const data = [...prevState.data];
        data.splice(data.indexOf(currentNews), 1);
        return {...prevState, data};
      });
      enqueueSnackbar(t('news.newsDeleted'), {variant: 'success'});
    });
  };

  const handleSanitizeAndSave = (editedNews) => {
    if (editedNews.content) {
      saveEntity({...editedNews, 'content': sanitize(editedNews.content)});
    } else {
      saveEntity(editedNews);
    }
  };

  function sanitize(content) {
      return sanitizeHtml(content, {
        allowedTags: ['a', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'img', 'br', 'hr', 'strong', 'em'],
        allowedAttributes: {
          'a': ['href', 'target', 'class'],
          'img': ['src', 'alt', 'height'],
          'div': ['class', 'align']
        }
      });
  }

  const saveEntity = (editedNews) => {
    if (!_.isEmpty(currentNews)) {
      editNews(editedNews).then((res) => {
          setNews(prevState => {
            const data = [...prevState.data];
            data[data.indexOf(currentNews)] = res.data;
            return {...prevState, data};
          });
          setOpenEditPage(false);
          enqueueSnackbar(t('news.editNews.savedSuccessfully'), {variant: 'success'});
        },
        () => {
          enqueueSnackbar(t('news.editNews.failedToSave'), {variant: 'error'});
        })
    } else {
      addNews(editedNews).then((res) => {
          setNews(prevState => {
            const data = [...prevState.data];
            data.push(res.data);
            return {...prevState, data};
          });
          setOpenEditPage(false);
          enqueueSnackbar(t('news.editNews.savedSuccessfully'), {variant: 'success'});
        },
        () => {
          enqueueSnackbar(t('news.editNews.failedToSave'), {variant: 'error'});
        });
    }
  };
  const handleCloseDeleteCofirmationDialog = () => {
    setOpenDeleteDialog(false)
  };

  //getting data for the page
  useEffect(() => {
    getNews().then(function (res) {
      setNews({...news, 'data': res.data});
    })
  }, []);

  return (
    <div>
      {!openEditPage &&
      <div>
        <Button type="button" color='secondary' variant='contained' className={classes.topButton}
                onClick={() => goToEditPage({})}>{t('button.add')}</Button>
        <MaterialTable
          columns={cols}
          data={news.data}
          icons={tableIcons}
          options={{
            paging: false,
            actionsColumnIndex: -1,
            toolbar: false
          }}
          actions={[
            {
              icon: Edit,
              tooltip: t('news.newsTable.editNews'),
              onClick: (event, rowData) => {
                goToEditPage(rowData);
              }
            },
            {
              icon: DeleteOutline,
              tooltip: t('news.newsTable.deleteNews'),
              onClick: (event, rowData) => {
                deleteCurrentNews(rowData);
              }
            }
          ]}
          localization={{
            body: {
              emptyDataSourceMessage: t('news.newsTable.noNewsAdded'),
            },
            header: {
              actions: t('jsp.actions')
            }
          }}
        />
      </div>
      }
      {openDeleteDialog && <DeleteConfirmationDialog
        open={openDeleteDialog}
        onDelete={handleDeleteEntity}
        handleClose={handleCloseDeleteCofirmationDialog}
        text={deleteText}
      />}
      {openEditPage &&
      <EditNews currentNews={currentNews} handleSave={handleSanitizeAndSave} goToMainPage={goToMainPage}/>
      }
    </div>

  );
}

function getNews() {
  return axios.get('/icash/admin/news')
}

function deleteNews(news) {
  return axios.delete('/icash/admin/news/' + news.id)
}

function addNews(news) {
  return axios.post('/icash/admin/news', news)
}

function editNews(news) {
  return axios.put('/icash/admin/news/' + news.id, news)
}
