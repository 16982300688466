(function() {
  'use strict';
  angular.module('gf.setup.exchangeRate', ['ui.router'])

    .factory('ExchangeRateMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/exchange_rate/metadata.json')
    })
    .factory('ExchangeRateContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/exchange_rate/context.json')
    })
    .factory('ExchangeRateMetadataNew', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/restaurant_data/exchange_rate/metadata_new.json')
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.setupExchange_rate', {
          url: '/setup/exchange_rate',
          template: require('../template/setup/exchange-rate/exchange_rates.html'),
          controller: 'ExchangeRateGridCtrl',
          controllerAs: 'exchangeRateGridCtrl',
          resolve: {
            exchangeRateMetadata: function (ExchangeRateMetadata) {
              return ExchangeRateMetadata.get().$promise;
            },
            exchangeRateContext: function (ExchangeRateContext) {
              return ExchangeRateContext.get().$promise;
            },
            exchangeRateMetadataNew: function (ExchangeRateMetadataNew) {
              return ExchangeRateMetadataNew.get().$promise;
            }
          },
          title: "exchangeRate"
        })
    }
    )

    .controller('ExchangeRateGridCtrl',

      function (exchangeRateContext, exchangeRateMetadata, securityService, exchangeRateMetadataNew, $scope, $http) {
        var self = this;
        self.metadata = exchangeRateMetadata;
        self.context = exchangeRateContext;
        self.metadataNew = exchangeRateMetadataNew;

        self.config = {
          enableSorting: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'id', width: 60, enableCellEdit: false, visible: false},
            {
              name: 'to', width: 200,
              validations: {
                'gf-uniqueexchangerate': {
                  'angular': {'gf-uniqueexchangerate': ''},
                  'errorMessage': "de.icash.validator.UniqueExchangeRate.message"
                }
              }
            },
            {
              name: 'valueBaseCurrency', cellFilter: 'number:4',
              editableCellTemplate: "<div><form name=\"inputForm\"><input ignore-mouse-wheel type=\"INPUT_TYPE\" step=\"0.0001\" ng-class=\"'colt' + col.uid\" ui-grid-editor ng-model=\"MODEL_COL_FIELD\"></form></div>"
            },
            {name: 'operate', actions: ['delete']}
          ]
        };
        function refreshContext() {
          $http.get('/icash/restaurant_data/exchange_rate/context.json').then(function (res) {
            self.context = res.data;
          })
        }
        $scope.$on('deleteEntity', refreshContext);
      }
    )
})();
