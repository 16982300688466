(function () {
  'use strict';
  angular.module('gf.bm.userPermissions', [])

    .factory('BMUserPermissions', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/bm_user/:operator/:id', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })

    .component('gfBmUserPermissions', {
      template: require('../template/bm/bm_user_permissions.html'),
      bindings: {
        bmUser: '='
      },
      controller: function (gridTreeHelper, BMUserPermissions, BranchGroup) {
        var self = this;
        self.$onInit = function () {
          var chosenGroups = self.bmUser ? self.bmUser.branchGroups : [];
          self.branchGroups = BranchGroup.get(function (response) {
            self.flattenBranchGroups = gridTreeHelper.flattenData(response.root);

            self.bmUser.branchGroups = _.chain(self.flattenBranchGroups)
              .filter(function (group) {
                return _.some(chosenGroups, function (chosenGroup) {
                  return chosenGroup.id === group.id;
                })
              }).map(function (group) {
                return {id: group.id, name: group.name};
              }).value();
          });
        }
      }
    })
    .directive('gfBmUserBranchGroups', ['$parse', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: link
      };

      function link(scope, element, attr, ngModel) {
        if (!ngModel) return;
        ngModel.$parsers.push(function (viewValue) {
          return _.map(viewValue, function (group) {
            return {id: group.id, name: group.name}
          })
        });
      }
    }])
})();
