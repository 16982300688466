import moment from 'moment';

const Periods = {
  TODAY: {
    id: 1,
    translation: 'date.today',
    getPeriod: () => ({
      from: moment().startOf('day').utc().valueOf()
    })
  },
  YESTERDAY: {
    id: 2,
    translation: 'date.yesterday',
    getPeriod: () => ({
      from: moment().subtract(1, 'days').startOf('day').utc().valueOf()
    })
  },
  THIS_WEEK: {
    id: 3,
    translation: 'date.thisWeek',
    getPeriod: () => ({
      from: moment().startOf('isoWeek').utc().valueOf()
    })
  },
  LAST_WEEK: {
    id: 4,
    translation: 'date.lastWeek',
    getPeriod: () => ({
      from: moment().subtract(1, 'weeks').startOf('isoWeek').utc().valueOf(),
      to: moment().subtract(1, 'weeks').endOf('isoWeek').utc().valueOf()
    })
  },
  THIS_MONTH: {
    id: 5,
    translation: 'date.thisMonth',
    getPeriod: () => ({
      from: moment().startOf('month').utc().valueOf()
    })
  },
  LAST_MONTH: {
    id: 6,
    translation: 'date.lastMonth',
    getPeriod: () => ({
      from: moment().subtract(1, 'months').startOf('month').utc().valueOf(),
      to: moment().subtract(1, 'months').endOf('month').utc().valueOf()
    })
  },
  THIS_YEAR: {
    id: 7,
    translation: 'date.thisYear',
    getPeriod: () => ({
      from: moment().startOf('year').utc().valueOf()
    })
  },
  FROM_TO: {
    id: 8,
    translation: 'date.FromTo',
    getPeriod: (from, to) => ({
      from: moment(from).startOf('day').utc().valueOf(),
      to: moment(to).endOf('day').utc().valueOf(),
    })
  }
};

class ReportPeriod {
  constructor(period) {
    this.period = period || Periods.TODAY
  }

  static today() {
    return new ReportPeriod(Periods.TODAY)
  }

  static yesterday() {
    return new ReportPeriod(Periods.YESTERDAY)
  }

  static thisWeek() {
    return new ReportPeriod(Periods.THIS_WEEK)
  }

  static lastWeek() {
    return new ReportPeriod(Periods.LAST_WEEK)
  }

  static thisMonth() {
    return new ReportPeriod(Periods.THIS_MONTH)
  }

  static lastMonth() {
    return new ReportPeriod(Periods.LAST_MONTH)
  }

  static thisYear() {
    return new ReportPeriod(Periods.THIS_YEAR)
  }

  static fromTo() {
    return new ReportPeriod(Periods.FROM_TO)
  }

  isFromTo() {
    return this.equals(ReportPeriod.fromTo())
  }

  static all() {
    return Object.values(Periods).map(period => new ReportPeriod(period))
  }

  static fromPeriodId(id) {
    return this.all().find(rp => (rp.period.id === id))
  }

  getPeriod() {
    return this.period.getPeriod(this.from, this.to)
  }

  equals(reportPeriod) {
    return this.period.id === reportPeriod.period.id
  }
}

export default ReportPeriod;
