(function() {
  'use strict';
  angular.module('gf.operator.data.salesforce', ['ui.router'])

    .factory('Salesforce', function($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/operator_data/saleforce_data', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      })
    })

    .config(function($stateProvider) {

      $stateProvider
        .state('page.operator_dataSalesforce', {
          url: '/operator_data/salesforce',
          template: require('../template/operator-data/salesforce/salesforce.html'),
          controller: 'SalesforceCtrl',
          controllerAs: 'salesforceCtrl',
          resolve: {
            salesforce: function (Salesforce) {
              return Salesforce.load().$promise;
            }
          },
          title: "salesforce"
        })
    })

    .controller('SalesforceCtrl',

      function (salesforce, Salesforce, notifyManager, $translate) {

        var self = this;

        self.salesforce = salesforce;

        self.save = function () {
          if (self.salesforceForm.$valid) {
            self.salesforce.$edit(function (data) {
              if (data.ok) {
                notifyManager.success($translate.instant('entity.edit.successfully'));
              }
              self.salesforce = Salesforce.load();
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        }

      }
    )

})();
