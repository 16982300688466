import React, {useEffect, useState} from 'react';
import axios from 'axios';
import _ from 'lodash';
import {
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import theme from "../../react_components/config.default.theme";
import {makeStyles} from "@material-ui/core/styles";
import * as yup from "yup";
import {useForm, Controller} from "react-hook-form";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MarkdownPreviewModal from '../js/MarkdownModal';

export default function EditNews({currentNews, handleSave, goToMainPage}) {

  const useStyles = makeStyles({
    formControl: {
      margin: theme.spacing(1),
      width: '100%',
    },
    logicBlock: {
      marginTop: 50,
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  });

  theme.palette.primary.main = '#000';

  const {t} = useTranslation();

  const defaultNews = {
    title: '',
    template: '',
    content: '',
    showTo: '',
    notShowTo: '',
    priority: 0,
    active: false,
    archive: false
  };

  const defaultShowTo = {
    country: [], contractType: [], operator: []
  };

  const defaultNotShowTo = {
    country: [], contractType: [], operator: []
  };

  const classes = useStyles();
  const [editedNews, setEditedNews] = useState(!_.isEmpty(currentNews) ? currentNews : defaultNews);
  const [editedNewsShowTo, setEditedNewsShowTo] = useState(!_.isEmpty(currentNews) ? currentNews.showTo : defaultShowTo);
  const [editedNewsNotShowTo, setEditedNewsNotShowTo] = useState(!_.isEmpty(currentNews) ? currentNews.notShowTo : defaultNotShowTo);
  const [countries, setCountries] = useState([]);
  const [contractTypes, setContractTypes] = useState([]);
  const [operators, setOperators] = useState([]);
  const [openMarkdownModal, setOpenMarkdownModal] = useState(false);

  const schema = yup.object().shape({
    title: yup.string().required(t('validation.requiredField')),
    template: yup.string().when('content', {
        is: (content) => !content,
        then: yup.string().required(t('validation.news.oneIsRequired'))
      }),
    content: yup.string().when('template', {
      is: (template) => !template,
      then: yup.string().required(t('validation.news.oneIsRequired'))
    })
  }, ['content', 'template']);

  const {handleSubmit, errors, setValue, control} = useForm({
    validationSchema: schema
  });

  //getting data for the page
  useEffect(() => {
    Promise
      .all([
        getCountries(),
        getContractTypes()
      ]).then(([countriesResp, contractResp]) => {
      setCountries(countriesResp.data);
      setContractTypes(contractResp.data);
    })
  }, []);

  const handleChange = event => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    setEditedNews({...editedNews, [event.target.name]: value});
    setValue(event.target.name, value, true);
  };

  const handleShowToChange = (name, event, value) => {
    setEditedNewsShowTo({...editedNewsShowTo, [name]: value});
  };

  const handleNotShowToChange = (name, event, value) => {
    setEditedNewsNotShowTo({...editedNewsNotShowTo, [name]: value});
  };

  useEffect(() => {
    setEditedNews({...editedNews, showTo: editedNewsShowTo, notShowTo: editedNewsNotShowTo});
  }, [editedNewsShowTo, editedNewsNotShowTo]);

  const onSubmitNewsForm = (data, event) => {
    handleSave(editedNews);
  };

  const handleOperatorChange = event => {
    getOperators(event.target.value).then(function (res) {
      setOperators(res.data.payload.operators)
    })
  };

  const previewMarkdown = () => {
    setOpenMarkdownModal(true);
  };

  const closeMarkdownModal = () => {
    setOpenMarkdownModal(false);
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={6}>
          <Button type="button" color='secondary' variant='contained'
                  onClick={goToMainPage}>{t('button.back')}</Button>
        </Grid>
        <Grid item xs={6} style={{textAlign: "right"}}>
          <Button type="button" color='secondary' variant='contained'
                  onClick={handleSubmit(onSubmitNewsForm)}>{t("button.save")}</Button>
        </Grid>
      </Grid>

      <form id='news-form'>
        <Grid container spacing={2}>
          <Grid item sm={2} xs={1} implementation="css"/>
          <Grid item sm={8} xs={10} container spacing={2}>
            <Grid item xs={12} className={classes.logicBlock}>
              <Typography
                component="h3"
                variant="h5"
                color="textSecondary"
              >
                {t('news')}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.logicBlock}>
              <FormControlLabel
                control={
                  <Switch
                    checked={editedNews.active}
                    name='active'
                    type="checkbox"
                    onChange={handleChange}
                    value="active"
                    color="secondary"
                    id='active'
                  />
                }
                label={t('news.isActive')}
              />
            </Grid>
            <Grid item xs={8}>
              <FormControl className={classes.formControl}>
                <Controller
                  as={TextField}
                  control={control}
                  id="title"
                  name="title"
                  label={t('news.title')}
                  defaultValue={editedNews.title || ''}
                  margin="normal"
                  error={!!errors.title}
                  onChange={([e]) => {
                    handleChange(e);
                    return e;
                  }}
                />
              </FormControl>
              {errors.title && <p style={theme.errorText}>{errors.title.message}</p>}
            </Grid>

            <Grid item xs={8}>
              <FormControl className={classes.formControl}>
                <Controller
                  as={TextField}
                  control={control}
                  id="template"
                  name="template"
                  label={t('news.template')}
                  defaultValue={editedNews.template || ''}
                  margin="normal"
                  error={!!errors.template && errors.content}
                  onChange={([e]) => {
                    handleChange(e);
                    return e;
                  }}
                />
              </FormControl>
              {(errors.template && errors.content) && <p style={theme.errorText}>{errors.template.message}</p>}
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <TextField
                  id="priority"
                  name="priority"
                  label={t('news.priority')}
                  value={editedNews.priority}
                  margin="normal"
                  onChange={handleChange}
                  inputProps={{type: 'number'}}
                />
              </FormControl>
            </Grid>
            <Grid item xs={10}>
              <FormControl className={classes.formControl}>
                <Controller
                  as={TextField}
                  control={control}
                  id="content"
                  name="content"
                  label={t('news.content')}
                  defaultValue={editedNews.content || ''}
                  margin="normal"
                  multiline
                  error={!!errors.content && errors.template}
                  onChange={([e]) => {
                    handleChange(e);
                    return e;
                  }}
                />
              </FormControl>
              {(errors.content && errors.template) && <p style={theme.errorText}>{errors.content.message}</p>}
            </Grid>
            <Grid item xs={2} className={classes.buttonWrapper}>

                <Button type="button" color='secondary' variant='contained'
                        onClick={previewMarkdown}>Preview</Button>

            </Grid>
              {openMarkdownModal &&
                <MarkdownPreviewModal open={openMarkdownModal} content={editedNews.content} handleClose={closeMarkdownModal}/>
              }
            <Grid item xs={12}>
              <Typography
                component="h3"
                variant="h5"
                color="textSecondary"
                className={classes.logicBlock}
              >
                {t('news.showNewsTo')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="country"
                  options={countries}
                  name="country"
                  getOptionLabel={option => option.name}
                  defaultValue={!_.isEmpty(currentNews) && currentNews.showTo.country.length ? currentNews.showTo.country : []}
                  onChange={(event, value) => handleShowToChange('country', event, value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('country')}
                      placeholder={t('country')}
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="contractType"
                  options={contractTypes}
                  name="contractType"
                  getOptionLabel={option => option.name}
                  defaultValue={!_.isEmpty(currentNews) && currentNews.showTo.contractType.length ? currentNews.showTo.contractType : []}
                  onChange={(event, value) => handleShowToChange('contractType', event, value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('operatorContractType')}
                      placeholder={t('operatorContractType')}
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="operator"
                  options={operators}
                  name="operator"
                  getOptionLabel={option => option.name}
                  defaultValue={!_.isEmpty(currentNews) && currentNews.showTo.operator.length ? currentNews.showTo.operator : []}
                  onChange={(event, value) => handleShowToChange('operator', event, value)}
                  onSelect={handleOperatorChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('operator')}
                      placeholder={t('operator')}
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography
                component="h3"
                variant="h5"
                color="textSecondary"
                className={classes.logicBlock}
              >
                {t('news.notShowNewsTo')}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="hide_for_country"
                  options={countries}
                  name="country"
                  getOptionLabel={option => option.name}
                  defaultValue={!_.isEmpty(currentNews) && currentNews.notShowTo.country.length ? currentNews.notShowTo.country : []}
                  onChange={(event, value) => handleNotShowToChange('country', event, value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('country')}
                      placeholder={t('country')}
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="hide_for_contract_type"
                  options={contractTypes}
                  name="contractType"
                  getOptionLabel={option => option.name}
                  defaultValue={!_.isEmpty(currentNews) && currentNews.notShowTo.contractType.length ? currentNews.notShowTo.contractType : []}
                  onChange={(event, value) => handleNotShowToChange('contractType', event, value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('operatorContractType')}
                      placeholder={t('operatorContractType')}
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.formControl}>
                <Autocomplete
                  multiple
                  id="hide_for_operator"
                  options={operators}
                  name="operator"
                  getOptionLabel={option => option.name}
                  defaultValue={!_.isEmpty(currentNews) && currentNews.notShowTo.operator.length ? currentNews.notShowTo.operator : []}
                  onChange={(event, value) => handleNotShowToChange('operator', event, value)}
                  onSelect={handleOperatorChange}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant="standard"
                      label={t('operator')}
                      name="operator"
                      placeholder={t('operator')}
                      fullWidth
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.logicBlock}>
            <Grid item xs={6}>
              <Button type="button" color='secondary' variant='contained'
                      onClick={goToMainPage}>{t('button.back')}</Button>
            </Grid>
            <Grid item xs={6} style={{textAlign: "right"}}>
              <Button type="button" color='secondary' variant='contained'
                      onClick={handleSubmit(onSubmitNewsForm)}>{t("button.save")}</Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

function getContractTypes() {
  return axios.get('/icash/admin/news/contract_type')
}

function getCountries() {
  return axios.get('/icash/admin/news/country')
}

function getOperators(value) {
  return axios.get('/icash/admin/in_app_message/autocomplete/operator?term=' + value)
}
