(function () {
  'use strict';
  angular.module('gf.bm.printer', ['gf.bm.groupView'])

    .factory('BMPrinterMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/printer/metadata.json')
    })
    .factory('BMPrinterContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/printer/context.json')
    })
    .factory('BMPrinterRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.hardwarePrinter', {
          url: '/hardware/printer',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMMasterdataPrinter',
          controllerAs: '$ctrl',
          resolve: {
            bmPrinterMetadata: function (BMPrinterMetadata, $stateParams, securityService) {
              return BMPrinterMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmPrinterContext: function (BMPrinterContext, $stateParams, securityService) {
              return BMPrinterContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmPrinterRules: function (BMPrinterRules, $stateParams, securityService) {
              return BMPrinterRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "masterdataPrinter"
        })
    })

    .controller('BMMasterdataPrinter',
      function (bmPrinterMetadata, bmPrinterContext, securityService, $stateParams, bmPrinterRules) {
        var self = this;

        self.metadata = bmPrinterMetadata;
        self.context = bmPrinterContext;
        self.masterdataRules = bmPrinterRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name'},
            {name: 'ipAddress', displayName: 'printer.host'},
            {name: 'printerDriver', enableCellEdit: false},
            {name: 'tcpPort', enableCellEdit: false},
            {name: 'udpPort', enableCellEdit: false},
            {name: 'description', enableCellEdit: false}
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/printer';
          }
        };

        self.permissionsConfig = {
          columnsPermissions: {
            'ipAddress': {
              editPermissions: ['BM_PRINTER_CHANGE_IP']
            }
          }
        }

      }
    )

})();
