import angular from 'angular';
import _ from 'lodash';

angular.module('gastrofix')

  .service('featureTableService', function ($translate) {
    var self = this;

    self.isDisabled = function (feature, options) {
      if (featureRules[feature.name] && _.isFunction(featureRules[feature.name].isDisabled)) {
        return featureRules[feature.name].isDisabled(feature, options);
      }
    };

    self.tooltipText = function (feature) {
      if (featureRules[feature.name] && _.isFunction(featureRules[feature.name].tooltipText)) {
        return featureRules[feature.name].tooltipText();
      }
    };

    self.tooltipTrigger = function (feature, options) {
      if (featureRules[feature.name] && _.isFunction(featureRules[feature.name].tooltipTrigger)) {
        return featureRules[feature.name].tooltipTrigger(feature, options);
      }
    };

    self.isHidden = function (feature, options) {
      if (featureRules[feature.name] && _.isFunction(featureRules[feature.name].isHidden)) {
        return featureRules[feature.name].isHidden(feature, options);
      }
    };

    var featureRules = {
      'POS_LEDGER': {
        isDisabled: function (feature, options) {
          if (!options.restaurant) return false;
          var exchangeRates = _.filter(options.restaurant.exchangeRates, function (o) {
            return !o.deleted;
          });
          return exchangeRates.length > 0;
        },
        tooltipText: function () {
          return $translate.instant('posLedger.feature.cantBeActivated');
        },
        tooltipTrigger: function (feature, options) {
          return this.isDisabled(feature, options);
        },
        isHidden: function (feature, options) {
          return options.isOperatorMode;
        }
      },
      'BRANCH_MANAGEMENT': {
        isHidden: function (feature, options) {
          return !options.isOperatorMode;
        }
      },
      'GTC': {
        isHidden: function (feature, options) {
          return !options.isOperatorMode;
        }
      },
      'EPSON_TSE': {
        isHidden: (feature, options) => options.isOperatorMode,
        isDisabled: (feature, options) => isEnabledOtherTSEFeature(options.restaurant.features, 'EPSON_TSE')
      },
      'CLOUD_TSE': {
        isHidden: (feature, options) => options.isOperatorMode,
        isDisabled: (feature, options) => isEnabledOtherTSEFeature(options.restaurant.features, 'CLOUD_TSE'),
        tooltipText: function () {
          return $translate.instant('tse.cloud.feature.activationText');
        },
        tooltipTrigger: function (feature, options) {
          return !options.restaurant.live;
        }
      }
    }
  });

const TSE_FEATURES = ['EPSON_TSE', 'CLOUD_TSE'];

function isEnabledOtherTSEFeature(features, tseFeature) {
  return !_.isUndefined(
    features.find(feature =>
      feature.name !== tseFeature && TSE_FEATURES.includes(feature.name)
    )
  );
}
