/*get security context and save it to constant before starting the application*/
(function () {
  var initInjector = angular.injector(['ng', 'ngResource']);
  var $http = initInjector.get('$http');
  var $resource = initInjector.get('$resource');
  var $q = initInjector.get('$q');
  var SecurityContext = $resource('/icash/util/security.json');

  SecurityContext.get().$promise
    .then(function(securityContext) {

      $q.all({
        info: $http.get('/icash/info')
      }).then(function (response) {

        angular.module('gf.security').constant('SecurityContext', securityContext);
        angular.module('gf.security').constant('Info', response.info.data);

        angular.element(document).ready(function () {
          angular.bootstrap(document, ['gastrofix']);
        });
      })

    });

})();
