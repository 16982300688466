import Highcharts from 'highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import highcharts3d from 'highcharts/highcharts-3d'
import highchartsModulesExporting from 'highcharts/modules/exporting'
import highchartsSunburst from'highcharts/modules/sunburst'

highchartsMore(Highcharts);
highcharts3d(Highcharts);
highchartsModulesExporting(Highcharts);
highchartsSunburst(Highcharts);

import 'jquery'
import 'angular'
import 'angular-animate'
import 'angular-ui-bootstrap'
import 'angular-bootstrap-colorpicker'
import 'angular-bootstrap-colorpicker/css/colorpicker.css'
import 'bootstrap-switch'
import 'bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css'
import 'angular-bootstrap-switch'
import 'angular-cookies'
import 'angular-dragdrop'
import 'angular-dynamic-locale'
import 'angular-loader'
import 'angular-loading-bar'
import 'angular-loading-bar/src/loading-bar.css'
import 'angular-local-storage'
import 'angular-mocks'
import 'angular-moment'
import 'angular-resource'
import 'angular-route'
import 'angular-sanitize'
import 'angular-toggle-switch'
import 'angular-touch'
import 'angular-translate'
import 'angular-translate-interpolation-messageformat'
import 'angular-translate-loader-static-files'
import 'angular-dialog-service'
import 'angular-tree-control'
import 'angular-tree-control/css/tree-control.css'
import 'angular-tree-control/context-menu'
import 'angular-ui-grid'
import 'angular-ui-grid/ui-grid.css'
import 'angular-ui-sortable'
import 'angulartics'
import 'angulartics-google-analytics'
import 'bootstrap-hover-dropdown'
import 'bootstrap-sass'
import 'cropper'
import 'eonasdan-bootstrap-datetimepicker'
import 'highcharts-ng'
import 'jquery-mousewheel'
import 'jquery-ui/ui/widgets/sortable'
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/widgets/droppable'
import 'moment'
import 'ng-file-upload'
import 'ng-onload/lib/ng-onload'
import 'oi.select'
import 'perfect-scrollbar'
import 'angular-ui-router'
import 'ui-select'
import 'ui-select/dist/select.css'
import 'blueimp-canvas-to-blob'
import 'angular-ui-tour'
import 'ngclipboard'
import 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'angular-simple-logger'
import 'ui-leaflet'
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers'
import 'leaflet.awesome-markers/dist/leaflet.awesome-markers.css'
import 'ui-grid-draggable-rows'

import './../main/angular/app/plugins/angularjs-dropdown-multiselect/angularjs-dropdown-multiselect'
import './../main/angular/app/plugins/jquery-svg/jquery.svg'
import './../main/angular/app/plugins/jquery-svg/jquery.svgdom'
import './../main/angular/app/plugins/osm-nominatim'
import './../main/angular/app/plugins/jquery.ui.touch-punch'
import './../main/angular/app/plugins/ui-grid-edit-datepicker'
import './../components/plugin-zweather/js/plugins/jquery.zweatherfeed_localized'

import './../main/angular/app/components/appInit'
import './../main/angular/app/components/app'
import './../main/angular/app/components/controller/menu_conf'
import './../main/angular/app/components/controller/controllers'
import './../main/angular/app/components/controller/customer'
import './../main/angular/app/components/controller/error'
import './../main/angular/app/components/controller/hardware'
import './../main/angular/app/components/controller/login'
import './../main/angular/app/components/controller/masterdata'
import './../main/angular/app/components/controller/operator_data'
import './../main/angular/app/components/controller/password_recovery'
import './../main/angular/app/components/controller/setup'
import './../main/angular/app/components/controller/unsubscribe'
import './../main/angular/app/components/controller/financial/financial'
import './../main/angular/app/components/controller/financial/tax'
import './../main/angular/app/components/services/cropper'
import './../main/angular/app/components/services/utils'

import './../main/angular/app/components/service/services'
import './../main/angular/app/components/service/provide-email-popup/provideEmailPopup'
import './../main/angular/app/components/service/feature-state-service'
import './../main/angular/app/components/service/gf-config-options'
import './../main/angular/app/components/service/gf-request-handlers'
import './../main/angular/app/components/service/plu_service'
import './../main/angular/app/components/service/reportsHelper'
import './../main/angular/app/components/service/resources'
import './../main/angular/app/components/directive/directives'
import './../main/angular/app/components/directive/dnd'
import './../main/angular/app/components/directive/grid'
import './../main/angular/app/components/directive/validation'
import './../main/angular/app/components/vendor-fixes/uib-datepicker'
import './../main/angular/app/components/common-config'

import './../components/gf-context-header/js/gf-context-header'
import './../components/gf-hardware-printer/js/gf-hardware-printer'
import './../components/gf-hardware-device/js/gf-hardware-device'
import './../components/gf-operator-data-trash/js/gf-operator-data-trash'
import './../components/gf-tour/js/gf-tour'
import './../components/gf-masterdata-waiter/js/gf-masterdata-waiter'
import './../components/gf-setup-history/js/gf-setup-history'
import './../components/gf-icons-library/js/gf-icons-library'
import './../components/gf-masterdata-ticket-layout/js/gf-masterdata-ticket-layout'
import './../components/gf-hardware-ticket-layout/js/gf-hardware-ticket-layout'
import './../components/gf-hardware-payment-device/js/gf-hardware-payment-device'
import './../components/gf-masterdata-article/js/gf-masterdata-article'
import './../components/gf-masterdata-article/js/gf-masterdata-constraints'
import './../components/gf-setup-rksv/js/gf-setup-rksv'
import './../components/gf-operator-data-user/js/gf-operator-data-user'
import './../components/gf-operator-data-user-group/js/gf-operator-data-user-group'
import './../components/gf-operator-data-operator-settings/js/gf-operator-data-operator-settings'
import './../components/gf-masterdata-course/js/gf-masterdata-course'
import './../components/gf-financial-vat/js/gf-financial-vat'
import './../components/gf-financial-cost-center/js/gf-financial-cost-center'
import './../components/gf-dashboard/js/gf-dashboard'
import './../components/gf-main-menu/js/gf-main-menu'
import './../components/gf-report/js/gf-report'
import './../components/gf-report/js/gf-report-config'
import './../components/gf-report/js/gf-large-amount-bills'
import './../components/gf-report/js/gf-report-popup-filter'
import './../components/gf-report-running/js/gf-report-running'
import './../components/gf-masterdata-inventory/js/gf-materdata-inventory'
import './../components/gf-financial-settlement-form/js/gf-financial-settlement-form'
import './../components/gf-operator-data-restaurant-group/js/gf-operator-data-restaurant-group'
import './../components/gf-setup-exchange-rate/js/gf-setup-exchange-rate'
import './../components/gf-setup-article-type/js/gf-setup-article-type'
import './../components/gf-financial-account/js/gf-financial-account'
import './../components/gf-setup-restaurant/js/gf-setup-restaurant'
import './../components/gf-setup-table/js/gf-setup-table'
import './../components/gf-masterdata-article-group/js/gf-masterdata-article-group'
import './../components/gf-features/js/features'
import './../components/gf-operator-data-salesforce/js/gf-operator-data-salesforce'
import './../components/gf-main-menu-partner/js/gf-partner-menu'
import './../components/gf-financial-discount/js/gf-financial-discount'
import './../components/gf-common-filters/js/gf-common-filters'
import './../components/gf-branch-management/js/entities/gf-bm-account'
import './../components/gf-branch-management/js/entities/gf-bm-article'
import './../components/gf-branch-management/js/entities/gf-bm-article-group'
import './../components/gf-branch-management/js/entities/gf-bm-cost-center'
import './../components/gf-branch-management/js/entities/gf-bm-course'
import './../components/gf-branch-management/js/entities/gf-bm-discount'
import './../components/gf-branch-management/js/entities/gf-bm-order-screen'
import './../components/gf-branch-management/js/entities/gf-bm-price-level'
import './../components/gf-branch-management/js/entities/gf-bm-printer'
import './../components/gf-branch-management/js/entities/gf-bm-service-period'
import './../components/gf-branch-management/js/entities/gf-bm-settlement-form'
import './../components/gf-branch-management/js/entities/gf-bm-setup-article-type'
import './../components/gf-branch-management/js/entities/gf-bm-ticket-layout'
import './../components/gf-branch-management/js/entities/gf-bm-vat'
import './../components/gf-branch-management/js/entities/gf-bm-waiter'
import './../components/gf-branch-management/js/entities/gf-bm-waiter-group'
import './../components/gf-branch-management/js/grid/gf-bm-rules-grid'
import './../components/gf-branch-management/js/grid/gf-bm-metadata'
import './../components/gf-branch-management/js/grid/gf-bm-permissions'
import './../components/gf-branch-management/js/grid/gf-bm-rule-manager'
import './../components/gf-branch-management/js/grid/gf-bm-validation'
import './../components/gf-branch-management/js/bm-user-permissions'
import './../components/gf-branch-management/js/gf-bm-article-template'
import './../components/gf-branch-management/js/gf-bm-dashboard'
import './../components/gf-branch-management/js/gf-bm-group-management'
import './../components/gf-branch-management/js/gf-bm-group-view'
import './../components/gf-branch-management/js/gf-bm-menu'
import './../components/gf-branch-management/js/gf-bm-report'
import './../components/gf-branch-management/js/gf-bm-main'
import './../components/gf-financial-service-period/js/gf-financial-service-period'
import './../components/gf-tax-audit/js/gf-tax-audit'
import './../components/gf-financial-price-level/js/gf-financial-price-level'
import './../components/gf-context-header/js/gf-context-header'
import './../components/gf-financial-reasons/js/gf-financial-reasons'
import './../components/gf-open-api/js/gf-open-api'
import './../components/react_components/react_directive'
import '../components/gf-news/js/news-modal-component'
import '../components/gf-financial-services/js/gf-financial-services'


import (`./../scss/themes/${THEME}.scss`);
