(function () {
  'use strict';
  angular.module('gf.masterdata.waiter', ['ui.router'])
    .factory('WaiterMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/waiter/metadata.json')
    })
    .factory('WaiterContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/waiter/context.json')
    })
    .factory('Waiter', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/masterdata/waiter/:waiterId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.masterdataWaiter', {
          url: '/masterdata/waiter',
          template: require('../template/masterdata/waiter/waiters.html'),
          controller: 'WaiterGridCtrl',
          controllerAs: 'waiterGridCtrl',
          resolve: {
            waiterMetadata: function (WaiterMetadata) {
              return WaiterMetadata.get().$promise;
            },
            waiterContext: function (WaiterContext) {
              return WaiterContext.get().$promise;
            },
            waiter: function (Waiter, $stateParams) {
              return Waiter.load({waiterId: $stateParams.id || "add"}).$promise;
            },
            currentTour: function (Tour) {
              return Tour.get({page: 'masterdataWaiter'}).$promise;
            }
          },
          title: "waiter"
        })

        .state('page.masterdataWaiterAdd', {
          url: '/masterdata/waiter/add',
          template: require('../template/masterdata/waiter/waiter.html'),
          controller: 'WaiterAddCtrl',
          controllerAs: 'waiterEditCtrl',
          resolve: {
            waiterMetadata: function (WaiterMetadata) {
              return WaiterMetadata.get().$promise;
            },
            waiterContext: function (WaiterContext) {
              return WaiterContext.get().$promise;
            },
            waiter: function (Waiter, $stateParams) {
              return Waiter.load({waiterId: $stateParams.id || "add"}).$promise;
            }
          },
          title: "waiter"
        })

        .state('page.masterdataWaiterDetails', {
          url: '/masterdata/waiter/:id',
          template: require('../template/masterdata/waiter/waiter.html'),
          controller: 'WaiterEditCtrl',
          controllerAs: 'waiterEditCtrl',
          resolve: {
            waiterMetadata: function (WaiterMetadata) {
              return WaiterMetadata.get().$promise;
            },
            waiterContext: function (WaiterContext) {
              return WaiterContext.get().$promise;
            },
            waiter: function (Waiter, $stateParams) {
              return Waiter.load({waiterId: $stateParams.id || "add"}).$promise;
            }
          },
          title: "waiter"
        })
    })

    .controller('WaiterGridCtrl',

      function ($uibModal, $resource, appConfig, waiterContext, waiterMetadata, currentTour, tourService, configOptionsService) {
        var self = this;

        self.currentTour = currentTour;

        self.metadata = waiterMetadata;
        self.context = waiterContext;

        var maxNumber = waiterContext.data.maxNumber;

        var actions = [
          {
            name: 'deleteWaiter',
            template: 'app/template/grid/delete_action.html',
            controller: 'RemoveRowWaiterCtrl',
            resolve: {
              EntityResource: $resource(appConfig.baseUrl + "/icash/masterdata/waiter/:id.json", {}, {})
            }
          },
          {
            name: 'enterPassword',
            template: 'app/template/grid/change_password.html',
            controller: 'ChangePasswordAction'
          }
        ];

        var cellClass = function (grid, row, col, rowRenderIndex, colRenderIndex) {
          if (row && row.entity && row.entity.id && row.entity.safe && !row.entity.password) {
            return 'deactivated-entity';
          }
        };

        self.config = {
          urlRoute: '/masterdata/waiter',
          enableSorting: true,
          enableColumnMoving: true,
          enableFiltering: true,
          enableColumnResizing: true,
          enableAdd: true,
          enableAddRow: true,
          registerActions: actions,
          cellEditableCondition: function ($scope) {
            return !$scope.row.entity.safe;
          },
          canAdd: function (grid) {
            if (maxNumber == 0) {
              return true;
            }

            var addRow = _.find(grid.rows, function (row) {
              return row.entity.id == null;
            });

            var length = grid.options.data.length;

            length = !addRow || addRow.entity.firstName && addRow.entity.lastName && addRow.entity.number && addRow.entity.loginPin && addRow.entity.groups ? length : length - 1;

            return length < maxNumber;
          },
          columns: [
            {
              name: 'id', width: 60, enableCellEdit: false,
              enableHiding: true, visible: false, cellFilter: 'safeFilter:row.entity', cellClass: cellClass,
              cellTemplate: "<div uib-tooltip=\"{{row.entity.safe && !row.entity.password ?  ('waiter.safe.specify.password' | translate) : ''}}\" tooltip-append-to-body=\"false\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD CUSTOM_FILTERS}}</div>"
            },
            {
              name: 'firstName', enableHiding: true,
              headerCellTemplate: 'app/template/tour/header_cell_tour.html', cellClass: cellClass,
              cellTemplate: "<div uib-tooltip=\"{{row.entity.safe && !row.entity.password ?  ('waiter.safe.specify.password' | translate) : ''}}\" tooltip-append-to-body=\"false\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD CUSTOM_FILTERS}}</div>"
            },
            {
              name: 'lastName', enableHiding: true, cellClass: cellClass,
              cellTemplate: "<div uib-tooltip=\"{{row.entity.safe && !row.entity.password ?  ('waiter.safe.specify.password' | translate) : ''}}\" tooltip-append-to-body=\"false\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD CUSTOM_FILTERS}}</div>"
            },
            {
              name: 'number', enableHiding: true, width: 140,
              validations: {
                'gf-uniquewaiternumber': {
                  'angular': {'gf-uniquewaiternumber': ''},
                  'errorMessage': 'de.icash.validator.UniquePersonalNumber.message'
                }
              },
              headerCellTemplate: 'app/template/tour/header_cell_tour.html',
              cellClass: cellClass,
              cellTemplate: "<div uib-tooltip=\"{{row.entity.safe && !row.entity.password ?  ('waiter.safe.specify.password' | translate) : ''}}\" tooltip-append-to-body=\"false\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD CUSTOM_FILTERS}}</div>"
            },
            {
              name: 'loginPin', enableHiding: true, width: 100,
              cellFilter: 'loginPinFilter|safeFilter:row.entity', filterCellFiltered: true,
              validations: {
                'gf-uniquewaiterloginpin': {
                  'angular': {'gf-uniquewaiterloginpin': ''},
                  'errorMessage': 'de.icash.validator.UniqueLoginPin.message'
                }
              },
              headerCellTemplate: 'app/template/tour/header_cell_tour.html', cellClass: cellClass,
              cellTemplate: "<div uib-tooltip=\"{{row.entity.safe && !row.entity.password ?  ('waiter.safe.specify.password' | translate) : ''}}\" tooltip-append-to-body=\"false\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD CUSTOM_FILTERS}}</div>"
            },
            {
              name: 'groups',
              enableHiding: true,
              width: 110,
              multiselect: true,
              headerCellTemplate: 'app/template/tour/header_cell_tour.html',
              cellClass: cellClass,
              cellTemplate: "<div uib-tooltip=\"{{row.entity.safe && !row.entity.password ?  ('waiter.safe.specify.password' | translate) : ''}}\" tooltip-append-to-body=\"false\" class=\"ui-grid-cell-contents\" title=\"TOOLTIP\">{{COL_FIELD CUSTOM_FILTERS}}</div>"
            },
            {
              name: 'operate', actions: ['edit', 'deleteWaiter'], alternativeActions: ['enterPassword'],
              showAlternativeActions: function (entity) {
                return entity.safe;
              },
              actionOptions: {
                'delete': {
                  isDisabled: configOptionsService.get('waiterDeleteButton').isDisabled
                }
              }
            }
          ],
          onRegisterApi: function () {
            tourService.startDetachedTour('masterdataWaiter', currentTour);
          }
        };
      }
    )
    .filter('loginPinFilter', function () {
      return function (input) {

        if (!input && input != 0) {
          return "";
        }

        var strValue = input.toString();
        return "0000".slice(strValue.length) + strValue;
      };
    })
    .filter('safeFilter', function () {
      return function (input, entity) {
        return !!entity && entity.safe ? "" : input;
      };
    })

    .controller('ChangePasswordAction',
      function ($scope, $uibModal, $translate, $resource, appConfig, notifyManager, Waiter, uiGridConstants, gridApi, entity) {

        var modalDialog = $uibModal.open({
          template: require('../template/masterdata/waiter/change_password.html'),
          windowClass: 'gf-modal',
          controller: 'ChangePasswordActionDialogCtrl',
          scope: $scope,
          resolve: {
            metadata: function () {
              var resource = $resource(appConfig.baseUrl + '/icash/masterdata/waiter/metadata.json');
              return resource.get().$promise;
            },
            id: function () {
              return entity.id;
            }
          }
        });

        modalDialog.result.then(function (data) {
          if (!!data.password) {
            entity.password = data.password;
          }
          if (!!data.loginPin) {
            entity.loginPin = data.loginPin;
          }
          var waiter = new Waiter(entity);


          waiter.$edit({waiterId: entity.id}, function (data) {
            notifyManager.success($translate.instant('entity.save.successfully'));
            gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
          });
        });
      }
    )
    .controller('ChangePasswordActionDialogCtrl',

      function ($scope, metadata, $uibModalInstance, id) {

        $scope.metadata = metadata;
        $scope.metadata.columns['loginPin'].angular = {min: 1, max: 9999};

        $scope.id = id;

        $scope.cancel = function () {
          $uibModalInstance.dismiss('cancel');
        };

        $scope.change = function () {
          if ($scope.cpf.$valid) {
            $uibModalInstance.close({password: $scope.password, loginPin: $scope.loginPin});
          }
        }
      }
    )
    .controller('RemoveRowWaiterCtrl', ['$scope', '$uibModal', '$translate', 'notifyManager', 'entity', 'gridApi', 'EntityResource', 'WaterValidateBalance', '$resource',

      function ($scope, $uibModal, $translate, notifyManager, entity, gridApi, EntityResource, WaterValidateBalance, $resource) {
        var entityValidateResource =
          $resource(
            WaterValidateBalance,
            {},
            {$validate: {method: 'GET'}}
          );

        entityValidateResource.$validate({waiterId: entity.id}, function (data) {
          if (data.ok) {
            $uibModal.open({
              template: require('../../../main/angular/app/template/grid/delete_confirmation.html'),
              scope: $scope,
              windowClass: 'gf-modal'
            }).result.then(function () {

              var entityResource = new EntityResource(entity);

              entityResource.$delete({id: entity.id}, function (data) {
                if (data.ok) {
                  gridApi.grid.options.deleteRow(entity);
                  notifyManager.info($translate.instant('grid.entriesDeleted', {number: 1}));
                } else {
                  notifyManager.error($translate.instant(data.message));
                  if (data.message.toLowerCase().indexOf("not_exists") > -1) {
                    gridApi.grid.options.reloadData();
                  }
                }
              });
            });
          } else {
            $uibModal.open({
              template: require('../../../main/angular/app/template/info_modal.html'),
              scope: $scope,
              windowClass: 'gf-modal',
              controller: function($scope) {
                if (data.payload.isOpenGastronomicDay) {
                  $scope.message = 'waiter.delete.open.gd';
                } else {
                  $scope.message = 'waiter.delete.validation';
                }
              }
            });
          }

        });


      }])

    .factory('WaiterAdd', [
      function () {
        return function (self, $scope, $translate, $state, notifyManager, waiter,
                         waiterContext, waiterMetadata) {

          self.waiterContext = waiterContext;
          self.waiterMetadata = waiterMetadata;
          self.waiter = waiter;

          angular.extend(self, waiterContext.data.dependencies);

          self.save = function () {
            if (self.waiterForm.$valid) {
              if (!self.waiter.groups.length) {
                notifyManager.error($translate.instant('waiter.save.failed.chooseGroup'));
                return;
              }
              self.waiter.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go("page.masterdataWaiter");
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };
          self.saveAndNew = function () {
            if (self.waiterForm.$valid) {
              if (!self.waiter.groups.length) {
                notifyManager.error($translate.instant('waiter.save.failed.chooseGroup'));
                return;
              }
              self.waiter.$save(function (data) {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.reload();
              });
            } else {
              $scope.$broadcast("gf-submitted");
            }
          };

          self.isDisabledSaveAndNext = function () {
            return self.waiterContext.data.maxNumber && self.waiterContext.data.totalNumber >= (self.waiterContext.data.maxNumber - 1);
          };

        }
      }
    ])
    .controller('WaiterAddCtrl',

      function ($scope, $translate, $state, notifyManager, waiter, waiterContext,
                waiterMetadata, WaiterAdd) {
        var self = this;

        WaiterAdd(self, $scope, $translate, $state, notifyManager, waiter, waiterContext,
          waiterMetadata);
      }
    )
    .controller('WaiterEditCtrl',

      function ($scope, $translate, $state, notifyManager, waiter, waiterContext,
                waiterMetadata, WaiterAdd) {
        var self = this;


        WaiterAdd(self, $scope, $translate, $state, notifyManager, waiter, waiterContext,
          waiterMetadata);

        self.save = function () {
          if (self.waiterForm.$valid) {
            if (!self.waiter.groups.length) {
              notifyManager.error($translate.instant('waiter.save.failed.chooseGroup'));
              return;
            }
            self.waiter.$edit({waiterId: self.waiter.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go("page.masterdataWaiter");
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };

        self.saveAndNew = function () {
          if (self.waiterForm.$valid) {
            if (!self.waiter.groups.length) {
              notifyManager.error($translate.instant('waiter.save.failed.chooseGroup'));
              return;
            }
            self.waiter.$edit({waiterId: self.waiter.id}, function (data) {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go("page.masterdataWaiterAdd");
            });
          } else {
            $scope.$broadcast("gf-submitted");
          }
        };
        self.isDisabledSaveAndNext = function () {
          return self.waiterContext.data.maxNumber && self.waiterContext.data.totalNumber >= self.waiterContext.data.maxNumber;
        };
      }
    );

  //.controller('WaiterChangePasswordCtrl', ["$scope", "$uibModalInstance", "$resource", "$route",
  //    function ($scope, $uibModalInstance, $resource, $route) {
  //        var routeParams = $route.current.params;
  //        var ChangePassword = new $resource("/icash/masterdata/waiter/:waiterId/changePassword", {waiterId: routeParams.waiterId});
  //        $scope.changePassword = new ChangePassword({});
  //
  //        $scope.ok = function () {
  //            $uibModalInstance.close();
  //        };
  //
  //        $scope.cancel = function () {
  //            $uibModalInstance.dismiss('cancel');
  //        };
  //
  //        $scope.change = function () {
  //            $scope.changePassword.$save(
  //                function (data) {
  //                    if (data.payload.result) {
  //                        $uibModalInstance.close(data.payload.password);
  //                    } else {
  //                        $uibModalInstance.close();
  //                    }
  //                });
  //        }
  //
  //    }
  //])

})();
