(function () {
  'use strict';
  angular.module('gf.financial.servicePeriod', ['ui.router'])

    .factory('ServicePeriodMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/service_period/metadata.json')
    })
    .factory('ServicePeriodContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/service_period/context.json')
    })
    .factory('ServicePeriod', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/financial/service_period/:servicePeriodId.json', {}, {
        load: {method: 'GET'},
        edit: {method: 'PUT'}
      });
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('page.financialService_period', {
          url: '/financial/service_period',
          template: require('../template/financial/service-period/service_periods.html'),
          controller: 'ServicePeriodGridCtrl',
          controllerAs: '$ctrl',
          resolve: {
            servicePeriodMetadata: function (ServicePeriodMetadata) {
              return ServicePeriodMetadata.get().$promise;
            },
            servicePeriodContext: function (ServicePeriodContext) {
              return ServicePeriodContext.get().$promise;
            }
          },
          title: 'servicePeriod'
        })
        .state('page.financialService_periodAdd', {
          url: '/financial/service_period/add',
          template: require('../template/financial/service-period/service_period.html'),
          controller: 'ServicePeriodAddCtrl',
          controllerAs: '$ctrl',
          resolve: {
            servicePeriodMetadata: function (ServicePeriodMetadata) {
              return ServicePeriodMetadata.get().$promise;
            },
            servicePeriodContext: function (ServicePeriodContext) {
              return ServicePeriodContext.get().$promise;
            },
            servicePeriod: function (ServicePeriod, $stateParams) {
              return ServicePeriod.load({servicePeriodId: $stateParams.id || "add"}).$promise;
            }
          },
          title: 'servicePeriod'
        })
        .state('page.financialService_periodDetails', {
          url: '/financial/service_period/:id',
          template: require('../template/financial/service-period/service_period.html'),
          controller: 'ServicePeriodEditCtrl',
          controllerAs: '$ctrl',
          resolve: {
            servicePeriodMetadata: function (ServicePeriodMetadata) {
              return ServicePeriodMetadata.get().$promise;
            },
            servicePeriodContext: function (ServicePeriodContext) {
              return ServicePeriodContext.get().$promise;
            },
            servicePeriod: function (ServicePeriod, $stateParams) {
              return ServicePeriod.get({servicePeriodId: $stateParams.id || "add"}).$promise;
            }
          },
          title: 'servicePeriod'
        })

    })

    .factory('ServicePeriodAdd',
      function ($translate, $state, notifyManager, configOptionsService) {
        return function (self, $scope, servicePeriod, servicePeriodContext, servicePeriodMetadata) {

          self.servicePeriodContext = servicePeriodContext;
          self.servicePeriodMetadata = servicePeriodMetadata;
          self.servicePeriod = servicePeriod;

          self.isActivable = !configOptionsService.get('servicePeriodActivateButton').isDisabled();

          self.save = function () {
            if (self.servicePeriodForm.$valid) {
              self.servicePeriod.$save(function () {
                notifyManager.success($translate.instant('entity.save.successfully'));
                $state.go('page.financialService_period')
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
          self.saveAndNew = function () {
            if (self.servicePeriodForm.$valid) {
              self.servicePeriod.$save(function () {
                notifyManager.success($translate.instant('entity.save.successfully'));
                self.reload();
              });
            } else {
              $scope.$broadcast('gf-submitted');
            }
          };
        }
      }
    )
    .controller('ServicePeriodAddCtrl',

      function ($scope, servicePeriod, servicePeriodContext, servicePeriodMetadata, ServicePeriodAdd) {
        var self = this;
        ServicePeriodAdd(self, $scope, servicePeriod, servicePeriodContext, servicePeriodMetadata);
      }
    )
    .controller('ServicePeriodEditCtrl',

      function ($scope, $translate, $state, notifyManager, servicePeriod, servicePeriodContext, servicePeriodMetadata,
                ServicePeriodAdd) {

        var self = this;
        ServicePeriodAdd(self, $scope, servicePeriod, servicePeriodContext, servicePeriodMetadata);

        self.save = function () {
          if (self.servicePeriodForm.$valid) {
            self.servicePeriod.$edit({servicePeriodId: self.servicePeriod.id}, function () {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.financialService_period')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
        self.saveAndNew = function () {
          if (self.servicePeriodForm.$valid) {
            self.servicePeriod.$edit({servicePeriodId: self.servicePeriod.id}, function () {
              notifyManager.success($translate.instant('entity.edit.successfully'));
              $state.go('page.financialService_periodAdd')
            });
          } else {
            $scope.$broadcast('gf-submitted');
          }
        };
      }
    )
    .controller('ServicePeriodGridCtrl',

      function ($scope, servicePeriodContext, servicePeriodMetadata, configOptionsService) {

        var self = this;
        self.metadata = servicePeriodMetadata;
        self.context = servicePeriodContext;

        self.isOverlapping = false;

        self.config = {
          urlRoute: '/financial/service_period',
          enableSorting: true,
          enableAdd: true,
          enableAddRow: true,
          enableColumnMoving: true,
          enableColumnResizing: true,
          enableColumnsHiding: true,
          columns: [
            {name: 'id', width: 60, enableCellEdit: false, visible: false},
            {name: 'name'},
            {
              name: 'timeStart',
              width: 70,
              enableCellEdit: false,
              cellFilter: "timeServicePeriodFilter:row.entity"
            },
            {
              name: 'timeEnd',
              width: 70,
              enableCellEdit: false,
              cellFilter: "timeServicePeriodFilter:row.entity"
            },
            {name: 'monday', width: 50, enableSorting: false},
            {name: 'tuesday', width: 50, enableSorting: false},
            {name: 'wednesday', width: 50, enableSorting: false},
            {name: 'thursday', width: 50, enableSorting: false},
            {name: 'friday', width: 50, enableSorting: false},
            {name: 'saturday', width: 50, enableSorting: false},
            {name: 'sunday', width: 50, enableSorting: false},

            {name: 'description', width: 200, visible: false},
            {
              name: 'operate',
              actions: ['activate', 'edit', 'delete'],
              actionOptions: {
                'activate': {
                  isDisabled: configOptionsService.get('servicePeriodActivateButton').isDisabled
                },
                'delete': {
                  isDisabled: configOptionsService.get('servicePeriodDeleteButton').isDisabled
                }
              }
            }
          ]
        };
      }
    )

    .directive('dateFormat', function (moment) {
      return {
        require: 'ngModel',
        link: function (scope, elem, attrs, ngModel) {
          ngModel.$parsers.push(function toModel(input) {
            return moment(input).format("HH:mm:ss");
          });

          ngModel.$formatters.push(function toView(input) {
            return moment(input, "HH:mm:ss").toISOString();
          });
        }
      }
    })
    .filter('timeServicePeriodFilter', ['moment', function (moment) {
      return function (input, entity) {
        if (!entity || !input) return;
        if (!entity.id) {
          return "";
        }
        return moment(input, "HH:mm:ss").format("HH:mm");
      }
    }])
})();
