import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {lighten, darken} from '@material-ui/core/styles';


const useStyles = makeStyles(theme => ({
  alert: {
    padding: '15px',
    border: '1px solid',
    borderLeft: '10px solid',
    borderColor: theme.palette.grey[200]
  },
  success: {
    background: lighten(theme.palette.success.main, 0.9),
    borderColor: lighten(theme.palette.success.main, 0.8),
    color: darken(theme.palette.success.main, 0.3),
    borderLeftColor: darken(theme.palette.success.main, 0.3),
  },
  info: {
    background: lighten(theme.palette.info.main, 0.9),
    borderColor: lighten(theme.palette.info.main, 0.8),
    color: darken(theme.palette.info.main, 0.3),
    borderLeftColor: darken(theme.palette.info.main, 0.3),
  },
  warning: {
    background: lighten(theme.palette.warning.main, 0.9),
    borderColor: lighten(theme.palette.warning.main, 0.8),
    color: darken(theme.palette.warning.main, 0.3),
    borderLeftColor: darken(theme.palette.warning.main, 0.3),
  },
  error: {
    background: lighten(theme.palette.error.main, 0.9),
    borderColor: lighten(theme.palette.error.main, 0.8),
    color: darken(theme.palette.error.main, 0.3),
    borderLeftColor: darken(theme.palette.error.main, 0.3),
  }
}));

function GFAlert({children, type, className}) {
  const classes = useStyles();

  return (
    <div className={`${classes.alert} ${classes[type]} ${className || ''}`}>
      <Typography component={'div'}>
        {children}
      </Typography>
    </div>
  )
}

export default GFAlert;
