(function () {
  'use strict';
  angular.module('gf.bm.orderScreen', ['gf.bm.groupView'])

    .factory('BMOrderScreenMetadata', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/order_screen/metadata.json')
    })
    .factory('BMOrderScreenContext', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/:ruleId/order_screen/context.json')
    })
    .factory('BMOrderScreenRules', function ($resource, appConfig) {
      return $resource(appConfig.baseUrl + '/icash/bm/:masterUnitId/:groupId/rule', {}, {
        load: {method: 'GET', isArray: true}
      })
    })

    .config(function ($stateProvider) {

      $stateProvider
        .state('bm.masterdataOrder_screen', {
          url: '/masterdata/order_screen',
          template: require('../../template/bm/group-view/masterdata/bm_article.html'),
          controller: 'BMMasterdataOrderScreen',
          controllerAs: '$ctrl',
          resolve: {
            bmOrderScreenMetadata: function (BMOrderScreenMetadata, $stateParams, securityService) {
              return BMOrderScreenMetadata.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmOrderScreenContext: function (BMOrderScreenContext, $stateParams, securityService) {
              return BMOrderScreenContext.get({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId,
                ruleId: 0
              }).$promise;
            },
            bmOrderScreenRules: function (BMOrderScreenRules, $stateParams, securityService) {
              return BMOrderScreenRules.load({
                masterUnitId: securityService.context.masterUnit.id,
                groupId: $stateParams.groupId
              }).$promise;
            }
          },
          title: "masterdataOrderScreen"
        })
    })

    .controller('BMMasterdataOrderScreen',
      function (bmOrderScreenMetadata, bmOrderScreenContext, securityService, $stateParams, bmOrderScreenRules) {
        var self = this;

        self.metadata = bmOrderScreenMetadata;
        self.metadata.fields.sync = {
            name: 'sync',
            javaType: 'boolean'
        };
        self.context = bmOrderScreenContext;
        self.masterdataRules = bmOrderScreenRules;

        self.gridConfig = {
          columnDefs: [
            {name: 'id', enableCellEdit: false, width: 70},
            {name: 'name', enableCellEdit: false},
            {name: 'rowSize', enableCellEdit: false},
            {name: 'size', enableCellEdit: false},
            {name: 'courseGroup'},
            {name: 'deviceScreenSize', enableCellEdit: false},
            {name: 'sync',
              enableCellEdit: securityService.hasAnyRole('BM_ORDER_SCREEN_UPDATE')
            }
          ],
          getBaseUrl: function (ruleId) {
            var rule = ruleId ? ruleId : 0;
            return '/icash/bm/' + securityService.context.masterUnit.id + '/' + $stateParams.groupId + '/' + rule + '/order_screen';
          }
        };

        self.permissionsConfig = {
          columnsPermissions: {
            'sync': {
              editPermissions: ['BM_ORDER_SCREEN_UPDATE']
            }
          }
        }

      }
    )

})();
