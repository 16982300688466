import React, {useEffect, useState} from 'react';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {
  Button,
  ClickAwayListener,
  Fade, MenuItem, MenuList,
  Paper,
  Popper
} from '@material-ui/core';
import {
  usePopupState,
  bindTrigger,
  bindPopper
} from 'material-ui-popup-state/hooks';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {makeStyles} from '@material-ui/core/styles';
import ReportPeriod from './ReportPeriod';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  rightPanel: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  datePickers: {
    flexGrow: 1
  },
  buttonWrapper: {
    textAlign: 'end'
  }
}));

function DatePicker({variant, color, placement, value, onSelect}) {
  const {t} = useTranslation();
  const [chosenDate, setChosenDate] = useState(value);
  const [date, setDate] = useState(chosenDate);

  const popupState = usePopupState(
    {variant: 'popper', popupId: 'datePickerSubMenu'})
  const classes = useStyles();

  useEffect(() => {
    if (value) {
      setChosenDate(value);
    }
  }, [value]);

  const onSelectOption = period => () => {
    setDate(period);
    popupState.close();
    onSelect(period);
  }

  const onSelectFromTo = () => {
    const fromToPeriod = ReportPeriod.fromTo();
    fromToPeriod.from = date.from || new Date();
    fromToPeriod.to = date.to || new Date();
    setDate(fromToPeriod);
  }

  const confirmFromTo = () => {
    onSelect && onSelect(date);
    popupState.close();
  }

  const handleFromChange = (from) => {
    const fromToPeriod = ReportPeriod.fromTo();
    fromToPeriod.from = from;
    fromToPeriod.to = date.to;
    setDate(fromToPeriod);
  }

  const handleToChange = (to) => {
    const fromToPeriod = ReportPeriod.fromTo();
    fromToPeriod.from = date.from;
    fromToPeriod.to = to;
    setDate(fromToPeriod);
  }

  const triggerProps = bindTrigger(popupState);
  const handleTriggerSubmenu = event => {
    if (!popupState.isOpen) {
      setDate(chosenDate);
    }
    triggerProps.onClick(event);
  }

  return (
    <>
      <Button
        {...triggerProps}
        onClick={handleTriggerSubmenu}
        variant={variant || 'contained'}
        color={color || 'primary'}
        startIcon={<DateRangeIcon/>}>
        {chosenDate.isFromTo()
          ? `${moment(chosenDate.getPeriod().from).format(
            'DD.MM.YYYY')} - ${moment(chosenDate.getPeriod().to).format(
            'DD.MM.YYYY')}`
          : t(chosenDate.period.translation)}
      </Button>
      <Popper {...bindPopper(popupState)} transition
              placement={placement || 'bottom-start'} style={{zIndex: 700}}>
        {({TransitionProps}) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper>
              <ClickAwayListener onClickAway={popupState.close}>
                <Grid container>
                  <Grid item xs={date.isFromTo() ? 6 : 12}>
                    <MenuList>
                      {ReportPeriod.all().map(reportPeriod => {
                        return <MenuItem
                          key={reportPeriod.period.id}
                          onClick={
                            reportPeriod.isFromTo()
                              ? onSelectFromTo
                              : onSelectOption(reportPeriod)
                          }
                          selected={date.equals(reportPeriod)}>
                          {t(reportPeriod.period.translation)}
                        </MenuItem>
                      })}
                    </MenuList>
                  </Grid>
                  {date.isFromTo() &&
                  <Grid item xs={6} className={classes.rightPanel}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <div className={classes.datePickers}>
                        <div>
                          <KeyboardDatePicker
                            autoOk
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date_from_picker"
                            label={t('reportFilter.fromTime')}
                            value={date.from}
                            onChange={handleFromChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            maxDate={date.to}
                          />
                        </div>
                        <div>
                          <KeyboardDatePicker
                            autoOk
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date_to_picker"
                            label={t('reportFilter.toTime')}
                            value={date.to}
                            onChange={handleToChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                            minDate={date.from}
                          />
                        </div>
                      </div>
                      <div className={classes.buttonWrapper}>
                        <Button variant='contained'
                                onClick={confirmFromTo}
                                size={'small'}>{t('button.ok')}</Button>
                      </div>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  }
                </Grid>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default DatePicker;
